import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { KeyIndicatorsConversion, KeyIndicatorsVolume } from ".";
import { setLoading } from "../../../actions/loadingActions";
import "../../../assets/styles/component/keyIndicators.scss";
import "../../../assets/styles/component/tooltip.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { ToggleSwitch } from "../../../components/Toogle";
import { KeyIndicator, NewMetricsTypes } from "../../../constants/interface";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";

interface Props {
  isCpoDashboard?: boolean;
  isVolume: boolean;
  setIsVolume: Dispatch<SetStateAction<boolean>>;
}

export const KeyIndicators = (props: KeyIndicator & Props) => {
  const {
    activeMetric,
    popLabel,
    dateTitleDescription,
    dateTitleValue,
    showPoP,
    onHandleMetricClick,
    isCpoDashboard,
    isVolume,
    setIsVolume,
  } = props;
  const dispatch = useDispatch();
  const cpoBreakdownTotal = useSelector((state: RootStateOrAny) => state.cpo_digital_performance.key_indicator_all);
  const availableKbas = useSelector((state: RootStateOrAny) => state.digital_performance.visits_trend_kbas.all.Current);
  const availableLeads = useSelector((state: RootStateOrAny) => state.digital_performance.visits_trend_leads.all.Current);
  const newTrendBreakdownTotal = useSelector((state: RootStateOrAny) => state.digital_performance.new_key_indicator_totals.data);

  const lead_keys = useSelector((state: RootStateOrAny) => state.cpo_digital_performance.cpo_lead_keys.keys);
  const kba_keys = useSelector((state: RootStateOrAny) => state.cpo_digital_performance.cpo_kba_keys.keys);

  const [oceKbas, setOceKbas] = useState<Array<any>>([]);
  const [oceLeads, setOceLeads] = useState<Array<any>>([]);

  const [Current, setCurrent] = useState<Array<NewMetricsTypes>>();
  const [YoY, setYoY] = useState<Array<NewMetricsTypes>>();
  const [PoP, setPoP] = useState<Array<NewMetricsTypes>>();
  const [cpoCurrent, setCpoCurrent] = useState<Array<NewMetricsTypes>>();
  const [cpo_oce, setCpoOce] =
    useState<{ kbas: number; kbas_over_visits: number; leads: number; leads_over_visits: number; visits: number }>();
  const [cpoYearOnYear, setCpoYearOnYear] =
    useState<{ kbas: number; kbas_over_visits: number; leads: number; leads_over_visits: number; visits: number }>();
  const [cpo_period_on_period, setCpoPeriodOnPeriod] =
    useState<{ kbas: number; kbas_over_visits: number; leads: number; leads_over_visits: number; visits: number }>();

  useEffect(() => {
    setCurrent(newTrendBreakdownTotal.Current);
    setYoY(newTrendBreakdownTotal.YoY);
    setPoP(newTrendBreakdownTotal?.PoP);
  }, [newTrendBreakdownTotal]);

  useEffect(() => {
    if (newTrendBreakdownTotal && Current) dispatch(setLoading("key_indicator", false));
  }, [Current, newTrendBreakdownTotal]);

  useEffect(() => {
    if (availableKbas?.length > 0) {
      setOceKbas(availableKbas[0].data);
    }
    if (availableLeads?.length > 0) {
      setOceLeads(availableLeads[0].data);
    }
  }, [availableKbas, availableLeads]);

  useEffect(() => {
    if (newTrendBreakdownTotal && Current) dispatch(setLoading("key_indicator", false));
  }, [Current, newTrendBreakdownTotal]);

  useEffect(() => {
    if (cpoBreakdownTotal && cpoBreakdownTotal?.Current) {
      setCpoCurrent(cpoBreakdownTotal.Current);
      setCpoYearOnYear(cpoBreakdownTotal.YoY);
      setCpoPeriodOnPeriod(cpoBreakdownTotal?.PoP);
      setCpoOce(cpoBreakdownTotal?.Current[0]);
    }
  }, [cpoBreakdownTotal]);

  const isLoading = useSelector((state: RootStateOrAny) => state.loading.key_indicator);

  const handleToggleClick = () => {
    setIsVolume((prevState) => {
      eventTracking(MixpanelEvents.volume_coversion_toggle, { volume: !prevState, dashboard: "OCE" });
      return !prevState;
    });
  };

  const period = useMemo(() => {
    if (dateTitleValue == "last week") {
      const startDate = moment(isCpoDashboard ? cpoBreakdownTotal?.start_date : newTrendBreakdownTotal?.start_date).format("DD-MMM");
      const endDate = moment(isCpoDashboard ? cpoBreakdownTotal?.end_date : newTrendBreakdownTotal?.end_date).format("DD-MMM");

      return `(${startDate} to ${endDate} )`;
    }
    return dateTitleDescription;
  }, [newTrendBreakdownTotal, cpoBreakdownTotal, dateTitleValue]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="key_indicators">
        <h3>
          Key indicators {dateTitleValue} <span className={"period_updatable"}>{period}</span>
        </h3>
        <ToggleSwitch
          activeToggleLabel={"Volume"}
          inactiveToggleLabel={"Conversion rates"}
          active={isVolume}
          handleToggleClick={handleToggleClick}
          toggleClassName={"dp_toggle"}
        />

        <div className="main_content">
          {isVolume ? (
            // Volumes
            <KeyIndicatorsVolume
              availableKbas={isCpoDashboard ? kba_keys : oceKbas}
              availableLeads={isCpoDashboard ? lead_keys : oceLeads}
              activeMetric={activeMetric}
              popLabel={popLabel}
              showPoP={showPoP}
              onHandleMetricClick={onHandleMetricClick}
              Current={isCpoDashboard ? cpoCurrent : Current}
              YoY={isCpoDashboard ? cpoYearOnYear : YoY}
              PoP={isCpoDashboard ? cpo_period_on_period : PoP}
              dateTitleDescription={""}
              dateTitleValue={""}
              isCpoDashboard={isCpoDashboard}
              cpoOce={cpo_oce ? cpo_oce : null}
            />
          ) : (
            // Conversion
            <>
              <KeyIndicatorsConversion
                availableKbas={isCpoDashboard ? kba_keys : oceKbas}
                availableLeads={isCpoDashboard ? lead_keys : oceLeads}
                popLabel={popLabel}
                showPoP={showPoP}
                Current={isCpoDashboard ? cpoCurrent : Current}
                YoY={isCpoDashboard ? cpoYearOnYear : YoY}
                PoP={isCpoDashboard ? cpo_period_on_period : PoP}
                isCpoDashboard={isCpoDashboard}
                cpo_oce={cpo_oce ? cpo_oce : null}
              />
            </>
          )}
        </div>

        {/*Overlay*/}
        <LoadingEllipsis isLoading={isLoading} />
      </div>
    </ErrorBoundary>
  );
};
