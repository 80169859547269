import {
  IDepositBreakdown,
  IGlobalPaidOrganicData,
  IPopularData,
  IPreoderDeposits,
  IPreorderCancellation,
  IPreorderdata,
  IPreorders_CAN,
  IPreorders_USA,
} from "../../constants/interface/ariya";
import { postData } from "../utils";
import { apiPrefix, apiPrefix_Dp } from "./constants";

export const fetchPreorderData = (markets: Array<string>): Promise<IPreorderdata> =>
  postData(`${apiPrefix_Dp}/ariya_pre_order`, { market: markets });
export const fetchPreorderGradesData = (): Promise<IPopularData> => postData(`${apiPrefix}/NAETopThreeGrades_for_testing`, {});
export const fetchPreorderColoursData = (): Promise<IPopularData> => postData(`${apiPrefix}/onlinePreOrderVehicleExteriorColor`, {});
export const fetchPreorderGradesData_Norway = (): Promise<IPopularData> => postData(`${apiPrefix}/onlinePreOrderVehicleVersion_norway`, {});
export const fetchPreorderColoursData_Norway = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleExteriorColor_norway`, {});

export const fetchDepositInsightsByCity = (): Promise<IPreoderDeposits> =>
  postData(`${apiPrefix}/ariya_online_deposits_insights_by_city`, {});
export const fetchDepositInsightsByCity_Norway = (): Promise<IPreoderDeposits> =>
  postData(`${apiPrefix}/ariya_online_deposits_insights_by_city_norway`, {});

export const fetchPreorderDrilldownVersions = (markets: Array<string>): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleVersion`, { market: markets });

export const fetchPreorderDrilldownVersions_Norway = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleVersion_norway`, {});

export const fetchPreorderDrilldownColors = (): Promise<IPopularData> => postData(`${apiPrefix}/onlinePreOrderVehicleExteriorColor`, {});
export const fetchPreorderDrilldownColors_Norway = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleExteriorColor_norway`, {});
export const fetchPreorderCancellationTrendData_Last_4_Weeks = (): Promise<IPreorderCancellation> =>
  postData(`${apiPrefix}/ariya_cancellations_trended_last_4_weeks`, {});
export const fetchPreorderCancellationTrendData_Last_4_Weeks_Norway = (): Promise<IPreorderCancellation> =>
  postData(`${apiPrefix}/ariya_cancellations_trended_norway_last_4_weeks`, {});
export const fetchPreorderCancellationTrendData = (): Promise<IPreorderCancellation> =>
  postData(`${apiPrefix}/ariya_cancellations_trended`, {});
export const fetchPreorderCancellationTrendData_Norway = (): Promise<IPreorderCancellation> =>
  postData(`${apiPrefix}/ariya_cancellations_trended_norway`, {});

export const fetchAriyaCancellations = (): Promise<IPopularData> => postData(`${apiPrefix}/ariya_cancellations`, {});
export const fetchDepositsSplit = (): Promise<IGlobalPaidOrganicData> => postData(`${apiPrefix}/ariya_online_deposits_split`, {});

export const fetchDepositsDirect = (): Promise<IDepositBreakdown> => postData(`${apiPrefix}/ariya_online_deposits_direct`, {});
export const fetchDepositsEmail = (): Promise<IDepositBreakdown> => postData(`${apiPrefix}/ariya_online_deposits_email`, {});

export const fetchDepositsDirect_Last_4_Weeks = (): Promise<IDepositBreakdown> =>
  postData(`${apiPrefix}/ariya_online_deposits_direct_last_4_weeks`, {});
export const fetchDepositsEmail_Last_4_Weeks = (): Promise<IDepositBreakdown> =>
  postData(`${apiPrefix}/ariya_online_deposits_email_last_4_weeks`, {});

export const fetchCancellationWithDeposit = (): Promise<IDepositBreakdown> =>
  postData(`${apiPrefix}/ariya_online_deposits_cancellation_after_deposits`, {});
export const fetchCancellationWithoutDeposit = (): Promise<IDepositBreakdown> =>
  postData(`${apiPrefix}/ariya_online_deposits_cancellation_before_deposits`, {});

export const fetchPreorderData_USA = (): Promise<IPreorders_USA> => postData(`${apiPrefix}/ariya_online_deposits_total_united_states`, {});
export const fetchPreorderCancellationTrendData_USA = (): Promise<IPreorderCancellation> =>
  postData(`${apiPrefix}/ariya_cancellations_trended_united_states`, {});

export const fetchPreorderDrilldownVersions_USA = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleVersion_united_states`, {});
export const fetchPreorderDrilldownColors_USA = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleExteriorColor_united_states`, {});
export const fetchDepositInsightsByCity_USA = (): Promise<IPreoderDeposits> =>
  postData(`${apiPrefix}/ariya_online_deposits_insights_by_city_united_states`, {});

// UK
export const fetchPreorderData_UK = (): Promise<IPreorders_USA> => postData(`${apiPrefix}/ariya_online_deposits_total_united_kingdom`, {});
export const fetchPreorderCancellationTrendData_UK = (): Promise<IPreorderCancellation> =>
  postData(`${apiPrefix}/ariya_cancellations_trended_united_kingdom`, {});
export const fetchPreorderDrilldownVersions_UK = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleVersion_united_kingdom`, {});
export const fetchPreorderDrilldownColors_UK = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleExteriorColor_united_kingdom`, {});
export const fetchDepositInsightsByCity_UK = (): Promise<IPreoderDeposits> =>
  postData(`${apiPrefix}/ariya_online_deposits_insights_by_city_united_kingdom`, {});

// Sweden
export const fetchPreorderData_Sweden = (): Promise<IPreorders_USA> => postData(`${apiPrefix}/ariya_online_deposits_total_sweden`, {});
export const fetchPreorderCancellationTrendData_Sweden = (): Promise<IPreorderCancellation> =>
  postData(`${apiPrefix}/ariya_cancellations_trended_sweden`, {});
export const fetchPreorderDrilldownVersions_Sweden = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleVersion_sweden`, {});
export const fetchPreorderDrilldownColors_Sweden = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleExteriorColor_sweden`, {});
export const fetchDepositInsightsByCity_Sweden = (): Promise<IPreoderDeposits> =>
  postData(`${apiPrefix}/ariya_online_deposits_insights_by_city_sweden`, {});

// Italy
export const fetchPreorderData_Italy = (): Promise<IPreorders_USA> => postData(`${apiPrefix}/ariya_online_deposits_total_italy`, {});
export const fetchPreorderCancellationTrendData_Italy = (): Promise<IPreorderCancellation> =>
  postData(`${apiPrefix}/ariya_cancellations_trended_italy`, {});
export const fetchPreorderDrilldownVersions_Italy = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleVersion_italy`, {});
export const fetchPreorderDrilldownColors_Italy = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleExteriorColor_italy`, {});
export const fetchDepositInsightsByCity_Italy = (): Promise<IPreoderDeposits> =>
  postData(`${apiPrefix}/ariya_online_deposits_insights_by_city_italy`, {});

// Germany
export const fetchPreorderData_Germany = (): Promise<IPreorders_USA> => postData(`${apiPrefix}/ariya_online_deposits_total_germany`, {});
export const fetchPreorderCancellationTrendData_Germany = (): Promise<IPreorderCancellation> =>
  postData(`${apiPrefix}/ariya_cancellations_trended_germany`, {});
export const fetchPreorderDrilldownVersions_Germany = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleVersion_germany`, {});
export const fetchPreorderDrilldownColors_Germany = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleExteriorColor_germany`, {});
export const fetchDepositInsightsByCity_Germany = (): Promise<IPreoderDeposits> =>
  postData(`${apiPrefix}/ariya_online_deposits_insights_by_city_germany`, {});

// Netherlands
export const fetchPreorderData_Netherlands = (): Promise<IPreorders_USA> =>
  postData(`${apiPrefix}/ariya_online_deposits_total_netherlands`, {});
export const fetchPreorderCancellationTrendData_Netherlands = (): Promise<IPreorderCancellation> =>
  postData(`${apiPrefix}/ariya_cancellations_trended_netherlands`, {});
export const fetchPreorderDrilldownVersions_Netherlands = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleVersion_netherlands`, {});
export const fetchPreorderDrilldownColors_Netherlands = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleExteriorColor_netherlands`, {});
export const fetchDepositInsightsByCity_Netherlands = (): Promise<IPreoderDeposits> =>
  postData(`${apiPrefix}/ariya_online_deposits_insights_by_city_netherlands`, {});

// Canada
export const fetchPreorderData_Canada = (truncate_flag?: boolean): Promise<IPreorders_CAN> =>
  postData(`${apiPrefix}/ariya_online_deposits_total_canada`, { truncate_flag });
export const fetchPreorderCancellationTrendData_Canada = (truncate_flag?: boolean): Promise<IPreorderCancellation> =>
  postData(`${apiPrefix}/ariya_cancellations_trended_canada`, { truncate_flag });
export const fetchPreorderDrilldownVersions_Canada = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleVersion_canada`, {});
export const fetchPreorderDrilldownColors_Canada = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleExteriorColor_canada`, {});
export const fetchDepositInsightsByCity_Canada = (): Promise<IPreoderDeposits> =>
  postData(`${apiPrefix}/ariya_online_deposits_insights_by_city_canada`, {});

//france
export const fetchPreorderData_France = (): Promise<IPreorders_USA> => postData(`${apiPrefix}/ariya_online_deposits_total_france`, {});
export const fetchPreorderGradesData_France = (): Promise<IPopularData> => postData(`${apiPrefix}/onlinePreOrderVehicleVersion_france`, {});
export const fetchPreorderColoursData_France = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleExteriorColor_france`, {});
export const fetchDepositInsightsByCity_France = (): Promise<IPreoderDeposits> =>
  postData(`${apiPrefix}/ariya_online_deposits_insights_by_city_france`, {});
export const fetchPreorderDrilldownVersions_France = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleVersion_france`, {});
export const fetchPreorderDrilldownColors_France = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleExteriorColor_france`, {});
export const fetchPreorderCancellationTrendData_Last_4_Weeks_France = (): Promise<IPreorderCancellation> =>
  postData(`${apiPrefix}/ariya_cancellations_trended_france_last_4_weeks`, {});
export const fetchPreorderCancellationTrendData_France = (): Promise<IPreorderCancellation> =>
  postData(`${apiPrefix}/ariya_cancellations_trended_france`, {});

// Belgium
export const fetchPreorderData_Belgium = (): Promise<IPreorders_USA> => postData(`${apiPrefix}/ariya_online_deposits_total_belgium`, {});
export const fetchPreorderCancellationTrendData_Belgium = (): Promise<IPreorderCancellation> =>
  postData(`${apiPrefix}/ariya_cancellations_trended_belgium`, {});
export const fetchPreorderDrilldownVersions_Belgium = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleVersion_belgium`, {});
export const fetchPreorderDrilldownColors_Belgium = (): Promise<IPopularData> =>
  postData(`${apiPrefix}/onlinePreOrderVehicleExteriorColor_belgium`, {});
export const fetchDepositInsightsByCity_Belgium = (): Promise<IPreoderDeposits> =>
  postData(`${apiPrefix}/ariya_online_deposits_insights_by_city_belgium`, {});
