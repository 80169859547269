import React, { Suspense, useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FETCH_USER_DETAILS } from "../../actions/actionTypes";
import "../../assets/styles/component/pageContainer.scss";
import "../../assets/styles/pages/userProfile.scss";
import { UserProfileForm } from "../../components/Forms/UserProfileForm";
import LoadingEllipsis from "../../components/Loading/LoadingEllipsis";
import { DashboardMenu } from "../../components/Menu";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { ProfilePicture } from "./common/ProfilePicture";

export const UserProfile = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state: RootStateOrAny) => state.user.details);

  useEffect(() => {
    dispatch({ type: FETCH_USER_DETAILS });
  }, [dispatch]);

  // Usage tracking
  useEffect(() => eventTracking(MixpanelEvents.page_view, { dashboard: "Profile", page: "User profile" }), []);

  return (
    <Suspense fallback={<LoadingEllipsis isLoading={true} />}>
      {
        <div className={"pageWrapper"} id={"mainDashboard"}>
          <div className="dashboard" id="userProfile">
            <Suspense fallback={<LoadingEllipsis isLoading={true} />}>
              <DashboardMenu />
              <div className={"contentWrapper profileWrapper"}>
                {Object.keys(userDetails).length > 0 ? (
                  <>
                    <div className="user_header">
                      <ProfilePicture
                        firstname={userDetails?.first_name || userDetails?.email || " "}
                        lastname={userDetails?.last_name || " "}
                      />
                      <div className="profile_name">
                        {`Hello, ${userDetails?.first_name || " "} ${userDetails?.last_name || " "}`}
                        <br />
                        <span>{userDetails?.email}</span>
                        {userDetails?.business_function ? (
                          <>
                            <br /> <span>{userDetails?.business_function}</span>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <UserProfileForm user={userDetails} />
                  </>
                ) : null}
              </div>
            </Suspense>
          </div>{" "}
          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick={true}
            draggable={false}
            pauseOnHover={false}
          />
        </div>
      }
    </Suspense>
  );
};
