import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { ChannelBreakdown } from "../../../constants/interface/admin/common";
import { FormFields } from "../Dialogs/AnnotationDialog";

interface Props {
  control: Control<FormFields>;
  channelBreakdowns: ChannelBreakdown[];
  filteredChannelBreakdownsList: string[];
  errors: FieldErrors | undefined;
}

const ChannelBreakdownsField = (props: Props) => {
  const { control, channelBreakdowns, filteredChannelBreakdownsList, errors } = props;
  return (
    <Controller
      name="channel_breakdowns"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={channelBreakdowns.filter(
            (channelBreakdown: ChannelBreakdown) =>
              filteredChannelBreakdownsList.includes(channelBreakdown.channel_breakdown) ||
              channelBreakdown.channel_breakdown == ALL_OPTION_NO_SPACE
          )}
          disableClearable
          getOptionLabel={(option: ChannelBreakdown) => option.channel_breakdown}
          getOptionSelected={(option, value) => option.channel_breakdown == value.channel_breakdown}
          value={value[0]}
          onChange={(event, selectedValue) => {
            onChange([selectedValue]);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Channel Breakdowns"
              placeholder="Channel Breakdowns"
              error={errors?.channel_breakdowns ? true : false}
              helperText={errors?.channel_breakdowns?.message}
            />
          )}
        />
      )}
    />
  );
};

export default ChannelBreakdownsField;
