import { SET_AVAILABLE_FILTERS, SET_CAN_ACCESS_VIEW, SET_PAGE_NAME, SET_PAGE_TITLE_DESCRIPTION } from "../actions/actionTypes";

const initialState = {
  filters: {
    date_range: false,
    brand: false,
    group: false,
    geography: false,
    model: false,
    channel: false,
    device: false,
    app_store: false,
    ccs_cockpit_btn: false,
    ccs_journey_btn: false,
    ccs_app_review_btn: false,
    reset: false,
  },
  pageName: "",
  pageTitleDescription: "",
  can_access_view: true,
};

const pageReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_AVAILABLE_FILTERS:
      return { ...state, filters: { ...action.payload } };

    case SET_PAGE_NAME:
      return { ...state, pageName: action.payload };

    case SET_PAGE_TITLE_DESCRIPTION:
      return { ...state, pageTitleDescription: action.payload };

    case SET_CAN_ACCESS_VIEW:
      return { ...state, can_access_view: action.payload };

    default:
      return state;
  }
};
export default pageReducer;
