import moment from "moment";
import { getFYQuarter } from "../../../utils/dateFunctions";

export const getGranularityDisplay = (dateRangeParam: string, setChartGranularity: React.Dispatch<React.SetStateAction<string>>) => {
  //For custom range
  //show granularity if 4 or more months are selected
  // Three months of same quarter are selected
  if (dateRangeParam && dateRangeParam.length > 1) {
    if (dateRangeParam.includes("quarter") || dateRangeParam.includes("year")) {
      return true;
    } else {
      if (dateRangeParam.includes("-")) {
        const startDateParam = dateRangeParam
          ? moment(dateRangeParam.split("-")[0].trim(), "MMMM YYYY").startOf("month").format("YYYY-MM-DD")
          : "";
        const endDateParam = dateRangeParam
          ? moment(dateRangeParam.split("-")[1].trim(), "MMMM YYYY").endOf("month").format("YYYY-MM-DD")
          : "";
        if (moment(startDateParam, "YYYY-MM-DD").add(3, "months").isBefore(moment(endDateParam, "YYYY-MM-DD"))) {
          return true;
        }
        if (
          getFYQuarter(startDateParam) === getFYQuarter(endDateParam) &&
          moment(startDateParam, "YYYY-MM-DD").add(2, "months").isSame(moment(endDateParam, "YYYY-MM-DD").startOf("month"))
        ) {
          return true;
        }
      }
    }
    setChartGranularity("monthly");
    return false;
  }
  return false;
};

export const displayWeeklyOption = (dateRangeParam: string, setChartGranularity: React.Dispatch<React.SetStateAction<string>>) => {
  //For custom range
  //if date-range is three months or less
  if (dateRangeParam && dateRangeParam.length > 1) {
    if (dateRangeParam.includes("quarter")) {
      return true;
    } else {
      if (dateRangeParam.includes("-")) {
        const startDateParam = dateRangeParam
          ? moment(dateRangeParam.split("-")[0].trim(), "MMMM YYYY").startOf("month").format("YYYY-MM-DD")
          : "";
        const endDateParam = dateRangeParam
          ? moment(dateRangeParam.split("-")[1].trim(), "MMMM YYYY").endOf("month").format("YYYY-MM-DD")
          : "";
        if (moment(startDateParam, "YYYY-MM-DD").add(3, "months").startOf("month").isAfter(moment(endDateParam, "YYYY-MM-DD"))) {
          return true;
        }
      }
    }
    setChartGranularity("monthly");
    return false;
  }
  return false;
};

export const getLegendDfp = (dateRangeParam: string, previousPeriod = false) => {
  if (dateRangeParam && dateRangeParam.length > 1) {
    if (dateRangeParam.includes("-")) {
      const startDateParam = dateRangeParam
        ? moment(dateRangeParam.split("-")[0].trim(), "MMMM YYYY").startOf("month").format("YYYY-MM-DD")
        : "";
      const endDateParam = dateRangeParam
        ? moment(dateRangeParam.split("-")[1].trim(), "MMMM YYYY").endOf("month").format("YYYY-MM-DD")
        : "";
      //if startdate is same as end date return Month and Year
      if (moment(startDateParam, "YYYY-MM-DD").startOf("month").isSame(moment(endDateParam, "YYYY-MM-DD").startOf("month"))) {
        return previousPeriod
          ? moment(startDateParam, "YYYY-MM-DD").subtract(1, "years").format("MMM YYYY")
          : moment(startDateParam, "YYYY-MM-DD").format("MMM YYYY");
      }
    }
  }
  return previousPeriod ? "Previous year" : "Selected period";
};
