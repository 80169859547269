import React from "react";
import { Tooltip } from "react-tippy";

interface Props {
  title: string;
  tooltipText?: string;
  chart: JSX.Element;
  toggle?: JSX.Element;
}

export const MtmContent = (props: Props) => {
  const { title, chart, tooltipText, toggle } = props;

  return (
    <div className="mtm_metric">
      <div className="tile_header">
        <h4 className="">{title}</h4>
        {toggle}
        {tooltipText && (
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            title={tooltipText}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        )}
      </div>

      {chart}
    </div>
  );
};
