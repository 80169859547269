import { ErrorBoundary } from "@sentry/react";
import React, { useMemo, useState } from "react";
import { ErrorMsg } from "../../../../components/AppMessages";
import { IPreorderTableData } from "../../../../constants/interface/ariya";
import { isNull } from "../../../../utils/utilityFunctions";
import { AriyaDefaultTable } from "../../Components";
import styles from "./totalPreorder.module.scss";

interface Props {
  tableData: Array<IPreorderTableData>;
  pageNavigationFn: (evt: React.MouseEvent<HTMLElement>) => void;
}
export const TotalPreorder = (props: Props) => {
  const { tableData, pageNavigationFn } = props;

  const [hiddenColumns] = useState([""]);

  const data = useMemo(() => tableData, [tableData]);

  const columns = useMemo(
    () => [
      {
        id: "market",
        Header: "Market",
        accessor: "market",
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "alphanumericIgnoreCase",
      },
      {
        id: "preorder",
        Header: "Online pre-orders",
        accessor: "total",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: function renderVisitsType(props1: { value: number }) {
          const { value } = props1;
          return isNull(value) ? "n/a" : (value as number).toLocaleString();
        },
      },
      {
        Header: "Market share",
        accessor: `share`,
        id: "share",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: function renderVisitsType(props1: { value: string }) {
          const { value } = props1;

          return `${value}%`;
        },
      },
      {
        Header: "",
        accessor: `pageNavigation`,
        id: "navigation",
        disableSortBy: true,
        Cell: (props: { value: string }) => {
          const { value } = props;
          return <div className={styles.moreIcon} data-page={value} onClick={pageNavigationFn} />;
        },
      },
    ],
    []
  );

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      {data.length ? (
        <AriyaDefaultTable
          columns={columns}
          data={data}
          id={"total_preorder_table"}
          data-test-id={"total_preorder_table"}
          // @ts-ignore
          renderRowSubComponent={() => {}}
          isChildComponent={false}
          initialSortColId={{ id: "preorder", desc: true }}
          hiddenCols={hiddenColumns}
          sortable={false}
        />
      ) : (
        <></>
      )}
    </ErrorBoundary>
  );
};
