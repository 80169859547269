import { DateAxis, LabelBullet, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { BLACK, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  seriesList: Array<{ name: string; field: string }>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
  seriesColor: string;
}

class DmeoLandingPagePerformanceLineChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme, seriesColor } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.0";
    this.chart.colors.list = [color(seriesColor)];

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 10;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.extraMax = 0.5;
    valueAxis.renderer.minGridDistance = 20;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return `${text}${"%"}`;
    });

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    seriesList?.forEach(({ name, field }, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.dataFields.dateX = "date";
      // series.dataFields.valueY = field;

      const circleBullet = lineSeriesConfiguration(series, {
        valueY: field,
        name: name,
      });
      circleBullet.tooltipText = `[bold font-size: var(--regular_font_size)]{dateX.formatDate('MMM YYYY')}: [/ font-size: var(--regular_font_size)]{valueY.formatNumber('###,###')}%`;

      const labelBullet = series.bullets.push(new LabelBullet());
      labelBullet.label.text = "{valueY.formatNumber('#,###')}%";
      labelBullet.label.dy = -10;
      labelBullet.label.dx = 10;
      labelBullet.label.fill = theme == "dark" ? color(WHITE) : color(BLACK);
    });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default DmeoLandingPagePerformanceLineChart;
