import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { getPerformanceByMarketMap } from "../../../../api/executiveSummaryProductPerformanceApi";
import { NoDataMsg } from "../../../../components/AppMessages";
import ProductPerformanceByMarketMapChart from "../../../../components/Charts/ExecutiveSummary/ProductPerformanceByMarketMapChart";
import { DefaultFilter } from "../../../../components/Filters/common";
import { closeFilterOptions } from "../../../../components/Filters/subs/helpers";
import { DATE_FILTER_VALUE_MAPPING } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import { eventTracking, MixpanelEvents } from "../../../../utils/userTracking";

const metricMapping: Record<string, string> = {
  Visits: "visits",
  KBAs: "kbas",
  "Leads Visits": "leads",
  "Total Products": "total_products",
  "CR - visits to KBA": "visits_to_kbas",
  "CR - visits to Leads": "visits_to_leads",
};

const metricTooltipMapping: Record<string, string> = {
  Visits: "Website visit happens every time the site has at least one page fully loaded.",
  KBAs: "KBA or Key Buying Actions visits are the count of visits including at least one key buying action as Model detail compare, Saved searches views, Compare tools views and Dealer search.",
  "Leads Visits":
    "Lead visits are the count of visits including at least one digital lead as Enquire, Test drive, Request a video and Reserve.",
  "Total Products": "",
  "CR - visits to KBA": "Percentage of visits that have performed a KBA.	",
  "CR - visits to Leads": "Percentage of website visits that have performed a lead.	",
};

interface Props {
  metricFilterValue: string;
  setMetricFilterValue: Dispatch<SetStateAction<string>>;
}

const PerformanceByMarketMap = withRouter((props: RouteComponentProps & Props) => {
  const { history, metricFilterValue, setMetricFilterValue } = props;
  const { theme } = useContext(ThemeContext);
  const { date_range } = useSelector((state: RootStateOrAny) => state.parameters);

  const [dateString, setDateString] = useState("");

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["performanceByMarketMapData", metricFilterValue, history.location.search],
    queryFn: () => getPerformanceByMarketMap(metricMapping[metricFilterValue]),
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search, metricFilterValue]);

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      setMetricFilterValue(optionValue);
      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "Executive Summary - Product Performance",
      });
    }
    closeFilterOptions();
  }, []);

  useEffect(() => {
    if (Object.keys(DATE_FILTER_VALUE_MAPPING).includes(date_range)) {
      setDateString(DATE_FILTER_VALUE_MAPPING[date_range]);
    } else {
      const [start, end] = date_range.split("-");
      const startDate = moment(start, "MMMM YYYY").format("MMM YYYY");
      const endDate = moment(end, "MMMM YYYY").format("MMM YYYY");
      setDateString(`${startDate} - ${endDate}`);
    }
  }, [date_range]);

  return (
    <div className="tile map-tile">
      <div className="tile-header">
        <div className="title-container">
          <div className="title">
            <span>Performance by market</span>
            <span>(for selected metric)</span>
          </div>
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            html={
              <>
                <p>
                  Performance by market shows the performance of each market individually for the selected metric. Please note that the
                  scale is generated by results of the markets selected in the top filters.
                </p>
                <br />
                <ul>
                  <li>
                    <span>
                      <b>Visits:</b> Website visit happens every time the site has at least one page fully loaded.
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>KBAs:</b> KBA or Key Buying Actions visits are the count of visits including at least one key buying action as
                      Model detail compare, Saved searches views, Compare tools views and Dealer search.
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>Leads:</b> Lead visits are the count of visits including at least one digital lead as Enquire, Test drive, Request
                      a video and Reserve.
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>Total Products:</b> Number of total products live in each market within the dashboard product scope.
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>CR - Visits to KBAs:</b> Percentage of visits that have performed a KBA.
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>CR - Visits to Leads:</b> Percentage of website visits that have performed a lead.
                    </span>
                  </li>
                </ul>
              </>
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <div className="filters-container">
          <DefaultFilter
            list={["Visits", "KBAs", "Leads Visits", "Total Products", "CR - visits to KBA", "CR - visits to Leads"]}
            filterName={"metric"}
            filterLabel={"METRIC FILTER BY"}
            filterValue={metricFilterValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </div>
      </div>
      {data && data.length > 0 ? (
        <ProductPerformanceByMarketMapChart
          chartId="performanceByMarketMapChart"
          data={data}
          isLoading={isLoading}
          currentPeriodLabel={dateString}
          metric={metricFilterValue}
          theme={theme}
        />
      ) : (
        <NoDataMsg />
      )}
    </div>
  );
});

export default PerformanceByMarketMap;
