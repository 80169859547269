import React from "react";
import "../../../assets/styles/main.scss";
import "../../../assets/styles/pages/login.scss";
import { InvalidToken } from "../../../components/ErrorTemplates";
import { LoginErrorTemplate } from "./subs";

export const InvalidTokenPage = () => {
  return (
    <LoginErrorTemplate>
      <InvalidToken />
    </LoginErrorTemplate>
  );
};
