import { makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { ErrorBoundary } from "@sentry/react";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAdminBrandsData, fetchAdminMarketsData, fetchAdminRegionsData, fetchAdminViewsData } from "../../actions";
import { ErrorMsg } from "../../components/AppMessages";
import { IframeLayout } from "../../components/Layouts";
import { TabPanel } from "../../components/Tabs";
import { CYAN } from "../../constants";
import AnnotationsAdmin from "./Annotations/Annotations";
import { AdminKpiUpdate } from "./KpiUpdate/KpiUpdate";
import { AdminReleaseNotes } from "./ReleaseNotes/ReleaseNotes";
import { AdminSeoInsights } from "./SeoInsights/SeoInsights";
import UserGroups from "./UserGroups/UserGroups";
import { Users } from "./Users/Users";
import UserViews from "./UserViews/UserViews";

const adminTabs = [
  { id: "users", label: "Users", component: <Users /> },
  { id: "user-groups", label: "Geo Scope", component: <UserGroups /> },
  { id: "user-views", label: "User Views", component: <UserViews /> },
  { id: "release-notes", label: "Release Notes", component: <AdminReleaseNotes /> },
  { id: "seo-insights", label: "SEO Insights", component: <AdminSeoInsights /> },
  { id: "kpi-update", label: "KPI update log", component: <AdminKpiUpdate /> },
];

const annotationsTab = { id: "annotations", label: "Annotations", component: <AnnotationsAdmin /> };

const userTabs: Array<{ id: string; label: string; component: JSX.Element }> = [];

const userViewsList = Cookies.get("views");

userViewsList && /\badmin/.test(userViewsList) && userTabs.push(...adminTabs);

userViewsList && /annotation_admin/.test(userViewsList) && userTabs.push(annotationsTab);

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const NewAdminPage = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const classes = makeStyles({
    tabPanel: {
      background: "rgb(44, 57, 78, 0.8)",
      color: "#FFF",
      height: "auto !important",
    },
  })();

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    Promise.all([
      dispatch(fetchAdminBrandsData()),
      dispatch(fetchAdminMarketsData()),
      dispatch(fetchAdminRegionsData()),
      dispatch(fetchAdminViewsData()),
    ]);
  }, [dispatch]);

  return (
    <IframeLayout hasFilter={false}>
      <ErrorBoundary fallback={<ErrorMsg />}>
        <div id="container">
          <div className={"adminWrapper"} style={{ padding: "24px" }}>
            <AppBar
              position="static"
              style={{
                width: "fit-content",
                margin: "0 auto",
                marginBottom: 20,
                background: "rgb(44, 57, 78, 0.8)",
                height: "auto",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="admin section tabs"
                TabIndicatorProps={{ style: { backgroundColor: CYAN } }}
                data-test-id="admin-section-tabs"
              >
                {userTabs.map((tab, idx) => (
                  <Tab label={tab.label} {...a11yProps(idx)} data-test-id={`${tab.id}-tab`} key={tab.id} />
                ))}
              </Tabs>
            </AppBar>
            {userTabs.map((tab, idx) => (
              <TabPanel data-test-id={`${tab.id}-panel`} value={value} index={idx} className={classes.tabPanel} key={tab.id}>
                {tab.component}
              </TabPanel>
            ))}
          </div>
        </div>
      </ErrorBoundary>
    </IframeLayout>
  );
};

export default NewAdminPage;
