import { SET_TAGS_DATA } from "../actions/actionTypes";

const initialState = { data: [] };

const tagsReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_TAGS_DATA:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};
export default tagsReducer;
