import React from "react";
import "../../../../assets/styles/main.scss";
import "../../../../assets/styles/pages/login.scss";

interface Props {
  children: JSX.Element;
}

export const LoginErrorTemplate = (props: Props) => {
  const { children } = props;

  return (
    <div className={"pageWrapper"} id={"loginPage"}>
      <div className="dashboard" id="login_dashboard">
        {children}
      </div>
    </div>
  );
};
