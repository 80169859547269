import { ErrorBoundary } from "@sentry/react";
import React, { Fragment } from "react";
import { Tooltip } from "react-tippy";
import { ErrorMsg } from "../../../components/AppMessages";
import Ratings from "../../../components/Ratings/Ratings";
import { metricTypes } from "../../../constants";
import { convertToAbsoluteFloat, plusSignNumbers, toLocaleInteger } from "../../../utils/utilityFunctions";

interface Props {
  availableMetric?: Array<{ name: string; key: string; metric_type: string; metric_visits: number | null; visits: number | null }>;
  metric_value: number | null;
  metric_yoy: number | null;
  metric_wow: number | null;
  showPoP: boolean;
  metric: metricTypes;
  showTooltip: boolean;
  popLabel: string;
  kbaTooltipVisible?: boolean;
  leadTooltipVisible?: boolean;
  setLeadTooltipVisible?: any;
  setKbaTooltipVisible?: any;
  isConversion: boolean;
  isCpoDashboard?: boolean;
  cpo_oce?: any;
  title: string;
}

export const KeyIndicatorMetric = (props: Props) => {
  const {
    metric_value,
    metric,
    showPoP,
    metric_yoy,
    metric_wow,
    popLabel,
    availableMetric,
    showTooltip,
    setKbaTooltipVisible,
    setLeadTooltipVisible,
    leadTooltipVisible,
    kbaTooltipVisible,
    isConversion,
    isCpoDashboard,
    cpo_oce,
    title,
  } = props;

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="key_indicator_header">
        <h4>{title}</h4>
        {showTooltip && (
          <Tooltip
            position="left"
            className="key_indicator_tooltip"
            html={
              <Fragment>
                <p>
                  {metric === "kbas"
                    ? "Key Buying Actions visits are the count of visits including at least one key buying action"
                    : "Digital lead visits are the count of visits including at least one digital lead"}
                </p>
                <details>
                  <summary>{`See ${metric === "kbas" ? ` Key Buying Actions` : `Digital lead`} types for this geography`}</summary>
                  <div>
                    <p>{`The ${
                      metric === "kbas" ? ` Key Buying Actions` : `Digital lead`
                    } types taken into account for this geography are: `}</p>
                    {availableMetric?.map((metric: { name: string; metric_type: string }) => (
                      <li key={metric.name ? metric.name : metric.metric_type}>
                        {metric.name
                          ? metric.name
                          : metric.metric_type.split("_").slice(1).join(" ").charAt(0).toUpperCase() +
                            metric.metric_type.split("_").slice(1).join(" ").slice(1)}
                      </li>
                    ))}
                  </div>
                </details>
              </Fragment>
            }
            trigger="click"
            animation="none"
            theme="dark"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            distance={-130}
            open={metric === "kbas" ? kbaTooltipVisible : leadTooltipVisible}
            //@ts-ignore
            onShown={metric === "kbas" ? () => setLeadTooltipVisible(false) : () => setKbaTooltipVisible(false)}
          >
            <span
              className="info_tooltip hide_on_mobile"
              onClick={(evt: React.MouseEvent<HTMLElement>) => {
                evt.stopPropagation();
                metric === "kbas" ? setKbaTooltipVisible(!kbaTooltipVisible) : setLeadTooltipVisible(!leadTooltipVisible);
              }}
            />
          </Tooltip>
        )}
      </div>

      <p className="main" data-test-id={`${isConversion ? `visits_to_${metric}` : metric}_main`}>
        {isConversion ? metric_value : metric_value ? (isNaN(metric_value) ? "n/a" : toLocaleInteger(metric_value)) : "-"}
      </p>

      <div className="comparison_values" data-test-id={`${isConversion ? `visits_to_${metric}` : metric}_comparison_values`}>
        {isCpoDashboard && !isConversion && (
          <p>
            {cpo_oce &&
              cpo_oce[`${metric}_new_cars`] &&
              ` vs ${cpo_oce[`${metric}_new_cars`].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} for new cars`}
          </p>
        )}
        {isCpoDashboard && isConversion && (
          <p>{cpo_oce && cpo_oce[`visits_to_${metric}_new_cars`] && ` vs ${cpo_oce[`visits_to_${metric}_new_cars`]} for new cars`}</p>
        )}
        {showPoP ? (
          <p className="period_comparison" data-test-id={`${isConversion ? `visits_to_${metric}` : metric}_period_comparison`}>
            {!isConversion && (
              <Ratings value={metric_wow ? (isNaN(metric_wow) ? "na" : String(metric_wow)) : ""} isPercentageValue={true} />
            )}
            <span className="comparison_value" data-test-id={"visits_period_comparison_value"}>
              {!isConversion ? convertToAbsoluteFloat(metric_wow, 0, true) : `${plusSignNumbers(metric_wow?.toFixed(2))} PTS`}
            </span>
            {popLabel}
          </p>
        ) : (
          ""
        )}
        <p className="year_comparison" data-test-id={`${isConversion ? `visits_to_${metric}` : metric}_year_comparison`}>
          {!isConversion && <Ratings value={metric_yoy ? (isNaN(metric_yoy) ? "na" : String(metric_yoy)) : ""} isPercentageValue={true} />}
          <span className="comparison_value" data-test-id={"visits_period_comparison_value"}>
            {!isConversion ? convertToAbsoluteFloat(metric_yoy, 0, true) : `${plusSignNumbers(metric_yoy?.toFixed(2))} PTS`}
          </span>
          YoY
        </p>
      </div>
    </ErrorBoundary>
  );
};
