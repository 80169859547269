import React, { useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { requestDashboardAccess } from "../../../api";
import dark_entry_icon from "../../../assets/images/cedar_home/enter_dark.png";
import light_entry_icon from "../../../assets/images/cedar_home/enter_light.png";
import warning_alert from "../../../assets/images/cedar_home/warning.png";
import { CXO_DASHBOARD_URL } from "../../../constants";
import { Dashboard } from "../../../constants/interface";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";

interface Props {
  theme: string;
  showIcons: boolean;
  isDisabled: boolean;
  isMobile: boolean;
  dashboard: Dashboard;
}

export const InfoBoard = (props: Props) => {
  const { dashboard, theme, showIcons, isDisabled, isMobile } = props;

  const DISABLE_ACCESS_REQUEST = ["midas"];

  //function for the request access url
  //send dashboard name to the backend
  const handleRequestAccess = useCallback(
    (e) => {
      e.preventDefault();
      requestDashboardAccess(dashboard.id).then((response) => {
        if (response.error) {
          toast.error("Failed to update your request, please try again later");
        } else {
          eventTracking(MixpanelEvents.dashboard_access_request, { dashboard: dashboard.name });
          toast.success("Request for access has been received");
        }
      });
    },
    [dashboard]
  );

  // load overview image from assets
  let image;
  if (dashboard.overview_image) {
    image = require(`../../../assets/images/cedar_home/${theme}_${dashboard.overview_image}.png`).default;
  }
  //knowledge hub link
  const cxoToken = useSelector((state: RootStateOrAny) => state.user.cxoToken.token);
  const [cxoUrl, setCxoUrl] = useState(CXO_DASHBOARD_URL);

  useEffect(() => {
    setCxoUrl(`${CXO_DASHBOARD_URL}${cxoToken}`);
  }, [cxoToken]);

  return (
    <>
      <div className={`main_section ${!showIcons ? "untouched" : "touched"}`}>
        {dashboard.name}
        {/* use cxoUrl for knowledge hub */}
        {!isDisabled && !isMobile && (
          <div className="icons">
            <a
              href={`${dashboard.id === "cxo_experiment_library" ? cxoUrl : dashboard.path}`}
              target={`${dashboard.id === "cxo_experiment_library" ? "_blank" : "_self"}`}
              data-test-id={`dashboard_link_${dashboard.id}`}
              onClick={() => (dashboard.id === "cxo_experiment_library" ? eventTracking(MixpanelEvents.cxo_access, { through: "LP" }) : {})}
            >
              <img className="icon" src={theme === "light" ? light_entry_icon : dark_entry_icon} alt="Info Icon" />
            </a>
          </div>
        )}
        {!isDisabled && !dashboard.hide_on_mobile && isMobile && (
          <div className="icons">
            <a
              href={`${dashboard.id === "cxo_experiment_library" ? cxoUrl : dashboard.path}`}
              data-test-id={`dashboard_link_${dashboard.id}`}
              onClick={() => (dashboard.id === "cxo_experiment_library" ? eventTracking(MixpanelEvents.cxo_access, { through: "LP" }) : {})}
            >
              <img className="icon" src={theme === "light" ? light_entry_icon : dark_entry_icon} alt="Info Icon" />
            </a>
          </div>
        )}
      </div>
      {showIcons && (
        <>
          {dashboard.point_of_interest_summary && (
            <div className="info_board">
              {
                <>
                  <p>{dashboard.point_of_interest_summary}</p>
                  <ul>
                    {dashboard.points_of_interest &&
                      dashboard.points_of_interest.map((x: string, idx: number) => (
                        <li key={idx}>
                          <span>{x}</span>
                        </li>
                      ))}
                  </ul>
                </>
              }
            </div>
          )}
          {!isDisabled && (!isMobile || (!dashboard.hide_on_mobile && isMobile)) && (
            <Link to={dashboard.path} data-test-id={`dashboard_link_${dashboard.id}`}>
              <div className="overview_board">{image && <img src={image} alt="dashboard overview" />}</div>
            </Link>
          )}
          {!isDisabled && dashboard.hide_on_mobile && isMobile && (
            <>
              <div className="overview_board">{image && <img src={image} alt="dashboard overview" />}</div>
              <div className="info_board warning_alert">
                <img src={warning_alert} alt="warning_alert" />
                <p>
                  Sorry, this is a medium fidelity dashboard and responsiveness isn&#39;t supported yet. Please use a laptop to view the
                  dashboard.
                </p>
              </div>
            </>
          )}
        </>
      )}
      {showIcons && isDisabled && !DISABLE_ACCESS_REQUEST.includes(dashboard.id) && (
        <div className="info_board">
          <p>Access to this dashboard is currently restricted.</p>
          <Link className="access_link" to="#" onClick={(e) => handleRequestAccess(e)} data-test-id={`access_link_${dashboard.id}`}>
            Request access
          </Link>
        </div>
      )}
    </>
  );
};
