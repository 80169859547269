import { AxisLabel } from "@amcharts/amcharts4/charts";
import moment from "moment";

export const formatDateAxisLabelToQuarterlyFY = (dateString: string) =>
  `Q${moment(dateString).fquarter().quarter} FY${moment(dateString).fquarter().year.toString().substr(-2)}`;

export const formatDateAxisLabelToMonthlyFY = (dateString: string) =>
  `${moment(dateString).format("MMM")} ${moment(dateString).year().toString().substr(-2)}`;

export const formatDateAxisLabelToYearlyFY = (dateString: string) => `FY${moment(dateString).year().toString().substr(-2)}`;

export const formatDateAxisLabelToMonthly = (dateString: string) => moment(dateString, "YYYY-MM-DD").format("MMM");

export const dateAxisLabelFormatter_QuarterlyFY = (text: string | undefined, axisLabel: AxisLabel): string | undefined => {
  const dataDate = axisLabel.dataItem.dates.date;

  return formatDateAxisLabelToQuarterlyFY(dataDate as unknown as string) || text;
};

export const dateAxisLabelFormatter_MonthlyFY = (text: string | undefined, axisLabel: AxisLabel): string | undefined => {
  const dataDate = axisLabel.dataItem.dates.date;

  return formatDateAxisLabelToMonthlyFY(dataDate as unknown as string) || text;
};

export const dateAxisLabelFormatter_YearlyFY = (text: string | undefined, axisLabel: AxisLabel): string | undefined => {
  const dataDate = axisLabel.dataItem.dates.date;

  return formatDateAxisLabelToYearlyFY(dataDate as unknown as string) || text;
};

export const formatChinaDateAxisLabelToQuarterlyFY = (dateString: string) =>
  `Q${moment(dateString).fquarter(1).quarter} CY${moment(dateString).fquarter(1).year.toString().substr(-2)}`;

export const formatChinaDateAxisLabelToYearlyFY = (dateString: string) => `CY${moment(dateString).fquarter(1).year.toString().substr(-2)}`;

export const formatChinaDateAxisLabelToMonthly = (dateString: string) => moment(dateString, "YYYY-MM-DD").format("MMM");

export const dateChinaAxisLabelFormatter_QuarterlyFY = (text: string | undefined, axisLabel: AxisLabel): string | undefined => {
  const dataDate = axisLabel.dataItem.dates.date;

  return formatChinaDateAxisLabelToQuarterlyFY(dataDate as unknown as string) || text;
};

export const dateChinaAxisLabelFormatter_YearlyFY = (text: string | undefined, axisLabel: AxisLabel): string | undefined => {
  const dataDate = axisLabel.dataItem.dates.date;

  return formatChinaDateAxisLabelToYearlyFY(dataDate as unknown as string) || text;
};
