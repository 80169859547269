import React, { useContext, useEffect, useState } from "react";
import { ToggleSwitch } from "../../../components/Toogle";
import { Tooltip } from "react-tippy";
import Ratings from "../../../components/Ratings/Ratings";
import DmeoConversionRateBarLineChart from "../../../components/Charts/Dmeo/DmeoConversionRateBarLineChart";
import { ThemeContext } from "../../../context";
import { useQuery } from "@tanstack/react-query";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getConversionRate } from "../../../api/dmeoApi";

const ConversionRateTile = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const [isLeads, setIsLeads] = useState(true);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["conversionRateData", isLeads],
    queryFn: () => getConversionRate(isLeads),
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search, isLeads]);

  return (
    <div className="tile conversion-rate-tile">
      <div className="tile-header">
        <div className="title"> Conversion Rate </div>
        <ToggleSwitch
          active={isLeads}
          activeToggleLabel="Leads"
          inactiveToggleLabel="KBAs"
          handleToggleClick={() => setIsLeads((prevState) => !prevState)}
        />
      </div>
      <div className="tile-content">
        <div className="metric-container">
          <div className="title">
            Conversion Rate{" "}
            <Tooltip
              position="top"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              size={"small"}
              html={
                <div>
                  <div>
                    <p>
                      <b>Leads:</b> Number of sessions where the user completes a lead after landing on the platform through a specific
                      campaign.
                    </p>
                    <p>
                      <b>KBAs:</b> Number of sessions where the user completes a Key Buying action after landing on the platform through a
                      specific campaign.
                    </p>
                  </div>
                </div>
              }
            >
              <span className="info_tooltip" />
            </Tooltip>
          </div>
          <div className="metric-value">{data?.conversion_rate ? `${data.conversion_rate}%` : "n/a"}</div>
          <div className="sub-text">
            {data?.yoy && <Ratings value={Number(data?.yoy)} isPercentageValue={true} />} <div>{data?.yoy ? `${data?.yoy}%` : "n/a"}</div>{" "}
            <div>&nbsp;vs PY</div>
          </div>
        </div>
        <div className="chart-containter">
          <DmeoConversionRateBarLineChart
            chartId="dmeoConversionRateBarLineChart"
            theme={theme}
            data={data?.data ? data.data : []}
            seriesList={data?.series ? data.series : []}
            isLoading={isFetching}
          />
        </div>
      </div>
    </div>
  );
});

export default ConversionRateTile;
