import React from "react";
import "../../../assets/styles/component/documentation.scss";
import "../../../assets/styles/component/releaseNotes.scss";

export const ReleaseNotes = (props: { isVisible: boolean; onCloseClick: () => void; notes: string }) => {
  const { isVisible, onCloseClick, notes } = props;
  return (
    <div className={`popup release_popup ${isVisible ? "show" : ""}`}>
      <div className="popupModal ">
        <button className="closePopup" onClick={onCloseClick}>
          ╳
        </button>

        {/* @ts-ignore */}
        <div className="data_message" dangerouslySetInnerHTML={{ __html: notes }} />
      </div>
    </div>
  );
};
