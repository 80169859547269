import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Control, Controller, FieldErrors, UseFormSetValue } from "react-hook-form";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { Market, Model } from "../../../constants/interface/admin/common";
import { FormFields } from "../Dialogs/AnnotationDialog";

interface Props {
  control: Control<FormFields>;
  availableMarkets: Market[];
  models: Model[];
  setValue: UseFormSetValue<FormFields>;
  errors: FieldErrors | undefined;
}

const MarketsField = (props: Props) => {
  const { control, availableMarkets, models, setValue, errors } = props;
  return (
    <Controller
      name="markets"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          multiple
          options={availableMarkets}
          disableCloseOnSelect
          getOptionLabel={(option: Market) => option.market}
          getOptionSelected={(option, value) => option.market == value.market}
          value={value}
          onChange={(_, data) => {
            setValue(
              "models",
              models.filter((model: { model: string }) => model.model == ALL_OPTION_NO_SPACE)
            );
            data.length > 0 && data[data.length - 1].market == ALL_OPTION_NO_SPACE
              ? onChange([data.find((market) => market.market == ALL_OPTION_NO_SPACE)])
              : onChange(data.filter((market) => market.market != ALL_OPTION_NO_SPACE));
          }}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox style={{ marginRight: 8 }} checked={selected} color="primary" />
              {option.market}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Markets"
              placeholder="Markets"
              error={errors?.markets ? true : false}
              helperText={errors?.markets?.message}
            />
          )}
        />
      )}
      rules={{
        required: {
          value: true,
          message: "Please select a market",
        },
      }}
    />
  );
};

export default MarketsField;
