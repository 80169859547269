/* eslint-disable react/jsx-key */
import React, { useEffect, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useFlexLayout, usePagination, useSortBy, useTable } from "react-table";
import "../../../../assets/styles/component/tables.scss";
import { getDateDependentValues } from "../../../../pages/Online CE Performance/subs/helpers";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const SeoDefaultChildTable = ({ data, columns: chartColumns, showHidden }): JSX.Element => {
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.seo_parameters.date_range);
  const { showPop: popVisible } = getDateDependentValues(dateRangeParamValue);

  const childInitialState = {
    initialState: {
      hiddenColumns: popVisible
        ? showHidden
          ? []
          : ["popComparisonColumn", "yoyColumn"]
        : showHidden
        ? ["popComparisonColumn", "popPercentageColumn"]
        : ["popComparisonColumn", "popPercentageColumn", "yoyColumn"],
    },
  };

  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 10, // minWidth is only used as a limit for resizing
      width: 10, // width is used for both the flex-basis and flex-grow
      maxWidth: 10, // maxWidth is only used as a limit for resizing
    }),
    []
  );
  const { getTableProps, getTableBodyProps, page, prepareRow, setHiddenColumns } = useTable(
    {
      columns: chartColumns,
      data,
      ...childInitialState,
      defaultColumn,
    },
    useSortBy,
    usePagination,
    useFlexLayout
  );

  useEffect(() => {
    let hiddenColumns: string[] = [];
    if (popVisible) {
      if (showHidden) {
        hiddenColumns = [];
      } else {
        hiddenColumns = ["popComparisonColumn", "yoyColumn"];
      }
    } else {
      if (showHidden) {
        hiddenColumns = ["popComparisonColumn", "popPercentageColumn"];
      } else {
        hiddenColumns = ["popComparisonColumn", "popPercentageColumn", "yoyColumn"];
      }
    }
    setHiddenColumns(hiddenColumns);
  }, [popVisible, setHiddenColumns, showHidden]);

  return data.length === 0 ? (
    <> </>
  ) : (
    <>
      <table {...getTableProps()} className="child-table" key={Math.random()}>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default SeoDefaultChildTable;
