import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { getProductsActivatedByMarket } from "../../../../api/executiveSummaryProductPerformanceApi";
import { NoDataMsg } from "../../../../components/AppMessages";
import ProductActivatedByMarketChart from "../../../../components/Charts/ExecutiveSummary/ProductActivatedByMarketChart";
import { DefaultFilter } from "../../../../components/Filters/common";
import InAppSingleSelectNestedFilter from "../../../../components/Filters/common/InAppSingleSelectNestedFilter";
import { closeFilterOptions } from "../../../../components/Filters/subs/helpers";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { FilterContext, ThemeContext } from "../../../../context";
import { eventTracking, MixpanelEvents } from "../../../../utils/userTracking";

const crSlectionMapping: Record<string, string> = {
  "Visits to KBAs": "visits_to_kbas",
  "Visits to Leads": "visits_to_leads",
};

const ProductsActivatedByMarket = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const [filterDataBy, setFilteDataBy] = useState("Global");
  const [crSelection, setCrSelection] = useState("Visits to KBAs");

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["productsActivatedByMarketData", filterDataBy, crSelection, history.location.search],
    queryFn: () => getProductsActivatedByMarket(filterDataBy, crSlectionMapping[crSelection]),
    enabled: false,
    initialData: {},
  });

  useEffect(() => {
    refetch();
  }, [history.location.search, filterDataBy, crSelection]);

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      filterName == "filter_data_by" ? setFilteDataBy(optionValue) : setCrSelection(optionValue);
      eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "Executive Summary" });
    }
    closeFilterOptions();
  }, []);

  const queryCache = useQueryClient()
  const filtersData: any = queryCache.getQueriesData(["executiveSummaryProductPerformanceFiltersData"])?.at(-1)
  const regions = filtersData ? filtersData[1]?.geographies?.map((geo: any) => ({ value: geo.region })) : []

  return (
    <div className="tile products-activated-market">
      <div className="tile-header">
        <div className="title-container">
          <div className="title">Products Activated by Market correlated with Conversion Rate</div>
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={
              " Shows the total number of products activated globally, regionally and in each market and a Conversion Rate comparison. Please note that the numbers are affected by the filters selection"
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <div className="filters-container">
          <InAppSingleSelectNestedFilter
            options={[
              { value: "Global" },
              {
                value: "Regions",
                children: regions,
              },
              { value: "Market" },
            ]}
            filterValue={filterDataBy}
            filterName={"filter_data_by"}
            filterLabel={"FILTER DATA BY"}
            setValue={setFilteDataBy}
          />
          <DefaultFilter
            list={["Visits to KBAs", "Visits to Leads"]}
            filterName={"cr_selection"}
            filterLabel={"CR SELECTION"}
            filterValue={crSelection}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </div>
      </div>
      {isFetching ? (
        <LoadingEllipsis isLoading={isFetching} />
      ) : data?.data && data?.series ? (
        <ProductActivatedByMarketChart
          chartName="productsActivatedByMarketChart"
          data={data?.data ?? []}
          seriesList={data?.series ?? []}
          isLoading={isFetching}
          theme={theme}
          categoryAxisField="category"
        />
      ) : (
        <NoDataMsg />
      )}
    </div>
  );
});

export default ProductsActivatedByMarket;
