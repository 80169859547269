import { format, parseISO, parseJSON } from "date-fns";
import XLSX from "xlsx";
import { Order } from "../../constants/interface/admin/common";

function descendingComparator<T, U>(
  a: Record<keyof T, Array<U | string | number | boolean | string[] | Date | any>>,
  b: Record<keyof T, Array<U | string | number | boolean | string[] | Date | any>>,
  orderBy: keyof T,
  table?: string
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let aValue = typeof a[orderBy] === "string" ? a[orderBy].toUpperCase() : a[orderBy] === null ? "" : a[orderBy];
  if (
    orderBy === "markets" ||
    orderBy === "regions" ||
    orderBy === "brands" ||
    orderBy === "models" ||
    orderBy === "channels" ||
    orderBy === "metrics" ||
    orderBy === "tags" ||
    (orderBy === "groups" && table === "user_views") ||
    (orderBy === "views" && table === "user_views")
  ) {
    // @ts-ignore
    aValue = a[orderBy].map((item) => item[orderBy.slice(0, -1)]).join(", ");
  } else if ((orderBy === "groups" && table === "users") || (orderBy === "views" && table === "users")) {
    aValue = a[orderBy].map((item) => item.name).join(", ");
  } else if (orderBy === "owner") {
    // @ts-ignore
    aValue = a["user"]["email"];
  } else if (orderBy === "created_at") {
    // @ts-ignore
    const date = parseJSON(aValue);
    aValue = format(date, "yyyy-LL-dd");
  } else if (orderBy === "scope") {
    // @ts-ignore
    aValue = a["applies_to"] == "filters_only" ? "these tags only" : "these tags and level above";
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let bValue = typeof b[orderBy] === "string" ? b[orderBy].toUpperCase() : b[orderBy] === null ? "" : b[orderBy];
  if (
    orderBy === "markets" ||
    orderBy === "regions" ||
    orderBy === "brands" ||
    orderBy === "models" ||
    orderBy === "channels" ||
    orderBy === "metrics" ||
    orderBy === "tags" ||
    (orderBy === "groups" && table === "user_views") ||
    (orderBy === "views" && table === "user_views")
  ) {
    // @ts-ignore
    bValue = b[orderBy].map((item) => item[orderBy.slice(0, -1)]).join(", ");
  } else if ((orderBy === "groups" && table === "users") || (orderBy === "views" && table === "users")) {
    bValue = b[orderBy].map((item) => item.name).join(", ");
  } else if (orderBy === "owner") {
    // @ts-ignore
    bValue = b["user"]["email"];
  } else if (orderBy === "created_at") {
    // @ts-ignore
    const date = parseISO(bValue);
    bValue = format(date, "yyyy-LL-dd");
  } else if (orderBy === "scope") {
    // @ts-ignore
    bValue = b["applies_to"] == "filters_only" ? "these tags only" : "these tags and level above";
  }

  if (orderBy === "version_number") {
    // @ts-ignore
    const majorDiff = b["major"] - a["major"];
    if (majorDiff != 0) {
      return majorDiff;
    }
    // @ts-ignore
    const minorDiff = b["minor"] - a["minor"];
    if (minorDiff != 0) {
      return minorDiff;
    }
    return 0;
  }

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: string | number,
  table?: string
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy, table) : (a, b) => -descendingComparator(a, b, orderBy, table);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function downloadExcelFile(data: any, filename: string) {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(data);

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  XLSX.writeFile(workbook, `${filename}.xlsx`);
}
