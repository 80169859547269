import { format, parse } from "date-fns";
import { VOC_DATE_FILTER_OPTIONS } from "../constants";
import { newApiFetchV2, newFetchDataV2 } from "./utils";

export const getVOCParams = (markets?: string) => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);

  const dateRangeParam = parameters.get("date_range");
  const brand = parameters.get("brand")?.split(",");
  const market = markets
    ? markets == "All"
      ? []
      : markets.split(",")
    : parameters.get("market") == "All"
    ? []
    : parameters.get("market")?.split(",");
  const objective = parameters.get("objective") == "All" ? [] : parameters.get("objective")?.split(",") ?? [];

  const date = dateRangeParam
    ? VOC_DATE_FILTER_OPTIONS.includes(dateRangeParam)
      ? dateRangeParam.split(" ").join("_").toLocaleLowerCase()
      : "custom"
    : "";

  const parameterEntries: Record<string, string | null | Array<string> | undefined> = {
    date_range: date,
    brand: brand,
    market: market,
    objective: objective,
  };

  if (date == "custom") {
    parameterEntries["start_date"] = dateRangeParam
      ? format(parse(dateRangeParam.split("-")[0].trim(), "dd/MM/yyyy", new Date()), "y-MM-dd")
      : "";
    parameterEntries["end_date"] = dateRangeParam
      ? format(parse(dateRangeParam.split("-")[1].trim(), "dd/MM/yyyy", new Date()), "y-MM-dd")
      : "";
  }

  return parameterEntries;
};

export const getMarketOverview = () => newApiFetchV2("/voc/market-overview", { ...getVOCParams() });
export const getStarRatingAverageRating = () => newApiFetchV2("/voc/star-rating-overview/average-rating", { ...getVOCParams() });
export const getStarRatingAverageRatingSelectedMarkets = (markets: string) =>
  newApiFetchV2("/voc/star-rating-overview/average-rating?use_cache=true", { ...getVOCParams(markets) });
export const getStarRatingAverageRatingTrend = () => newApiFetchV2("/voc/star-rating-overview/average-rating-trend", { ...getVOCParams() });
export const getStarRatingFeedback = () => newApiFetchV2("/voc/star-rating-overview/feedback", { ...getVOCParams() });
export const getStarRatingScoreDistribution = () => newApiFetchV2("/voc/star-rating-overview/score-distribution", { ...getVOCParams() });
export const getStarRatingDeviceDistribution = () => newApiFetchV2("/voc/star-rating-overview/device-distribution", { ...getVOCParams() });
export const getStarRatingAverageRatingByMarket = (markets: string) =>
  newApiFetchV2("/voc/star-rating-overview/average-rating-by-market", { ...getVOCParams(markets) });
export const getExitSurveyObjectiveCompletion = () =>
  newApiFetchV2("/voc/exit-survey/objective-completion?use_cache=true", { ...getVOCParams() });
export const getExitSurveyObjectiveCompletionSelectedMarkets = (markets: string) =>
  newApiFetchV2("/voc/exit-survey/objective-completion", { ...getVOCParams(markets) });
export const getExitSurveyFeedback = () => newApiFetchV2("/voc/exit-survey/feedback", { ...getVOCParams() });
export const getExitSurveyObjectiveCompletionTrend = () =>
  newApiFetchV2("/voc/exit-survey/objective-completion-trend", { ...getVOCParams() });
export const getExitSurveyNonCompletionReasons = () => newApiFetchV2("/voc/exit-survey/non-completion-reasons", { ...getVOCParams() });
export const getExitSurveyDeviceDistribution = () => newApiFetchV2("/voc/exit-survey/device-distribution", { ...getVOCParams() });
export const getExitSurveyObjectiveCompletionByMarket = (markets: string) =>
  newApiFetchV2("/voc/exit-survey/objective-completion-by-market", { ...getVOCParams(markets) });
export const getRatingPerTouchpointMarketBreakdown = () =>
  newApiFetchV2("/voc/rating-per-touchpoint/market-breakdown", { ...getVOCParams() });
export const getRatingPerTouchpointVlpFocus = () => newApiFetchV2("/voc/rating-per-touchpoint/vlp-focus", { ...getVOCParams() });
export const getRatingPerTouchpointOtherTouchpoints = () =>
  newApiFetchV2("/voc/rating-per-touchpoint/other-touchpoints", { ...getVOCParams() });
export const getRatingPerTouchpointMediaCampaigns = () =>
  newApiFetchV2("/voc/rating-per-touchpoint/media-campaigns", { ...getVOCParams() });
export const getRatingPerTouchpointElectrification = () =>
  newApiFetchV2("/voc/rating-per-touchpoint/electrification", { ...getVOCParams() });
export const getRatingPerTouchpointCompletionRate = () =>
  newApiFetchV2("/voc/rating-per-touchpoint/completion-rate", { ...getVOCParams() });
export const getVocWebsiteDataDefinitions = (page: string) => newFetchDataV2(`/voc/data-definition?page=${page}`);
export const getTopicClassificationDissatisfaction = () =>
  newApiFetchV2("/voc/categories/topic-classification-dissatisfaction", { ...getVOCParams() });
export const getTopicClassificationSatisfaction = () =>
  newApiFetchV2("/voc/categories/topic-classification-satisfaction", { ...getVOCParams() });
export const getFeedbackComments = (experience_rating: any, topics: any, touchpoints: any) =>
  newApiFetchV2("/voc/categories/feedback-comments", { ...getVOCParams(), experience_rating, topics, touchpoints });
export const getRptOwnership = () => newApiFetchV2("/voc/rating-per-touchpoint/ownership", { ...getVOCParams() });
export const getRptEditorial = () => newApiFetchV2("/voc/rating-per-touchpoint/editorial", { ...getVOCParams() });
export const getFeedbackCommentsFilters = () => newApiFetchV2("/voc/categories/feedback-comments-filters", { ...getVOCParams() });
