import {
  FETCH_BRAND_IMAGE_ATTRIBUTES_AVERAGE,
  FETCH_BRAND_IMAGE_ATTRIBUTES_TREND,
  FETCH_BRAND_OAO_AVERAGE,
  FETCH_BRAND_OAO_TREND,
  FETCH_BRAND_VOLUME_AVERAGE,
  FETCH_BRAND_VOLUME_TREND,
  SET_BRAND_IMAGE_ATTRIBUTES_AVERAGE,
  SET_BRAND_IMAGE_ATTRIBUTES_TREND,
  SET_BRAND_OAO_AVERAGE,
  SET_BRAND_OAO_TREND,
  SET_BRAND_VOLUME_AVERAGE,
  SET_BRAND_VOLUME_TREND,
} from "./actionTypes";

export const fetchBrandVolumeTrend = () => {
  return { type: FETCH_BRAND_VOLUME_TREND };
};

export const setBrandVolumeTrend = (data: any) => {
  return { type: SET_BRAND_VOLUME_TREND, payload: data };
};

export const fetchBrandVolumeAverage = () => {
  return { type: FETCH_BRAND_VOLUME_AVERAGE };
};

export const setBrandVolumeAverage = (data: any) => {
  return { type: SET_BRAND_VOLUME_AVERAGE, payload: data };
};

export const fetchBrandOaOTrend = () => {
  return { type: FETCH_BRAND_OAO_TREND };
};

export const setBrandOaOTrend = (data: any) => {
  return { type: SET_BRAND_OAO_TREND, payload: data };
};

export const fetchBrandOaOAverage = () => {
  return { type: FETCH_BRAND_OAO_AVERAGE };
};

export const setBrandOaOAverage = (data: any) => {
  return { type: SET_BRAND_OAO_AVERAGE, payload: data };
};

export const fetchBrandImageAttributesTrend = () => {
  return { type: FETCH_BRAND_IMAGE_ATTRIBUTES_TREND };
};

export const setBrandImageAttributesTrend = (data: any) => {
  return { type: SET_BRAND_IMAGE_ATTRIBUTES_TREND, payload: data };
};

export const fetchBrandImageAttributesAverage = () => {
  return { type: FETCH_BRAND_IMAGE_ATTRIBUTES_AVERAGE };
};

export const setBrandImageAttributesAverage = (data: any) => {
  return { type: SET_BRAND_IMAGE_ATTRIBUTES_AVERAGE, payload: data };
};
