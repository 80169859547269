import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { Metric } from "../../../constants/interface/admin/common";
import { FormFields } from "../Dialogs/AnnotationDialog";

interface Props {
  control: Control<FormFields>;
  metrics: Metric[];
  errors: FieldErrors | undefined;
}

const MetricsField = (props: Props) => {
  const { control, metrics, errors } = props;
  return (
    <Controller
      name="metrics"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          multiple
          options={metrics}
          disableCloseOnSelect
          getOptionLabel={(option: Metric) => option.metric}
          getOptionSelected={(option, value) => option.metric == value.metric}
          value={value}
          onChange={(_, data) => {
            onChange(data);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Metric"
              placeholder="Metric"
              error={errors?.metrics ? true : false}
              helperText={errors?.metrics?.message}
            />
          )}
        />
      )}
      rules={{
        required: {
          value: true,
          message: "Please select a metric",
        },
      }}
    />
  );
};

export default MetricsField;
