import moment from "moment";
import { DIS_PRESET_DATE_PARAMS } from "../constants";
import { newApiFetchV2 } from "./utils";

export const getPurchaseFunnelParameters = () => {
  const parameters = new URLSearchParams(window.location.search);
  const dateRangeParam = parameters.get("date_range");
  const marketParam = parameters.get("market");
  const leadSourceParam = parameters.get("leads_source");
  const leadGroupParam = parameters.get("lead_group");
  const brandParam = parameters.get("brand");

  const market = marketParam && marketParam != "All" ? marketParam.split(",") : [];
  const brand = brandParam ? brandParam.split(",") : [];
  const leads_source = leadSourceParam && leadSourceParam != "All" ? leadSourceParam.split(",") : [];
  const lead_group = leadGroupParam && leadGroupParam != "All" ? leadGroupParam.split(",") : [];

  const paramObj: any = { market, brand, leads_source, lead_group };

  if (dateRangeParam) {
    if (DIS_PRESET_DATE_PARAMS.includes(dateRangeParam)) {
      paramObj["date_range"] = dateRangeParam.split(" ").join("_").toLocaleLowerCase();
    } else if (dateRangeParam?.includes("-")) {
      paramObj["date_range"] = "custom";
      paramObj["start_date"] = moment(dateRangeParam.split("-")[0], "MMMM YYYY").format("YYYY-MM-DD");
      paramObj["end_date"] = moment(dateRangeParam.split("-")[1], "MMMM YYYY").endOf("month").format("YYYY-MM-DD");
    } else {
      paramObj["date_range"] = DIS_PRESET_DATE_PARAMS[DIS_PRESET_DATE_PARAMS.length - 2].split(" ").join("_").toLocaleLowerCase();
    }
  }

  return paramObj;
};

export const getDisLastRefreshDate = () => newApiFetchV2(`/dis/last-refresh-date`, getPurchaseFunnelParameters());

export const getAvailableMarkets = () => newApiFetchV2(`/dis/markets-with-lead-sources-selected`, getPurchaseFunnelParameters());

export const getKeyIndicators = () => newApiFetchV2("/dis/sales-key-indicators", getPurchaseFunnelParameters());

export const getDigitallyInfluencedSalesTrend = (cumulative: boolean) =>
  newApiFetchV2(`/dis/digitally-influenced-sales-trend?cumulative=${cumulative}`, getPurchaseFunnelParameters());

export const getPrivateSalesBreakdown = (cumulative: boolean) =>
  newApiFetchV2(`/dis/private-sales-breakdown?cumulative=${cumulative}`, getPurchaseFunnelParameters());

export const getDisCloseRate = () => newApiFetchV2(`/dis/digitally-influenced-close-rate?cumulative`, getPurchaseFunnelParameters());

export const getSalesAndLeadsTrend = () => newApiFetchV2(`/dis/sales-and-leads-trend`, getPurchaseFunnelParameters());

export const getDisCloseRateVariance = () =>
  newApiFetchV2(`/dis/digitally-influenced-close-rate-variance-by-channel`, getPurchaseFunnelParameters());

export const getSalesOrLeadsTrendVariance = (isSales: boolean) =>
  newApiFetchV2(`/dis/sales-or-leads-trend-variance-by-channel?is_sales=${isSales}`, getPurchaseFunnelParameters());

export const getDigitalLeadsVariance = (showDigitallyInfluencedLeads: boolean) =>
  newApiFetchV2(
    `/dis/digital-leads-variance-by-channel?digitally_influenced_leads=${showDigitallyInfluencedLeads}`,
    getPurchaseFunnelParameters()
  );

export const getSalesVariance = () => newApiFetchV2(`/dis/sales-variance-by-channel`, getPurchaseFunnelParameters());

export const getDigitalLeadsBySource = (showDigitallyInfluencedLeads: boolean) =>
  newApiFetchV2(`/dis/digital-leads-by-source?digitally_influenced_leads=${showDigitallyInfluencedLeads}`, getPurchaseFunnelParameters());

export const getSalesBreakdownBySource = (showDigitallyInfluencedSales: boolean) =>
  newApiFetchV2(`/dis/sales-breakdown-by-source?digitally_influenced_sales=${showDigitallyInfluencedSales}`, getPurchaseFunnelParameters());

export const getTopGrowthModels = () => newApiFetchV2(`/dis/top-growth-models`, getPurchaseFunnelParameters());

export const getBottomGrowthModels = () => newApiFetchV2(`/dis/top-decrease-models`, getPurchaseFunnelParameters());

export const getTopIncreaseModels = () => newApiFetchV2(`/dis/top-increase-models-performance-variance`, getPurchaseFunnelParameters());

export const getTopDecreaseModels = () => newApiFetchV2(`/dis/top-decrease-models-performance-variance`, getPurchaseFunnelParameters());

export const getModelsVolumeBreakdown = (proportion: boolean) =>
  newApiFetchV2(`/dis/models-volume-breakdown?proportion=${proportion}`, getPurchaseFunnelParameters());
