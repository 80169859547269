import { Box } from "@material-ui/core";
import React from "react";

interface TabPanelProps {
  index: number;
  value: number;
  children?: React.ReactNode;
  [x: string]: number | React.ReactNode;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={3}> {children}</Box>}
    </div>
  );
};
