import { ErrorBoundary } from "@sentry/react";
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import PriceSalesVolumeChart from "../../../components/Charts/ProductMarketingOptimization/PriceSalesVolumeChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { ToggleSwitch } from "../../../components/Toogle";
import { currencies } from "../../../constants/currenctConstants";
import { ThemeContext } from "../../../context";

interface Props {
  setPriceType: Dispatch<SetStateAction<string>>;
  seriesList: any[];
}

const PriceSalesVolume = (props: Props) => {
  const { setPriceType, seriesList } = props;
  const ref = useRef<any>(null);
  const themeContext = useContext(ThemeContext);

  const customerIntegrationDataLoading: boolean = useSelector((state: RootStateOrAny) => state.loading.price_volume_sales);
  const modelParam = useSelector((state: RootStateOrAny) => state.parameters.model);
  const marketParam = useSelector((state: RootStateOrAny) => state.parameters.market);

  const data = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.price_volume_sales?.data ?? {});
  const currency = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.price_volume_sales?.currency ?? "");
  const competitorModels = useSelector(
    (state: RootStateOrAny) => state.product_marketing_optimization.price_volume_sales?.competitor_models ?? []
  );

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [showRoundedPrice, setShowRoundedPrice] = useState<boolean>(true);

  const currencySymbol = useMemo(() => {
    //@ts-ignore
    if (currencies[currency] != undefined) {
      //@ts-ignore
      return currencies[currency]["symbol"];
    }
  }, [currency]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (tooltipVisible && ref.current != null && !ref.current.contains(e.target)) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [tooltipVisible]);

  useEffect(() => {
    showRoundedPrice ? setPriceType("rounded") : setPriceType("exact");
  }, [showRoundedPrice]);

  const models = useMemo(() => {
    if (competitorModels.length > 0) {
      return competitorModels[0].competitor_models;
    }
    return [];
  }, [competitorModels]);

  return (
    <>
      <div className="price_sales_volume primary_tile_background">
        <div className="header">
          <div className="title_container">
            <h3>Price sales volume curve</h3>
            {models?.length > 0 && marketParam != "Italy" && (
              <Tooltip
                position="left"
                className="key_indicator_tooltip"
                html={
                  <div ref={ref}>
                    <p>
                      <u>Source:</u> Sales volume data sourced from JATO database
                    </p>
                    <br />
                    <p>Segment data based on the following competitor models:</p>
                    <ul>
                      {models?.map((model: string) => (
                        <li key={model}>{model}</li>
                      ))}
                    </ul>
                    {(modelParam.includes("Note") || modelParam.includes("Aura")) && (
                      <div>
                        <p>
                          <u>Note:</u> Note and Aura aren&#8217;t distinguishable in the JATO database, so this chart displays the same data
                          for both models.
                        </p>
                        <br />
                      </div>
                    )}
                    {modelParam.includes("NP300") && marketParam == "Mexico" && (
                      <div>
                        <p>
                          <u>Note:</u> Frontier and NP300 aren&#8217;t distinguishable in the JATO database, so this chart displays the same
                          data for both models.
                        </p>
                        <br />
                      </div>
                    )}
                  </div>
                }
                trigger="click"
                animation="none"
                theme="dark"
                interactive={true}
                delay={1}
                hideDelay={1}
                duration={1}
                open={tooltipVisible}
                //@ts-ignore
                onShown={() => setTooltipVisible(!tooltipVisible)}
              >
                <span
                  className="info_tooltip hide_on_mobile"
                  onClick={(evt: React.MouseEvent<HTMLElement>) => {
                    evt.stopPropagation();
                    setTooltipVisible(!tooltipVisible);
                  }}
                />
              </Tooltip>
            )}
          </div>

          <div className="toogle_container">
            <p>See:</p>
            <ToggleSwitch
              activeToggleLabel={"Rounded price"}
              inactiveToggleLabel={"Exact price"}
              active={showRoundedPrice}
              handleToggleClick={() => setShowRoundedPrice(!showRoundedPrice)}
              toggleClassName={"product_marketing_optimization_toggle"}
            />
          </div>
        </div>

        <div className="main_content">
          {seriesList?.length && marketParam != "Italy" ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <PriceSalesVolumeChart
                data={data}
                theme={themeContext.theme}
                isLoading={customerIntegrationDataLoading}
                currency={currencySymbol}
                showRoundedPrice={showRoundedPrice}
                seriesList={seriesList}
                market={marketParam}
              />
            </ErrorBoundary>
          ) : customerIntegrationDataLoading ? (
            <LoadingEllipsis isLoading={customerIntegrationDataLoading} />
          ) : (
            <NoDataMsg message="No data available for this market" />
          )}
        </div>
      </div>
    </>
  );
};

export default PriceSalesVolume;
