import { ErrorBoundary } from "@sentry/react";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg } from "../../../../components/AppMessages";
import { ValueBarChart } from "../../../../components/Charts/CCSCockpit";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";
import { ThemeContext } from "../../../../context";
import { formatToThreeDigits } from "../../../../utils/numberFormatting";
import { isNull } from "../../../../utils/utilityFunctions";

interface Props {
  title: string;
  value: number;
  comparisonPercentageValue?: number | null;
  isLoading: boolean;
  chartDataKey?: string;
  chartData?: Array<any>;
  onClick?: () => void;
  toggle: (toggleState: boolean, setToogleState: Dispatch<SetStateAction<boolean>>) => void;
  toggleActive: boolean;
  setToggleActive: Dispatch<SetStateAction<boolean>>;
}

export const ValueTile = (props: Props) => {
  const {
    title,
    value,
    comparisonPercentageValue,
    chartData,
    chartDataKey,
    isLoading,
    onClick: handleClick,
    toggle,
    toggleActive,
    setToggleActive,
  } = props;

  const themeContext = useContext(ThemeContext);

  const comparisonLabel = "YoY";

  const { brand: brandParamValue, region: regionParamValue } = useSelector((state: RootStateOrAny) => state.parameters);

  const [isAllUndefined, setIsAllUndefined] = useState<boolean>(false);
  useEffect(() => {
    const key = `${chartDataKey}`;
    setIsAllUndefined(
      chartData?.every((splitRow) => typeof splitRow[key] === "undefined" || isNull(splitRow[key]) || splitRow[key] === 0) ? true : false
    );
  }, [chartData]);

  const isComparisonValueValid = typeof comparisonPercentageValue !== "undefined";

  return (
    <div className="tile" id={title.replaceAll(" ", "_")} data-test-id={title.replaceAll(" ", "_")} onClick={handleClick}>
      <div className="tile_header">
        {title} {chartData && toggle(toggleActive, setToggleActive)}
      </div>
      <div className="CCS_main_content">
        <div className="tile_content">
          <div className="value" data-test-value={isNull(value) ? "n/a" : value}>
            {isNull(value) ? "n/a" : `${formatToThreeDigits(value)}`}
          </div>
          {isComparisonValueValid && (
            <p className="period_comparison comparison">
              <span className="main">
                <Ratings
                  value={comparisonPercentageValue ? (isNull(comparisonPercentageValue) ? "na" : String(comparisonPercentageValue)) : ""}
                  isPercentageValue={true}
                />
                <span className="comparison_value">{comparisonPercentageValue}%</span> {comparisonLabel}
              </span>
            </p>
          )}
        </div>
        {chartData && (
          <div className="metric_content">
            <ErrorBoundary fallback={<ErrorMsg />}>
              <ValueBarChart
                chartId={`${chartDataKey}Chart`}
                data={isAllUndefined ? [] : chartData}
                seriesName={title}
                dataKey={chartDataKey ? chartDataKey : ""}
                theme={themeContext.theme}
              />
            </ErrorBoundary>
          </div>
        )}
        {(brandParamValue === "Nissan" || /all/gi.test(brandParamValue)) &&
          (/all/gi.test(regionParamValue) || regionParamValue === "Europe") && (
            <div className="asterisk">
              <p>*Europe app downloads only represent Nissan Connect Services data</p>
            </div>
          )}
      </div>

      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};
