import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";

const TotalAddedValueTile = () => {
  const { total_added_value: totalAddedValue } = useSelector((state: RootStateOrAny) => state.msrp.total_added_value);
  const { total_added_value: loading } = useSelector((state: RootStateOrAny) => state.loading);
  const currency = useSelector((state: RootStateOrAny) => state.msrp.currency);

  return (
    <div className="msrp-tile total-added-value">
      <div>
        <div className="tile-header">Total added value</div>
        <div className="value">{totalAddedValue ? `${currency}${totalAddedValue.toLocaleString()}` : "n/a"}</div>
      </div>
      <div className="note">Note: DIS here is only a partial picture representing sales captured from Sales Navi</div>
      <LoadingEllipsis isLoading={loading} />
    </div>
  );
};

export default TotalAddedValueTile;
