import { ColumnSeries, DateAxis, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent, Tooltip } from "@amcharts/amcharts4/core";
import _, { isNull } from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, CCS_ANALYSIS_COLORS, WHITE } from "../../../constants";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import { columnSeriesConfiguration, configureDateAxis, configureValueAxis, legendConfiguration } from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";
interface Props {
  data: any;
  chartName: string;
  theme: ThemeInt;
  seriesList: any;
  showVolume: boolean;
}
interface ISeries {
  name: string;
  field: string;
  seriesId: any;
  index: number;
}
class FeatureUsageBarStackedChart extends Component<Props> {
  chart!: XYChart;
  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }
  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }
  componentWillUnmount() {
    this.chart.dispose();
  }
  initChart() {
    const { data, chartName, theme, seriesList, showVolume } = this.props;
    this.chart = create(chartName, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.colors.list = CCS_ANALYSIS_COLORS.map((chartColor: string) => color(chartColor));

    //Add legends
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.itemContainers.template.paddingTop = 2.5;
    this.chart.legend.itemContainers.template.marginBottom = 25;
    this.chart.legend.position = "left";
    this.chart.legend.scrollable = this.props.chartName === "CssAnalysisFeatureUsageChart" ? false : true;
    this.chart.legend.paddingBottom = 0;
    this.chart.legend.width = 520;
    this.chart.legend.maxHeight = 200;
    this.chart.legend.marginTop = -500;
    this.chart.legend.scrollable = true;

    this.chart.legend.layout = "grid";
    //Default x axis to show current date
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 30;
    //Y Axis that is used by default
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter.numberFormat = showVolume ? "#a" : "#a";
    if (!showVolume) {
      valueAxis.max = 100;
      valueAxis.renderer.labels.template.adapter.add("text", function (text) {
        return text + "%";
      });
    }
    //@ts-ignore
    this.chart.series.events.on("inited", function (ev) {
      ev.target.each(function (item) {
        //@ts-ignore
        item.zIndex = item.dataItem.dataContext.legendIdx;
        ev.target.moveValue(item, item.zIndex);
      });
    });
    this.chart.legend.events.on("ready", function (ev) {
      ev.target.itemContainers.each(function (item) {
        //@ts-ignore
        item.zIndex = item.dataItem.dataContext.legendIdx;
        ev.target.children.moveValue(item, item.zIndex);
      });
    });
    const createSeries = ({ name, field, seriesId, index }: ISeries) => {
      seriesId = this.chart.series.push(new ColumnSeries());
      columnSeriesConfiguration(seriesId, {
        name: name,
        valueY: field,
        stacked: true,
      });
      seriesId.dataFields.dateX = "date";
      seriesId.columns.template.tooltipText = "{dateX} {name}: {valueY.formatNumber('#')}";
      seriesId.columns.template.width = percent(65);
      seriesId.tooltip = new Tooltip();
      seriesId.tooltip.getFillFromObject = false;
      seriesId.tooltip.autoTextColor = false;
      seriesId.tooltip.background.fill = color(WHITE);
      seriesId.tooltip.label.fill = color(BLACK);
      //@ts-ignore
      seriesId.legendIdx = seriesList.length - (index + 1);
      seriesId.columns.template.adapter.add("tooltipText", (value: any, target: any) => {
        const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };
        const dateVal = `[bold font-size: var(--regular_font_size)]${formatDateAxisLabelToMonthly(dateValue?.date)}[/]\n`;
        let text = "";
        this.chart.series.each((item) => {
          if (!item.isHidden) {
            const value = item.dataFields.valueY && dateValue ? (dateValue[item.dataFields.valueY] as number) : null;
            text =
              `[font-size: var(--regular_font_size) ${item.stroke}]●[/] [font-size: var(--regular_font_size)]${
                item.name
              }: [font-size: var(--regular_font_size)]${
                isNull(value) || isNaN(value) ? "n/a" : showVolume ? formatToThreeDigits(value as number) : `${Math.floor(value)}%`
              }\n` + text;
          }
        });
        return dateVal + text;
      });
    };
    seriesList?.map((series: { name: string; volumeField: string; shareField: string }, ind: number) => {
      return createSeries({
        name: series.name,
        field: showVolume ? series.volumeField : series.shareField,
        seriesId: `series${ind + 1}`,
        index: ind,
      });
    });
  }
  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
      </div>
    );
  }
}
export default FeatureUsageBarStackedChart;
