import {
  SET_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL,
  SET_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL_TABLE,
  SET_EXECUTIVE_SUMMARY_CHANNEL_MIX_PERFORMANCE,
  SET_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_DATE,
  SET_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_MARKET,
  SET_EXECUTIVE_SUMMARY_DETAILED_COMPARISONS,
  SET_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_DATE,
  SET_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_MARKET,
  SET_EXECUTIVE_SUMMARY_DIS_SUMMARY,
  SET_EXECUTIVE_SUMMARY_KEY_INDICATORS,
  SET_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_DATE,
  SET_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_MARKET,
} from "../actions/actionTypes/executiveSummaryActionTypes";

const initialState = {
  dis_summary: {},
  aggregated_purchase_funnel: {},
  aggregated_purchase_funnel_table: {},
  detailed_comparisons: {},
  channel_mix_performance: {},
  private_sales_by_market: {},
  private_sales_by_date: {},
  dis_percentage_by_market: {},
  dis_percentage_by_date: {},
  close_rate_by_market: {},
  close_rate_by_date: {},
  key_indicators: [],
};

const executiveSummaryReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_EXECUTIVE_SUMMARY_DIS_SUMMARY:
      return { ...state, dis_summary: action.payload };

    case SET_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL:
      return { ...state, aggregated_purchase_funnel: action.payload };

    case SET_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL_TABLE:
      return { ...state, aggregated_purchase_funnel_table: action.payload };

    case SET_EXECUTIVE_SUMMARY_DETAILED_COMPARISONS:
      return { ...state, detailed_comparisons: action.payload };

    case SET_EXECUTIVE_SUMMARY_CHANNEL_MIX_PERFORMANCE:
      return { ...state, channel_mix_performance: action.payload };

    case SET_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_MARKET:
      return { ...state, private_sales_by_market: action.payload };

    case SET_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_DATE:
      return { ...state, private_sales_by_date: action.payload };

    case SET_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_MARKET:
      return { ...state, dis_percentage_by_market: action.payload };

    case SET_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_DATE:
      return { ...state, dis_percentage_by_date: action.payload };

    case SET_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_MARKET:
      return { ...state, close_rate_by_market: action.payload };

    case SET_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_DATE:
      return { ...state, close_rate_by_date: action.payload };

    case SET_EXECUTIVE_SUMMARY_KEY_INDICATORS:
      return { ...state, key_indicators: action.payload };

    default:
      return state;
  }
};

export default executiveSummaryReducer;
