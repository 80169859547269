import React, { useEffect, useState } from "react";
import { getPowerBiReportToken } from "../../api";
import { IframeLayout } from "../../components/Layouts";
import { PowerBiContent } from "../../components/PageContent";
import { POWER_BI_URL_MAPPING } from "../../constants";
import { IPowerBiToken } from "../../constants/interface";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";

export const DigitalUserExperienceCoreModels = () => {
  const [powerBiToken, setPowerBiToken] = useState<IPowerBiToken>({
    embed_token: "",
    embed_url: "",
    report_id: "",
    token_id: "",
    token_expiration: "",
    report_name: "",
  });

  useEffect(() => {
    getPowerBiReportToken(POWER_BI_URL_MAPPING.decibel_october).then((token) => setPowerBiToken(token));
  }, []);

  // Usage tracking
  useEffect(
    () => eventTracking(MixpanelEvents.page_view, { dashboard: "Digital User Experience", page: "Digital User Experience - Core Models" }),
    []
  );

  return (
    <IframeLayout hasFilter={false}>
      <PowerBiContent reportId="decibel_core_models" token={powerBiToken} showPagePane={false} />
    </IframeLayout>
  );
};
