export const tooltipData: Record<string, { definitions: Array<{ name: string; definition: string }>; source: string }> = {
  "Website visits": {
    definitions: [{ name: "Website visit", definition: " happens every time the site has at least one page fully loaded. " }],
    source: "Adobe Analytics",
  },
  "Visits by channel": {
    definitions: [
      {
        name: "Paid visits",
        definition:
          " are external marketing efforts that involve: paid media (paid placement as displays, ads, affiliates and programmatic), paid search (ads in search engine results) and paid social media (ads in social media).",
      },
      {
        name: "Organic",
        definition: " includes website visits coming from search engines or from social media activity without a paid promotion.",
      },
      { name: "Direct", definition: " includes visitors that arrive directly on the website, without clicking on a link on another site." },
      {
        name: "Internal traffic",
        definition: " is generated when a user arrives at the page by clicking a link from another page on the Nissan website. ",
      },
      {
        name: "Referral",
        definition:
          " include visitors that arrive on the website from other sites, without searching for in search engines. Referring domains, booked marked and vanity domain are examples of referral visits. ",
      },
      {
        name: "Other",
        definition: " includes other marketing channels as email, mobile app, partnerships and unspecified or specified as other.",
      },
    ],
    source: " Adobe Analytics ",
  },
  "Retail sales": {
    definitions: [
      {
        name: "Retail sales",
        definition: " is the volume of completed sales",
      },
    ],
    source: "sales data sourced from IDEA database. ",
  },
  "Test drive requests": {
    definitions: [{ name: "Test drive requests", definition: " include visitors that requested a test drive on Nissan website. " }],
    source: "Adobe Analytics",
  },
  "Lead visits to test drive requests": {
    definitions: [{ name: "Ratio of leads ", definition: " that requested a test drive on Nissan website." }],
    source: "Adobe Analytics",
  },
  "Lead visits": {
    definitions: [
      {
        name: "Lead visits ",
        definition: " are the count of visits including at least one digital lead as Enquire, Test drive, Request a video and Reserve. ",
      },
    ],
    source: "Adobe Analytics",
  },
  "KBA visits": {
    definitions: [
      {
        name: "KBA or Key Buying Actions visits",
        definition:
          " are the count of visits including at least one key buying action as Model detail compare, Saved searches vies, Compare tools views and Dear search.",
      },
    ],
    source: "Adobe Analytics",
  },
};
