import { Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, makeStyles, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Autocomplete } from "@material-ui/lab";
import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createSeoInsight, updateSeoInsight } from "../../../../../api/adminApi";
import { ErrorMsg } from "../../../../../components/AppMessages";
import { CYAN } from "../../../../../constants";
import { ISeoInsight } from "../../../../../constants/interface/admin/seoInsights";

interface Props {
  open: boolean;
  selectedInsight: ISeoInsight | undefined;
  markets: Array<string>;
  toggleOpen: () => void;
  setInsights: Dispatch<SetStateAction<Array<ISeoInsight>>>;
}

interface IInsightsFormData extends FormData, ISeoInsight {}

export const CreateUpdateDialogue = (props: Props) => {
  const { open, selectedInsight, toggleOpen, setInsights, markets: countries } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ISeoInsight>({
    defaultValues: { ...selectedInsight },
  });
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [years, setYears] = useState<Array<string>>([]);

  const classes = makeStyles({
    button: {
      backgroundColor: CYAN,
      color: "#FFF !important",
      borderRadius: "0px !important",
      border: "none !important",
      marginRight: "10px",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      "&:hover": {
        backgroundColor: "#5ed2ff !important",
        border: "none !important",
      },
    },
  })();

  const submitHandler = useCallback(
    (values: IInsightsFormData) => (selectedInsight?.seo_insights_id ? updateFlashMsg(values) : createFlashMsg(values)),
    [selectedInsight]
  );

  useEffect(() => {
    const start = 2017;
    const current = moment().get("year");
    const availableYears = [];
    for (let i = start; i <= current; i++) {
      availableYears.push(i.toString());
    }

    setYears(availableYears);
  }, []);

  useEffect(() => {}, []);

  const createFlashMsg = (values: IInsightsFormData) => {
    createSeoInsight(values).then((response) => {
      if (response && !("error" in response)) {
        setInsights((prevState) => [...prevState, response]);
        toggleOpen();
      } else {
        toast.error("Creation failed");
      }
    });
  };

  const updateFlashMsg = (values: IInsightsFormData) => {
    updateSeoInsight(values).then((response) => {
      if (response && !("error" in response)) {
        setInsights((prevState) => prevState.map((insight) => (insight.seo_insights_id === response.seo_insights_id ? response : insight)));
        toggleOpen();
      } else {
        toast.error("Update failed");
      }
    });
  };

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <Dialog onClose={toggleOpen} open={open} scroll="body" fullWidth>
        <DialogTitle>{selectedInsight?.seo_insights_id ? "Update entry" : "Create new entry"}</DialogTitle>
        <DialogContent dividers>
          {/* Markets */}
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="country"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      id="country"
                      options={countries}
                      getOptionLabel={(option) => option}
                      getOptionSelected={(option, value) => option === value}
                      value={value}
                      onChange={(_, data) => onChange(data)}
                      renderTags={(value: Array<string>, getTagProps) =>
                        value.map((option, idx) => <Chip key={option} label={option} {...getTagProps({ index: idx })} />)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Market"
                          required
                          error={Boolean(errors.country)}
                          helperText={Boolean(errors?.country) && "This field is required"}
                          InputProps={{ ...params.InputProps, ...{ "data-test-id": "market-group-input" } }}
                        />
                      )}
                      defaultValue={countries[0]}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Month and Year */}
          <Grid container spacing={2}>
            <Grid item md={8}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="month"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      id="month"
                      options={months}
                      getOptionLabel={(option) => option}
                      getOptionSelected={(option, value) => option === value}
                      value={value}
                      onChange={(_, data) => onChange(data)}
                      renderTags={(value: Array<string>, getTagProps) =>
                        value.map((option, idx) => <Chip key={option} label={option} {...getTagProps({ index: idx })} />)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Month"
                          required
                          error={Boolean(errors.month)}
                          helperText={Boolean(errors?.month) && "This field is required"}
                          InputProps={{ ...params.InputProps, ...{ "data-test-id": "month-group-input" } }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="year"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      id="year"
                      options={years}
                      getOptionLabel={(option) => option}
                      getOptionSelected={(option, value) => option === value}
                      value={value}
                      onChange={(_, data) => onChange(data)}
                      renderTags={(value: Array<string>, getTagProps) =>
                        value.map((option, idx) => <Chip key={option} label={option} {...getTagProps({ index: idx })} />)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Year"
                          required
                          error={Boolean(errors.year)}
                          helperText={Boolean(errors?.year) && "This field is required"}
                          InputProps={{ ...params.InputProps, ...{ "data-test-id": "year-group-input" } }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/*Insight */}
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl fullWidth>
                <Controller
                  name="insights"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Insights"
                      id="insights"
                      type="string"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      error={Boolean(errors.insights)}
                      helperText={errors?.insights?.message}
                      required
                      inputProps={{
                        "data-test-id": "release_notes-input",
                      }}
                      multiline
                      maxRows={12}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Show */}
          <Grid container>
            <Grid item md={12}>
              <FormControl>
                <Controller
                  name="show_insights"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      value="show_insights"
                      control={
                        <Checkbox
                          color="primary"
                          checked={!!value}
                          onChange={onChange}
                          inputProps={{
                            id: "valid-checkbox",
                          }}
                        />
                      }
                      label="Show"
                      labelPlacement="start"
                      style={{ margin: 0 }}
                    />
                  )}
                  defaultValue={false}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ justifyContent: `${selectedInsight?.seo_insights_id ? "flex-end" : "space-between"}` }}>
          <Button
            className={classes.button}
            variant="outlined"
            type="submit"
            onClick={handleSubmit(submitHandler)}
            disableElevation
            data-test-id={`${selectedInsight?.seo_insights_id ? "btn-update-release" : "btn-create-release"}`}
          >
            {selectedInsight?.seo_insights_id ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </ErrorBoundary>
  );
};
