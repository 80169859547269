import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { JourneyExcellenceContext } from "../context/JourneyExcellenceContext";

interface Props {
  title: string;
}

const metricMap: Record<string, string> = {
  fmi: "FMI",
  online_journey: "Online Journey",
  leads_management_system: "Leads Management System",
  product_journey: "Projuct Journey",
};

const JourneyExcellenceTitle = (props: Props) => {
  const { title } = props;
  const { metric, setMetric } = useContext(JourneyExcellenceContext);
  const [activeTitle, setActiveTitle] = useState(false);

  useEffect(() => {
    setActiveTitle(metric === title || (metric in metricMap && metricMap[metric] === title));
  }, [metric, title]);

  const updateMetric = useCallback(() => {
    metric === title || (metric in metricMap && metricMap[metric] === title) ? setMetric("") : setMetric(title);
  }, [metric, title]);

  return (
    <div className={`section-title dmeo-row ${activeTitle ? "active" : ""}`} onClick={updateMetric}>
      <p className="title">{title}</p>
      <ArrowCircleRightOutlinedIcon style={{ color: "#00a0dd" }} />
    </div>
  );
};

export default JourneyExcellenceTitle;
