import { Box, Button, Grid } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { setLastDataRefresh } from "../../../../actions";
import {
  fetchAriyaCancellations,
  fetchDepositsDirect,
  fetchDepositsDirect_Last_4_Weeks,
  fetchDepositsEmail,
  fetchDepositsEmail_Last_4_Weeks,
  fetchPreorderCancellationTrendData,
  fetchPreorderCancellationTrendData_Last_4_Weeks,
  fetchPreOrderDepositsChartData,
  fetchPreOrderDepositsData,
  fetchPreorderDrilldownColors,
  fetchPreorderDrilldownVersions,
  fetchVisitsData,
} from "../../../../api/Ariya";
import { DrilldownLayout } from "../../../../components/Layouts/Ariya";
import { IChartData, IMetricTotal, popularState } from "../../../../constants/interface/ariya";
import { ThemeContext } from "../../../../context";
import { eventTracking, MixpanelEvents } from "../../../../utils/userTracking";
import commonStyles from "../../ariya.module.scss";
import { ExperienceCard } from "../../Experience Insight/components/Card";
import { ProgressTable } from "../../Experience Insight/components/Tables";
import { MajorCard } from "../../HandraisersInsights/Cards";
import { getGraphData } from "../../helpers/charts";
import { extractMonitorData, extractProgressTableDataFromData, mergeGraphData } from "../../helpers/dataFunctions";
import { MonitorCard } from "../../Monitor/components/Card";
import { MonitorTrendline } from "../../Monitor/components/Charts";
import { PreordersByCity_Japan } from "../Tables";
import japanStyles from "./japan.module.scss";

export const PreorderInsights_Japan = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const themeContext = useContext(ThemeContext);

  const dispatch = useDispatch();
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.ariya_monitor);

  const [preorderTotals, setPreorderTotals] = useState<IMetricTotal>({ current: null, previous: null });
  const [cancellationsTotals, setCancellationsTotals] = useState<IMetricTotal>({ current: null, previous: null });
  const [preorderChartData, setPreorderChartData] = useState<Array<IChartData>>([]);
  const [cancellationsChartData, setCancellationsChartData] = useState<Array<IChartData>>([]);
  const [mergedChartData, setMergedChartData] = useState<Array<IChartData>>([]);
  const [topVersions, setTopVersions] = useState<popularState>({ records: [] });
  const [topColours, setTopColours] = useState<popularState>({ records: [] });
  const [cancellationReasons, setCancellationReasons] = useState<popularState>({ records: [] });
  // const [depositSplit, setDepositSplit] = useState<IGlobalViewDonutData>([]);

  const pageNavigation = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;

      history.push({ pathname: pageLink, search: "" });
    },
    [history]
  );

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Ariya", page: "Ariya Preorder - Japan" });
  }, []);

  // Last data refresh
  useEffect(() => {
    fetchVisitsData().then((response) => {
      if (response && !("error" in response)) {
        const {
          chart_data: { year, month, day },
        } = extractMonitorData(response);
        dispatch(setLastDataRefresh("ariya_monitor", `${year}-${month}-${day}`));
      }
    });
  }, []);

  // Preorder
  useEffect(() => {
    Promise.all([
      fetchPreOrderDepositsData().then((response) => {
        if (response && !("error" in response)) {
          const {
            report: { data, totals },
          } = response;
          setPreorderTotals({ current: +totals[0], previous: +data[data.length - 1].counts[0] });
        }
      }),
      fetchPreOrderDepositsChartData().then((response) => {
        if (response && !("error" in response)) {
          const {
            report: { data },
          } = response;
          setPreorderChartData(getGraphData(data));
        }
      }),
      fetchPreorderCancellationTrendData_Last_4_Weeks().then((response) => {
        if (response && !("error" in response)) {
          setCancellationsChartData(getGraphData(response.report.data));
        }
      }),
      fetchPreorderCancellationTrendData().then((response) => {
        if (response && !("error" in response)) {
          const data = response.report.data;
          const lastDay = +data[data.length - 1].counts[0];
          setCancellationsTotals((prevState) => ({ ...prevState, previous: lastDay }));
        }
      }),
    ]);
  }, []);

  // Merge chart data
  useEffect(() => {
    setMergedChartData(mergeGraphData(preorderChartData, cancellationsChartData));
  }, [preorderChartData, cancellationsChartData]);

  // Grades data
  useEffect(() => {
    Promise.all([
      fetchPreorderDrilldownVersions(["Japan"]).then((response) => {
        if (response && !("error" in response)) {
          const data = response.report.data
            .filter((row) => !row.name.includes("No version selected"))
            .sort((a, b) => +b.counts[0] - +a.counts[0])
            .slice(0, 5);
          const total = Number(response.report.totals[0]);
          setTopVersions({ records: extractProgressTableDataFromData(data, total) });
        }
      }),
    ]);
  }, []);

  // Colours data
  useEffect(() => {
    Promise.all([
      fetchPreorderDrilldownColors().then((response) => {
        if (response && !("error" in response)) {
          const data = response.report.data.sort((a, b) => +b.counts[0] - +a.counts[0]).slice(0, 5);
          const total = Number(response.report.totals[0]);
          setTopColours({ records: extractProgressTableDataFromData(data, total) });
        }
      }),
    ]);
  }, []);

  // Cancellation reasons
  useEffect(() => {
    fetchAriyaCancellations().then((response) => {
      if (response && !("error" in response)) {
        const data = response.report.data.sort((a, b) => +b.counts[0] - +a.counts[0]);
        const total = +response.report.totals[0];
        setCancellationsTotals((prevState) => ({ ...prevState, current: total }));
        setCancellationReasons({ records: extractProgressTableDataFromData(data, total) });
      }
    });
  }, []);

  // Email vs Direct deposit
  const [depositEmailTotal, setDepositEmailTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [depositEmailChartData, setDepositEmailChartData] = useState<Array<IChartData>>([]);

  const [depositDirectTotal, setDepositDirectTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [depositDirectChartData, setDepositDirectChartData] = useState<Array<IChartData>>([]);
  const [mergedDepositChartData, setMergedDepositChartData] = useState<Array<IChartData>>([]);

  useEffect(() => {
    Promise.all([
      fetchDepositsDirect().then((response) => {
        if (response && !("error" in response)) {
          const data = response.report.data;
          const lastDay = +data[data.length - 1].counts[0];
          const total = +response.report.totals[0];
          setDepositDirectTotal({ current: total, previous: lastDay });
        }
      }),
      fetchDepositsDirect_Last_4_Weeks().then((response) => {
        if (response && !("error" in response)) {
          const data = response.report.data;
          setDepositDirectChartData(getGraphData(data));
        }
      }),
      fetchDepositsEmail().then((response) => {
        if (response && !("error" in response)) {
          const data = response.report.data;
          const lastDay = +data[data.length - 1].counts[0];
          const total = +response.report.totals[0];
          setDepositEmailTotal({ current: total, previous: lastDay });
        }
      }),
      fetchDepositsEmail_Last_4_Weeks().then((response) => {
        if (response && !("error" in response)) {
          const data = response.report.data;
          setDepositEmailChartData(getGraphData(data));
        }
      }),
    ]);
  }, []);

  // Merge chart data
  useEffect(() => {
    setMergedDepositChartData(mergeGraphData(depositEmailChartData, depositDirectChartData));
  }, [depositDirectChartData, depositEmailChartData]);

  return (
    <DrilldownLayout
      flagName={"JP"}
      title="Japan online pre-order insights"
      lastDataRefresh={lastDataRefresh}
      previousPagePath="/ariya/preorder"
    >
      <>
        <Box display={"grid"} gridGap={15} className={japanStyles.topCardContainer}>
          <MonitorCard
            metric={{
              title: "Online pre-orders",
              lastDayValue: preorderTotals.previous,
              value: preorderTotals.current,
              legend: "solid",
            }}
            metric2={{
              title: "Cancellations",
              lastDayValue: cancellationsTotals.previous,
              value: cancellationsTotals.current,
              legend: "dotted",
            }}
            cardTitle={"Pre-orders"}
            chart={
              <MonitorTrendline
                data={mergedChartData}
                chartId={"preorder_japan"}
                series={["preorder", "cancellation"]}
                theme={themeContext.theme}
              />
            }
            hideMore={true}
            spanBy={6}
          />
          <>
            <MonitorCard
              metric={{
                title: "From email (CRM)",
                lastDayValue: null,
                value: depositEmailTotal.current,
                legend: "solid",
              }}
              metric2={{
                title: "Direct online",
                lastDayValue: null,
                value: depositDirectTotal.current,
                legend: "dotted",
              }}
              cardTitle={"Pre-orders with deposit completion"}
              chart={
                <MonitorTrendline
                  data={mergedDepositChartData}
                  chartId={"deposit_japan"}
                  series={["preorder", "cancellation"]}
                  theme={themeContext.theme}
                />
              }
              hideMore={true}
              spanBy={6}
            />
          </>
        </Box>
        <Box display={"grid"} gridGap={15} className={japanStyles.cardContainer}>
          <MajorCard
            id="japan_deposit_insights"
            content1={<ProgressTable id="top_versions" title="Top grades" data={topVersions} />}
            content2={<ProgressTable id="top_colours" title="Top body colours" data={topColours} />}
            content3={<ProgressTable id="cancellation_reasons" title="Top cancellations reasons" data={cancellationReasons} />}
            className={japanStyles.major2}
          />

          <ExperienceCard title="Insights by city" id="handraisers_city" className={japanStyles.major3}>
            <PreordersByCity_Japan />
          </ExperienceCard>
        </Box>

        <Grid container spacing={10} justifyContent="space-evenly">
          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page={"/ariya/monitor"}
              data-test-id="ariyaHome"
            >
              Ariya home
            </Button>
          </Grid>

          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page={"/ariya/experience"}
              data-test-id="ariyaExperience"
            >
              Experience insights
            </Button>
          </Grid>

          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page={"/ariya/handraisers"}
              data-test-id="ariyaHandraisers"
            >
              Hand-raisers insights
            </Button>
          </Grid>
        </Grid>
      </>
    </DrilldownLayout>
  );
});
