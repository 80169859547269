import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { ErrorMsg } from "../../../components/AppMessages";
import MsrpPerModelDifferenceChart from "../../../components/Charts/MSRP/MsrpPerModelDifferenceChart";
import SalesByModelChart from "../../../components/Charts/MSRP/SalesByModelChart";
import { INDIGO, TOP_FIVE_MODEL_COLORS } from "../../../constants";
import { ThemeContext } from "../../../context";

const ModelComparison = () => {
  const themeContext = useContext(ThemeContext);

  const { data: salesByModelData, series: salesByModelSeries } = useSelector((state: RootStateOrAny) => state.msrp.sales_by_model);
  const { data: differenceByModelData, series: differenceByModelSeries } = useSelector(
    (state: RootStateOrAny) => state.msrp.difference_by_model
  );
  const { data: vmeDifferenceByModelData, series: vmeDifferenceByModelSeries } = useSelector(
    (state: RootStateOrAny) => state.msrp.vme_difference_by_model
  );
  const {
    sales_by_model: salesByModelLoading,
    difference_by_model: differenceByModelLoading,
    vme_difference_by_model: VmeDifferenceByModelLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);
  const topFiveModels = useSelector((state: RootStateOrAny) => state.msrp.top_five_models);

  const modelColorsMapping = useMemo(() => {
    const res: Record<string, string> = {};
    topFiveModels.map((model: string, idx: number) => {
      res[`${model}`] = TOP_FIVE_MODEL_COLORS[idx];
    });
    res["Others"] = INDIGO;
    return res;
  }, [topFiveModels]);

  const differenceByModelDataWithColors = useMemo(() => {
    if (differenceByModelData && differenceByModelData.length > 0) {
      return differenceByModelData.map((modelData: Record<string, string>, idx: number) => ({
        ...modelData,
        color: modelColorsMapping[modelData.model],
      }));
    }
    return [];
  }, [differenceByModelData, modelColorsMapping]);

  const vmeDifferenceByModelDataWithColors = useMemo(() => {
    if (vmeDifferenceByModelData && vmeDifferenceByModelData.length > 0) {
      return vmeDifferenceByModelData.map((modelData: Record<string, string>, idx: number) => ({
        ...modelData,
        color: modelColorsMapping[modelData.model],
      }));
    }
    return [];
  }, [vmeDifferenceByModelData, modelColorsMapping]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="mrsp-model-comparison msrp-tile">
        <div className="model-comparison-charts">
          <div className="chart-tile">
            <div className="tile-header">
              Sales by Model
              <Tooltip
                position="top-end"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                distance={50}
                delay={1}
                hideDelay={1}
                duration={1}
                title={"Sales proportion per model"}
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
            </div>
            <SalesByModelChart
              seriesList={salesByModelSeries}
              data={salesByModelData}
              chartName="SalesByModelChart"
              theme={themeContext.theme}
              isLoading={salesByModelLoading}
              colorMapping={modelColorsMapping}
            />
          </div>
          <div className="chart-tile">
            <div className="tile-header">
              MSRP Difference by Model
              <Tooltip
                position="top-end"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                distance={50}
                delay={1}
                hideDelay={1}
                duration={1}
                title={"Difference between MSRP DIS and MSRP Non-DIS"}
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
            </div>
            <MsrpPerModelDifferenceChart
              data={differenceByModelDataWithColors}
              chartName="msrpDifferenceByModelChart"
              theme={themeContext.theme}
              isLoading={differenceByModelLoading}
            />
          </div>
          <div className="chart-tile">
            <div className="tile-header">
              VME Difference by Model
              <Tooltip
                position="top-end"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                distance={50}
                delay={1}
                hideDelay={1}
                duration={1}
                title={"Actual difference between VME DIS and VME Non-DIS percentage discount"}
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
            </div>
            <MsrpPerModelDifferenceChart
              data={vmeDifferenceByModelDataWithColors}
              chartName="vmeDifferenceByModelChart"
              theme={themeContext.theme}
              isLoading={VmeDifferenceByModelLoading}
              isPercentage={true}
            />
          </div>
        </div>
        <div className="model-comparison-legends">
          <div className={`trend-legend`}>
            {differenceByModelSeries?.map((series: Record<string, string>, idx: number) => {
              return (
                <div className="legend-item" key={series.name}>
                  <div className="legend-box" style={{ backgroundColor: modelColorsMapping[series.name] }}></div>
                  {` ${series.name}`}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ModelComparison;
