import React, { useContext, useEffect, useMemo } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchMediaMixDataByQuarter, fetchMidasMediaMixFilters, fetchMidasMediaMixLastDataRefresh, setPageName } from "../../../actions";
import "../../../assets/styles/pages/MidasMediaMix.scss";
import "../../../assets/styles/pages/midasUnderConstruction.scss";
import Collapsible from "../../../components/Collapsible/Collapsible";
import MarketingInvastmentDashboardFilters from "../../../components/Filters/MarketingInvastmentDashboardFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { MIDAS_BTN_FILTERS } from "../../../constants";
import { DashboardBannerContext } from "../../../context";
import { getAvailableYearScenarios, getSortedYrScenarios } from "../helpers";
import MediaMixGraph from "./components/MediaMixGraph";
import MediaMixTables from "./components/MediaMixTables";

const MediaMix = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.midas_fmi_last_refresh_date);
  const { brand, nsc, yr_scnr_rv, mdl_nm_rev, model, fmi_sort, mc_group, subgroup, market } = useSelector(
    (state: RootStateOrAny) => state.parameters
  );
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const filters: any = useSelector((state: RootStateOrAny) => state.filters.midas_filters);

  const pageName = "Media mix";
  const data = useSelector((state: RootStateOrAny) => state.midas.midas_media_mix_data_by_quarter);

  //Endpoints that depend on parameters
  useEffect(() => {
    Promise.all([dispatch(fetchMediaMixDataByQuarter())]);
  }, [parameters]);

  useEffect(() => {
    Promise.all([dispatch(fetchMidasMediaMixFilters())]);
  }, [brand, yr_scnr_rv, nsc, mdl_nm_rev, model, fmi_sort, mc_group, subgroup, market]);

  useEffect(() => {
    Promise.all([
      // Sets page name
      dispatch(setPageName(pageName)),
      dispatch(fetchMidasMediaMixLastDataRefresh()),
    ]);
    setBannerText("Forex Rate based from latest BP");
    setShowBanner(false);
  }, [dispatch]);

  const yrScenarios: string[] = useMemo(() => getAvailableYearScenarios(data?.full_year), [data]);

  const sortedYrScenarios = useMemo(() => getSortedYrScenarios(filters?.yr_scnr_rv, yrScenarios), [yrScenarios, filters?.yr_scnr_rv]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<MarketingInvastmentDashboardFilters filterBtns={MIDAS_BTN_FILTERS} hasModel isCustom />}
        dataDocumentation="midas"
        lastDataRefresh={lastDataRefresh}
        extraClass="media_mix"
        pageTitle={<PageTitle dataDocumentation="midas" />}
      >
        <div className="midas_media_mix_sotu">
          {/* Top two charts / FMI charts */}
          <Collapsible myStyle="pmo_sotu" show={true} title="">
            <div className="media_mix_by_quarter">
              <MediaMixGraph yrScenarios={sortedYrScenarios} />
            </div>
          </Collapsible>
          <div className="tables">
            <MediaMixTables yrScenarios={sortedYrScenarios} />
          </div>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default MediaMix;
