import { put, retry, takeEvery } from "redux-saga/effects";
import { setTagsData } from "../actions";
import { FETCH_TAGS } from "../actions/actionTypes";
import { getTags } from "../api";
import { secondsDelay } from "../api/utils";
import { ITagFilters } from "../constants/interface";

// Watcher Sagas
export function* watchFetchTags() {
  yield takeEvery(FETCH_TAGS, fetchTags);
}

// Worker Sagas
export function* fetchTags() {
  try {
    const data: ITagFilters = yield retry(4, secondsDelay, getTags);
    if (!("error" in data)) yield put(setTagsData(data));
  } catch (err) {
    console.log("Failed to get Model data");
  }
}
