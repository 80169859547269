import React, { useEffect, useState } from "react";
import "../../assets/styles/pages/info.scss";
import { SwitchDocumentation } from "../../components/Documentation/common/SwitchDocumentation";
import { InfoLayout } from "../../components/Layouts";
import { InfoSideMenu } from "../../components/Menu/InfoSideMenu";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";

export const DashboardInfo = (): JSX.Element => {
  const [documentation, setDocumentation] = useState("digital_data");
  const menuItems: Array<{ name: string; docName: string }> = [
    { name: "Online CE performance", docName: "digital_data" },
    { name: "SEO reporting", docName: "seo_data" },
  ];

  // Usage tracking
  useEffect(() => eventTracking(MixpanelEvents.page_view, { dashboard: "INFO", page: "Dashboard Info" }), [documentation]);

  return (
    <InfoLayout
      menu={<InfoSideMenu documentation={documentation} setDocumentation={setDocumentation} menuItems={menuItems} />}
      content={<SwitchDocumentation value={documentation} />}
    />
  );
};
