import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { getSeoInsights } from "../../../api/seoApi";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { sorter } from "../../../utils/utilityFunctions";
import { getMonthsArray } from "../helpers/helpers";

interface InsightsInterface {
  country: string;
  insights_data: any[];
}

interface InsightDataInterface {
  month: string;
  insights: string;
  show: boolean;
}

export const Insights = (): JSX.Element => {
  const [allInsights, setAllInsights] = useState<any>([]);
  const [insights, setInsights] = useState<Array<InsightDataInterface>>([]);
  const [loading, setLoading] = useState(true);

  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.seo_parameters.date_range);
  const marketParamValue = useSelector((state: RootStateOrAny) => state.seo_parameters.market);

  useEffect(() => {
    getSeoInsights().then((response) => {
      if (!("error" in response)) {
        setAllInsights(response.data);
      }
    });
  }, []);

  useEffect(() => {
    //use lodash groupBy function to group the objects using the country name
    setLoading(true);
    const groupedInsights = _.mapValues(_.groupBy(allInsights, "country"), (x) => x.map((y) => _.omit(y, "country")));
    const newInsights = _(groupedInsights)
      .map((value: any, key: any) => ({ country: key, insights_data: value }))
      .value();
    const marketInsights = newInsights?.filter((marketInsight: InsightsInterface) => marketInsight.country === marketParamValue)[0];
    const filteredInsights = marketInsights?.insights_data
      .sort(sorter)
      .filter(
        (insightData: any) =>
          insightData.insights !== "" &&
          insightData.show_insights === 1 &&
          getMonthsArray(dateRangeParamValue).includes(`${insightData.month} ${insightData.year}`)
      );
    setInsights(filteredInsights);
    setLoading(false);
  }, [allInsights, marketParamValue, dateRangeParamValue]);

  return (
    <div className="seo_tile insights-tile">
      <h3>Insights</h3>
      <div className="insights-container">
        {!insights || insights?.length === 0 ? (
          <p className="padded-content">There are no insights for this period</p>
        ) : (
          insights?.map((insight: any) => {
            return (
              <Fragment key={Math.random()}>
                {dateRangeParamValue.includes("month") ? null : (
                  <p className="padded-content">
                    <u>
                      {insight.month} {insight.year}:
                    </u>
                  </p>
                )}
                <div className="insight-content" dangerouslySetInnerHTML={{ __html: insight.insights }} />
              </Fragment>
            );
          })
        )}
      </div>
      <LoadingEllipsis isLoading={loading} />
    </div>
  );
};
