import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg } from "../../../../components/AppMessages";
import { MtpSpendChart } from "../../../../components/Charts/Midas/MtpSpendChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../../context";
import { maxValue } from "../Helpers/helpers";

interface Props {
  seriesList: Array<{ name: string; seriesColor: string }>;
}

const MtpSpendYearGraph = (props: Props) => {
  const themeContext = useContext(ThemeContext);
  const { seriesList } = props;
  const categoryOrder = ["Media", "Strategic", "Fixed"];
  const loading = useSelector((state: RootStateOrAny) => state.loading.midas_mtp_spend);
  const data = useSelector((state: RootStateOrAny) => state.midas.midas_mtp_spend);

  const chartData = useMemo(() => {
    if (data?.full_year?.length)
      return data?.full_year
        ?.map((val: { yr_scnr_rv: string; mtp_category: string; amount: number }) => {
          const result: any = { yr_scnr_rv: val.yr_scnr_rv, mtp_category: val.mtp_category };
          result[`${val.yr_scnr_rv}_amount`] = val.amount;
          return result;
        })
        .sort(
          (a: { mtp_category: string }, b: { mtp_category: string }) =>
            categoryOrder?.indexOf(a.mtp_category) - categoryOrder?.indexOf(b.mtp_category)
        );
    return [];
  }, [data]);

  const { max, min } = useMemo(() => maxValue(data?.full_year), [data]);

  return (
    <div className="mtp_spend_yearly primary_background">
      <div className="main_content ">
        <div className="quarter_breakdown">
          {chartData?.length ? <span className="quarter_title">Full year</span> : null}

          {chartData?.length && !loading ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MtpSpendChart
                minVal={min}
                maxVal={max}
                data={chartData}
                seriesList={seriesList}
                theme={themeContext.theme}
                chartName="mtpSpendChartYearly"
                isLoading={false}
              />
            </ErrorBoundary>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MtpSpendYearGraph;
