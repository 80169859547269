import { ColumnSeries, DateAxis, LabelBullet, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { create } from "@amcharts/amcharts4/core";
import { endOfMonth, endOfQuarter } from "date-fns";
import Cookies from "js-cookie";
import _, { isNull } from "lodash";
import React, { Component, Dispatch, SetStateAction } from "react";
import WebFont from "webfontloader";
import { CYAN, DARK_ACCENT, DARK_PURPLE, LIGHT_CYAN, LIGHT_PURPLE } from "../../../constants";
import { Annotation } from "../../../pages/Admin/types";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import ShareAnnotationModal from "../../Annotations/Dialogs/ShareAnnotationModal";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { createAnnotationTooltips } from "../helpers/annotationTooltips";
import {
  configureMTMColumnSeries,
  configureMTMDateAxis,
  configureMTMLineSeries,
  configureMTMValueAxis,
  formatDateAxisLabelToMonthly,
  formatDateAxisLabelToQuarterly,
  mtmChartConfiguration,
  mtmLegendConfiguration,
} from "../helpers/mtmConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  chartName: string;
  isLoading: boolean;
  isQuarterly: boolean;
  theme: ThemeInt;
  toggleAnnotationsDialog: () => void;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
  marketType: string;
}

interface State {
  shareModalOpen: boolean;
  annotations: Annotation[] | undefined;
}

export class MTMInMarketMediaMlc extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { shareModalOpen: false, annotations: undefined };

    this.toogleShareModal = this.toogleShareModal.bind(this);
  }

  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  toogleShareModal() {
    this.setState({
      shareModalOpen: !this.state.shareModalOpen,
    });
  }

  handleShareAnnotation(annotations: Annotation[]) {
    this.setState({
      annotations: annotations,
    });
    this.toogleShareModal();
  }

  initChart() {
    const {
      data,
      chartName,
      isQuarterly,
      theme,
      toggleAnnotationsDialog,
      setAnnotationStartDate,
      setAnnotationEndDate,
      setAnnotationDefaultMetric,
      marketType,
    } = this.props;
    const userViewsList = Cookies.get("views");

    this.chart = create(chartName, XYChart);
    mtmChartConfiguration(this.chart);
    this.chart.data = data;

    //Add legends
    this.chart.legend = mtmLegendConfiguration("{date}", theme, false);
    this.chart.legend.labels.template.wrap = false;

    //Default x axis to show current date
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureMTMDateAxis(dateAxis, theme, isQuarterly);

    //Y Axis that is used by default
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureMTMValueAxis(valueAxis, theme);
    valueAxis.extraMax = 0.4;

    const secondValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureMTMValueAxis(secondValueAxis, theme);
    secondValueAxis.renderer.opposite = true;

    const createLineSeries = ({
      field,
      color,
      isPercentageShare = false,
      name,
    }: {
      field: string;
      color: string;
      isPercentageShare?: boolean;
      name: string;
    }) =>
      configureMTMLineSeries(this.chart.series.push(new LineSeries()), {
        name,
        valueY: field,
        xAxis: dateAxis,
        yAxis: isPercentageShare ? secondValueAxis : valueAxis,
        color,
      });

    //Function to create the series using the arguments given
    const series = this.chart.series.push(new ColumnSeries());
    // series.dataFields.valueY =onlineKey;
    configureMTMColumnSeries(series, { name: "Online", valueY: "in_market_online_fmi", color: CYAN, stacked: true });
    series.columns.template.adapter.add("tooltipText", (value, target) => {
      const dateValue = target.tooltipDataItem.dataContext as { date: string; [index: string]: string | number };

      let text = `[bold]${
        isQuarterly ? formatDateAxisLabelToQuarterly(dateValue.date) : formatDateAxisLabelToMonthly(dateValue.date)
      }[/]\n`;

      this.chart.series.each((item) => {
        if (!item.isHidden) {
          const value = item.dataFields.valueY ? (dateValue[item.dataFields.valueY] as number) : null;

          text += `[${item.stroke}]●[/] ${item.name}: ${isNull(value) ? "n/a" : formatToThreeDigits(value as number)}\n`;
        }
      });

      return text;
    });
    if (userViewsList?.includes("annotations")) {
      series.columns.template.events.on("hit", (ev: any) => {
        const startDate = new Date(ev.target.dataItem.dateX);
        const endDate = isQuarterly ? endOfQuarter(startDate) : endOfMonth(startDate);
        setAnnotationStartDate(startDate);
        setAnnotationEndDate(endDate);
        setAnnotationDefaultMetric(`${marketType} online media investment`);
        toggleAnnotationsDialog();
      });
    }

    createLineSeries({
      name: "Cost per tier 1 lead",
      field: "cost_per_tier_1_lead",
      color: theme === "light" ? DARK_ACCENT : LIGHT_CYAN,
      isPercentageShare: true,
    });

    const series1 = this.chart.series.push(new ColumnSeries());
    configureMTMColumnSeries(series1, { name: "Offline", valueY: "in_market_offline_fmi", color: DARK_PURPLE, stacked: true });

    series1.columns.template.adapter.add("tooltipText", (value, target) => {
      const dateValue = target.tooltipDataItem.dataContext as { date: string; [index: string]: string | number };

      let text = `[bold]${
        isQuarterly ? formatDateAxisLabelToQuarterly(dateValue.date) : formatDateAxisLabelToMonthly(dateValue.date)
      }[/]\n`;

      this.chart.series.each((item) => {
        if (!item.isHidden) {
          const value = item.dataFields.valueY ? (dateValue[item.dataFields.valueY] as number) : null;

          text += `[${item.stroke}]●[/] ${item.name}: ${isNull(value) ? "n/a" : formatToThreeDigits(value as number)}\n`;
        }
      });
      return text;
    });
    if (userViewsList?.includes("annotations")) {
      series1.columns.template.events.on("hit", (ev: any) => {
        const startDate = new Date(ev.target.dataItem.dateX);
        const endDate = isQuarterly ? endOfQuarter(startDate) : endOfMonth(startDate);
        setAnnotationStartDate(startDate);
        setAnnotationEndDate(endDate);
        setAnnotationDefaultMetric(`${marketType} offline media investment`);
        toggleAnnotationsDialog();
      });
    }

    createLineSeries({
      name: "Cost per 3rd party lead",
      field: "cost_per_third_party_lead",
      color: LIGHT_PURPLE,
      isPercentageShare: true,
    });

    const onlineLabelText = "{in_market_online_fmi}";
    const offlineLabelText = "{in_market_offline_fmi}";

    const labelBullet = series1.bullets.push(new LabelBullet());
    labelBullet.label.text = onlineLabelText;
    labelBullet.locationY = 0;
    labelBullet.locationX = 0.1;
    labelBullet.label.rotation = -80;
    labelBullet.label.horizontalCenter = "left";
    labelBullet.label.verticalCenter = "bottom";
    labelBullet.label.fill = series.stroke;
    labelBullet.label.fontSize = 14;
    labelBullet.label.fontWeight = "bold";
    labelBullet.label.maxWidth = 130;
    labelBullet.label.truncate = false;

    const labelBullet2 = series1.bullets.push(new LabelBullet());
    labelBullet2.label.text = offlineLabelText;
    labelBullet2.locationY = 0;
    labelBullet2.locationX = 0.5;
    labelBullet2.label.maxWidth = 130;
    labelBullet2.label.rotation = -80;
    labelBullet2.label.horizontalCenter = "left";
    labelBullet2.label.verticalCenter = "bottom";
    labelBullet2.label.fill = series1.stroke;
    labelBullet2.label.fontSize = 14;
    labelBullet2.label.fontWeight = "bold";
    labelBullet2.label.truncate = false;

    let annotationBullet = createAnnotationTooltips.bind(this);
    annotationBullet = annotationBullet(series1, "annotations");
    // @ts-ignore
    annotationBullet.adapter.add("disabled", function (disabled: any, target: any) {
      if (!target.dataItem) {
        return disabled;
      }
      if (target.dataItem.dataContext?.annotations) {
        return false;
      } else {
        return true;
      }
    });
    // @ts-ignore
    annotationBullet.dy = -50;

    this.chart.series.each((series) => {
      series.bullets.getIndex(0)?.adapter.add("tooltipText", (value, target) => {
        if (target?.tooltipDataItem) {
          const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

          let text = `[bold]${
            isQuarterly ? formatDateAxisLabelToQuarterly(dateValue?.date) : formatDateAxisLabelToMonthly(dateValue?.date)
          }[/]\n`;

          this.chart.series.each((item) => {
            if (!item.isHidden) {
              const value = item?.dataFields?.valueY && dateValue ? (dateValue[item.dataFields.valueY] as number) : null;

              text += item.name.includes("Cost")
                ? `[${item.stroke}]●[/] ${item.name}: ${isNull(value) ? "n/a" : formatToThreeDigits(Math.round(value as number))}\n`
                : `[${item.stroke}]●[/] ${item.name}: ${isNull(value) ? "n/a" : "{" + item.dataFields.valueY + "}"}\n`;
            }
          });
          return text;
        }
      });
    });
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
        <ShareAnnotationModal
          open={this.state.shareModalOpen}
          handleToogleDialog={this.toogleShareModal}
          annotations={this.state.annotations}
        />
      </div>
    );
  }
}
