import { ErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import BrandPerformanceTrendChart from "../../../components/Charts/BrandPerformance/BrandPerformanceTrendChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import BrandPerformanceTable from "../../../components/Tables/BrandPerformanceTable";
import { performancePages } from "../../../constants";
import { ThemeContext } from "../../../context";
import PmoTooltip from "../../ProductMarketingOptimization/components/common/PmoTooltip";
import CompetitorSelect from "./competitorSelect";

interface Props {
  onHandleRadioClick: any;
  nissanCompetitor: string;
  chartData: any;
  TooltipParagraphs: () => JSX.Element;
  TooltipParagraphAvg: () => JSX.Element;
  tableData: any;
  columns: any;
  trendTitle: string;
  trendChartId: string;
  tableId: string;
  tableTitle: string;
  loadingTrend: boolean;
  loadingTable: boolean;
  percentageChart?: boolean;
  initialSortCol?: any;
  activePage: performancePages;
  diff: number;
  dateTitle: string;
}

const BrandPerformanceGraph = (props: Props) => {
  const { theme } = useContext(ThemeContext);
  const { granularity, date_range, market } = useSelector((state: RootStateOrAny) => state.parameters);

  const {
    onHandleRadioClick,
    nissanCompetitor,
    chartData,
    TooltipParagraphs,
    TooltipParagraphAvg,
    tableData,
    columns,
    trendTitle,
    trendChartId,
    tableId,
    tableTitle,
    loadingTrend,
    loadingTable,
    percentageChart,
    initialSortCol,
    activePage,
    diff,
    dateTitle,
  } = props;

  const topModels = useMemo(() => {
    return chartData?.series?.slice(-10)?.map((series: { name: any }) => series?.name);
  }, [chartData]);

  return (
    <>
      <div className="data_area">
        <div className="primary_background ">
          <div className="header" style={{ paddingTop: "1em", paddingLeft: "1em" }}>
            <div className="title_container">
              <h3>{trendTitle}</h3>
              <PmoTooltip paragraphs={<TooltipParagraphs />} />
              <CompetitorSelect onHandleRadioClick={onHandleRadioClick} nissanCompetitor={nissanCompetitor} />
            </div>
          </div>
          <div className="chart_content">
            {chartData?.data?.length && !loadingTrend ? (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <BrandPerformanceTrendChart
                  theme={theme}
                  chartId={trendChartId}
                  granularity={granularity}
                  seriesList={chartData.series?.map((val: any, idx: number) => ({ ...val, hide: !topModels?.includes(val?.name) }))}
                  data={chartData.data}
                  percentageChart={percentageChart}
                  nissanCompetitor={nissanCompetitor}
                  market={market}
                  diff={diff}
                />
              </ErrorBoundary>
            ) : loadingTrend ? (
              <LoadingEllipsis isLoading={loadingTrend} />
            ) : (
              <NoDataMsg message="No data available" />
            )}
          </div>
        </div>
        <div className="primary_background ">
          <div className="header" style={{ padding: "1.5em" }}>
            <div className="title_container">
              <h3>
                {tableTitle} {dateTitle}
              </h3>
              <PmoTooltip paragraphs={<TooltipParagraphAvg />} />
            </div>
          </div>
          <div className="chart_content brand_perf_table" style={{ margin: "1em" }}>
            {tableData?.length && !loadingTable ? (
              <BrandPerformanceTable
                isLoading={loadingTable}
                columns={columns ?? []}
                tableId={tableId}
                data={tableData}
                initialSortCol={initialSortCol}
              />
            ) : loadingTable ? (
              <LoadingEllipsis isLoading={loadingTrend} />
            ) : (
              <NoDataMsg message="No data available" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandPerformanceGraph;
