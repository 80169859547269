import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { ErrorBoundary } from "@sentry/react";
import { capitalize } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { setPageName, setSpecificParameter } from "../../../actions";
import { fetchCcsAnalysisEnrollmentRateData, fetchCcsAnalysisUserEngagementRateData } from "../../../actions/ccsAnalysisActions";
import { fetchAppAnalysisFeatureUsageData, fetchAppAnalysisWebUsage } from "../../../actions/ccsCockpitActions";
import "../../../assets/styles/pages/ccsAnalysis.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import { ValueCombinedChartNew } from "../../../components/Charts/CCSCockpit";
import FeatureUsageBarStackedChart from "../../../components/Charts/CCSCockpit/FeatureUsageBarStackedChart";
import { CcsCockpitFilters } from "../../../components/Filters";
import { UserEngagementMarketFilter } from "../../../components/Filters/common/UserEngagementMarketFiler";
import { DashboardLayout } from "../../../components/Layouts";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { ThreeToggleSwitch, ToggleSwitch } from "../../../components/Toogle";
import { ALL_COUNTRIES, ALL_OPTION } from "../../../constants";
import { FilterContext, ThemeContext } from "../../../context";
import { isCustomDate, isDigitalPerformanceDate } from "../../../utils/dateFunctions";
import { sortMonthYear } from "../Cockpit/subs/helpers";

const filterBtns = [
  { id: "ccs_vital_signs_btn", name: "See CCS VS", navigateTo: "/ccs_vital_signs/ccs_cockpit" },
  { id: "ccs_ccs_analysis_btn", name: "See app analysis", navigateTo: "/ccs_vital_signs/app_ratings" },
];

const barSeriesList = [
  { name: "Connected cars sold", dataKey: "totalSoldEligible" },
  { name: "Enrolled customers", dataKey: "totalUserEnrollment" },
];

const userEngagementBarSeriesList = [
  { name: "Enrolled customer cumulative", dataKey: "enrolled_customer_cumulative" },
  { name: "Active users", dataKey: "active_users" },
];

const defaultUserEngagementLineSeriesList = [{ name: "User engagement", dataKey: "user_engagement" }];

const lineSeriesList = [{ name: "Enrollment rate", dataKey: "soldEligiblePercentage" }];

export const Analysis = withRouter((props: RouteComponentProps) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { history } = props;

  const ccsAppRatingsLastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.ccs_cockpit);
  const {
    ccs_main_data: enrollmentDataLoading,
    feature_usage: featureUsageDataLoading,
    web_usage: webUsageDataLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);
  const { geography: availableGeographies, user_engagement_geography } = useSelector((state: RootStateOrAny) => state.filters.cockpit_data);
  const {
    date_range: dateRangeParamValue,
    region: regionParamValue,
    market: marketParamValue,
    model: modelParamValue,
    user_engagement_market: userEngagementMarket,
    brand: brandParamValue,
    enrolled_user_market: enrolledUserMarket,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const featureUsageData = useSelector((state: RootStateOrAny) => state.ccs_cockpit.feature_usage);
  const { Current: currentUserEngagement, Previous: previousUserEngagement } = useSelector(
    (state: RootStateOrAny) => state.ccs_analysis.user_engagement_rate_data
  );
  const { current: currentEnrollmentRateData, previous: previousEnrollmentRateData } = useSelector(
    (state: RootStateOrAny) => state.ccs_analysis.enrollment_rate_data
  );
  const webUsageData = useSelector((state: RootStateOrAny) => state.ccs_cockpit.web_usage);
  const [showFeatureUsageVolume, setShowFeatureUsageVolume] = useState(true);
  const [showWebUsageVolume, setShowWebUsageVolume] = useState(true);
  const [serviceType, setServiceType] = useState<Array<string>>(["On Board", "Off Board"]);
  const [triggerType, setTriggerType] = useState<Array<string>>(["Auto", "User"]);
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);
  const [marketFilterParam, setMarketFilterParam] = useState("All");
  const [userEngagementLineSeriesList, setUserEngagementLineSeriesList] = useState(defaultUserEngagementLineSeriesList);

  const [marketList, setMarketList] = useState<Array<string>>([]);

  useEffect(() => {
    if (user_engagement_geography) {
      const markets: Array<string> = [];
      user_engagement_geography.map((market: any) => {
        market.region == "europe" && markets.push(capitalize(market.market));
      });
      setMarketList(markets);
    }
  }, [user_engagement_geography]);

  useEffect(() => {
    dispatch(setSpecificParameter("service_type", serviceType.length > 1 ? "All" : serviceType[0]));
    dispatch(setSpecificParameter("trigger_type", triggerType.length > 1 ? "All" : triggerType[0]));
  }, [serviceType, triggerType]);

  const updateServiceType = (service_type: string) => {
    const list = ["On Board", "Off Board"];
    serviceType.includes(service_type) && serviceType.length > 1
      ? setServiceType(serviceType.filter((service) => service !== service_type))
      : serviceType.includes(service_type) && serviceType.length == 1
      ? setServiceType(list.filter((service) => service !== service_type))
      : setServiceType((serviceType) => [...serviceType, service_type]);
  };

  const updateTriggerType = (service_type: string) => {
    const list = ["Auto", "User"];

    triggerType.includes(service_type) && triggerType.length > 1
      ? setTriggerType(triggerType.filter((service) => service !== service_type))
      : triggerType.includes(service_type) && triggerType.length == 1
      ? setTriggerType(list.filter((service) => service !== service_type))
      : setTriggerType((triggerType) => [...triggerType, service_type]);
  };

  useEffect(() => {
    dispatch(setPageName("CCS analysis"));
  }, []);

  // Reset geography filter if invalid
  useEffect(() => {
    if (availableGeographies && availableGeographies.length) {
      const isRegionValid =
        regionParamValue && availableGeographies.filter((geo: { region: string }) => geo.region === regionParamValue).length !== 0;
      const isMarketValid =
        marketParamValue && availableGeographies.filter((geo: { market: string }) => geo.market === marketParamValue).length !== 0;
      !isMarketValid && dispatch(setSpecificParameter("market", ALL_COUNTRIES));
      !isRegionValid &&
        Promise.all([dispatch(setSpecificParameter("region", ALL_OPTION)), dispatch(setSpecificParameter("market", ALL_COUNTRIES))]);
    }
  }, [regionParamValue, marketParamValue, availableGeographies]);

  // Fetching main data on filter change
  useEffect(() => {
    (!isDigitalPerformanceDate(dateRangeParamValue) || !isCustomDate(dateRangeParamValue)) &&
      setTimeout(
        // ensures all parameters are set in history search object
        () =>
          Promise.all([
            dispatch(fetchCcsAnalysisEnrollmentRateData()),
            dispatch(fetchAppAnalysisFeatureUsageData()),
            dispatch(fetchAppAnalysisWebUsage()),
          ]),
        100
      );
  }, [regionParamValue, brandParamValue, modelParamValue, marketParamValue, dateRangeParamValue, triggerType, serviceType]);

  useEffect(() => {
    (!isDigitalPerformanceDate(dateRangeParamValue) || !isCustomDate(dateRangeParamValue)) &&
      setTimeout(
        // ensures all parameters are set in history search object
        () => dispatch(fetchCcsAnalysisUserEngagementRateData(userEngagementMarket)),

        100
      );
  }, [regionParamValue, brandParamValue, modelParamValue, dateRangeParamValue, userEngagementMarket]);

  useEffect(() => {
    if (regionParamValue == "Europe" && userEngagementMarket == "G8") {
      setUserEngagementLineSeriesList([...userEngagementLineSeriesList, { name: "BP target", dataKey: "bp_target" }]);
    } else {
      setUserEngagementLineSeriesList([...defaultUserEngagementLineSeriesList]);
    }
  }, [regionParamValue, userEngagementMarket]);

  useEffect(() => {
    if (regionParamValue != "Europe" && userEngagementMarket != "All") {
      dispatch(setSpecificParameter("user_engagement_market", "All"));
    }
  }, [regionParamValue]);

  // Chart Data
  const groupedChartData = useMemo(() => {
    const activeDates = dateRangeParamValue.split(",");

    return sortMonthYear(activeDates).map((activeDate) => {
      const defaultValues = {
        soldEligiblePercentage: null,
        totalAppDownloads: null,
        totalConnectivityReady: null,
        totalProducesEligible: null,
        totalSoldEligible: null,
        totalUserEnrollment: null,
      };

      const label = moment(activeDate, "MMMM YYYY").format("MMM YY");
      const data = currentEnrollmentRateData?.monthly_user_engagement
        ?.filter((row: any) => moment(`${row.month} ${row.year}`, "M YYYY").format("MMM YY") === label)
        .map((row: any) => {
          const { enrolled_users, connected_cars, sold_eligible, month, year } = row;
          const month_year = moment(`${month}-${year}`, "M-YYYY").format("MMM YY");
          const totalUserEnrollment = enrolled_users;
          const totalSoldEligible = sold_eligible;

          const soldEligiblePercentage = connected_cars * 100;

          return {
            label: month_year,
            totalUserEnrollment,
            totalSoldEligible,
            soldEligiblePercentage,
          };
        });

      return data?.length ? { ...data[0] } : { label, ...defaultValues };
    });
  }, [currentEnrollmentRateData, dateRangeParamValue]);

  const activeUserRateChartData = useMemo(() => {
    const data = currentUserEngagement;
    return data
      ? data.map((item: any) => {
          return {
            ...item,
            label: moment(item.date, "YYYY-MM-DD").format("MMM YY"),
            active_users: item.active_user_avg ? item.active_user_avg : null,
            user_engagement: item.user_engagement_avg ? item.user_engagement_avg : null,
            bp_target: item.bp_user_engagement ? item.bp_user_engagement * 100 : null,
          };
        })
      : [];
  }, [currentUserEngagement]);

  const featureUsageChartData = useMemo(() => {
    const activeDates = dateRangeParamValue.split(",");

    return sortMonthYear(activeDates).map((activeDate) => {
      const label = moment(activeDate, "MMMM YYYY").format("YYYY-MM-DD");
      const result: any = { date: label };
      featureUsageData?.data
        ?.filter((row: any) => label == row.date)
        .map((row: any) => {
          const key = row.service
            .replace(/[^a-zA-Z]/g, "")
            .replace(/\s+/g, "_")
            .toLowerCase();
          result[`${key}_volume`] = row.volume;
          result[`${key}_share`] = row.share;
        });

      return result;
    });
  }, [featureUsageData]);

  const featureUsageSeriesList = useMemo(() => {
    const services: Set<string> = new Set(featureUsageData?.data?.map((row: any) => row.service));

    return Array.from(services)
      .map((service) => {
        const key = service
          .replace(/[^a-zA-Z]/g, "")
          .replace(/\s+/g, "_")
          .toLowerCase();
        return { name: service, volumeField: `${key}_volume`, shareField: `${key}_share` };
      })
      .sort((a, b) => featureUsageData?.order?.indexOf(a.name) - featureUsageData?.order?.indexOf(b.name))
      .reverse();
  }, [featureUsageData]);

  const featureDataCheck = useMemo(() => {
    const score: string[] = [];
    featureUsageChartData.forEach((myfeat: any) => {
      const d = new Date(myfeat.date);
      const year = d.getFullYear();
      year == 2020 ? score.push("true") : score.push("false");
    });
    return score;
  }, [featureUsageChartData]);

  const webUsageChartData = useMemo(() => {
    const activeDates = dateRangeParamValue.split(",");

    return sortMonthYear(activeDates).map((activeDate) => {
      const label = moment(activeDate, "MMMM YYYY").format("YYYY-MM-DD");
      const result: any = { date: label };
      webUsageData?.data
        ?.filter((row: any) => label == row.date)
        .map((row: any) => {
          const key = row.page
            .replace(/[^a-zA-Z]/g, "")
            .replace(/\s+/g, "_")
            .toLowerCase();
          result[`${key}_volume`] = row.volume;
          result[`${key}_share`] = row.share;
        });

      return result;
    });
  }, [webUsageData]);

  const webUsageSeriesList = useMemo(() => {
    const pages: Set<string> = new Set(webUsageData?.data?.map((row: any) => row.page));

    return Array.from(pages)
      .map((page) => {
        const key = page
          .replace(/[^a-zA-Z]/g, "")
          .replace(/\s+/g, "_")
          .toLowerCase();
        return { name: page, volumeField: `${key}_volume`, shareField: `${key}_share` };
      })
      .sort((a, b) => webUsageData?.order?.indexOf(a.name) - webUsageData?.order?.indexOf(b.name))
      .reverse();
  }, [webUsageData]);

  console.log(webUsageChartData);
  console.log(webUsageSeriesList);

  const webDataCheck = useMemo(() => {
    const score: string[] = [];
    webUsageChartData.forEach((myfeat) => {
      const d = new Date(myfeat.date);
      const year = d.getFullYear();
      year == 2020 ? score.push("true") : score.push("false");
    });
    return score;
  }, [webUsageChartData]);

  const marketFilterValue = useMemo(() => {
    let value = "All";
    if (marketList && userEngagementMarket) {
      const selectedLeadsArr = userEngagementMarket.split(",");
      value =
        selectedLeadsArr?.length === marketList.length
          ? "All"
          : selectedLeadsArr?.length > 1
          ? `${selectedLeadsArr?.length} markets selected`
          : userEngagementMarket;
    }
    return value;
  }, [marketList, userEngagementMarket]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<CcsCockpitFilters filterBtns={filterBtns} />}
        dataDocumentation={"ccs_data"}
        lastDataRefresh={ccsAppRatingsLastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="ccs_data" />}
      >
        <div className="ccs_analysis">
          <div className="enrollment_rate ratings_tile">
            <div className="ratings_tile_header">Enrollment rate</div>
            {enrollmentDataLoading ? (
              <LoadingEllipsis isLoading={enrollmentDataLoading} />
            ) : (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <ValueCombinedChartNew
                  chartId="CssAnalysisEnrollmentChart"
                  theme={themeContext.theme}
                  barSeriesList={barSeriesList}
                  lineSeriesList={lineSeriesList}
                  data={groupedChartData}
                />
              </ErrorBoundary>
            )}
          </div>

          <div className="active_user_rate ratings_tile">
            <div className="ratings_tile_header">
              <div className="ratings_tile_header_text">
                <p>User engagement rate</p>
              </div>
              {regionParamValue == "Europe" && (
                <div className="chartFilterContainer">
                  <ErrorBoundary fallback={<ErrorMsg />}>
                    <UserEngagementMarketFilter
                      filterValue={marketFilterValue}
                      marketsList={marketList}
                      marketGroupsList={["G8"]}
                      marketParamValue={userEngagementMarket}
                      parameterName="user_engagement_market"
                    />
                  </ErrorBoundary>
                </div>
              )}
            </div>
            {enrollmentDataLoading ? (
              <LoadingEllipsis isLoading={enrollmentDataLoading} />
            ) : (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <ValueCombinedChartNew
                  chartId="CssAnalysisActiveUserRateChart"
                  theme={themeContext.theme}
                  barSeriesList={userEngagementBarSeriesList}
                  lineSeriesList={userEngagementLineSeriesList}
                  data={activeUserRateChartData}
                />
              </ErrorBoundary>
            )}
          </div>

          <div className="feature_usage ratings_tile">
            <div className="ratings_tile_header">
              <div className="ratings_tile_header_text">
                <p>Connected car services usage</p>
              </div>
              <div className="ratings_tile_header_toggles">
                <ThreeToggleSwitch
                  firstElement={"User"}
                  secondElement={"Auto"}
                  handleToggleClick={updateTriggerType}
                  activeSelection={triggerType}
                  toggleTitle={"Trigger:"}
                />

                <ThreeToggleSwitch
                  firstElement={"On Board"}
                  firstLabel={"On board"}
                  secondLabel={"Off board"}
                  secondElement={"Off Board"}
                  handleToggleClick={updateServiceType}
                  activeSelection={serviceType}
                  toggleTitle={"On/Off board:"}
                />

                <ToggleSwitch
                  activeToggleLabel="Volume"
                  inactiveToggleLabel="Share"
                  active={showFeatureUsageVolume}
                  handleToggleClick={() => setShowFeatureUsageVolume(!showFeatureUsageVolume)}
                />
              </div>
              <Link className="dashboard-link" to="/ccs_vital_signs/top_flop">
                <ArrowCircleRightOutlinedIcon style={{ color: "#00a0dd" }} />
              </Link>
            </div>
            {featureUsageDataLoading ? (
              <LoadingEllipsis isLoading={featureUsageDataLoading} />
            ) : featureDataCheck.includes("false") ? (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <FeatureUsageBarStackedChart
                  chartName="CssAnalysisFeatureUsageChart"
                  theme={themeContext.theme}
                  seriesList={featureUsageSeriesList}
                  data={featureUsageChartData}
                  showVolume={showFeatureUsageVolume}
                />
              </ErrorBoundary>
            ) : (
              <div className="message">
                <h3>2020 data is not available.</h3>
              </div>
            )}
          </div>
          <div className="web_page_views ratings_tile">
            <div className="ratings_tile_header">
              <div className="ratings_tile_header_text">
                <p>Website visits trend by webpage</p>
                <Tooltip
                  position="top-end"
                  className={"normal_tippy"}
                  trigger="mouseenter"
                  animation="none"
                  interactive={true}
                  distance={50}
                  delay={1}
                  hideDelay={1}
                  duration={1}
                  title={"Website visits by webpage is the number of visits to a Nissan webpage for a specific feature."}
                  size={"small"}
                >
                  <span className="info_tooltip" />
                </Tooltip>
              </div>
              <div className="ratings_tile_header_toggles">
                <ToggleSwitch
                  activeToggleLabel="Volume"
                  inactiveToggleLabel="Share"
                  active={showWebUsageVolume}
                  handleToggleClick={() => setShowWebUsageVolume(!showWebUsageVolume)}
                />
              </div>
            </div>

            {webUsageDataLoading ? (
              <LoadingEllipsis isLoading={webUsageDataLoading} />
            ) : (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <FeatureUsageBarStackedChart
                  chartName="CssAnalysisWebUsagChart"
                  theme={themeContext.theme}
                  seriesList={webUsageSeriesList}
                  data={webUsageChartData}
                  showVolume={showWebUsageVolume}
                />
              </ErrorBoundary>
            )}
          </div>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});
