export const FETCH_TOP_FLOP_FILTERS = "FETCH_TOP_FLOP_FILTERS";
export const SET_TOP_FLOP_FILTERS = "SET_TOP_FLOP_FILTERS";

export const FETCH_TOP_FLOP_SERVICES = "FETCH_TOP_FLOP_SERVICES";
export const SET_TOP_FLOP_SERVICES = "SET_TOP_FLOP_SERVICES";

export const FETCH_TOP_SERVICES = "FETCH_TOP_SERVICES";
export const SET_TOP_SERVICES = "SET_TOP_SERVICES";

export const FETCH_FLOP_SERVICES = "FETCH_FLOP_SERVICES";
export const SET_FLOP_SERVICES = "SET_FLOP_SERVICES";

export const FETCH_TOP_MODELS = "FETCH_TOP_MODELS";
export const SET_TOP_MODELS = "SET_TOP_MODELS";

export const FETCH_TOP_REGIONS = "FETCH_TOP_REGIONS";
export const SET_TOP_REGIONS = "SET_TOP_REGIONS";

export const FETCH_SERVICE_GROUPS = "FETCH_SERVICE_GROUPS";
export const SET_SERVICE_GROUPS = "SET_SERVICE_GROUPS";
