import { CategoryAxis, LabelBullet, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { BLACK, PMO_CHART_COLORS, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureCategoryAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  data: any;
  theme: ThemeInt;
  seriesList: Array<{ name: string; seriesColor: string }>;
  isLoading: boolean;
  maxAmount: number;
  minAmount: number;
}

class CumulativeTrendLineChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chartId = this.props.chartId;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      setTimeout(() => this.initChart(), 1500);
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme, seriesList, maxAmount, minAmount } = this.props;
    // Global chart configuration
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    this.chart.data = data;
    this.chart.colors.list = PMO_CHART_COLORS.map((chartColor) => color(chartColor));

    // Create data chart axes
    const dateAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(dateAxis, theme);
    dateAxis.renderer.cellStartLocation = 0.5;
    dateAxis.renderer.cellEndLocation = 0.8;
    dateAxis.dataFields.category = "quarter";
    dateAxis.renderer.minGridDistance = 20;

    // 2nd date axis for comparison data
    const dateAxis2 = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(dateAxis2, theme);
    dateAxis2.renderer.labels.template.disabled = true;
    dateAxis2.renderer.cellStartLocation = 0.2;
    dateAxis2.renderer.cellEndLocation = 0.5;
    dateAxis2.dataFields.category = "quarter";
    dateAxis2.renderer.minGridDistance = 20;

    dateAxis.renderer.labels.template.adapter.add("textOutput", function (text) {
      return `Q${text?.charAt(0)}`;
    });
    dateAxis.renderer.labels.template.fontSize = "13px";
    dateAxis2.renderer.labels.template.fontSize = "13px";
    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.extraMax = 0.12;
    valueAxis.min = minAmount < 0 ? minAmount : 0;
    if (minAmount >= 0) valueAxis.min = 0;
    if (maxAmount <= 0) {
      valueAxis.max = 0;
      valueAxis.extraMax = 0;
    }
    if (minAmount < 0) valueAxis.extraMin = 0.2;
    if (minAmount >= 0) valueAxis.extraMin = 0.09;
    valueAxis.numberFormatter.numberFormat = "#.0a";
    valueAxis.renderer.labels.template.fontSize = "13px";
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.minGridDistance = 5;
    function createGrid(value: number) {
      const range = valueAxis.axisRanges.create();
      range.value = value;
      range.label.text = "{value}";
    }

    createGrid(0);
    if (maxAmount > 0) {
      const numStr = maxAmount.toFixed(0).toString();
      const newNum =
        Number(numStr.charAt(1)) >= 5
          ? Number(
              Number(numStr.substring(0, 1) + numStr.slice(1).replace(/[0-9]/g, "0")) + Number(1 + numStr.slice(1).replace(/[0-9]/g, "0"))
            )
          : Number(
              Number(numStr.substring(0, 2) + numStr.slice(2).replace(/[0-9]/g, "0")) + Number(1 + numStr.slice(2).replace(/[0-9]/g, "0"))
            );

      if (minAmount > 0) {
        createGrid(newNum / 2);
      } else {
        createGrid(minAmount);
      }
      createGrid(newNum);
    } else {
      createGrid(minAmount);
    }

    seriesList?.forEach(({ name, seriesColor }, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.data = data;
      const bullet = lineSeriesConfiguration(series, {
        valueY: `${name}_cumulative_amount`,
        name: name.replace(new RegExp("_", "g"), " "),
      });
      series.xAxis = idx === 0 ? dateAxis2 : dateAxis;
      series.dataFields.dateX = "quarter";
      series.dataFields.valueX = `${seriesList[1]?.name}_cumulative_amount`;
      series.fill = color(seriesColor);
      series.stroke = color(seriesColor);
      series.strokeOpacity = name?.includes("highest") || name.includes("lowest") ? 0 : 1;
      bullet.fillOpacity = name?.includes("highest") || name.includes("lowest") ? 0 : 1;
      bullet.strokeOpacity = name?.includes("highest") || name.includes("lowest") ? 0 : 1;
      series.dataFields.categoryX = "quarter";

      if (name?.includes("highest") || name?.includes("lowest") || seriesList?.length == 3) {
        const labelBullet = series.bullets.push(new LabelBullet());
        labelBullet.label.text = `{valueY.formatNumber("#.0a")}`;
        labelBullet.label.truncate = false;
        labelBullet.label.fill = color(this.props.theme === "light" ? BLACK : WHITE);
        labelBullet.locationX = idx == 0 ? -2 : 2;

        labelBullet.label.adapter.add("verticalCenter", function () {
          return name?.includes("highest") ? "bottom" : "top";
        });
        labelBullet.label.fontSize = "12px";
      }
    });
  }
  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default CumulativeTrendLineChart;
