import { Button, FormControlLabel, makeStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { ErrorBoundary } from "@sentry/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { postDataV2, updateDataV2 } from "../../../../../api/utils";
import { ErrorMsg } from "../../../../../components/AppMessages";
import { businessFunctionsOptions, CYAN, __local__ } from "../../../../../constants";
import { User } from "../../../../../constants/interface/admin/usersData";

interface Props {
  open: boolean;
  toggleOpen: () => void;
  setUsers: Dispatch<SetStateAction<Array<any>>>;
  selectedUser: User | undefined;
  userGroups: any[];
  userViews: any[];
  userDepartments: any[];
}

type FormData = {
  email: string;
  first_name: string;
  last_name: string;
  display_name: string;
  groups: Array<{ id: number; name: string }>;
  view_groups: Array<{ id: number; name: string }>;
  department: string;
  language: string;
  business_function: string;
  role: string;
  on_ga: boolean;
  preferred_url: string;
  account_status: string;
  other_department?: string;
};

const filter = createFilterOptions<string>();

const MAINUSERVIEWS = ["STANDARD", "BETA", "ADMIN"];

const UserFormDialog = (props: Props) => {
  const { open, toggleOpen, setUsers, selectedUser, userGroups, userViews, userDepartments } = props;
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState<boolean>(false);
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      ...selectedUser,
      groups: selectedUser?.groups ?? [],
      view_groups: selectedUser?.views ?? [],
      on_ga: selectedUser?.on_ga ?? true,
    },
  });

  const watchDepartment = watch("department");

  const classes = makeStyles({
    button: {
      backgroundColor: CYAN,
      color: "#FFF !important",
      borderRadius: "0px !important",
      border: "none !important",
      marginRight: "10px",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      "&:hover": {
        backgroundColor: "#5ed2ff !important",
        border: "none !important",
      },
    },
  })();

  const submitHandler = (values: FormData) => {
    values.email = values.email.trim();
    if (selectedUser?.id) {
      const endpoint = `/user_service/update-userdata${
        __local__ ? "/" + selectedUser.id : "?email=" + selectedUser.email.replace(/[+]/g, "%2B")
      }`;
      updateDataV2(endpoint, values).then((res) => {
        if (!res.error) {
          setUsers((prevState) =>
            prevState.map((user) => {
              if (user.id !== res.id) {
                return user;
              }
              return res;
            })
          );
          toggleOpen();
        } else {
          toast.error(res.error);
          console.log(res.error);
        }
      });
    } else {
      postDataV2(`/user_service/create?domain=${window.location.origin}&send_email_now=${sendWelcomeEmail}`, values).then((res) => {
        if (!res.error) {
          setUsers((prevState) => [...prevState, res]);
          toggleOpen();
        } else {
          toast.error(res.error);
          console.log(res.error);
        }
      });
    }
  };

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <Dialog onClose={toggleOpen} open={open} scroll="body" fullWidth>
        <DialogTitle>{selectedUser?.id ? "Update entry" : "Create new entry"}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl fullWidth>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Email"
                      id="user-email"
                      type="string"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      error={Boolean(errors.email)}
                      helperText={errors?.email?.message}
                      required
                      inputProps={{
                        "data-test-id": "user-email-input",
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6}>
              <FormControl fullWidth>
                <Controller
                  name="first_name"
                  control={control}
                  rules={{
                    required: {
                      value: selectedUser == undefined ? true : false,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="First Name"
                      id="user-first-name"
                      type="string"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      error={Boolean(errors.first_name)}
                      helperText={errors?.first_name?.message}
                      inputProps={{
                        "data-test-id": "user-first-name-input",
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth>
                <Controller
                  name="last_name"
                  control={control}
                  rules={{
                    required: {
                      value: selectedUser == undefined ? true : false,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Last Name"
                      id="user-last-name"
                      type="string"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      error={Boolean(errors.last_name)}
                      helperText={errors?.last_name?.message}
                      inputProps={{
                        "data-test-id": "user-last-name-input",
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl fullWidth>
                <Controller
                  name="display_name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Display Name"
                      id="user-display-name"
                      type="string"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      inputProps={{
                        "data-test-id": "user-display-name-input",
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="groups"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      multiple
                      id="user-group-select"
                      options={userGroups.sort((a, b) => (a.name > b.name ? 1 : -1))}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => option.id == value.id}
                      value={value}
                      onChange={(_, data) => onChange(data)}
                      renderTags={(value: Array<{ id: number; name: string }>, getTagProps) =>
                        value.map((option: { id: number; name: string }, index: number) => (
                          <Chip key={index} label={option.name} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Geo Scope"
                          required
                          error={Boolean(errors.groups)}
                          helperText={Boolean(errors?.groups) && "This field is required"}
                          InputProps={{ ...params.InputProps, ...{ "data-test-id": "user-group-input" } }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="view_groups"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      multiple
                      id="user-view-select"
                      options={userViews}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => option.id == value.id}
                      value={value}
                      onChange={(_, data) => onChange(data)}
                      renderTags={(value: Array<{ id: number; name: string }>, getTagProps) =>
                        value.map((option: { id: number; name: string }, index: number) => (
                          <Chip key={index} label={option.name} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="User View"
                          required
                          error={Boolean(errors.view_groups)}
                          helperText={
                            // @ts-ignore
                            errors?.view_groups?.type === "containsOneMainView"
                              ? "User view must contain 'STANDARD', 'ADMIN' or 'BETA'"
                              : // @ts-ignore
                              errors?.view_groups?.type === "containsMoreThanOneMainViews"
                              ? "User view can only contain one of the following 'STANDARD', 'ADMIN' or 'BETA'"
                              : Boolean(errors?.view_groups) && "This field is required"
                          }
                          InputProps={{ ...params.InputProps, ...{ "data-test-id": "user-view-input" } }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl fullWidth>
                <Controller
                  name="preferred_url"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Preferred Url"
                      id="user-preferred-url"
                      type="string"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      inputProps={{
                        "data-test-id": "user-preferred-url-input",
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6}>
              <FormControl fullWidth>
                <Controller
                  name="business_function"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      value={value}
                      options={businessFunctionsOptions}
                      onChange={(event, newValue) => onChange(newValue)}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== "") {
                          filtered.push(`${params.inputValue}`);
                        }

                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Business Function"
                          variant="outlined"
                          required
                          error={Boolean(errors.business_function)}
                          helperText={Boolean(errors?.business_function) && "This field is required"}
                          InputProps={{ ...params.InputProps, ...{ "data-test-id": "user-business-function-input" } }}
                        />
                      )}
                      freeSolo
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="department"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      id="department-select"
                      options={userDepartments}
                      value={value}
                      onChange={(_, data) => onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Department"
                          required
                          error={Boolean(errors.department)}
                          helperText={Boolean(errors?.department) && "This field is required"}
                          InputProps={{ ...params.InputProps, ...{ "data-test-id": "department-input" } }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6}>
              <FormControl fullWidth>
                <Controller
                  name="role"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Role"
                      id="user-role"
                      type="string"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      inputProps={{
                        "data-test-id": "user-role-input",
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>

            {watchDepartment == "Other" && (
              <Grid item md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="other_department"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="Other department"
                        id="other-department"
                        type="string"
                        value={value}
                        onChange={onChange}
                        variant="outlined"
                        inputProps={{
                          "data-test-id": "other-department-input",
                        }}
                        error={Boolean(errors.other_department)}
                        helperText={Boolean(errors?.other_department) && "This field is required"}
                        required
                      />
                    )}
                    defaultValue=""
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>

          <Grid container>
            <Grid item md={12}>
              <FormControl>
                <Controller
                  name="on_ga"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      value="on_ga"
                      control={
                        <Checkbox
                          color="primary"
                          checked={value}
                          onChange={onChange}
                          inputProps={{
                            id: "user-on-ga-checkbox",
                          }}
                        />
                      }
                      label="On GA"
                      labelPlacement="start"
                      style={{ margin: 0 }}
                    />
                  )}
                  defaultValue={false}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ justifyContent: `${selectedUser?.id ? "flex-end" : "space-between"}` }}>
          {!selectedUser?.id && (
            <FormControl>
              <FormControlLabel
                value="send_welcome_email"
                control={
                  <Checkbox
                    color="primary"
                    checked={sendWelcomeEmail}
                    onChange={(e) => setSendWelcomeEmail(e.target.checked)}
                    inputProps={{
                      id: "user-send-welcome-email-checkbox",
                    }}
                  />
                }
                label="Send Welcome Email"
                labelPlacement="start"
              />
            </FormControl>
          )}
          <Button
            className={classes.button}
            variant="outlined"
            type="submit"
            onClick={handleSubmit(submitHandler)}
            disableElevation
            data-test-id={`${selectedUser?.id ? "btn-update-user" : "btn-create-user"}`}
          >
            {selectedUser?.id ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </ErrorBoundary>
  );
};

export default UserFormDialog;
