import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter, Sprite } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { BLACK, CCS_ANALYSIS_COLORS, MEDIA_MIX_CHART_COLORS, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, legendConfiguration, lineSeriesConfiguration } from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string | null>>;
  seriesList: Array<{ name: string; field: string }>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
  isRatio?: boolean;
  seriesColors: Record<string, string>;
  isLeads?: boolean;
  isVisits?: boolean;
}

class MarketPerformanceSummaryLineChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme, isRatio, seriesColors, isLeads, isVisits } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    if (isRatio) {
      this.chart.numberFormatter.numberFormat = "#";
    } else {
      this.chart.numberFormatter.numberFormat = "#.0a";
    }
    this.chart.colors.list = MEDIA_MIX_CHART_COLORS.map((colorStr: string) => color(colorStr));
    this.chart.paddingLeft = 0;
    this.chart.paddingRight = 0;

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.marginBottom = 15;
    this.chart.legend.labels.template.fontSize = "0.9rem";

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme, true);
    dateAxis.renderer.minGridDistance = 30;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter = new NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#a";

    const crValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(crValueAxis, theme);
    crValueAxis.numberFormatter = new NumberFormatter();
    crValueAxis.numberFormatter.numberFormat = "#a";
    crValueAxis.renderer.opposite = true;
    crValueAxis.syncWithAxis = valueAxis;

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    seriesList.forEach(({ name, field }, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.dataFields.dateX = "date";
      if (seriesColors) series.fill = color(seriesColors[name]);
      if (seriesColors) series.stroke = color(seriesColors[name]);

      const circleBullet = lineSeriesConfiguration(series, {
        valueY: field,
        name: name,
        yAxis: isVisits && field == "kbas" || isLeads && field == "leads" ? crValueAxis : field == "cr" ? crValueAxis : valueAxis,
      });
      series.numberFormatter = new NumberFormatter();
      series.numberFormatter.numberFormat = "###,###";
      circleBullet.tooltipText = `[bold font-size: var(--regular_font_size)]{dateX.formatDate('MMM')}: [bold font-size: var(--regular_font_size)] {valueY.numberFormat('#')}${field == "cr" ? "%" : ""
        }`;
      if (series.tooltip) {
        series.tooltip.getFillFromObject = false;
        series.tooltip.autoTextColor = false;
        series.tooltip.background.fill = color(WHITE);
        series.tooltip.label.fill = color(BLACK);
      }
    });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default MarketPerformanceSummaryLineChart;
