// Todo: Add missing dates for last data refresh alert
import { Box, Button, Grid } from "@material-ui/core";
import { format, parse } from "date-fns";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { setAriyaDepositTotals, setAriyaHandraisers, setAriyaPreOrder, setAriyaVisits, setLastDataRefresh } from "../../../actions";
import {
  fetchPreorderData,
  fetchPreorderData_Belgium,
  fetchPreorderData_Canada,
  fetchPreorderData_France,
  fetchPreorderData_Germany,
  fetchPreorderData_Italy,
  fetchPreorderData_Netherlands,
  fetchPreorderData_Sweden,
  fetchPreorderData_UK,
  fetchPreorderData_USA,
} from "../../../api/Ariya";
import {
  fetchHandraisersData,
  fetchPreOrderDepositsChartData,
  fetchPreOrderDepositsData,
  fetchVisitsData,
} from "../../../api/Ariya/monitor";
import { HomeLayout } from "../../../components/Layouts/Ariya";
import { IAriyaMonitorData, IChartData, IMetricTotal, IRegionSplitState } from "../../../constants/interface/ariya";
import { ThemeContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import commonStyles from "../ariya.module.scss";
import { getGraphData } from "../helpers/charts";
import { extractMonitorData, extractRegionSplitData, sortChartData, sumObjectsByKey } from "../helpers/dataFunctions";
import { MonitorCard } from "./components/Card";
import { MonitorTrendline } from "./components/Charts";
import styles from "./monitor.module.scss";

export const Monitor = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const themeContext = useContext(ThemeContext);

  const dispatch = useDispatch();

  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.ariya_monitor);
  const { count_data: viewsCount }: IAriyaMonitorData = useSelector((state: RootStateOrAny) => state.ariya.visits);
  const { count_data: handraisersCount }: IAriyaMonitorData = useSelector((state: RootStateOrAny) => state.ariya.handraisers);

  //Views
  const [viewsTotal, setViewsTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [viewsJpn, setViewsJpn] = useState<IRegionSplitState>({ current: null, percentage: null });
  const [viewsUsa, setViewsUsa] = useState<IRegionSplitState>({ current: null, percentage: null });
  const [viewsNae, setViewsNae] = useState<IRegionSplitState>({ current: null, percentage: null });
  const [viewsOther, setViewsOther] = useState<IRegionSplitState>({ current: null, percentage: null });
  const [viewsChart, setViewsChart] = useState<Array<IChartData>>([]);

  //Handraisers
  const [handraisersTotal, setHandraisersTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [handraisersJpn, setHandraisersJpn] = useState<IRegionSplitState>({ current: null, percentage: null });
  const [handraisersUsa, setHandraisersUsa] = useState<IRegionSplitState>({ current: null, percentage: null });
  const [handraisersNae, setHandraisersNae] = useState<IRegionSplitState>({ current: null, percentage: null });
  const [handraisersChart, setHandraisersChart] = useState<Array<IChartData>>([]);

  // Pre-order
  const [japanPreorderTotal, setJapanPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [japanPreorderChart, setJapanPreorderChart] = useState<Array<IChartData>>([]);
  const [updatedPreorderTotal, setUpdatedPreorderTotal] = useState<number | null>(null);
  const [updatedLastDayPreorder, setUpdatedLastDayPreorder] = useState<number | null>(null);

  // Norway Total
  const [norwayPreorderTotal, setNorwayPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [norwayPreorderChart, setNorwayPreorderChart] = useState<Array<IChartData>>([]);

  // Americas Total
  const [usaPreorderTotal, setUsaPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [usaPreorderChart, setUsaPreorderChart] = useState<Array<IChartData>>([]);

  // Netherlands Total
  const [netherlandsPreorderTotal, setNetherlandsPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [netherlandsPreorderChart, setNetherlandsPreorderChart] = useState<Array<IChartData>>([]);

  // United Kingdom Total
  const [ukPreorderTotal, setUkPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [ukPreorderChart, setUkPreorderChart] = useState<Array<IChartData>>([]);

  // Sweden Total
  const [swedenPreorderTotal, setSwedenPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [swedenPreorderChart, setSwedenPreorderChart] = useState<Array<IChartData>>([]);

  // Belgium Total
  const [belgiumPreorderTotal, setBelgiumPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [belgiumPreorderChart, setBelgiumPreorderChart] = useState<Array<IChartData>>([]);

  // Italy Total
  const [italyPreorderTotal, setItalyPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [italyPreorderChart, setItalyPreorderChart] = useState<Array<IChartData>>([]);

  // Germany Total
  const [germanyPreorderTotal, setGermanyPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [germanyPreorderChart, setGermanyPreorderChart] = useState<Array<IChartData>>([]);

  //France Total
  const [francePreorderTotal, setFrancePreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [francePreorderChart, setFrancePreorderChart] = useState<Array<IChartData>>([]);

  // Canada Total
  const [canadaPreorderTotal, setCanadaPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [canadaPreorderChart, setCanadaPreorderChart] = useState<Array<IChartData>>([]);

  const [fileUpdatedDate, setFileUpdatedDate] = useState<string>("");
  // Merged pre-order / deposits data
  const [mergedPreOrderDepositsChartData, setMergedPreOrderDepositsChartData] = useState<Array<IChartData>>([]);

  const pageNavigation = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;

      history.push({ pathname: pageLink, search: "" });
    },
    [history]
  );

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Ariya", page: "Ariya Monitor" });
  }, []);

  // Views
  useEffect(() => {
    fetchVisitsData().then((response) => {
      if (response && !("error" in response)) {
        dispatch(setAriyaVisits(response));
        const {
          chart_data: { data, year, month, day },
          totals: { current, previous },
        } = extractMonitorData(response);
        Promise.all([
          dispatch(setLastDataRefresh("ariya_monitor", `${year}-${month}-${day}`)),
          setViewsTotal({ current, previous }),
          setViewsChart(getGraphData(data)),
        ]);
      }
    });
  }, []);

  useEffect(() => {
    const { japan, nae, usa, other } = extractRegionSplitData(viewsCount, viewsTotal);

    setViewsJpn(japan);
    setViewsNae(nae);
    setViewsUsa(usa);
    setViewsOther(other);
  }, [viewsCount, viewsTotal]);

  // Handraisers
  useEffect(() => {
    fetchHandraisersData().then((response) => {
      if (response && !("error" in response)) {
        dispatch(setAriyaHandraisers(response));
        const {
          chart_data: { data },
          totals: { current, previous },
        } = extractMonitorData(response);
        Promise.all([setHandraisersTotal({ current, previous }), setHandraisersChart(getGraphData(data))]);
      }
    });
  }, []);

  useEffect(() => {
    const { japan, nae, usa } = extractRegionSplitData(handraisersCount, handraisersTotal);

    setHandraisersJpn(japan);
    setHandraisersNae(nae);
    setHandraisersUsa(usa);
  }, [handraisersCount, handraisersTotal]);

  // Preorder
  useEffect(() => {
    Promise.all([
      fetchPreorderData(["Norway"]).then((response) => {
        if (response && !("error" in response)) {
          dispatch(setAriyaPreOrder(response));
          const {
            chart_data: { data },
            totals: { current, previous },
          } = extractMonitorData(response);
          Promise.all([setNorwayPreorderTotal({ current, previous }), setNorwayPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderData_USA().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setUsaPreorderTotal({ current, previous }), setUsaPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderData_Netherlands().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setNetherlandsPreorderTotal({ current, previous }), setNetherlandsPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderData_UK().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setUkPreorderTotal({ current, previous }), setUkPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderData_Sweden().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setSwedenPreorderTotal({ current, previous }), setSwedenPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderData_Belgium().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setBelgiumPreorderTotal({ current, previous }), setBelgiumPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderData_Italy().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setItalyPreorderTotal({ current, previous }), setItalyPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderData_Germany().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setGermanyPreorderTotal({ current, previous }), setGermanyPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderData_France().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setFrancePreorderTotal({ current, previous }), setFrancePreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderData_Canada().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals, last_date_in_file } = response.report;
          setFileUpdatedDate(last_date_in_file);
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setCanadaPreorderTotal({ current, previous }), setCanadaPreorderChart(getGraphData(data))]);
        }
      }),
    ]);
  }, []);

  useEffect(() => {
    // Updated Current
    setUpdatedPreorderTotal(null);
    if (
      !!japanPreorderTotal.current ||
      !!norwayPreorderTotal.current ||
      !!usaPreorderTotal.current ||
      !!netherlandsPreorderTotal.current ||
      !!ukPreorderTotal.current ||
      !!swedenPreorderTotal.current ||
      !!belgiumPreorderTotal.current ||
      !!italyPreorderTotal.current ||
      !!germanyPreorderTotal.current ||
      !!francePreorderTotal.current ||
      !!canadaPreorderTotal.current
    ) {
      japanPreorderTotal.current && setUpdatedPreorderTotal((prevState) => (prevState ?? 0) + (japanPreorderTotal.current ?? 0));
      norwayPreorderTotal.current && setUpdatedPreorderTotal((prevState) => (prevState ?? 0) + (norwayPreorderTotal.current ?? 0));
      usaPreorderTotal.current && setUpdatedPreorderTotal((prevState) => (prevState ?? 0) + (usaPreorderTotal.current ?? 0));
      netherlandsPreorderTotal.current &&
        setUpdatedPreorderTotal((prevState) => (prevState ?? 0) + (netherlandsPreorderTotal.current ?? 0));
      ukPreorderTotal.current && setUpdatedPreorderTotal((prevState) => (prevState ?? 0) + (ukPreorderTotal.current ?? 0));
      swedenPreorderTotal.current && setUpdatedPreorderTotal((prevState) => (prevState ?? 0) + (swedenPreorderTotal.current ?? 0));
      belgiumPreorderTotal.current && setUpdatedPreorderTotal((prevState) => (prevState ?? 0) + (belgiumPreorderTotal.current ?? 0));
      italyPreorderTotal.current && setUpdatedPreorderTotal((prevState) => (prevState ?? 0) + (italyPreorderTotal.current ?? 0));
      germanyPreorderTotal.current && setUpdatedPreorderTotal((prevState) => (prevState ?? 0) + (germanyPreorderTotal.current ?? 0));
      francePreorderTotal.current && setUpdatedPreorderTotal((prevState) => (prevState ?? 0) + (francePreorderTotal.current ?? 0));
      canadaPreorderTotal.current && setUpdatedPreorderTotal((prevState) => (prevState ?? 0) + (canadaPreorderTotal.current ?? 0));
    }

    // Updates previous
    setUpdatedLastDayPreorder(null);
    if (
      !!japanPreorderTotal.previous ||
      !!norwayPreorderTotal.previous ||
      !!usaPreorderTotal.previous ||
      !!netherlandsPreorderTotal.previous ||
      !!ukPreorderTotal.previous ||
      !!swedenPreorderTotal.previous ||
      !!belgiumPreorderTotal.previous ||
      !!italyPreorderTotal.previous ||
      !!germanyPreorderTotal.previous ||
      !!francePreorderTotal.previous ||
      !!canadaPreorderTotal.previous
    ) {
      japanPreorderTotal.previous && setUpdatedLastDayPreorder((prevState) => (prevState ?? 0) + (japanPreorderTotal.previous ?? 0));
      norwayPreorderTotal.previous && setUpdatedLastDayPreorder((prevState) => (prevState ?? 0) + (norwayPreorderTotal.previous ?? 0));
      usaPreorderTotal.previous && setUpdatedLastDayPreorder((prevState) => (prevState ?? 0) + (usaPreorderTotal.previous ?? 0));
      netherlandsPreorderTotal.previous &&
        setUpdatedLastDayPreorder((prevState) => (prevState ?? 0) + (netherlandsPreorderTotal.previous ?? 0));
      ukPreorderTotal.previous && setUpdatedLastDayPreorder((prevState) => (prevState ?? 0) + (ukPreorderTotal.previous ?? 0));
      swedenPreorderTotal.previous && setUpdatedLastDayPreorder((prevState) => (prevState ?? 0) + (swedenPreorderTotal.previous ?? 0));
      belgiumPreorderTotal.previous && setUpdatedLastDayPreorder((prevState) => (prevState ?? 0) + (belgiumPreorderTotal.previous ?? 0));
      italyPreorderTotal.previous && setUpdatedLastDayPreorder((prevState) => (prevState ?? 0) + (italyPreorderTotal.previous ?? 0));
      germanyPreorderTotal.previous && setUpdatedLastDayPreorder((prevState) => (prevState ?? 0) + (germanyPreorderTotal.previous ?? 0));
      francePreorderTotal.previous && setUpdatedLastDayPreorder((prevState) => (prevState ?? 0) + (francePreorderTotal.previous ?? 0));
      canadaPreorderTotal.previous && setUpdatedLastDayPreorder((prevState) => (prevState ?? 0) + (canadaPreorderTotal.previous ?? 0));
    }
  }, [
    japanPreorderTotal,
    norwayPreorderTotal,
    usaPreorderTotal,
    ukPreorderTotal,
    swedenPreorderTotal,
    belgiumPreorderTotal,
    italyPreorderTotal,
    germanyPreorderTotal,
    francePreorderTotal,
    netherlandsPreorderTotal,
    canadaPreorderTotal,
  ]);

  // Deposit
  useEffect(() => {
    Promise.all([
      fetchPreOrderDepositsData().then((response) => {
        if (response && !("error" in response)) {
          dispatch(setAriyaDepositTotals(response));
          const {
            report: { data, totals },
          } = response;

          Promise.all([setJapanPreorderTotal({ current: +totals[0], previous: +data[data.length - 1].counts[0] })]);
        }
      }),
      fetchPreOrderDepositsChartData().then((response) => {
        if (response && !("error" in response)) {
          dispatch(setAriyaDepositTotals(response));
          const {
            report: { data },
          } = response;

          Promise.all([setJapanPreorderChart(getGraphData(data))]);
        }
      }),
    ]);
  }, []);

  // Merge preoder / deposits chart data
  useEffect(() => {
    const sortedChartData: Array<IChartData> = sortChartData([
      ...norwayPreorderChart,
      ...japanPreorderChart,
      ...usaPreorderChart,
      ...netherlandsPreorderChart,
      ...ukPreorderChart,
      ...swedenPreorderChart,
      ...belgiumPreorderChart,
      ...italyPreorderChart,
      ...germanyPreorderChart,
      ...francePreorderChart,
      ...canadaPreorderChart,
    ]);

    const aggregatedChart: any = sumObjectsByKey(sortedChartData);

    const updatedAggregatedChartData = aggregatedChart?.map((value: IChartData) => {
      if (value === aggregatedChart[aggregatedChart.length - 1]) {
        return (value = { ...value, bulletRadius: 4, showBullet: true });
      } else {
        return (value = { ...value, bulletRadius: 2, showBullet: false });
      }
    });
    setUpdatedLastDayPreorder(aggregatedChart[aggregatedChart?.length - 1]?.value);
    setMergedPreOrderDepositsChartData(updatedAggregatedChartData);
  }, [
    japanPreorderChart,
    norwayPreorderChart,
    usaPreorderChart,
    netherlandsPreorderChart,
    ukPreorderChart,
    swedenPreorderChart,
    belgiumPreorderChart,
    italyPreorderChart,
    germanyPreorderChart,
    francePreorderChart,
    canadaPreorderChart,
  ]);

  return (
    <HomeLayout
      title="ARIYA experience monitor"
      lastDataRefresh={lastDataRefresh ? format(parse(lastDataRefresh, "yyyy-M-dd", new Date()), "Y-MM-dd") : ""}
      lastCanadaUpdate={fileUpdatedDate ? format(parse(fileUpdatedDate, "yyyy-MM-dd", new Date()), "Y-MM-dd") : ""}
    >
      <>
        <Box display={"grid"} gridGap={15} className={styles.cardContainer}>
          {/* Visits */}
          <MonitorCard
            metric={{ title: "Visits", value: viewsTotal.current, lastDayValue: viewsTotal.previous }}
            split={[
              { title: "NJPN", value: viewsJpn.current, percentageTrend: viewsJpn.percentage },
              { title: "Americas", value: viewsUsa.current, percentageTrend: viewsUsa.percentage },
              { title: "AMIEO", value: viewsNae.current, percentageTrend: viewsNae.percentage },
              { title: "Other", value: viewsOther.current, percentageTrend: viewsOther.percentage },
            ]}
            description="*Others reflects the data available that is not part of NJPN, Americas, AMIEO"
            chart={<MonitorTrendline data={viewsChart} chartId={"viewsChart"} series={["views"]} theme={themeContext.theme} />}
            clickable={{ page: "/ariya/experience", onClick: pageNavigation }}
            withChartHeight={true}
          />

          {/* Handraisers */}
          <MonitorCard
            metric={{ title: "All hand-raisers*", value: handraisersTotal.current, lastDayValue: handraisersTotal.previous }}
            split={[
              { title: "NJPN", value: handraisersJpn.current },
              { title: "Americas", value: handraisersUsa.current },
              { title: "AMIEO", value: handraisersNae.current },
            ]}
            description={`*Hand-raisers are defined as follows: Japan - sum of Keep me informed and Brochure request; USA - Keep me informed; NAE - sum of Keep me informed and Pre-launch information`}
            chart={
              <MonitorTrendline data={handraisersChart} chartId={"handraisersChart"} series={["handraisers"]} theme={themeContext.theme} />
            }
            clickable={{ page: "/ariya/handraisers", onClick: pageNavigation }}
            withChartHeight={true}
          />

          {/* Online preorder */}
          <MonitorCard
            metric={{ title: "Online pre-order*", value: updatedPreorderTotal, lastDayValue: updatedLastDayPreorder }}
            split={[
              { title: "NJPN", value: japanPreorderTotal.current },
              { title: "Americas", value: (usaPreorderTotal.current ?? 0) + (canadaPreorderTotal.current ?? 0) },
              {
                title: "AMIEO",
                value:
                  norwayPreorderTotal.current ??
                  netherlandsPreorderTotal.current ??
                  ukPreorderTotal.current ??
                  swedenPreorderTotal.current ??
                  belgiumPreorderTotal.current ??
                  germanyPreorderTotal.current ??
                  false
                    ? (norwayPreorderTotal.current ?? 0) +
                      (netherlandsPreorderTotal.current ?? 0) +
                      (ukPreorderTotal.current ?? 0) +
                      (swedenPreorderTotal.current ?? 0) +
                      (belgiumPreorderTotal.current ?? 0) +
                      (italyPreorderTotal.current ?? 0) +
                      (germanyPreorderTotal.current ?? 0) +
                      (francePreorderTotal.current ?? 0)
                    : null,
              },
            ]}
            description={`*This number is showing the online pre-orders and should not be compared with the overall pre-orders reported by the Sales management system. The CEDAR team is working closely with each market to reconcile this data`}
            chart={
              <MonitorTrendline
                data={mergedPreOrderDepositsChartData}
                chartId={"preorderChart"}
                series={["preorder", "deposit"]}
                theme={themeContext.theme}
              />
            }
            clickable={{ page: "/ariya/preorder", onClick: pageNavigation }}
            withChartHeight={true}
          />
        </Box>

        <Grid container spacing={10} justifyContent="space-evenly">
          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page="/ariya/experience"
              data-test-id="ariyaExperience"
            >
              Experience insights
            </Button>
          </Grid>

          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page="/ariya/handraisers"
              data-test-id="ariyaHandraisers"
            >
              Hand-raisers insights
            </Button>
          </Grid>

          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page="/ariya/preorder"
              data-test-id="ariyaPreorder"
            >
              Online pre-order insights
            </Button>
          </Grid>
        </Grid>
      </>
    </HomeLayout>
  );
});
