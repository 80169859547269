import { AnyAction } from "redux";
import { put, retry, takeLatest } from "redux-saga/effects";
import {
  FETCH_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL,
  FETCH_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL_TABLE,
  FETCH_EXECUTIVE_SUMMARY_CHANNEL_MIX_PERFORMANCE,
  FETCH_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_DATE,
  FETCH_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_MARKET,
  FETCH_EXECUTIVE_SUMMARY_DETAILED_COMPARISONS,
  FETCH_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_DATE,
  FETCH_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_MARKET,
  FETCH_EXECUTIVE_SUMMARY_DIS_SUMMARY,
  FETCH_EXECUTIVE_SUMMARY_KEY_INDICATORS,
  FETCH_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_DATE,
  FETCH_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_MARKET,
} from "../actions/actionTypes/executiveSummaryActionTypes";
import {
  setExecutiveSummaryAggregatedPurchaseFunnel,
  setExecutiveSummaryAggregatedPurchaseFunnelTable,
  setExecutiveSummaryChannelMixPerformance,
  setExecutiveSummaryCloseRateByDate,
  setExecutiveSummaryCloseRateByMarket,
  setExecutiveSummaryDetailedComparisons,
  setExecutiveSummaryDisPercentageByDate,
  setExecutiveSummaryDisPercentageByMarket,
  setExecutiveSummaryDisSummary,
  setExecutiveSummaryKeyIndicators,
  setExecutiveSummaryPrivateSalesByDate,
  setExecutiveSummaryPrivateSalesByMarket,
} from "../actions/executiveSummaryActions";
import { setLoading } from "../actions/loadingActions";
import {
  getExecutiveSummaryAggregatedCloseRateByDate,
  getExecutiveSummaryAggregatedCloseRateByMarket,
  getExecutiveSummaryAggregatedDisPercentageByDate,
  getExecutiveSummaryAggregatedDisPercentageByMarket,
  getExecutiveSummaryAggregatedPrivateSalesByDate,
  getExecutiveSummaryAggregatedPrivateSalesByMarket,
  getExecutiveSummaryAggregatedPurchaseFunnel,
  getExecutiveSummaryAggregatedPurchaseFunnelTable,
  getExecutiveSummaryChannelMixPerformance,
  getExecutiveSummaryDetailedComparisons,
  getExecutiveSummaryDisSummary,
  getExecutiveSummaryKeyIndicators,
} from "../api/executiveSummaryWebsiteApi";
import { secondsDelay } from "../api/utils";

//WATCHER SAGAS
export function* watchExecutiveSummaryDisSummarySaga() {
  yield takeLatest(FETCH_EXECUTIVE_SUMMARY_DIS_SUMMARY, fetchExecutiveSummaryDisSummarySaga);
}

export function* watchExecutiveSummaryAggregatedPurchaseFunnelSaga() {
  yield takeLatest(FETCH_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL, fetchExecutiveSummaryAggregatedPurchaseFunnelSaga);
}

export function* watchExecutiveSummaryAggregatedPurchaseFunnelTableSaga() {
  yield takeLatest(FETCH_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL_TABLE, fetchExecutiveSummaryAggregatedPurchaseFunnelTableSaga);
}

export function* watchExecutiveSummaryDetailedComparisonsSaga() {
  yield takeLatest(FETCH_EXECUTIVE_SUMMARY_DETAILED_COMPARISONS, fetchExecutiveSummaryDetailedComparisonsSaga);
}

export function* watchExecutiveSummaryChannelMixPerformanceSaga() {
  yield takeLatest(FETCH_EXECUTIVE_SUMMARY_CHANNEL_MIX_PERFORMANCE, fetchExecutiveSummaryChannelMixPerformanceSaga);
}

export function* watchExecutiveSummaryPrivateSalesByMarketSaga() {
  yield takeLatest(FETCH_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_MARKET, fetchExecutiveSummaryPrivateSalesByMarketSaga);
}

export function* watchExecutiveSummaryPrivateSalesByDateSaga() {
  yield takeLatest(FETCH_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_DATE, fetchExecutiveSummaryPrivateSalesByDateSaga);
}

export function* watchExecutiveSummaryDisPercentageByMarketSaga() {
  yield takeLatest(FETCH_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_MARKET, fetchExecutiveSummaryDisPercentageByMarketSaga);
}

export function* watchExecutiveSummaryDisPercentageByDateSaga() {
  yield takeLatest(FETCH_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_DATE, fetchExecutiveSummaryDisPercentageByDateSaga);
}

export function* watchExecutiveSummaryCloseRateByMarketSaga() {
  yield takeLatest(FETCH_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_MARKET, fetchExecutiveSummaryCloseRateByMarketSaga);
}

export function* watchExecutiveSummaryCloseRateByDateSaga() {
  yield takeLatest(FETCH_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_DATE, fetchExecutiveSummaryCloseRateByDateSaga);
}

export function* watchExecutiveSummaryKeyIndicatorsSaga() {
  yield takeLatest(FETCH_EXECUTIVE_SUMMARY_KEY_INDICATORS, fetchExecutiveSummaryKeyIndicatorsSaga);
}

//WORKER SAGAS
export function* fetchExecutiveSummaryDisSummarySaga() {
  try {
    yield put(setLoading("executive_summary_dis_summary", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getExecutiveSummaryDisSummary);
    if (!("error" in response)) {
      yield put(setExecutiveSummaryDisSummary(response));
    }
  } catch (err) {
    console.log("failed to fetch /executive-summary/dis-summary");
    console.log(err);
  } finally {
    yield put(setLoading("executive_summary_dis_summary", false));
  }
}

export function* fetchExecutiveSummaryAggregatedPurchaseFunnelSaga() {
  try {
    yield put(setLoading("executive_summary_aggregated_purchase_funnel", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getExecutiveSummaryAggregatedPurchaseFunnel);
    if (!("error" in response)) {
      yield put(setExecutiveSummaryAggregatedPurchaseFunnel(response));
    }
  } catch (err) {
    console.log("failed to fetch /executive-summary/dis-summary");
    console.log(err);
  } finally {
    yield put(setLoading("executive_summary_aggregated_purchase_funnel", false));
  }
}

export function* fetchExecutiveSummaryAggregatedPurchaseFunnelTableSaga() {
  try {
    yield put(setLoading("executive_summary_aggregated_purchase_funnel_table", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getExecutiveSummaryAggregatedPurchaseFunnelTable);
    if (!("error" in response)) {
      yield put(setExecutiveSummaryAggregatedPurchaseFunnelTable(response));
    }
  } catch (err) {
    console.log("failed to fetch /executive-summary/dis-summary");
    console.log(err);
  } finally {
    yield put(setLoading("executive_summary_aggregated_purchase_funnel_table", false));
  }
}

export function* fetchExecutiveSummaryDetailedComparisonsSaga() {
  try {
    yield put(setLoading("executive_summary_detailed_comparisons", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getExecutiveSummaryDetailedComparisons);
    if (!("error" in response)) {
      yield put(setExecutiveSummaryDetailedComparisons(response));
    }
  } catch (err) {
    console.log("failed to fetch /executive-summary/detailed-comparisons");
    console.log(err);
  } finally {
    yield put(setLoading("executive_summary_detailed_comparisons", false));
  }
}

export function* fetchExecutiveSummaryChannelMixPerformanceSaga() {
  try {
    yield put(setLoading("executive_summary_channel_mix_performance", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getExecutiveSummaryChannelMixPerformance);
    if (!("error" in response)) {
      yield put(setExecutiveSummaryChannelMixPerformance(response));
    }
  } catch (err) {
    console.log("failed to /executive-summary/channel-mix-performance");
    console.log(err);
  } finally {
    yield put(setLoading("executive_summary_channel_mix_performance", false));
  }
}

export function* fetchExecutiveSummaryPrivateSalesByMarketSaga() {
  try {
    yield put(setLoading("executive_summary_private_sales_by_market", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getExecutiveSummaryAggregatedPrivateSalesByMarket);
    if (!("error" in response)) {
      yield put(setExecutiveSummaryPrivateSalesByMarket(response));
    }
  } catch (err) {
    console.log("failed to /executive-summary/aggregated/private-sales-breakdown-by-market");
    console.log(err);
  } finally {
    yield put(setLoading("executive_summary_private_sales_by_market", false));
  }
}

export function* fetchExecutiveSummaryPrivateSalesByDateSaga() {
  try {
    yield put(setLoading("executive_summary_private_sales_by_date", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getExecutiveSummaryAggregatedPrivateSalesByDate);
    if (!("error" in response)) {
      yield put(setExecutiveSummaryPrivateSalesByDate(response));
    }
  } catch (err) {
    console.log("failed to /executive-summary/aggregated/private-sales-breakdown-by-date");
    console.log(err);
  } finally {
    yield put(setLoading("executive_summary_private_sales_by_date", false));
  }
}

export function* fetchExecutiveSummaryDisPercentageByMarketSaga() {
  try {
    yield put(setLoading("executive_summary_dis_percentage_by_market", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getExecutiveSummaryAggregatedDisPercentageByMarket);
    if (!("error" in response)) {
      yield put(setExecutiveSummaryDisPercentageByMarket(response));
    }
  } catch (err) {
    console.log("failed to /executive-summary/aggregated/dis-percentage-breakdown-by-market");
    console.log(err);
  } finally {
    yield put(setLoading("executive_summary_dis_percentage_by_market", false));
  }
}

export function* fetchExecutiveSummaryDisPercentageByDateSaga() {
  try {
    yield put(setLoading("executive_summary_dis_percentage_by_date", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getExecutiveSummaryAggregatedDisPercentageByDate);
    if (!("error" in response)) {
      yield put(setExecutiveSummaryDisPercentageByDate(response));
    }
  } catch (err) {
    console.log("failed to /executive-summary/aggregated/dis-percentage-breakdown-by-date");
    console.log(err);
  } finally {
    yield put(setLoading("executive_summary_dis_percentage_by_date", false));
  }
}

export function* fetchExecutiveSummaryCloseRateByMarketSaga() {
  try {
    yield put(setLoading("executive_summary_close_rate_by_market", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getExecutiveSummaryAggregatedCloseRateByMarket);
    if (!("error" in response)) {
      yield put(setExecutiveSummaryCloseRateByMarket(response));
    }
  } catch (err) {
    console.log("failed to /executive-summary/aggregated/close-rate-breakdown-by-market");
    console.log(err);
  } finally {
    yield put(setLoading("executive_summary_close_rate_by_market", false));
  }
}

export function* fetchExecutiveSummaryCloseRateByDateSaga() {
  try {
    yield put(setLoading("executive_summary_close_rate_by_date", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getExecutiveSummaryAggregatedCloseRateByDate);
    if (!("error" in response)) {
      yield put(setExecutiveSummaryCloseRateByDate(response));
    }
  } catch (err) {
    console.log("failed to /executive-summary/aggregated/close-rate-breakdown-by-date");
    console.log(err);
  } finally {
    yield put(setLoading("executive_summary_close_rate_by_date", false));
  }
}

export function* fetchExecutiveSummaryKeyIndicatorsSaga({ metric, period }: AnyAction) {
  try {
    yield put(setLoading("executive_summary_key_indicators", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getExecutiveSummaryKeyIndicators, metric, period);
    if (!("error" in response)) {
      yield put(setExecutiveSummaryKeyIndicators(response));
    }
  } catch (err) {
    console.log("failed to /executive-summary/key-indicators");
    console.log(err);
  } finally {
    yield put(setLoading("executive_summary_key_indicators", false));
  }
}
