import { put, retry, takeLatest } from "redux-saga/effects";
import { FETCH_ACTIVATION_RATIO_DATA } from "../actions/actionTypes/activationRatioTypes";
import { setActivationRatio } from "../actions/activationRatioActions";
import { setLoading } from "../actions/loadingActions";
import { getActivationRatioData } from "../api/activationRatioApi";
import { secondsDelay } from "../api/utils";

// Watcher sagas
export function* watchFetchActivationRatioDataSaga() {
  yield takeLatest(FETCH_ACTIVATION_RATIO_DATA, fetchActivationRatioDataSaga);
}

// Worker sagas
export function* fetchActivationRatioDataSaga() {
  try {
    yield put(setLoading("activation_ratio_data", true));

    const response: Record<any, any> = yield retry(4, secondsDelay, getActivationRatioData);
    if (!("error" in response)) {
      yield put(setActivationRatio(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("activation_ratio_data", false));
  }
}
