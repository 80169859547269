import { ErrorBoundary } from "@sentry/react";
import moment from "moment/moment";
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg } from "../../../../components/AppMessages";
import { ToggleSwitch } from "../../../../components/Toogle";
import { ALL_OPTION } from "../../../../constants";
import { CCSBusinessRevenueChartData, CCSBusinessRevenueData } from "../../../../constants/interface/ccsCockpit";
import { CockpitContext } from "../../subs/CockpitContext";
import { DisabledTile, TotalVehicleProduced, ValueSplitTile } from "../tiles";

interface Props {
  showMetric?: boolean;
}

const defaultSubscriptionPackageSalesTitle = "CCS subscription sales";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "January",
];

export const BusinessRevenueTiles = (props: Props) => {
  const { showMetric } = props;

  const { Current: currentBusinessRevenueData, YoY: yoyBusinessRevenueData }: CCSBusinessRevenueData = useSelector(
    (state: RootStateOrAny) => state.ccs_cockpit.business_revenue_data
  );
  const {
    data: businessRevenueChartData,
    series: businessRevenueChartSeries,
    FYs,
  }: CCSBusinessRevenueChartData = useSelector((state: RootStateOrAny) => state.ccs_cockpit.business_revenue_chart_data);
  const { Current: currentConnectedCarsData, YoY: yoyConnectedCarsData } = useSelector(
    (state: RootStateOrAny) => state.ccs_cockpit.connected_cars_sold_vs_produced
  );
  const { Current: currentConnectedCarsChartData } = useSelector(
    (state: RootStateOrAny) => state.ccs_cockpit.connected_cars_sold_vs_produced_chart_data
  );
  const { Current: currentSalesRevenueData, YoY: yoySalesRevenueData }: CCSBusinessRevenueData = useSelector(
    (state: RootStateOrAny) => state.ccs_cockpit.sales_revenue_data
  );
  const { data: salesRevenueChartData, series: salesRevenueChartSeries }: CCSBusinessRevenueChartData = useSelector(
    (state: RootStateOrAny) => state.ccs_cockpit.sales_revenue_chart_data
  );
  const {
    business_revenue_data: businessRevenueLoading,
    ccs_main_data: ccsMainDataLoading,
    sales_revenue_data: salesRevenueLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);
  const {
    date_range: dateParamValue,
    region: regionParamValue,
    market: marketParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const dates: Array<string> = useMemo(() => dateParamValue.split(","), [dateParamValue]);

  const [connectedBusinessRevenueTitle] = useState(() => "CCS business revenue");
  const [subscriptionPackageSalesTitle] = useState(() => "CCS subscription sales");
  const [showCurrency, setShowCurrency] = useState(true);
  const [b2bToggleActive, setB2bToggleActive] = useState(true);
  const [businessRevenueConversionToggleActive, setBusinessRevenueConversionToggleActive] = useState(true);
  const [subscriptionsConversionToggleActive, setSubscriptionsConversionToggleActive] = useState(true);
  const [financialYearAvailable, setfinancialYear] = useState<number[]>([]);

  const { setMetric } = useContext(CockpitContext);

  const sortedCurrentBusinessRevenueData = useMemo(() => {
    if (currentBusinessRevenueData) {
      const sortedData = currentBusinessRevenueData.filter((data: any) => !["All", "Europe"].includes(data.region));
      return [...sortedData, ...currentBusinessRevenueData.filter((data: any) => data.region == "All")];
    }

    return [];
  }, [currentBusinessRevenueData]);

  const connectedCarsSoldChartData = useMemo(() => {
    if (currentConnectedCarsChartData) {
      const formattedChartData = currentConnectedCarsChartData.reduce(
        (result: Array<{ [index: string]: Record<string, number | string> }>, row: any) => {
          const { date } = row;
          const label = moment(date).format("MMM YY");

          return [...result, { label, ...row }];
        },
        []
      );

      return formattedChartData;
    }
    return {};
  }, [currentConnectedCarsChartData, dates]);

  const toggleType = useMemo(() => {
    if (regionParamValue != ALL_OPTION) {
      return "conversion";
    }
    return undefined;
  }, [regionParamValue]);

  const renderConversionToggle = (toggleState: boolean, setToogleState: Dispatch<SetStateAction<boolean>>) => (
    <ToggleSwitch
      activeToggleLabel="Regional currency"
      inactiveToggleLabel="$ conversion"
      active={toggleState}
      handleToggleClick={() => setToogleState(!toggleState)}
    />
  );

  useEffect(() => {
    const year = dates.map((date) => date.split(" ")[1]);
    const monthSelected = dates.map((date) => date.split(" ")[0]);
    const financialYear = monthSelected.map((month, index) =>
      months.indexOf(month) > 3 ? parseInt(year[index]) : parseInt(year[index]) - 1
    );

    const yoyAdd = Array.from(new Set(financialYear));

    yoyAdd[0] > 2019 && yoyAdd.unshift(yoyAdd[0] - 1);

    setfinancialYear(Array.from(new Set(yoyAdd)));
  }, [dates]);

  useEffect(() => {
    if (/all/gi.test(regionParamValue)) {
      setBusinessRevenueConversionToggleActive(true);
      setSubscriptionsConversionToggleActive(true);
    }
  }, [regionParamValue]);

  return (
    <div className={`section_content ${showMetric ? "isMetric" : ""}`}>
      <ErrorBoundary fallback={<ErrorMsg />}>
        {/europe/gi.test(regionParamValue) ? (
          <DisabledTile
            title={connectedBusinessRevenueTitle}
            disableMessage={"Revenue data currently not measurable for Europe (3 year free trial period)"}
            onClick={() => setMetric("Business revenue")}
          />
        ) : (
          <ValueSplitTile
            title={connectedBusinessRevenueTitle}
            splitData={sortedCurrentBusinessRevenueData}
            yoySplitData={yoyBusinessRevenueData}
            splitValueKey={businessRevenueConversionToggleActive ? "revenue_value" : "revenue_value_converted"}
            chartDataKey={businessRevenueConversionToggleActive ? "revenue_value_normalised" : "revenue_value_converted"}
            chartData={showMetric ? businessRevenueChartData : undefined}
            chartSeries={showMetric ? businessRevenueChartSeries : undefined}
            isLoading={businessRevenueLoading}
            showCurrency={showCurrency}
            financialYear={financialYearAvailable}
            data-target="business_revenue"
            onClick={() => setMetric("Business revenue")}
            region={regionParamValue}
            toggle={
              toggleType == "conversion"
                ? renderConversionToggle(businessRevenueConversionToggleActive, setBusinessRevenueConversionToggleActive)
                : undefined
            }
            isConverted={!businessRevenueConversionToggleActive}
            financialYearSpan={FYs}
          />
        )}
      </ErrorBoundary>

      <ErrorBoundary fallback={<ErrorMsg />}>
        {/europe/gi.test(regionParamValue) ? (
          <DisabledTile
            title={defaultSubscriptionPackageSalesTitle}
            disableMessage={"Revenue data currently not measurable for Europe (3 year free trial period)"}
            onClick={() => setMetric("Business revenue")}
          />
        ) : (
          <ValueSplitTile
            title={subscriptionPackageSalesTitle}
            splitData={currentSalesRevenueData}
            yoySplitData={yoySalesRevenueData}
            splitValueKey={
              marketParamValue == "United States" && !b2bToggleActive
                ? "b2b"
                : subscriptionsConversionToggleActive
                ? "revenue_value"
                : "revenue_value_converted"
            }
            chartData={showMetric ? salesRevenueChartData : undefined}
            chartSeries={showMetric ? salesRevenueChartSeries : undefined}
            chartDataKey={subscriptionsConversionToggleActive ? "revenue_value_normalised" : "revenue_value_converted"}
            isLoading={salesRevenueLoading}
            showCurrency={showCurrency}
            data-target="business_revenue"
            region={regionParamValue}
            onClick={() => setMetric("Business revenue")}
            financialYear={financialYearAvailable}
            toggle={
              toggleType == "conversion"
                ? renderConversionToggle(subscriptionsConversionToggleActive, setSubscriptionsConversionToggleActive)
                : undefined
            }
            isConverted={!subscriptionsConversionToggleActive}
            isSalesRevenueTile={true}
          />
        )}
      </ErrorBoundary>

      <DisabledTile
        title={"CCS services purchased by customer - Average value"}
        disableMessage={"TBD FY22"}
        onClick={() => setMetric("Business revenue")}
      />

      <ErrorBoundary fallback={<ErrorMsg />}>
        <TotalVehicleProduced
          title={"Connected cars sold"}
          value={currentConnectedCarsData ? currentConnectedCarsData[0]?.sold_eligible : null}
          sold={currentConnectedCarsData ? currentConnectedCarsData[0]?.sold_eligible : null}
          produced={currentConnectedCarsData ? currentConnectedCarsData[0]?.sold_to_produced : null}
          comparisonProduced={yoyConnectedCarsData ? yoyConnectedCarsData[0]?.pts_sold_to_produced : null}
          isLoading={ccsMainDataLoading}
          comparisonPercentageValue={yoyConnectedCarsData ? yoyConnectedCarsData[0]?.perc_sold_eligible : null}
          chartData={{
            data: showMetric ? connectedCarsSoldChartData : undefined,
            chartId: "connected_sold_eligibleChart",
            barDataKey: "sold_eligible",
            barSeriesName: "Sold Eligible",
            lineSeriesKey: "sold_to_produced",
            lineSeriesName: regionParamValue == "China" ? "connected cars sold" : "% produced connected cars",
          }}
          data-target="business_revenue"
          onClick={() => setMetric("Business revenue")}
        />
      </ErrorBoundary>
    </div>
  );
};
