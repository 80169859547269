import {
  FETCH_IMPRESSIONS_BREAKDOWN,
  FETCH_IMPRESSIONS_OVERVIEW,
  FETCH_VISITORS_COUNTRY,
  FETCH_VISITS_BREAKDOWN,
  FETCH_VISITS_OVERVIEW,
  SET_IMPRESSIONS_BREAKDOWN,
  SET_IMPRESSIONS_OVERVIEW,
  SET_VISITORS_COUNTRY,
  SET_VISITS_BREAKDOWN,
  SET_VISITS_OVERVIEW,
} from "./actionTypes";

export const fetchVisitsOverview = () => ({ type: FETCH_VISITS_OVERVIEW });
export const setVisitsOverview = (data: any) => ({ type: SET_VISITS_OVERVIEW, payload: data });

export const fetchVisitsBreakdown = () => ({ type: FETCH_VISITS_BREAKDOWN });
export const setVisitsBreakdown = (data: any) => ({ type: SET_VISITS_BREAKDOWN, payload: data });

export const fetchImpressionsOverview = () => ({ type: FETCH_IMPRESSIONS_OVERVIEW });
export const setImpressionsOverview = (data: any) => ({ type: SET_IMPRESSIONS_OVERVIEW, payload: data });

export const fetchImpressionsBreakdown = () => ({ type: FETCH_IMPRESSIONS_BREAKDOWN });
export const setImpressionsBreakdown = (data: any) => ({ type: SET_IMPRESSIONS_BREAKDOWN, payload: data });

export const fetchVisitorsCountry = () => ({ type: FETCH_VISITORS_COUNTRY });
export const setVisitorsCountry = (data: any) => ({ type: SET_VISITORS_COUNTRY, payload: data });
