import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import ValueTile from "./ValueTile";

const LeadsManagementSystem = () => {
  const {
    Current: currentConversionAndCostData,
    YoY: yoyConversionData,
    PoP: popConversionData,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.conversion_and_cost);
  const {
    Current: currentDisAndLeadsData,
    YoY: yoyDisAndLeadsData,
    PoP: popDisAndLeadsData,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.lms_dis_and_leads);
  const {
    Current: currentDigitallyInfluencedSales,
    YoY: yoyDigitallyInfluencedSales,
    PoP: popDigitallyInfluencedSales,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.lms_digitally_influenced_sales);

  const conversionAndCostLoading = useSelector((state: RootStateOrAny) => state.loading.conversion_and_cost);

  return (
    <div id="leads_management_system" className="section-content journey-excellence-row" data-target="leads_management_system">
      <div className="section-content-row">
        <ValueTile
          title="Leads"
          mainValue={currentConversionAndCostData?.leads}
          yoyValue={yoyConversionData?.perc_leads}
          popValue={popConversionData?.perc_leads}
          tooltipText="Visitors who express interest (lead-type actions) and leave PII data"
          isLoading={conversionAndCostLoading}
        />
        <ValueTile
          title="Website visits to Leads"
          mainValue={currentConversionAndCostData?.web_visits_to_leads}
          yoyValue={yoyConversionData?.pts_web_visits_to_leads}
          popValue={popConversionData?.pts_web_visits_to_leads}
          isPercentage={true}
          isLoading={conversionAndCostLoading}
          tooltipText="Conversion from website visitst to leads that express interest (lead-type actions) and leave PII data"
        />
        <ValueTile
          title="Cost per Leads"
          mainValue={currentConversionAndCostData?.cost_per_lead}
          yoyValue={yoyConversionData?.perc_cost_per_lead}
          popValue={popConversionData?.perc_cost_per_lead}
          showCurrency={true}
          ratingInverse={true}
          ratingArrowInverse={true}
          isLoading={conversionAndCostLoading}
          tooltipText="Cost for lead visits based on the Media FMI allocated through digital channels"
        />
      </div>
      <div className="section-content-row">
        {/* <SplitValueTile
          primaryTitle="Private sales"
          secondaryTitle="DIS"
          primaryValue={currentDisAndLeadsData?.private_sales_actual}
          secondaryValue={currentConversionAndCostData?.dis}
          primaryYoyValue={yoyDisAndLeadsData?.perc_private_sales_actual}
          secondaryYoyValue={yoyConversionData?.perc_dis}
          primaryTooltipText="Sum of all sales in the market ( Digitally Influenced Sales + Dealer Walk ins + Other)"
          secondaryTooltipText="Number of confirmed new car sales that can be attributed to OEM managed digital activity"
          isLoading={conversionAndCostLoading}
        /> */}
        <ValueTile
          title="DIS"
          mainValue={currentConversionAndCostData?.dis}
          yoyValue={yoyConversionData?.perc_dis}
          popValue={popConversionData?.perc_dis}
          isLoading={conversionAndCostLoading}
          tooltipText="Number of confirmed new car sales that can be attributed to OEM managed digital activity"
        />
        <ValueTile
          title="Leads to DIS"
          mainValue={currentConversionAndCostData?.leads_to_dis}
          yoyValue={yoyConversionData?.pts_leads_to_dis}
          popValue={popConversionData?.pts_leads_to_dis}
          isPercentage={true}
          isLoading={conversionAndCostLoading}
          tooltipText="Conversion from Visitors who express interest (lead-type actions) and leave PII data into car sales that can be atributed to OEM managed digital actiivty"
        />
        <ValueTile
          title="Cost per DIS"
          mainValue={currentConversionAndCostData?.cost_per_dis}
          yoyValue={yoyConversionData?.perc_cost_per_dis}
          popValue={popConversionData?.perc_cost_per_dis}
          showCurrency={true}
          ratingInverse={true}
          ratingArrowInverse={true}
          isLoading={conversionAndCostLoading}
          tooltipText="Cost per DIS based on the Media FMI allocated through digital channels"
        />
      </div>
      <div className="section-content-row">
        <ValueTile
          title="Private sales"
          mainValue={currentDisAndLeadsData?.private_sales_actual}
          yoyValue={yoyDisAndLeadsData?.perc_private_sales_actual}
          popValue={popDisAndLeadsData?.perc_private_sales_actual}
          isLoading={conversionAndCostLoading}
          tooltipText="Sum of all sales in the market ( Digitally Influenced Sales + Dealer Walk ins + Other)"
        />
        <ValueTile
          title="DIS%"
          mainValue={currentDigitallyInfluencedSales?.dis_percentage}
          yoyValue={yoyDigitallyInfluencedSales?.pts_dis_percentage}
          popValue={popDigitallyInfluencedSales?.pts_dis_percentage}
          targetValue={currentDigitallyInfluencedSales?.pts_target}
          isLoading={conversionAndCostLoading}
          isPercentage={true}
          tooltipText="Sum of all sales in the market ( Digitally Influenced Sales + Dealer Walk ins + Other)"
        />
      </div>
    </div>
  );
};

export default LeadsManagementSystem;
