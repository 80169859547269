import { Box } from "@material-ui/core";
import React from "react";
import style from "./card.module.scss";

interface Props {
  id: string;
  title?: string;
  titleDescription?: string;
  children: React.ReactElement;
  footnote?: string;
  className?: string;
  contentClassName?: string;
}

const Card = (props: Props) => {
  const { children, id, title, footnote, titleDescription, className, contentClassName } = props;

  return (
    <Box className={`${style.card} ${className}`} id={id} data-test-id={id}>
      {title && (
        <h2 className={style.title}>
          {title}
          {titleDescription && (
            <>
              <br />
              {titleDescription}
            </>
          )}
        </h2>
      )}

      <div data-test-id={id + "_content"} className={`${style.content} ${contentClassName ? contentClassName : ""}`}>
        {children}
      </div>

      {footnote && (
        <div className={style.footnote} data-test-id="footnote">
          {footnote}
        </div>
      )}
    </Box>
  );
};

export default Card;
