import "moment-fquarter";
import { g5Markets, g8Markets } from "../constants/ccsConstants";
import { isCustomDate, isDigitalPerformanceDate } from "../utils/dateFunctions";
import { newApiFetch, newApiFetchV2 } from "./utils";

const postCCSData = (url: string, isRevenue?: boolean, markets?: string, marketGroup?: string) => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);
  const interimDateRange = parameters.get("date_range");

  if (isCustomDate(interimDateRange as string) || isDigitalPerformanceDate(interimDateRange as string) || !interimDateRange)
    return { error: "wrong date parameters" };

  const date_range = parameters.get("date_range")?.split(",") || parameters.get("date_range");
  const model = parameters.get("model") === "All" ? (isRevenue ? ["All"] : []) : parameters.get("model")?.split(",");
  const topic = parameters.get("topic") === "All" ? [] : parameters.get("topic")?.split(",");
  const market =
    parameters.get("region") === "North America"
      ? ["United States"]
      : markets && parameters.get("region") === "Europe"
      ? markets != "All"
        ? markets == "G8"
          ? g8Markets
          : markets.split(",")
        : []
      : [];
  const g8_toggle = url.includes("user-engagement") && (markets == "G8" || parameters.get("user_engagement_market") == "G8");

  const parameterEntries: any = markets
    ? {
        ...Object.fromEntries(parameters.entries()),
        date_range,
        model,
        market,
        topic,
        g8_toggle,
      }
    : { ...Object.fromEntries(parameters.entries()), date_range, model, topic, g8_toggle };

  // @ts-ignore
  if (marketGroup) parameterEntries["market_group"] = marketGroup;
  delete parameterEntries["user_engagement_market"];

  return newApiFetch(url, parameterEntries);
};

const postCCSDataV2 = (url: string, markets?: string, isRevenue?: boolean) => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);
  const interimDateRange = parameters.get("date_range");

  if (isCustomDate(interimDateRange as string) || isDigitalPerformanceDate(interimDateRange as string) || !interimDateRange)
    return { error: "wrong date parameters" };

  const date_range = parameters.get("date_range")?.split(",") || parameters.get("date_range");
  const model = parameters.get("model") === "All" ? [] : parameters.get("model")?.split(",");
  const topic = parameters.get("topic") === "All" ? [] : parameters.get("topic")?.split(",");
  const region =
    parameters.get("region") == "North America" && url != "/ccs-app-ratings/get-app-ratings-combined-avg-data"
      ? "United States"
      : parameters.get("region") == "G8"
      ? "All"
      : parameters.get("region");
  const market =
    markets && markets != "All"
      ? markets == "G8"
        ? g8Markets
        : markets.split(",")
      : parameters.get("market") === "All countries"
      ? []
      : parameters.get("market") != ""
      ? parameters.get("market")?.split(",")
      : [];
  const g8_toggle = url.includes("get-user-engagement") && (markets == "G8" || parameters.get("user_engagement_market") == "G8");

  const parameterEntries: any = { ...Object.fromEntries(parameters.entries()), date_range, model, topic, market, g8_toggle };

  delete parameterEntries["user_engagement_market"];
  delete parameterEntries["enrolled_user_market"];

  if (isRevenue && region == "United States") {
    parameterEntries["revenue_type"] = parameters.get("revenue_type")?.split(",");
  } else {
    delete parameterEntries["revenue_type"];
  }

  return newApiFetchV2(url, parameterEntries);
};

const postCCSEuropeDataV2 = (url: string, markets?: Array<string>, isGrouped?: boolean) => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);
  const interimDateRange = parameters.get("date_range");

  if (isCustomDate(interimDateRange as string) || isDigitalPerformanceDate(interimDateRange as string) || !interimDateRange)
    return { error: "wrong date parameters" };

  const date_range = parameters.get("date_range")?.split(",") || parameters.get("date_range");
  const market = parameters.get("market");
  const marketValue = markets
    ? markets
    : parameters.get("market") == "G8"
    ? g8Markets
    : parameters.get("market") == "G5"
    ? g5Markets
    : parameters.get("market") == "All countries"
    ? []
    : parameters.get("market")?.split(",");
  const parameterEntries: any = { ...Object.fromEntries(parameters.entries()), date_range, market: marketValue };

  delete parameterEntries["user_engagement_market"];
  delete parameterEntries["enrolled_user_market"];

  if (isGrouped && (market == "All" || market == "G8" || market == "G5")) {
    parameterEntries["market_group"] = market;
  }

  return newApiFetchV2(url, parameterEntries);
};

const postCCSForFY = (url: string) => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);
  const interimDateRange = parameters.get("date_range");

  if (isCustomDate(interimDateRange as string) || isDigitalPerformanceDate(interimDateRange as string) || !interimDateRange)
    return { error: "wrong date parameters" };

  const dateRangeArr = parameters.get("date_range")?.split(",");

  const model = parameters.get("model") === "All" ? [] : parameters.get("model")?.split(",");

  const parameterEntries = { ...Object.fromEntries(parameters.entries()), date_range: dateRangeArr, model };

  return newApiFetch(url, parameterEntries);
};

const postCCSForFYV2 = (url: string) => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);
  const interimDateRange = parameters.get("date_range");

  if (isCustomDate(interimDateRange as string) || isDigitalPerformanceDate(interimDateRange as string) || !interimDateRange)
    return { error: "wrong date parameters" };

  const dateRangeArr = parameters.get("date_range")?.split(",");

  const model = parameters.get("model") === "All" ? [] : parameters.get("model")?.split(",");
  const market = parameters.get("market") === "All countries" ? [] : parameters.get("market")?.split(",");

  const parameterEntries = { ...Object.fromEntries(parameters.entries()), date_range: dateRangeArr, model, market };

  return newApiFetchV2(url, parameterEntries);
};

const postAppReview = (url: string, app_store: string) => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);

  const interimDateRange = parameters.get("date_range");

  if (isCustomDate(interimDateRange as string) || isDigitalPerformanceDate(interimDateRange as string) || !interimDateRange)
    return { error: "wrong date parameters" };

  const modelParameterValue = parameters.get("model") === "All" ? [] : parameters.get("model")?.split(",");
  const date_range = parameters.get("date_range")?.split(",") || parameters.get("date_range");
  const model = modelParameterValue;
  const topic = parameters.get("topic") === "All" ? [] : parameters.get("topic")?.split(",");

  const parameterEntries = { ...Object.fromEntries(parameters.entries()), date_range, model, app_store, topic };

  return newApiFetch(url, parameterEntries);
};

const postAppReviewV2 = (url: string, app_store: string) => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);

  const interimDateRange = parameters.get("date_range");

  if (isCustomDate(interimDateRange as string) || isDigitalPerformanceDate(interimDateRange as string) || !interimDateRange)
    return { error: "wrong date parameters" };

  const modelParameterValue = parameters.get("model") ? parameters.get("model") : "";
  const date_range = parameters.get("date_range")?.split(",") || parameters.get("date_range");
  const model =
    !modelParameterValue || /all/gi.test(modelParameterValue as string)
      ? []
      : /Leaf/gi.test(modelParameterValue as string)
      ? "Leaf"
      : "Non-leaf";
  const topic = parameters.get("topic") === "All" ? [] : parameters.get("topic")?.split(",");
  const market = parameters.get("market") === "All countries" ? [] : parameters.get("market")?.split(",");

  const parameterEntries = { ...Object.fromEntries(parameters.entries()), date_range, model, app_store, topic, market };

  return newApiFetchV2(url, parameterEntries);
};

const postWarrantyStartDateData = () => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);
  const interimDateRange = parameters.get("date_range");

  if (isCustomDate(interimDateRange as string) || isDigitalPerformanceDate(interimDateRange as string) || !interimDateRange)
    return { error: "wrong date parameters" };

  const dateRangeArr = parameters.get("date_range")?.split(",");
  const model = parameters.get("model") === "All" ? [] : parameters.get("model")?.split(",");
  const market_groups = parameters.get("region") === "All" ? [] : parameters.get("region")?.split(",");
  const market = parameters.get("market") === "All countries" ? [] : parameters.get("market")?.split(",");

  const parameterEntries = { dates_list: dateRangeArr, model, market_groups, market };
  return newApiFetchV2("/europe-activation-ratio-reporting/get-activation-data", parameterEntries);
};

export const getVOC_callCentre = (breakdown?: string) =>
  postCCSDataV2(`/ccs/get-satisfaction?use_cache=true${breakdown ? "&breakdown=" + breakdown : ""}`);
export const postFetchCcsMainData = () => postCCSDataV2("/ccs/get-ccs-main-data?use_cache=true");
export const postFetchUserEngagement = (markets: string, breakdown?: boolean) =>
  postCCSDataV2(`/ccs/get-user-engagement?use_cache=true${breakdown ? "&breakdown=" + breakdown : ""}`, markets);
export const fetchBusinessRevenuePostData = (breakdown?: string) =>
  postCCSDataV2(`/ccs/get-business-revenue-data?use_cache=true${breakdown ? "&breakdown=" + breakdown : ""}`);
export const fetchSubscriptionSalesPostData = (breakdown?: string) =>
  postCCSDataV2(`/ccs/get-subscription-sales?use_cache=true${breakdown ? "&breakdown=" + breakdown : ""}`);
export const fetchConnectedCarsPostData = (breakdown?: string) =>
  postCCSDataV2(`/ccs/get-connected-cars-sold-vs-produced?use_cache=true${breakdown ? "&breakdown=" + breakdown : ""}`);
export const getTopUsedServicesData = () => postCCSDataV2("/ccs/get-top-used-services-data");
export const getAllReviewsData = () => postCCSDataV2("/ccs-app-ratings/reviews");
export const getAppRatingsData = (app_store: string, breakdown?: string) => {
  return postAppReviewV2(`/ccs/get-app-ratings?use_cache=true${breakdown ? "&breakdown=" + breakdown : ""}`, app_store);
};
export const getAppRatingsByMarketData = (app_store: string, breakdown?: string) => {
  return postAppReviewV2(`/ccs/get-app-ratings-by-market?use_cache=true${breakdown ? "&breakdown=" + breakdown : ""}`, app_store);
};
export const getAppReviewBreakdownData = (app_store: string) => postAppReviewV2("/ccs/get-app-review-app-store-breakdown-data", app_store);
export const getCCSSubscription = () => postCCSForFYV2("/ccs/get-ccs-subscriptions-data");
export const getWarrantyStartDateData = () => postWarrantyStartDateData();
export const getAppReviewByTopicData = () => {
  return postCCSDataV2("/ccs-app-ratings/topic-volume");
};
export const getAppSatisfactionData = () => {
  return postCCSDataV2("/ccs-app-ratings/sentiment-volume");
};
export const getAppAnalysisFeatureUsageData = () => {
  return postCCSDataV2("ccs-app-analysis/get-feature-usage-data?use_cache=true");
};

export const getAppAnalysisWebUsageData = () => {
  return postCCSDataV2("ccs-app-analysis/get-web-viewer-data?use_cache=true");
};

export const getAppRatingsDownloadsData = () => {
  return postCCSDataV2("ccs-app-ratings/downloads-data?use_cache=true");
};

export const getEngagementAppDownloadsData = (breakdown?: boolean) => {
  return postCCSDataV2(`ccs/get-app-downloads?use_cache=true${breakdown ? "&breakdown=" + breakdown : ""}`);
};

export const getAppDownloadsData = () => {
  return postCCSDataV2("ccs-app-ratings/downloads-data?use_cache=true");
};

export const getAppOpenRateData = () => {
  return postCCSDataV2("ccs-app-ratings/open-rate-data?use_cache=true");
};

export const getEuropeAppRatingsAvgData = () => {
  return postCCSEuropeDataV2("/ccs-app-ratings/get-europe-app-ratings-avg-data", undefined, true);
};

export const getEuropeAppRatingsAvgTotalData = (markets?: Array<string>) => {
  return postCCSEuropeDataV2("/ccs-app-ratings/get-europe-app-ratings-combined-avg-data", markets, true);
};

export const getEuropeAppRatingsTrendData = (markets?: Array<string>) => {
  return postCCSEuropeDataV2("/ccs-app-ratings/get-europe-app-ratings-trend-data", markets, true);
};

export const getAppRatingsAvgData = () => {
  return postCCSDataV2("/ccs-app-ratings/get-app-ratings-combined-avg-data");
};

export const getRenewalChurnRateData = (breakdown?: boolean) => {
  return postCCSDataV2(`ccs/get-renewal-and-churn-rate?use_cache=true${breakdown ? "&breakdown=" + breakdown : ""}`);
};

export const getCCSJourneyData = () => postCCSDataV2(`ccs-journey/get-ccs-journey`);

export const getG8ActivationRatioData = (breakdown?: boolean) => {
  return postCCSDataV2(`ccs/get-g8-private-activation-ratio?use_cache=true${breakdown ? "&breakdown=" + breakdown : ""}`);
};

export const getEnrolledCustomers = (markets: string, breakdown?: boolean) =>
  postCCSDataV2(`ccs/get-user-enrollment?use_cache=true${breakdown ? "&breakdown=" + breakdown : ""}`, markets);

export const getSalesRevenueSourceData = () => {
  return postCCSDataV2("/ccs/get-sales-source-dropdown", undefined, true);
};

export const getSalesRevenueData = (breakdown?: boolean) => {
  return postCCSDataV2(`/ccs/get-sales-revenue-data?use_cache=true${breakdown ? "&breakdown=" + breakdown : ""}`, undefined, true);
};

export const getCCSJourneyBannerData = () => {
  return postCCSDataV2("/ccs-journey/banner");
};
