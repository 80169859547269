import { ErrorBoundary } from "@sentry/react";
import React, { useEffect, useState } from "react";
import { KeyIndicatorMetric } from ".";
import { ErrorMsg } from "../../../components/AppMessages";
import { useVisible } from "../../../hooks";
import { convertToAbsoluteFloat } from "../../../utils/utilityFunctions";

interface Props {
  availableKbas: Array<{ name: string; key: string; metric_type: string; metric_visits: number | null; visits: number | null }>;
  availableLeads: Array<{ name: string; key: string; metric_type: string; metric_visits: number | null; visits: number | null }>;
  Current?: any;
  YoY?: any;
  PoP?: any;
  isCpoDashboard?: boolean;
  cpo_oce?: any;
  popLabel: string;
  showPoP: boolean;
}

export const KeyIndicatorsConversion = (props: Props) => {
  const { popLabel, availableKbas, availableLeads, PoP: WoW, Current, YoY, showPoP, isCpoDashboard, cpo_oce } = props;

  const {
    wrapperRef: kbaConversionTooltipRef,
    isVisible: kbaConversionTooltipVisible,
    setIsVisible: setKbaConversionTooltipVisible,
  } = useVisible(false);
  const {
    wrapperRef: leadConversionTooltipRef,
    isVisible: leadConversionTooltipVisible,
    setIsVisible: setLeadConversionTooltipVisible,
  } = useVisible(false);

  const [visitsToKbas, setVisitsToKbas] = useState<any>();
  const [visitsToKbasPop, setVisitsToKbasPop] = useState<any>();
  const [visitsToKbasYoy, setVisitsToKbasYoY] = useState<any>();
  const [visitsToLeads, setVisitsToLeads] = useState<any>();
  const [visitsToLeadsPop, setVisitsToLeadsPop] = useState<any>();
  const [visitsToLeadsYoy, setVisitsToLeadsYoY] = useState<any>();

  useEffect(() => {
    if (Current) {
      setVisitsToKbas(convertToAbsoluteFloat(Current[0].visits_to_kbas, 2, true));
      setVisitsToLeads(convertToAbsoluteFloat(Current[0].visits_to_leads, 2, true));
      setVisitsToLeadsPop(WoW ? WoW[0]?.pts_visits_to_leads : null);
      setVisitsToLeadsYoY(YoY ? YoY[0]?.pts_visits_to_leads : null);
      setVisitsToKbasPop(WoW ? WoW[0]?.pts_visits_to_kbas : null);
      setVisitsToKbasYoY(YoY ? YoY[0]?.pts_visits_to_kbas : null);
    }
  }, [WoW, Current, YoY, isCpoDashboard]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div id="conversions" className="indicator_funnel">
        <div className={"content conversion"} id="qv_to_kba" data-test-id={"qv_to_kba"} ref={kbaConversionTooltipRef}>
          <KeyIndicatorMetric
            availableMetric={availableKbas}
            metric_value={visitsToKbas ? visitsToKbas : null}
            metric_yoy={visitsToKbasYoy ? visitsToKbasYoy : null}
            metric_wow={visitsToKbasPop ? visitsToKbasPop : null}
            showPoP={showPoP}
            metric={"kbas"}
            popLabel={popLabel}
            showTooltip={true}
            kbaTooltipVisible={kbaConversionTooltipVisible}
            leadTooltipVisible={leadConversionTooltipVisible}
            setLeadTooltipVisible={setLeadConversionTooltipVisible}
            setKbaTooltipVisible={setKbaConversionTooltipVisible}
            isConversion={true}
            isCpoDashboard={isCpoDashboard}
            cpo_oce={cpo_oce ? cpo_oce : null}
            title="Visits to KBA visits"
          />
        </div>
        <div className={"content conversion"} id="qv_to_leads" data-test-id="qv_to_leads" ref={leadConversionTooltipRef}>
          <KeyIndicatorMetric
            availableMetric={availableLeads}
            metric_value={visitsToLeads ? visitsToLeads : null}
            metric_yoy={visitsToLeadsYoy ? visitsToLeadsYoy : null}
            metric_wow={visitsToLeadsPop ? visitsToLeadsPop : null}
            showPoP={showPoP}
            metric={"leads"}
            popLabel={popLabel}
            showTooltip={true}
            kbaTooltipVisible={kbaConversionTooltipVisible}
            leadTooltipVisible={leadConversionTooltipVisible}
            setLeadTooltipVisible={setLeadConversionTooltipVisible}
            setKbaTooltipVisible={setKbaConversionTooltipVisible}
            isConversion={true}
            cpo_oce={cpo_oce ? cpo_oce : null}
            isCpoDashboard={isCpoDashboard}
            title="Visits to digital lead visits"
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};
