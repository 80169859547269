import { CategoryAxis, ColumnSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import { connect, RootStateOrAny } from "react-redux";
import WebFont from "webfontloader";
import { BLACK, GRADE_MIX_COLORS, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { columnSeriesConfiguration, configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  theme: ThemeInt;
  chartName: string;
  isLoading: boolean;
  isPercentage?: boolean;
  currency: string;
}

interface ISeries {
  name: string;
  seriesColor: string;
}

class MsrpPerModelDifferenceChart extends Component<Props> {
  chartId = this.props.chartName;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, theme, isPercentage, currency } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.fontSize = "var(--chart_small)";
    this.chart.colors.list = GRADE_MIX_COLORS.map((colorMix: string) => color(colorMix));

    // Create Axes
    const categoryAxis = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "model";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.labels.template.disabled = true;

    const valueAxis = this.chart.xAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.minGridDistance = 40;
    valueAxis.extraMin = 0.2;
    valueAxis.extraMax = 0.1;
    valueAxis.renderer.minLabelPosition = 0.05;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return `${!isPercentage ? currency : ""}` + text + `${isPercentage ? "%" : ""}`;
    });

    const series = this.chart.series.push(new ColumnSeries());
    columnSeriesConfiguration(series, {
      name: "",
      valueY: "diff",
    });
    series.dataFields.categoryY = "model";
    series.dataFields.valueX = "diff";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.propertyFields.stroke = "color";
    series.columns.template.tooltipText = `{categoryY}: ${isPercentage ? "" : currency}{valueY.formatNumber('###,###')}${
      isPercentage ? "%" : ""
    }[/]`;
    if (series.tooltip) {
      series.tooltip.getFillFromObject = false;
      series.tooltip.autoTextColor = false;
      series.tooltip.background.fill = color(WHITE);
      series.tooltip.label.fill = color(BLACK);
    }
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

function mapStateToProps(state: RootStateOrAny) {
  const currency = state.msrp.currency;
  return {
    currency,
  };
}
export default connect(mapStateToProps)(MsrpPerModelDifferenceChart);
