import { capitalize } from "lodash";
import moment from "moment";
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { setSpecificParameter } from "../../../../actions";
import { ValueCombinedChart } from "../../../../components/Charts/CCSCockpit";
import { LegendMarker } from "../../../../components/Charts/components/LegendMarker";
import { UserEngagementMarketFilter } from "../../../../components/Filters/common/UserEngagementMarketFiler";
import { closeFilterOptions, closeMarketOptions } from "../../../../components/Filters/subs/helpers";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";
import { ToggleSwitch } from "../../../../components/Toogle";
import { ALL_OPTION, CYAN, LIGHT_CYAN, RED } from "../../../../constants";
import { DashboardBannerContext, FilterContext, ThemeContext } from "../../../../context";
import { formatToThreeDigits } from "../../../../utils/numberFormatting";
import { convertToAbsolutePercentage, convertToPercentage, isNull, plusSignNumbers } from "../../../../utils/utilityFunctions";

interface Props {
  value: number;
  comparisonValue: number | null;
  userEngagement: number;
  comparisonUserEngagement: number;
  isLoading: boolean;
  totalEnrollment: number;
  isTotal: boolean;
  setIsTotal: Dispatch<SetStateAction<boolean>>;
  comparisonPercentageValue?: number | null;
  chartData?: { data: Array<any> | undefined; chartId: string };
  onClick?: () => void;
  bpComparisonValue?: number | null;
  showMetric?: boolean;
  isSingleYear?: boolean;
}

const defaultUserEngagementTooltip =
  "User engagement %: Average monthly active users (from latest 3 months) / total enrolled users (from latest 3 months) in selected period of Nissan connect app. Avg monthly active users / Avg enrolled customers";
const monthlyUserEngagementTooltip = "User engagement= Monthly active users/total enrolled customers over all periods";

const defaultActiveUserTitle = "Avg monthly active users";
const monthlyActiveUserTitle = "monthly active users";

const barSeriesList = [{ name: "Active users", dataKey: "totalActiveUser" }];
const defaultBarSeriesList = [{ name: "User engagement", dataKey: "userEngagement" }];

export const ActiveUsersTile = (props: Props) => {
  const {
    value,
    userEngagement,
    comparisonPercentageValue,
    comparisonUserEngagement,
    chartData,
    isLoading,
    totalEnrollment,
    onClick: handleClick,
    isTotal,
    setIsTotal,
    bpComparisonValue,
    showMetric,
    isSingleYear,
  } = props;
  const themeContext = useContext(ThemeContext);

  const dispatch = useDispatch();

  const [highLight, setHighLight] = useState(false);

  const { date_range: dateRangeParam, region } = useSelector((state: RootStateOrAny) => state.parameters);
  const { user_engagement_geography } = useSelector((state: RootStateOrAny) => state.filters.cockpit_data);
  const [title, setTitle] = useState(defaultActiveUserTitle);
  const [userEngagementTooltip, setUserEngagementTooltip] = useState(defaultUserEngagementTooltip);

  const comparisonLabel = "YoY";

  const {
    market: marketParamValue,
    region: regionParamValue,
    brand: brandParamValue,
    model: modelParamValue,
    user_engagement_market: userEngagementMarket,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const { bannerHovered } = useContext(DashboardBannerContext);

  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const [marketFilterParam, setMarketFilterParam] = useState("All");

  const [europeBpToggle, setEuropeBpToggle] = useState(false);

  const [marketList, setMarketList] = useState<Array<string>>([]);

  useEffect(() => {
    if (user_engagement_geography) {
      const markets: Array<string> = [];
      user_engagement_geography.map((market: any) => {
        market.region == "europe" && markets.push(capitalize(market.market));
      });
      setMarketList(markets);
    }
  }, [user_engagement_geography]);

  useEffect(() => {
    if (marketParamValue === "United States" && bannerHovered) {
      setHighLight(true);
    } else {
      setHighLight(false);
    }
  }, [marketParamValue, bannerHovered]);

  useEffect(() => {
    if (dateRangeParam.split(",").length === 1) {
      setTitle(regionParamValue == "China" ? "Active users last one year" : monthlyActiveUserTitle);
      setUserEngagementTooltip(
        value
          ? regionParamValue == "China"
            ? `User engagement %: Average (active user last one year / total cumulative enrolled users) in selected period of Nissan connect app. Average (active users last one year (${formatToThreeDigits(
                value
              )}) / total enrolled users (${formatToThreeDigits(totalEnrollment)}))`
            : `User engagement %: Average monthly active users (from latest 3 months) / ${
                regionParamValue == "Japan" ? "total NC users" : "total enrolled users"
              } (from latest 3 months) in selected period of Nissan connect app. Avg monthly active users (${formatToThreeDigits(
                value
              )}) / ${regionParamValue == "Japan" ? "avg NC customers" : "Avg enrolled customers"} (${formatToThreeDigits(
                totalEnrollment
              )}) <br>
            ${regionParamValue == ALL_OPTION ? "China not included in Geography = 'All' calculations" : ""}
            `
          : monthlyUserEngagementTooltip
      );
    } else {
      setTitle(regionParamValue == "China" ? "Avg active users last one year" : defaultActiveUserTitle);
      setUserEngagementTooltip(
        value
          ? regionParamValue == "China"
            ? `User engagement %: Average (active user last one year / total cumulative enrolled users) in selected period of Nissan connect app. Average (active users last one year (${formatToThreeDigits(
                value
              )}) / total enrolled users (${formatToThreeDigits(totalEnrollment)}))`
            : `User engagement %: Average monthly active users (from latest 3 months) / ${
                regionParamValue == "Japan" ? "total NC users" : "total enrolled users"
              } (from latest 3 months) in selected period of Nissan connect app. Avg monthly active users (${formatToThreeDigits(
                value
              )}) / ${regionParamValue == "Japan" ? "avg NC customers" : "Avg enrolled customers"} (${formatToThreeDigits(
                totalEnrollment
              )}) <br>
              ${regionParamValue == ALL_OPTION ? "China not included in Geography = 'All' calculations" : ""}
          `
          : defaultUserEngagementTooltip
      );
    }
  }, [dateRangeParam, value, totalEnrollment]);

  const [isAllNull, setIsAllNull] = useState(false);
  useEffect(() => {
    setIsAllNull(
      chartData?.data?.every(
        (element) => isNaN(element.user_engagement_avg) || (isNull(element.user_engagement_avg) && isNull(element.active_user_avg))
      )
        ? true
        : false
    );
  }, [chartData]);

  const isComparisonValid = !isNull(comparisonPercentageValue) && typeof comparisonPercentageValue !== "undefined";
  const comparisonValueAsPercentage = convertToAbsolutePercentage(comparisonPercentageValue as number, 0);

  const handleToggleClick = () => {
    setIsTotal(!isTotal);
  };

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const marketFilterValue = useMemo(() => {
    let value = "All countries";
    if (marketList && userEngagementMarket) {
      const selectedMarketsArr = userEngagementMarket.split(",");
      value =
        selectedMarketsArr?.length === marketList.length
          ? "All"
          : selectedMarketsArr?.length > 1
          ? `${selectedMarketsArr?.length} markets selected`
          : userEngagementMarket;
    }
    return value;
  }, [marketList, userEngagementMarket]);

  const handleEuropeBpToggleClick = () => {
    setEuropeBpToggle(!europeBpToggle);
  };

  useEffect(() => {
    const marketGroup = europeBpToggle || regionParamValue != "Europe" ? "All" : "G8";
    dispatch(setSpecificParameter("user_engagement_market", marketGroup));
  }, [dateRangeParam, brandParamValue, europeBpToggle, regionParamValue, modelParamValue]);

  const formattedChartData = useMemo(() => {
    if (chartData?.data) {
      return chartData?.data.reduce((result: Array<any>, row: any) => {
        const { date } = row;
        const label = moment(date).format("MMM YY");

        return [...result, { label, ...row }];
      }, []);
    }
    return [];
  }, [chartData?.data]);

  const lineDataKey = useMemo(() => {
    if (marketParamValue === "United States" && !isTotal) {
      return "ppu_user_engagement_avg";
    }
    return "user_engagement_avg";
  }, [marketParamValue, isTotal]);

  const barDataKey = useMemo(() => {
    if (marketParamValue === "United States" && !isTotal) {
      return "ppu_active_user_avg";
    }
    return "active_user_avg";
  }, [marketParamValue, isTotal]);

  return (
    <div
      className={`tile engagement_tile ${!isTotal && marketParamValue === "United States" ? "active_users" : ""}`}
      id={"active_users"}
      data-test-id={"active_users"}
    >
      {marketParamValue === "United States" && (
        <ToggleSwitch
          activeToggleLabel={"Premium package users (PPU)"}
          inactiveToggleLabel={"Total users"}
          active={!isTotal}
          handleToggleClick={handleToggleClick}
          toggleClassName={"dp_toggle cockpit_toggle"}
        />
      )}
      {chartData && chartData.data && regionParamValue === "Europe" && (
        <div className="chartFilterContainer">
          <UserEngagementMarketFilter
            filterValue={marketFilterValue}
            marketsList={marketList}
            marketGroupsList={["G8"]}
            marketParamValue={userEngagementMarket}
            parameterName="user_engagement_market"
          />
        </div>
      )}
      {chartData && chartData.data == undefined && regionParamValue === "Europe" && (
        <ToggleSwitch
          activeToggleLabel={"All"}
          inactiveToggleLabel={"G8"}
          active={europeBpToggle}
          handleToggleClick={handleEuropeBpToggleClick}
          toggleClassName={"europe_bp_toggle cockpit_toggle"}
        />
      )}
      <div className="CCS_main_content" onClick={handleClick}>
        <div className="tile_content">
          <div className="child_content">
            <div className="tile_header">
              {chartData?.data && <LegendMarker color={CYAN} shape={"square"} />}
              {title}
              {regionParamValue == ALL_OPTION && (
                <Tooltip
                  position="top"
                  className={"normal_tippy"}
                  trigger="mouseenter"
                  animation="none"
                  interactive={true}
                  delay={1}
                  hideDelay={1}
                  duration={1}
                  title="China not included in Geography = 'All' calculations"
                  size={"small"}
                >
                  <span className="info_tooltip" />
                </Tooltip>
              )}
            </div>
            <div className="value" data-test-value={value ? value : "n/a"} data-test-id={"active_user_value"}>
              <span className="main">{value ? formatToThreeDigits(value) : "n/a"}</span>
            </div>

            {isComparisonValid && (
              <p className="period_comparison comparison">
                <span className="main">
                  <Ratings
                    value={comparisonPercentageValue ? (isNull(comparisonPercentageValue) ? "na" : String(comparisonPercentageValue)) : ""}
                    isPercentageValue={true}
                  />
                  <span className="comparison_value">{comparisonPercentageValue}%</span> {comparisonLabel}
                </span>
              </p>
            )}
          </div>
          <div className={`child_content ${highLight ? "isBlue" : ""}`}>
            <div className="tile_header">
              {chartData?.data && <LegendMarker shape={"line"} color={LIGHT_CYAN} />}User engagement{" "}
              <Tooltip
                position="top"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                delay={1}
                hideDelay={1}
                duration={1}
                title={userEngagementTooltip}
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
            </div>
            <div className="value" data-test-value={userEngagement ? userEngagement : "n/a"} data-test-id={"user_engagement"}>
              <span className="main">{userEngagement ? `${userEngagement}%` : "n/a"}</span>
            </div>

            {!isNull(comparisonUserEngagement) && typeof comparisonUserEngagement !== "undefined" && (
              <p className={"period_comparison comparison"}>
                <span className="main">
                  <span className={"comparison_value"}>
                    {isNull(comparisonUserEngagement) ? "n/a" : `${plusSignNumbers(comparisonUserEngagement)} PTS`}
                  </span>{" "}
                  {comparisonLabel}
                </span>
              </p>
            )}
            {isSingleYear &&
              !showMetric &&
              ((!isTotal && regionParamValue === "North America") || (isTotal && regionParamValue === "Europe" && !europeBpToggle)) &&
              brandParamValue !== "Infiniti" && (
                <p className="period_comparison comparison">
                  <span className="main">
                    <span className="comparison_value">
                      {isNull(bpComparisonValue) || isNull(userEngagement)
                        ? "n/a"
                        : `${plusSignNumbers(Math.round((userEngagement as number) * 100 - (bpComparisonValue as number) * 100))} PTS`}
                    </span>
                    vs BP
                  </span>
                </p>
              )}
            {((!isTotal && chartData?.data && regionParamValue === "North America" && brandParamValue !== "Infiniti") ||
              (isTotal &&
                chartData?.data &&
                regionParamValue === "Europe" &&
                userEngagementMarket == "G8" &&
                brandParamValue !== "Infiniti")) && (
              <>
                <div className="tile_header">
                  <LegendMarker color={RED} shape={"line"} />
                  BP target
                </div>
                {isSingleYear && (
                  <div
                    className="value"
                    data-test-value={bpComparisonValue ? "bpComparisonValue_enrollment" : "n/a"}
                    data-test-id={"sold_eligible_value"}
                  >
                    {modelParamValue.includes("All") ? (
                      <span className="main">
                        {isNull(bpComparisonValue) ? "n/a" : bpComparisonValue && convertToPercentage(bpComparisonValue, 0)}
                      </span>
                    ) : (
                      <span className="main">n/a </span>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {chartData && chartData.data && (
          <div className="metric_content">
            <ValueCombinedChart
              data={isAllNull ? [] : formattedChartData}
              lineDataKey={lineDataKey}
              lineSeriesName={"User engagement"}
              barDataKey={barDataKey}
              barSeriesName={"Active users"}
              chartId={"active_userChart"}
              theme={themeContext.theme}
              region={regionParamValue}
            />
            {/* <ValueCombinedChartNew
              data={isAllNull ? [] : chartData.data}
              barSeriesList={barSeriesList}
              lineSeriesList={lineSeriesList}
              chartId={"active_userChart"}
              theme={themeContext.theme}
            /> */}
          </div>
        )}
      </div>

      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};
