import { ErrorBoundary } from "@sentry/react";
import React, { useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../../components/AppMessages";
import ServiceGroupsChart from "../../../../components/Charts/TopFlop/ServiceGroupsChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../../context";

const RegionsGraph = () => {
  const themeContext = useContext(ThemeContext);

  const data = useSelector((state: RootStateOrAny) => state.top_flop.service_groups);
  const isLoading = useSelector((state: RootStateOrAny) => state.loading.top_flop_service_groups);

  return (
    <>
      <div className="service_groups_chart">
        <h3>Usage by service group</h3>
        <div className="main_content">
          <ErrorBoundary fallback={<ErrorMsg />}>
            {data && data.length > 0 ? <ServiceGroupsChart data={data} theme={themeContext.theme} /> : isLoading ? null : <NoDataMsg />}
          </ErrorBoundary>
        </div>
        <LoadingEllipsis isLoading={isLoading} />
      </div>
    </>
  );
};

export default RegionsGraph;
