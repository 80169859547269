import { ErrorBoundary } from "@sentry/react";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg } from "../../../components/AppMessages";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { dataConversion } from "../helpers/helpers";
import { VolumeIndicator } from "./VolumeIndicator";

export const PerformanceOverview = (): JSX.Element => {
  const { impressions_overview, visits_overview } = useSelector((state: RootStateOrAny) => state.seo_data);
  const searchEngine = useSelector((state: RootStateOrAny) => state.seo_parameters.search_engine);
  const [clicksData, setClicksData] = useState<any>([]);
  const [impressionsData, setImpressionsData] = useState<any>([]);
  const [visitsData, setVisitsData] = useState<any>([]);
  const { visits_overview: isVisitsLoading, impressions_overview: isImpressionsLoading } = useSelector(
    (state: RootStateOrAny) => state.loading
  );
  const impressionsDisabled = useSelector((state: RootStateOrAny) => state.seo_parameters.impressions_disabled);
  const visitsDisabled = useSelector((state: RootStateOrAny) => state.seo_parameters.visits_disabled);

  useEffect(() => {
    if (impressions_overview?.length !== 0) {
      const getClicksData = dataConversion(impressions_overview[0]?.totals, "clicks");
      const getImpressionsData = dataConversion(impressions_overview[0]?.totals, "impressions");
      setClicksData(getClicksData);
      setImpressionsData(getImpressionsData);
    } else {
      setClicksData([]);
      setImpressionsData([]);
    }
  }, [impressions_overview]);

  useEffect(() => {
    if (visits_overview?.length !== 0) {
      const getVisitsData = dataConversion(visits_overview[0]?.totals, "organic_visits");
      setVisitsData(getVisitsData);
    } else {
      setVisitsData([]);
    }
  }, [visits_overview]);

  return (
    <div className="seo_tile performance_overview">
      <h3>Performance overview</h3>
      <div className="performance_overview_container">
        <ErrorBoundary fallback={<ErrorMsg />}>
          <VolumeIndicator
            title={"Impressions"}
            metric={"impressions"}
            data={impressionsData}
            searchEngine={"Google search"}
            showTooltip={false}
            disabled={impressionsDisabled}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <VolumeIndicator
            title={"Clicks"}
            metric={"clicks"}
            data={clicksData}
            searchEngine={"Google search"}
            showTooltip={false}
            disabled={impressionsDisabled}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <VolumeIndicator
            title={"Natural search visits"}
            metric={"organic_visits"}
            data={visitsData}
            searchEngine={`${searchEngine === "All" ? "All search engines" : "Google search"}`}
            showTooltip={true}
            disabled={visitsDisabled}
          />
        </ErrorBoundary>
      </div>
      <LoadingEllipsis isLoading={isVisitsLoading || isImpressionsLoading} />
    </div>
  );
};
