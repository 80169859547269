import { ErrorBoundary } from "@sentry/react";
import React, { useContext } from "react";
import { ErrorMsg, NoDataMsg } from "../../../../components/AppMessages";
import CumulativeBarChart from "../../../../components/Charts/Midas/CumulativeBarChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../../context";
interface Props {
  chartData: any;
  loading: boolean;
  seriesList: Array<{ name: string; seriesColor: string }>;
  maxAmount: number;
  minAmount: number;
}

const CumulativeBarGraph = (props: Props) => {
  const themeContext = useContext(ThemeContext);
  const { chartData, loading, seriesList, maxAmount, minAmount } = props;

  return (
    <div className="cumulative_bar primary_background">
      <div className="fmi_tile_header">
        <div className="title">
          <h3>FMI by quarter</h3>
        </div>
        <div className="legend">
          {seriesList?.map(({ name, seriesColor }, index: number) => (
            <div className="legend_item" key={name}>
              <div className="legend_box" style={{ backgroundColor: seriesColor }}></div>
              {name.replace(new RegExp("_", "g"), " ")}
            </div>
          ))}
        </div>
      </div>
      <div className="main_content ">
        {chartData?.length && !loading ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <CumulativeBarChart
              data={chartData}
              minAmount={minAmount}
              isLoading={loading}
              theme={themeContext.theme}
              seriesList={seriesList}
              maxAmount={maxAmount}
            />
          </ErrorBoundary>
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available" />
        )}
      </div>
    </div>
  );
};

export default CumulativeBarGraph;
