import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect, useMemo } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchMidasFilters, fetchMidasFmiByQuarter, fetchMidasFmiLastDataRefresh, fetchMtpSpend, setPageName } from "../../../actions";
import "../../../assets/styles/pages/fmiRunRate.scss";
import "../../../assets/styles/pages/midasUnderConstruction.scss";
import MarketingInvastmentDashboardFilters from "../../../components/Filters/MarketingInvastmentDashboardFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { MIDAS_BTN_FILTERS, PMO_CHART_COLORS } from "../../../constants";
import { DashboardBannerContext } from "../../../context";
import CumulativeBarGraph from "./components/CumulativeBarGraph";
import CumulativeTrendline from "./components/CumulativeTrendline";
import MtpSpendGraph from "./components/MtpSpendGraph";
import MtpSpendYearGraph from "./components/MtpSpendYearGraph";

const FmiRunRate = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.midas_fmi_last_refresh_date);
  const { brand, nsc, local_currency_name, yr_scnr_rv, market, mc_group, subgroup } = useSelector(
    (state: RootStateOrAny) => state.parameters
  );

  const fmiLoading = useSelector((state: RootStateOrAny) => state.loading.midas_fmi_by_quarter);
  const fmiData = useSelector((state: RootStateOrAny) => state.midas.midas_fmi_by_quarter);
  const allYrScenarios = useSelector((state: RootStateOrAny) => state.filters.midas_filters.yr_scnr_rv);
  const currentPath = window.location.pathname;

  const pageName = "FMI run rate";

  useEffect(() => {
    Promise.all([
      // Sets page name
      dispatch(setPageName(pageName)),
      dispatch(fetchMidasFmiLastDataRefresh()),
    ]);
    setBannerText("Forex Rate based from latest BP");
    setShowBanner(false);
  }, [dispatch]);
  //Endpoints that depend on parameters
  useEffect(() => {
    Promise.all([dispatch(fetchMidasFmiByQuarter()), dispatch(fetchMtpSpend())]);
  }, [brand, yr_scnr_rv, nsc, local_currency_name, mc_group, subgroup, market]);

  useEffect(() => {
    dispatch(fetchMidasFilters());
  }, [brand, nsc, yr_scnr_rv, mc_group, subgroup, market]);

  const seriesList: Array<{ name: string; seriesColor: string }> = useMemo(() => {
    if (fmiData?.length) {
      const yrScenarios: string[] = Array?.from(new Set(fmiData?.map((val: { yr_scnr_rv: string }) => val?.yr_scnr_rv)));
      const sortedYearScenarios = yrScenarios?.sort(
        (a, b) =>
          allYrScenarios?.indexOf(a?.replace(/FY/g, "FY ")?.replace(/_/g, " ")) -
          allYrScenarios?.indexOf(b?.replace(/FY/g, "FY ")?.replace(/_/g, " "))
      );
      return sortedYearScenarios?.map((series: string, index: number) => {
        return { name: series, seriesColor: PMO_CHART_COLORS[index] };
      });
    }
    return [];
  }, [fmiData]);

  const fmiChartData = useMemo(() => {
    if (fmiData?.length)
      return fmiData?.map((val: { yr_scnr_rv: string; quarter: string; amount: number; cumulative_amount: number }) => {
        const result: any = { quarter: val.quarter };
        result[`${val.yr_scnr_rv}_cumulative_amount`] = val?.cumulative_amount;
        result[`${val.yr_scnr_rv}_amount`] = val?.amount;
        return result;
      });
    return [];
  }, [fmiData]);

  const merged = useMemo(() => {
    const quarters: string[] = [];
    const result: any = [];
    fmiChartData?.map((row: any) => {
      if (quarters?.includes(row?.quarter)) {
        const currentRow = result?.find((value: any) => value?.quarter == row?.quarter);
        const newRow = { ...currentRow, ...row };
        const idx = result?.findIndex((val: any) => val?.quarter == row?.quarter);
        result.splice(idx, 1);
        result.push(newRow);
      } else {
        quarters.push(row?.quarter);
        result.push(row);
      }
    });
    return result;
  }, [fmiChartData]);

  const { max: maxFmi, min: minFmi } = useMemo(() => {
    const maxValues: number[] = [];
    if (fmiData?.length) {
      fmiData?.map((val: { cumulative_amount: number; amount: number }) => {
        maxValues.push(val.amount);
      });
      return { max: Math.max(...maxValues), min: Math.min(...maxValues) };
    }
    return { max: 0, min: 0 };
  }, [fmiData]);

  const { max, min } = useMemo(() => {
    const maxValues: number[] = [];
    if (fmiData?.length) {
      fmiData?.map((val: { cumulative_amount: number; amount: number }) => {
        maxValues.push(val.cumulative_amount);
      });
      return { max: Math.max(...maxValues), min: Math.min(...maxValues) };
    }
    return { max: 0, min: 0 };
  }, [fmiData]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<MarketingInvastmentDashboardFilters filterBtns={MIDAS_BTN_FILTERS} isCustom />}
        dataDocumentation="midas"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="midas" />}
      >
        <ErrorBoundary>
          <div className="midas_sotu">
            {/* Top two charts / FMI charts */}
            <div className="fmi_cumulative primary_background">
              <CumulativeTrendline loading={fmiLoading} chartData={merged} seriesList={seriesList} maxAmount={max} minAmount={min} />
              <CumulativeBarGraph
                loading={fmiLoading}
                chartData={fmiChartData}
                seriesList={seriesList}
                maxAmount={maxFmi}
                minAmount={minFmi}
              />
            </div>

            {/* Bottom section */}
            <div className="mtp_spend primary_background">
              {/* Title area */}
              <div className="fmi_tile_header">
                <div className="title">
                  <h3>MTP spend</h3>
                </div>
                {/* Legend on Mtp Section */}
                <div className="legend">
                  {seriesList?.map(({ name, seriesColor }, index: number) => (
                    <div className="legend_item" key={name}>
                      <div className="legend_box" style={{ backgroundColor: seriesColor }}></div>
                      {name.replace(new RegExp("_", "g"), " ")}
                    </div>
                  ))}
                </div>
              </div>
              {/* Mtp charts  */}
              <div className="charts">
                <MtpSpendGraph seriesList={seriesList} />
                <MtpSpendYearGraph seriesList={seriesList} />
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default FmiRunRate;
