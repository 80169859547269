import {
  SET_ACTIVATION_COUNTRY_DATA,
  SET_ACTIVATION_DEALER_DATA,
  SET_ACTIVATION_MODEL_DATA,
  SET_ACTIVATION_OVERALL_DATA,
  SET_COCKPIT_ACTIVATION_DATA,
} from "../actions/actionTypes";

const initialState = {
  overall_data: {},
  country_data: {},
  model_data: {},
  dealer_data: {},
  cockpit_activation_breakdown: {},
};

const europeActivationReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_ACTIVATION_OVERALL_DATA:
      return { ...state, overall_data: action.payload };

    case SET_ACTIVATION_COUNTRY_DATA:
      return { ...state, country_data: action.payload };

    case SET_ACTIVATION_MODEL_DATA:
      return { ...state, model_data: action.payload };

    case SET_ACTIVATION_DEALER_DATA:
      return { ...state, dealer_data: action.payload };

    case SET_COCKPIT_ACTIVATION_DATA:
      return { ...state, cockpit_activation_breakdown: action.payload };
    default:
      return state;
  }
};

export default europeActivationReducer;
