import { SET_CCS_ANALYSIS_ENROLLMENT_RATE, SET_CCS_ANALYSIS_USER_ENGAGEMENT_RATE } from "../actions/actionTypes/ccsAnalysisActionTypes";

const initialState = {
  enrollment_rate_data: [],
  user_engagement_rate_data: [],
};

const ccsAnalysisReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_CCS_ANALYSIS_ENROLLMENT_RATE:
      return { ...state, enrollment_rate_data: action.payload };
    case SET_CCS_ANALYSIS_USER_ENGAGEMENT_RATE:
      return { ...state, user_engagement_rate_data: action.payload };
    default:
      return state;
  }
};

export default ccsAnalysisReducer;
