import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { ErrorMsg } from "../../../components/AppMessages";
import Collapsible from "../../../components/Collapsible/Collapsible";
import MarketingActivityCalendar from "../components/MarketingActivityCalendar";
import SearchInterestVsFMI from "../components/SearchIntersetVsFMI";
import SovModule from "../components/SovModule";

const SearchInterstSection = () => {
  return (
    <Collapsible myStyle="pmo_sotu marketing_activity_sotu" show={true} title="Marketing activities and investment analysis">
      <div className="marketing_activities_and_investment_analysis primary_tile_background">
        <ErrorBoundary fallback={<ErrorMsg />}>
          <SearchInterestVsFMI />
        </ErrorBoundary>
        <ErrorBoundary fallback={<ErrorMsg />}>
          <SovModule />
        </ErrorBoundary>
      </div>
      <ErrorBoundary fallback={<ErrorMsg />}>
        <MarketingActivityCalendar />
      </ErrorBoundary>
    </Collapsible>
  );
};

export default SearchInterstSection;
