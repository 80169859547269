import React, { Dispatch, Fragment, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { fetchGeoScopeData } from "../../../actions";
import { useDropdownVisible } from "../../../hooks";

interface GeographicalScopeInputProps {
  scope: string;
  setScope: Dispatch<string>;
  hiddenScopeValue: string;
  setHiddenScopeValue: Dispatch<string>;
}

export const GeographicalScopeInput = (Props: GeographicalScopeInputProps) => {
  const { scope, setScope, hiddenScopeValue, setHiddenScopeValue } = Props;
  const geoScopeData = useSelector((state: RootStateOrAny) => state.filters.geo_scope);
  const [geography, setGeography] = useState<Array<{ group: string; geo_scope: string[] }>>([]);
  const [selectedMarket, setSelectedMarket] = useState<boolean>(false);
  const { wrapperRef: geoRef, isVisible: showRegions, setIsVisible: setShowRegions } = useDropdownVisible(false);

  const dispatch = useDispatch();

  //Run dispatch action to fetch the filters data
  useEffect(() => {
    dispatch(fetchGeoScopeData());
  }, []);

  useEffect(() => {
    if (geoScopeData?.length) {
      setGeography(geoScopeData);
    }
  }, [geoScopeData]);

  //Function to reset the geography value and unselect all checkboxes when a user clicks on the input dropdown
  const handleRegionDropdown = () => {
    setShowRegions(!showRegions);
    const allCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll("multiselect-checkbox");
    allCheckboxes?.forEach((checkbox) => {
      checkbox.indeterminate = false;
      checkbox.checked = false;
    });
  };

  const handleShowMarkets = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { id: currentId, dataset },
    } = evt;
    const marketListId = dataset?.marketTarget;

    const currentElement = currentId ? document.getElementById(currentId) : undefined;
    const marketListElement = marketListId ? document.getElementById(marketListId) : undefined;

    const shownClass = "show-markets";
    const marketDownClass = "down";

    if (selectedMarket && marketListElement?.classList.contains(shownClass)) {
      document.querySelectorAll(`.region_list`)?.forEach((childElement) => childElement.classList.remove("down"));
      document.querySelectorAll(`.region_country_filter`)?.forEach((childElement) => childElement.classList.remove("show-markets"));
      setSelectedMarket(false);
    } else {
      document.querySelectorAll(`.region_list`)?.forEach((childElement) => childElement.classList.remove("down"));
      document.querySelectorAll(`.region_country_filter`)?.forEach((childElement) => childElement.classList.remove("show-markets"));
      currentElement?.classList.toggle(marketDownClass);
      marketListElement?.classList.add(shownClass);
      setSelectedMarket(true);
    }
  };

  const handleRegionSelect = (region: string) => {
    const allMarketCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(`.market_multiselect`);
    const allHQCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(`.hq_market_select`);
    document.querySelectorAll(`.region_list`)?.forEach((childElement) => childElement.classList.remove("down"));
    document.querySelectorAll(`.region_country_filter`)?.forEach((childElement) => childElement.classList.remove("show-markets"));
    allMarketCheckboxes?.forEach((checkbox) => (checkbox.checked = false));
    allHQCheckboxes?.forEach((checkbox) => {
      checkbox.indeterminate = false;
      checkbox.checked = false;
    });
    setScope(region);
    setHiddenScopeValue(region);
  };

  const handleMarketSelect = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { checked: isChecked, dataset, value },
    } = evt;

    const parentRegionValue = dataset?.region;

    const allMarketCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(`.market_multiselect`);
    const allSelectedMarketsCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(`.market_multiselect:checked`);
    const allSelectedRegionMarketsCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
      `.market_multiselect[data-region='${parentRegionValue}']:checked`
    );
    const allRegionMarketCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
      `.market_multiselect[data-region='${parentRegionValue}']`
    );

    const HQMarket: HTMLInputElement | null = document.querySelector(`[id='${parentRegionValue} HQ']`);
    const allHQCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(`.hq_market_select`);

    const allRegionMarkets: string[] = [];
    const allMarkets: string[] = [];
    const selectedMarkets: string[] = [];
    const allSelectedMarkets: string[] = [];

    allMarketCheckboxes?.forEach((checkbox) => allMarkets.push(checkbox.value));
    allRegionMarketCheckboxes?.forEach((checkbox) => allRegionMarkets.push(checkbox.value));
    allSelectedRegionMarketsCheckboxes?.forEach((checkbox) => selectedMarkets.push(checkbox.value));
    allSelectedMarketsCheckboxes?.forEach((checkbox) => allSelectedMarkets.push(checkbox.value));

    //Conditional statement to update the state of HQ market
    if (value.includes("HQ")) {
      if (HQMarket) {
        HQMarket.indeterminate = false;
        HQMarket.checked = isChecked;
      }
      allRegionMarketCheckboxes?.forEach((checkbox) => (checkbox.checked = isChecked));
    } else {
      if (allSelectedMarkets?.length > 0) {
        if (selectedMarkets?.length === 0 && HQMarket) {
          HQMarket.indeterminate = false;
          HQMarket.checked = false;
        } else {
          if (allRegionMarkets.every((market) => selectedMarkets.includes(market)) && HQMarket) {
            HQMarket.indeterminate = false;
            HQMarket.checked = true;
          } else {
            if (HQMarket) {
              HQMarket.indeterminate = true;
            }
          }
        }
      } else {
        if (allHQCheckboxes && allMarketCheckboxes) {
          allHQCheckboxes?.forEach((checkbox) => {
            checkbox.checked = false;
            checkbox.indeterminate = false;
          });
          allMarketCheckboxes?.forEach((checkbox) => {
            checkbox.checked = false;
            checkbox.indeterminate = false;
          });
        }
      }
    }
    //Depending on the state of the top level market (HQ markets) update the value of geography scope
    const indeterminateCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(`.hq_market_select:indeterminate`);
    const allHQSelectedCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(`.hq_market_select:checked`);

    //for when a market is selected but region has no HQ
    const allMarketsCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(`.market_multiselect:checked`);
    const allMarketsSelected: string[] = [];
    allMarketsCheckboxes?.forEach((checkbox) => allMarketsSelected.push(`${checkbox.value} (${checkbox.dataset.region})`));

    if (indeterminateCheckboxes?.length > 0) {
      const allMarketsCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(`.market_multiselect:checked`);
      const allMarkets: string[] = [];
      allMarketsCheckboxes?.forEach((checkbox) => allMarkets.push(`${checkbox.value} (${checkbox.dataset.region})`));
      allMarkets?.length > 1 ? setScope(`${allMarkets?.length} markets selected`) : setScope(allMarkets[0]);
      setHiddenScopeValue(allMarkets.join(", "));
    } else if (allHQSelectedCheckboxes?.length > 0) {
      const regionsSelected: string[] = [];
      allHQSelectedCheckboxes?.forEach((checkbox) => regionsSelected.push(checkbox.value));

      regionsSelected?.length > 1 ? setScope(`${regionsSelected?.length} regions selected`) : setScope(regionsSelected[0]);
      setHiddenScopeValue(regionsSelected.join(", "));
    } //for when a market is selected but region has no HQ
    else if (allMarketsSelected?.length) {
      allMarketsSelected?.length > 1 ? setScope(`${allMarketsSelected?.length} markets selected`) : setScope(allMarketsSelected[0]);
      setHiddenScopeValue(allMarketsSelected.join(", "));
    } else if (indeterminateCheckboxes?.length === 0 && allHQSelectedCheckboxes?.length === 0) {
      setScope("");
      setHiddenScopeValue("");
    }
  };

  return (
    <div className="input_field" id="scope_input" ref={geoRef}>
      <label htmlFor="scope">Geographical scope</label>
      <div className="field">
        <div className="dropdown" onClick={handleRegionDropdown}>
          <input type="hidden" id="scope_hidden_filter" value={hiddenScopeValue} />
          <input readOnly type="text" name="scope" id="scope" value={scope} />
          <span className="arrow_down"></span>
        </div>
        {showRegions && (
          <ul className="dropdown-options" id="geography_options">
            {geography
              ? geography.map((row, idx) => {
                  const { group: region, geo_scope: markets } = row;
                  const marketUlId = `market_${region}${idx}`;
                  const showMarkets = markets?.length > 1;
                  return (
                    <Fragment key={region}>
                      {showMarkets ? (
                        <div
                          className="nested_list region_list"
                          onClick={handleShowMarkets}
                          data-market-target={marketUlId}
                          id={`${region}-div-${idx}`}
                        >
                          <li>{region}</li>
                          <span className={`nested_list_span market-arrow-span`}>
                            <span className={`market-arrow`}></span>
                          </span>
                        </div>
                      ) : (
                        <div className="nested_list region_list" onClick={() => handleRegionSelect(region)}>
                          <li>{region}</li>
                          <span className={`nested_list_span market-arrow-span`}></span>
                        </div>
                      )}
                      <ul className={`region_country_filter`} id={marketUlId}>
                        {showMarkets &&
                          markets?.map((market: string, ind: number) => {
                            return (
                              <div className="multiselectDiv" key={ind}>
                                {market.includes("HQ") ? (
                                  <>
                                    <input
                                      type="checkbox"
                                      className="multiselect-checkbox hq_market_select"
                                      id={market}
                                      name={market}
                                      value={market}
                                      data-region={region}
                                      onChange={handleMarketSelect}
                                    />
                                    <label className="multiselect-label" htmlFor={market}>
                                      {market}
                                    </label>
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="checkbox"
                                      className="multiselect-checkbox market_multiselect"
                                      id={`${market}_${region}`}
                                      name={market}
                                      value={market}
                                      data-region={region}
                                      onChange={handleMarketSelect}
                                    />

                                    <label className="multiselect-label" htmlFor={`${market}_${region}`}>
                                      {market}
                                    </label>
                                  </>
                                )}
                              </div>
                            );
                          })}
                      </ul>
                    </Fragment>
                  );
                })
              : null}
          </ul>
        )}
      </div>
    </div>
  );
};
