import { CategoryAxis, ColumnSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { CYAN } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import { configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  granularity: string;
  theme: ThemeInt;
}

class ServiceGroupsChart extends Component<Props> {
  static defaultProps = {
    granularity: "monthly",
  };

  chart!: XYChart;
  chartId = "serviceGroupsGraph";

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.colors.list = [color(CYAN)];

    // Create Axes
    const categoryAxis = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "service_group";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 10;

    const valueAxis = this.chart.xAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter.numberFormat = "#.0a";
    // Creating Series
    const series = this.chart.series.push(new ColumnSeries());
    series.dataFields.valueX = "events";
    series.dataFields.categoryY = "service_group";

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return a.events - b.events;
    });
  }
  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={"serviceGroupsGraph"} data-test-id={"serviceGroupsGraph"} className={"graph"} />
      </div>
    );
  }
}

export default ServiceGroupsChart;
