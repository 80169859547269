import { ErrorBoundary } from "@sentry/react";
import React, { Dispatch, SetStateAction, useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import { GranularityOptions } from "../../../components/Charts/components";
import { VisitsTrendChart } from "../../../components/Charts/DigitalPerformance";
import { METRIC_TITLES } from "../../../constants";
import { ThemeContext } from "../../../context";
import { attachAnnotationsToChartData } from "../../../utils/annotationFunctions";

interface Props {
  granularity: string;
  metric: string;
  breakdownTitle: string;
  onHandleRadioClick: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  toggleAnnotationsDialog: () => void;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
  setAnnotationDefaultRegion: Dispatch<SetStateAction<string | undefined>>;
  setAnnotationDefaultMarket: Dispatch<SetStateAction<string | undefined>>;
  setAnnotationDefaultModel: Dispatch<SetStateAction<string | undefined>>;
  isCpoDashboard?: boolean;
}

export const VisitsTrendGraph = (props: Props) => {
  const {
    granularity,
    breakdownTitle,
    metric,
    onHandleRadioClick,
    toggleAnnotationsDialog,
    setAnnotationStartDate,
    setAnnotationEndDate,
    setAnnotationDefaultMetric,
    setAnnotationDefaultRegion,
    setAnnotationDefaultMarket,
    setAnnotationDefaultModel,
    isCpoDashboard,
  } = props;
  const themeContext = useContext(ThemeContext);
  const data = useSelector((state: RootStateOrAny) =>
    isCpoDashboard ? state.cpo_digital_performance.cpo_visits_trend.Current : state.digital_performance.visits_trend.Current ?? []
  );
  // const markets = useSelector((state: RootStateOrAny) => state.digital_performance.visits_trend.all.markets);
  const cpoMarkets = useSelector((state: RootStateOrAny) => state.cpo_digital_performance.cpo_breakdown_trend_markets);
  const isLoading = useSelector((state: RootStateOrAny) => state.loading.visits_breakdown_graph);
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const annotations = useSelector((state: RootStateOrAny) => state.digital_performance.visits_chart_annotations);

  const graphData = useMemo(() => {
    if (data && data.length > 0) {
      const result: any[] = [];
      data.map((item: Record<string, string | Array<any>>) => {
        const [market] = Object.values(item);
        Array.isArray(item.data) &&
          item.data.map((dataItem: { row_date: string; visits: number; kbas: number; leads: number }) => {
            const itemIndexInResults = result.findIndex((element) => element.date == dataItem.row_date);
            if (itemIndexInResults != -1) {
              result[itemIndexInResults][`${market}_visits`] = dataItem.visits;
              result[itemIndexInResults][`${market}_kbas`] = dataItem.kbas;
              result[itemIndexInResults][`${market}_leads`] = dataItem.leads;
            } else {
              result.push({
                date: dataItem.row_date,
                [`${market}_visits`]: dataItem.visits,
                [`${market}_kbas`]: dataItem.kbas,
                [`${market}_leads`]: dataItem.leads,
              });
            }
          });
      });
      return result;
    } else {
      return [];
    }
  }, [data, granularity, isCpoDashboard]);

  const graphDataWithAnnotations = useMemo(() => {
    if (annotations && graphData) {
      return attachAnnotationsToChartData(annotations, graphData, granularity, "visits");
    } else {
      return graphData;
    }
  }, [graphData, annotations, granularity]);

  const marketList: Array<string> = useMemo(() => {
    if (data) {
      return data.map((item: Record<string, string | Array<any>>) => {
        const [first] = Object.keys(item);
        return item[first];
      });
    } else {
      return [];
    }
  }, [data, metric, isCpoDashboard, cpoMarkets]);

  return (
    <>
      <h3>
        {METRIC_TITLES[metric]} trend by {breakdownTitle}
      </h3>
      <div className="main_content">
        <ErrorBoundary fallback={<ErrorMsg />}>
          {graphData.length > 0 ? (
            <VisitsTrendChart
              isCpoDashboard={isCpoDashboard}
              data={graphDataWithAnnotations}
              granularity={granularity}
              seriesList={marketList}
              metric={metric}
              theme={themeContext.theme}
              annotations={annotations}
              breakdownTitle={breakdownTitle}
              toggleAnnotationsDialog={toggleAnnotationsDialog}
              setAnnotationEndDate={setAnnotationEndDate}
              setAnnotationStartDate={setAnnotationStartDate}
              setAnnotationDefaultMetric={setAnnotationDefaultMetric}
              setAnnotationDefaultRegion={setAnnotationDefaultRegion}
              setAnnotationDefaultMarket={setAnnotationDefaultMarket}
              setAnnotationDefaultModel={setAnnotationDefaultModel}
            />
          ) : isLoading ? null : (
            <NoDataMsg />
          )}
        </ErrorBoundary>
      </div>
      {graphData.length > 0 && (
        <GranularityOptions
          onHandleRadioClick={onHandleRadioClick}
          granularity={granularity}
          name={"visits_trend"}
          dateValue={parameters.date_range}
        />
      )}
    </>
  );
};
