import { CategoryAxis, ColumnSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { LIME_GREEN, ORANGE } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import { columnSeriesConfiguration, configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<{ label: string; [index: string]: number | string }>;
  theme: ThemeInt;
}

class StackedSatisfactionChart extends Component<Props> {
  chartId = "satisfactionChart";
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.colors.list = [color(LIME_GREEN), color(ORANGE)];

    // Creating Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "label";

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.calculateTotals = true;

    valueAxis.strictMinMax = true;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + "%";
    });

    // Creating Series
    const series = this.chart.series.push(new ColumnSeries());
    columnSeriesConfiguration(series, {
      name: "Positive",
      valueY: "positive",
      stacked: true,
    });
    series.dataFields.categoryX = "label";
    series.columns.template.tooltipText = "{categoryX} {name}: {valueY.formatNumber('#.')}%";
    series.columns.template.width = percent(65);
    const series2 = this.chart.series.push(new ColumnSeries());
    columnSeriesConfiguration(series2, {
      name: "Negative",
      valueY: "negative",
      stacked: true,
    });
    series2.dataFields.categoryX = "label";
    series2.columns.template.tooltipText = "{categoryX} {name}: {valueY.formatNumber('#.')}%";
    series2.columns.template.width = percent(65);
  }
  render() {
    return <div id={this.chartId} className={"metricChart"} />;
  }
}

export default StackedSatisfactionChart;
