import { retry, takeLatest } from "@redux-saga/core/effects";
import { put } from "redux-saga/effects";
import {
  setAdminBrandsData,
  setAdminChannelBreakdownsData,
  setAdminChannelsData,
  setAdminMarketsData,
  setAdminMetricsData,
  setAdminModelsData,
  setAdminRegionsData,
  setAdminViewsData,
} from "../actions";
import {
  FETCH_ADMIN_BRANDS,
  FETCH_ADMIN_CHANNELS_DATA,
  FETCH_ADMIN_CHANNEL_BREAKDOWNS_DATA,
  FETCH_ADMIN_MARKETS,
  FETCH_ADMIN_METRICS_DATA,
  FETCH_ADMIN_NEW_MODELS,
  FETCH_ADMIN_REGIONS,
  FETCH_ADMIN_VIEWS,
} from "../actions/actionTypes";
import {
  getBrandsData,
  getChannelBreakdownsData,
  getChannelsData,
  getMarketsData,
  getMetricsData,
  getModelsData,
  getRegionsData,
  getViewsData,
} from "../api/adminApi";
import { secondsDelay } from "../api/utils";
import { Brand, Channel, ChannelBreakdown, Market, Metric, Model, Region, View } from "../constants/interface/admin/common";

// Watcher sagas
export function* watchFetchAdminBrandsData() {
  yield takeLatest(FETCH_ADMIN_BRANDS, fetchAdminBrandsData);
}

export function* watchFetchAdminMarketsData() {
  yield takeLatest(FETCH_ADMIN_MARKETS, fetchAdminMarketsData);
}

export function* watchFetchAdminRegionsData() {
  yield takeLatest(FETCH_ADMIN_REGIONS, fetchAdminRegionsData);
}

export function* watchFetchAdminViewsData() {
  yield takeLatest(FETCH_ADMIN_VIEWS, fetchAdminViewsData);
}

export function* watchFetchAdminModelsData() {
  yield takeLatest(FETCH_ADMIN_NEW_MODELS, fetchAdminNewModelsData);
}

export function* watchFetchAdminChannelsData() {
  yield takeLatest(FETCH_ADMIN_CHANNELS_DATA, fetchAdminChannelsData);
}

export function* watchFetchAdminChannelBreakdownsData() {
  yield takeLatest(FETCH_ADMIN_CHANNEL_BREAKDOWNS_DATA, fetchAdminChannelBreakdownsData);
}

export function* watchFetchAdminMetricsData() {
  yield takeLatest(FETCH_ADMIN_METRICS_DATA, fetchAdminMetricsData);
}
// Worker sagas
export function* fetchAdminBrandsData() {
  try {
    const response: Brand = yield retry(4, secondsDelay, getBrandsData);

    if (!("error" in response)) {
      yield put(setAdminBrandsData(response));
    }
  } catch (err) {
    console.log("failed to fetch brands data", err);
  }
}

export function* fetchAdminMarketsData() {
  try {
    const response: Market = yield retry(4, secondsDelay, getMarketsData);

    if (!("error" in response)) {
      yield put(setAdminMarketsData(response));
    }
  } catch (err) {
    console.log("failed to fetch markets data", err);
  }
}

export function* fetchAdminRegionsData() {
  try {
    const response: Region = yield retry(4, secondsDelay, getRegionsData);

    if (!("error" in response)) {
      yield put(setAdminRegionsData(response));
    }
  } catch (err) {
    console.log("failed to fetch regions data", err);
  }
}

export function* fetchAdminViewsData() {
  try {
    const response: View = yield retry(4, secondsDelay, getViewsData);

    if (!("error" in response)) {
      yield put(setAdminViewsData(response));
    }
  } catch (err) {
    console.log("failed to fetch views data", err);
  }
}

export function* fetchAdminNewModelsData() {
  try {
    const response: Model = yield retry(4, secondsDelay, getModelsData);

    if (!("error" in response)) {
      yield put(setAdminModelsData(response));
    }
  } catch (err) {
    console.log("failed to fetch models data", err);
  }
}

export function* fetchAdminChannelsData() {
  try {
    const response: Channel = yield retry(4, secondsDelay, getChannelsData);

    if (!("error" in response)) {
      yield put(setAdminChannelsData(response));
    }
  } catch (err) {
    console.log("failed to fetch channels data", err);
  }
}

export function* fetchAdminChannelBreakdownsData() {
  try {
    const response: ChannelBreakdown = yield retry(4, secondsDelay, getChannelBreakdownsData);

    if (!("error" in response)) {
      yield put(setAdminChannelBreakdownsData(response));
    }
  } catch (err) {
    console.log("failed to fetch channels breakdowns data", err);
  }
}

export function* fetchAdminMetricsData() {
  try {
    const response: Metric = yield retry(4, secondsDelay, getMetricsData);

    if (!("error" in response)) {
      yield put(setAdminMetricsData(response));
    }
  } catch (err) {
    console.log("failed to fetch metrics data", err);
  }
}

export default "";
