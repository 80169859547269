export const getCurrency = (region: string) => {
  switch (region) {
    case "China":
      return "¥";

    case "Japan":
      return "¥";

    case "United States" || "United states":
      return "$";

    case "North America":
      return "$";

    default:
      return "";
  }
};

export const getCurrencyPMO = (region: string) => {
  switch (region) {
    case "Australia":
      return "AU$";

    case "Norway":
      return "Nkr";

    case "Canada":
      return "CA$";

    case "India":
      return "Rs";

    case "South Africa":
      return "R";

    case "Philippines":
      return "₱";

    case "Brazil":
      return "R$";

    case "Japan":
      return "¥";

    case "Mexico":
      return "Mx$ ";

    case "Thailand":
      return "฿";

    case "United Arab Emirates":
      return "د.إ";

    case "United States" || "United states":
      return "$";

    case "Saudi Arabia":
      return "SR";

    case "United Kingdom":
      return "£";

    default:
      return "€";
  }
};
