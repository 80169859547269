export const generateChartColors = (theme: string, disabled: boolean) => {
  const colors = disabled
    ? {
        green: "#a4a4a4",
        pink: "#a4a4a4",
        purple: "#a4a4a4",
        yellow: "#a4a4a4",
        white: "#a4a4a4",
      }
    : {
        green: "#03dac6",
        pink: "#ff7597",
        purple: "#bb86fc",
        yellow: "#f2c043",
        white: theme === "light" ? "#2c394e" : "#ffffff",
      };
  return colors;
};
