import { CategoryAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { CCS_BUSINESS_REVENUE_AND_SUBSCRIPTION_COLORS } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import { configureCategoryAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  seriesName: string;
  seriesList: Array<string>;
  dataKey: string;
  data: Array<{ label: string }> & Array<{ [index: string]: number }>;
  theme: ThemeInt;
  normalized: boolean;
}

class SplitValueLineChart extends Component<Props> {
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.props.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.props.chartId);
  }

  initChart() {
    const { data, seriesList, chartId, theme, normalized } = this.props;
    if (data.length) {
      this.chart = create(chartId, XYChart);
      oceChartConfiguration(this.chart);
      this.chart.data = data;
      this.chart.numberFormatter.numberFormat = "#a";

      // Creating Axes
      const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
      configureCategoryAxis(categoryAxis, theme);
      categoryAxis.dataFields.category = "label";
      const screenWidth = window.matchMedia("(min-width: 1920px)");
      categoryAxis.renderer.minGridDistance = screenWidth.matches ? 95 : 75;

      const valueAxis = this.chart.yAxes.push(new ValueAxis());
      configureValueAxis(valueAxis, theme);
      valueAxis.min = 0;
      if (normalized) {
        valueAxis.max = 1;
      }
      valueAxis.title.rotation = 0;
      valueAxis.title.fontSize = 22;
      valueAxis.numberFormatter = new NumberFormatter();
      valueAxis.numberFormatter.numberFormat = normalized ? "#" : "#a";

      this.createSeries(seriesList, valueAxis);
    }
  }

  createSeries(seriesList: Array<string>, valueAxis: ValueAxis) {
    seriesList.forEach((seriesValue, idx) => {
      // Creating Series
      const series = this.chart.series.push(new LineSeries());
      const bullet = lineSeriesConfiguration(series, {
        name: seriesValue === "North America" ? "United States" : seriesValue,
        valueY: `${this.props.dataKey}_${seriesValue.replace(/\s/g, "_")}`,
      });
      series.stroke = color(CCS_BUSINESS_REVENUE_AND_SUBSCRIPTION_COLORS[idx]);
      series.dataFields.categoryX = "label";
      series.strokeWidth = 2;

      bullet.fill = color(CCS_BUSINESS_REVENUE_AND_SUBSCRIPTION_COLORS[idx]);
      bullet.tooltipText = "{categoryX} {name}: {valueY.formatNumber('#.##')}";
    });
  }

  render() {
    return <div id={this.props.chartId} className={"metricChart"} />;
  }
}

export default SplitValueLineChart;
