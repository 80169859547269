import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FilterContext } from "../../../context";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../../utils/utilityFunctions";
import { setParameters, setSpecificParameter } from "../../../actions";
import {
  ALL_OPTION_NO_SPACE,
  DEFAULT_VOC_PARAMETERS,
  DEFAULT_VOC_QUICK_AFTER_SALES_PARAMETERS,
  MIN_SELECTABLE_DATE,
  VOC_DATE_FILTER_OPTIONS,
} from "../../../constants";
import { FiltersLayout } from "../../Layouts";
import { ErrorBoundary } from "@sentry/react";
import { ErrorMsg } from "../../AppMessages";
import { DateFilter, DefaultFilter, MultiSelectFilter } from "../common";
import { closeFilterOptions, closeMarketOptions } from "../subs/helpers";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import moment from "moment";
import { Button } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { getQuickVocSalesFilters } from "../../../api/quickVocSalesApi";
import PurchaseFunnelDateFilter from "../common/PurchaseFunnelDateFilter";
import { format, parse } from "date-fns";
import MultiSelectSearchFilter from "../common/MultiSelectSearchFilter";

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
  queryFn: () => Record<string, any>;
  pageName: string;
}

const VocQuickAfterSalesFilters = withRouter((props: RouteComponentProps & Props) => {
  const { filterBtns, history, queryFn, pageName } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const {
    date_range: dateRangeParamValue,
    brand: brandParamValue,
    market: marketParamValue,
    dealer: dealerParamValue,
    model: modelParamValue,
    model_type: modelTypeParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const [brandFilterValue, setBrandFilterValue] = useState<string>("");
  const [marketFilterValue, setMarketFilterValue] = useState<string>("");
  const [dealerFilterValue, setDealerFilterValue] = useState<string>("");
  const [modelFilterValue, setModelFilterValue] = useState<string>("");
  const [modelTypeFilterValue, setModelTypeFilterValue] = useState<string>("");

  const { data, refetch } = useQuery({
    queryKey: ["filtersData", history.location.search],
    queryFn: queryFn,
    initialData: {},
    enabled: false,
  });

  const { start_date, end_date, brand: brands, market: markets, dealer: dealers, model: models, vehicle_type: modelTypes } = data;

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet(pageName);
    if (setDefaultFilters || dateRangeParamValue !== DEFAULT_VOC_QUICK_AFTER_SALES_PARAMETERS.date_range) {
      dispatch(setParameters(DEFAULT_VOC_QUICK_AFTER_SALES_PARAMETERS));
      currentDefaultFiltersSet(pageName);
    }
  }, []);

  //Sets the initial start and end selectable date to be the response from the BE
  const [marketDataAvailability, setMarketDataAvailability] = useState<{ start: string; end: string }>({
    start: start_date ?? MIN_SELECTABLE_DATE,
    end: end_date || moment().subtract(1, "days").format("DD/MM/YYYY"),
  });

  useEffect(() => {
    if (!dateRangeParamValue && start_date) {
      const dateString = format(parse(start_date, "y-MM-dd", new Date()), "MMMM y");
      dispatch(setSpecificParameter("date_range", `${dateString} - ${dateString}`));
    }
  }, [dateRangeParamValue, start_date]);

  useEffect(() => {
    if (markets && marketParamValue != "All") {
      const selectedMarket = marketParamValue?.split(",");
      const marketsAreValid = selectedMarket?.every((market: string) => [...markets].includes(market));
      !marketsAreValid && dispatch(setSpecificParameter("market", "All"));
    }
  }, [marketParamValue, markets]);

  useEffect(() => {
    if (dealers && dealerParamValue != "All") {
      const selectedDealer = dealerParamValue?.split(/,(?=\w+)/);
      const dealersAreValid = selectedDealer?.every((dealer: string) => [...dealers].includes(dealer));
      !dealersAreValid && dispatch(setSpecificParameter("dealer", "All"));
    }
  }, [dealerParamValue, dealers]);

  useEffect(() => {
    if (models && modelParamValue != "All") {
      const selectedModel = modelParamValue?.split(",");
      const modelsAreValid = selectedModel?.every((model: string) => [...models].includes(model));
      !modelsAreValid && dispatch(setSpecificParameter("model", "All"));
    }
  }, [modelParamValue, models]);

  useEffect(() => {
    if (modelTypes && modelTypeParamValue != "All") {
      const selectedModelType = modelTypeParamValue?.split(",");
      const modelTypesAreValid = selectedModelType?.every((modelType: string) => [...modelTypes].includes(modelType));
      !modelTypesAreValid && dispatch(setSpecificParameter("model_type", "All"));
    }
  }, [modelTypeParamValue, modelTypes]);

  useEffect(() => {
    if (brands && brandParamValue) {
      const selectedBrands =
        brands?.length === brandParamValue.split(",")?.length
          ? "All"
          : brandParamValue.includes(",")
            ? `${brandParamValue.split(",")?.length} brands selected`
            : brandParamValue;
      setBrandFilterValue(selectedBrands);
    }
  }, [brandParamValue, brands]);

  useEffect(() => {
    if (markets && marketParamValue) {
      const selectedMarkets =
        markets?.length === marketParamValue.split(",")?.length
          ? "All"
          : marketParamValue.includes(",")
            ? `${marketParamValue.split(",")?.length} markets selected`
            : marketParamValue;
      setMarketFilterValue(selectedMarkets);
    }
  }, [marketParamValue, markets]);

  useEffect(() => {
    if (dealers && dealerParamValue) {
      const selectedDealers =
        dealers?.length === dealerParamValue.split(/,(?=\w+)/)?.length
          ? "All"
          : dealerParamValue.includes(",")
            ? `${dealerParamValue.split(",")?.length} dealers selected`
            : dealerParamValue;
      setDealerFilterValue(selectedDealers);
    }
  }, [dealerParamValue, dealers]);

  useEffect(() => {
    if (models && modelParamValue) {
      const selectedModels =
        models?.length === modelParamValue.split(",")?.length
          ? "All"
          : modelParamValue.includes(",")
            ? `${modelParamValue.split(",")?.length} models selected`
            : modelParamValue;
      setModelFilterValue(selectedModels);
    }
  }, [modelParamValue, models]);

  useEffect(() => {
    if (modelTypes && modelTypeParamValue) {
      const selectedModelTypes =
        modelTypes?.length === modelTypeParamValue.split(",")?.length
          ? "All"
          : modelTypeParamValue.includes(",")
            ? `${modelTypeParamValue.split(",")?.length} model types selected`
            : modelTypeParamValue;
      setModelTypeFilterValue(selectedModelTypes);
    }
  }, [modelTypeParamValue, models]);

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));
      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "VOC",
        page: pageName,
      });
    }
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParamValue);
    params.set("brand", brandParamValue);
    params.set("market", marketParamValue);
    params.set("dealer", dealerParamValue);
    params.set("model", modelParamValue);
    params.set("model_type", modelTypeParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [dateRangeParamValue, brandParamValue, marketParamValue, dealerParamValue, modelParamValue, modelTypeParamValue]);

  const resetFilters = useCallback(() => {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(DEFAULT_VOC_PARAMETERS)) {
      params.set(key, value);
    }

    history.push({ search: `?${params.toString()}` });
    window.location.reload();
  }, [history]);

  const renderDashboardClassificationButtons = () => {
    return (
      <>
        <Button variant={"outlined"} className={`filter_btn_element`} size={"small"} disabled>
          Try
        </Button>
        <Button
          variant={"outlined"}
          className={`filter_btn_element ${pageName == "Quick VOC Sales" ? "active" : ""}`}
          size={"small"}
          disabled
        >
          Buy
        </Button>
        <Button
          variant={"outlined"}
          className={`filter_btn_element ${pageName == "Quick VOC After Sales" ? "active" : ""}`}
          size={"small"}
          disabled
        >
          Use
        </Button>
      </>
    );
  };

  const handleBrandFilterSubmission = useCallback(() => {
    const selectedBrandCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="brand"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;
    const selectedBrands: Array<string> = [];
    selectedBrandCheckboxes?.forEach((checkbox) => selectedBrands.push(checkbox.value));

    closeFilterOptions();
    const value = brands?.length === selectedBrands?.length ? "All" : selectedBrands.join(",");
    dispatch(setSpecificParameter("brand", value));

    eventTracking(MixpanelEvents.filter_change, { filter: "brand", value, dashboard: "VOC", page: pageName });
  }, [brands]);

  const handleMarketFilterSubmission = useCallback(() => {
    const selectedMarketCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="market"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;
    const selectedMarkets: Array<string> = [];
    selectedMarketCheckboxes?.forEach((checkbox) => selectedMarkets.push(checkbox.value));

    closeFilterOptions();
    const value = markets?.length === selectedMarkets?.length ? "All" : selectedMarkets.join(",");
    dispatch(setSpecificParameter("market", value));

    eventTracking(MixpanelEvents.filter_change, { filter: "market", value, dashboard: "VOC", page: pageName });
  }, [markets]);

  const handleDealerFilterSubmission = useCallback(() => {
    const selectedDealerCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="dealer"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;
    const selectedDealers: Array<string> = [];
    selectedDealerCheckboxes?.forEach((checkbox) => selectedDealers.push(checkbox.value));

    closeFilterOptions();
    const value = dealers?.length === selectedDealers?.length ? "All" : selectedDealers.join(",");
    dispatch(setSpecificParameter("dealer", value));

    eventTracking(MixpanelEvents.filter_change, { filter: "dealer", value, dashboard: "VOC", page: pageName });
  }, [dealers]);

  const handleModelFilterSubmission = useCallback(() => {
    const selectedModelCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="model"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;
    const selectedModels: Array<string> = [];
    selectedModelCheckboxes?.forEach((checkbox) => selectedModels.push(checkbox.value));

    closeFilterOptions();
    const value = models?.length === selectedModels?.length ? "All" : selectedModels.join(",");
    dispatch(setSpecificParameter("model", value));

    eventTracking(MixpanelEvents.filter_change, { filter: "model", value, dashboard: "VOC", page: pageName });
  }, [models]);

  const handleModelTypeFilterSubmission = useCallback(() => {
    const selectedModelTypeCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="model_type"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;
    const selectedModelTypes: Array<string> = [];
    selectedModelTypeCheckboxes?.forEach((checkbox) => selectedModelTypes.push(checkbox.value));

    closeFilterOptions();
    const value = modelTypes?.length === selectedModelTypes?.length ? "All" : selectedModelTypes.join(",");
    dispatch(setSpecificParameter("model_type", value));

    eventTracking(MixpanelEvents.filter_change, { filter: "model_type", value, dashboard: "VOC", page: pageName });
  }, [modelTypes]);

  return (
    <FiltersLayout
      extraClass={"iframeFilters-midas"}
      resetFilters={resetFilters}
      filterBtns={filterBtns}
      renderDashboardClassificationButtons={renderDashboardClassificationButtons}
    >
      <div className="filte">
        <ErrorBoundary fallback={<ErrorMsg />}>
          <PurchaseFunnelDateFilter
            value={dateRangeParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
            onFilterClick={showFilterOptionsFn}
            presetDatesList={VOC_DATE_FILTER_OPTIONS}
            minDateValue={marketDataAvailability.start}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="brand"
            // @ts-ignore
            filterList={brands ? brands : []}
            filterName="BRAND"
            value={brandFilterValue}
            parameterName={"brand"}
            parameterValue={brandParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleBrandFilterSubmission}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="market"
            // @ts-ignore
            filterList={markets ? markets : []}
            filterName="MARKET"
            value={marketFilterValue}
            parameterName={"market"}
            parameterValue={marketParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleMarketFilterSubmission}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectSearchFilter
            filterName="DEALER NAME"
            parameterName="dealer"
            parameterValue={dealerParamValue}
            options={dealers ? dealers : []}
            value={dealerFilterValue}
            dashboard="VOC"
            page={pageName}
            onShowFilterOptions={showFilterOptionsFn}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectSearchFilter
            filterName="MODEL"
            parameterName="model"
            parameterValue={modelParamValue}
            options={models ? models : []}
            value={modelFilterValue}
            dashboard="VOC"
            page={pageName}
            onShowFilterOptions={showFilterOptionsFn}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="model_type"
            // @ts-ignore
            filterList={modelTypes ? modelTypes : []}
            filterName="MODEL TYPE"
            value={modelTypeFilterValue}
            parameterName={"model_type"}
            parameterValue={modelTypeParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleModelTypeFilterSubmission}
          />
        </ErrorBoundary>
      </div>
    </FiltersLayout>
  );
});

export default VocQuickAfterSalesFilters;
