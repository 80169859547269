import { isNull, toLocaleFloat } from "../../../../utils/utilityFunctions";

export const getMtpCategoryTableData = (mtpCategories: string[], data: any[], sortOrder: string[], period?: string, combined?: boolean) => {
  const mtpCategoryOrder = ["Media", "Strategic", "Fixed"];
  const breakDownData: any[] = [];
  const parentData = mtpCategories
    ?.map((category: string) => {
      const result: any = { mtp_category: category };
      const unGroupedBreakDownData: any = [];
      let fmiCategories: string[] = [];
      let groupedBreakDownData: any = [];

      //Extract fmi categories for the mtp category
      //Format Row Data for current MtpCategory and extract Breakdown data for grouping
      //Group category Data by year scenario and month/quarter
      if (period == "FY Month") {
        const months = Array.from(new Set(data?.map((val: { month: string | 0 }) => Number(val?.month))));
        months.sort((a, b) => a - b);
        fmiCategories = Array.from(new Set(dataForMonthlyPeriod(months, data, result, category, unGroupedBreakDownData)));
        groupedBreakDownData = breakDownForMonthlyPeriod(fmiCategories, months, unGroupedBreakDownData, category, combined);
      } else {
        const quarters = Array.from(new Set(data?.map((val: { quarter: string | 0 }) => Number(val.quarter))));
        quarters?.sort((a, b) => a - b);
        fmiCategories = Array.from(new Set(dataForQuarterlyPeriod(quarters, data, category, result, unGroupedBreakDownData)));
        groupedBreakDownData = breakDownForQuarterlyPeriod(fmiCategories, quarters, category, unGroupedBreakDownData, combined);
      }

      result[`break_down_data`] = groupedBreakDownData.sort((a: { fmi_category: string }, b: { fmi_category: string }) =>
        b.fmi_category.localeCompare(a.fmi_category)
      );
      breakDownData.push(...groupedBreakDownData);
      return result;
    })
    .map((val) => ({
      fmi_category: val.mtp_category,
      ...val,
    }));

  const finalData = [...breakDownData, ...parentData];
  return finalData.sort(
    (a: { mtp_category: string }, b: { mtp_category: string }) =>
      mtpCategoryOrder.indexOf(b.mtp_category) - mtpCategoryOrder.indexOf(a.mtp_category)
  );
};

const dataForMonthlyPeriod = (months: number[], data: any, result: any, category: string, unGroupedBreakDownData: any) => {
  let fmiCategories: string[] = [];
  months?.map((month: number) => {
    data
      ?.filter((row: any) => category == row.mtp_category && month == Number(row.month))
      .map((row: any) => {
        const idx = Number(row.month);
        result[`amount_${idx}_${row.yr_scnr_rv.replace(/_/g, " ")}`] = isNull(row.amount) ? 0 : toLocaleFloat(row.amount, 1);
        result[`variance_${idx}_${row.yr_scnr_rv.replace(/_/g, " ")}`] =
          isNull(row.variance) || row.variance == undefined ? 0 : toLocaleFloat(row.variance, 1);
        result[`total_amount_${idx}_${row.yr_scnr_rv.replace(/_/g, " ")}`] =
          isNull(row.combined_categories_amount) || row.combined_categories_amount == undefined
            ? 0
            : toLocaleFloat(row.combined_categories_amount, 1);
        result[`total_variance_${idx}_${row.yr_scnr_rv.replace(/_/g, " ")}`] =
          isNull(row.combined_categories_variance) || row.combined_categories_variance == undefined
            ? 0
            : toLocaleFloat(row.combined_categories_variance, 1);
        fmiCategories = Array.from(new Set(row?.break_down_data?.map((val: { fmi_category: string }) => val?.fmi_category)));

        row?.break_down_data?.map((breakdown: any) => {
          const value: any = { mtp_category: category, fmi_category: breakdown.fmi_category };
          value[`amount`] = breakdown.amount;
          value[`variance`] = breakdown.variance;
          value[`yr_scnr_rv`] = breakdown.yr_scnr_rv.replace(/_/g, " ");
          value["month"] = breakdown.month;
          unGroupedBreakDownData.push(value);
        });
      });
  });
  return fmiCategories;
};

const dataForQuarterlyPeriod = (quarters: number[], data: any, category: string, result: any, unGroupedBreakDownData: any) => {
  let fmi_categories: string[] = [];
  quarters?.map((quarter: number) => {
    data
      ?.filter((row: any) => category == row.mtp_category && quarter == Number(row.quarter))
      .map((row: any) => {
        const idx = Number(row.quarter);
        result[`amount_${idx}_${row.yr_scnr_rv.replace(/_/g, " ")}`] = isNull(row.amount) ? 0 : toLocaleFloat(row.amount, 1);
        result[`variance_${idx}_${row.yr_scnr_rv.replace(/_/g, " ")}`] = isNull(row.variance) ? 0 : toLocaleFloat(row.variance, 1);
        result[`total_amount_${idx}_${row.yr_scnr_rv.replace(/_/g, " ")}`] =
          isNull(row.combined_categories_amount) || row.combined_categories_amount == undefined
            ? 0
            : toLocaleFloat(row.combined_categories_amount, 1);
        result[`total_variance_${idx}_${row.yr_scnr_rv.replace(/_/g, " ")}`] =
          isNull(row.combined_categories_variance) || row.combined_categories_variance == undefined
            ? 0
            : toLocaleFloat(row.combined_categories_variance, 1);

        fmi_categories = Array.from(new Set(row?.break_down_data?.map((val: { fmi_category: string }) => val?.fmi_category)));
        row?.break_down_data?.map((breakdown: any) => {
          const value: any = { mtp_category: category, fmi_category: breakdown.fmi_category };
          value[`amount`] = isNull(breakdown.amount) ? 0 : toLocaleFloat(breakdown.amount, 1);
          value[`variance`] = isNull(breakdown.variance) || breakdown.variance == undefined ? 0 : breakdown.variance;
          value[`yr_scnr_rv`] = breakdown.yr_scnr_rv.replace(/_/g, " ");
          value["quarter"] = breakdown.quarter;
          unGroupedBreakDownData.push(value);
        });
      });
  });

  return fmi_categories;
};

const breakDownForMonthlyPeriod = (
  fmi_categories: string[],
  months: number[],
  unGroupedBreakDownData: any[],
  category: string,
  combined = false
) => {
  const groupedBreakDownData: any[] = [];
  fmi_categories?.map((fmi_category: string) => {
    const breakDown: any = { fmi_category: fmi_category, mtp_category: category };
    unGroupedBreakDownData
      ?.filter((val: { month: string; fmi_category: string }) => val.fmi_category == fmi_category)
      .map((val: { month: any; variance: any; yr_scnr_rv: string; amount: any }) => {
        const idx = combined ? months.indexOf(Number(val?.month)) : Number(val?.month);

        breakDown[`amount_${idx}_${val.yr_scnr_rv.replace(/_/g, " ")}`] = isNull(val.amount) ? 0 : toLocaleFloat(val.amount, 1);
        breakDown[`variance_${idx}_${val.yr_scnr_rv.replace(/_/g, " ")}`] =
          isNull(val.variance) || val.variance == undefined ? 0 : toLocaleFloat(val.variance, 1);
      });
    groupedBreakDownData.push(breakDown);
  });
  return groupedBreakDownData;
};

const breakDownForQuarterlyPeriod = (
  fmiCategories: string[],
  quarters: number[],
  category: any,
  unGroupedBreakDownData: any[],
  combined = false
) => {
  const groupedBreakDownData: any[] = [];
  fmiCategories?.map((fmi_category: string) => {
    const breakDown: any = { fmi_category: fmi_category, mtp_category: category };
    unGroupedBreakDownData
      ?.filter((val: { fmi_category: string }) => val.fmi_category == fmi_category)
      .map((val: { variance: any; quarter: string; yr_scnr_rv: string; amount: any }) => {
        const idx = combined ? quarters.indexOf(Number(val?.quarter)) : Number(val?.quarter);
        breakDown[`amount_${idx}_${val.yr_scnr_rv.replace(/_/g, " ")}`] = isNull(val.amount) ? 0 : toLocaleFloat(val.amount);
        breakDown[`variance_${idx}_${val.yr_scnr_rv.replace(/_/g, " ")}`] =
          isNull(val.variance) || val.variance == undefined ? 0 : toLocaleFloat(val.variance, 1);
      });
    groupedBreakDownData.push(breakDown);
  });
  return groupedBreakDownData;
};

export const getTotalsRow = (computedData: any, yrScenarios: string[], period: string, quarters?: number[], months?: number[]) => {
  const result: any = { fmi_category: "Total" };
  const data = computedData?.filter(
    (val: { fmi_category: string; mtp_category: string }) => val.mtp_category == "Media" && val.fmi_category == "Media"
  );
  if (computedData.length && period == "FY Quarter") {
    quarters?.map((val: number) => {
      result[`amount_${val}_${yrScenarios[0].replace(/_/g, " ")}`] = data[0][`total_amount_${val}_${yrScenarios[0].replace(/_/g, " ")}`];
      result[`variance_${val}_${yrScenarios[0].replace(/_/g, " ")}`] =
        data[0][`total_variance_${val}_${yrScenarios[0].replace(/_/g, " ")}`];
      if (yrScenarios.length > 1) {
        result[`amount_${val}_${yrScenarios[1].replace(/_/g, " ")}`] = data[0][`total_amount_${val}_${yrScenarios[1].replace(/_/g, " ")}`];
        result[`variance_${val}_${yrScenarios[1].replace(/_/g, " ")}`] =
          data[0][`total_variance_${val}_${yrScenarios[1].replace(/_/g, " ")}`];
      }
    });
  }
  if (computedData.length && period == "FY Month") {
    months?.map((val: number) => {
      result[`amount_${val}_${yrScenarios[0].replace(/_/g, " ")}`] = data[0][`total_amount_${val}_${yrScenarios[0].replace(/_/g, " ")}`];
      result[`variance_${val}_${yrScenarios[0].replace(/_/g, " ")}`] =
        data[0][`total_variance_${val}_${yrScenarios[0].replace(/_/g, " ")}`];
      if (yrScenarios.length > 1) {
        result[`amount_${val}_${yrScenarios[1].replace(/_/g, " ")}`] = data[0][`total_amount_${val}_${yrScenarios[1].replace(/_/g, " ")}`];
        result[`variance_${val}_${yrScenarios[1].replace(/_/g, " ")}`] =
          data[0][`total_variance_${val}_${yrScenarios[1].replace(/_/g, " ")}`];
      }
    });
  }
  return result;
};
