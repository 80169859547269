import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { setParameters } from "../../actions";
import "../../assets/styles/main.scss";
import "../../assets/styles/pages/login.scss";
import { ForgotPasswordForm } from "../../components/Forms";
import { searchStringToObject } from "../../utils/utilityFunctions";
import { setAppHeight } from "./subs/helpers";

export const ForgotPassword = withRouter((props: RouteComponentProps): JSX.Element => {
  const { location } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    setAppHeight();
    const { search } = location;

    search && dispatch(setParameters(searchStringToObject(search)));
  }, [dispatch, location]);

  return (
    <div className={"pageWrapper"} id={"loginPage"}>
      <div className="dashboard" id="login_dashboard">
        <p id="IESupportMessage" />
        <div id="container">
          <ForgotPasswordForm />
        </div>
      </div>
    </div>
  );
});
