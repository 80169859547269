const seoCustomSortTypes = (sortByValue: string | undefined) => {
  const reSplitAlphaNumeric = /([0-9]+)/gm;

  const additionalMetricsColumns: any = {
    volumeColumn: "clicks",
    popComparisonColumn: "clicksPopComparison",
    popPercentageColumn: "clicksPopPercentage",
    yoyColumn: "clicksYoyComparison",
    yoyPercentageColumn: "clicksYoyPercentage",
  };

  function compareBasic(a: any, b: any, desc: any) {
    if (a == "n/a") {
      return desc ? -1 : 1;
    }

    if (b == "n/a") {
      return desc ? 1 : -1;
    }

    return a === b ? 0 : a > b ? 1 : -1;
  }

  function getRowValueByColumnID(row: any, columnId: any) {
    if (sortByValue && sortByValue === "Clicks") {
      const additionalMetricId = additionalMetricsColumns[columnId];
      return row.original.clicksData[0][additionalMetricId];
    } else {
      return row.values[columnId];
    }
  }

  function toString(a: any) {
    if (typeof a === "number") {
      if (isNaN(a) || a === Infinity || a === -Infinity) {
        return "";
      }
      return String(a);
    }
    if (typeof a === "string") {
      return a;
    }
    return "";
  }

  return {
    alphanumericFalsyLast(rowA: any, rowB: any, columnId: any, desc: any) {
      let a = getRowValueByColumnID(rowA, columnId);
      let b = getRowValueByColumnID(rowB, columnId);
      // Force to strings (or "" for unsupported types)
      a = toString(a);
      b = toString(b);

      // Split on number groups, but keep the delimiter
      // Then remove falsey split values
      a = a.split().filter(Boolean);
      b = b.split().filter(Boolean);

      if (isNaN(a)) {
        return desc ? -1 : 1;
      }

      if (isNaN(b)) {
        return desc ? 1 : -1;
      }
      if (a.length === 0) {
        return desc ? -1 : 1;
      }

      if (b.length === 0) {
        return desc ? 1 : -1;
      }
      // While
      while (a.length && b.length) {
        const aa = a.shift();
        const bb = b.shift();

        const an = parseInt(aa, 10);
        const bn = parseInt(bb, 10);
        // Both are numbers
        if (an > bn) {
          return 1;
        }
        if (bn > an) {
          return -1;
        }
      }
      return a.length - b.length;
    },
    alphanumericCustom(rowA: any, rowB: any, columnId: any) {
      let a = getRowValueByColumnID(rowA, columnId);
      let b = getRowValueByColumnID(rowB, columnId);
      // Force to strings (or "" for unsupported types)
      a = toString(a);
      b = toString(b);

      // Split on number groups, but keep the delimiter
      // Then remove falsey split values
      a = a.split(reSplitAlphaNumeric).filter(Boolean);
      b = b.split(reSplitAlphaNumeric).filter(Boolean);

      // While
      while (a.length && b.length) {
        const aa = a.shift();
        const bb = b.shift();

        const an = parseInt(aa, 10);
        const bn = parseInt(bb, 10);

        const combo = [an, bn].sort();

        // Both are string
        if (isNaN(combo[0])) {
          if (aa > bb) {
            return 1;
          }
          if (bb > aa) {
            return -1;
          }
          continue;
        }

        // One is a string, one is a number
        if (isNaN(combo[1])) {
          return isNaN(an) ? -1 : 1;
        }

        // Both are numbers
        if (an > bn) {
          return 1;
        }
        if (bn > an) {
          return -1;
        }
      }

      return a.length - b.length;
    },
    basicCustom(rowA: any, rowB: any, columnId: any, desc: any) {
      const a = getRowValueByColumnID(rowA, columnId);
      const b = getRowValueByColumnID(rowB, columnId);

      return compareBasic(a, b, desc);
    },
  };
};

export default seoCustomSortTypes;
