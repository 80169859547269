import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { ThemeContext } from "../../../../context";
import { useQuery } from "@tanstack/react-query";
import {
  getKbasAndLeadsSummary,
  getKbasAndLeadsTable,
  getKbasAndLeadsTrend,
  getSearchInterestSummary,
  getSearchInterestTable,
  getSearchInterestTrend,
} from "../../../../api/JourneyExcellenceMarketPerformanceSummary";
import MarketPerformanceSummaryLineChart from "../../../../components/Charts/MarketPerformanceSummary/MarketPerformanceSummaryLineChart";
import MarketPerformanceSummaryTable from "./MarketPerformanceSummaryTable";
import { GRADE_MIX_COLORS } from "../../../../constants";
import { getValueClass, plusSignNumbers } from "../../../../utils/utilityFunctions";
import { formatToThreeDigits } from "../../../../utils/numberFormatting";
import { ToggleSwitch } from "../../../../components/Toogle";
import { format } from "date-fns";

const KbasAndLeads = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const [showroomTrafficActive, setShowroomTrafficActive] = useState(false);

  const {
    data: kbasAndLeadsSummaryData,
    isFetching: isFetchingQualifiedVisitsAndKbasSummary,
    refetch: refetchQualifiedVisitsAndKbasSummary,
  } = useQuery({
    queryKey: ["kbasAndLeadsSummary", showroomTrafficActive, history.location.search],
    queryFn: () => getKbasAndLeadsSummary(showroomTrafficActive),
    enabled: false,
  });

  const {
    data: kbasAndLeadsTrendData,
    isFetching: isFetchingKbasAndLeadsTrend,
    refetch: refetchKbasAndLeadsTrend,
  } = useQuery({
    queryKey: ["kbasAndLeadsTrend", showroomTrafficActive, history.location.search],
    queryFn: () => getKbasAndLeadsTrend(showroomTrafficActive),
    enabled: false,
  });

  const {
    data: kbasAndLeadsTableData,
    isFetching: isFetchingQualifiedVisitsAndKbasTable,
    refetch: refetchQualifiedVisitsAndKbasTable,
  } = useQuery({
    queryKey: ["kbasAndLeadsTable", showroomTrafficActive, history.location.search],
    queryFn: () => getKbasAndLeadsTable(showroomTrafficActive),
    enabled: false,
  });

  useEffect(() => {
    refetchQualifiedVisitsAndKbasSummary();
    refetchKbasAndLeadsTrend();
    refetchQualifiedVisitsAndKbasTable();
  }, [history.location.search, showroomTrafficActive]);

  const seriesColors = useMemo(() => {
    if (kbasAndLeadsTrendData?.series) {
      const res: any = {};
      kbasAndLeadsTrendData?.series?.map((seriesItem: any, idx: number) => {
        res[seriesItem.name] = GRADE_MIX_COLORS[idx];
      });
      return res;
    }
    return {};
  }, [kbasAndLeadsTrendData]);

  return (
    <div className="tile">
      <div className="tile-header">
        <div className="title">
          <p>{showroomTrafficActive ? "Showroom and Traffic" : "KBAs and Leads"}</p>
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            html={
              <div>
                <u>KBAs:</u>
                <p>
                  KBA or Key Buying Actions visits are the count of visits including at least one key buying action as Model detail compare,
                  Saved searches views, Compare tools views and Dealer search.
                </p>
                <p>Source: Adobe Analytics</p>
                <br />
                <u>Leads:</u>
                <p>
                  Lead visits are the count of visits including at least one digital lead as Enquire, Test drive, Request a video and
                  Reserve.
                </p>
                <p>Source: Adobe Analytics</p>
              </div>
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        {/* <ToggleSwitch
          inactiveToggleLabel="Leads"
          activeToggleLabel="Showroom Traffic"
          active={showroomTrafficActive}
          handleToggleClick={() => setShowroomTrafficActive((prevState) => !prevState)}
        /> */}
      </div>
      <div className="tile-content">
        <div className="summary kba-and-leads-summary-table">
          <div className="column">
            <span>Conversion</span>
            <br />
            <span>(MoM)</span>
          </div>
          <div className="column">
            <span>
              {kbasAndLeadsSummaryData?.mom_date
                ? `${format(new Date(kbasAndLeadsSummaryData?.mom_date), "MMM")}: ${kbasAndLeadsSummaryData?.mom ? `${kbasAndLeadsSummaryData?.mom.toLocaleString()}%` : "n/a"
                }`
                : ""}
            </span>
            <br />
            <span>
              {kbasAndLeadsSummaryData?.mom_yoy && (
                <span className={`${getValueClass(kbasAndLeadsSummaryData?.mom_yoy)}`}>
                  {kbasAndLeadsSummaryData?.mom_yoy ? `${plusSignNumbers(kbasAndLeadsSummaryData?.mom_yoy)}ppt` : ""}
                </span>
              )}
            </span>
          </div>
          <div className="column">
            <span>
              {kbasAndLeadsSummaryData?.current_date
                ? `${format(new Date(kbasAndLeadsSummaryData?.current_date), "MMM")}: ${kbasAndLeadsSummaryData?.current ? `${kbasAndLeadsSummaryData.current.toLocaleString()}%` : "n/a"
                }`
                : ""}
            </span>
            <br />
            <span>
              {kbasAndLeadsSummaryData?.current_yoy && (
                <span className={`${getValueClass(kbasAndLeadsSummaryData?.current_yoy)}`}>
                  {kbasAndLeadsSummaryData?.current_yoy ? `${plusSignNumbers(kbasAndLeadsSummaryData?.current_yoy)}ppt` : ""}
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="chart-table-container">
        <MarketPerformanceSummaryLineChart
          chartId="kbasAndLeadsChart"
          data={kbasAndLeadsTrendData?.data ?? []}
          isLoading={isFetchingKbasAndLeadsTrend}
          theme={theme}
          seriesList={kbasAndLeadsTrendData?.series ?? []}
          seriesColors={seriesColors}
          isLeads={true}
        />
        <MarketPerformanceSummaryTable
          data={kbasAndLeadsTableData ?? []}
          columnHeaders={["", "KBAs", "YoY"]}
          columnDataKeys={[
            { key: "model", type: "string" },
            { key: "kbas", type: "number" },
            { key: "kbas_yoy", type: "percentage" },
          ]}
        />
      </div>
    </div>
  );
});

export default KbasAndLeads;
