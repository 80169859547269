import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { CYAN } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  series: Array<{ market: string; ev_field: string; services_field: string; color: string }>;
  theme: ThemeInt;
  colorsMapping: Record<string, string>;
}

export class AppRatingsBreakdownSplitGeoLineGraph extends Component<Props> {
  chartId = "appRatingsBreakdownChart";
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, series, theme, colorsMapping } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "0|#.0";

    // Creating Axes
    // const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    // configureCategoryAxis(categoryAxis, theme);
    // categoryAxis.dataFields.category = "label";

    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);

    // Creating Series
    this.createSeries(series);

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });
  }

  createSeries(seriesList: Array<{ market: string; ev_field: string; services_field: string }>) {
    seriesList.forEach((dataItem) => {
      const evSeries = this.chart.series.push(new LineSeries());
      const evBullet = lineSeriesConfiguration(evSeries, {
        name: dataItem.market,
        valueY: `${dataItem.ev_field}`,
      });
      evSeries.dataFields.dateX = "date";

      evSeries.fill = color(this.props.colorsMapping[dataItem.market] || CYAN);
      evSeries.stroke = color(this.props.colorsMapping[dataItem.market] || CYAN);
      evBullet.tooltipText = "[b]{name} {categoryX} EV:[/] {valueY.formatNumber('#.0')}";

      const servicesSeries = this.chart.series.push(new LineSeries());
      const servicesBullet = lineSeriesConfiguration(servicesSeries, {
        name: dataItem.market,
        valueY: `${dataItem.services_field}`,
      });
      servicesSeries.dataFields.dateX = "date";

      servicesSeries.fill = color(this.props.colorsMapping[dataItem.market] || CYAN);
      servicesSeries.stroke = color(this.props.colorsMapping[dataItem.market] || CYAN);
      servicesSeries.strokeDasharray = "2.2";
      servicesBullet.tooltipText = "[b]{name} {categoryX} Services:[/] {valueY.formatNumber('#.0')}";
    });
  }

  render() {
    return <div id={this.chartId} className={"metricChart"} />;
  }
}
