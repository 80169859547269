import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { fetchMsrpModelPerformance } from "../../../actions";
import { InAppMultiSelectFilter } from "../../../components/Filters/common/InAppMultiselectFilter";
import { closeFilterOptions } from "../../../components/Filters/subs/helpers";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { DefaultTable } from "../../../components/Tables/subs";

const ModelPerformance = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();

  const modelPerformanceData = useSelector((state: RootStateOrAny) => state.msrp.msrp_model_performance);
  const currency = useSelector((state: RootStateOrAny) => state.msrp.currency);
  const { model: modelsList } = useSelector((state: RootStateOrAny) => state.filters.msrp_filters);
  const { msrp_model_performance: msrpModelPerformanceLoading } = useSelector((state: RootStateOrAny) => state.loading);

  const [modelFilterValue, setModelFilterValue] = useState("All");

  useEffect(() => {
    dispatch(fetchMsrpModelPerformance(modelFilterValue));
  }, [history.location.search, modelFilterValue]);

  const handleModelMultiselectSubmit = useCallback(() => {
    const parameterName = "model";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = modelsList?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    setModelFilterValue(filterValue);
  }, [modelsList]);

  const renderArrow = (value: string) => {
    return value == "green" ? (
      <ArrowUpwardIcon className={`arrow-icon ${value}`} />
    ) : value == "amber" ? (
      <ArrowForwardIcon className={`arrow-icon ${value}`} />
    ) : (
      <ArrowDownwardIcon className={`arrow-icon ${value}`} />
    );
  };

  const columns = useMemo(
    () => [
      {
        id: "carName",
        Header: "Car Name",
        accessor: "model",
        defaultCanSort: true,
        sortType: "basicCustom",
      },
      {
        id: "msrp_dis",
        Header: "DIS MSRP",
        accessor: "msrp_dis",
        defaultCanSort: true,
        sortType: "basicCustom",
        Cell: (cell: { value: number; row: { original: { msrp_dis_arrow: number } } }) => {
          const { value, row } = cell;
          const arrowClass = row.original.msrp_dis_arrow == 1 ? "green" : row.original.msrp_dis_arrow == 0 ? "amber" : "red";

          return (
            <div className="model-performance-cell">
              {value != null && renderArrow(arrowClass)}
              <p className="value">{value ? `${currency}${value.toLocaleString()}` : "n/a"}</p>
            </div>
          );
        },
      },
      {
        id: "nonMsrpDis",
        Header: "Non DIS MSRP",
        accessor: "msrp_non_dis",
        defaultCanSort: true,
        sortType: "basicCustom",
        Cell: (cell: { value: number; row: { original: { msrp_non_dis_arrow: number } } }) => {
          const { value, row } = cell;
          const arrowClass = row.original.msrp_non_dis_arrow == 1 ? "green" : row.original.msrp_non_dis_arrow == 0 ? "amber" : "red";

          return (
            <div className="model-performance-cell">
              {value != null && renderArrow(arrowClass)}
              <p className="value">{value ? `${currency}${value.toLocaleString()}` : "n/a"}</p>
            </div>
          );
        },
      },
      {
        id: "disVolume",
        Header: "DIS Volume",
        accessor: "dis_volume",
        defaultCanSort: true,
        sortType: "basicCustom",
        Cell: (cell: { value: number; row: { original: { dis_volume_arrow: number } } }) => {
          const { value, row } = cell;
          const arrowClass = row.original.dis_volume_arrow == 1 ? "green" : row.original.dis_volume_arrow == 0 ? "amber" : "red";

          return (
            <div className="model-performance-cell">
              {value != null && renderArrow(arrowClass)}
              <p className="value">{value ? value.toLocaleString() : "n/a"}</p>
            </div>
          );
        },
      },
      {
        id: "nonDisVolume",
        Header: "Non DIS Volume",
        accessor: "non_dis_volume",
        defaultCanSort: true,
        sortType: "basicCustom",
        Cell: (cell: { value: number; row: { original: { non_dis_volume_arrow: number } } }) => {
          const { value, row } = cell;
          const arrowClass = row.original.non_dis_volume_arrow == 1 ? "green" : row.original.non_dis_volume_arrow == 0 ? "amber" : "red";

          return (
            <div className="model-performance-cell">
              {value != null && renderArrow(arrowClass)}
              <p className="value">{value ? value.toLocaleString() : "n/a"}</p>
            </div>
          );
        },
      },
      {
        id: "disVme",
        Header: "DIS VME",
        accessor: "dis_vme",
        defaultCanSort: true,
        sortType: "basicCustom",
        Cell: (cell: { value: number; row: { original: { dis_vme_arrow: number } } }) => {
          const { value, row } = cell;
          const arrowClass = row.original.dis_vme_arrow == 1 ? "green" : row.original.dis_vme_arrow == 0 ? "amber" : "red";

          return (
            <div className="model-performance-cell">
              {value != null && renderArrow(arrowClass)}
              <p className="value">{value ? `${value.toLocaleString()}%` : "n/a"}</p>
            </div>
          );
        },
      },
      {
        id: "nonDisVme",
        Header: "Non DIS VME",
        accessor: "non_dis_vme",
        defaultCanSort: true,
        sortType: "basicCustom",
        Cell: (cell: { value: number; row: { original: { non_dis_vme_arrow: number } } }) => {
          const { value, row } = cell;
          const arrowClass = row.original.non_dis_vme_arrow == 1 ? "green" : row.original.non_dis_vme_arrow == 0 ? "amber" : "red";

          return (
            <div className="model-performance-cell">
              {value != null && renderArrow(arrowClass)}
              <p className="value">{value ? `${value.toLocaleString()}%` : "n/a"}</p>
            </div>
          );
        },
      },
      {
        id: "vmeAddedValue",
        Header: "VME added value",
        accessor: "vme_as_value",
        defaultCanSort: true,
        sortType: "basicCustom",
        Cell: (cell: { value: number; row: { original: { vme_as_value_arrow: number } } }) => {
          const { value, row } = cell;
          const arrowClass = row.original.vme_as_value_arrow == 1 ? "green" : row.original.vme_as_value_arrow == 0 ? "amber" : "red";

          return (
            <div className="model-performance-cell">
              {value != null && renderArrow(arrowClass)}
              <p className="value">{value ? `${currency}${value.toLocaleString()}` : "n/a"}</p>
            </div>
          );
        },
      },
      {
        id: "msrpAddedValue",
        Header: "MSRP added value",
        accessor: "msrp_added_value",
        defaultCanSort: true,
        sortType: "basicCustom",
        Cell: (cell: { value: number; row: { original: { msrp_added_value_arrow: number } } }) => {
          const { value, row } = cell;
          const arrowClass =
            row.original.msrp_added_value_arrow == 1 ? "green" : row.original.msrp_added_value_arrow == 0 ? "amber" : "red";

          return (
            <div className="model-performance-cell">
              {value != null && renderArrow(arrowClass)}
              <p className="value">{value ? `${currency}${value.toLocaleString()}` : "n/a"}</p>
            </div>
          );
        },
      },
    ],
    [modelPerformanceData]
  );

  return (
    <div className="full-width-row">
      <div className="msrp-tile">
        <div className="tile-header">
          Model Performance
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            html={
              <div>
                <p>Green arrow: good insight</p>
                <p>Orange arrow: average insight</p>
                <p>Red arrow: bad insight</p>
              </div>
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
          <InAppMultiSelectFilter
            filterLabel="Select Models"
            optionsList={modelsList}
            filterValue={modelFilterValue}
            parameterName={`model`}
            parameterValue={modelFilterValue}
            handleFilterSubmission={handleModelMultiselectSubmit}
          />
          <div className="in-app-filter">Total added value = VME added value + MSRP added value</div>
        </div>
        <DefaultTable
          columns={columns}
          data={modelPerformanceData}
          id={"msrpModelPerformance"}
          isChildComponent={false}
          initialSortColId={{ id: "carName", desc: false }}
          hiddenCols={[]}
          // manualSortBy={true}
        />
        <LoadingEllipsis isLoading={msrpModelPerformanceLoading} />
      </div>
    </div>
  );
});

export default ModelPerformance;
