import { ALL_OPTION, NISSAN_DATSUN } from "../../../constants";
import { FiltersData } from "../../../constants/interface";

export const closeFilterOptions = () => {
  document.querySelectorAll(`.filter_options`)?.forEach((childElement) => childElement.classList.remove("showOptions"));
};

export const closeMarketOptions = () => {
  document.querySelectorAll(`.market-arrow`)?.forEach((childElement) => childElement.classList.remove("down"));
  document.querySelectorAll(`.region_country_filter`)?.forEach((childElement) => childElement.classList.remove("show-market"));
  document.getElementById("region_ul")?.classList.remove("market-open");
};

export const closeSubFilterOptions = () => {
  const arrowDownClass = "down";
  const openSubFilterClass = "show";

  document.querySelectorAll(".arrow-span")?.forEach((arrowElement) => arrowElement.classList.remove(arrowDownClass));
  document.querySelectorAll(".multicheck_sub_filter_options")?.forEach((subUlElement) => subUlElement.classList.remove(openSubFilterClass));
};

export const objectToQuerystring = (paramObject: { [x: string]: string | number | boolean }) => {
  const parameters = new URLSearchParams();
  Object.keys(paramObject).forEach((key) => {
    parameters.set(key, <string>paramObject[key]);
  });

  return parameters.toString();
};

export const filterDataByBrand = (data: FiltersData, brand: string | string[]): any[] => {
  if (!data || !brand) return [];

  const brandValue = typeof brand == "object" ? brand.join("|") : brand;

  return data.filter(({ brands }) => {
    if (brand === NISSAN_DATSUN) {
      return /Nissan|Datsun/gi.test(brands);
    }
    const brandRe = new RegExp(brandValue, "gi");
    return brandRe.test(brands);
  });
};

export const filterDataByBrandAndGroup = (data: FiltersData, brand: string | string[], group: string): any[] => {
  if (!data || !brand || brand.length === 0 || !group) return [];

  const brandReValue = typeof brand == "object" ? brand.join("|") : brand;
  return data
    .filter(({ brands }) => {
      if (brand === NISSAN_DATSUN) {
        return /Nissan|Datsun/gi.test(brands);
      }

      const brandRe = new RegExp(brandReValue, "gi");
      return brandRe.test(brands);
    })
    .filter(({ group_name }) => (group === ALL_OPTION ? true : group_name === group));
};
