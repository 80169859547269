import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";

interface Props {
  title: string;
  tooltipText?: string;
  value: number;
  yoyValue: number;
  popValue: number;
  isLoading: boolean;
}
const MetricTile = (props: Props) => {
  const { title, tooltipText, value, yoyValue, popValue, isLoading } = props;
  const dateRangeParam = useSelector((state: RootStateOrAny) => state.parameters.date_range);

  const showPopComparison = useMemo(() => {
    if (dateRangeParam.includes("financial year") || dateRangeParam.includes("-")) {
      return false;
    }
    return true;
  }, [dateRangeParam]);

  return (
    <div className="tile  metric-tile">
      {isLoading ? (
        <LoadingEllipsis isLoading={isLoading} />
      ) : (
        <>
          <div className="tile-header">
            <div className="title-container">
              <div className="title">{title}</div>
              {tooltipText && (
                <Tooltip
                  position="top"
                  className={"normal_tippy"}
                  trigger="mouseenter"
                  animation="none"
                  interactive={true}
                  delay={1}
                  hideDelay={1}
                  duration={1}
                  title={tooltipText}
                  size={"small"}
                >
                  <span className="info_tooltip" />
                </Tooltip>
              )}
            </div>
          </div>
          <div className="tile-content">
            <div className="value">{value}</div>
            <div className="comparison">
              <div className="yoy-comparison">
                <Ratings value={yoyValue} isPercentageValue={false} testValue={false} />
                {yoyValue != null && yoyValue != undefined ? `${yoyValue}% YoY` : "n/a"}
              </div>
              {showPopComparison && (
                <div className="pop-comparison">
                  <Ratings value={popValue} isPercentageValue={false} testValue={false} />
                  {popValue != null && popValue != undefined ? `${popValue}% PoP` : "n/a"}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MetricTile;
