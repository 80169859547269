import React from "react";
import QuarterBreakdown from "./QuarterBreakdown";

interface Props {
  loading: boolean;
  seriesList: { name: string; seriesColor: string }[];
  yrScenarios: string[];
  q1ChartData: any;
  q2ChartData: any;
  q3ChartData: any;
  q4ChartData: any;
  fullYearChartData: any;
  fullYearMaxAmount: number;
  minAmount: number;
  q1MaxAmount: number;
  q2MaxAmount: number;
  q3MaxAmount: number;
  q4MaxAmount: number;
}

const MidasSharedCharts = (props: Props) => {
  const {
    loading,
    seriesList,
    yrScenarios,
    q1ChartData,
    q2ChartData,
    q3ChartData,
    q4ChartData,
    fullYearChartData,
    fullYearMaxAmount,
    minAmount,
    q1MaxAmount,
    q2MaxAmount,
    q3MaxAmount,
    q4MaxAmount,
  } = props;

  return (
    <div className="media_mix_charts primary_background">
      <div className="legend">
        {seriesList?.map(({ name, seriesColor }) => (
          <div className="legend_item" key={name}>
            <div className="legend_box" style={{ backgroundColor: seriesColor }}></div>
            {name.replace(new RegExp("_", "g"), " ")}
          </div>
        ))}
      </div>
      <div className="charts strategic">
        <QuarterBreakdown
          quarterTitle="Q1"
          loading={loading}
          data={q1ChartData}
          maxAmount={q1MaxAmount}
          seriesList={seriesList}
          yrScenarios={yrScenarios}
          columnPercentage={45}
          minAmount={minAmount}
        />
        <QuarterBreakdown
          quarterTitle="Q2"
          loading={loading}
          data={q2ChartData}
          maxAmount={q2MaxAmount}
          seriesList={seriesList}
          yrScenarios={yrScenarios}
          minAmount={minAmount}
          hideAxis={true}
        />
        <QuarterBreakdown
          quarterTitle="Q3"
          loading={loading}
          data={q3ChartData}
          maxAmount={q3MaxAmount}
          seriesList={seriesList}
          yrScenarios={yrScenarios}
          minAmount={minAmount}
          hideAxis={true}
        />
        <QuarterBreakdown
          quarterTitle="Q4"
          loading={loading}
          data={q4ChartData}
          maxAmount={q4MaxAmount}
          seriesList={seriesList}
          yrScenarios={yrScenarios}
          minAmount={minAmount}
          hideAxis={true}
        />
        <QuarterBreakdown
          quarterTitle="Full Year"
          loading={loading}
          data={fullYearChartData}
          seriesList={seriesList}
          yrScenarios={yrScenarios}
          columnPercentage={45}
          minAmount={minAmount}
          maxAmount={fullYearMaxAmount}
        />
      </div>
    </div>
  );
};

export default MidasSharedCharts;
