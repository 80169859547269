import React from "react";
import DigitalSpendTrend from "../tiles/DigitalSpendTrend";
import DigitalSpendByCampaignObjectiveTrend from "../tiles/DigitalSpendByCampaignObjectiveTrend";
import DigitalSpendByChannelTrend from "../tiles/DigitalSpendByChannelTrend";
import TopTenCampaignsTile from "../tiles/TopTenCampaignsTile";

const DigitalMarketingSpendContent = () => {
  return (
    <div className="digital-marketing-spend-content">
      <div className="digital-arketing-spend-row">
        <DigitalSpendTrend />
        <DigitalSpendByCampaignObjectiveTrend />
      </div>
      <div className="digital-arketing-spend-row">
        <DigitalSpendByChannelTrend />
        <TopTenCampaignsTile />
      </div>
    </div>
  );
};

export default DigitalMarketingSpendContent;
