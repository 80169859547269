import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ValueCombinedChart } from "../../../../components/Charts/CCSCockpit";
import { LegendMarker } from "../../../../components/Charts/components/LegendMarker";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";
import { CYAN, LIGHT_CYAN } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import { formatToThreeDigits } from "../../../../utils/numberFormatting";
import { isNull, plusSignNumbers } from "../../../../utils/utilityFunctions";

interface Props {
  title: string;
  value: number;
  produced: number | null;
  sold: number | null;
  comparisonProduced: number | null;
  comparisonPercentageValue?: number | null;
  chartData?: {
    data: Array<any> | undefined;
    chartId: string;
    barSeriesName: string;
    barDataKey: string;
    lineSeriesKey: string;
    lineSeriesName: string;
  };
  isLoading: boolean;
  onClick?: () => void;
}

export const TotalVehicleProduced = (props: Props) => {
  const { title, value, produced, sold, comparisonProduced, comparisonPercentageValue, chartData, isLoading, onClick: handleClick } = props;
  const { region: regionParam } = useSelector((state: RootStateOrAny) => state.parameters);

  const themeContext = useContext(ThemeContext);

  const isComparisonValueValid = !isNull(comparisonPercentageValue) && typeof comparisonPercentageValue !== "undefined";

  const comparisonLabel = "YoY";

  const [isAllUndefined, setIsAllUndefined] = useState<boolean>(false);
  useEffect(() => {
    const l = `${chartData?.barDataKey}`;
    setIsAllUndefined(chartData?.data?.every((splitRow) => typeof splitRow[l] === "undefined" || isNull(splitRow[l])) ? true : false);
  }, [chartData]);

  return (
    <div className="tile" id={title.replaceAll(" ", "_")} data-test-id={title.replaceAll(" ", "_")} onClick={handleClick}>
      <div className="CCS_main_content">
        <div className="tile_content">
          <div className="child_content">
            <div className="tile_header">
              {chartData && chartData.data && <LegendMarker color={CYAN} shape={"square"} />}
              {title}
            </div>
            <div className="value" data-test-value-type={"value"} data-test-value={isNull(value) ? "n/a" : value}>
              {isNull(value)
                ? "n/a"
                : regionParam == "China"
                ? formatToThreeDigits(value as number, 1)
                : formatToThreeDigits(value as number)}
            </div>

            {isComparisonValueValid && (
              <p className="period_comparison comparison">
                <span className="main">
                  <Ratings
                    value={comparisonPercentageValue ? (isNull(comparisonPercentageValue) ? "n/a" : comparisonPercentageValue) : "n/a"}
                    isPercentageValue={true}
                  />
                  <span className="comparison_value">
                    {!isNull(comparisonPercentageValue) ? `${Math.abs(comparisonPercentageValue as number)}%` : "n/a"}
                  </span>
                  {comparisonLabel}
                </span>
              </p>
            )}
          </div>
          <div className="child_content">
            <div className="tile_header">
              {chartData && chartData.data && <LegendMarker shape={"line"} color={LIGHT_CYAN} />} %{" "}
              {regionParam == "China" ? "connected cars sold" : "produced connected cars"}
            </div>
            <div className="value" data-test-value-type={"produced_eligible"} data-test-value={produced}>
              {produced ? `${Math.round(produced)}%` : "n/a"}
            </div>
            {!isNull(comparisonProduced) && typeof comparisonProduced !== "undefined" && (
              <p className="period_comparison comparison">
                <span className="main">
                  <span className="comparison_value">
                    {isNull(comparisonProduced) ? "n/a" : `${plusSignNumbers(Math.round(comparisonProduced as number))} PTS `}
                    {comparisonLabel}
                  </span>
                </span>
              </p>
            )}
          </div>
        </div>

        {chartData && chartData.data && (
          <div className="metric_content">
            <ValueCombinedChart
              chartId={chartData.chartId}
              barSeriesName={chartData.barSeriesName}
              barDataKey={chartData.barDataKey}
              lineSeriesName={chartData.lineSeriesName}
              lineDataKey={chartData.lineSeriesKey}
              data={isAllUndefined ? [] : chartData.data}
              theme={themeContext.theme}
            />
          </div>
        )}
      </div>

      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};
