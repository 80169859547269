export const FETCH_USER_ENGAGEMENT = "FETCH_USER_ENGAGEMENT";
export const SET_USER_ENGAGEMENT = "SET_USER_ENGAGEMENT";

export const FETCH_USER_ENGAGEMENT_CHART_DATA = "FETCH_USER_ENGAGEMENT_CHART_DATA";
export const SET_USER_ENGAGEMENT_CHART_DATA = "SET_USER_ENGAGEMENT_CHART_DATA";

export const FETCH_ENROLLED_CUSTOMERS_DATA = "FETCH_ENROLLED_CUSTOMERS_DATA";
export const SET_ENROLLED_CUSTOMERS_DATA = "SET_ENROLLED_CUSTOMERS_DATA";

export const FETCH_ENROLLED_CUSTOMERS_CHART_DATA = "FETCH_ENROLLED_CUSTOMERS_CHART_DATA";
export const SET_ENROLLED_CUSTOMERS_CHART_DATA = "SET_ENROLLED_CUSTOMERS_CHART_DATA";

export const FETCH_BUSINESS_REVENUE_DATA = "FETCH_BUSINESS_REVENUE_DATA";
export const SET_BUSINESS_REVENUE_DATA = "SET_BUSINESS_REVENUE_DATA";

export const FETCH_BUSINESS_REVENUE_CHART_DATA = "FETCH_BUSINESS_REVENUE_CHART_DATA";
export const SET_BUSINESS_REVENUE_CHART_DATA = "SET_BUSINESS_REVENUE_CHART_DATA";

export const FETCH_SUBSCRIPTION_SALES_DATA = "FETCH_SUBSCRIPTION_SALES_DATA";
export const SET_SUBSCRIPTION_SALES_DATA = "SET_SUBSCRIPTION_SALES_DATA";

export const FETCH_SUBSCRIPTION_SALES_CHART_DATA = "FETCH_SUBSCRIPTION_SALES_CHART_DATA";
export const SET_SUBSCRIPTION_SALES_CHART_DATA = "SET_SUBSCRIPTION_SALES_CHART_DATA";

export const FETCH_CONNECTED_CARS_DATA = "FETCH_CONNECTED_CARS_DATA";
export const SET_CONNECTED_CARS_DATA = "SET_CONNECTED_CARS_DATA";

export const FETCH_CONNECTED_CARS_CHART_DATA = "FETCH_CONNECTED_CARS_CHART_DATA";
export const SET_CONNECTED_CARS_CHART_DATA = "SET_CONNECTED CARS_CHART_DATA";

export const FETCH_TOP_USED_SERVICES = "FETCH_TOP_USED_SERVICES";
export const SET_TOP_USED_SERVICES = "SET_TOP_USED_SERVICES";

export const FETCH_APP_RATINGS = "FETCH_APP_RATINGS";
export const SET_APP_RATINGS = "SET_APP_RATINGS";

export const FETCH_APP_RATINGS_CHART_DATA = "FETCH_APP_RATINGS_CHARTDATA";
export const SET_APP_RATINGS_CHART_DATA = "SET_APP_RATINGS_CHARTDATA";

export const FETCH_APP_RATINGS_BY_MARKET = "FETCH_APP_RATINGS_BY_MARKET";
export const SET_APP_RATINGS_BY_MARKET = "SET_APP_RATINGS_BY_MARKET";

export const FETCH_APP_RATINGS_BY_MARKET_CHART_DATA = "FETCH_APP_RATINGS_BY_MARKET_CHART_DATA";
export const SET_APP_RATINGS_BY_MARKET_CHART_DATA = "SET_APP_RATINGS_BY_MARKET_CHART_DATA";

export const FETCH_APP_REVIEW_BY_TOPIC = "FETCH_APP_REVIEW_BY_TOPIC";
export const SET_APP_REVIEW_BY_TOPIC = "SET_APP_REVIEW_BY_TOPIC";

export const FETCH_APP_REVIEW_BREAKDOWN = "FETCH_APP_REVIEW_BREAKDOWN";
export const SET_APP_REVIEW_BREAKDOWN = "SET_APP_REVIEW_BREAKDOWN";

export const FETCH_VOC_CALL_CENTRE = "FETCH_VOC_CALL_CENTRE";
export const SET_VOC_CALL_CENTRE = "SET_VOC_CALL_CENTRE";

export const FETCH_VOC_CALL_CENTRE_CHART_DATA = "FETCH_VOC_CALL_CENTRE_CHART_DATA";
export const SET_VOC_CALL_CENTRE_CHART_DATA = "SET_VOC_CALL_CENTRE_CHART_DATA";

export const FETCH_RENEWAL_CHURN_RATE = "FETCH_RENEWAL_CHURN_RATE";
export const SET_RENEWAL_CHURN_RATE = "SET_RENEWAL_CHURN_RATE";

export const FETCH_RENEWAL_CHURN_RATE_CHART_DATA = "FETCH_RENEWAL_CHURN_RATE_CHART_DATA";
export const SET_RENEWAL_CHURN_RATE_CHART_DATA = "SET_RENEWAL_CHURN_RATE_CHART_DATA";

export const FETCH_ALL_REVIEWS = "FETCH_ALL_REVIEWS";
export const SET_ALL_REVIEWS = "SET_ALL_REVIEWS";

export const FETCH_WARRANTY_START_DATE = "FETCH_WARRANTY_START_DATE";
export const SET_WARRANTY_START_DATE = "SET_WARRANTY_START_DATE";

export const FETCH_APP_SATISFACTION_DATA = "FETCH_APP_SATISFACTION_DATA";
export const SET_APP_SATISFACTION_DATA = "SET_APP_SATISFACTION_DATA";

export const FETCH_APP_ANALYSIS_FEATURE_USAGE_DATA = "FETCH_APP_ANALYSIS_FEATURE_USAGE_DATA";
export const SET_APP_ANALYSIS_FEATURE_USAGE_DATA = "SET_APP_ANALYSIS_FEATURE_USAGE_DATA";

export const FETCH_APP_ANALYSIS_WEB_USAGE_DATA = "FETCH_APP_ANALYSIS_WEB_USAGE_DATA";
export const SET_APP_ANALYSIS_WEB_USAGE_DATA = "SET_APP_ANALYSIS_WEB_USAGE_DATA";

export const FETCH_APP_RATINGS_DOWNLOADS_DATA = "FETCH_APP_RATINGS_DOWNLOADS_DATA";
export const SET_APP_RATINGS_DOWNLOADS_DATA = "SET_APP_RATINGS_DOWNLOADS_DATA";

export const FETCH_ENGAGEMENT_APP_DOWNLOADS_DATA = "FETCH_ENGAGEMENT__APP_DOWNLOADS_DATA";
export const SET_ENGAGEMENT_APP_DOWNLOADS_DATA = "SET_ENGAGEMENT_APP_DOWNLOADS_DATA";

export const FETCH_ENGAGEMENT_APP_DOWNLOADS_CHART_DATA = "FETCH_ENGAGEMENT_APP_DOWNLOADS_CHART_DATA";
export const SET_ENGAGEMENT_APP_DOWNLOADS_CHART_DATA = "SET_ENGAGEMENT_APP_DOWNLOADS_CHART_DATA";

export const FETCH_APP_OPEN_RATE_DATA = "FETCH_APP_OPEN_RATE_DATA";
export const SET_APP_OPEN_RATE_DATA = "SET_APP_OPEN_RATE_DATA";

export const FETCH_CCS_JOURNEY_DATA = "FETCH_CCS_JOURNEY_DATA";
export const SET_CCS_JOURNEY_DATA = "SET_CCS_JOURNEY_DATA";

export const FETCH_G8_PRIVATE_ACTIVATION_RATIO_DATA = "FETCH_G8_PRIVATE_ACTIVATION_RATIO_DATA";
export const SET_G8_PRIVATE_ACTIVATION_RATIO_DATA = "SET_G8_PRIVATE_ACTIVATION_RATIO_DATA";

export const FETCH_G8_PRIVATE_ACTIVATION_RATIO_CHART_DATA = "FETCH_G8_PRIVATE_ACTIVATION_RATIO_CHART_DATA";
export const SET_G8_PRIVATE_ACTIVATION_RATIO_CHART_DATA = "SET_G8_PRIVATE_ACTIVATION_RATIO_CHART_DATA";
export const FETCH_EUROPE_APP_RATINGS_AVG_DATA = "FETCH_EUROPE_APP_RATINGS_AVG_DATA";
export const SET_EUROPE_APP_RATINGS_AVG_DATA = "SET_EUROPE_APP_RATINGS_AVG_DATA";

export const FETCH_EUROPE_APP_RATINGS_AVG_TOTAL_DATA = "FETCH_EUROPE_APP_RATINGS_AVG_TOTAL_DATA";
export const SET_EUROPE_APP_RATINGS_AVG_TOTAL_DATA = "SET_EUROPE_APP_RATINGS_AVG_TOTAL_DATA";

export const FETCH_EUROPE_APP_RATINGS_TREND_DATA = "FETCH_EUROPE_APP_RATINGS_TREND_DATA";
export const SET_EUROPE_APP_RATINGS_TREND_DATA = "SET_EUROPE_APP_RATINGS_TREND_DATA";

export const FETCH_APP_RATINGS_AVG_DATA = "FETCH_APP_RATINGS_AVG_DATA";
export const SET_APP_RATINGS_AVG_DATA = "SET_APP_RATINGS_AVG_DATA";

export const FETCH_CCS_JOURNEY_BANNER_DATA = "FETCH_CCS_JOURNEY_BANNER_DATA";
export const SET_CCS_JOURNEY_BANNER_DATA = "SET_CCS_JOURNEY_BANNER_DATA";

export const FETCH_SALES_REVENUE_SOURCE = "FETCH_SALES_REVENUE_SOURCE";
export const SET_SALES_REVENUE_SOURCE = "SET_SALES_REVENUE_SOURCE";

export const FETCH_SALES_REVENUE_DATA = "FETCH_SALES_REVENUE_DATA";
export const SET_SALES_REVENUE_DATA = "SET_SALES_REVENUE_DATA";

export const FETCH_SALES_REVENUE_CHART_DATA = "FETCH_SALES_REVENUE_CHART_DATA";
export const SET_SALES_REVENUE_CHART_DATA = "SET_SALES_REVENUE_CHART_DATA";
