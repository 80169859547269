import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageName } from "../../../actions";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { VOC_BTN_FILTERS } from "../../../constants/vocConstants";
import { PageTitle } from "../../../components/PageContent/subs";
import VocQuickVocCecFilters from "../../../components/Filters/Voc/VocQuickVocCecFilters";
import { useQuery } from "@tanstack/react-query";
import "../../../assets/styles/pages/customerJourneyVoc.scss";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  getCustomerJourneyVocBuy,
  getCustomerJourneyVocFilters,
  getCustomerJourneyVocLastRefreshDate,
  getCustomerJourneyVocPerformanceByMarket,
  getCustomerJourneyVocTry,
  getCustomerJourneyVocUse,
} from "../../../api/customerJourneyVocApi";
import CustomerJourneyVocCategoryTile from "./components/CustomerJourneyVocCategoryTile";
import PerformanceByMarket from "../QuickVocSales/components/PerformanceByMarket";
import { getPerformanceByMarketMap } from "../../../api/quickVocAfterSales";
import { getQuickVocCecPerformanceByMarket } from "../../../api/quickVocCecApi";
import { DashboardBannerContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";

const metricMapping: Record<string, string> = {
  Website: "website",
  "Quick VOC Sales": "voc_quick_sales",
  "CEC Info Request": "info",
  "CEC CCS": "ccs",
  "CCS Vital Signs": "ccs_vital_signs",
  "Quick VOC After Sales": "voc_after_sales",
  "CEC Complaints": "complaint",
};

const CustomerJourneyVoc = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { history } = props;

  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);

  useEffect(() => {
    setShowBanner(true);
    setBannerText(
      "Please note that figures presented in the dashboard are a sample of FY24 data. CEDAR and AMIEO are collaborating to make full figures and extended date ranges available shortly."
    );
    return () => {
      setShowBanner(false);
      setBannerText("");
    };
  }, []);

  const { data: lastDataRefreshData, refetch: refetchLastDataRefresh } = useQuery({
    queryKey: ["customerJourneyVocLastDataRefresh"],
    queryFn: getCustomerJourneyVocLastRefreshDate,
    enabled: false,
  });

  useEffect(() => {
    dispatch(setPageName("Customer Journey VOC"));
    refetchLastDataRefresh();
    eventTracking(MixpanelEvents.page_view, { dashboard: "VOC", page: "Customer Journey VOC" });
  }, [dispatch]);

  const {
    data: customerJourneyVocTryData,
    isFetching: isFetchingCustomerJourneyVocTryData,
    refetch: refetchCustomerJourneyVocTryData,
  } = useQuery({
    queryKey: ["customerJourneyVocTry", history.location.search],
    queryFn: getCustomerJourneyVocTry,
    initialData: {},
    enabled: false,
  });

  const {
    data: customerJourneyVocBuyData,
    isFetching: isFetchingVocBuyData,
    refetch: refetchVocBuyData,
  } = useQuery({
    queryKey: ["customerJourneyVocBuy", history.location.search],
    queryFn: getCustomerJourneyVocBuy,
    initialData: {},
    enabled: false,
  });

  const {
    data: customerJourneyVocUseData,
    isFetching: isFetchingVocUseData,
    refetch: refetchVocUseData,
  } = useQuery({
    queryKey: ["customerJourneyVocUse", history.location.search],
    queryFn: getCustomerJourneyVocUse,
    initialData: {},
    enabled: false,
  });

  useEffect(() => {
    refetchCustomerJourneyVocTryData();
    refetchVocBuyData();
    refetchVocUseData();
  }, [history.location.search]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<VocQuickVocCecFilters filterBtns={VOC_BTN_FILTERS} queryFn={getCustomerJourneyVocFilters} pageName="VOC 360" />}
        dataDocumentation="voc"
        lastDataRefresh={lastDataRefreshData?.date ?? ""}
        pageTitle={<PageTitle dataDocumentation="voc" />}
      >
        <div className="customer-journey-voc">
          <div className="grid-3">
            <CustomerJourneyVocCategoryTile
              title="Try"
              tiles={[
                {
                  title: "Website",
                  value: customerJourneyVocTryData?.website,
                  rating: customerJourneyVocTryData?.website_previous_period,
                },
              ]}
              isLoading={isFetchingCustomerJourneyVocTryData}
            />
            <CustomerJourneyVocCategoryTile
              title="Buy"
              tiles={[
                {
                  title: "Quick VOC Sales",
                  value: customerJourneyVocBuyData?.voc_sales,
                  rating: customerJourneyVocBuyData?.voc_sales_previous_period,
                },
                {
                  title: "CEC Info Request",
                  value: customerJourneyVocBuyData?.cec_info_req,
                  rating: customerJourneyVocBuyData?.cec_info_req_previous_period,
                },
              ]}
              isLoading={isFetchingVocBuyData}
            />
            <CustomerJourneyVocCategoryTile
              title="Use"
              tiles={[
                {
                  title: "CEC CCS",
                  value: customerJourneyVocUseData?.ccs,
                  rating: customerJourneyVocUseData?.ccs_previous_period,
                },
                {
                  title: "Quick VOC After Sales",
                  value: customerJourneyVocUseData?.voc_after_sales,
                  rating: customerJourneyVocUseData?.voc_after_sales_previous_period,
                },
                {
                  title: "CCS Vital Signs",
                  value: customerJourneyVocUseData?.ccs_app_ratings,
                  rating: customerJourneyVocUseData?.ccs_app_ratings_previous_period,
                },
                {
                  title: "CEC Complaints",
                  value: customerJourneyVocUseData?.complaints,
                  rating: customerJourneyVocUseData?.complaints_previous_period,
                },
              ]}
              isLoading={isFetchingVocUseData}
            />
          </div>

          <PerformanceByMarket
            queryFn={getCustomerJourneyVocPerformanceByMarket}
            metrics={[
              "Website",
              "Quick VOC Sales",
              "CEC Info Request",
              "CEC CCS",
              "CCS Vital Signs",
              "Quick VOC After Sales",
              "CEC Complaints",
            ]}
            metricMapping={metricMapping}
          />
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default CustomerJourneyVoc;
