import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { ErrorMsg } from "../../../../components/AppMessages";
import { BusinessRevenueTiles, CockpitTitle, CustomerQualityTiles, EngagementTiles, OperationalTiles } from "../normalSections";

export const NormalContent = () => {
  return (
    <>
      <div id="business_revenue" className="cockpit_row normal" data-target="business_revenue">
        <CockpitTitle title={"Business revenue"} hide_on_desktop={true} />
        <ErrorBoundary fallback={<ErrorMsg />}>
          <BusinessRevenueTiles />
        </ErrorBoundary>
      </div>

      <div id="customer_quality" className="cockpit_row normal" data-target="customer_quality">
        <CockpitTitle title={"Customer quality"} hide_on_desktop={true} />
        <ErrorBoundary fallback={<ErrorMsg />}>
          <CustomerQualityTiles />
        </ErrorBoundary>
      </div>

      <div id="engagement" className="cockpit_row normal" data-target="engagement">
        <CockpitTitle title={"Engagement"} hide_on_desktop={true} />

        <ErrorBoundary fallback={<ErrorMsg />}>
          <EngagementTiles />
        </ErrorBoundary>
      </div>

      <div id="operational" className="cockpit_row" data-target="operational">
        <CockpitTitle title={"Operational"} hide_on_desktop={true} />
        <ErrorBoundary fallback={<ErrorMsg />}>
          <OperationalTiles />
        </ErrorBoundary>
      </div>
    </>
  );
};
