import { DELETE_SPECIFIC_PARAMETER, SET_PARAMETERS, SET_SPECIFIC_PARAMETER } from "../actions/actionTypes";
import { DEFAULT_PURCHASE_FUNNEL_PARAMETERS } from "../constants";

const searchParam = new URLSearchParams(window.location.search);

//@ts-ignore
const searchSpecificState = Object.fromEntries(searchParam.entries());

// Sets initial parameter state to the url parameters / default parameters when no parameters have been defined
const initialState: { [index: string]: string | boolean } =
  Object.keys(searchSpecificState).length > 0
    ? { ...DEFAULT_PURCHASE_FUNNEL_PARAMETERS, ...searchSpecificState }
    : { ...DEFAULT_PURCHASE_FUNNEL_PARAMETERS };

const purchaseFunnelParametersReducer = (state = initialState, action: { payload: any; type: string; name: string }) => {
  switch (action.type) {
    case SET_PARAMETERS:
      return { ...state, ...action.payload };

    case SET_SPECIFIC_PARAMETER:
      return { ...state, [action.name]: action.payload };

    case DELETE_SPECIFIC_PARAMETER:
      const nextState = { ...state };
      delete nextState[action.name];
      return nextState;

    default:
      return state;
  }
};

export default purchaseFunnelParametersReducer;
