import React from "react";
import Toggle from "react-toggle";
import "../../../assets/styles/component/toggleInput.scss";

interface Props {
  id: string;
  label: React.ReactNode;
  name: string;
  leftLabel?: string;
  rightLabel?: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: any;
  className?: string;
}

export const FormsToggleSwitch = (props: Props): JSX.Element => {
  const { checked, name, label, id, className, leftLabel, rightLabel, onChange } = props;
  let wrapperClass = "input_field";

  if (props.error?.length > 0) {
    wrapperClass += " has-error";
  }

  return (
    <div className={wrapperClass}>
      <label htmlFor={id}>{label}</label>
      <div className="field">
        {
          <>
            {leftLabel && <span className="toggle-label">{leftLabel}</span>}{" "}
            <Toggle checked={checked} name={name} onChange={onChange} id={id} className={className ? className : ""} icons={false} />
            {rightLabel && <span className="toggle-label">{rightLabel}</span>}
          </>
        }
      </div>
      {props.error && <div className="alert alert-danger">{props.error}</div>}
    </div>
  );
};
