import React from "react";
import { GRADE_MIX_COLORS } from "../../../../constants";

interface Props {
  legendList: { name: string; volumeField: string; color: string; gradeField: string }[];
  seriesList: { name: string; volumeField: string; color: string; gradeField: string }[];
  nimTech?: boolean;
}

const ComparisonLegend = (props: Props) => {
  const { legendList, seriesList, nimTech } = props;
  return (
    <div className={`trend-legend showOnMobileOnly ${nimTech ? "nim_tech_legend" : ""}`}>
      {legendList.map((series: { name: string }, index: number) => {
        return (
          <div className="legend-item" key={series.name}>
            <div className="legend-box" style={{ backgroundColor: GRADE_MIX_COLORS[seriesList?.length - 1 - index] }}></div>
            {` ${series.name}`}
          </div>
        );
      })}
    </div>
  );
};

export default ComparisonLegend;
