import { createContext, Dispatch, SetStateAction } from "react";
import { IReleaseNote } from "../constants/interface/admin/releaseNotes";

export const PopUpMessagesContext = createContext<{
  flashMessage: string;
  setFlashMessage: Dispatch<SetStateAction<string>>;
  showFlashMessage: boolean;
  setShowFlashMessage: Dispatch<SetStateAction<boolean>>;

  releaseNote: IReleaseNote | undefined;
  setReleaseNote: Dispatch<SetStateAction<IReleaseNote | undefined>>;

  showReleaseMessage: boolean;
  setShowReleaseMessage: Dispatch<SetStateAction<boolean>>;
}>({
  flashMessage: "",
  setFlashMessage(): void {},

  showFlashMessage: false,
  setShowFlashMessage(): void {},

  releaseNote: undefined,
  setReleaseNote(): void {},

  showReleaseMessage: false,
  setShowReleaseMessage(): void {},
});
