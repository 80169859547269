import React from "react";
import "../../../../assets/styles/component/ratingsPills.scss";

interface Props {
  title: string;
  values: Array<string | number>;
  selectedValues: Array<string | number>;
  handlePillClick: (evt: React.MouseEvent<HTMLElement>) => void;
}

export const RatingsPills = (props: Props) => {
  const { values, title, selectedValues, handlePillClick } = props;

  return (
    <div className={"pills_container"}>
      <div className="pill_header">
        <p className="white_info">{title}</p>
      </div>
      <div className="pill_selections">
        {values.map((value) => (
          <div
            className={`pill ${selectedValues.includes(value) ? "active" : ""}`}
            data-value={value}
            key={value}
            onClick={handlePillClick}
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};
