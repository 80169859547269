import React from "react";
import { ModelData } from "../../../components/Documentation/docs";
import { DataDictionaryTable, DataUpdateLogTable } from "../../../components/Tables";

interface Props {
  value: string;
}

export const SwitchDataDocumentation = (props: Props): JSX.Element => {
  switch (props.value) {
    case "kpi_definition":
      return <DataDictionaryTable />;

    case "kpi_update":
      return <DataUpdateLogTable />;

    case "model_data":
      return <ModelData />;

    default:
      return <DataDictionaryTable />;
  }
};
