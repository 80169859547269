import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageName } from "../../../actions";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import VocDashboardFilters from "../../../components/Filters/Voc/VocWebsiteFilters";
import { VOC_BTN_FILTERS } from "../../../constants/vocConstants";
import { PageTitle } from "../../../components/PageContent/subs";

const VocRsa = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageName(""));
  }, [dispatch]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<VocDashboardFilters filterBtns={VOC_BTN_FILTERS} inComplete />}
        dataDocumentation=""
        lastDataRefresh={""}
        pageTitle={<PageTitle dataDocumentation="" />}
      >
        <div className="no-data-tile">This dashboard page is in development and will be available soon in CEDAR home</div>
      </DefaultPageContent>
    </DashboardLayout>
  );
};

export default VocRsa;
