import React from "react";
import "../../../assets/styles/main.scss";
import "../../../assets/styles/pages/login.scss";
import { AccessDenied } from "../../../components/ErrorTemplates";
import { LoginErrorTemplate } from "./subs";

export const AccessDeniedPage = () => {
  return (
    <LoginErrorTemplate>
      <AccessDenied />
    </LoginErrorTemplate>
  );
};
