import moment from "moment";

export const checkConsecutiveMonths = (selectedMonths: Array<string>, orderedMonths: Array<string>) => {
  let consecutiveDateRange = ""; //Variable to store the date range if the dates are consecutive
  let popMonths: Array<string> = []; //Array to store the pop months when consecutive months are generated

  //Get the exact index of the selected months from the original ordered array of months
  const selectedMonthsIndexes: Array<number> = orderedMonths
    .map((month, index) => (selectedMonths.includes(month) ? index : ""))
    .filter((value) => typeof value === "number") as Array<number>;
  //Check if the indexes above are consecutive
  const isConsecutive =
    selectedMonthsIndexes?.length > 1 &&
    !!selectedMonthsIndexes.reduce((output, lastest) => (output || output === 0 ? (output + 1 === lastest ? lastest : 0) : 0));

  if (isConsecutive) {
    //Get the index of the smallest/largest month from the selected months
    const smallestMonthIndex = Math.min(...selectedMonthsIndexes);
    const greatestMonthIndex = Math.max(...selectedMonthsIndexes);

    //Get the index of previous n number of months from the selected range
    const smallestPoPMonthIndex = Number(smallestMonthIndex) - Number(selectedMonthsIndexes?.length);
    const greatestPoPMonthIndex = Number(greatestMonthIndex) - Number(selectedMonthsIndexes?.length);

    const smallest = moment(orderedMonths[smallestMonthIndex], "MMMM YYYY").format("MMM YY");
    const largest = moment(orderedMonths[greatestMonthIndex], "MMMM YYYY").format("MMM YY");
    consecutiveDateRange = `${smallest} - ${largest}`;

    //Get the pop months
    popMonths = orderedMonths.slice(smallestPoPMonthIndex, greatestPoPMonthIndex + 1);
  }
  return { isConsecutive, consecutiveDateRange, popMonths };
};
