import React, { useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { setPageName } from "../../../actions";
import {
  fetchSelfServicePopularSearchTermsViaGoogle,
  fetchSelfServiceSelfLearningPageVisitsAndTimeSpent,
  fetchSelfServiceSelfLearningPageVisitsByChannel,
  fetchSelfServiceSelfLearningPageVisitsByEntryPoint,
  fetchSelfServiceTopicClassificationDissatisfaction,
  fetchSelfServiceTopicClassificationSatisfaction,
  fetchSelfServiceTopicClassificationVerbatimData,
  fetchSelfServiceUserVisitingSelfLearningPage,
  fetchSelfServiceVocRatingForSelfLearningPage,
  fetchSelfServiceVocRatingForSelfLearningPageOverTime,
} from "../../../actions/useOwnershipTypes";
import LineTrendChart from "../../../components/Charts/UseOwnership/LineTrendChart";
import MultipleBarLineTrendChart from "../../../components/Charts/UseOwnership/MultipleBarLineTrendChart";
import UseOwnershipDonutChart from "../../../components/Charts/UseOwnership/UseOwnershipDonutChart";
import UseOwnershipTopicChart from "../../../components/Charts/UseOwnership/UseOwnershipTopicChart";
import UseOwnershipFilters from "../../../components/Filters/UseOwnershipFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { ToggleSwitch } from "../../../components/Toogle";
import { AVG_APP_RATINGS_COLORS, VOC_TOPIC_CLASSIFICATION_COLORS } from "../../../constants";
import { USE_OWNERSHIP_BTN_FILTERS } from "../../../constants/useOwnershipConstants";
import { DashboardBannerContext, ThemeContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import MetricTile from "../components/MetricTile";
import PopularSearchTable from "./components/PopularSearchTable";
import VerbatimTable from "./components/VerbatimTable";

const topicCategories = ["Experience", "Tools", "Contact", "Content"];

const SelfService = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const { setShowBanner, setBannerText } = useContext(DashboardBannerContext);
  const { history } = props;
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.use_ownership_last_data_refresh);
  const dateRangeParameter = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const {
    self_service_users_visiting_self_learning_page,
    self_service_self_learning_page_visits_and_time_spent,
    self_service_self_learning_page_visits_by_channel,
    self_service_self_learning_page_visits_by_entry_point,
    self_service_voc_rating_for_self_learning_page,
    self_service_voc_rating_for_self_learning_page_over_time,
    self_service_popular_search_terms_via_google,
    self_service_topic_classification_dissatisfaction,
    self_service_topic_classification_satisfaction,
  } = useSelector((state: RootStateOrAny) => state.use_ownership);
  const {
    self_service_users_visiting_self_learning_page_loading,
    self_service_self_learning_page_visits_and_time_spent_loading,
    self_service_self_learning_page_visits_by_channel_loading,
    self_service_self_learning_page_visits_by_entry_point_loading,
    self_service_voc_rating_for_self_learning_page_loading,
    self_service_voc_rating_for_self_learning_page_over_time_loading,
    self_service_topic_classification_dissatisfaction_loading,
    self_service_topic_classification_satisfaction_loading,
  } = useSelector((state: RootStateOrAny) => state.use_ownership);

  const [visitsByChannelTogglaState, setVisitsByChannelTogglaState] = useState(true);
  const [visitsByEntryPointToggleState, setVisitsByEntryPointToggleState] = useState(true);

  const [topTenToggleValue, setTopTenToggleValue] = useState(true);

  useEffect(() => {
    dispatch(setPageName("USE Ownership Dashboard - Self Service"));
    setShowBanner(true);
    setBannerText(
      " Please note that data availability differs across markets in scope. N/A is displayed when data is not available. For more information on data availability by market view the complete data dictionary by clicking on the book sing on bottom left."
    );

    return () => {
      setShowBanner(false);
      setBannerText("");
    };
  }, []);

  useEffect(() => {
    dispatch(fetchSelfServiceUserVisitingSelfLearningPage());
    dispatch(fetchSelfServiceSelfLearningPageVisitsAndTimeSpent());
    dispatch(fetchSelfServiceVocRatingForSelfLearningPage());
    dispatch(fetchSelfServiceVocRatingForSelfLearningPageOverTime());
    dispatch(fetchSelfServiceTopicClassificationVerbatimData());
    dispatch(fetchSelfServiceTopicClassificationDissatisfaction());
    dispatch(fetchSelfServiceTopicClassificationSatisfaction());
    dispatch(fetchSelfServicePopularSearchTermsViaGoogle());
  }, [history.location.search]);

  useEffect(() => {
    dispatch(fetchSelfServiceSelfLearningPageVisitsByChannel(visitsByChannelTogglaState));
  }, [history.location.search, visitsByChannelTogglaState]);

  useEffect(() => {
    dispatch(fetchSelfServiceSelfLearningPageVisitsByEntryPoint(visitsByEntryPointToggleState));
  }, [history.location.search, visitsByEntryPointToggleState]);

  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Use Ownership", page: props.history.location.pathname });
  }, [window.location]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<UseOwnershipFilters filterBtns={USE_OWNERSHIP_BTN_FILTERS} page="Self Service" />}
        dataDocumentation="use_ownership"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="use_ownership/self_service" />}
      >
        <div className="self-service-page">
          <div className="row">
            <MetricTile
              title="Users visiting self-learning page"
              titleTooltipText={"Users who lands on the self-learning page on the website"}
              mainValue={self_service_users_visiting_self_learning_page?.Current?.users_visiting_self_learning_page}
              yoyValue={self_service_users_visiting_self_learning_page?.YoY?.perc_users_visiting_self_learning_page}
              popValue={self_service_users_visiting_self_learning_page?.PoP?.perc_users_visiting_self_learning_page}
              showPoP={true}
              isLoading={self_service_users_visiting_self_learning_page_loading}
            />
          </div>
          <div className="row">
            <div className="tile self-learning">
              <div className="title-container">
                <div className="title">Self-learning page visits and time spent</div>
                <Tooltip
                  position="top-end"
                  className={"normal_tippy"}
                  trigger="mouseenter"
                  animation="none"
                  interactive={true}
                  delay={1}
                  hideDelay={1}
                  duration={1}
                  html={
                    <div>
                      <p>
                        <b>Self-learning page visits:</b> Visits to Self-learning page.
                      </p>
                      <br />
                      <p>
                        <b>Time spent on self-learning page:</b> Users who lands on the self-learning page on the website.
                      </p>
                    </div>
                  }
                  size={"small"}
                >
                  <span className="info_tooltip" />
                </Tooltip>
              </div>
              <div className="chart">
                <MultipleBarLineTrendChart
                  chartId="selfLearningVisitsTimeSpent"
                  data={self_service_self_learning_page_visits_and_time_spent?.data ?? []}
                  seriesList={self_service_self_learning_page_visits_and_time_spent.series ?? []}
                  theme={theme}
                  isLoading={self_service_self_learning_page_visits_and_time_spent_loading}
                />
              </div>
            </div>
            <div className="tile self-learning">
              <div className="title-container">
                <div className="title">
                  Self-learning page visits by channel
                  <Tooltip
                    position="top-end"
                    className={"normal_tippy"}
                    trigger="mouseenter"
                    animation="none"
                    interactive={true}
                    delay={1}
                    hideDelay={1}
                    duration={1}
                    title={
                      "Visits to Self-learning page with the breakdown by channel. Please note that channels vary across diferent markets. "
                    }
                    size={"small"}
                  >
                    <span className="info_tooltip" />
                  </Tooltip>
                </div>
                <ToggleSwitch
                  activeToggleLabel="Trend"
                  inactiveToggleLabel="Cumulative"
                  active={visitsByChannelTogglaState}
                  handleToggleClick={() => setVisitsByChannelTogglaState((prevState) => !prevState)}
                />
              </div>

              <div className="chart">
                {visitsByChannelTogglaState ? (
                  <LineTrendChart
                    chartId="visitsByChannelTrend"
                    isLoading={self_service_self_learning_page_visits_by_channel_loading}
                    theme={theme}
                    data={self_service_self_learning_page_visits_by_channel?.data ?? []}
                    seriesList={self_service_self_learning_page_visits_by_channel?.series ?? []}
                    seriesColors={AVG_APP_RATINGS_COLORS}
                  />
                ) : (
                  <UseOwnershipDonutChart
                    chartId="visitsByChannelDonut"
                    valueField="percentage"
                    categoryField={"channel"}
                    theme={theme}
                    data={self_service_self_learning_page_visits_by_channel?.data ?? []}
                    isLoading={self_service_self_learning_page_visits_by_channel_loading}
                    showLegend={true}
                    hideLabels={true}
                    innerRadius={35}
                    radius={100}
                    showTotalValueInTooltip={true}
                    totalValueInTooltipKey="self_learning_page_visits"
                  />
                )}
              </div>
            </div>
            <div className="tile self-learning">
              <div className="title-container">
                <div className="title">
                  Self-learning page visits by entry point
                  <Tooltip
                    position="top-end"
                    className={"normal_tippy"}
                    trigger="mouseenter"
                    animation="none"
                    interactive={true}
                    delay={1}
                    hideDelay={1}
                    duration={1}
                    title={
                      "Visits on Self-learning page with the breakdown by channel. Entry point is considered from which part of the website the users came before landing on the Service Booking form. Please note that if entry point is Self-learning page it means that it was the first contact of the users with the website."
                    }
                    size={"small"}
                  >
                    <span className="info_tooltip" />
                  </Tooltip>
                </div>
                <ToggleSwitch
                  activeToggleLabel="Trend"
                  inactiveToggleLabel="Cumulative"
                  active={visitsByEntryPointToggleState}
                  handleToggleClick={() => setVisitsByEntryPointToggleState((prevState) => !prevState)}
                />
              </div>

              <div className="chart">
                {visitsByEntryPointToggleState ? (
                  <LineTrendChart
                    chartId="visitsByEntryPointTrend"
                    isLoading={self_service_self_learning_page_visits_by_entry_point_loading}
                    theme={theme}
                    data={self_service_self_learning_page_visits_by_entry_point?.data ?? []}
                    seriesList={self_service_self_learning_page_visits_by_entry_point?.series ?? []}
                    seriesColors={AVG_APP_RATINGS_COLORS}
                  />
                ) : (
                  <UseOwnershipDonutChart
                    chartId="VisitsByEntryPointDonut"
                    valueField="percentage"
                    categoryField={"entry_point"}
                    theme={theme}
                    data={self_service_self_learning_page_visits_by_entry_point?.data ?? []}
                    isLoading={self_service_self_learning_page_visits_by_entry_point_loading}
                    showLegend={true}
                    hideLabels={true}
                    innerRadius={35}
                    radius={100}
                    showTotalValueInTooltip={true}
                    totalValueInTooltipKey="self_learning_page_visits"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="metric-chart-container">
              <MetricTile
                title="VOC rating for Self-learning page"
                titleTooltipText={"Average out of 5 of all ratings given by users to evaluate their experience on Self-learning pages"}
                mainValue={self_service_voc_rating_for_self_learning_page?.Current?.average_experience_rating}
                yoyValue={self_service_voc_rating_for_self_learning_page?.YoY?.pts_average_experience_rating}
                popValue={self_service_voc_rating_for_self_learning_page?.PoP?.pts_average_experience_rating}
                showPoP={true}
                isRating={true}
                maxRating={5}
                isLoading={self_service_voc_rating_for_self_learning_page_loading}
              />
              <div className="tile" style={{ height: "100%" }}>
                <div className="title-container">
                  <div className="title">VOC rating for Self-learning page over time</div>
                  <Tooltip
                    position="top-end"
                    className={"normal_tippy"}
                    trigger="mouseenter"
                    animation="none"
                    interactive={true}
                    delay={1}
                    hideDelay={1}
                    duration={1}
                    title={"Average out of 5 of all ratings given by users to evaluate their experience on Self-learning pages"}
                    size={"small"}
                  >
                    <span className="info_tooltip" />
                  </Tooltip>
                </div>
                <div className="chart">
                  <MultipleBarLineTrendChart
                    chartId="vocRatinggOverTime"
                    data={self_service_voc_rating_for_self_learning_page_over_time?.data ?? []}
                    seriesList={self_service_voc_rating_for_self_learning_page_over_time?.series ?? []}
                    theme={theme}
                    isLoading={self_service_voc_rating_for_self_learning_page_over_time_loading}
                  />
                </div>
              </div>
            </div>
            <div className="tile self-learning top-categories">
              <div className="title-container">
                <div className="title">
                  Top 10 categories and verbatims
                  <Tooltip
                    position="top-end"
                    className={"normal_tippy"}
                    trigger="mouseenter"
                    animation="none"
                    interactive={true}
                    delay={1}
                    hideDelay={1}
                    duration={1}
                    title={"Number of verbatims per topic"}
                    size={"small"}
                  >
                    <span className="info_tooltip" />
                  </Tooltip>
                  <ToggleSwitch
                    activeToggleLabel={"Ratings from 1 to 3"}
                    inactiveToggleLabel={"Ratings from 4 to 5"}
                    active={topTenToggleValue}
                    handleToggleClick={() => {
                      setTopTenToggleValue((prevState) => !prevState);
                    }}
                    toggleClassName={"filter_toggle"}
                  />
                </div>
              </div>

              <div className="chipContainer">
                {topicCategories &&
                  topicCategories.map((category: string) => (
                    <div key={category} className="chip" style={{ backgroundColor: VOC_TOPIC_CLASSIFICATION_COLORS[category] }}>
                      {category}
                    </div>
                  ))}
              </div>

              <div className="chart">
                {/* <NoDataMsg /> */}
                <UseOwnershipTopicChart
                  chartId={`topTenCategoriesChart`}
                  data={
                    topTenToggleValue ? self_service_topic_classification_dissatisfaction : self_service_topic_classification_satisfaction
                  }
                  theme={theme}
                  colorMapping={VOC_TOPIC_CLASSIFICATION_COLORS}
                  isLoading={
                    topTenToggleValue
                      ? self_service_topic_classification_dissatisfaction_loading
                      : self_service_topic_classification_satisfaction_loading
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <VerbatimTable />
          </div>

          <div className="row popular-search-row">
            {/* <PopularSearchTable id="LiveChat" title="Popular search terms live chat" data={tableData} tooltipText="" /> */}
            <PopularSearchTable
              id="GoogleSearch"
              title="Popular search terms via google search for guided learning"
              data={self_service_popular_search_terms_via_google}
              tooltipText="Volume and share of guided learning serach terms in Google Search"
            />
          </div>
          {/* <div className="call-center-volumes-row">
            <div className="title-container">
              <div className="title">Call center volumes related to GL topics</div>
              <Tooltip
                position="top-end"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                delay={1}
                hideDelay={1}
                duration={1}
                title={"TEXT"}
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
            </div>
            <div className="charts">
              <div>
                <CallCenterVolumesChart
                  seriesList={callCenterChartData.series}
                  data={formattedCallCenterTrendData}
                  chartId="callCenterVolumeChart1"
                  theme={theme}
                  isLoading={false}
                  dateValue={dateRangeParameter}
                />
              </div>
              <div>
                <CallCenterVolumesChart
                  seriesList={callCenterChartData.series}
                  data={formattedCallCenterTrendData}
                  chartId="callCenterVolumeChart2"
                  theme={theme}
                  isLoading={false}
                  dateValue={dateRangeParameter}
                />
              </div>
              <div>
                <CallCenterVolumesChart
                  seriesList={callCenterChartData.series}
                  data={formattedCallCenterTrendData}
                  chartId="callCenterVolumeChart3"
                  theme={theme}
                  isLoading={false}
                  dateValue={dateRangeParameter}
                />
              </div>
            </div>
          </div> */}
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default SelfService;
