import {
  FETCH_CONVERSION,
  FETCH_CONVERSION_AND_COST,
  FETCH_CONVERSION_TREND,
  FETCH_CUSTOMER_EXPERIENCE_DXS,
  FETCH_CUSTOMER_JOURNEY,
  FETCH_DIGITAL_SPEND,
  FETCH_DIGITAL_SPEND_VISITS_DATA,
  FETCH_DIGITAL_SPEND_VISITS_TREND,
  FETCH_EXIT_SURVEY_FEEDBACK,
  FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION,
  FETCH_FMI_LAST_ACT_MONTH,
  FETCH_JOURNEY_COST,
  FETCH_JOURNEY_COST_TREND,
  FETCH_LMS_CONVERSION_AND_COST,
  FETCH_LMS_CONVERSION_AND_COST_TREND,
  FETCH_LMS_DIGITALLY_INFLUENCED_SALES,
  FETCH_LMS_DIGITALLY_INFLUENCED_SALES_TREND,
  FETCH_LMS_DIS_AND_LEADS,
  FETCH_LMS_SALES_AND_LEADS_TREND,
  FETCH_METRIC_CHANNEL,
  FETCH_METRIC_CHANNEL_TREND,
  FETCH_PERFORMANCE_OVERVIEW,
  FETCH_PRODUCT_JOURNEY_ENTRY_POINTS,
  FETCH_PRODUCT_JOURNEY_EXIT_POINTS,
  FETCH_PRODUCT_JOURNEY_PERFORMANCE_OVERVIEW,
  FETCH_PRODUCT_JOURNEY_WEBSITE_ENTRY_POINTS,
  FETCH_STAR_RATING_AVERAGE,
  FETCH_STAR_RATING_FEEDBACK,
  FETCH_WEBSITE_PERFORMANCE,
  FETCH_WEBSITE_PERFORMANCE_TREND,
  SET_CONVERSION,
  SET_CONVERSION_AND_COST,
  SET_CONVERSION_TREND,
  SET_CUSTOMER_EXPERIENCE_DXS,
  SET_CUSTOMER_JOURNEY,
  SET_DIGITAL_SPEND,
  SET_DIGITAL_SPEND_VISITS_DATA,
  SET_DIGITAL_SPEND_VISITS_TREND,
  SET_EXIT_SURVEY_FEEDBACK,
  SET_EXIT_SURVEY_OBJECTIVE_COMPLETION,
  SET_FMI_LAST_ACT_MONTH,
  SET_JOURNEY_COST,
  SET_JOURNEY_COST_TREND,
  SET_JOURNEY_EXCELLENCE_CURRENCY,
  SET_LMS_CONVERSION_AND_COST,
  SET_LMS_CONVERSION_AND_COST_TREND,
  SET_LMS_DIGITALLY_INFLUENCED_SALES,
  SET_LMS_DIGITALLY_INFLUENCED_SALES_TREND,
  SET_LMS_DIS_AND_LEADS,
  SET_LMS_SALES_AND_LEADS_TREND,
  SET_METRIC_CHANNEL,
  SET_METRIC_CHANNEL_TREND,
  SET_PERFORMANCE_OVERVIEW,
  SET_PRODUCT_JOURNEY_ENTRY_POINTS,
  SET_PRODUCT_JOURNEY_EXIT_POINTS,
  SET_PRODUCT_JOURNEY_PERFORMANCE_OVERVIEW,
  SET_PRODUCT_JOURNEY_WEBSITE_ENTRY_POINTS,
  SET_STAR_RATING_AVERAGE,
  SET_STAR_RATING_FEEDBACK,
  SET_WEBSITE_PERFORMANCE,
  SET_WEBSITE_PERFORMANCE_TREND,
} from "./actionTypes";

export const fetchDigitalSpend = (showActuals: boolean) => ({ type: FETCH_DIGITAL_SPEND, payload: showActuals });
export const setDigitalSpend = (data: any) => ({ type: SET_DIGITAL_SPEND, payload: data });

export const fetchDigitalSpendVisitsData = (showActuals: boolean) => ({ type: FETCH_DIGITAL_SPEND_VISITS_DATA, payload: showActuals });
export const setDigitalSpendVisitsData = (data: any) => ({ type: SET_DIGITAL_SPEND_VISITS_DATA, payload: data });

export const fetchDigitalSpendVisitsTrend = () => ({ type: FETCH_DIGITAL_SPEND_VISITS_TREND });
export const setDigitalSpendVisitsTrend = (data: any) => ({ type: SET_DIGITAL_SPEND_VISITS_TREND, payload: data });

export const fetchWebsitePerformance = () => ({ type: FETCH_WEBSITE_PERFORMANCE });
export const setWebsitePerformance = (data: any) => ({ type: SET_WEBSITE_PERFORMANCE, payload: data });

export const fetchWebsitePerformanceTrend = () => ({ type: FETCH_WEBSITE_PERFORMANCE_TREND });
export const setWebsitePerformanceTrend = (data: any) => ({ type: SET_WEBSITE_PERFORMANCE_TREND, payload: data });

export const fetchJourneyCost = (showActuals: boolean) => ({ type: FETCH_JOURNEY_COST, payload: showActuals });
export const setJourneyCost = (data: any) => ({ type: SET_JOURNEY_COST, payload: data });

export const fetchJourneyCostTrend = () => ({ type: FETCH_JOURNEY_COST_TREND });
export const setJourneyCostTrend = (data: any) => ({ type: SET_JOURNEY_COST_TREND, payload: data });

export const fetchCustomerJourney = () => ({ type: FETCH_CUSTOMER_JOURNEY });
export const setCustomerJourney = (data: any) => ({ type: SET_CUSTOMER_JOURNEY, payload: data });

export const fetchConversion = () => ({ type: FETCH_CONVERSION });
export const setConversion = (data: any) => ({ type: SET_CONVERSION, payload: data });

export const fetchConversionTrend = () => ({ type: FETCH_CONVERSION_TREND });
export const setConversionTrend = (data: any) => ({ type: SET_CONVERSION_TREND, payload: data });

export const fetchMetricChannel = () => ({ type: FETCH_METRIC_CHANNEL });
export const setMetricChannel = (data: any) => ({ type: SET_METRIC_CHANNEL, payload: data });

export const fetchMetricChannelTrend = (channel: boolean, metric: string) => ({
  type: FETCH_METRIC_CHANNEL_TREND,
  payload: { channel, metric },
});
export const setMetricChannelTrend = (data: any) => ({ type: SET_METRIC_CHANNEL_TREND, payload: data });

export const fetchCustomerExperienceDxs = () => ({ type: FETCH_CUSTOMER_EXPERIENCE_DXS });
export const setCustomerExperienceDxs = (data: any) => ({ type: SET_CUSTOMER_EXPERIENCE_DXS, payload: data });

export const fetchStarRatingAverage = () => ({ type: FETCH_STAR_RATING_AVERAGE });
export const setStarRatingAverage = (data: any) => ({ type: SET_STAR_RATING_AVERAGE, payload: data });

export const fetchStarRatingFeedback = () => ({ type: FETCH_STAR_RATING_FEEDBACK });
export const setStarRatingFeedback = (data: any) => ({ type: SET_STAR_RATING_FEEDBACK, payload: data });

export const fetchExitSurveyObjectiveCompletion = () => ({ type: FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION });
export const setExitSurveyObjectiveCompletion = (data: any) => ({ type: SET_EXIT_SURVEY_OBJECTIVE_COMPLETION, payload: data });

export const fetchExitSurveyFeedback = () => ({ type: FETCH_EXIT_SURVEY_FEEDBACK });
export const setExitSurveyFeedback = (data: any) => ({ type: SET_EXIT_SURVEY_FEEDBACK, payload: data });

export const fetchConversionAndCost = (showActuals: boolean) => ({ type: FETCH_CONVERSION_AND_COST, payload: showActuals });
export const setConversionAndCost = (data: any) => ({ type: SET_CONVERSION_AND_COST, payload: data });

export const fetchPerformanceOverview = () => ({ type: FETCH_PERFORMANCE_OVERVIEW });
export const setPerformanceOverview = (data: any) => ({ type: SET_PERFORMANCE_OVERVIEW, payload: data });

export const fetchLmsDigitallyInfluencedSales = () => ({ type: FETCH_LMS_DIGITALLY_INFLUENCED_SALES });
export const setLmsDigitallyInfluencedSales = (data: any) => ({ type: SET_LMS_DIGITALLY_INFLUENCED_SALES, payload: data });

export const fetchLmsDigitallyInfluencedSalesTrend = () => ({ type: FETCH_LMS_DIGITALLY_INFLUENCED_SALES_TREND });
export const setLmsDigitallyInfluencedSalesTrend = (data: any) => ({ type: SET_LMS_DIGITALLY_INFLUENCED_SALES_TREND, payload: data });

export const fetchLmsDisAndLeads = () => ({ type: FETCH_LMS_DIS_AND_LEADS });
export const setLmsDisAndLeads = (data: any) => ({ type: SET_LMS_DIS_AND_LEADS, payload: data });

export const fetchLmsSalesAndLeadsTrend = () => ({ type: FETCH_LMS_SALES_AND_LEADS_TREND });
export const setLmsSalesAndLeadsTrend = (data: any) => ({ type: SET_LMS_SALES_AND_LEADS_TREND, payload: data });

export const fetchLmsConversionAndCost = (showActuals: boolean) => ({ type: FETCH_LMS_CONVERSION_AND_COST, payload: showActuals });
export const setLmsConversionAndCost = (data: any) => ({ type: SET_LMS_CONVERSION_AND_COST, payload: data });

export const fetchLmsConversionAndCostTrend = () => ({ type: FETCH_LMS_CONVERSION_AND_COST_TREND });
export const setLmsConversionAndCostTrend = (data: any) => ({ type: SET_LMS_CONVERSION_AND_COST_TREND, payload: data });

export const fetchProductJourneyPerformanceOverview = (metric: string) => ({
  type: FETCH_PRODUCT_JOURNEY_PERFORMANCE_OVERVIEW,
  payload: metric,
});
export const setProductJourneyPerformanceOverview = (data: any) => ({ type: SET_PRODUCT_JOURNEY_PERFORMANCE_OVERVIEW, payload: data });

export const fetchProductJourneyEntryPoints = () => ({ type: FETCH_PRODUCT_JOURNEY_ENTRY_POINTS });
export const setProductJourneyEntryPoints = (data: any) => ({ type: SET_PRODUCT_JOURNEY_ENTRY_POINTS, payload: data });

export const fetchProductJourneyExitPoints = () => ({ type: FETCH_PRODUCT_JOURNEY_EXIT_POINTS });
export const setProductJourneyExitPoints = (data: any) => ({ type: SET_PRODUCT_JOURNEY_EXIT_POINTS, payload: data });

export const fetchProductJourneyWebsiteEntryPoints = () => ({ type: FETCH_PRODUCT_JOURNEY_WEBSITE_ENTRY_POINTS });
export const setProductJourneyWebsiteEntryPoints = (data: any) => ({ type: SET_PRODUCT_JOURNEY_WEBSITE_ENTRY_POINTS, payload: data });

export const fetchFmiLastActMonth = () => ({ type: FETCH_FMI_LAST_ACT_MONTH });
export const setFmiLastActMonth = (data: any) => ({ type: SET_FMI_LAST_ACT_MONTH, payload: data });

export const setJourneyExcellenceCurrency = (data: string) => ({ type: SET_JOURNEY_EXCELLENCE_CURRENCY, payload: data });
