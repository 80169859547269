import { Button, DialogContent, DialogContentText, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setCurrentUserDetails } from "../../actions";
import { fetchDataV2, updateDataV2 } from "../../api/utils";
import { CYAN } from "../../constants";

type FormData = {
  department: string;
  other_department?: string;
};

const useStyles = makeStyles({
  button: {
    backgroundColor: CYAN,
    color: "#FFF !important",
    borderRadius: "0px !important",
    border: "none !important",
    marginRight: "15px",
    paddingTop: "8px !important",
    paddingBottom: "8px !important",
    "&:hover": {
      backgroundColor: "#5ed2ff !important",
      border: "none !important",
    },
  },
});

const SelectDepartmentForm = () => {
  const dispatch = useDispatch();
  const { details: currentUser } = useSelector((state: RootStateOrAny) => state.user);

  const [departments, setDepartments] = useState<Array<string>>([]);
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      department: currentUser?.department,
      other_department: currentUser?.other_department ?? null,
    },
  });

  const watchDepartment = watch("department");

  useEffect(() => {
    fetchDataV2("/user_service/get-all-departments").then((response) => {
      if (response && !("error" in response)) setDepartments(response);
    });
  }, []);

  useEffect(() => {
    if (currentUser?.email && !currentUser.department) {
      setOpen(true);
    }
  }, [currentUser]);

  const submitHandler = (values: FormData) => {
    if (!values.other_department) {
      delete values.other_department;
    }

    updateDataV2("/user_service/update-current-user", values).then((res) => {
      if (!res.error) {
        dispatch(setCurrentUserDetails(res));
        setOpen(false);
      } else {
        console.log(res.error);
      }
    });
  };

  return (
    <Dialog open={open} scroll="body" fullWidth>
      <DialogContent>
        <DialogContentText id="select-department-dialog-description" style={{ whiteSpace: "pre-line" }}>
          Dear user, <br></br>We are doing some modifications to CEDAR Home and we are adding more information about our users. Could you
          kindly add your department below?
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="department"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    id="department-select"
                    options={departments}
                    value={value}
                    onChange={(_, data) => onChange(data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Department"
                        required
                        error={Boolean(errors.department)}
                        helperText={Boolean(errors?.department) && "This field is required"}
                        InputProps={{ ...params.InputProps, ...{ "data-test-id": "department-input" } }}
                      />
                    )}
                  />
                )}
              />
            </FormControl>
          </Grid>

          {watchDepartment == "Other" && (
            <Grid item md={12}>
              <FormControl fullWidth>
                <Controller
                  name="other_department"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Other department"
                      id="other-department"
                      type="string"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      inputProps={{
                        "data-test-id": "other-department-input",
                      }}
                      error={Boolean(errors.other_department)}
                      helperText={Boolean(errors?.other_department) && "This field is required"}
                      required
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <Button
          className={classes.button}
          variant="outlined"
          type="submit"
          onClick={handleSubmit(submitHandler)}
          disableElevation
          data-test-id={`btn-update-user-department`}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectDepartmentForm;
