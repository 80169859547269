export const CHART_TOOLTIP_FORMAT: { [index: string]: { dateFormat: string; tooltipFormat: string } } = {
  daily: {
    dateFormat: "dd MMM",
    tooltipFormat:
      "[font-size: var(--regular_font_size);bold]{dateX.formatDate('dd MMM YYYY')} [/font-size: var(--regular_font_size);]: {valueY.formatNumber('#,###.')}",
  },
  weekly: {
    dateFormat: "dd MMM",
    tooltipFormat:
      "[font-size: var(--regular_font_size);bold]w/c {dateX.formatDate('dd MMM YYYY')} [/font-size: var(--regular_font_size);]: {valueY.formatNumber('#,###.')}",
  },
  weekly_dfp: {
    dateFormat: "dd MMM",
    tooltipFormat:
      "[font-size: var(--regular_font_size);bold] {dateX.formatDate('MMM YYYY')} [/font-size: var(--regular_font_size);]: {valueY.formatNumber('#,###.')}",
  },
  monthly: {
    dateFormat: "MMM",
    tooltipFormat:
      "[font-size: var(--regular_font_size);bold]{dateX.formatDate('MMM YYYY')} [/font-size: var(--regular_font_size);]: {valueY.formatNumber('#,###.')}",
  },
};
export const CHART_TOOLTIP_FORMAT_NORMALIZED: { [index: string]: { dateFormat: string; tooltipFormat: string } } = {
  daily: {
    dateFormat: "dd MMM",
    tooltipFormat:
      "[font-size: var(--regular_font_size);bold]{dateX.formatDate('dd MMM YYYY')} [/font-size: var(--regular_font_size);]: {valueY.formatNumber('#.##')}",
  },
  weekly: {
    dateFormat: "dd MMM",
    tooltipFormat:
      "[font-size: var(--regular_font_size);bold]w/c {dateX.formatDate('dd MMM YYYY')} [/font-size: var(--regular_font_size);]: {valueY.formatNumber('#.##')}",
  },
  monthly: {
    dateFormat: "MMM",
    tooltipFormat:
      "[font-size: var(--regular_font_size);bold]{dateX.formatDate('MMM YYYY')} [/font-size: var(--regular_font_size);]: {valueY.formatNumber('#.##')}",
  },
};
