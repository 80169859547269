import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageName } from "../../../actions";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { VOC_BTN_FILTERS } from "../../../constants/vocConstants";
import { PageTitle } from "../../../components/PageContent/subs";
import VocQuickAfterSalesFilters from "../../../components/Filters/Voc/VocQuickAfterSalesFilters";
import "../../../assets/styles/pages/quickVocSales.scss";
import { useQuery } from "@tanstack/react-query";
import PerformanceByMarket from "../QuickVocSales/components/PerformanceByMarket";
import MarketDeepDive from "../QuickVocSales/components/MarketDeepDive";
import {
  getMarketDeepDiveTable,
  getMarketDeepDiveTrendline,
  getPerformanceByMarketMap,
  getQuickVocAfterSalesFilters,
  getQuickVocAfterSalesLastRefreshDate,
} from "../../../api/quickVocAfterSales";
import { DashboardBannerContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";

const QuickVocAfterSales = () => {
  const dispatch = useDispatch();

  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);

  useEffect(() => {
    setShowBanner(true);
    setBannerText(
      "Please note that figures presented in the dashboard are a sample of FY24 data. CEDAR and AMIEO are collaborating to make full figures and extended date ranges available shortly."
    );
    return () => {
      setShowBanner(false);
      setBannerText("");
    };
  }, []);

  const { data: lastDataRefreshData, refetch: refetchLastDataRefresh } = useQuery({
    queryKey: ["quickVocAfterSalesLastDataRefresh"],
    queryFn: getQuickVocAfterSalesLastRefreshDate,
    enabled: false,
  });

  useEffect(() => {
    dispatch(setPageName("Quick VOC After Sales"));
    refetchLastDataRefresh();
    eventTracking(MixpanelEvents.page_view, { dashboard: "VOC", page: "Quick VOC After Sales" });
  }, [dispatch]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={
          <VocQuickAfterSalesFilters filterBtns={VOC_BTN_FILTERS} queryFn={getQuickVocAfterSalesFilters} pageName="Quick VOC After Sales" />
        }
        dataDocumentation="voc"
        lastDataRefresh={lastDataRefreshData?.date ?? ""}
        pageTitle={<PageTitle dataDocumentation="voc" />}
      >
        <div className="quick-voc-sales">
          <PerformanceByMarket queryFn={getPerformanceByMarketMap} />
          <MarketDeepDive tableQueryFn={getMarketDeepDiveTable} trendlineQueryFn={getMarketDeepDiveTrendline} />
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
};

export default QuickVocAfterSales;
