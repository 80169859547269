import { matchSorter } from "match-sorter";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { closeFilterOptions } from "../subs/helpers";
import { MultiSelectDealerFilter } from "./MultiSelectDealerFilter";

interface DealerFilterProps {
  filterValue: string;
  list: string[];
  showFilterOptions: (evt: React.MouseEvent<HTMLElement>) => void;
  dealerParamValue: string;
  topFiveDealers?: string[];
  bottomFiveDealers?: string[];
  marketFilterValue: string;
  page: string;
}

const DealerFilter = (props: DealerFilterProps) => {
  const dispatch = useDispatch();
  const { page } = props;

  const { filterValue, list, showFilterOptions, topFiveDealers, bottomFiveDealers, dealerParamValue, marketFilterValue } = props;
  const [searchString, setSearchString] = useState<string>("");
  const [searchedDealersList, setSearchedDealersList] = useState(list);

  useEffect(() => {
    if (!filterValue?.includes("Top") && !filterValue?.includes("Bottom")) {
      dispatch(setSpecificParameter("bottom_five_dealers", false));
      dispatch(setSpecificParameter("top_five_dealers", false));
    }

    if (filterValue?.includes("Bottom")) {
      dispatch(setSpecificParameter("bottom_five_dealers", true));
      dispatch(setSpecificParameter("top_five_dealers", false));
    }

    if (filterValue?.includes("Top")) {
      dispatch(setSpecificParameter("top_five_dealers", true));
      dispatch(setSpecificParameter("bottom_five_dealers", false));
    }
  }, [filterValue]);

  const handleDealerMultiselectSubmit = useCallback(() => {
    const parameterName = "dealer";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    closeFilterOptions();

    const filterValue = list?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, { filter: "date_range", value: filterValue, page, dashboard: page });
  }, [page]);

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;

    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    closeFilterOptions();
    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const filteredValues = matchSorter(list, value, { threshold: matchSorter.rankings.CONTAINS });
    setSearchedDealersList(filteredValues);
    setSearchString(value);
  };

  return (
    <div className={"filter"} id="dealer_filter_div">
      <label className="filter_header" htmlFor="dealer_filter">
        DEALER
      </label>
      <div className="input_arrow" onClick={showFilterOptions} data-options-ul={"dealer_ul"}>
        <input readOnly type="text" name="dealer" id="dealer_filter" className={"filter_input"} data-test-id="dealer" value={filterValue} />
        <span className="arrow_down " />
      </div>
      <ul className="filter_options" id="dealer_ul" data-test-id="dealer_list">
        {list?.length > 1 ? (
          <Fragment>
            <div className="filter_search_bar" id="">
              <input value={searchString} placeholder="Search dealers" type="search" onChange={handleInputChange} />
            </div>
            {topFiveDealers && (
              <li data-filter={"dealer"} data-value={topFiveDealers} onClick={handleFilterOptionClick}>
                Top five dealers
              </li>
            )}
            {bottomFiveDealers && (
              <li className={"filter_divider"} data-filter={"dealer"} data-value={bottomFiveDealers} onClick={handleFilterOptionClick}>
                Bottom five dealers
              </li>
            )}

            <MultiSelectDealerFilter
              parentKey="dealer"
              // @ts-ignore
              filterList={list}
              filteredValues={searchedDealersList}
              parameterName={"dealer"}
              parameterValue={dealerParamValue.includes("All") ? list?.join(",") : dealerParamValue}
              onShowFilterOptions={showFilterOptions}
              handleFilterSubmission={handleDealerMultiselectSubmit}
              searchedValue={searchString}
            />
          </Fragment>
        ) : (
          <Fragment>
            <li className={"filter_divider"}>All</li>
            <li className={"error_msg"}>
              {marketFilterValue === "All" ? "Please select a market to see options" : "No dealers for the market selected"}
            </li>
          </Fragment>
        )}
      </ul>
    </div>
  );
};

export default DealerFilter;
