import { ErrorBoundary } from "@sentry/react";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getUserGroupDiagnosticToken } from "../../api/purchaseFunnelDiagnosticApi";
import { ErrorMsg } from "../../components/AppMessages";
import { FilterBtns } from "../../components/Filters/common";
import { FiltersLayout, IframeLayout } from "../../components/Layouts";
import { IframePageContent } from "../../components/PageContent";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";

export const PurchaseFunnelDiagnostic = withRouter((): JSX.Element => {
  const [iframeUrl, setIframeUrl] = useState("");

  //Fetch diagnostic token
  useEffect(() => {
    getUserGroupDiagnosticToken().then((token) => {
      const iframeUrl = token?.url || "https://diagnostics-funnel.nissan-cedar.com/";
      const retrievedToken = Cookies.get("dash_auth_token");
      if (retrievedToken === "") {
        Cookies.set("dash_auth_token", token?.dash_auth_token, { expires: 1, secure: true, sameSite: "strict" });
      }
      const urlWithToken = iframeUrl + "/set_token?dash_auth_token=" + token?.dash_auth_token;
      setIframeUrl(urlWithToken);
    });
  }, []);

  // Usage tracking
  useEffect(() => eventTracking(MixpanelEvents.page_view, { dashboard: "Purchase Funnel", page: "Purchase Funnel Diagnostic" }), []);

  return (
    <IframeLayout hasFilter={true}>
      <ErrorBoundary fallback={<ErrorMsg />}>
        <IframePageContent
          frameId="purchase_funnel_diagnostic"
          filter={
            <FiltersLayout extraClass={"iframeFilters"}>
              <FilterBtns
                id="purchase_funnel_btn"
                navigateTo="/purchase_funnel_report"
                name="Back to purchase funnel"
                withBackArrow={true}
              />
            </FiltersLayout>
          }
          iframeUrl={iframeUrl}
        />
      </ErrorBoundary>
    </IframeLayout>
  );
});
