import {
  SET_APP_RATINGS_FILTERS,
  SET_BRAND_PERFORMANCE_IMAGE_ATTRIBUTE_FILTERS,
  SET_BRAND_PERFORMANCE_OAO_FILTERS,
  SET_BRAND_PERFORMANCE_VOLUME_FILTERS,
  SET_CCS_FILTERS,
  SET_CPO_OPERATIONAL_FILTERS,
  SET_ECOMMERCE_FILTERS,
  SET_EUROPE_ACTIVATION_FILTERS,
  SET_EUROPE_ACTIVATION_MARKET_GROUPS,
  SET_EXECUTIVE_SUMMARY_FILTERS,
  SET_FILTERS,
  SET_FILTER_OPTIONS_LIST,
  SET_GEO_SCOPE,
  SET_JOURNEY_EXCLLENCE_FILTERS,
  SET_MIDAS_FILTERS,
  SET_MSRP_FILTERS,
  SET_MTM_MARKETS,
  SET_NEW_PURCHASE_FUNNEL_FILTERS,
  SET_OCE_ALL_MARKET_REGIONS,
  SET_OCE_ALL_REGIONS,
  SET_OCE_CHANNELS_BY_BRAND_FILTERS,
  SET_OCE_MARKETS_BY_BRAND_FILTERS,
  SET_OCE_MODEL_BY_BRAND_FILTERS,
  SET_PRODUCT_MARKETING_OPTIMIZATION_FILTERS,
  SET_PRODUCT_PERFORMANCE_FILTERS,
  SET_SEO_GEOGRAPHY,
  SET_TOP_FLOP_FILTERS,
  SET_UAE_ACTIVATION_FILTERS,
  SET_USER_GROUP_MARKETS,
  SET_USE_OWNERSHIP_FILTERS,
  SET_VOC_FILTERS,
} from "../actions/actionTypes";

const initialState = {
  data: [],
  filterOptions: {},
  cockpit_data: {},
  europe_activation_filters: {},
  app_ratings_filters: {},
  europe_activation_market_groups: [],
  user_group_markets: [],
  purchase_funnel_markets: [],
  seo_markets: [],
  mtm_markets: [],
  purchase_funnel_filters: [],
  top_flop_filters: [],
  product_marketing_optimization_filters: {},
  midas_filters: {},
  geo_scope: {},
  product_performance_filters: {},
  uae_activation_filters: {},
  voc_filters: {},
  cpo_operational_filters: {},
  ecommerce_filters: {},
  msrp_filters: {},
  journey_excellence_filters: {},
  use_ownership_filters: {},
  oce_all_market_regions: {},
  oce_all_regions: [],
  oce_model_by_brand_filters: {},
  oce_markets_by_brand_filters: {},
  oce_channels_by_brand_filters: {},
  brand_performance_volume_filters: {},
  brand_performance_oao_filters: {},
  brand_performance_image_attributes_filters: {},
  executive_summary_filters: {},
};

const filtersReducer = (
  state = initialState,
  action: {
    filter: string;
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_FILTERS:
      return { ...state, data: action.payload };

    case SET_GEO_SCOPE:
      return { ...state, geo_scope: action.payload };

    case SET_CCS_FILTERS:
      return { ...state, cockpit_data: action.payload };

    case SET_EUROPE_ACTIVATION_FILTERS:
      return { ...state, europe_activation_filters: action.payload };

    case SET_APP_RATINGS_FILTERS:
      return { ...state, app_ratings_filters: action.payload };

    case SET_FILTER_OPTIONS_LIST:
      return { ...state, filterOptions: { ...state.filterOptions, [action.filter]: action.payload } };

    case SET_EUROPE_ACTIVATION_MARKET_GROUPS:
      return { ...state, europe_activation_market_groups: action.payload };

    case SET_USER_GROUP_MARKETS:
      return { ...state, user_group_markets: action.payload };

    case SET_MTM_MARKETS:
      return { ...state, mtm_markets: action.payload };

    case SET_SEO_GEOGRAPHY:
      return { ...state, seo_markets: action.payload };

    case SET_NEW_PURCHASE_FUNNEL_FILTERS:
      return { ...state, purchase_funnel_filters: action.payload };

    case SET_TOP_FLOP_FILTERS:
      return { ...state, top_flop_filters: action.payload };

    case SET_PRODUCT_MARKETING_OPTIMIZATION_FILTERS:
      return { ...state, product_marketing_optimization_filters: action.payload };

    case SET_MIDAS_FILTERS:
      return { ...state, midas_filters: action.payload };

    case SET_PRODUCT_PERFORMANCE_FILTERS:
      return { ...state, product_performance_filters: action.payload };

    case SET_UAE_ACTIVATION_FILTERS:
      return { ...state, uae_activation_filters: action.payload };

    case SET_VOC_FILTERS:
      return { ...state, voc_filters: action.payload };

    case SET_CPO_OPERATIONAL_FILTERS:
      return { ...state, cpo_operational_filters: action.payload };

    case SET_ECOMMERCE_FILTERS:
      return { ...state, ecommerce_filters: action.payload };

    case SET_MSRP_FILTERS:
      return { ...state, msrp_filters: action.payload };

    case SET_JOURNEY_EXCLLENCE_FILTERS:
      return { ...state, journey_excellence_filters: action.payload };

    case SET_USE_OWNERSHIP_FILTERS:
      return { ...state, use_ownership_filters: action.payload };

    case SET_OCE_ALL_MARKET_REGIONS:
      return { ...state, oce_all_market_regions: action.payload };

    case SET_OCE_ALL_REGIONS:
      return { ...state, oce_all_regions: action.payload };

    case SET_OCE_MODEL_BY_BRAND_FILTERS:
      return { ...state, oce_model_by_brand_filters: action.payload };

    case SET_OCE_MARKETS_BY_BRAND_FILTERS:
      return { ...state, oce_markets_by_brand_filters: action.payload };

    case SET_OCE_CHANNELS_BY_BRAND_FILTERS:
      return { ...state, oce_channels_by_brand_filters: action.payload };

    case SET_BRAND_PERFORMANCE_VOLUME_FILTERS:
      return { ...state, brand_performance_volume_filters: action.payload };

    case SET_BRAND_PERFORMANCE_OAO_FILTERS:
      return { ...state, brand_performance_oao_filters: action.payload };

    case SET_BRAND_PERFORMANCE_IMAGE_ATTRIBUTE_FILTERS:
      return { ...state, brand_performance_image_attribute_filters: action.payload };

    case SET_EXECUTIVE_SUMMARY_FILTERS:
      return { ...state, executive_summary_filters: action.payload };

    default:
      return state;
  }
};

export default filtersReducer;
