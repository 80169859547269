import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { ALL_OPTION } from "../../../constants";
import { closeFilterOptions } from "../subs/helpers";

interface Props {
  onShowFilterOptions: any;
  colorChange: (data: any) => void;
  type: any[];
  value: any;
  id: string;
  series: any;
}

type checkboxState = { value: string; checked: boolean; indeterminate: boolean };
type filterValuesType = { value: string; checked: boolean };
let intitialState = {
  value: ALL_OPTION,
  checked: true,
  indeterminate: false,
};

export const RegionalColorFilter = (props: Props) => {
  const { onShowFilterOptions, colorChange, type, value, series } = props;

  const [colours, setColours] = useState<Array<checkboxState>>([intitialState]);
  const [filterValue, setFilterValue] = useState<string>();

  useEffect(() => {
    if (series?.length) {
      const colourList = series.map((e: any) => ({
        value: e.name,
        checked: !e.hide,
        indeterminate: e.hide,
      }));

      intitialState = {
        value: ALL_OPTION,
        checked: colourList.every((color: any) => color.checked),
        indeterminate: false,
      };

      colourList.unshift(intitialState);
      setColours(colourList);
    } else {
      setColours([intitialState]);
    }
  }, [series]);

  useEffect(() => {
    const selectedColors: Array<string> = [];
    colours.forEach((color) => color.checked && selectedColors.push(color.value));
    if (selectedColors.includes(ALL_OPTION)) {
      setFilterValue("All");
    } else if (selectedColors.length > 3) {
      setFilterValue(`${selectedColors.length} colors selected`);
    } else {
      setFilterValue(selectedColors.join(","));
    }
  }, [colours]);

  const handleCheckboxClick = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value: checkboxValue, checked: isChecked, dataset },
      } = evt;
      // setFilterValues([...filterValues, { value: checkboxValue, checked: isChecked }]);
      // If All is deselected, clear all selections
      if (checkboxValue === ALL_OPTION) {
        if (!isChecked) {
          resetSelection();
        } else {
          showAllOptions();
        }
        return;
      } else {
        let state = true;
        if (!isChecked) {
          state = false;
        }

        const colorUpdate = colours.map((e: any) => {
          if (e.value === checkboxValue) {
            return { ...e, checked: state, indeterminate: false };
          }
          return { ...e };
        });

        setColours(colorUpdate);
      }
    },

    [colours]
  );

  const handleOnlyBtnClick = useCallback(
    (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const value = dataset.value;
      const save: any = colours.filter((e: any) => e.value === value);

      const colorUpdate = colours.map((e: any) => {
        if (e.value === value) {
          return { ...e, checked: !save[0].checked, indeterminate: false };
        }
        return { ...e, checked: save[0].checked, indeterminate: false };
      });

      setColours(colorUpdate);
    },
    [colours]
  );

  const resetSelection = useCallback(() => {
    const updatedColours = colours.map((colour) => ({ ...colour, checked: false, indeterminate: false }));
    setColours(updatedColours);
  }, [colours]);

  const showAllOptions = useCallback(() => {
    setColours((prevRegion) => prevRegion.map((region) => ({ ...region, checked: true, indeterminate: false })));
  }, [colours]);

  const handleSubmission = useCallback(
    (evt) => {
      evt.preventDefault();

      const colorUpdate = colours.map((e: any) => {
        if (!e.checked) {
          return { ...e, indeterminate: true };
        }
        return { ...e };
      });

      setColours(colorUpdate);
      colorChange(colorUpdate);
      closeFilterOptions();
    },
    [colours]
  );

  const renderList = (list: Array<checkboxState>, colorType: string) =>
    list.map(({ value, checked, indeterminate }) => (
      <div className={"multiselect_container color_list"} id={`${props.id}`} key={`${props.id}${value}`}>
        <input
          type="checkbox"
          className={`multiselect-checkbox ${colorType}-checkbox ${colorType}_checkbox`}
          id={`${props.id}_${colorType}_${value}`}
          name={`${colorType}_${value}`}
          value={value}
          data-color-type={colorType}
          checked={checked}
          // ref={(input) => {
          //   if (input) {
          //     input.indeterminate = indeterminate;
          //   }
          // }}
          onChange={handleCheckboxClick}
        />
        <label className="multiselect-label color_label" htmlFor={`${props.id}_${colorType}_${value}`}>
          {value}
        </label>

        {!/All/g.test(value) && (
          <Box
            className="colorOptionBtn"
            data-color={colorType}
            data-value={value}
            sx={{ border: 1, textAlign: "center" }}
            onClick={handleOnlyBtnClick}
          >
            ONLY
          </Box>
        )}
      </div>
    ));

  return (
    <div className="filter" id={`${props.id}_colours_filter_div`}>
      <label className="filter_header" htmlFor={`colours_filter`}>
        {"SELECT COLORS"}
      </label>
      <div className="input_arrow" onClick={onShowFilterOptions} data-options-ul={`${props.id}_colour_selector`}>
        <input
          readOnly
          type="text"
          id={`${props.id}_colours_filter`}
          className={"filter_input"}
          data-test-id="colours"
          value={filterValue}
        />
        <span className="arrow_down " />
      </div>

      <div className="filter_options" id={`${props.id}_colour_selector`} data-test-id={`${props.id}_colour_selector`}>
        <div className="colours_options">
          {/* Regions */}
          <div className={`marketList`} id={`${props.id}_colour_list`} data-test-id={"colour_list"}>
            {renderList(colours, "colours")}
          </div>
        </div>
        {/* Apply btn */}
        <div className="filter_options__submit_area colour_options__submit_area">
          <Box className="filter_submit_btn submit_geo_filter" sx={{ border: 1, textAlign: "center" }} onClick={handleSubmission}>
            Apply
          </Box>
        </div>
      </div>
    </div>
  );
};
