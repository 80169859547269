import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getDmeoDigitalSpendTopTenCampaigns } from "../../../api/dmeoApi";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";

const data = [
  {
    campaign: "Camp 1",
    channel: "Display",
  },
  {
    campaign: "Camp 2",
    channel: "Paid search",
  },
  {
    campaign: "Camp 3",
    channel: "Social",
  },
  {
    campaign: "Camp 4",
    channel: "Programmatic",
  },
];

const TopTenCampaignsTile = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["digitalSpendTopTenCampaignsData"],
    queryFn: getDmeoDigitalSpendTopTenCampaigns,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile">
      <div className="tile-header">Top 10 Campaigns</div>
      <div className="tile-content">
        <table className="top-10-campain-table">
          <thead>
            <tr>
              <th>Campaign</th>
              <th>Channel</th>
              <th>Digital Spend</th>
              <th>CPC/CTR</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((dataItem: any) => (
              <tr key={dataItem.campaign_name}>
                <td>{dataItem.campaign_name}</td>
                <td>{dataItem.channel}</td>
                <td>${dataItem?.digital_spend?.toLocaleString()}</td>
                <td>${Number(dataItem?.cpc_cpr)?.toFixed(2)?.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <LoadingEllipsis isLoading={isFetching} />
    </div>
  );
});

export default TopTenCampaignsTile;
