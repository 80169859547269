import { DELETE_SPECIFIC_PARAMETER, SET_PARAMETERS, SET_SPECIFIC_PARAMETER } from "../actions/actionTypes";
import { DEFAULT_MTM_PARAMETERS } from "../constants";

const searchParam = new URLSearchParams(window.location.search);

const searchSpecificState = Object.fromEntries(searchParam.entries());

//TO DO: Refactor the parameters reducers to only use one parameter reducer for all dashboards. That is MTM, OCE, SEO and activation page.
//This only sets the parameters of the mtm activation page.

// Sets initial parameter state to the url parameters / default mtm parameters when no parameters have been defined
const initialState: { [index: string]: string } =
  Object.keys(searchSpecificState).length > 0 ? { ...DEFAULT_MTM_PARAMETERS, ...searchSpecificState } : { ...DEFAULT_MTM_PARAMETERS };

const mtmParametersReducer = (state = initialState, action: { payload: any; type: string; name: string }) => {
  switch (action.type) {
    case SET_PARAMETERS:
      return { ...state, ...action.payload };

    case SET_SPECIFIC_PARAMETER:
      return { ...state, [action.name]: action.payload };

    case DELETE_SPECIFIC_PARAMETER:
      const nextState = { ...state };
      delete nextState[action.name];
      return nextState;

    default:
      return state;
  }
};

export default mtmParametersReducer;
