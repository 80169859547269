import React from "react";
import DigitalMarketingSpendTiles from "../tiles/DigitalMarketingSpendTiles";
import CampaignPerformanceTiles from "../tiles/CampaignPerformanceTiles";

const MainDashboardContent = () => {
  return (
    <div className="dmeo-main-content">
      <DigitalMarketingSpendTiles />
      <CampaignPerformanceTiles />
    </div>
  );
};

export default MainDashboardContent;
