import { DateAxis, ILineSeriesDataFields, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, IComponentDataFields } from "@amcharts/amcharts4/core";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { DIGITAL_LEADS_SOURCE_COLORS } from "../../../constants";
import { ThemeContext } from "../../../context";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, legendConfiguration, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<any>;
  chartId: string;
  activationFormat: string;
  seriesList: any;
  isCumulative: { cumulative: boolean; startDate: string };
  theme: ThemeInt;
}

export class G8ActivationTrendLineChart extends Component<Props> {
  static contextType = ThemeContext;

  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.props.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.props.chartId);
  }

  initChart() {
    const { theme } = this.props;
    this.chart = create(this.props.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.colors.list = DIGITAL_LEADS_SOURCE_COLORS.map((chartColor) => color(chartColor));
    this.chart.data = this.props.data;
    this.chart.numberFormatter.numberFormat = "#a";

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    const screenWidth = window.matchMedia("(min-width: 1920px)");
    dateAxis.renderer.minGridDistance = screenWidth.matches ? 95 : 75;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter.numberFormat = "#.a";

    if (this.props.activationFormat === "rates") {
      valueAxis.renderer.labels.template.adapter.add("text", function (text) {
        return text + "%";
      });
    } else {
      valueAxis.maxPrecision = 0;
    }

    // Creating series
    this.createSeries(this.props.seriesList, this.props.activationFormat);

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.itemContainers.template.marginBottom = 15;
  }

  createSeries(SeriesList: { name: string; field: string }[], activationFormat: string) {
    const cumulativeDateString = this.props.isCumulative?.cumulative
      ? `${moment(this.props.isCumulative?.startDate, "MMMM YYYY").format("MMM YYYY")} to {dateX.formatDate('MMM YYYY')}`
      : `{dateX.formatDate('MMM YYYY')}`;

    SeriesList?.forEach((market, idx) => {
      const series = this.chart.series.push(new LineSeries());
      const bullet = lineSeriesConfiguration(series, {
        name: market.name,
        valueY: `${market.field}_${activationFormat == "rates" ? "rate" : "volume"}`,
      });
      series.propertyFields.strokeDasharray = "dash";

      series.dataFields = {
        ...series.dataFields,
        dateX: "date",
        servicesY: `${market.field}_volume`,
        warrantyY: `${market.field}_total_warranty_start`,
      } as ILineSeriesDataFields;

      idx >= 5 ? (series.hidden = true) : (series.hidden = false);

      //Bullet adapter
      bullet.adapter.add("tooltipText", (text, target) => {
        const data = target.dataItem?.dataContext as Record<string, number | string>;
        const dataFields = target.parent?.dataItem?.component?.dataFields as IComponentDataFields & {
          warrantyY: string;
          servicesY: string;
        };

        const warrantyKey: string = dataFields?.warrantyY;
        const servicesKey: string = dataFields?.servicesY;
        const cumulativeStartDateMatchesStartDate =
          moment(data?.date, "YYYY-MM-DD").format("MMMM YYYY") === this.props.isCumulative?.startDate;

        const warrantyValue = warrantyKey && data?.[warrantyKey] ? `{warrantyY.formatNumber('#,###')}` : "0";

        const activationRatio =
          data?.[servicesKey] && data?.[warrantyKey]
            ? Math.round(((data[servicesKey] as number) / (data[warrantyKey] as number)) * 100)
            : "n/a";

        const activationRatioText = this.props.activationFormat === "rates" ? `{valueY.formatNumber('#.')}` : `${activationRatio}`;

        return `[bold]{name} ${
          this.props.isCumulative?.cumulative && cumulativeStartDateMatchesStartDate
            ? `{dateX.formatDate('MMM YYYY')}`
            : cumulativeDateString
        }[/]
      Activation ratio: ${activationRatioText}%
      Services activated: {servicesY.formatNumber('#,###')}
      Total warranty start: ${warrantyValue}`;
      });
      series.hide();
    });
  }
  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} className={"graph"} data-test-id={this.props.chartId} />
      </div>
    );
  }
}
