import {
  FETCH_CCS_ANALYSIS_ENROLLMENT_RATE,
  FETCH_CCS_ANALYSIS_USER_ENGAGEMENT_RATE,
  SET_CCS_ANALYSIS_ENROLLMENT_RATE,
  SET_CCS_ANALYSIS_USER_ENGAGEMENT_RATE,
} from "./actionTypes/ccsAnalysisActionTypes";

export const fetchCcsAnalysisEnrollmentRateData = () => ({ type: FETCH_CCS_ANALYSIS_ENROLLMENT_RATE });
export const setCcsAnalysisEnrollmentRateData = (data: any) => ({ type: SET_CCS_ANALYSIS_ENROLLMENT_RATE, payload: data });

export const fetchCcsAnalysisUserEngagementRateData = (markets: string) => ({
  type: FETCH_CCS_ANALYSIS_USER_ENGAGEMENT_RATE,
  payload: markets,
});
export const setCcsAnalysisUserEngagementRateData = (data: any) => ({ type: SET_CCS_ANALYSIS_USER_ENGAGEMENT_RATE, payload: data });
