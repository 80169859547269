import { SvgIconProps } from "@mui/material";
import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";
import { getGranularityFromDate } from "../../../../utils/utilityFunctions";

interface Props {
  Icon: React.ComponentType<SvgIconProps>;
  title: string;
  titleTooltipText: string;
  mainValue: number;
  yoyValue: number;
  popValue: number;
  isLoading: boolean;
  isPercentage?: boolean;
}

const PoPLabelMapping: Record<string, string> = {
  monthly: "MoM",
  quarterly: "QoQ",
};

const MetricTileWithIcon = (props: Props) => {
  const { Icon, title, titleTooltipText, mainValue, yoyValue, popValue, isLoading, isPercentage } = props;
  const dateRangeParam = useSelector((state: RootStateOrAny) => state.parameters.date_range);

  const PoPLabel = useMemo(() => {
    const dateGranularity = getGranularityFromDate(dateRangeParam, "monthly");
    return PoPLabelMapping[dateGranularity];
  }, [dateRangeParam]);

  return (
    <div className="tile metric-tile">
      <div className="icon">{<Icon fontSize="large" />}</div>
      <div className="tile-content">
        <div className="title-container">
          <div className="title">{title}</div>
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={titleTooltipText}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>

        <div className="value">{mainValue ? `${mainValue?.toLocaleString()}${isPercentage ? "%" : ""}` : "n/a"}</div>

        <div className="variance">
          <div className="yoy-variance">
            <div>
              <Ratings value={yoyValue} isPercentageValue={false} testValue={false} />
            </div>
            {yoyValue ? `${yoyValue}% Yoy` : "n/a"}
          </div>
          {popValue && (
            <div className="pop-variance">
              <Ratings value={popValue} isPercentageValue={false} testValue={false} />
              {popValue}% {PoPLabel}
            </div>
          )}
        </div>
      </div>
      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};

export default MetricTileWithIcon;
