import { ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { create, options, Sprite } from "@amcharts/amcharts4/core";
import { endOfMonth, endOfQuarter } from "date-fns";
import Cookies from "js-cookie";
import _, { isNull } from "lodash";
import React, { Component, Dispatch, SetStateAction } from "react";
import WebFont from "webfontloader";
import { CYAN, DARK_ACCENT, DARK_PURPLE, LIGHT_CYAN, LIME_GREEN, PURPLE } from "../../../constants";
import { Annotation } from "../../../pages/Admin/types";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import ShareAnnotationModal from "../../Annotations/Dialogs/ShareAnnotationModal";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { createAnnotationTooltips } from "../helpers/annotationTooltips";
import {
  configureMTMColumnSeries,
  configureMTMDateAxis,
  configureMTMLineSeries,
  configureMTMValueAxis,
  formatDateAxisLabelToMonthly,
  formatDateAxisLabelToQuarterly,
  mtmChartConfiguration,
  mtmLegendConfiguration,
} from "../helpers/mtmConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  chartName: string;
  isLoading: boolean;

  firstActualField?: string;
  blueLegendName?: string;

  firstTargetField?: string;
  firstTargetFieldName?: string;

  firstForecastField?: string;
  firstForecastFieldName?: string;

  firstTooltipForecastField?: string;
  secondTooltipForecastField?: string;

  secondActualField?: string;
  pinkLegendName: string;

  secondTargetField?: string;
  secondForecastField?: string;
  secondForecastFieldName?: string;

  isQuarterly: boolean;
  theme: ThemeInt;
  isPercentageShare?: boolean;
  isRateChart?: boolean;
  thirdActualField?: string;
  thirdTargetField?: string;
  thirdTooltipForecastField?: string;

  lightCyanLegendName?: string;
  isJapan?: boolean;

  toggleAnnotationsDialog: () => void;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
  annotationMetric?: string;
  momLegendTooltipText: Record<string, string>;
  yoyLegendTooltipText: Record<string, string>;
}

interface State {
  shareModalOpen: boolean;
  annotations: Annotation[] | undefined;
}

export class MTMMultipleActualTargetForecastChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { shareModalOpen: false, annotations: undefined };

    this.tooltipConfig = this.tooltipConfig.bind(this);
    this.toogleShareModal = this.toogleShareModal.bind(this);
  }

  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  toogleShareModal() {
    this.setState({
      shareModalOpen: !this.state.shareModalOpen,
    });
  }

  handleShareAnnotation(annotations: Annotation[]) {
    this.setState({
      annotations: annotations,
    });
    this.toogleShareModal();
  }

  initChart() {
    const {
      data,
      chartName,
      firstActualField,
      blueLegendName,
      firstForecastField,
      firstForecastFieldName,
      firstTargetField,
      firstTargetFieldName,
      firstTooltipForecastField,
      secondTooltipForecastField,
      secondActualField,
      secondForecastFieldName,
      pinkLegendName,
      secondForecastField,
      secondTargetField,
      isPercentageShare,
      isQuarterly,
      isRateChart,
      theme,
      thirdActualField,
      thirdTargetField,
      thirdTooltipForecastField,
      lightCyanLegendName,
      isJapan,
      toggleAnnotationsDialog,
      setAnnotationStartDate,
      setAnnotationEndDate,
      setAnnotationDefaultMetric,
      annotationMetric,
      momLegendTooltipText,
      yoyLegendTooltipText,
    } = this.props;

    options.autoDispose = true;

    this.chart = create(chartName, XYChart);
    mtmChartConfiguration(this.chart);
    this.chart.data = data;

    //Add legends
    this.chart.legend = mtmLegendConfiguration("{date}", theme);
    this.chart.legend.labels.template.wrap = false;
    this.chart.legend.itemContainers.template.adapter.add("tooltipText", (text, target) => {
      // @ts-ignore
      const fieldName = target.dataItem?.dataContext?.dataFields?.valueY;
      const momText =
        // @ts-ignore
        target.dataItem?.name?.includes("MoM") || target.dataItem?.name?.includes("QoQ") ? momLegendTooltipText[fieldName] : "";
      // @ts-ignore
      const yoyText = target.dataItem?.name?.includes("YoY") ? yoyLegendTooltipText[fieldName] : "";
      if (!momText && !yoyText) {
        return;
      }

      return `${momText}
      ${yoyText}
      `;
    });

    //Default x axis to show current date
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureMTMDateAxis(dateAxis, theme, isQuarterly);

    const forecastDateAxis = this.chart.xAxes.push(new DateAxis());
    configureMTMDateAxis(forecastDateAxis, theme, isQuarterly);
    forecastDateAxis.renderer.labels.template.disabled = true;
    forecastDateAxis.syncWithAxis = dateAxis;

    //Y Axis that is used by default
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureMTMValueAxis(valueAxis, theme);
    if (isRateChart) valueAxis.renderer.labels.template.disabled = true;
    valueAxis.extraMax = 0.1;

    const rightValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureMTMValueAxis(rightValueAxis, theme);
    rightValueAxis.renderer.opposite = true;
    rightValueAxis.renderer.labels.template.adapter.add("text", (text) => {
      return text;
    });
    rightValueAxis.syncWithAxis = valueAxis;

    interface SeriesCreatorInt {
      field: string;
      color: string;
      isPercentageShare?: boolean;
      isRateChart?: boolean;
      legendName?: string;
      name?: string;
      yAxixsSetter?: boolean;
    }

    const createColumnSeries = ({
      field,
      tooltipForecast,
      color,
      legendName,
      yAxixsSetter = false,
    }: SeriesCreatorInt & { legendName: string; tooltipForecast: string }) => {
      const columnSeries = this.chart.series.push(new ColumnSeries());
      const userViewsList = Cookies.get("views");
      let yValue: ValueAxis;

      if (yAxixsSetter) {
        yValue = rightValueAxis;
      } else {
        yValue = valueAxis;
      }

      configureMTMColumnSeries(columnSeries, {
        name: legendName,
        yAxis: yValue,
        valueY: field,
        forecastAxis: tooltipForecast,
        color,
      });

      columnSeries.columns.template.adapter.add("tooltipText", this.tooltipConfig);

      if (userViewsList?.includes("annotations")) {
        columnSeries.columns.template.events.on("hit", (ev: any) => {
          const startDate = new Date(ev.target.dataItem.dateX);
          const endDate = isQuarterly ? endOfQuarter(startDate) : endOfMonth(startDate);
          setAnnotationStartDate(startDate);
          setAnnotationEndDate(endDate);
          setAnnotationDefaultMetric(`${annotationMetric ? annotationMetric : ""} ${legendName}`.trim());
          toggleAnnotationsDialog();
        });
      }

      const seriesAnnotationMetric =
        chartName == "closeRateChart"
          ? `Digitally influenced close rate ${legendName}`
          : chartName == "influencedSalesChart"
          ? `Sales ${legendName}`
          : legendName;
      let annotationBullet = createAnnotationTooltips.bind(this);
      annotationBullet = annotationBullet(columnSeries, "annotations", {
        metric: seriesAnnotationMetric,
      });
      // @ts-ignore
      annotationBullet.adapter.add("disabled", function (disabled: any, target: any) {
        if (!target.dataItem) {
          return disabled;
        }
        if (target.dataItem.dataContext?.annotations) {
          const itemAnnotations = target.dataItem.dataContext.annotations.filter((annotation: Annotation) =>
            annotation.metrics.flatMap((metric) => metric.metric).includes(seriesAnnotationMetric)
          );
          if (itemAnnotations.length > 0) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      });
      if (chartName == "influencedSalesChart") {
        //@ts-ignore
        annotationBullet.tooltip.pointerOrientation = "right";
      }
    };

    const createLineSeries = ({ field, name = "Target", color, isRateChart = false, isPercentageShare = false }: SeriesCreatorInt) => {
      const lineSeries = this.chart.series.push(new LineSeries());
      configureMTMLineSeries(lineSeries, {
        name: name,
        valueY: field,
        xAxis: dateAxis,
        color,
      });
    };

    if (blueLegendName && firstActualField) {
      createColumnSeries({
        field: firstActualField,
        tooltipForecast: firstTooltipForecastField as string,
        color: CYAN,
        legendName: blueLegendName,
        isRateChart,
      });
    }

    secondActualField &&
      (secondActualField == "total_private_sales_actual" || secondActualField == "total_private_sales_actual"
        ? createColumnSeries({
            field: secondActualField,
            tooltipForecast: secondTooltipForecastField as string,
            color: CYAN,
            legendName: pinkLegendName,
          })
        : createColumnSeries({
            field: secondActualField,
            tooltipForecast: secondTooltipForecastField as string,
            color: PURPLE,
            // yAxixsSetter: secondActualField == "sales_from_tracked_leads_actual" ? false : true,
            legendName: pinkLegendName,
          }));

    if (secondTargetField) {
      createLineSeries({
        field: secondTargetField,
        name: "Retail sales target",
        color: LIGHT_CYAN,
        isPercentageShare: true,
        isRateChart: false,
      });
    }

    if (firstTargetField) {
      createLineSeries({
        name: firstTargetFieldName,
        field: firstTargetField,
        color: theme === "light" ? DARK_ACCENT : PURPLE,
        isRateChart,
      });
    }

    const createDottedColumnSeries = ({
      field,
      color,
      name = "Forecast",
      tooltipForecast,
      yAxixsSetter,
    }: SeriesCreatorInt & { tooltipForecast: string }) => {
      const dottedColumnSeries = this.chart.series.push(new ColumnSeries());
      configureMTMColumnSeries(dottedColumnSeries, {
        name: name,
        xAxis: forecastDateAxis,
        valueY: field,
        forecastAxis: tooltipForecast,
        color,
        yAxis: yAxixsSetter ? rightValueAxis : valueAxis,
      });

      //if (firstForecastField != "temp_total_private_sales_forecast" )
      dottedColumnSeries.hiddenInLegend = true;
      dottedColumnSeries.fillOpacity = 0.0;
      dottedColumnSeries.strokeWidth = 2;
      dottedColumnSeries.strokeDasharray = "5,5";

      dottedColumnSeries.columns.template.adapter.add("tooltipText", this.tooltipConfig);
    };

    if (!isJapan) {
      if (firstForecastField) {
        createDottedColumnSeries({
          name: firstForecastFieldName,
          field: firstForecastField!,
          tooltipForecast: firstTooltipForecastField!,
          color: CYAN,
        });
      }
    }

    if (isJapan) {
      createColumnSeries({
        field: thirdActualField as string,
        color: LIME_GREEN,
        tooltipForecast: thirdTooltipForecastField as string,
        legendName: lightCyanLegendName as string,
      });

      if (thirdTargetField) {
        createLineSeries({
          field: thirdTargetField as string,
          color: LIME_GREEN,
          isPercentageShare: isPercentageShare,
          isRateChart,
        });
      }
    }

    if (!isJapan) {
      if (secondForecastField) {
        createDottedColumnSeries({
          field: secondForecastField!,
          tooltipForecast: secondTooltipForecastField!,
          color: DARK_PURPLE,
          isPercentageShare: isPercentageShare,
          isRateChart,
          yAxixsSetter: true,
          name: secondForecastFieldName ? secondForecastFieldName : "Forecast",
        });
      }
    }

    // Configures chart tooltip to show the value of each series
    this.chart.series.each((series) => {
      series.bullets.getIndex(0)?.adapter.add("tooltipText", this.tooltipConfig);
    });

    this.chart.bottomAxesContainer.layout = "vertical";
  }

  tooltipConfig(value: string | undefined, target: Sprite) {
    const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

    let text = `[bold]${
      this.props.isQuarterly ? formatDateAxisLabelToQuarterly(dateValue?.date) : formatDateAxisLabelToMonthly(dateValue?.date)
    }[/]\n`;

    this.chart.series.each((item) => {
      if (!item.isHidden) {
        const value =
          item?.dataFields?.valueY && dateValue
            ? item.name === "Forecast"
              ? // @ts-ignore
                (dateValue[item.dataFields.forecastY] as number)
              : (dateValue[item.dataFields.valueY] as number)
            : null;

        const fieldName = item.name.includes("MoM")
          ? item.name.substring(0, item.name.indexOf("MoM"))
          : item.name.includes("QoQ")
          ? item.name.substring(0, item.name.indexOf("QoQ"))
          : item.name.includes("YoY")
          ? item.name.substring(0, item.name.indexOf("YoY"))
          : item.name;

        const itemText = fieldName.replace(/(\r\n|\n|\r)/gm, "").includes("+")
          ? fieldName.replace(/\+.*/, "").replace(/(\r\n|\n|\r)/gm, "")
          : fieldName.replace(/-.*/, "").replace(/(\r\n|\n|\r)/gm, "");

        text += `[${item.stroke}]●[/] ${itemText}: ${
          isNull(value)
            ? "n/a"
            : item.dataFields.valueY?.includes("share") || item.dataFields.valueY?.includes("rate")
            ? `${(value as number).toFixed(2)}%`
            : formatToThreeDigits(value as number)
        }\n`;
      }
    });
    return text;
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
        <ShareAnnotationModal
          open={this.state.shareModalOpen}
          handleToogleDialog={this.toogleShareModal}
          annotations={this.state.annotations}
        />
      </div>
    );
  }
}
