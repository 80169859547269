// Todo: Move some effects to sagas instead of useEffect
import React from "react";
import { withRouter } from "react-router-dom";
import "../../assets/styles/component/filters.scss";
import { FiltersLayout } from "../Layouts";
import { FilterBtns } from "./common";

const filterBtns = [
  { id: "ccs_cockpit", navigateTo: "/ccs_vital_signs/ccs_cockpit", name: "See ccs cockpit" },
  { id: "ccs_journey", navigateTo: "/ccs_vital_signs/ccs_journey", name: "See ccs journey" },
  { id: "app_review", navigateTo: "/ccs_vital_signs/app_ratings", name: "See app ratings" },
];

export const CcsPDCAFilters = withRouter(() => {
  return (
    <FiltersLayout extraClass={"iframeFilters"}>
      <>
        {filterBtns.map((btn) => (
          <FilterBtns id={btn.id} navigateTo={btn.navigateTo} name={btn.name} key={btn.id} />
        ))}
      </>
    </FiltersLayout>
  );
});
