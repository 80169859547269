import { registry } from "@amcharts/amcharts4/core";

export const disposeChart = (chartId: string) => {
  const myPromise = new Promise((resolve) => {
    const myChart = registry.baseSprites.filter((item) => {
      return item.parent?.htmlContainer?.id === chartId;
    })?.[0];

    resolve(myChart?.dispose());
  });

  return myPromise;
};
