import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

export const PurchaseFunnelData = (): JSX.Element => {
  const parameters = useSelector((state: RootStateOrAny) => state.purchase_funnel_parameters);

  return (
    <div className="data_documentation">
      <h3>DIEGITALLY INFLUENCED SALES - DATA OVERVIEW</h3>

      <h4>KPI definitions and sources</h4>
      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>Element</th>
            <th>Definition</th>
            <th>Provided by</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>% Digitally influenced sales</td>
            <td>Digitally influenced sales / Total private sales</td>
            <td>Calculated</td>
          </tr>
          <tr>
            <td>Digitally influenced sales (DIS)</td>
            <td>Number of confirmed new car sales that can be attributed to OEM managed digital activity</td>
            <td>Market</td>
          </tr>
          <tr>
            <td>Non-digitally influenced sales</td>
            <td>Sales tracked by market - Digitally influenced sales</td>
            <td>Calculated</td>
          </tr>
          <tr>
            <td>Untracked private sales</td>
            <td>Total private sales - Sales tracked by market</td>
            <td>Calculated</td>
          </tr>
          <tr>
            <td>Total private sales</td>
            <td>Digitally influenced sales + Others (dealer walk ins + other)</td>
            <td>Global Sales Team</td>
          </tr>
          <tr>
            <td>Digitally influenced close rate</td>
            <td>Digitally influenced sales / Digital leads</td>
            <td>Calculated</td>
          </tr>
          <tr>
            <td>Leads</td>
            <td>Visitors who express interest (lead-type actions) and leave PII data</td>
            <td>Market</td>
          </tr>
        </tbody>
      </table>

      <h4>Digital Lead Sources definition</h4>
      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>
              Digital Lead Channel
            </th>
            <th>
              Definition
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Brand Website
            </td>
            <td>
              Visitors to the brand website who submit a lead form, tracked by PACE.
            </td>
          </tr>
          <tr>
            <td>
              Dealer Website
            </td>
            <td>
              Visitors to dealer websites, controlled / influenced by Nissan, who perform a lead-type action.
            </td>
          </tr>
          <tr>
            <td>
              Ecommerce
            </td>
            <td>
              Leads and sales generated through partnership with the e-commerce sites.
            </td>
          </tr>
          <tr>
            <td>
              3rd Party
            </td>
            <td>
              Leads from independent websites purchased by Nissan and distributed to Dealers.
            </td>
          </tr>
          <tr>
            <td>
              Social Media
            </td>
            <td>
              Visitors who arrive via social media and submit a lead form.
            </td>
          </tr>
          <tr>
            <td>
              OEM Online Ads
            </td>
            <td>
              Visitors who arrive via OEM online ads and submit a lead form.
            </td>
          </tr>
          <tr>
            <td>
              Customer Database
            </td>
            <td>
              Visitors  from customer database based on email subscriptions for Japan. It is used for official DIS reporting.
            </td>
          </tr>
          <tr>
            <td>
              Tracked Leads
            </td>
            <td>
              Sales Navi data for Japan and it is used for internal reference only.
            </td>
          </tr>
          <tr>
            <td>
              NRFM
            </td>
            <td>
              Leads generated through NR Finance Mexico (NRFM).
            </td>
          </tr>
          <tr>
            <td>
              Unsourced Leads
            </td>
            <td>
              Leads from unidentified sources.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
