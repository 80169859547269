import { all, put, retry, takeLatest } from "redux-saga/effects";
import {
  setCpoBreakdownTrendAll,
  setCpoKbaBreakdownTableData,
  setCpoKbaLabelMapping,
  setCpoKbaLeadKeys,
  setCpoKbaVisitsData,
  setCpoKeyIndicatorAll,
  setCpoKeyIndicatorVariance,
  setCpoLeadBreakdownTableData,
  setCpoLeadLabelMapping,
  setCpoLeadVisitsData,
  setCpoVisitsGraphData,
  setCpoVisitsLeadsKbasGraphData_Daily,
  setCpoVisitsLeadsKbasGraphData_Monthly,
  setCpoVisitsLeadsKbasGraphData_Weekly,
  setCpoVisitsTrendLeadsKbas,
  setModelKeyIndicatorsData,
  setModelTrendData,
} from "../actions";
import {
  FETCH_CPO_BREAKDOWN_TREND,
  FETCH_CPO_BREAKDOWN_TREND_ALL,
  FETCH_CPO_KBA_BREAKDOWN_TABLE_DATA,
  FETCH_CPO_KBA_LABEL_MAPPING,
  FETCH_CPO_KBA_LEAD_VISITS_TREND_DATA,
  FETCH_CPO_KBA_VISITS_TREND_DATA,
  FETCH_CPO_LEAD_BREAKDOWN_TABLE_DATA,
  FETCH_CPO_LEAD_LABEL_MAPPING,
  FETCH_CPO_LEAD_VISITS_TREND_DATA,
  FETCH_KEY_INDICATOR_ALL,
  FETCH_KEY_INDICATOR_VARIANCE,
  FETCH_MODEL_KEY_INDICATORS,
  FETCH_MODEL_TREND,
} from "../actions/actionTypes";
import { setLoading } from "../actions/loadingActions";
import {
  getCpoBreakdownAll,
  getCpoBreakdownTrends,
  getCpoKbaBreakdownGraph,
  getCpoKbaBreakdownTable,
  getCpoKbaLabelMapping,
  getCpoKbaLeadBreakdownAll,
  getCpoKeyIndicatorAll,
  getCpoKeyIndicatorVariance,
  getCpoLeadBreakdownGraph,
  getCpoLeadBreakdownTable,
  getCpoLeadBreakdownTrends,
  getCpoLeadLabelMapping,
  getCpoModelKeyIndicators,
  getCpoModelTrend,
} from "../api";
import { secondsDelay } from "../api/utils";
import { ICPOBreakdownAll, IKeyIndicatorAll, IKeyIndicatorVariance } from "../constants/interface";

// Watcher Sagas
export function* watchFetchModelTrend() {
  yield takeLatest(FETCH_MODEL_TREND, fetchModelTrendSaga);
}

export function* watchFetchModelKeyIndiators() {
  yield takeLatest(FETCH_MODEL_KEY_INDICATORS, fetchModelKeyIndicatorsSaga);
}

export function* watchFetchKeyIndicatorVariance() {
  yield takeLatest(FETCH_KEY_INDICATOR_VARIANCE, fetchKeyIndicatorVariance);
}

export function* watchFetchKeyIndicatorAll() {
  yield takeLatest(FETCH_KEY_INDICATOR_ALL, fetchKeyIndicatorAll);
}

export function* watchFetchCpoBreakdownTrend() {
  yield takeLatest(FETCH_CPO_BREAKDOWN_TREND, fetchCpoBreakdownTrend);
}

export function* watchFetchCpoBreakdownTrendAll() {
  yield takeLatest(FETCH_CPO_BREAKDOWN_TREND_ALL, fetchCpoBreakdownTrendAll);
}

export function* watchFetchCpoVisitsTrendLeads() {
  yield takeLatest(FETCH_CPO_KBA_LEAD_VISITS_TREND_DATA, fetchCpoVisitsTrendKbaLeadsAll);
}

export function* watchFetchCpoVisitsKba() {
  yield takeLatest(FETCH_CPO_KBA_VISITS_TREND_DATA, fetchCpoVisitsTrendKba);
}

export function* watchFetchCpoVisitsLeads() {
  yield takeLatest(FETCH_CPO_LEAD_VISITS_TREND_DATA, fetchCpoVisitsTrendLead);
}

export function* watchFetchCpoKbaBreakdownTable() {
  yield takeLatest(FETCH_CPO_KBA_BREAKDOWN_TABLE_DATA, fetchCpoKbaBreakdownTableSaga);
}

export function* watchFetchCpoLeadBreakdownTable() {
  yield takeLatest(FETCH_CPO_LEAD_BREAKDOWN_TABLE_DATA, fetchCpoLeadBreakdownTableSaga);
}

export function* watchFetchCpoKbaLabelMapping() {
  yield takeLatest(FETCH_CPO_KBA_LABEL_MAPPING, fetchCpoKbaLabelMappingSaga);
}

export function* watchFetchCpoLeadLabelMapping() {
  yield takeLatest(FETCH_CPO_LEAD_LABEL_MAPPING, fetchCpoLeadLabelMappingSaga);
}

// Worker Sagas

export function* fetchModelTrendSaga() {
  try {
    yield put(setLoading("full_figures_graph", true));

    const response: Record<string, any> = yield retry(4, secondsDelay, getCpoModelTrend);
    if (!("error" in response)) {
      yield all([put(setModelTrendData(response)), put(setLoading("full_figures_graph", false))]);
    }
  } catch (err) {
    console.log("Failed to get /certified-pre-owned/get-model-detail-visits-graph");
  }
}

export function* fetchModelKeyIndicatorsSaga() {
  try {
    yield put(setLoading("model_key_indicator", true));

    const response: Record<string, any> = yield retry(4, secondsDelay, getCpoModelKeyIndicators);

    if (!("error" in response)) {
      yield all([put(setModelKeyIndicatorsData(response)), put(setLoading("model_key_indicator", false))]);
    }
  } catch (err) {
    console.log("Failed to get /certified-pre-owned/get-model-detail-visits-key-indicators");
  }
}

export function* fetchKeyIndicatorVariance() {
  try {
    yield put(setLoading("full_figures_graph", true));

    const response: Array<IKeyIndicatorVariance> = yield retry(4, secondsDelay, getCpoKeyIndicatorVariance);

    if (!("error" in response)) {
      yield all([put(setCpoKeyIndicatorVariance(response)), put(setLoading("full_figures_graph", false))]);
    }
  } catch (err) {
    console.log("Failed to get /cpo/variance");
  }
}

export function* fetchKeyIndicatorAll() {
  try {
    yield put(setLoading("key_indicator", true));
    const searchParams = new URLSearchParams(window.location.search);

    const response: IKeyIndicatorAll = yield retry(4, secondsDelay, getCpoKeyIndicatorAll);

    if (!("error" in response)) {
      yield all([put(setCpoKeyIndicatorAll(response)), put(setLoading("key_indicator", false))]);
    }
  } catch (err) {
    console.log("Failed to get /certified-pre-owned/get-key-indicators");
  }
}
// @ts-ignore
export function* fetchCpoBreakdownTrend() {
  yield put(setLoading("visits_breakdown_graph", true));
  const searchParams = new URLSearchParams(window.location.search);
  const response: Record<string, any> = yield retry(4, secondsDelay, getCpoBreakdownTrends);

  if (!("error" in response)) {
    yield all([put(setCpoVisitsGraphData(response)), put(setLoading("visits_breakdown_graph", false))]);
  }
}

export function* fetchCpoBreakdownTrendAll() {
  try {
    yield put(setLoading("visits_breakdown_table", true));
    const searchParams = new URLSearchParams(window.location.search);

    const response: ICPOBreakdownAll = yield retry(4, secondsDelay, getCpoBreakdownAll);

    if (!("error" in response)) {
      yield all([put(setCpoBreakdownTrendAll(response)), put(setLoading("visits_breakdown_table", false))]);
    }
  } catch (err) {
    console.log("Failed to get /cpo/breakdown/all");
  }
}

// @ts-ignore
export function* fetchCpoVisitsTrendKbaLeadsAll(action) {
  yield put(setLoading("kba_trend_table", true));
  yield put(setLoading("lead_trend_table", true));

  const searchParams = window.location.search;
  const response: Array<any> = yield retry(4, secondsDelay, getCpoKbaLeadBreakdownAll, searchParams);

  if (!("error" in response)) {
    const keys = Object.keys(response[0]);
    const indexOfYoY = keys.indexOf("year_on_year");
    keys.splice(indexOfYoY);
    const names = keys.map((key) => {
      const arr = key.split("_");
      arr.shift();
      return {
        key: key,
        name: arr.join(" ").charAt(0).toUpperCase() + arr.join(" ").slice(1),
      };
    });
    yield put(setCpoKbaLeadKeys(names));

    const data = names.map((name: { key: string; name: string }) => {
      return {
        key: name.key,
        name: name.name,
        current_data: {
          key: name.key,
          visits: response[0][name.key],
        },
        pop_data: {
          key: name.key,
          visits: response[0].period_on_period ? response[0].period_on_period[name.key] : null,
        },
        pop_data_difference: {
          key: name.key,
          visits: response[0].period_on_period_difference ? response[0].period_on_period_difference[name.key] : null,
        },
        yoy_data: {
          key: name.key,
          visits: response[0].year_on_year ? response[0].year_on_year[name.key] : null,
        },
        yoy_data_difference: {
          key: name.key,
          visits: response[0].year_on_year_difference ? response[0].year_on_year_difference[name.key] : null,
        },
      };
    });
    yield put(setCpoVisitsTrendLeadsKbas(data));
    yield put(setLoading("kba_trend_table", false));
    yield put(setLoading("lead_trend_table", false));
  }
}

// @ts-ignore
export function* fetchCpoVisitsTrendKba() {
  try {
    yield put(setLoading("kba_breakdown_graph", true));

    const response: Array<any> = yield retry(4, secondsDelay, getCpoKbaBreakdownGraph);

    if (!("error" in response)) {
      yield all([put(setCpoKbaVisitsData(response)), put(setLoading("kba_breakdown_graph", false))]);
    }
  } catch (err) {
    console.log("Failed to get /certified-pre-owned/get-kba-breakdown-graph");
  }
}

// @ts-ignore
export function* fetchCpoVisitsTrendLead() {
  try {
    yield put(setLoading("lead_breakdown_graph", true));

    const response: Array<any> = yield retry(4, secondsDelay, getCpoLeadBreakdownGraph);

    if (!("error" in response)) {
      yield all([put(setCpoLeadVisitsData(response)), put(setLoading("lead_breakdown_graph", false))]);
    }
  } catch (err) {
    console.log("Failed to get /certified-pre-owned/get-kba-breakdown-graph");
  }
}

// @ts-ignore
export function* fetchCpoVisitsTrendLeadsTrends() {
  yield put(setLoading("lead_trend_graph", true));
  yield put(setLoading("kba_trend_graph", false));

  const searchParams = window.location.search;
  const response: { daily: any[]; weekly: any[]; monthly: any[] } = yield retry(4, secondsDelay, getCpoLeadBreakdownTrends, searchParams);

  if (!("error" in response)) {
    const { daily, weekly, monthly } = response;

    yield all([
      put(setCpoVisitsLeadsKbasGraphData_Daily(daily)),
      put(setCpoVisitsLeadsKbasGraphData_Weekly(weekly)),
      put(setCpoVisitsLeadsKbasGraphData_Monthly(monthly)),
      put(setLoading("lead_trend_graph", false)),
      put(setLoading("kba_trend_graph", false)),
    ]);
  }
}

export function* fetchCpoKbaBreakdownTableSaga() {
  try {
    yield put(setLoading("kba_breakdown_table", true));

    const response: Array<any> = yield retry(4, secondsDelay, getCpoKbaBreakdownTable);

    if (!("error" in response)) {
      yield all([put(setCpoKbaBreakdownTableData(response)), put(setLoading("kba_breakdown_table", false))]);
    }
  } catch (err) {
    console.log("Failed to get /certified-pre-owned/get-kba-breakdown-table");
  }
}

export function* fetchCpoLeadBreakdownTableSaga() {
  try {
    yield put(setLoading("lead_breakdown_table", true));

    const response: Array<any> = yield retry(4, secondsDelay, getCpoLeadBreakdownTable);

    if (!("error" in response)) {
      yield all([put(setCpoLeadBreakdownTableData(response)), put(setLoading("lead_breakdown_table", false))]);
    }
  } catch (err) {
    console.log("Failed to get /certified-pre-owned/get-kba-breakdown-table");
  }
}

export function* fetchCpoKbaLabelMappingSaga() {
  try {
    yield put(setLoading("kba_label_mapping", true));

    const response: Array<any> = yield retry(4, secondsDelay, getCpoKbaLabelMapping);

    if (!("error" in response)) {
      yield all([put(setCpoKbaLabelMapping(response)), put(setLoading("kba_label_mapping", false))]);
    }
  } catch (err) {
    console.log("Failed to get /certified-pre-owned/get-kba-label-mapping");
  }
}

export function* fetchCpoLeadLabelMappingSaga() {
  try {
    yield put(setLoading("lead_label_mapping", true));

    const response: Array<any> = yield retry(4, secondsDelay, getCpoLeadLabelMapping);

    if (!("error" in response)) {
      yield all([put(setCpoLeadLabelMapping(response)), put(setLoading("lead_label_mapping", false))]);
    }
  } catch (err) {
    console.log("Failed to get /certified-pre-owned/get-lead-label-mapping");
  }
}
