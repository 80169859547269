import { CategoryAxis, ColumnSeries, LabelBullet, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _, { isNull } from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, PMO_CHART_COLORS, WHITE } from "../../../constants";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { columnSeriesConfiguration, configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  theme: ThemeInt;
  isLoading: boolean;
  seriesList: any;
  chartName: string;
  categories: string[];
  maxAmount: number;
  isQ1?: boolean;
  minAmount: number;
  hideAxis?: boolean;
}

interface ISeries {
  name: string;
  field: string;
  series: any;
  index: number;
}

class MediaMixBarChart extends Component<Props> {
  chartId = this.props.chartName;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { theme, seriesList, data, categories, maxAmount, minAmount, hideAxis } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.colors.list = PMO_CHART_COLORS.map((chartColor) => color(chartColor));
    this.chart.data = data;
    this.chart.maskBullets = false;
    this.chart.paddingTop = 35;

    // Create data chart axes

    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "yr_scnr_rv";
    categoryAxis.renderer.cellStartLocation = 0;
    categoryAxis.renderer.cellEndLocation = 1;
    categoryAxis.renderer.minGridDistance = 2;
    categoryAxis.renderer.labels.template.fontSize = "12px";

    const categoryAxis1 = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis1, theme);
    categoryAxis1.dataFields.category = "yr_scnr_rv";
    categoryAxis1.renderer.cellStartLocation = 0;
    categoryAxis1.renderer.cellEndLocation = 1;
    categoryAxis1.renderer.minGridDistance = 2;
    categoryAxis1.renderer.labels.template.disabled = true;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter.numberFormat = "#a";
    valueAxis.renderer.labels.template.fontSize = "12px";
    valueAxis.extraMax = 0.2;
    valueAxis.renderer.minGridDistance = 80;
    valueAxis.min = minAmount;
    valueAxis.max = maxAmount;
    valueAxis.renderer.labels.template.disabled = hideAxis ? true : false;

    seriesList?.forEach((series: { name: string; volumeField: string; seriesColor: string }) => {
      this.createSeries(`${series?.name}_amount`, series?.name, series?.seriesColor);
    });

    const totalsSeries = this.chart.series.push(new ColumnSeries());
    totalsSeries.dataFields.categoryX = "yr_scnr_rv";
    totalsSeries.xAxis = categoryAxis1;
    totalsSeries.dataFields.valueY = "yr_scnr_rv_total";
    totalsSeries.name = "Total";
    totalsSeries.stacked = true;
    totalsSeries.strokeWidth = 0;
    totalsSeries.strokeOpacity = 0;
    totalsSeries.fillOpacity = 0;
    totalsSeries.fill = color(BLACK);
    totalsSeries.columns.template.width = percent(65);
    const totalBullet = totalsSeries.bullets.push(new LabelBullet());
    totalBullet.label.text = `{yr_scnr_rv_total.formatNumber("#.0a")}`;
    totalBullet.label.fill = color(this.props.theme === "light" ? BLACK : WHITE);
    totalBullet.locationY = -0.07;
    totalBullet.label.fontSize = "12px";

    totalsSeries.columns.template.events.on("sizechanged", function (ev) {
      //    @ts-ignore
      if (ev.target?.dataItem && ev.target?.dataItem?.bullets) {
        const height = ev.target.pixelHeight;

        //    @ts-ignore
        ev.target?.dataItem?.bullets.each(function (id, bullet) {
          if (bullet?.label?.currentText !== undefined && bullet?.label?.currentText !== "0.0") {
            if (height > 15) {
              bullet.show();
            } else {
              bullet.hide();
            }
          } else {
            bullet.hide();
          }
        });
      }
    });
  }

  createSeries(field: string, name: string, seriesColor: string) {
    const series = this.chart.series.push(new ColumnSeries());
    columnSeriesConfiguration(series, {
      valueY: field,
      name: name,
    });

    series.dataFields.categoryX = "yr_scnr_rv";
    series.dataFields.valueX = "yr_scnr_rv_total";
    series.sequencedInterpolation = true;
    series.stacked = true;
    series.fill = color(seriesColor);
    series.stroke = color(seriesColor);
    series.zIndex = 20;

    if (this.props.isQ1) {
      series.columns.template.width = percent(40);
    } else {
      series.columns.template.width = percent(65);
    }

    const labelBullet = series.bullets.push(new LabelBullet());
    labelBullet.label.text = `{valueY.formatNumber("#.0a")}`;
    labelBullet.label.fill = color(this.props.theme === "light" ? BLACK : WHITE);
    labelBullet.locationY = 0.5;
    labelBullet.label.fontSize = "12px";

    series.columns.template.tooltipText = "{name}: {valueY.formatNumber('#.0a')}";
    //@ts-ignore
    series.tooltip.getFillFromObject = false;
    //@ts-ignore
    series.tooltip.autoTextColor = false;
    //@ts-ignore
    series.tooltip.background.fill = color(WHITE);
    //@ts-ignore
    series.tooltip.label.fill = color(BLACK);

    series.columns.template.adapter.add("tooltipText", (value: any, target: any) => {
      const dateValue = target?.tooltipDataItem?.dataContext as { yr_scnr_rv: string; [index: string]: string | number };
      const dateVal = `[bold font-size: var(--regular_font_size)]${dateValue?.yr_scnr_rv}[/]\n`;
      let text = "";
      this.chart.series.each((item) => {
        if (!item.isHidden) {
          const value = item.dataFields.valueY && dateValue ? (dateValue[item.dataFields.valueY] as number) : null;
          text =
            `[font-size: var(--regular_font_size) ${item.stroke}]●[/] [font-size: var(--regular_font_size)]${item.name.replace(
              /_/g,
              " "
            )}: [font-size: var(--regular_font_size)]${isNull(value) || isNaN(value) ? "n/a" : formatToThreeDigits(value as number, 1)}\n` +
            text;
        }
      });
      return dateVal + text;
    });

    series.columns.template.events.on("sizechanged", function (ev) {
      //    @ts-ignore
      if (ev.target?.dataItem && ev.target?.dataItem?.bullets) {
        const height = ev.target.pixelHeight;

        //    @ts-ignore
        ev.target?.dataItem?.bullets.each(function (id, bullet) {
          if (bullet?.label?.currentText !== undefined && bullet?.label?.currentText !== "0.0") {
            if (height > 15) {
              bullet.show();
            } else {
              bullet.hide();
            }
          } else {
            bullet.hide();
          }
        });
      }
    });
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default MediaMixBarChart;
