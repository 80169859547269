import { isNull } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { NoDataMsg } from "../../../components/AppMessages";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../components/Ratings/Ratings";
import { tooltipData } from "../../../constants/dfpConstants";
import { convertToAbsolutePts, toLocaleInteger } from "../../../utils/utilityFunctions";

interface Props {
  title: string;
  chart: JSX.Element;
  totalValue: number | null;
  comparisonValue: number | null;
  data: any;
  ToggleSwitch?: JSX.Element;
  loading: boolean;
  ratio?: boolean;
  showTotals?: boolean;
}

const MetricComparisonsGraph = (props: Props) => {
  const { title, chart, ratio, totalValue, comparisonValue, ToggleSwitch, data, loading, showTotals } = props;
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const ref = useRef<any>(null);

  const tooltipDefinitionData = useMemo(
    () => tooltipData[title === "Website visits" && !showTotals ? "Visits by channel" : title],
    [title, showTotals]
  );

  const { model, market } = useSelector((state: RootStateOrAny) => state.parameters);

  const kbas = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.totals_visits?.kbas ?? []);
  const leads = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.totals_visits?.leads ?? []);
  const [availableMetric, setAvailableMetric] = useState<Array<any>>([]);
  const [metric, setMetric] = useState<string>("");

  useEffect(() => {
    if (title == "KBA visits") {
      setMetric("kbas");
    } else if (title == "Lead visits") {
      setMetric("leads");
    } else {
      setMetric("");
    }

    if (kbas?.length > 0 && title == "KBA visits") setAvailableMetric(kbas);

    if (leads?.length > 0 && title == "Lead visits") setAvailableMetric(leads);
  }, [kbas, leads, title, market, model]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (tooltipVisible && ref.current != null && !ref.current.contains(e.target)) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [tooltipVisible]);

  return (
    <div className="metric_graph primary_background">
      <div className="dfp_tile_header">
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div className="title">
            <h3>{title}</h3>
            <Tooltip
              position="left"
              className="customer_intention_tooltip"
              html={
                <div ref={ref}>
                  {tooltipDefinitionData?.definitions?.map((val: { name: string; definition: string }) => {
                    return (
                      <>
                        <p key={val?.name}>
                          <span style={{ fontWeight: "bold" }}>{val?.name}</span> {val?.definition}
                        </p>
                        {metric?.length ? (
                          <div>
                            <p>{`The ${
                              metric === "kbas" ? ` Key Buying Actions` : `Digital lead`
                            } types taken into account for this geography are: `}</p>
                            {availableMetric?.map((metric: string, idx: number) => (
                              <li key={idx}>
                                {metric?.split("_")?.slice(1)?.join(" ")?.charAt(0)?.toUpperCase() +
                                  metric?.split("_")?.slice(1)?.join(" ")?.slice(1)}
                              </li>
                            ))}
                          </div>
                        ) : null}
                        <br />
                      </>
                    );
                  })}
                  <p>
                    <u>Source</u>: {tooltipDefinitionData?.source}
                  </p>
                  <br />
                </div>
              }
              trigger="click"
              animation="none"
              theme="dark"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              open={tooltipVisible}
              //@ts-ignore
              onShown={() => setTooltipVisible(!tooltipVisible)}
            >
              <span
                className="info_tooltip hide_on_mobile"
                onClick={(evt: React.MouseEvent<HTMLElement>) => {
                  evt.stopPropagation();
                  setTooltipVisible(!tooltipVisible);
                }}
              />
            </Tooltip>
          </div>
          {ToggleSwitch}
        </div>

        {title === "Website visits" && !showTotals ? null : (
          <p className="period_comparison" id={`period_yoy_comparison`} data-test-id={`period_yoy_comparison`}>
            <span className="main_value">
              {totalValue ? (
                <span className="main_value_number" id={`$yoy_value`} data-test-id={`yoy_value`}>
                  {ratio ? `${totalValue.toFixed(1)}%` : toLocaleInteger(totalValue)}
                </span>
              ) : (
                <span className="main_value_number" id={`$yoy_value`} data-test-id={`yoy_value`}>
                  n/a
                </span>
              )}
            </span>
            {comparisonValue && isFinite(comparisonValue) ? (
              <span className="comparison_value" id={`media_spend_yoy_comparison_value`} data-test-id={`media_spend_yoy_comparison_value`}>
                <Ratings value={!isNull(comparisonValue) ? comparisonValue : "n/a"} isPercentageValue={true} testValue={false} />
                <span className="comparison_value_number">
                  {comparisonValue
                    ? ratio
                      ? `${convertToAbsolutePts(comparisonValue, 1)}`
                      : `${Math.abs(comparisonValue).toFixed(1)}%`
                    : "n/a"}
                </span>
                YoY
              </span>
            ) : null}
          </p>
        )}
      </div>

      {!isNull(data) ? chart : loading ? <LoadingEllipsis isLoading={loading} /> : <NoDataMsg message="No data available" />}
    </div>
  );
};

export default MetricComparisonsGraph;
