import React from "react";
import MsrpProfileByMonth from "./MsrpProfileByMonth";
import VmeDiscountProfileByMonth from "./VmeDiscountProfileByMonth";

const MsrpTrends = () => {
  return (
    <div className="row">
      <MsrpProfileByMonth />
      <VmeDiscountProfileByMonth />
    </div>
  );
};

export default MsrpTrends;
