import { ErrorBoundary } from "@sentry/react";
import React from "react";
import "../../../assets/styles/component/profilePicture.scss";

interface Props {
  firstname: string;
  lastname: string;
}

export const ProfilePicture = (props: Props) => {
  const { firstname, lastname } = props;

  return (
    <ErrorBoundary>
      <div className="profile_picture">
        <span>{`${firstname[0]}${lastname[0]}`}</span>
      </div>
    </ErrorBoundary>
  );
};
