import { endOfMonth, endOfQuarter, endOfWeek, format, getMonth, getYear } from "date-fns";
import { getGeographyList } from "../components/Filters/subs/filterFunctions";
import { ALL_COUNTRIES, ALL_OPTION, ALL_OPTION_NO_SPACE, ANNOTATION_QUERY_PARAMS, ARIYA, AURA, QASHQAI_2021 } from "../constants";
import { Market, MetricType } from "../constants/interface/admin/common";
import { Annotation } from "../pages/Admin/types";

type KbaType = {
  id: number;
  kba_type: string;
  name: string;
};

type LeadType = {
  id: number;
  lead_type: string;
  name: string;
};

interface AnnotationParams extends Omit<Annotation, "id" | "group" | "date_created"> {
  id?: number;
  group?: string;
  date_created?: string;
}

export const attachAnnotationsToChartData = (annotations: Array<Annotation>, data: any, granularity: string, chart: string) => {
  const dataCopy = data.map((item: any) => {
    delete item.annotations;
    return item;
  });
  annotations.map((annotation) => {
    const annotationDataPoints: any = [];
    dataCopy.map((item: any, index: number) => {
      const startOfPeriod = new Date(item.date);
      const endOfPeriod =
        granularity == "monthly" ? endOfMonth(startOfPeriod) : granularity == "weekly" ? endOfWeek(startOfPeriod) : startOfPeriod;
      if (chart == "visits" || chart == "kba_visits") {
        endOfPeriod >= new Date(annotation.start_date) &&
          ((getMonth(startOfPeriod) == getMonth(new Date(annotation.start_date)) &&
            getYear(startOfPeriod) == getYear(new Date(annotation.start_date))) ||
            (getMonth(startOfPeriod) == getMonth(new Date(annotation.end_date)) &&
              getYear(startOfPeriod) == getMonth(new Date(annotation.end_date)))) &&
          annotationDataPoints.push({ index: index, data: item });
      } else {
        endOfPeriod >= new Date(annotation.start_date) && annotationDataPoints.push({ index: index, data: item });
      }
    });
    if (annotationDataPoints.length > 0) {
      if (dataCopy[annotationDataPoints[0].index].annotations) {
        const itemAnnotations = [...dataCopy[annotationDataPoints[0].index].annotations, annotation];
        dataCopy[annotationDataPoints[0].index].annotations = [...itemAnnotations];
      } else {
        dataCopy[annotationDataPoints[0].index].annotations = [annotation];
      }
    }
  });
  return dataCopy;
};

export const attachAnnotationsFullFigures = (annotations: Array<Annotation>, data: any, metric: string, granularity: string) => {
  const dataCopy = [
    ...data.map((item: any) => {
      delete item.comparison_annotations;
      delete item.ref_annotations;
      return item;
    }),
  ];
  annotations
    .filter((annotation) => annotation.metrics.map((metric) => metric.metric).includes(metric))
    .map((annotation) => {
      const refAnnotationDataPoints: any = [];
      const comparisonAnnotationDataPoints: any = [];
      dataCopy.map((item: any, index: number) => {
        if (granularity == "daily") {
          new Date(item.ref_date) >= new Date(annotation.start_date) &&
            new Date(item.ref_date) <= new Date(annotation.end_date) &&
            refAnnotationDataPoints.push({ index: index, data: item });
          new Date(item.comparison_date) >= new Date(annotation.start_date) &&
            new Date(item.comparison_date) <= new Date(annotation.end_date) &&
            comparisonAnnotationDataPoints.push({ index: index, data: item });
        } else if (granularity == "weekly") {
          const refWeekEndDate = new Date(item.ref_date);
          const comparisonWeekEndDate = new Date(item.comparison_date);
          refWeekEndDate.setDate(refWeekEndDate.getDate() + 6);
          comparisonWeekEndDate.setDate(comparisonWeekEndDate.getDate() + 6);
          new Date(item.ref_date).getFullYear() == new Date(annotation.start_date).getFullYear() &&
            new Date(item.ref_date).getMonth() == new Date(annotation.start_date).getMonth() &&
            new Date(annotation.start_date) <= new Date(refWeekEndDate) &&
            refAnnotationDataPoints.push({ index: index, data: item });
          new Date(item.comparison_date).getFullYear() == new Date(annotation.start_date).getFullYear() &&
            new Date(item.comparison_date).getMonth() == new Date(annotation.start_date).getMonth() &&
            new Date(annotation.start_date) <= new Date(comparisonWeekEndDate) &&
            comparisonAnnotationDataPoints.push({ index: index, data: item });
        } else {
          new Date(annotation.start_date).getFullYear() == new Date(item.ref_date).getFullYear() &&
            new Date(annotation.start_date).getMonth() == new Date(item.ref_date).getMonth() &&
            refAnnotationDataPoints.push({ index: index, data: item });
          new Date(annotation.start_date).getFullYear() == new Date(item.comparison_date).getFullYear() &&
            new Date(annotation.start_date).getMonth() == new Date(item.comparison_date).getMonth() &&
            comparisonAnnotationDataPoints.push({ index: index, data: item });
        }
      });
      if (refAnnotationDataPoints.length > 0) {
        if (dataCopy[refAnnotationDataPoints[0].index].ref_annotations) {
          const itemAnnotations = [...dataCopy[refAnnotationDataPoints[0].index].ref_annotations, annotation];
          dataCopy[refAnnotationDataPoints[0].index].ref_annotations = [...itemAnnotations];
        } else {
          dataCopy[refAnnotationDataPoints[0].index].ref_annotations = [annotation];
        }
      }
      if (comparisonAnnotationDataPoints.length > 0) {
        if (dataCopy[comparisonAnnotationDataPoints[0].index].comparison_annotations) {
          const itemAnnotations = [...dataCopy[comparisonAnnotationDataPoints[0].index].comparison_annotations, annotation];
          dataCopy[comparisonAnnotationDataPoints[0].index].comparison_annotations = [...itemAnnotations];
        } else {
          dataCopy[comparisonAnnotationDataPoints[0].index].comparison_annotations = [annotation];
        }
      }
    });
  return dataCopy;
};

export const convertToMetricType = (data: Array<KbaType> | Array<LeadType>): MetricType[] => {
  const merticTypesData = data.map((item: KbaType | LeadType | MetricType) => {
    let newObj = {} as MetricType;
    if ("kba_type" in item) {
      newObj = { id: item.id, metric_type: item.kba_type };
    } else if ("lead_type" in item) {
      newObj = { id: item.id, metric_type: item.lead_type };
    }
    return newObj;
  });

  return merticTypesData;
};

export const convertParamsToQueryParams = (annotation: AnnotationParams) => {
  // @ts-ignore
  const dashboardQueryParams = ANNOTATION_QUERY_PARAMS[annotation.dashboard];

  const date_range =
    annotation.dashboard == "mtm_metrics_that_matter"
      ? "Q2 20 - Q1 22"
      : `${format(new Date(annotation.start_date), "dd/MM/yyyy")} - ${format(new Date(annotation.end_date), "dd/MM/yyyy")}`;
  const brand = annotation.brands[0]?.brand;
  const group = "All ";
  const region = annotation.regions.flatMap((region) => (region.region == "ALL" ? ALL_OPTION : region.region)).join(",");
  const market = annotation.markets.flatMap((market) => (market.market == "ALL" ? ALL_COUNTRIES : market.market)).join(",");
  const model = annotation.models.flatMap((model) => model.model).join(",");
  const last_touch_marketing_channel = annotation.channels
    .flatMap((channel) => (channel.channel == "ALL" ? ALL_OPTION : channel.channel))
    .join(",");
  const last_touch_marketing_channel_breakdown = annotation.channel_breakdowns
    .flatMap((channel_breakdown) => (channel_breakdown.channel_breakdown == "ALL" ? ALL_OPTION : channel_breakdown.channel_breakdown))
    .join(",");
  const metric = annotation.metrics[0]?.metric;
  const tag = annotation.tags.length > 0 ? annotation.tags.flatMap((tag) => tag.tag).join(",") : ALL_OPTION_NO_SPACE;

  const params = {
    date_range: date_range,
    brand: brand,
    group: group,
    region: region == "All" ? ALL_OPTION : region,
    market: market,
    model: model,
    last_touch_marketing_channel: last_touch_marketing_channel,
    last_touch_marketing_channel_breakdown: last_touch_marketing_channel_breakdown,
    mobile_device_type: "All ",
    metric: metric,
    app_store: "All",
    tag: tag,
  };

  const paramsAsArray = Object.entries(params);

  const filtered = paramsAsArray.filter(([key, value]) => dashboardQueryParams.includes(key));

  return Object.fromEntries(filtered);
};

export const getAvailableAnnotationMarkets = (filters: any, brands: string[], markets: Market[], dashboard: string, regions?: string[]) => {
  if (dashboard == "online_ce_performance") {
    const geographyList = getGeographyList({ filters: filters, brand: brands });
    if (regions && regions.length == 0) {
      return [];
    } else if (regions && regions.find((region: string) => region == ALL_OPTION_NO_SPACE)) {
      const regionMarketList = geographyList.slice(1).flatMap((geo: any) => geo.markets);
      return markets.filter((market: Market) => regionMarketList.includes(market.market) || market.market == ALL_OPTION_NO_SPACE);
    } else if (regions && regions.length > 0) {
      const selectedRegionsMarkets = geographyList
        .filter((geo: any) => regions.flatMap((region: string) => region).includes(geo.region))
        .flatMap((geo: any) => geo.markets);
      return [{ market: ALL_OPTION_NO_SPACE }, ...markets.filter((market: Market) => selectedRegionsMarkets.includes(market.market))];
    }
  } else if (dashboard == "purchase_funnel") {
    const brandFilters = filters.filter((filter: any) =>
      brands.some((brand) => filter.brand_source.flatMap((brandSource: { brand: string }) => brandSource.brand).includes(brand))
    );
    const purchaseFunnelMarkets = brandFilters.map((filter: { market: string }) => filter.market);
    return [{ market: ALL_OPTION_NO_SPACE }, ...markets.filter((market: Market) => purchaseFunnelMarkets.includes(market.market))];
  } else if (dashboard == "mtm_metrics_that_matter") {
    const mtmMarketList = filters.flatMap((filter: { region: string }) => filter.region);
    return [...markets.filter((market: Market) => mtmMarketList.includes(market.market))];
  }

  return [];
};

export const getAvailableAnnotationModels = (
  regions: string[],
  markets: string[],
  models: Record<string, Array<{ model: string; counter: number }>>
) => {
  let availableModelsList: Array<string> = [];
  let hasAriya = false;
  let hasQashqai = false;
  let hasAura = false;
  if (models && regions.length > 0 && markets.length > 0) {
    if (regions.includes(ALL_OPTION_NO_SPACE) && markets.includes(ALL_OPTION_NO_SPACE)) {
      availableModelsList = [];
    } else if (markets.includes(ALL_OPTION_NO_SPACE)) {
      availableModelsList = regions
        .map((region) => (region == ALL_OPTION_NO_SPACE ? models["Global"] : models[region]))
        .flatMap((models) => models)
        .sort((a, b) => b.counter - a.counter)
        .map((model) => {
          if (model.model == ARIYA) hasAriya = true;
          if (model.model == QASHQAI_2021) hasQashqai = true;
          return model.model;
        })
        .slice(0, 10);
    } else if (markets.length == 1) {
      const marketModels = markets
        .map((market) => models[market])
        .flatMap((models) => models)
        .sort((a, b) => b.counter - a.counter);
      availableModelsList = marketModels
        .map((model) => {
          if (model.model == ARIYA) hasAriya = true;
          if (model.model == QASHQAI_2021) hasQashqai = true;
          if (markets[0] === "Japan" && model.model == AURA) hasAura = true;
          return model.model;
        })
        .slice(0, 10);
    }
    hasAriya && !availableModelsList.includes(ARIYA) && availableModelsList.push(ARIYA);
    hasQashqai && !availableModelsList.includes(QASHQAI_2021) && availableModelsList.push(QASHQAI_2021);
    hasAura && !availableModelsList.includes(AURA) && availableModelsList.push(AURA);
  }
  const distinct = (value: string, index: number, self: Array<string>) => {
    return self.indexOf(value) === index;
  };
  return availableModelsList.filter(distinct);
};

export const attachAnnotationsCurrentComparisonTrendline = (
  annotations: Array<Annotation>,
  data: Array<Record<string, number | string | Array<Annotation>>>,
  metric: string
): Array<Record<string, number | string | Array<Annotation>>> => {
  const filteredAnnotations = annotations.filter((annotation) => annotation.metrics.flatMap((metric) => metric.metric).includes(metric));
  const annotationsLength = filteredAnnotations.length;
  for (let i = 0; i < annotationsLength; i++) {
    const annotation = filteredAnnotations[i];

    const annotationStartDate = new Date(annotation.start_date);

    let start = 0;
    let end = data.length - 1;

    loop2: while (start <= end) {
      const mid = Math.floor((start + end) / 2);
      const dataDate = new Date(data[mid].date as string);

      if (dataDate.getMonth() == annotationStartDate.getMonth() && dataDate.getFullYear() == annotationStartDate.getFullYear()) {
        if ("currentValueAnnotations" in data[mid]) {
          //@ts-ignore
          data[mid]["currentValueAnnotations"].push(annotation);
        } else {
          data[mid]["currentValueAnnotations"] = [annotation];
        }
        break loop2;
      } else if (dataDate.getMonth() == annotationStartDate.getMonth() && dataDate.getFullYear() - 1 == annotationStartDate.getFullYear()) {
        if ("comparissonValueAnnotations" in data[mid]) {
          //@ts-ignore
          data[mid]["comparisonValueAnnotations"].push(annotation);
        } else {
          data[mid]["comparisonValueAnnotations"] = [annotation];
        }
        break loop2;
      } else if (dataDate.getFullYear() == annotationStartDate.getFullYear() && dataDate.getMonth() < annotationStartDate.getMonth()) {
        start = mid + 1;
      } else {
        end = mid - 1;
      }
    }
  }

  return data;
};

export const attachAnnotationsLeadsSalesTrendChart = (
  annotations: Array<Annotation>,
  data: Array<Record<string, number | string | Array<Annotation>>>
): Array<Record<string, number | string | Array<Annotation>>> => {
  return data.map((item: any) => {
    const dataDate = new Date(item.date);
    const itemAnnotations = annotations.filter((annotation) => {
      const annotationStartDate = new Date(annotation.start_date);
      return annotationStartDate.getMonth() == dataDate.getMonth() && annotationStartDate.getFullYear() == dataDate.getFullYear();
    });

    if (itemAnnotations.length) {
      return { ...item, annotations: itemAnnotations };
    } else {
      return item;
    }
  });
};

export const attachAnnotationsPrivateSalesBreakdownChart = (
  annotations: Array<Annotation>,
  data: Array<Record<string, number | string | Array<Annotation>>>
): Array<Record<string, number | string | Array<Annotation>>> => {
  const metrics = ["Digitally influenced sales", "Non-digitally influenced sales", "Total private sales"];
  return data.map((item: any) => {
    const dataDate = new Date(item.date);
    const itemAnnotations = annotations.filter((annotation) => {
      const annotationStartDate = new Date(annotation.start_date);
      return (
        annotationStartDate.getMonth() == dataDate.getMonth() &&
        annotationStartDate.getFullYear() == dataDate.getFullYear() &&
        metrics.some((metric) => annotation.metrics.flatMap((metric) => metric.metric).includes(metric))
      );
    });

    if (itemAnnotations.length) {
      return { ...item, annotations: itemAnnotations };
    } else {
      return item;
    }
  });
};

export const attachAnnotationsDigitalLeadsSourceChart = (
  annotations: Array<Annotation>,
  data: Array<Record<string, number | string | Array<Annotation>>>
): Array<Record<string, number | string | Array<Annotation>>> => {
  const leadSources = ["All", ...Object.keys(data)];
  return data.map((item: any) => {
    const dataDate = new Date(item.date);
    const itemAnnotations = annotations.filter((annotation) => {
      const annotationStartDate = new Date(annotation.start_date);
      return (
        annotationStartDate.getMonth() == dataDate.getMonth() &&
        annotationStartDate.getFullYear() == dataDate.getFullYear() &&
        leadSources.some((leadSource) => annotation.lead_sources.flatMap((lead_source) => lead_source.lead_source).includes(leadSource))
      );
    });

    if (itemAnnotations.length) {
      return { ...item, annotations: itemAnnotations };
    } else {
      return item;
    }
  });
};

export const attachAnnotationsMtm = (
  annotations: Array<Annotation>,
  data: Array<Record<string, number | string | Array<Annotation>>>,
  granularity: string,
  metrics: string[]
) => {
  return data.map((item: any) => {
    const dataDate = new Date(item.date);
    const endDate = granularity == "monthly" ? endOfMonth(dataDate) : endOfQuarter(dataDate);

    const itemAnnotations = annotations.filter((annotation) => {
      const annotationStartDate = new Date(annotation.start_date);
      const annotationsMetrics = annotation.metrics.flatMap((metric) => metric.metric);
      dataDate.setHours(0, 0, 0, 0);
      annotationStartDate.setHours(0, 0, 0, 0);
      return (
        annotationStartDate >= dataDate && annotationStartDate <= endDate && annotationsMetrics.some((metric) => metrics.includes(metric))
      );
    });

    if (itemAnnotations.length) {
      return { ...item, annotations: itemAnnotations };
    } else {
      return item;
    }
  });
};
