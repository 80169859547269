import {
  FETCH_DIFFERENCE_BY_MODEL,
  FETCH_DIS_BY_MODEL_AND_MONTH,
  FETCH_MSRP_MODEL_PERFORMANCE,
  FETCH_MSRP_OVER_TIME_BY_MODEL,
  FETCH_MSRP_PROFILE_BY_MONTH,
  FETCH_MSRP_SUMMARY,
  FETCH_MSRP_VME_DIFFERENCE,
  FETCH_SALES_BY_MODEL,
  FETCH_TOP_FIVE_MODELS,
  FETCH_TOTAL_ADDED_VALUE,
  FETCH_TOTAL_VALUE_DIS_BY_MONTH,
  FETCH_VME_DIFFERENCE_BY_MODEL,
  FETCH_VME_DISCOUNT_OVER_TIME_BY_MODEL,
  SET_CURRENCY,
  SET_DIFFERENCE_BY_MODEL,
  SET_DIS_BY_MODEL_AND_MONTH,
  SET_MSRP_MODEL_PERFORMANCE,
  SET_MSRP_OVER_TIME_BY_MODEL,
  SET_MSRP_PROFILE_BY_MONTH,
  SET_MSRP_SUMMARY,
  SET_MSRP_VME_DIFFERENCE,
  SET_SALES_BY_MODEL,
  SET_TOP_FIVE_MODELS,
  SET_TOTAL_ADDED_VALUE,
  SET_TOTAL_VALUE_DIS_BY_MONTH,
  SET_VME_DIFFERENCE_BY_MODEL,
  SET_VME_DISCOUNT_OVER_TIME_BY_MODEL,
} from "./actionTypes";

export const fetchMsrpSummary = () => ({ type: FETCH_MSRP_SUMMARY });
export const setMsrpSummary = (data: any) => ({ type: SET_MSRP_SUMMARY, payload: data });

export const fetchSalesByModel = () => ({ type: FETCH_SALES_BY_MODEL });
export const setSalesByModel = (data: any) => ({ type: SET_SALES_BY_MODEL, payload: data });

export const fetchDifferenceByModel = () => ({ type: FETCH_DIFFERENCE_BY_MODEL });
export const setDifferenceByModel = (data: any) => ({ type: SET_DIFFERENCE_BY_MODEL, payload: data });

export const fetchVmeDifferenceByModel = () => ({ type: FETCH_VME_DIFFERENCE_BY_MODEL });
export const setVmeDifferenceByModel = (data: any) => ({ type: SET_VME_DIFFERENCE_BY_MODEL, payload: data });

export const fetchMsrpVmeDifference = () => ({ type: FETCH_MSRP_VME_DIFFERENCE });
export const setMsrpVmeDifference = (data: any) => ({ type: SET_MSRP_VME_DIFFERENCE, payload: data });

export const fetchMsrpProfileByMonth = () => ({ type: FETCH_MSRP_PROFILE_BY_MONTH });
export const setMsrpProfileByMonth = (data: any) => ({ type: SET_MSRP_PROFILE_BY_MONTH, payload: data });

export const fetchMsrpModelPerformance = (models: string) => ({ type: FETCH_MSRP_MODEL_PERFORMANCE, payload: models });
export const setMsrpModelPerformance = (data: any) => ({ type: SET_MSRP_MODEL_PERFORMANCE, payload: data });

export const fetchMsrpOverTimeByModel = (models: string) => ({ type: FETCH_MSRP_OVER_TIME_BY_MODEL, payload: models });
export const setMsrpOverTimeByModel = (data: any) => ({ type: SET_MSRP_OVER_TIME_BY_MODEL, payload: data });

export const fetchVmeDiscountOverTimeByModel = (models: string) => ({ type: FETCH_VME_DISCOUNT_OVER_TIME_BY_MODEL, payload: models });
export const setVmeDiscountOverTimeByModel = (data: any) => ({ type: SET_VME_DISCOUNT_OVER_TIME_BY_MODEL, payload: data });

export const fetchTotalValueDisByMonth = () => ({ type: FETCH_TOTAL_VALUE_DIS_BY_MONTH });
export const setTotalValueDisByMonth = (data: any) => ({ type: SET_TOTAL_VALUE_DIS_BY_MONTH, payload: data });

export const fetchDisByModelAndMonth = () => ({ type: FETCH_DIS_BY_MODEL_AND_MONTH });
export const setDisByModelAndMonth = (data: any) => ({ type: SET_DIS_BY_MODEL_AND_MONTH, payload: data });

export const setCurrency = (data: string) => ({ type: SET_CURRENCY, payload: data });

export const fetchTotalAddedValue = () => ({ type: FETCH_TOTAL_ADDED_VALUE });
export const setTotalAddedValue = (data: any) => ({ type: SET_TOTAL_ADDED_VALUE, payload: data });

export const fetchTopFiveModels = () => ({ type: FETCH_TOP_FIVE_MODELS });
export const setTopFiveModels = (data: any) => ({ type: SET_TOP_FIVE_MODELS, payload: data });
