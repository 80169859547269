import React, { useEffect, useState } from "react";
import SummaryTile from "./SummaryTile";
import { ToggleSwitch } from "../../../../components/Toogle";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getQuickVocCecCcs, getQuickVocCecComplaint, getQuickVocCecInformationRequest } from "../../../../api/quickVocCecApi";

const Summary = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const [ratingActive, setRatingActive] = useState(true);

  const {
    data: complaintData,
    refetch: refetchComplaintData,
    isFetching: isFetchingComplaintData,
  } = useQuery({
    queryKey: ["quickVocCecComplaint", ratingActive],
    queryFn: () => getQuickVocCecComplaint(ratingActive),
    enabled: false,
  });

  const {
    data: informationRequestData,
    refetch: refetchInformationRequestData,
    isFetching: isFetchingInformationRequestData,
  } = useQuery({
    queryKey: ["quickVocCecInformationRequest", ratingActive],
    queryFn: () => getQuickVocCecInformationRequest(ratingActive),
    enabled: false,
  });

  const {
    data: ccsData,
    refetch: refetchCcsData,
    isFetching: isFetchingCcsData,
  } = useQuery({
    queryKey: ["quickVocCecCcs", ratingActive],
    queryFn: () => getQuickVocCecCcs(ratingActive),
    enabled: false,
  });

  useEffect(() => {
    refetchComplaintData();
    refetchInformationRequestData();
    refetchCcsData();
  }, [history.location.search, ratingActive]);

  return (
    <div className="summary-container">
      <ToggleSwitch
        activeToggleLabel={"Rating"}
        inactiveToggleLabel={"Volume"}
        active={ratingActive}
        handleToggleClick={() => setRatingActive((prevState) => !prevState)}
      />
      <div className="summary-tiles-container">
        <SummaryTile
          title="COMPLAINT"
          summaryValue={complaintData?.summary}
          previousPeriodValue={complaintData?.summary_previous_period}
          isRating={ratingActive}
          isLoading={isFetchingComplaintData}
          chartId="complaintChart"
          chartData={{ data: complaintData?.data ?? [], series: complaintData?.series ?? [] }}
        />
        <SummaryTile
          title="INFORMATION REQUEST"
          summaryValue={informationRequestData?.summary}
          previousPeriodValue={informationRequestData?.summary_previous_period}
          isRating={ratingActive}
          isLoading={isFetchingInformationRequestData}
          chartId="informationRequestChart"
          chartData={{ data: informationRequestData?.data ?? [], series: informationRequestData?.series ?? [] }}
        />
        <SummaryTile
          title="CEC CCS"
          summaryValue={ccsData?.summary}
          previousPeriodValue={ccsData?.summary_previous_period}
          isRating={ratingActive}
          isLoading={isFetchingCcsData}
          chartId="ccsChart"
          chartData={{ data: ccsData?.data ?? [], series: ccsData?.series ?? [] }}
        />
      </div>
    </div>
  );
});

export default Summary;
