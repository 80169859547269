import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { getTopFiveProductsVisited } from "../../../../api/executiveSummaryProductPerformanceApi";
import ExecutiveSummaryDonutChart from "../../../../components/Charts/ExecutiveSummary/ExecutiveSummaryDonutChart";
import { ThemeContext } from "../../../../context";

const TopFiveProductsVisited = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["topFiveProductsVisitedData", history.location.search],
    queryFn: getTopFiveProductsVisited,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile">
      <div className="tile-header">
        <div className="title-container">
          <div className="title">Top 5 products visited</div>
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={"Products that have received the biggest number of website visits within the selected date range and markets."}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      <ExecutiveSummaryDonutChart
        chartId="topFiveProductsVisitedChart"
        data={data}
        theme={theme}
        categoryField="product"
        valueField="visits_perc"
        isLoading={isLoading}
        radius={100}
      />
    </div>
  );
});

export default TopFiveProductsVisited;
