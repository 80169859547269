import {
  SET_ACCESSIBILITY,
  SET_BUGS_RELEASE_BREAKDOWN,
  SET_BUGS_STATUS_BREAKDOWN,
  SET_CUSTOMER_EXPERIENCE,
  SET_DIGITAL_PERFORMANCE,
  SET_ERROR_PAGES,
  SET_FEATURES_ACTIVATED,
  SET_INFLUENCE_ON_LEADS,
  SET_INFLUENCE_ON_SHOPPING_TOOLS,
  SET_LIVE_MARKETS,
  SET_PAGE_PERFORMANCE,
  SET_PAGE_PERFORMANCE_BREAKDOWN,
  SET_TOTAL_BUGS,
} from "../actions/actionTypes/productPerformanceActionTypes";

const initialState = {
  live_markets: {},
  features_activated: {},
  digital_performance: [],
  influence_on_shopping_tools: [],
  influence_on_leads: [],
  total_bugs: {},
  bugs_status_breakdown: {},
  bugs_release_breakdown: {},
  page_performance: [],
  page_performance_breakdown: [],
  accessibility: {},
  customer_experience: {},
  error_pages: {},
};

const ProductPerformanceReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_LIVE_MARKETS:
      return { ...state, live_markets: action.payload };

    case SET_FEATURES_ACTIVATED:
      return { ...state, features_activated: action.payload };

    case SET_DIGITAL_PERFORMANCE:
      return { ...state, digital_performance: action.payload };

    case SET_INFLUENCE_ON_SHOPPING_TOOLS:
      return { ...state, influence_on_shopping_tools: action.payload };

    case SET_INFLUENCE_ON_LEADS:
      return { ...state, influence_on_leads: action.payload };

    case SET_TOTAL_BUGS:
      return { ...state, total_bugs: action.payload };

    case SET_BUGS_STATUS_BREAKDOWN:
      return { ...state, bugs_status_breakdown: action.payload };

    case SET_BUGS_RELEASE_BREAKDOWN:
      return { ...state, bugs_release_breakdown: action.payload };

    case SET_PAGE_PERFORMANCE:
      return { ...state, page_performance: action.payload };

    case SET_PAGE_PERFORMANCE_BREAKDOWN:
      return { ...state, page_performance_breakdown: action.payload };

    case SET_ACCESSIBILITY:
      return { ...state, accessibility: action.payload };

    case SET_CUSTOMER_EXPERIENCE:
      return { ...state, customer_experience: action.payload };

    case SET_ERROR_PAGES:
      return { ...state, error_pages: action.payload };

    default:
      return state;
  }
};

export default ProductPerformanceReducer;
