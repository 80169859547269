import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";
import { getGranularityFromDate } from "../../../../utils/utilityFunctions";

interface Props {
  yoyLabel: string;
  latestMonthLabel: string;
  isLoading: boolean;
  indicatorsData: {
    title: string;
    yoyValue: number | string;
    yoyTargetValue?: number | string;
    yoyComparisonValue: number | string;
    popComparisonValue?: number | string;
    yoyTargetComparisonValue?: number | string;
    latestMonthValue: number;
    latestMonthTargetValue?: number | string;
    latestMonthYoyComparisonValue: number | string;
    latestMonthPopComparisonValue: number | string;
    latestMonthTargetComparisonValue?: number | string;
    isPercentage?: boolean;
    titleTooltipText?: string;
  }[];
}

const PoPLabelMapping: Record<string, string> = {
  monthly: "MoM",
  quarterly: "QoQ",
};

const JourneyExcellenceKeyIndicator = (props: Props) => {
  const { yoyLabel, latestMonthLabel, isLoading, indicatorsData } = props;
  const dateRangeParam = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const marketParam = useSelector((state: RootStateOrAny) => state.parameters.market);

  const PoPLabel = useMemo(() => {
    const dateGranularity = getGranularityFromDate(dateRangeParam, "monthly");
    return PoPLabelMapping[dateGranularity];
  }, [dateRangeParam]);

  return (
    <div className="key-indicators-container">
      <div className="key-indicator-row duration">
        <div>{yoyLabel}</div>
        <div>{latestMonthLabel}</div>
      </div>
      {indicatorsData.map(
        ({
          title,
          yoyValue,
          yoyTargetValue,
          yoyComparisonValue,
          yoyTargetComparisonValue,
          popComparisonValue,
          latestMonthValue,
          latestMonthTargetValue,
          latestMonthYoyComparisonValue,
          latestMonthPopComparisonValue,
          latestMonthTargetComparisonValue,
          isPercentage,
          titleTooltipText,
        }) => (
          <div className="key-indicator-data" key={title}>
            <div className="key-indicator-row">
              <div className="title">
                {title}
                {titleTooltipText && (
                  <Tooltip
                    position="top"
                    className={"normal_tippy"}
                    trigger="mouseenter"
                    animation="none"
                    interactive={true}
                    delay={1}
                    hideDelay={1}
                    duration={1}
                    title={titleTooltipText}
                    size={"small"}
                  >
                    <span className="info_tooltip"></span>
                  </Tooltip>
                )}
              </div>
              <div className="title">{title}</div>
            </div>
            <div className="key-indicator-row duration-values">
              <div className="values current-values">
                <div className="main-value">
                  <span className="main-value-number">
                    {yoyValue?.toLocaleString()}
                    {isPercentage ? "%" : ""}
                  </span>
                  {yoyTargetValue && (
                    <span className="main-value-target">
                      (Target: {yoyTargetValue}
                      {isPercentage ? "%" : ""})
                    </span>
                  )}
                </div>
                <div className="comparison-values">
                  <div>
                    <Ratings value={yoyComparisonValue} isPercentageValue={isPercentage ? true : false} testValue={false} />
                    {Number(yoyComparisonValue) > 0 ? "+" : ""}
                    {yoyComparisonValue} {isPercentage ? "PTS" : "%"} YoY
                  </div>
                  {(dateRangeParam.includes("quarter") || dateRangeParam.includes("month")) && (
                    <div>
                      <Ratings value={popComparisonValue as number} isPercentageValue={isPercentage ? true : false} testValue={false} />
                      {Number(popComparisonValue) > 0 ? "+" : ""}
                      {popComparisonValue} {popComparisonValue ? (isPercentage ? "PTS" : "%") : "n/a"} {PoPLabel}
                    </div>
                  )}
                  {yoyTargetComparisonValue && (
                    <div>
                      <Ratings value={yoyTargetComparisonValue} isPercentageValue={isPercentage ? true : false} testValue={false} />
                      {Number(yoyTargetComparisonValue) > 0 ? "+" : ""}
                      {yoyTargetComparisonValue} {isPercentage ? "PTS" : "%"} vs target
                    </div>
                  )}
                </div>
              </div>
              <div className="values">
                <div className="main-value">
                  <span className="main-value-number">
                    {latestMonthValue?.toLocaleString()}
                    {isPercentage ? "%" : ""}
                  </span>
                  {latestMonthTargetValue && (
                    <span className="main-value-target">
                      (Target: {latestMonthTargetValue}
                      {isPercentage ? "%" : ""})
                    </span>
                  )}
                </div>
                <div className="comparison-values">
                  <div>
                    <Ratings value={latestMonthYoyComparisonValue} isPercentageValue={isPercentage ? true : false} testValue={false} />
                    {Number(latestMonthYoyComparisonValue) > 0 ? "+" : ""}
                    {latestMonthYoyComparisonValue} {isPercentage ? "PTS" : "%"} YoY
                  </div>
                  <div>
                    <Ratings value={latestMonthPopComparisonValue} isPercentageValue={isPercentage ? true : false} testValue={false} />
                    {Number(latestMonthPopComparisonValue) > 0 ? "+" : ""}
                    {latestMonthPopComparisonValue} {latestMonthPopComparisonValue ? (isPercentage ? "PTS" : "%") : "n/a"} MoM
                  </div>
                  {latestMonthTargetComparisonValue && (
                    <div>
                      <Ratings value={latestMonthTargetComparisonValue} isPercentageValue={isPercentage ? true : false} testValue={false} />
                      {Number(latestMonthTargetComparisonValue) > 0 ? "+" : ""}
                      {latestMonthTargetComparisonValue} {isPercentage ? "PTS" : "%"} vs target
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      )}
      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};

export default JourneyExcellenceKeyIndicator;
