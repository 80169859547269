import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../../components/AppMessages";
import FlopServicesChart from "../../../../components/Charts/TopFlop/FlopServicesChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../../context";

const FlopServicesGraph = () => {
  const themeContext = useContext(ThemeContext);

  const data = useSelector((state: RootStateOrAny) => state.top_flop.flop_services);
  const isLoading = useSelector((state: RootStateOrAny) => state.loading.top_flop_flop_services);

  const graphData = useMemo(() => {
    const groupedData: any[] = [];
    const servicesDataGroupByDate: Record<string, any> = {};
    if (data) {
      data.forEach((service: any) => {
        const date = `${service?.week ? `Week ${service.week} ` : ""}${service.month_name} ${service.year}`;
        if (date in servicesDataGroupByDate) {
          servicesDataGroupByDate[date]["date"] = date;
          servicesDataGroupByDate[date][service.service] = service.events;
        } else {
          servicesDataGroupByDate[date] = {};
          servicesDataGroupByDate[date]["date"] = date;
          servicesDataGroupByDate[date][service.service] = service.events;
        }
      });
    }
    Object.values(servicesDataGroupByDate).forEach((item) => groupedData.push(item));
    return groupedData;
  }, [data]);

  const serviceList: Array<string> = useMemo(() => {
    if (data) {
      return Array.from(new Set(data.flatMap((service: { service: string }) => service.service)));
    }
    return [];
  }, [data]);

  return (
    <>
      <div className="flop_services">
        <h3>Flop 5 connected car services</h3>
        <div className="main_content">
          <ErrorBoundary fallback={<ErrorMsg />}>
            {graphData && graphData.length > 0 ? (
              <FlopServicesChart data={graphData} seriesList={serviceList} theme={themeContext.theme} />
            ) : isLoading ? null : (
              <NoDataMsg />
            )}
          </ErrorBoundary>
        </div>
        <LoadingEllipsis isLoading={isLoading} />
      </div>
    </>
  );
};

export default FlopServicesGraph;
