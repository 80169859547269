import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ErrorBoundary } from "@sentry/react";
import React, { Fragment, useLayoutEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useExpanded, usePagination, useSortBy, useTable } from "react-table";
import { setSpecificParameter } from "../../../actions";
import "../../../assets/styles/component/tables.scss";
import { ALL_OPTION } from "../../../constants";
import { ErrorMsg } from "../../AppMessages";
import customSortTypes from "./sortType";
import { useStartExpanded } from "./useStartExpanded";
interface Props {
  data: any;
  columns: any;
  id: string;
  extraClass?: string;
  hiddenCols: string[];
  hideHeader?: boolean;
  startExpanded?: boolean;
  renderRowSubComponent?: (props: { row: any }) => JSX.Element;
  isChildComponent: boolean;
  initialSortColId: { id: string; desc: boolean };
  setSortInformation?: (id: string, desc: boolean) => void;
  parentColumns?: string[];
  selectedref?: React.MutableRefObject<any>;
  manualSortBy?: boolean;
  showPagination?: boolean;
  pageSize?: number;
}

const DefaultTable = ({
  data,
  columns: chartColumns,
  id: chartId,
  extraClass,
  hideHeader,
  hiddenCols,
  startExpanded,
  renderRowSubComponent,
  isChildComponent,
  initialSortColId,
  setSortInformation,
  selectedref,
  manualSortBy,
  showPagination,
  pageSize,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    document.querySelectorAll(".child_table > tbody > tr > td > .filter-link").forEach((element) => {
      element.addEventListener("click", (evt) => {
        const {
          //@ts-ignore
          currentTarget: { dataset },
        } = evt;
        const type = dataset?.type;
        const value = dataset?.value;

        if (type && value) {
          if (type === "breakdown") dispatch(setSpecificParameter("market", value));
          if (type === "market") dispatch(setSpecificParameter("region", ALL_OPTION));
          dispatch(setSpecificParameter(type, value));
        }
        evt.stopPropagation();
      });
    });
  }, []);

  const sortTypes = useMemo(() => customSortTypes(), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    footerGroups,
    rowSpanHeaders,
    nextPage,
    previousPage,
    page,
    state,
    pageCount,
  } = useTable(
    {
      columns: chartColumns,
      data,
      initialState: {
        hiddenColumns: [...hiddenCols],
        sortBy: [initialSortColId],
        startExpanded,
        pageSize: showPagination && pageSize ? pageSize : 10000,
      },
      sortTypes,
      manualSortBy: manualSortBy ? true : false,
    },
    (hooks) => hooks.useInstance.push(useRowSpan),
    useSortBy,
    useExpanded,
    usePagination,
    useStartExpanded
  );

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <table id={chartId} ref={selectedref} {...getTableProps()} className={`${extraClass} ${isChildComponent ? "child_table" : ""}`}>
        {isChildComponent
          ? ""
          : !hideHeader && (
            <thead
              className={`${extraClass?.includes("topFlop") ? "top_flop_header" : ""}${extraClass?.includes("metricBreakdown") ? "breakdown_header" : ""
                }`}
            >
              {headerGroups.map((headerGroup, index) => {
                const { key: headerGroupKey, ...otherHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                return (
                  <tr {...otherHeaderGroupProps} key={headerGroupKey ? headerGroupKey : `${chartId}_${index}_tr`}>
                    {headerGroup.headers.map((column, index) => {
                      const sortClass =
                        extraClass?.includes("midas") || extraClass?.includes("cpo_operational")
                          ? "disabled disable_click"
                          : column.canSort
                            ? column?.isSorted
                              ? column.isSortedDesc
                                ? "down"
                                : "up"
                              : ""
                            : "disabled";
                      const popClass = column.Header === "WoW" || column.Header === "MoM" || column.Header === "QoQ" ? "PoP" : "";

                      const sortedId = column.id;
                      const isSorted = column.isSorted;
                      const isSortedDesc = !!column.isSortedDesc;
                      const indexOfLastColumn = headerGroup.headers.length - 1;

                      // Updates sort id and order for child component
                      isSorted && setSortInformation && setSortInformation(sortedId, isSortedDesc);

                      const { key: headerKey, ...otherHeaderProps } = column.getHeaderProps(column.getSortByToggleProps());

                      return (
                        <th
                          {...otherHeaderProps}
                          key={`${headerKey}_${index}`}
                          className={`sortable ${extraClass?.includes("media_mix")
                              ? extraClass?.includes("q1") && index !== 0 && index !== indexOfLastColumn
                                ? "border_right"
                                : index !== indexOfLastColumn
                                  ? "border_right"
                                  : ""
                              : ""
                            }
                            ${(extraClass?.includes("mtp") || extraClass?.includes("cpo_operational")) && index !== indexOfLastColumn
                              ? "border_right"
                              : ""
                            }
                            ${column.id?.includes("fy0") ? "blue" : column.id?.includes("fy1") ? "dark_purple" : ""}
                            ${sortClass} ${column.Header} ${popClass}`}
                        >
                          {column.render("Header")}
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
          )}

        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            for (let j = 0; j < row.allCells.length; j++) {
              const cell = row.allCells[j];
              const rowSpanHeader = rowSpanHeaders.find((x: any) => x.id === cell.column.id);

              if (rowSpanHeader) {
                if (rowSpanHeader.topCellValue === null || rowSpanHeader.topCellValue !== cell.value) {
                  //@ts-ignore
                  cell.isRowSpanned = false;
                  rowSpanHeader.topCellValue = cell.value;
                  rowSpanHeader.topCellIndex = i;
                  //@ts-ignore
                  cell.rowSpan = 1;
                } else {
                  //@ts-ignore
                  rows[rowSpanHeader.topCellIndex].allCells[j].rowSpan++;
                  //@ts-ignore
                  cell.isRowSpanned = true;
                }
              }
            }
            return null;
          })}

          {page.map((row, index) => {
            const rowClass = (index + 1) % 2 === 0 ? "even" : "odd";

            return (
              <Fragment key={row.getRowProps().key}>
                <tr
                  {...row.getRowProps()}
                  className={
                    extraClass?.includes("mtp") || extraClass?.includes("cpo_operational") || extraClass?.includes("strategic")
                      ? ""
                      : rowClass
                  }
                >
                  {row.cells.map((cell, idx) => {
                    const { key: cellKey, ...otherCellProps } = cell.getCellProps();
                    //@ts-ignore
                    if (cell.isRowSpanned) return null;
                    else
                      return (
                        <td
                          //@ts-ignore
                          rowSpan={cell.rowSpan}
                          {...otherCellProps}
                          className={`${extraClass?.includes("media_mix")
                              ? extraClass?.includes("q1") && idx !== 0 && idx !== row.cells.length - 1
                                ? "border_right"
                                : idx !== row.cells.length - 1
                                  ? "border_right"
                                  : ""
                              : ""
                            } ${cell.column.id?.includes("fy0") ? "blue" : cell.column.id?.includes("fy1") ? "dark_purple" : ""} 
                          ${extraClass?.includes("mtp") && !isChildComponent && index == rows.length - 1 ? "total_highlight" : ""}
                          ${(extraClass?.includes("mtp") || extraClass?.includes("cpo_operational")) && idx !== row.cells.length - 1
                              ? "border_right"
                              : ""
                            } 
                          ${extraClass?.includes("mtp") && !isChildComponent
                              ? " bold_row"
                              : (extraClass?.includes("media_mix") || extraClass?.includes("cpo_operational_table")) &&
                                index == rows.length - 1
                                ? "bold_row"
                                : ""
                            }
                          ${cell.column.id?.includes("variance") ? "odd" : ""}
                          ${extraClass?.includes("mtp") && cell.value == cell.row.original.fmi_category && !isChildComponent ? "odd" : ""} 
                          ${extraClass?.includes("cpo_operational") && cell.row.original.dealer == cell?.row?.original?.outlet
                              ? "odd"
                              : "even"
                            } 
                          
                          ${extraClass?.includes("strategic") && cell.column.id?.includes("model") ? rowClass : ""}
                          ${cell.column.Header}`.trim()}
                          key={cellKey}
                        >
                          {cell.render("Cell")}
                          {extraClass?.includes("mediaMix") && (cell.column.id?.includes("share0") || cell.column.id?.includes("share1"))
                            ? cell.value
                              ? "%"
                              : "0%"
                            : ""}
                        </td>
                      );
                  })}
                </tr>
                {/* @ts-ignore */}
                {row.isExpanded ? (
                  <tr className={`${extraClass?.includes("mtp") || extraClass?.includes("cpo_operational") ? "even" : rowClass}`}>
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent && renderRowSubComponent({ row })}</td>
                  </tr>
                ) : null}
              </Fragment>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()} key={group.getFooterGroupProps().key}>
              {group.headers.map((column) => (
                <td {...column.getFooterProps()} key={column.getFooterProps().key}>
                  {column.render("Footer")}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      {showPagination && (
        <div className="pagination">
          <div>
            Page {state.pageIndex + 1} of {pageCount}
          </div>
          {state.pageIndex !== 0 ? (
            <div onClick={() => previousPage()}>
              <ChevronLeftIcon />
            </div>
          ) : null}
          {state.pageIndex + 1 !== pageCount ? (
            <div onClick={() => nextPage()}>
              <ChevronRightIcon />
            </div>
          ) : null}
        </div>
      )}
    </ErrorBoundary>
  );
};

const useRowSpan = (instance: any) => {
  const { allColumns } = instance;
  let rowSpanHeaders: any = [];

  allColumns.forEach((column: any) => {
    const { id, enableRowSpan } = column;

    if (enableRowSpan) {
      rowSpanHeaders = [...rowSpanHeaders, { id, topCellValue: null, topCellIndex: 0 }];
    }

    Object.assign(instance, { rowSpanHeaders });
  });
};

export default DefaultTable;
