export type powerBIUrlKeys =
  | "beta_car_configurator"
  | "online_car_configurator"
  | "cedar_lead_management_uat"
  | "mtm_metrics"
  | "online_to_physical"
  | "data_quality"
  | "decibel"
  | "decibel_october"
  | "new_beta_car_configurator"
  | "qq_configuration"
  | "configurator_g5"
  | "configurator_nibu";

type IPowerBiUrlMapping = Record<powerBIUrlKeys, string>;

export const POWER_BI_URL_MAPPING: IPowerBiUrlMapping = {
  new_beta_car_configurator: "jp_configurations",
  beta_car_configurator: "cedar_car_configurator_test",
  online_car_configurator: "cedar_car_configurator_prod",
  cedar_lead_management_uat: "cedar_lead_management_uat",
  mtm_metrics: "cedar_precision_marketing_dashboard_new",
  online_to_physical: "online_to_physical",
  data_quality: "data_quality",
  decibel: "decibel",
  decibel_october: "decibel_october",
  qq_configuration: "car_config_qashqai",
  configurator_g5: "car_config_new_qashqai",
  configurator_nibu: "nibu_configurations",
};

export const PURCHASE_FUNNEL_REPORTS_MAPPING: { [key: string]: string } = {
  Japan: "purchase_funnel_japan",
  Mexico: "purchase_funnel_mexico",
  China: "china_purchase_funnel",
  Global: "global_purchase_funnel",
  Canada: "canada_purchase_funnel",
  "United States": "cedar_lead_management_prod",
  "United Kingdom": "uk_purchase_funnel",
  Brazil: "brazil_purchase_funnel",
};
