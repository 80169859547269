import React from "react";
import "../../../../assets/styles/component/disByMarketCurrentTable.scss";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";

interface Props {
  data: Array<Record<string, number | string>>;
  showWebsiteVisits: boolean;
  isLoading: boolean;
  dateLabel: string;
}

const DisByMarketCurrentTable = (props: Props) => {
  const { data, showWebsiteVisits, isLoading, dateLabel } = props;

  return isLoading ? (
    <LoadingEllipsis isLoading={isLoading} />
  ) : (
    <table className="dis-table dis-by-market-current-table">
      <tbody>
        <tr>
          <td className="performance-header bold">{dateLabel}</td>
          {showWebsiteVisits && (
            <td className="visits ">
              <p className="bold">Visits</p>
              <p className="bold sub-text">(to Nissan Tier 1 website)</p>
            </td>
          )}
          <td className="visits-to-lead bold">Visits to Lead Conversion</td>
          <td className="tracked-leads bold">
            <p className="bold">Tracked Leads</p>
            <p className="bold sub-text">(from Nissan OEM channels)</p>
          </td>
          <td className="oem-leads bold">OEM Leads to DIS Conversion</td>
          <td className="total-digital-leads bold">TTL Digital Leads</td>
          <td className="total-dis bold">
            <p className="bold">TTL Digitally Influenced Sales</p> <p className="bold sub-text">(from all digital channels)</p>
          </td>
          <td className="private-sales bold">Total Private Sales</td>
          <td className="dis bold">
            <p className="bold">DIS %</p> <p className="bold sub-text">(from all digital channels)</p>
          </td>
        </tr>
        {data.map((dataItem) => (
          <>
            <tr key={dataItem.market} className="performance-row">
              <td className="performance-header bold">Performance</td>
              {showWebsiteVisits && <td className="visits">{dataItem.visits?.toLocaleString()}</td>}
              <td className="visits-to-lead">
                {dataItem.visits_to_lead}
                {`${dataItem.visits_to_lead ? "%" : ""}`}
              </td>
              <td className="tracked-leads">{dataItem.tracked_leads?.toLocaleString()}</td>
              <td className="oem-leads">
                {dataItem.oem_leads_to_dis}
                {`${dataItem.oem_leads_to_dis ? "%" : ""}`}
              </td>
              <td className="total-digital-leads">{dataItem.ttl_digital_leads?.toLocaleString()}</td>
              <td className="total-dis">{dataItem.ttl_digitally_influenced_sales?.toLocaleString()}</td>
              <td className="private-sales">{dataItem.private_sales?.toLocaleString()}</td>
              <td className="dis" rowSpan={2}>
                <div className="dis-data">
                  <span className="bold">
                    DIS: {dataItem.dis_perc}
                    {`${dataItem.dis_perc ? "%" : ""}`}
                  </span>
                  <span
                    className={`${
                      (dataItem.dis_perc_yoy as number) == 0 ? "textGray" : (dataItem.dis_perc_yoy as number) > 0 ? "textGreen" : "textRed"
                    }`}
                  >
                    {(dataItem.dis_perc_yoy as number) > 0 ? "+" : ""}
                    {dataItem.dis_perc_yoy}
                    {`${dataItem.dis_perc_yoy ? "PTS" : ""}`} vs LY
                  </span>
                  <span
                    className={`${
                      dataItem.dis_perc_vs_bp == 0 ? "textGray" : (dataItem.dis_perc_vs_bp as number) > 0 ? "textGreen" : "textRed"
                    }`}
                  >
                    {(dataItem.dis_perc_vs_bp as number) > 0 ? "+" : ""}
                    {dataItem.dis_perc_vs_bp}
                    {`${dataItem.dis_perc_vs_bp ? "PTS" : ""}`} vs BP
                  </span>
                </div>
              </td>
            </tr>
            <tr key={dataItem.market} className="yoy-row">
              <td className="performance-header bold">YoY Δ</td>
              {showWebsiteVisits && (
                <td
                  className={`visits ${
                    dataItem.visits_yoy == 0
                      ? "bg-gray textGray"
                      : (dataItem.visits_yoy as number) > 0
                      ? "bg-green textGreen"
                      : "bg-red textRed"
                  }`}
                >
                  {dataItem.visits_yoy}
                  {`${dataItem.visits_to_lead ? "%" : ""}`}
                </td>
              )}
              <td
                className={`visits-to-lead ${
                  dataItem.visits_to_lead_yoy == 0
                    ? "bg-gray textGray"
                    : (dataItem.visits_to_lead_yoy as number) > 0
                    ? "bg-green textGreen"
                    : "bg-red textRed"
                }`}
              >
                {dataItem.visits_to_lead_yoy}
                {`${dataItem.visits_to_lead_yoy ? "PTS" : ""}`}
              </td>
              <td
                className={`tracked-leads ${
                  dataItem.tracked_leads_yoy == 0
                    ? "bg-gray textGray"
                    : (dataItem.tracked_leads_yoy as number) > 0
                    ? "bg-green textGreen"
                    : "bg-red textRed"
                }`}
              >
                {dataItem.tracked_leads_yoy}
                {`${dataItem.tracked_leads_yoy ? "%" : ""}`}
              </td>
              <td
                className={`oem-leads ${
                  dataItem.oem_leads_to_dis_yoy == 0
                    ? "bg-gray textGray"
                    : (dataItem.oem_leads_to_dis_yoy as number) > 0
                    ? "bg-green textGreen"
                    : "bg-red textRed"
                }`}
              >
                {dataItem.oem_leads_to_dis_yoy}
                {`${dataItem.oem_leads_to_dis_yoy ? "PTS" : ""}`}
              </td>
              <td
                className={`total-digital-leads ${
                  dataItem.ttl_digital_leads_yoy == 0
                    ? "bg-gray textGray"
                    : (dataItem.ttl_digital_leads_yoy as number) > 0
                    ? "bg-green textGreen"
                    : "bg-red textRed"
                }`}
              >
                {dataItem.ttl_digital_leads_yoy}
                {`${dataItem.ttl_digital_leads_yoy ? "%" : ""}`}
              </td>
              <td
                className={`total-dis ${
                  dataItem.ttl_digitally_influenced_sales_yoy == 0
                    ? "bg-gray textGray"
                    : (dataItem.ttl_digitally_influenced_sales_yoy as number) > 0
                    ? "bg-green textGreen"
                    : "bg-red textRed"
                }`}
              >
                {dataItem.ttl_digitally_influenced_sales_yoy}
                {`${dataItem.ttl_digitally_influenced_sales_yoy ? "%" : ""}`}
              </td>
              <td
                className={`private-sales ${
                  dataItem.private_sales_yoy == 0
                    ? "bg-gray textGray"
                    : (dataItem.private_sales_yoy as number) > 0
                    ? "bg-green textGreen"
                    : "bg-red textRed"
                }`}
              >
                {dataItem.private_sales_yoy}
                {`${dataItem.private_sales_yoy ? "%" : ""}`}
              </td>
            </tr>
          </>
        ))}
      </tbody>
    </table>
  );
};

export default DisByMarketCurrentTable;
