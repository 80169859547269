import React from "react";
import "../../assets/styles/component/customProgressBar.scss";

interface Props {
  value: number;
}
export const CustomProgressBar = (props: Props): JSX.Element => {
  const { value } = props;

  return (
    <div className="custom_progress_container">
      <span className="custom_progress_bar" style={{ width: `${value}%` }}></span>
    </div>
  );
};
