import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { ErrorBoundary } from "@sentry/react";
import _ from "lodash";
import React, { Component, Fragment } from "react";
import { BLACK, LIGHT_GREY, WHITE } from "../../../constants";
import { ThemeContext } from "../../../context";
import { configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { generateChartColors } from "./subs/colors";
import { SeriesInterface, TrendChartInterface } from "./subs/types";

export class ImpressionsChart extends Component<TrendChartInterface> {
  static contextType = ThemeContext;

  chart: any;

  componentDidMount() {
    this.initChart();
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    //Desturcture props and define colors to use on the charts
    const { chartName, legendLabels, data, disabled, minDistance, weekCommence, dateRange, theme } = this.props;
    const { white, green, purple } = generateChartColors(theme, disabled);

    this.chart = am4core.create(chartName, am4charts.XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;

    //Default x axis to show current date
    const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.labels.template.fill = disabled
      ? am4core.color(LIGHT_GREY)
      : theme === "light"
      ? am4core.color(BLACK)
      : am4core.color(WHITE);
    dateAxis.renderer.grid.template.strokeWidth = 0;
    dateAxis.periodChangeDateFormats.setKey("month", "MMM");
    dateAxis.renderer.minGridDistance = minDistance;
    dateAxis.endLocation = 0.5;
    dateAxis.startLocation = 0;
    if (dateRange.includes("month") && data && data?.length > 1) {
      const { popDate } = data[data.length - 1];
      const dateValue = popDate?.split("-");
      if (dateValue) dateAxis.max = new Date(parseInt(dateValue[0]), parseInt(dateValue[1]), parseInt(dateValue[2])).getTime();
    }

    //X axis used to map the pop dates but it's disabled by default as we only want to show the current date by default
    const dateAxis2 = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis2.renderer.labels.template.disabled = true;
    dateAxis2.renderer.grid.template.strokeWidth = 0;
    dateAxis2.periodChangeDateFormats.setKey("month", "MMM");
    dateAxis2.renderer.minGridDistance = minDistance;
    dateAxis2.endLocation = 0.5;
    dateAxis2.startLocation = 0;

    dateAxis2.syncWithAxis = dateAxis;

    //Y Axis that is used by default
    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    configureValueAxis(valueAxis, theme, disabled);
    valueAxis.renderer.grid.template.strokeWidth = 0;
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#a";

    //Y Axis that is displayed on the opposite to show clicks value when we have both clicks and impressions on the chart
    const valueAxis2 = this.chart.yAxes.push(new am4charts.ValueAxis());
    configureValueAxis(valueAxis2, theme, disabled);
    valueAxis2.renderer.grid.template.strokeWidth = 0;
    valueAxis2.renderer.labels.template.disabled = this.props.showImpressions && this.props.showClicks ? false : true;
    valueAxis2.renderer.opposite = true;

    //Function to create the series using the arguments given
    const createSeries = ({ name, field, color, hidden, dateValue, xAxisValue, yAxisValue, showDashedLine }: SeriesInterface) => {
      const series = this.chart.series.push(new am4charts.LineSeries());
      series.name = name;
      series.dataFields.dateX = dateValue;
      series.dataFields.valueY = field;
      series.tooltipText = "{dateX}: [b]{valueY}[/]";
      series.xAxis = xAxisValue;
      series.yAxis = yAxisValue;
      series.strokeWidth = 2;
      series.fill = am4core.color(color);
      series.stroke = am4core.color(color);
      series.hidden = hidden;
      series.hiddenInLegend = hidden;
      series.strokeDasharray = showDashedLine ? "2,4" : "";

      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.tooltipText = `[font-size: var(--regular_font_size)]${weekCommence} {dateX.formatDate('EEEE')} - {dateX.formatDate("d/M/yyyy")} : [bold font-size: var(--regular_font_size)]{valueY.formatNumber('#,###')}[/]`;
      bullet.circle.radius = 2;

      return series;
    };
    //Series to display comparison/pop impressions. By default it's always displayed
    //Since its pop data we use the date axis that is disabled
    createSeries({
      name: legendLabels?.previous,
      field: "popValue",
      color: white,
      dateValue: "popDate",
      xAxisValue: dateAxis2,
      yAxisValue: valueAxis,
      hidden: this.props.showClicks ? true : false,
    });

    //Series to display the current impressions. By default it's always displayed
    createSeries({
      name: this.props.showClicks ? "Impressions" : legendLabels?.current,
      field: "currentValue",
      color: purple,
      dateValue: "row_date",
      xAxisValue: dateAxis,
      yAxisValue: valueAxis,
      hidden: !this.props.showImpressions,
    });

    //Series to display pop Clicks data
    createSeries({
      name: legendLabels?.previous,
      field: "popClicks",
      color: white,
      dateValue: "popDate",
      xAxisValue: dateAxis2,
      yAxisValue: valueAxis,
      hidden: !this.props.showImpressions && this.props.showClicks ? false : true,
    });

    //Series to display current Clicks data
    createSeries({
      name: !this.props.showImpressions && this.props.showClicks ? legendLabels?.current : "Clicks",
      field: "currentClicks",
      color: green,
      dateValue: "row_date",
      xAxisValue: dateAxis,
      yAxisValue: this.props.showClicks && this.props.showImpressions ? valueAxis2 : valueAxis,
      hidden: !this.props.showClicks,
    });

    //Add legends
    this.chart.legend = new am4charts.Legend();
    this.chart.legend.position = "top";
    this.chart.legend.useDefaultMarker = true;
    this.chart.legend.labels.template.fill = theme === "light" ? am4core.color("#000000") : am4core.color(white);

    const marker = this.chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(0, 0, 0, 0);
    marker.strokeWidth = 0.5;

    const markerTemplate = this.chart.legend.markers.template;
    markerTemplate.width = 15;
    markerTemplate.height = 15;

    this.chart.zoomOutButton.disabled = true;
  }

  render() {
    return (
      <ErrorBoundary>
        <Fragment>
          <div id={this.props.chartName}></div>
        </Fragment>
      </ErrorBoundary>
    );
  }
}
