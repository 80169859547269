import { SET_ACTIVATION_RATIO_DATA } from "../actions/actionTypes/activationRatioTypes";

const initialState = {
  activation_ratio: {},
};

const activationRatioReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_ACTIVATION_RATIO_DATA:
      return { ...state, activation_ratio: action.payload };

    default:
      return state;
  }
};

export default activationRatioReducer;
