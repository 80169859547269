import { Box, Button, makeStyles } from "@material-ui/core";
import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { deleteReleaseNote, getReleaseNotes } from "../../../api/adminApi";
import { ErrorMsg } from "../../../components/AppMessages";
import { CYAN } from "../../../constants";
import { IReleaseNote } from "../../../constants/interface/admin/releaseNotes";
import UserSearchField from "../common/Fields/UserSearchField";
import { CreateUpdateDialogue } from "./subs/Dialogs/CreateUpdateDialogue";
import { DeleteDialogue } from "./subs/Dialogs/DeleteDialogue";
import { ReleaseTable } from "./subs/ReleaseTable";

export const AdminReleaseNotes = () => {
  const [deleteDialogueOpen, setDeleteDialogueOpen] = useState<boolean>(false);
  const [formDialogueOpen, setFormDialogueOpen] = useState<boolean>(false);
  const [selectedReleaseNote, setSelectedReleaseNote] = useState<IReleaseNote | undefined>(undefined);
  const [releaseNotes, setReleaseNotes] = useState<Array<IReleaseNote>>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const classes = makeStyles({
    button: {
      backgroundColor: CYAN,
      color: "#FFF !important",
      borderRadius: "0px !important",
      border: "none !important",
      marginRight: "10px",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      "&:hover": {
        backgroundColor: "#5ed2ff !important",
        border: "none !important",
      },
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  })();

  useEffect(() => {
    getReleaseNotes().then((response) => {
      if (response && !("error" in response)) setReleaseNotes(response);
    });
  }, []);

  const handleToggleDeleteDialog = () => setDeleteDialogueOpen(!deleteDialogueOpen);

  const handleToggleFlashFormDialogue = useCallback(() => {
    setFormDialogueOpen((prevState) => !prevState);
    if (formDialogueOpen) {
      setSelectedReleaseNote(undefined);
    }
  }, [formDialogueOpen]);

  const handleDeleteReleaseNote = (id: number) => {
    deleteReleaseNote(id).then((response) => {
      if (response && response?.id) {
        setReleaseNotes((prevState) => prevState.filter((msg) => msg.id !== id));
        setSelectedReleaseNote(undefined);
        handleToggleDeleteDialog();
      } else {
        toast.error("Failed to delete release note");
      }
    });
  };

  const filteredReleaseNotes = useMemo(() => {
    return releaseNotes.filter((note) => note.version_number.includes(searchValue));
  }, [releaseNotes, searchValue]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <Box component="div">
        <Box className={classes.buttonsContainer} component="div" mb={3}>
          <Box component="div">
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={() => {
                setSelectedReleaseNote(undefined);
                handleToggleFlashFormDialogue();
              }}
              data-test-id="btn-add-new-user"
            >
              New
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToggleFlashFormDialogue}
              disabled={!selectedReleaseNote}
              data-test-id="btn-edit-user"
            >
              Edit
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToggleDeleteDialog}
              disabled={!selectedReleaseNote}
              data-test-id="btn-delete-user"
            >
              Delete
            </Button>
          </Box>
          <Box component="div">
            <UserSearchField value={searchValue} setValue={setSearchValue} />
          </Box>
        </Box>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <ReleaseTable
            data={filteredReleaseNotes}
            selectedReleaseNote={selectedReleaseNote}
            setSelectedReleaseNote={setSelectedReleaseNote}
            setDeleteDialogOpen={setDeleteDialogueOpen}
          />
        </ErrorBoundary>

        {deleteDialogueOpen && selectedReleaseNote && (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <DeleteDialogue
              releaseNote={selectedReleaseNote}
              open={deleteDialogueOpen}
              handleClose={handleToggleDeleteDialog}
              handleDelete={handleDeleteReleaseNote}
            />
          </ErrorBoundary>
        )}

        {formDialogueOpen && (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <CreateUpdateDialogue
              selectedReleaseNote={selectedReleaseNote}
              open={formDialogueOpen}
              toggleOpen={handleToggleFlashFormDialogue}
              setReleaseNotes={setReleaseNotes}
            />
          </ErrorBoundary>
        )}
      </Box>
    </ErrorBoundary>
  );
};
