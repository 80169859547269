import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import {
  getSearchInterestSummary,
  getSearchInterestTable,
  getSearchInterestTrend,
} from "../../../../api/JourneyExcellenceMarketPerformanceSummary";
import { formatToThreeDigits } from "../../../../utils/numberFormatting";
import { format, parse } from "date-fns";
import { getValueClass, plusSignNumbers } from "../../../../utils/utilityFunctions";
import MarketPerformanceSummaryLineChart from "../../../../components/Charts/MarketPerformanceSummary/MarketPerformanceSummaryLineChart";
import { ThemeContext } from "../../../../context";
import { GRADE_MIX_COLORS, MEDIA_MIX_CHART_COLORS } from "../../../../constants";
import MarketPerformanceSummaryTable from "./MarketPerformanceSummaryTable";
import { ToggleSwitch } from "../../../../components/Toogle";

const SearchInterestBrandAndModel = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const [brandAndCoreModelActive, setBrandAndCoreModelActive] = useState(true);

  const {
    data: searchInterestSummaryData,
    isFetching: isFetchingSearchInterestSummary,
    refetch: refetchSearchInterestSummary,
  } = useQuery({
    queryKey: ["searchInterestSummary", history.location.search],
    queryFn: getSearchInterestSummary,
    enabled: false,
  });

  const {
    data: searchInterestTrendData,
    isFetching: isFetchingSearchInterestTrend,
    refetch: refetchSearchInterestTrend,
  } = useQuery({
    queryKey: ["searchInterestTrend", brandAndCoreModelActive, history.location.search],
    queryFn: () => getSearchInterestTrend(brandAndCoreModelActive),
    enabled: false,
  });

  const {
    data: searchInterestTableData,
    isFetching: isFetchingSearchInterestTable,
    refetch: refetchSearchInterestTable,
  } = useQuery({
    queryKey: ["searchInterestTable", brandAndCoreModelActive, history.location.search],
    queryFn: () => getSearchInterestTable(brandAndCoreModelActive),
    enabled: false,
  });

  useEffect(() => {
    refetchSearchInterestSummary();
  }, [history.location.search]);

  useEffect(() => {
    refetchSearchInterestTrend();
    refetchSearchInterestTable();
  }, [history.location.search, brandAndCoreModelActive]);

  const seriesColors = useMemo(() => {
    if (searchInterestTrendData?.series) {
      const res: any = {};
      searchInterestTrendData?.series?.map((seriesItem: any, idx: number) => {
        // if the series name is nissan assign it color #C3002F.
        if (seriesItem.name === "Nissan") {
          res[seriesItem.name] = "#C3002F";
        } else {
          res[seriesItem.name] = GRADE_MIX_COLORS[idx];
        }
      });
      return res;
    }
    return {};
  }, [searchInterestTrendData]);

  return (
    <div className="tile">
      <div className="tile-header">
        <div className="title">
          <p>Search Interest: Brand & Model</p>
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            html={
              <div>
                <p>
                  Search interest volume corresponds to the number of exact searches for the keyword &quot;Nissan&quot; (or a particular
                  model if selected in the filter) within a given month
                </p>
                <p>Source: Google Search Console</p>
              </div>
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <ToggleSwitch
          inactiveToggleLabel="Brand & competitors"
          activeToggleLabel="Brand & core model"
          active={brandAndCoreModelActive}
          handleToggleClick={() => setBrandAndCoreModelActive((prevState) => !prevState)}
        />
      </div>
      <div className="tile-content">
        <div className="summary search-interest-summary-table">
          <div className="column">
            {searchInterestSummaryData?.current_date
              ? `${format(
                new Date(searchInterestSummaryData?.current_date),
                "MMM"
              )}: ${searchInterestSummaryData?.current?.toLocaleString()}`
              : "n/a"}
          </div>
          <div className="column">
            MoM:{" "}
            {searchInterestSummaryData?.mom ? (
              <span className={`${getValueClass(searchInterestSummaryData.mom)}`}>{plusSignNumbers(searchInterestSummaryData.mom)}%</span>
            ) : (
              "n/a"
            )}
          </div>
          <div className="column">
            YoY:{" "}
            {searchInterestSummaryData?.yoy ? (
              <span className={`${getValueClass(searchInterestSummaryData.yoy)}`}>{plusSignNumbers(searchInterestSummaryData.yoy)}%</span>
            ) : (
              "n/a"
            )}
          </div>
        </div>
      </div>
      <div className="chart-table-container">
        <MarketPerformanceSummaryLineChart
          chartId="searchInterestBrandAndModelChart"
          data={searchInterestTrendData?.data ?? []}
          isLoading={isFetchingSearchInterestTrend}
          theme={theme}
          seriesList={searchInterestTrendData?.series ?? []}
          seriesColors={seriesColors}
        />
        <MarketPerformanceSummaryTable
          data={searchInterestTableData ?? []}
          columnHeaders={["", "Volume", "YoY"]}
          columnDataKeys={[
            { key: "category", type: "string" },
            { key: "search_volume", type: "number" },
            { key: "yoy", type: "percentage" },
          ]}
        />
      </div>
    </div>
  );
});

export default SearchInterestBrandAndModel;
