import { Button } from "@material-ui/core";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { isNull } from "../../../utils/utilityFunctions";
import { Breadcrumb } from "../../Breadcrumb/Breadcrumb";
import { closeFilterOptions } from "../subs/helpers";

type checkboxState = { value: string; checked: boolean };

type listTypes = "market" | "region" | "territory" | "area" | "district";

interface Props {
  onShowFilterOptions: any;
  availableMarkets: {
    area: any;
    district: any;
    market: string;
    region: string;
    territory: string[];
  }[];
}

export const UsedCarsGeoFilter = (props: Props) => {
  const { onShowFilterOptions, availableMarkets } = props;

  const dispatch = useDispatch();
  const marketParam = useSelector((state: RootStateOrAny) => state.parameters.market);

  const [activeListState, setActiveList] = useState<listTypes>("market");
  const [selectionValue, setSelectionValue] = useState<string>("");

  const [markets, setMarkets] = useState<Array<checkboxState>>([]);
  const [regions, setRegions] = useState<Array<checkboxState>>([]);
  const [territories, setTerritories] = useState<Array<checkboxState>>([]);
  const [areas, setAreas] = useState<Array<checkboxState>>([]);
  const [districts, setDistricts] = useState<Array<checkboxState>>([]);

  useEffect(() => {
    setSelectionValue(marketParam);
  }, [marketParam]);

  //update markets
  useEffect(() => {
    setMarkets(getCheckedSubList(availableMarkets, "market"));
  }, [availableMarkets, marketParam]);

  //update regions and territories when selected market changes
  useEffect(() => {
    const checkedMarket = markets?.filter((val) => val.checked)?.map((val) => val.value)[0];
    const geos = availableMarkets?.filter((val) => val?.market == checkedMarket);
    const hasSomeRegions = geos?.some((val) => !isNull(val?.region));
    setRegions([]);
    setTerritories([]);
    setAreas([]);
    setDistricts([]);
    if (geos?.length && hasSomeRegions) {
      setRegions(getCheckedSubList(geos, "region"));
    } else {
      setTerritories(getCheckedSubList(geos, "territory"));
    }
  }, [markets, availableMarkets]);

  //update territory or area when selected market or region changes
  useEffect(() => {
    const checkedMarket = markets?.filter((val) => val.checked)?.map((val) => val.value)[0];
    const checkedRegions = regions?.filter((val) => val.checked)?.map((val) => val.value);
    const geos = availableMarkets?.filter((val) => val?.market == checkedMarket && checkedRegions?.includes(val?.region));
    if (geos?.length == 0) {
      setAreas([]);
      setDistricts([]);
    }

    const hasSomeAreas = geos?.some((val) => !isNull(val?.area));
    if (geos?.length && hasSomeAreas) {
      setAreas(getCheckedSubList(geos, "area"));
    } else {
      setTerritories(getCheckedSubList(geos, "territory"));
    }
  }, [markets, regions]);

  //update district when selected market, region 0r area changes
  useEffect(() => {
    const checkedMarket = markets?.filter((val) => val.checked)?.map((val) => val.value)[0];
    const checkedRegions = regions?.filter((val) => val.checked)?.map((val) => val.value);
    const checkedAreas = areas?.filter((val) => val.checked)?.map((val) => val.value);

    const geos = availableMarkets?.filter(
      (val) => val?.market == checkedMarket && checkedRegions?.includes(val?.region) && checkedAreas?.includes(val?.area)
    );

    const hasSomeDistricts = geos?.some((val) => !isNull(val?.district));
    if (geos?.length && hasSomeDistricts) {
      setDistricts(getCheckedSubList(geos, "district"));
    } else {
      setDistricts([]);
    }
  }, [markets, regions, areas]);

  //set default market
  useEffect(() => {
    const markets = availableMarkets?.map((market) => market.market);
    if (markets?.length && !markets?.includes(marketParam)) dispatch(setSpecificParameter("market", markets[0]));
  }, [marketParam, availableMarkets]);

  const getCheckedSubList = (
    geosForMarket: {
      area: any;
      district?: any;
      market?: string;
      region?: string;
      territory?: string[];
    }[],
    key: listTypes
  ) => {
    const list: string[] = [];
    geosForMarket?.map((val) => {
      if (val[`${key}`]) {
        if (key == "district" || key == "territory") {
          list.push(...val[`${key}`]);
        } else {
          list.push(val[`${key}`]);
        }
      }
    });
    const uniqueItems = Array.from(new Set(list))?.sort((a, b) => a.localeCompare(b));
    return key == "market"
      ? uniqueItems?.map((val) => ({ value: val, checked: val == marketParam }))
      : [{ value: ALL_OPTION_NO_SPACE, checked: true }, ...uniqueItems.map((val) => ({ value: val, checked: true }))];
  };

  const showAllOptions = useCallback(
    (listType?: listTypes) => {
      if (listType === "region") setRegions((prevRegion) => prevRegion.map((region) => ({ ...region, checked: true })));
      if (listType === "territory") setTerritories((prevRegion) => prevRegion.map((region) => ({ ...region, checked: true })));
      if (listType === "area") setAreas((prevRegion) => prevRegion.map((region) => ({ ...region, checked: true })));
      if (listType === "district") setDistricts((prevRegion) => prevRegion.map((region) => ({ ...region, checked: true })));
    },
    [regions, territories, areas, districts]
  );

  const resetSelection = useCallback(
    (listType?: listTypes) => {
      const updatedRegions = regions.map((region) => ({ ...region, checked: false }));
      const updatedTerritory = territories.map((region) => ({ ...region, checked: false }));
      const updatedArea = areas.map((region) => ({ ...region, checked: false }));
      const updatedDistrict = districts.map((region) => ({ ...region, checked: false }));
      if (!listType) {
        setRegions(updatedRegions);
        setTerritories(updatedTerritory);
        setAreas(updatedArea);
        setDistricts(updatedDistrict);
      } else {
        if (listType === "region") setRegions(updatedRegions);
        if (listType === "territory") setTerritories(updatedTerritory);
        if (listType === "area") setAreas(updatedArea);
        if (listType === "district") setDistricts(updatedDistrict);
      }
    },
    [regions, territories, areas, districts]
  );

  const breadcrumbSelection = useCallback(
    () => (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const breadcrumbValue = dataset?.value;
      setActiveList(breadcrumbValue as listTypes);
    },
    [setActiveList]
  );

  const handleCheckboxClick = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value: checkboxValue, checked: isChecked, dataset },
      } = evt;

      // If All is deselected, clear all selections
      if (checkboxValue === ALL_OPTION_NO_SPACE && (dataset?.listType as listTypes) !== "market") {
        if (!isChecked) {
          resetSelection(dataset?.listType as listTypes);
        } else {
          showAllOptions(dataset?.listType as listTypes);
        }
        return;
      }

      const listType = dataset?.listType as listTypes;
      if (listType == "market" && !isChecked) return;

      const listCopy =
        listType == "market"
          ? [...markets]
          : listType == "region"
          ? [...regions]
          : listType == "area"
          ? [...areas]
          : listType == "district"
          ? [...districts]
          : [...territories];
      const index = Number(dataset.key);
      listCopy[index] = { value: checkboxValue, checked: isChecked };
      if (listType == "market") {
        const updatedList = listCopy?.map((val) => ({ value: val.value, checked: val.value == checkboxValue }));
        setMarkets(updatedList);
      } else {
        const listWithoutAll = listCopy?.filter((val) => val?.value !== ALL_OPTION_NO_SPACE);
        const allChecked = listWithoutAll?.some((val) => !val.checked);
        const updatedList = [{ value: ALL_OPTION_NO_SPACE, checked: allChecked }, ...listWithoutAll];
        listType == "region"
          ? setRegions(updatedList)
          : listType == "area"
          ? setAreas(updatedList)
          : listType == "district"
          ? setDistricts(updatedList)
          : setTerritories(updatedList);
      }
    },
    [markets, regions, territories, areas, districts]
  );

  const handleOnlyBtnClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const listType = dataset.listtype as listTypes;

      const value = dataset.value;
      const listCopy =
        listType == "region" ? [...regions] : listType == "territory" ? [...territories] : listType == "area" ? [...areas] : [...districts];
      const updatedList = listCopy.map((val) => ({ ...val, checked: val.value == value }));

      listType == "region"
        ? setRegions(updatedList)
        : listType == "territory"
        ? setTerritories(updatedList)
        : listType == "area"
        ? setAreas(updatedList)
        : setDistricts(updatedList);
    },
    [markets, regions, territories, areas, districts]
  );

  const handleSubmission = useCallback(
    (evt) => {
      evt.preventDefault();

      if (selectionValue === "") return;
      const selectedMarkets = markets?.filter((market) => market.checked)?.map((market) => market.value);

      const checkedRegions: string = regions
        ?.filter((val) => val.checked)
        ?.map((val) => val.value)
        ?.toString();

      const checkedTerritories: string = territories
        ?.filter((val) => val.checked)
        ?.map((val) => val.value)
        ?.toString();

      const checkedAreas: string = areas
        ?.filter((val) => val.checked)
        ?.map((val) => val.value)
        ?.toString();

      const checkedDistricts: string = districts
        ?.filter((val) => val.checked)
        ?.map((val) => val.value)
        ?.toString();

      dispatch(setSpecificParameter("market", selectedMarkets?.toString()));
      dispatch(setSpecificParameter("region", checkedRegions?.length == 0 ? ALL_OPTION_NO_SPACE : checkedRegions));
      dispatch(setSpecificParameter("territory", checkedTerritories?.length == 0 ? ALL_OPTION_NO_SPACE : checkedTerritories));
      dispatch(setSpecificParameter("area", checkedAreas?.length == 0 ? ALL_OPTION_NO_SPACE : checkedAreas));
      dispatch(setSpecificParameter("district", checkedDistricts?.length == 0 ? ALL_OPTION_NO_SPACE : checkedDistricts));

      eventTracking(MixpanelEvents.filter_change, { filter: "market", value: selectedMarkets, dashboard: "Used Cars", page: "Used Cars" });

      closeFilterOptions();
    },
    [markets, regions, territories, selectionValue, areas, districts]
  );

  const renderList = (list: Array<checkboxState>, listType: listTypes) => {
    return list?.map(({ value, checked }, index) => (
      <div className={"multiselect_container geography_list"} key={value}>
        <input
          type="checkbox"
          className={`multiselect-checkbox ${listType}-checkbox geography_checkbox`}
          id={`${listType}_${value?.replace(" ", "_")}`}
          name={`${listType}_${value.replace(" ", "_")}`}
          value={value}
          data-list-type={listType}
          data-key={index}
          checked={checked}
          onChange={handleCheckboxClick}
        />
        <label className="multiselect-label geography_label" htmlFor={`${listType}_${value.replace(" ", "_")}`}>
          {value}
        </label>

        {!/all/gi.test(value) && listType !== "market" && (
          <Button
            onClick={handleOnlyBtnClick}
            className={"geographyOptionBtn"}
            size={"small"}
            variant={"outlined"}
            data-listtype={listType}
            data-value={value}
            data-key={index}
          >
            ONLY
          </Button>
        )}
      </div>
    ));
  };

  return (
    <div className="filter" id="geography_filter_div">
      <label className="filter_header" htmlFor={"region_country_filter"}>
        GEOGRAPHY
      </label>

      <div className="input_arrow" onClick={onShowFilterOptions} data-options-ul={"geography_selector"}>
        <input readOnly type="text" id="region_country_filter" className={"filter_input"} data-test-id="region" value={selectionValue} />
        <span className="arrow_down " />
      </div>

      <div className="filter_options" id="geography_selector" data-test-id="geography_selector">
        <div className="search_div">
          <div className="selection_label hide_on_mobile">
            <p className={"selection_value"}>{selectionValue}</p>
          </div>

          <div className="search_clear_div">
            <div className="clear_btn" onClick={() => resetSelection()}>
              {" "}
              Clear all{" "}
            </div>
          </div>
        </div>
        <div className="breadcrumb_container hide_on_desktop">
          <Breadcrumb
            list={["Market", "Region", "Territory", "Area", "District"]}
            onBreadcrumbClick={breadcrumbSelection()}
            activeValue={activeListState}
          />
        </div>
        <div className="geography_options_title hide_on_mobile">
          {markets?.length && (
            <div className="geography_type">
              <p className="title">Market</p>
            </div>
          )}
          {regions?.length > 1 ? (
            <div className="geography_type">
              <p className="title">Region</p>
            </div>
          ) : null}
          {territories?.length > 1 ? (
            <div className="geography_type">
              <p className="title">Territory</p>
            </div>
          ) : null}

          {areas?.length > 1 ? (
            <div className="geography_type">
              <p className="title">Area</p>
            </div>
          ) : null}
          {districts?.length > 1 ? (
            <div className="geography_type">
              <p className="title">District</p>
            </div>
          ) : null}
        </div>
        <div className="geography_options">
          {markets?.length ? (
            <>
              {/* Models */}
              <div className={`marketList ${activeListState === "market" ? "active" : ""}`} id="model_list" data-test-id={"model_list"}>
                {renderList(markets, "market")}
              </div>
              {/* Region */}
              {regions?.length > 1 ? (
                <div className={`marketList ${activeListState === "region" ? "active" : ""}`} id="region_list" data-test-id={"region_list"}>
                  {renderList(regions, "region")}
                </div>
              ) : null}
              {/* Territory */}
              {territories?.length > 1 ? (
                <div
                  className={`marketList ${activeListState === "territory" ? "active" : ""}`}
                  id="area_list"
                  data-test-id={"territory_list"}
                >
                  {renderList(territories, "territory")}
                </div>
              ) : null}
              {areas?.length > 1 ? (
                <div className={`marketList ${activeListState === "area" ? "active" : ""}`} id="area_list" data-test-id={"area_list"}>
                  {renderList(areas, "area")}
                </div>
              ) : null}
              {districts?.length > 1 ? (
                <div
                  className={`marketList ${activeListState === "district" ? "active" : ""}`}
                  id="district_list"
                  data-test-id={"district_list"}
                >
                  {renderList(districts, "district")}
                </div>
              ) : null}
            </>
          ) : (
            <ul>
              <li className={"error_msg"} style={{ fontSize: "0.9rem" }}>
                No models available for the selected filters.
              </li>
            </ul>
          )}
        </div>
        {/* Apply btn */}
        <div className="geography_options__submit_area">
          <button className="filter_button no_icon" onClick={handleSubmission} data-test-id={"geography_apply_btn"}>
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};
