import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { NoDataMsg } from "../../../../components/AppMessages";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { BLUE, DARK_PURPLE, LIGHT_PURPLE, metricTypes } from "../../../../constants";
import LineBulletSeries from "./LineBullet";
import SpendVsTrafficBreakdown from "./SpendVsTrafficBreakdown";

interface Props {
  metric: metricTypes;
  models: string[];
  modelData: {
    date: string;
    date_yoy: string;
    model: string;
    visits: number | null;
    kbas: number | null;
    leads: number | null;
    visits_yoy: number | null;
    kbas_yoy: number | null;
    leads_yoy: number | null;
    amount: number | null;
  }[];
  metricData: {
    date: string;
    date_yoy: string;
    visits: number | null;
    kbas: number | null;
    leads: number | null;
    visits_yoy: number | null;
    kbas_yoy: number | null;
    leads_yoy: number | null;
    amount: number | null;
  }[];
}

const kpiMapping = {
  visits: "Website visits",
  kbas: "KBA",
  leads: "Leads",
};

const SpendVsTrafficMetric = (props: Props) => {
  const { metric, models, modelData, metricData } = props;

  const model = useSelector((state: RootStateOrAny) => state.parameters.model);
  const market = useSelector((state: RootStateOrAny) => state.parameters.market);
  const marketLoading = useSelector((state: RootStateOrAny) => state.loading.midas_spend_vs_traffic_market_data);
  const modelLoading = useSelector((state: RootStateOrAny) => state.loading.midas_spend_vs_traffic_model_data);

  const metricChartData = useMemo(() => {
    if (metricData?.length) {
      return metricData?.map((value) => {
        const result: any = { amount: value.amount == 0 ? null : value.amount, date: value.date, date_yoy: value.date_yoy };
        result[`metric`] = value[metric];
        result[`metric_yoy`] = value[`${metric}_yoy`];
        return result;
      });
    }
    return metricData;
  }, [metricData, metric]);

  const modelMetricData = useMemo(() => {
    if (modelData?.length) {
      return modelData?.map((value) => {
        const result: any = {
          amount: value.amount == 0 ? null : value.amount,
          date: value.date,
          model: value.model,
          date_yoy: value.date_yoy,
        };
        result[`metric`] = value[metric];
        result[`metric_yoy`] = value[`${metric}_yoy`];
        return result;
      });
    }
    return modelData;
  }, [modelData, metric]);

  const modelsToDisplay = useMemo(() => {
    const filteredModelList = /all/gi.test(model) ? [] : model?.split(",");
    return filteredModelList?.length ? filteredModelList : models;
  }, [models, model, modelData]);

  return (
    <div className={`metric_charts`}>
      <div className="by_market primary_background">
        <div className="header">
          <div style={{ display: "flex", alignItems: "center", marginBottom: "1em" }} className="header_and_tooltip">
            <h3>Market total values</h3>
            <Tooltip
              position="top-end"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={true}
              distance={50}
              delay={1}
              hideDelay={1}
              duration={1}
              size={"small"}
              title={`
              Total Media buy FMI spend and ${kpiMapping[metric]} at Market level <br>
              ${market == "United Arab Emirates" ? "Definition of UAE - Media FMI: NMEF | Online Performance: Abu Dhabi and Dubai" : ""}
              `}
            >
              <span className="info_tooltip" />
            </Tooltip>
          </div>
          <div className="axis_titles">
            <h3>{`Media spend`}</h3>
            <div className="series">
              <LineBulletSeries
                label={`${metric == "visits" ? "Website" : metric == "kbas" ? "KBA" : "Lead"} visits`}
                backgroundColor={DARK_PURPLE}
                isLine
              />
              <LineBulletSeries
                label={`${metric == "visits" ? "Website" : metric == "kbas" ? "KBA" : "Lead"} visits (previous year)`}
                backgroundColor={LIGHT_PURPLE}
                isLine
              />
              <LineBulletSeries label={`Media spend`} backgroundColor={BLUE} />
            </div>
            <h3>{`${metric == "visits" ? "Website" : metric == "kbas" ? "KBA" : "Lead"} Visits`}</h3>
          </div>
        </div>
        <SpendVsTrafficBreakdown loading={marketLoading} data={metricChartData} metric={metric} />
      </div>

      <div className={`by_model ${modelsToDisplay?.length == 0 ? "primary_background" : ""}`}>
        <div className="header_and_legend">
          <div className="header">
            <h3>{`Breakdown by Core Model`}</h3>
            <Tooltip
              position="top-end"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={true}
              // distance={50}
              delay={1}
              hideDelay={1}
              duration={1}
              size={"small"}
              title={`
              Core models will be shown by default. <br>
              ${market == "United Arab Emirates" ? "Definition of UAE - Media FMI: NMEF | Online Performance: Abu Dhabi and Dubai" : ""}
              `}
            >
              <span className="info_tooltip" />
            </Tooltip>
          </div>
          <div className="series">
            <LineBulletSeries
              label={`${metric == "visits" ? "Website" : metric == "kbas" ? "KBA" : "Lead"} visits`}
              backgroundColor={DARK_PURPLE}
              isLine
            />
            <LineBulletSeries
              label={`${metric == "visits" ? "Website" : metric == "kbas" ? "KBA" : "Lead"} visits (previous year)`}
              backgroundColor={LIGHT_PURPLE}
              isLine
            />
            <LineBulletSeries label={`Media spend`} backgroundColor={BLUE} />
          </div>
        </div>
        {modelsToDisplay?.length && !modelLoading ? (
          <div className="model_charts">
            {modelsToDisplay
              ?.sort((a: string, b: string) => a.localeCompare(b))
              ?.map((model: string, idx: number) => (
                <div className="model_tile primary_background" key={`${model}_${idx}`}>
                  <div className="model_title">
                    <h3>{model}</h3>
                  </div>
                  <SpendVsTrafficBreakdown
                    isModelChart={true}
                    model={model}
                    loading={modelLoading}
                    data={modelMetricData}
                    metric={metric}
                  />
                </div>
              ))}
          </div>
        ) : modelLoading ? (
          <LoadingEllipsis isLoading={modelLoading} />
        ) : (
          <NoDataMsg message="No data available" />
        )}
      </div>
    </div>
  );
};

export default SpendVsTrafficMetric;
