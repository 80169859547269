import {
  FETCH_AVERAGE_DAYS_IN_STOCK_TREND_DATA,
  FETCH_AVERAGE_LIST_PRICE_TREND_DATA,
  FETCH_AVERAGE_SALES_PRICE_TREND_DATA,
  FETCH_BASE_TRENDS_DATA,
  FETCH_DEALER_TREND_DATA,
  FETCH_INVENTORY_BY_AGE_DATA,
  FETCH_INVENTORY_RATIO_DATA,
  FETCH_INVENTORY_VOLUME_TREND_DATA,
  FETCH_LAST_WEEK_DATES,
  FETCH_LEAD_SALES_CONVERSION_DATA,
  FETCH_LIST_PRICE_DATA,
  FETCH_PERFORMANCE_PER_UNIT_DATA,
  FETCH_SALES_REVENUE_TREND_DATA,
  FETCH_SALES_VOLUME_TREND_DATA,
  FETCH_TOTAL_VALUES_DATA,
  FETCH_TURNOVER_RATIO_DATA,
  SET_AVERAGE_DAYS_IN_STOCK_TREND_DATA,
  SET_AVERAGE_LIST_PRICE_TREND_DATA,
  SET_AVERAGE_SALES_PRICE_TREND_DATA,
  SET_BASE_TRENDS_DATA,
  SET_DEALER_TREND_DATA,
  SET_INVENTORY_BY_AGE_DATA,
  SET_INVENTORY_RATIO_DATA,
  SET_INVENTORY_VOLUME_TREND_DATA,
  SET_LAST_WEEK_DATES,
  SET_LEAD_SALES_CONVERSION_DATA,
  SET_LIST_PRICE_DATA,
  SET_PERFORMANCE_PER_UNIT_DATA,
  SET_SALES_REVENUE_TREND_DATA,
  SET_SALES_VOLUME_TREND_DATA,
  SET_TOTAL_VALUES_DATA,
  SET_TURNOVER_RATIO_DATA,
} from "./actionTypes";

export const fetchBaseTrendsData = () => ({ type: FETCH_BASE_TRENDS_DATA });
export const setBaseTrendsData = (data: any) => ({ type: SET_BASE_TRENDS_DATA, payload: data });

export const fetchDealerTrendData = () => ({ type: FETCH_DEALER_TREND_DATA });
export const setDealerTrendData = (data: any) => ({ type: SET_DEALER_TREND_DATA, payload: data });

export const fetchInventoryByAgeData = () => ({ type: FETCH_INVENTORY_BY_AGE_DATA });
export const setInventoryByAgeData = (data: any) => ({ type: SET_INVENTORY_BY_AGE_DATA, payload: data });

export const fetchPerformancePerUnitData = () => ({ type: FETCH_PERFORMANCE_PER_UNIT_DATA });
export const setPerformancePerUnitData = (data: any) => ({ type: SET_PERFORMANCE_PER_UNIT_DATA, payload: data });

// NEW METRICS BREAKDOWN
export const fetchTotalValuesData = () => ({ type: FETCH_TOTAL_VALUES_DATA });
export const setTotalValuesData = (data: any) => ({ type: SET_TOTAL_VALUES_DATA, payload: data });

export const fetchListPriceData = () => ({ type: FETCH_LIST_PRICE_DATA });
export const setListPriceData = (data: any) => ({ type: SET_LIST_PRICE_DATA, payload: data });

export const fetchInventoryRatioData = () => ({ type: FETCH_INVENTORY_RATIO_DATA });
export const setInventoryRatioData = (data: any) => ({ type: SET_INVENTORY_RATIO_DATA, payload: data });

export const fetchTurnoverRatioData = () => ({ type: FETCH_TURNOVER_RATIO_DATA });
export const setTurnoverRatioData = (data: any) => ({ type: SET_TURNOVER_RATIO_DATA, payload: data });

export const fetchLeadSalesConversionData = () => ({ type: FETCH_LEAD_SALES_CONVERSION_DATA });
export const setLeadSalesConversionData = (data: any) => ({ type: SET_LEAD_SALES_CONVERSION_DATA, payload: data });

export const fetchInventoryVolumeTrendData = () => ({ type: FETCH_INVENTORY_VOLUME_TREND_DATA });
export const setInventoryVolumeTrendData = (data: any) => ({ type: SET_INVENTORY_VOLUME_TREND_DATA, payload: data });

export const fetchAverageDaysInStockTrendData = () => ({ type: FETCH_AVERAGE_DAYS_IN_STOCK_TREND_DATA });
export const setAverageDaysInStockTrendData = (data: any) => ({ type: SET_AVERAGE_DAYS_IN_STOCK_TREND_DATA, payload: data });

export const fetchSalesVolumeTrendData = () => ({ type: FETCH_SALES_VOLUME_TREND_DATA });
export const setSalesVolumeTrendData = (data: any) => ({ type: SET_SALES_VOLUME_TREND_DATA, payload: data });

export const fetchSalesRevenueTrendData = () => ({ type: FETCH_SALES_REVENUE_TREND_DATA });
export const setSalesRevenueTrendData = (data: any) => ({ type: SET_SALES_REVENUE_TREND_DATA, payload: data });

export const fetchAverageListPriceTrendData = () => ({ type: FETCH_AVERAGE_LIST_PRICE_TREND_DATA });
export const setAverageListPriceTrendData = (data: any) => ({ type: SET_AVERAGE_LIST_PRICE_TREND_DATA, payload: data });

export const fetchAverageSalesPriceTrendData = () => ({ type: FETCH_AVERAGE_SALES_PRICE_TREND_DATA });
export const setAverageSalesPriceTrendData = (data: any) => ({ type: SET_AVERAGE_SALES_PRICE_TREND_DATA, payload: data });

export const fetchLastWeekDates = () => ({ type: FETCH_LAST_WEEK_DATES });
export const setLastWeekDates = (data: any) => ({ type: SET_LAST_WEEK_DATES, payload: data });
