import { ErrorBoundary } from "@sentry/react";
import React, { useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../AppMessages";
import { DefaultTable } from "./subs";

const StrategicDSTable = (props: { tableData: any; tableId: string; keys: string[]; quarters: number[]; tableClass?: string }) => {
  const { keys, tableData, tableId, quarters, tableClass } = props;
  const isLoading = useSelector((state: RootStateOrAny) => state.loading.midas_media_mix_data_by_quarter);

  const quartersOrder: number[] = [1, 2, 3, 4, 0];
  const screenWidth = window.matchMedia("(max-width: 768px)");
  const [hideModels] = useState<boolean>(!tableId.includes("1") && !tableId.includes("combined") && !screenWidth.matches ? true : false);
  const show_touchpoints = useSelector((state: RootStateOrAny) => state.parameters.show_touchpoints);

  const extraClass = useMemo(() => {
    let extraClass = `midas media_mix ${keys?.length == 1 ? "one_yr_scnr" : ""} ${tableClass}`;
    if (tableId.includes("1")) extraClass += " q1";
    if (tableId.includes("strategic")) extraClass += " strategic";
    if (tableId.includes("combined")) extraClass += " combined";
    if (keys?.length == 1) extraClass += " on_yr_scnr";
    return extraClass;
  }, [tableId]);

  const includeVariance = keys?.length == 2 && (tableId.includes("Full") || tableId.includes("combined")) && tableId.includes("strategic");

  const modelCol = [
    {
      Header: `${!show_touchpoints ? "FMI Category" : "Touchpoints"}`,
      accessor: `model`,
      id: "model",
      canSort: false,
      defaultCanSort: false,
    },
  ];

  const columns: any[] | undefined = useMemo(() => {
    return quarters
      ?.sort((a: number, b: number) => quartersOrder.indexOf(a) - quartersOrder.indexOf(b))
      ?.map((quarter: number) => {
        if (keys?.length < 2) {
          return [
            {
              id: `share0fy0_strategic${quarter}`,
              Header: `${keys[0]}`,
              accessor: `share_${quarter}_${keys[0]}`,
              defaultCanSort: true,
              defaultSortDesc: true,
              sortDescFirst: true,
              sortType: "alphanumericCustom",
            },
          ];
        } else {
          return [
            {
              id: `share0fy0_strategic${quarter}`,
              Header: `${keys[0]}`,
              accessor: `share_${quarter}_${keys[0]}`,
              defaultCanSort: true,
              defaultSortDesc: true,
              sortDescFirst: true,
              sortType: "alphanumericCustom",
            },
            {
              id: `share1fy1_strategic${quarter}`,
              Header: keys[1],
              accessor: `share_${quarter}_${keys[1]}`,
              defaultCanSort: true,
              defaultSortDesc: true,
              sortDescFirst: true,
              sortType: "alphanumericCustom",
            },
          ];
        }
      })
      .flat();
  }, [quarters, keys]);

  const varianceCol = [
    {
      id: "mtpvariance",
      Header: "Var",
      accessor: `variance_${0}_${keys[0]}`,
      canSort: false,
      valueType: "percentage",
      defaultCanSort: false,
      columnColor: "",
    },
  ];

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <>
        {tableData?.length ? (
          <>
            <DefaultTable
              columns={includeVariance ? modelCol.concat(columns.concat(varianceCol)) : modelCol.concat(columns)}
              data={tableData}
              id={tableId}
              data-test-id={"visits_trend_breakdown"}
              isChildComponent={false}
              initialSortColId={{ id: "visits", desc: true }}
              hiddenCols={hideModels ? ["model"] : []}
              extraClass={extraClass}
            />
          </>
        ) : isLoading ? null : (
          <NoDataMsg />
        )}
      </>
    </ErrorBoundary>
  );
};

export default StrategicDSTable;
