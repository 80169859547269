import React, { useContext, useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchFixedDataByQuarter, fetchMidasFixedFilters, fetchMidasFixedLastDataRefresh, setPageName } from "../../../actions";
import "../../../assets/styles/pages/MidasMediaMix.scss";
import "../../../assets/styles/pages/midasUnderConstruction.scss";
import Collapsible from "../../../components/Collapsible/Collapsible";
import MarketingInvastmentDashboardFilters from "../../../components/Filters/MarketingInvastmentDashboardFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { MIDAS_BTN_FILTERS } from "../../../constants";
import { DashboardBannerContext } from "../../../context";
import FixedGraph from "./components/FixedGraph";
import FixedTables from "./components/FixedTables";

const Fixed = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.midas_fmi_last_refresh_date);
  const { brand, mdl_nm_rev, unit, local_currency_name, yr_scnr_rv, show_touchpoints, mc_group, subgroup, market, model } = useSelector(
    (state: RootStateOrAny) => state.parameters
  );

  const pageName = "Fixed";
  useEffect(() => {
    Promise.all([
      // Sets page name
      dispatch(setPageName(pageName)),
      dispatch(fetchMidasFixedLastDataRefresh()),
    ]);
    setBannerText("Forex Rate based from latest BP");
    setShowBanner(false);
  }, [dispatch]);

  useEffect(() => {
    Promise.all([dispatch(fetchFixedDataByQuarter())]);
  }, [brand, yr_scnr_rv, market, mdl_nm_rev, unit, local_currency_name, show_touchpoints, mc_group, subgroup, model]);

  useEffect(() => {
    if (brand && yr_scnr_rv && market) dispatch(fetchMidasFixedFilters());
  }, [brand, yr_scnr_rv, mc_group, subgroup, market, model]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<MarketingInvastmentDashboardFilters hasUnit filterBtns={MIDAS_BTN_FILTERS} hasModel isCustom />}
        dataDocumentation="midas"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="midas" />}
      >
        <div className="midas_media_mix_sotu">
          <Collapsible myStyle="pmo_sotu" show={true} title="">
            <div className="media_mix_by_quarter">
              <FixedGraph />
            </div>
          </Collapsible>
          <div className="tables">
            <FixedTables />
          </div>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default Fixed;
