import { all, put, retry, takeLatest } from "redux-saga/effects";
import {
  setKbaLabelMapping,
  setKbaTrendBreakdown,
  setLeadLabelMapping,
  setLeadTrendBreakdown,
  setNewKeyIndicatorTotals,
  setNewTrendBreakdown,
  setVisitsTrendAll,
  setVisitsTrendKbas,
  setVisitsTrendLeads,
  setWebsiteVisits,
} from "../actions";
import {
  FETCH_KBA_BREAKDOWN_DATA,
  FETCH_KBA_LABEL_MAPPING,
  FETCH_KBA_VISITS_TREND_DATA,
  FETCH_LEAD_BREAKDOWN_DATA,
  FETCH_LEAD_LABEL_MAPPING,
  FETCH_LEAD_VISITS_TREND_DATA,
  FETCH_NEW_KEY_INDICATOR_TOTALS,
  FETCH_NEW_TREND_BREAKDOWN,
  FETCH_VISITS_TREND,
  FETCH_WEBSITE_VISITS,
} from "../actions/actionTypes";
import { setLoading } from "../actions/loadingActions";
import {
  getKbaLabelMapping,
  getKbaTrendBreakdown,
  getLeadLabelMapping,
  getLeadsTrendBreakdown,
  getNewKeyIndicatorTotals,
  getNewTrendBreakdown,
  getVisitsTrend,
  getVisitsTrendKbas,
  getVisitsTrendLeads,
  getWebsiteVisits,
} from "../api";
import { secondsDelay } from "../api/utils";
import { trendData, trendNewBreakdownData } from "../constants/interface";

// Watcher Sagas
export function* watchFetchWebsiteVisits() {
  yield takeLatest(FETCH_WEBSITE_VISITS, fetchWebsiteVisits);
}

export function* watchFetchVisitsTrend() {
  yield takeLatest(FETCH_VISITS_TREND, fetchVisitsTrend);
}

export function* watchFetchKbaVisitsData() {
  yield takeLatest(FETCH_KBA_VISITS_TREND_DATA, fetchVisitsTrendKbas);
}

export function* watchFetchKbaBreakdownData() {
  yield takeLatest(FETCH_KBA_BREAKDOWN_DATA, fetchKbaTrendBreakdown);
}

export function* watchFetchLeadVisitsData() {
  yield takeLatest(FETCH_LEAD_VISITS_TREND_DATA, fetchVisitsTrendLeads);
}

export function* watchFetchLeadBreakdownData() {
  yield takeLatest(FETCH_LEAD_BREAKDOWN_DATA, fetchLeadTrendBreakdown);
}

//refactored endpoints
export function* watchNewFetchTrendBreakdown() {
  yield takeLatest(FETCH_NEW_TREND_BREAKDOWN, fetchNewTrendBreakdown);
}

export function* watchNewFetchKeyIndicatorTotals() {
  yield takeLatest(FETCH_NEW_KEY_INDICATOR_TOTALS, fetchNewKeyIndicatorTotals);
}

export function* watchFetchKbaLabelMapping() {
  yield takeLatest(FETCH_KBA_LABEL_MAPPING, fetchKbaLabelMapping);
}

export function* watchFetchLeadLabelMapping() {
  yield takeLatest(FETCH_LEAD_LABEL_MAPPING, fetchLeadLabelMapping);
}

// Worker Sagas
export function* fetchKbaTrendBreakdown() {
  try {
    yield put(setLoading("kba_trend_table", true));
    const searchParams = window.location.search;
    const response: { data: Array<trendData> } = yield retry(4, secondsDelay, getKbaTrendBreakdown, searchParams);

    if (!("error" in response)) {
      yield all([put(setKbaTrendBreakdown(response)), put(setLoading("kba_trend_table", false))]);
    }
  } catch (err) {
    console.log("Failed to get /trend_breakdown");
  }
}

export function* fetchLeadTrendBreakdown() {
  try {
    yield put(setLoading("lead_trend_table", true));
    const searchParams = window.location.search;
    const response: { data: Array<trendData> } = yield retry(4, secondsDelay, getLeadsTrendBreakdown, searchParams);

    if (!("error" in response)) {
      yield all([put(setLeadTrendBreakdown(response)), put(setLoading("lead_trend_table", false))]);
    }
  } catch (err) {
    console.log("Failed to get /breakdowns/leads");
  }
}

// @ts-ignore
export function* fetchVisitsTrend() {
  try {
    yield put(setLoading("visits_breakdown_graph", true));
    const searchParams = window.location.search;
    const response: { Current: any[]; YoY: any[]; PoP: any[] } = yield retry(4, secondsDelay, getVisitsTrend, searchParams);

    if (!("error" in response)) {
      yield put(setVisitsTrendAll(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("visits_breakdown_graph", false));
  }
}

export function* fetchVisitsTrendKbas() {
  try {
    yield put(setLoading("kba_trend_graph", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getVisitsTrendKbas, searchParams);
    if (!("error" in response)) {
      yield put(setVisitsTrendKbas(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("kba_trend_graph", false));
  }
}

// @ts-ignore
export function* fetchVisitsTrendLeads() {
  try {
    yield put(setLoading("lead_trend_graph", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getVisitsTrendLeads, searchParams);
    if (!("error" in response)) {
      yield put(setVisitsTrendLeads(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("lead_trend_graph", false));
  }
}

export function formatMetricDataGraph(data: any[], breakdownList: string[]) {
  // Todo: Add test
  const lastRow = data[data?.length - 1]?.visits?.[breakdownList[0]];
  const lastRowComplete = lastRow && Object.keys(lastRow).includes("complete") ? lastRow?.complete : true;

  if (!lastRowComplete) {
    data.pop();
  }

  return data.map((row: { date: string; visits: { [index: string]: { name: string; visits: number } } }) => {
    const { visits, date } = row;
    const formattedVisits = breakdownList.reduce((result: { [index: string]: number }, key) => {
      const { visits: value } = visits[key];
      result[key] = value;
      return result;
    }, {});
    return { date, ...formattedVisits };
  });
}

export function formatVisitsDataGraph(data: any[], marketList: string[]) {
  const markets = marketList;
  const lastRow = data[data?.length - 1]?.visits?.[markets[0]];
  const lastRowComplete = lastRow && Object.keys(lastRow).includes("complete") ? lastRow?.complete : true;

  if (!lastRowComplete) {
    data.pop();
  }

  return data.map((row: { date: string; visits: { [index: string]: { kbas: number; leads: number; visits: number } } }) => {
    const { visits, date } = row;
    const marketFormatted = markets.reduce((result, market) => {
      const selectedRow = visits?.[market];
      result = {
        ...result,
        [`${market}_visits`]: selectedRow?.visits,
        [`${market}_kbas`]: selectedRow?.kbas,
        [`${market}_leads`]: selectedRow?.leads,
      };
      return result;
    }, {});
    return { date, ...marketFormatted };
  });
}

//Refactored endpoints
export function* fetchNewTrendBreakdown() {
  try {
    yield all([put(setLoading("visits_breakdown_table", true))]);

    const searchParams = window.location.search;

    const response: trendNewBreakdownData = yield retry(4, secondsDelay, getNewTrendBreakdown, searchParams);

    if (!("error" in response)) {
      yield all([put(setNewTrendBreakdown(response)), put(setLoading("visits_breakdown_table", false))]);
    }
  } catch (err) {
    console.log("Failed to get /get-trend-breakdown");
  }
}

export function* fetchNewKeyIndicatorTotals() {
  try {
    yield all([put(setLoading("key_indicator", true))]);

    const searchParams = window.location.search;

    const response: trendNewBreakdownData = yield retry(4, secondsDelay, getNewKeyIndicatorTotals, searchParams);
    if (!("error" in response)) {
      yield all([put(setNewKeyIndicatorTotals(response))]);
    }
  } catch (err) {
    console.log("Failed to get /get-key-indicators");
  }
}

export function* fetchKbaLabelMapping() {
  try {
    const searchParams = window.location.search;

    const response: Record<string, string> = yield retry(4, secondsDelay, getKbaLabelMapping, searchParams);
    if (!("error" in response)) {
      yield put(setKbaLabelMapping(response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchLeadLabelMapping() {
  try {
    const searchParams = window.location.search;

    const response: Record<string, string> = yield retry(4, secondsDelay, getLeadLabelMapping, searchParams);
    if (!("error" in response)) {
      yield put(setLeadLabelMapping(response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchWebsiteVisits() {
  try {
    yield put(setLoading("full_figures_graph", true));
    const searchParams = window.location.search;

    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getWebsiteVisits, searchParams);

    if (!("error" in response)) {
      yield all([put(setWebsiteVisits(response))]);
    }
  } catch (err) {
    console.log("Failed to get /trend_breakdown");
  } finally {
    yield put(setLoading("full_figures_graph", false));
  }
}
