import { ErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useContext } from "react";
import { Tooltip } from "react-tippy";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import DISCarModelsChart from "../../../components/Charts/PurchaseFunnel/DISCarModelsChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../context";

interface Props {
  chartTitle: string;
  chartId: string;
  chartData: any;
  loading: boolean;
  showVolume: boolean;
  colorMapping: Record<string, string>;
  maxValue: number;
  tooltipText: string;
}

const DisByCarModelGraph = (props: Props) => {
  const { chartTitle, chartId, chartData: data, loading, showVolume, colorMapping, maxValue, tooltipText } = props;
  const themeContext = useContext(ThemeContext);

  return (
    <div className="car_model">
      <div className="title">
        <h3>{chartTitle}</h3>
        <Tooltip
          position="top"
          className={"normal_tippy"}
          trigger="mouseenter"
          animation="none"
          interactive={true}
          delay={1}
          hideDelay={1}
          duration={1}
          title={tooltipText}
          size={"small"}
        >
          <span className="info_tooltip" />
        </Tooltip>
      </div>
      <div className="chart_content">
        {data?.length && !loading ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <DISCarModelsChart
              showVolume={showVolume}
              chartId={chartId}
              data={data}
              theme={themeContext.theme}
              isLoading={loading}
              colorMapping={colorMapping}
              maxValue={maxValue}
            />
          </ErrorBoundary>
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available" />
        )}
      </div>
    </div>
  );
};

export default DisByCarModelGraph;
