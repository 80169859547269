export const FETCH_VISITS_DROP_OFF = "FETCH_VISITS_DROP_OFF";
export const SET_VISITS_DROP_OFF = "SET_VISITS_DROP_OFF";

export const FETCH_INFLUENCE_OF_SHOPPING_TOOLS = "FETCH_INFLUENCE_OF_SHOPPING_TOOLS";
export const SET_INFLUENCE_OF_SHOPPING_TOOLS = "SET_INFLUENCE_OF_SHOPPING_TOOLS";

export const FETCH_ORDERS = "FETCH_ORDERS";
export const SET_ORDERS = "SET_ORDERS";

export const FETCH_TDS_LEADS = "FETCH_TDS_LEADS";
export const SET_TDS_LEADS = "SET_TDS_LEADS";

export const FETCH_NON_TDS_LEADS = "FETCH_NON_TDS_LEADS";
export const SET_NON_TDS_LEADS = "SET_NON_TDS_LEADS";
