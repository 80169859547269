import { captureException, ErrorBoundary } from "@sentry/react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React from "react";
import "../../assets/styles/component/pageContainer.scss";
import { ErrorMsg } from "../AppMessages";

interface Props {
  token: {
    embed_token: string;
    embed_url: string;
    report_id: string;
    token_id: string;
    token_expiration: string;
    report_name: string;
  };
  filter?: JSX.Element;
  showPagePane: boolean;
  reportId: string;
}

export const PowerBiContent = (props: Props): JSX.Element => {
  const { filter, token, showPagePane, reportId } = props;

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      {filter}
      <div id="container">
        {/*Main content*/}
        <div className={"iframeWrapper"} id={reportId} data-test-id={reportId}>
          {
            <PowerBIEmbed
              embedConfig={{
                type: "report",
                accessToken: token?.embed_token,
                embedUrl: token?.embed_url,
                id: token?.report_id,
                tokenType: models.TokenType.Embed,
                settings: {
                  panes: {
                    filters: {
                      visible: false,
                    },
                    pageNavigation: {
                      visible: showPagePane,
                    },
                  },
                },
              }}
              eventHandlers={
                new Map([
                  [
                    "loaded",
                    function () {
                      console.log("Report loaded");
                    },
                  ],
                  [
                    "rendered",
                    function () {
                      console.log("Report rendered");
                    },
                  ],
                  [
                    "error",
                    function (event) {
                      captureException(event?.detail);
                    },
                  ],
                ])
              }
              cssClassName={"report-style-class"}
              getEmbeddedComponent={(embeddedReport) => embeddedReport}
            />
          }
        </div>
      </div>
    </ErrorBoundary>
  );
};
