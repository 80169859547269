import { ErrorBoundary } from "@sentry/react";
import React from "react";
import blueCedarLogo from "../../assets/images/blue_cedar_logo.svg";
import "../../assets/styles/main.scss";
import "../../assets/styles/pages/login.scss";
import { ErrorMsg } from "../../components/AppMessages";
import { SignUpForm } from "../../components/Forms";

const SignUp = (): JSX.Element => {
  return (
    <div className={"pageWrapper"} id={"loginPage"}>
      <div className="dashboard" id="login_dashboard">
        <p id="IESupportMessage" />
        <div id="container">
          <div className="form_body" id="login_form_body">
            <div className="header_section">
              <img src={blueCedarLogo} alt="Cedar Logo" />
            </div>
            <ErrorBoundary fallback={<ErrorMsg class={"formError"} />}>
              <SignUpForm />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
