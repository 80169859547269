import { FETCH_MODELS, SET_HAS_ARIYA, SET_HAS_AURA, SET_HAS_QASHQAI, SET_HAS_SAKURA, SET_MODELS, SET_MODELS_DATA } from "./actionTypes";

export const fetchModelData = () => {
  return { type: FETCH_MODELS };
};

export const setModelsData = (data: any) => {
  return { type: SET_MODELS_DATA, payload: data };
};

export const setModels = (data: any) => {
  return { type: SET_MODELS, payload: data };
};

export const setHasAriya = (data: boolean) => {
  return { type: SET_HAS_ARIYA, payload: data };
};

export const setHasAura = (data: boolean) => {
  return { type: SET_HAS_AURA, payload: data };
};

export const setHasSakura = (data: boolean) => {
  return { type: SET_HAS_SAKURA, payload: data };
};

export const setHasQashqai = (data: boolean) => {
  return { type: SET_HAS_QASHQAI, payload: data };
};
