import { createContext, Dispatch, SetStateAction } from "react";

export const PasswordResetContext = createContext<{
  token: string;
  setUserToken: Dispatch<SetStateAction<string>>;
  showResetModal: boolean;
  setShowResetModal: Dispatch<SetStateAction<boolean>>;
  showSuccessMessage: boolean;
  setShowSuccessMessage: Dispatch<SetStateAction<boolean>>;
}>({
  showSuccessMessage: false,
  token: "",
  showResetModal: false,
  setUserToken(): void {},
  setShowResetModal(): void {},
  setShowSuccessMessage(): void {},
});
