export const FETCH_BASE_TRENDS_DATA = "FETCH_BASE_TRENDS_DATA";
export const SET_BASE_TRENDS_DATA = "SET_BASE_TRENDS_DATA";

export const FETCH_DEALER_TREND_DATA = "FETCH_DEALER_TREND_DATA";
export const SET_DEALER_TREND_DATA = "SET_DEALER_TREND_DATA";

export const FETCH_INVENTORY_BY_AGE_DATA = "FETCH_INVENTORY_BY_AGE_DATA";
export const SET_INVENTORY_BY_AGE_DATA = "SET_INVENTORY_BY_AGE_DATA";

export const FETCH_PERFORMANCE_PER_UNIT_DATA = "FETCH_PERFORMANCE_PER_UNIT_DATA";
export const SET_PERFORMANCE_PER_UNIT_DATA = "SET_PERFORMANCE_PER_UNIT_DATA";

//new metrics breakdown
export const FETCH_TOTAL_VALUES_DATA = "FETCH_TOTAL_VALUES_DATA";
export const SET_TOTAL_VALUES_DATA = "SET_TOTAL_VALUES_DATA";

export const FETCH_LIST_PRICE_DATA = "FETCH_LIST_PRICE_DATA";
export const SET_LIST_PRICE_DATA = "SET_LIST_PRICE_DATA";

export const FETCH_INVENTORY_RATIO_DATA = "FETCH_INVENTORY_RATIO_DATA";
export const SET_INVENTORY_RATIO_DATA = "SET_INVENTORY_RATIO_DATA";

export const FETCH_TURNOVER_RATIO_DATA = "FETCH_TURNOVER_RATIO_DATA";
export const SET_TURNOVER_RATIO_DATA = "SET_TURNOVER_RATIO_DATA";

export const FETCH_LEAD_SALES_CONVERSION_DATA = "FETCH_LEAD_SALES_CONVERSION_DATA";
export const SET_LEAD_SALES_CONVERSION_DATA = "SET_LEAD_SALES_CONVERSION_DATA";

export const FETCH_INVENTORY_VOLUME_TREND_DATA = "FETCH_INVENTORY_VOLUME_TREND_DATA";
export const SET_INVENTORY_VOLUME_TREND_DATA = "SET_INVENTORY_VOLUME_TREND_DATA";

export const FETCH_AVERAGE_DAYS_IN_STOCK_TREND_DATA = "FETCH_AVERAGE_DAYS_IN_STOCK_TREND_DATA";
export const SET_AVERAGE_DAYS_IN_STOCK_TREND_DATA = "SET_AVERAGE_DAYS_IN_STOCK_TREND_DATA";

export const FETCH_SALES_VOLUME_TREND_DATA = "FETCH_SALES_VOLUME_TREND_DATA";
export const SET_SALES_VOLUME_TREND_DATA = "SET_SALES_VOLUME_TREND_DATA";

export const FETCH_SALES_REVENUE_TREND_DATA = "FETCH_SALES_REVENUE_TREND_DATA";
export const SET_SALES_REVENUE_TREND_DATA = "SET_SALES_REVENUE_TREND_DATA";

export const FETCH_AVERAGE_LIST_PRICE_TREND_DATA = "FETCH_AVERAGE_LIST_PRICE_TREND_DATA";
export const SET_AVERAGE_LIST_PRICE_TREND_DATA = "SET_AVERAGE_LIST_PRICE_TREND_DATA";

export const FETCH_AVERAGE_SALES_PRICE_TREND_DATA = "FETCH_AVERAGE_SALES_PRICE_TREND_DATA";
export const SET_AVERAGE_SALES_PRICE_TREND_DATA = "SET_AVERAGE_SALES_PRICE_TREND_DATA";

export const FETCH_LAST_WEEK_DATES = "FETCH_LAST_WEEK_DATES";
export const SET_LAST_WEEK_DATES = "SET_LAST_WEEK_DATES";
