import {
  SET_CPO_BREAKDOWN_TREND,
  SET_CPO_BREAKDOWN_TREND_ALL,
  SET_CPO_CHANNEL_DATA,
  SET_CPO_GROUPED_DATA,
  SET_CPO_KBA_BREAKDOWN_TABLE_DATA,
  SET_CPO_KBA_KEYS,
  SET_CPO_KBA_LABEL_MAPPING,
  SET_CPO_KBA_VISITS_TREND_DATA,
  SET_CPO_LEAD_BREAKDOWN_TABLE_DATA,
  SET_CPO_LEAD_KEYS,
  SET_CPO_LEAD_LABEL_MAPPING,
  SET_CPO_LEAD_VISITS_TREND_DATA,
  SET_CPO_MARKETS,
  SET_CPO_VISITS_GRAPH_DATA,
  SET_CPO_VISITS_TREND_KBA_LEADS,
  SET_KEY_INDICATOR_ALL,
  SET_KEY_INDICATOR_VARIANCE,
  SET_MODEL_KEY_INDICATORS,
  SET_MODEL_TREND,
} from "../actions/actionTypes";

const initialState: {
  model_trend: Array<any>;
  model_key_indicators: Record<string, unknown>;
  key_indicator_variance: Record<string, unknown>;
  key_indicator_all: Record<string, unknown>;
  cpo_breakdown_trend: Record<string, unknown>;
  cpo_breakdown_trend_kba: Record<string, unknown>;
  cpo_breakdown_trend_lead: Record<string, unknown>;
  cpo_breakdown_trend_markets: Array<string>;
  cpo_visits_trend: Record<string, unknown>;
  cpo_leads_kba_graph_data: Record<string, unknown>;
  cpo_kba_keys: Record<string, unknown>;
  cpo_lead_keys: Record<string, unknown>;
  cpo_grouped_data: Record<string, unknown>;
  cpo_channel_data: Record<string, unknown>;
  cpo_kba_breakdown_table: Array<any>;
  cpo_lead_breakdown_table: Array<any>;
  cpo_kba_label_mapping: Record<string, unknown>;
  cpo_lead_label_mapping: Record<string, unknown>;
} = {
  model_trend: [],
  model_key_indicators: {},
  key_indicator_variance: {},
  key_indicator_all: {},
  cpo_breakdown_trend: {},
  cpo_breakdown_trend_kba: {},
  cpo_breakdown_trend_lead: {},
  cpo_breakdown_trend_markets: [],
  cpo_visits_trend: { daily: [], weekly: [], monthly: [] },
  cpo_leads_kba_graph_data: {},
  cpo_kba_keys: {},
  cpo_lead_keys: {},
  cpo_grouped_data: { data: [] },
  cpo_channel_data: { data: [] },
  cpo_kba_breakdown_table: [],
  cpo_lead_breakdown_table: [],
  cpo_kba_label_mapping: {},
  cpo_lead_label_mapping: {},
};

const cpoDashboardReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_MODEL_TREND:
      return { ...state, model_trend: action.payload };

    case SET_MODEL_KEY_INDICATORS:
      return { ...state, model_key_indicators: action.payload };

    case SET_KEY_INDICATOR_VARIANCE:
      return { ...state, key_indicator_variance: action.payload };

    case SET_KEY_INDICATOR_ALL:
      return { ...state, key_indicator_all: action.payload };

    case SET_CPO_BREAKDOWN_TREND:
      return { ...state, cpo_breakdown_trend: action.payload };

    case SET_CPO_BREAKDOWN_TREND_ALL:
      return { ...state, cpo_breakdown_trend_all: action.payload };

    case SET_CPO_MARKETS:
      return { ...state, cpo_breakdown_trend_markets: action.payload };

    case SET_CPO_VISITS_GRAPH_DATA:
      return {
        ...state,
        cpo_visits_trend: action.payload,
      };

    case SET_CPO_KBA_VISITS_TREND_DATA:
      return {
        ...state,
        cpo_breakdown_trend_kba: action.payload,
      };

    case SET_CPO_LEAD_VISITS_TREND_DATA:
      return {
        ...state,
        cpo_breakdown_trend_lead: action.payload,
      };

    case SET_CPO_KBA_KEYS:
      return {
        ...state,
        cpo_kba_keys: { ...state.cpo_kba_keys, keys: action.payload },
      };
    case SET_CPO_LEAD_KEYS:
      return {
        ...state,
        cpo_lead_keys: { ...state.cpo_lead_keys, keys: action.payload },
      };
    case SET_CPO_VISITS_TREND_KBA_LEADS:
      return {
        ...state,
        cpo_leads_kba_graph_data: { ...state.cpo_leads_kba_graph_data, breakdown: action.payload },
      };

    case SET_CPO_GROUPED_DATA:
      return {
        ...state,
        cpo_grouped_data: { ...state.cpo_grouped_data, data: action.payload },
      };
    case SET_CPO_CHANNEL_DATA:
      return {
        ...state,
        cpo_channel_data: { ...state.cpo_channel_data, data: action.payload },
      };

    case SET_CPO_KBA_BREAKDOWN_TABLE_DATA:
      return { ...state, cpo_kba_breakdown_table: action.payload };

    case SET_CPO_LEAD_BREAKDOWN_TABLE_DATA:
      return { ...state, cpo_lead_breakdown_table: action.payload };

    case SET_CPO_KBA_LABEL_MAPPING:
      return { ...state, cpo_kba_label_mapping: action.payload };

    case SET_CPO_LEAD_LABEL_MAPPING:
      return { ...state, cpo_lead_label_mapping: action.payload };

    default:
      return state;
  }
};

export default cpoDashboardReducer;
