import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useMemo } from "react";
import { IReviewsRow } from "../../constants/interface/ccsCockpit";
import { ErrorMsg, NoDataMsg } from "../AppMessages";
import { CommentsDefaultTable } from "./subs";

interface Props {
  data: Array<IReviewsRow>;
  showOriginalLanguage?: boolean;
}

export const ReviewsTable = (props: Props) => {
  const { data, showOriginalLanguage } = props;
  const columns = useMemo(
    () => [
      {
        id: "date",
        Header: "Date",
        accessor: "date",
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "alphanumericIgnoreCase",
        Cell: (props1: { value: string }) => {
          const { value } = props1;

          return moment(value, "YYYY-MM-DD").format("DD MMM YYYY");
        },
      },
      {
        Header: "Ratings",
        accessor: `rating`,
        id: "ratings",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
      },
      {
        id: "review",
        Header: "Comment",
        accessor: "review",
        sortType: "alphanumericFalsyLast",
        defaultCanSort: false,
      },

      {
        id: "os",
        Header: "OS",
        accessor: "platform",
        sortType: "alphanumericCustom",
        defaultCanSort: false,
        Cell: (props1: { value: string }) => {
          const { value } = props1;

          return value === "Google" ? "Android" : value;
        },
      },
      {
        id: "topic",
        Header: "Topic",
        accessor: "topic",
        sortType: "alphanumericCustom",
        defaultCanSort: false,
        Cell: (props1: { value: string }) => {
          const { value } = props1;

          return value;
        },
      },
    ],
    []
  );

  const columnsOriginal = useMemo(
    () => [
      {
        id: "date",
        Header: "Date",
        accessor: "date",
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "alphanumericIgnoreCase",
        Cell: (props1: { value: string }) => {
          const { value } = props1;

          return moment(value, "YYYY-MM-DD").format("DD MMM YYYY");
        },
      },
      {
        Header: "Ratings",
        accessor: `rating`,
        id: "ratings",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
      },
      {
        id: "reviews",
        Header: "Comment",
        accessor: "original_review",
        sortType: "alphanumericFalsyLast",
        defaultCanSort: false,
      },

      {
        id: "os",
        Header: "OS",
        accessor: "platform",
        sortType: "alphanumericCustom",
        defaultCanSort: false,
        Cell: (props1: { value: string }) => {
          const { value } = props1;

          return value === "Google" ? "Android" : value;
        },
      },

      {
        id: "topic",
        Header: "Topic",
        accessor: "topic",
        sortType: "alphanumericCustom",
        defaultCanSort: false,
      },
    ],
    []
  );

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <>
        {data?.length > 0 ? (
          <CommentsDefaultTable
            columns={showOriginalLanguage ? columnsOriginal : columns}
            data={data}
            id={"appRatingsComments"}
            data-test-id={"app_review_table"}
            initialSortColId={{ id: "kbas", desc: true }}
            hiddenCols={[]}
            extraClass="appRatingsComments"
          />
        ) : (
          <NoDataMsg />
        )}
      </>
    </ErrorBoundary>
  );
};
