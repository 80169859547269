import {
  IActivationCountryData,
  IActivationDealerData,
  IActivationModelData,
  IActivationOverallData,
} from "../constants/interface/europeActivation/activationData";
import {
  FETCH_ACTIVATION_COUNTRY_DATA,
  FETCH_ACTIVATION_DEALER_DATA,
  FETCH_ACTIVATION_MODEL_DATA,
  FETCH_ACTIVATION_OVERALL_DATA,
  FETCH_COCKPIT_ACTIVATION_DATA,
  FETCH_COCKPIT_ACTIVATION_OVERALL_DATA,
  SET_ACTIVATION_COUNTRY_DATA,
  SET_ACTIVATION_DEALER_DATA,
  SET_ACTIVATION_MODEL_DATA,
  SET_ACTIVATION_OVERALL_DATA,
  SET_COCKPIT_ACTIVATION_DATA,
} from "./actionTypes";

export const fetchActivationOverallData = () => ({ type: FETCH_ACTIVATION_OVERALL_DATA });
export const fetchCockpitActivationOverallData = () => ({ type: FETCH_COCKPIT_ACTIVATION_OVERALL_DATA });
export const setActivationOverallData = (data: IActivationOverallData) => ({ type: SET_ACTIVATION_OVERALL_DATA, payload: data });

export const fetchActivationCountryData = (cumulative: boolean) => ({ type: FETCH_ACTIVATION_COUNTRY_DATA, payload: cumulative });
export const setActivationCountryData = (data: IActivationCountryData) => ({ type: SET_ACTIVATION_COUNTRY_DATA, payload: data });

export const fetchActivationModelData = (cumulative: boolean) => ({ type: FETCH_ACTIVATION_MODEL_DATA, payload: cumulative });
export const setActivationModelData = (data: IActivationModelData) => ({ type: SET_ACTIVATION_MODEL_DATA, payload: data });

export const fetchActivationDealerData = (cumulative: boolean) => ({ type: FETCH_ACTIVATION_DEALER_DATA, payload: cumulative });
export const setActivationDealerData = (data: IActivationDealerData) => ({ type: SET_ACTIVATION_DEALER_DATA, payload: data });

export const fetchCockpitActivation = (cumulative: boolean) => ({ type: FETCH_COCKPIT_ACTIVATION_DATA, payload: cumulative });
export const setCockpitActivation = (data: IActivationCountryData) => ({ type: SET_COCKPIT_ACTIVATION_DATA, payload: data });
