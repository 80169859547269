import {
  SET_ALL_REVIEWS,
  SET_APP_ANALYSIS_FEATURE_USAGE_DATA,
  SET_APP_ANALYSIS_WEB_USAGE_DATA,
  SET_APP_OPEN_RATE_DATA,
  SET_APP_RATINGS,
  SET_APP_RATINGS_AVG_DATA,
  SET_APP_RATINGS_BY_MARKET,
  SET_APP_RATINGS_BY_MARKET_CHART_DATA,
  SET_APP_RATINGS_CHART_DATA,
  SET_APP_RATINGS_DOWNLOADS_DATA,
  SET_APP_REVIEW_BREAKDOWN,
  SET_APP_REVIEW_BY_TOPIC,
  SET_APP_SATISFACTION_DATA,
  SET_BUSINESS_REVENUE_CHART_DATA,
  SET_BUSINESS_REVENUE_DATA,
  SET_CCS_JOURNEY_BANNER_DATA,
  SET_CCS_JOURNEY_DATA,
  SET_CONNECTED_CARS_CHART_DATA,
  SET_CONNECTED_CARS_DATA,
  SET_ENGAGEMENT_APP_DOWNLOADS_CHART_DATA,
  SET_ENGAGEMENT_APP_DOWNLOADS_DATA,
  SET_ENROLLED_CUSTOMERS_CHART_DATA,
  SET_ENROLLED_CUSTOMERS_DATA,
  SET_EUROPE_APP_RATINGS_AVG_DATA,
  SET_EUROPE_APP_RATINGS_AVG_TOTAL_DATA,
  SET_EUROPE_APP_RATINGS_TREND_DATA,
  SET_G8_PRIVATE_ACTIVATION_RATIO_CHART_DATA,
  SET_G8_PRIVATE_ACTIVATION_RATIO_DATA,
  SET_RENEWAL_CHURN_RATE,
  SET_RENEWAL_CHURN_RATE_CHART_DATA,
  SET_SALES_REVENUE_CHART_DATA,
  SET_SALES_REVENUE_DATA,
  SET_SALES_REVENUE_SOURCE,
  SET_SUBSCRIPTION_SALES_CHART_DATA,
  SET_SUBSCRIPTION_SALES_DATA,
  SET_TOP_USED_SERVICES,
  SET_USER_ENGAGEMENT,
  SET_USER_ENGAGEMENT_CHART_DATA,
  SET_VOC_CALL_CENTRE,
  SET_VOC_CALL_CENTRE_CHART_DATA,
  SET_WARRANTY_START_DATE,
} from "../actions/actionTypes";

const initialState = {
  business_revenue_data: {},
  business_revenue_chart_data: {},
  subscription_sales_data: {},
  subscription_sales_chart_data: {},
  top_used_services: [],
  app_ratings: {},
  app_ratings_chart_data: {},
  app_ratings_by_market: {},
  app_ratings_by_market_chart_data: {},
  app_review_breakdown: [],
  app_satisfaction: [],
  app_review_by_topic: {
    all_data: [],
    topics: [],
  },
  subscriptions: {},
  callCentre: {},
  callCentreChartData: {},
  reviews: [],
  user_engagement: {},
  user_engagement_chart_data: {},
  enrolled_customers: {},
  enrolled_customers_chart_data: {},
  warranty_start_date: {},
  feature_usage: [],
  web_usage: [],
  app_ratings_downloads: [],
  engagement_downloads: {},
  engagement_downloads_chart_data: {},
  open_rate: [],
  connected_cars_sold_vs_produced: {},
  connected_cars_sold_vs_produced_chart_data: {},
  renewal_and_churn_rate: {},
  renewal_and_churn_rate_chart_data: {},
  ccs_journey_data: {},
  g8_private_activation_ratio_data: {},
  g8_private_activation_ratio_chart_data: {},
  europe_app_ratings_avg_data: {
    avg_data: [],
    total_data: [],
  },
  europe_app_ratings_trend_data: [],
  app_ratings_avg: [],
  ccs_journey_banner_date: "",
  sales_revenue_sources: [],
  sales_revenue_data: {},
  sales_revenue_chart_data: {},
};

const ccsCockpitReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_TOP_USED_SERVICES:
      return { ...state, top_used_services: action.payload };

    case SET_APP_RATINGS:
      return { ...state, app_ratings: action.payload };

    case SET_APP_RATINGS_CHART_DATA:
      return { ...state, app_ratings_chart_data: action.payload };

    case SET_APP_RATINGS_BY_MARKET:
      return { ...state, app_ratings_by_market: action.payload };

    case SET_APP_RATINGS_BY_MARKET_CHART_DATA:
      return { ...state, app_ratings_by_market_chart_data: action.payload };

    case SET_APP_REVIEW_BREAKDOWN:
      return { ...state, app_review_breakdown: action.payload };

    case SET_APP_SATISFACTION_DATA:
      return { ...state, app_satisfaction: action.payload };

    case SET_APP_REVIEW_BY_TOPIC:
      return { ...state, app_review_by_topic: action.payload };

    case SET_BUSINESS_REVENUE_DATA: {
      return { ...state, business_revenue_data: action.payload };
    }

    case SET_BUSINESS_REVENUE_CHART_DATA: {
      return { ...state, business_revenue_chart_data: action.payload };
    }

    case SET_SUBSCRIPTION_SALES_DATA: {
      return { ...state, subscription_sales_data: action.payload };
    }

    case SET_SUBSCRIPTION_SALES_CHART_DATA: {
      return { ...state, subscription_sales_chart_data: action.payload };
    }

    case SET_CONNECTED_CARS_DATA: {
      return { ...state, connected_cars_sold_vs_produced: action.payload };
    }

    case SET_CONNECTED_CARS_CHART_DATA: {
      return { ...state, connected_cars_sold_vs_produced_chart_data: action.payload };
    }

    case SET_VOC_CALL_CENTRE:
      return { ...state, callCentre: action.payload };

    case SET_VOC_CALL_CENTRE_CHART_DATA:
      return { ...state, callCentreChartData: action.payload };

    case SET_ALL_REVIEWS:
      return { ...state, reviews: action.payload };

    case SET_USER_ENGAGEMENT:
      return { ...state, user_engagement: action.payload };

    case SET_USER_ENGAGEMENT_CHART_DATA:
      return { ...state, user_engagement_chart_data: action.payload };

    case SET_ENROLLED_CUSTOMERS_DATA:
      return { ...state, enrolled_customers: action.payload };

    case SET_ENROLLED_CUSTOMERS_CHART_DATA:
      return { ...state, enrolled_customers_chart_data: action.payload };

    case SET_WARRANTY_START_DATE:
      return { ...state, warranty_start_date: action.payload };

    case SET_APP_ANALYSIS_FEATURE_USAGE_DATA:
      return { ...state, feature_usage: action.payload };

    case SET_APP_ANALYSIS_WEB_USAGE_DATA:
      return { ...state, web_usage: action.payload };

    case SET_APP_RATINGS_DOWNLOADS_DATA:
      return { ...state, app_ratings_downloads: action.payload };

    case SET_ENGAGEMENT_APP_DOWNLOADS_DATA:
      return { ...state, engagement_downloads: action.payload };

    case SET_ENGAGEMENT_APP_DOWNLOADS_CHART_DATA:
      return { ...state, engagement_downloads_chart_data: action.payload };

    case SET_APP_OPEN_RATE_DATA:
      return { ...state, open_rate: action.payload };

    case SET_RENEWAL_CHURN_RATE:
      return { ...state, renewal_and_churn_rate: action.payload };

    case SET_RENEWAL_CHURN_RATE_CHART_DATA:
      return { ...state, renewal_and_churn_rate_chart_data: action.payload };

    case SET_CCS_JOURNEY_DATA:
      return { ...state, ccs_journey_data: action.payload };

    case SET_G8_PRIVATE_ACTIVATION_RATIO_DATA:
      return { ...state, g8_private_activation_ratio_data: action.payload };

    case SET_G8_PRIVATE_ACTIVATION_RATIO_CHART_DATA:
      return { ...state, g8_private_activation_ratio_chart_data: action.payload };

    case SET_EUROPE_APP_RATINGS_AVG_DATA:
      return { ...state, europe_app_ratings_avg_data: { ...state.europe_app_ratings_avg_data, avg_data: action.payload } };

    case SET_EUROPE_APP_RATINGS_AVG_TOTAL_DATA:
      return { ...state, europe_app_ratings_avg_data: { ...state.europe_app_ratings_avg_data, total_data: action.payload } };

    case SET_EUROPE_APP_RATINGS_TREND_DATA:
      return { ...state, europe_app_ratings_trend_data: action.payload };

    case SET_APP_RATINGS_AVG_DATA:
      return { ...state, app_ratings_avg: action.payload };

    case SET_CCS_JOURNEY_BANNER_DATA:
      return { ...state, ccs_journey_banner_date: action.payload };

    case SET_SALES_REVENUE_SOURCE:
      return { ...state, sales_revenue_sources: action.payload };

    case SET_SALES_REVENUE_DATA: {
      return { ...state, sales_revenue_data: action.payload };
    }

    case SET_SALES_REVENUE_CHART_DATA: {
      return { ...state, sales_revenue_chart_data: action.payload };
    }

    default:
      return state;
  }
};

export default ccsCockpitReducer;
