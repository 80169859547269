import React from "react";
import "../../assets/styles/component/toggleSwitch.scss";

interface ToggleSwitchProps {
  toggleTitle?: string;
  activeToggleLabel: string;
  inactiveToggleLabel: string;
  handleToggleClick: any;
  active: boolean;
  toggleClassName?: string;
}

export const ToggleSwitch = (props: ToggleSwitchProps): JSX.Element => {
  const { activeToggleLabel, inactiveToggleLabel, handleToggleClick, active, toggleTitle, toggleClassName } = props;
  return (
    <div className={`toggle_container ${toggleClassName ? toggleClassName : ""}`}>
      {toggleTitle && <span className="toggle_title">{toggleTitle} </span>}
      <div className={`toggle_switch`}>
        <span className={`toggle_label ${active ? "active_label" : ""}`}>{activeToggleLabel}</span>
        <span className="toggle_span">
          <span id="active_toggle" className={`${active ? "active_toggle" : ""} change_toggle_view`} onClick={handleToggleClick} />
          <span id="inactive_toggle" className={`${!active ? "active_toggle" : ""} change_toggle_view`} onClick={handleToggleClick} />
        </span>
        <span className={`toggle_label ${!active ? "active_label" : ""}`}>{inactiveToggleLabel}</span>
      </div>
    </div>
  );
};
