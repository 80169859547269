import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { deleteDataV2, fetchDataV2 } from "../../../api/utils";
import { ErrorMsg } from "../../../components/AppMessages";
import { CYAN } from "../../../constants";
import UserSearchField from "../common/Fields/UserSearchField";
import DeleteUserViewDialog from "./subs/Dialogs/DeleteUserViewDialog";
import UserViewFormDialog from "./subs/Dialogs/UserViewsFormDialog";
import UserViewsTable from "./subs/UserViewsTable";

const UserViews = () => {
  const { views } = useSelector((state: RootStateOrAny) => state.admin);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [userViewFormDialogOpen, setUserViewDialogFormOpen] = useState<boolean>(false);
  const [selectedUserView, setSelectedUserView] = useState<any>(undefined);
  const [userViews, setUserViews] = useState<Array<any>>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const filteredUserViews = userViews.filter((userView) => userView.name.toLowerCase().includes(searchValue.toLowerCase()));

  const classes = makeStyles({
    button: {
      backgroundColor: CYAN,
      color: "#FFF !important",
      borderRadius: "0px !important",
      border: "none !important",
      marginRight: "10px",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      "&:hover": {
        backgroundColor: "#5ed2ff !important",
        border: "none !important",
      },
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  })();

  useEffect(() => {
    fetchDataV2("/user_service/get-all-view-groups").then((res) => {
      if (!res.error) {
        setUserViews(res);
      } else {
        console.log(res.error);
      }
    });
  }, []);

  const handleToogleDeleteDialog = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  };

  const handleToggleUserViewFormDialog = () => {
    setUserViewDialogFormOpen(!userViewFormDialogOpen);
    if (userViewFormDialogOpen) {
      setSelectedUserView(undefined);
    }
  };

  const handleDeleteUserView = (id: number) => {
    deleteDataV2(`/user_service/delete-view-group?id=${id}`).then((res) => {
      if (!res.error) {
        setUserViews((prevState) => prevState.filter((user) => user.id !== id));
        setSelectedUserView(undefined);
        handleToogleDeleteDialog();
      } else {
        console.log(res.error);
      }
    });
  };

  return (
    <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
      <Box component="div">
        <Box className={classes.buttonsContainer} component="div" mb={3}>
          <Box component="div">
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={() => {
                setSelectedUserView(undefined);
                handleToggleUserViewFormDialog();
              }}
              data-test-id="btn-add-new-user-view"
            >
              New
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToggleUserViewFormDialog}
              disabled={!selectedUserView}
              data-test-id="btn-edit-user-view"
            >
              Edit
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToogleDeleteDialog}
              disabled={!selectedUserView}
              data-test-id="btn-delete-user-view"
            >
              Delete
            </Button>
          </Box>
          <Box component="div">
            <UserSearchField value={searchValue} setValue={setSearchValue} />
          </Box>
        </Box>

        <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
          <UserViewsTable
            selectedUserView={selectedUserView}
            setSelectedUserView={setSelectedUserView}
            setDeleteDialogOpen={setDeleteDialogOpen}
            userViews={filteredUserViews}
            views={views}
            searchValue={searchValue}
          />
        </Sentry.ErrorBoundary>

        {deleteDialogOpen && selectedUserView && (
          <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
            <DeleteUserViewDialog
              open={deleteDialogOpen}
              handleClose={handleToogleDeleteDialog}
              handleDelete={handleDeleteUserView}
              userView={selectedUserView}
            />
          </Sentry.ErrorBoundary>
        )}

        {userViewFormDialogOpen && (
          <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
            <UserViewFormDialog
              open={userViewFormDialogOpen}
              toggleOpen={handleToggleUserViewFormDialog}
              setUserViews={setUserViews}
              selectedUserView={selectedUserView}
              views={views}
            />
          </Sentry.ErrorBoundary>
        )}
      </Box>
    </Sentry.ErrorBoundary>
  );
};

export default UserViews;
