import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import _ from "lodash";
import React, { Component, ReactNode } from "react";
import WebFont from "webfontloader";
import {
  BLACK,
  BROWN,
  CYAN,
  LIGHT_BROWN,
  LIGHT_CYAN,
  LIGHT_LIGHT_YELLOW,
  LIGHT_PURPLE,
  LIGHT_YELLOW,
  PURPLE,
  WHITE,
} from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configQuarterlyAxis, dateAxisConfig, dateAxisGridConfigRenderer, dateAxisLabelRendererConfig } from "../helpers/am5Configurations";
import { formatValueAxisUnitsAm5 } from "../helpers/helpers";
import { formatDateAxisLabelToQuarterlyFY } from "../helpers/pmoConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  current: any;
  previous: any;
  theme: ThemeInt;
  isLoading: boolean;
  seriesList: any;
  granularity: string;
  toggleActive: boolean;
  market: string;
}

class SearchInterestVsFMIChart extends Component<Props> {
  root!: am5.Root;
  componentDidMount() {
    if (this.root) {
      WebFont.load({
        custom: {
          families: ["nissan"],
          urls: ["../../../assets/fonts/fonts.css"],
        },
        timeout: 8000,
      });
    }
    am5.addLicense("AM5C250554441");
    this.initChart();
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props) && this.root) {
      this.root.dispose();
      this.initChart();
    }
  }

  initChart() {
    const { theme, seriesList, market, current, previous, granularity, toggleActive } = this.props;
    const root = am5.Root.new("chartdiv");

    const lastObject = current[current.length - 1]?.date_f;
    const lastObject1 = previous[current.length - 1]?.date_f;
    root.setThemes([am5themes_Animated.new(root)]);
    root.utc = true;

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      })
    );

    root.numberFormatter.setAll({
      numberFormat: "#,###.",
      numericFields: ["valueY"],
    });

    root.dateFormatter.setAll({
      dateFormat: "MMM yyyy",
      dateFields: ["valueX"],
    });

    const dateAxis = chart.xAxes.push(dateAxisConfig(root, 1, "month", 0.5, 0.95, granularity));

    const dateAxis1 = chart.xAxes.push(dateAxisConfig(root, 1, "month", 0.05, 0.5, granularity));

    const dateAxis2 = chart.xAxes.push(dateAxisConfig(root, 1, "month", 0.5, 0.5, granularity));

    dateAxisLabelRendererConfig(theme, dateAxis, true);
    dateAxisLabelRendererConfig(theme, dateAxis1, false);
    dateAxisLabelRendererConfig(theme, dateAxis2, false);

    if (granularity == "quarterly" && current?.length > 1) {
      configQuarterlyAxis(dateAxis, lastObject);
      configQuarterlyAxis(dateAxis1, lastObject1);
      configQuarterlyAxis(dateAxis2, lastObject1);
    }
    dateAxisGridConfigRenderer(dateAxis, 0, 0, false);
    dateAxisGridConfigRenderer(dateAxis1, 0, 0, false);
    dateAxisGridConfigRenderer(dateAxis2, 0, 0, false);

    const valueAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        numberFormat: "#a",
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    valueAxis.get("renderer").labels.template.setAll({
      fill: am5.color(theme == "light" ? BLACK : WHITE),
      fontSize: "13px",
    });

    dateAxis.get("renderer").labels.template.adapters.add("text", function (text, target) {
      return granularity == "quarterly" ? formatDateAxisLabelToQuarterlyFY(text as unknown as string) || text : text;
    });

    dateAxis1.get("renderer").labels.template.adapters.add("text", function (text, target) {
      return granularity == "quarterly" ? formatDateAxisLabelToQuarterlyFY(text as unknown as string) || text : text;
    });

    dateAxis2.get("renderer").labels.template.adapters.add("text", function (text, target) {
      return granularity == "quarterly" ? formatDateAxisLabelToQuarterlyFY(text as unknown as string) || text : text;
    });

    const valueAxis1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        syncWithAxis: valueAxis,
        min: 0,
        numberFormat: "#a",
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
        }),
      })
    );

    valueAxis1.get("renderer").labels.template.setAll({
      fill: am5.color(theme == "light" ? BLACK : WHITE),
      fontSize: "13px",
    });

    formatValueAxisUnitsAm5(valueAxis1, market);

    // Add series
    const createSeries = (
      name: string,
      field: string,
      period: string,
      seriesColor: string,
      dateAxisParam: am5xy.DateAxis<am5xy.AxisRenderer>,
      valueAxisParam: am5xy.ValueAxis<am5xy.AxisRenderer>,
      stacked: boolean
    ) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: dateAxisParam,
          yAxis: valueAxisParam,
          stacked: stacked,
          valueYField: field,
          valueXField: "date_f",
          fill: am5.color(seriesColor),
          stroke: am5.color(seriesColor),
        })
      );

      series.columns.template.setAll({
        tooltipText: "[bold; font-size: var(--regular_font_size);]{valueX}[/ font-size: var(--regular_font_size);]: {valueY}",
        width: am5.percent(90),
        tooltipY: 0,
      });
      series.data.processor = am5.DataProcessor.new(root, {
        numericFields: ["value"],
        dateFields: ["date_f"],
      });
      series.data.setAll(period == "current" ? current : previous);
      series.columns.template.adapters.add("tooltipText", (value: string | undefined, target: am5.RoundedRectangle, key: "tooltipText") => {
        const data = target.dataItem?.dataContext;
        if (granularity === "quarterly") {
          //@ts-ignore
          const dateVal = formatDateAxisLabelToQuarterlyFY(data?.date);
          return (value = `[bold; font-size: var(--regular_font_size);] ${dateVal}[/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###.")}`);
        }
        return value;
      });
    };

    const createLineSeries = (name: string, period: string, seriesColor: string) => {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: period == "current" ? dateAxis : dateAxis2,
          yAxis: valueAxis,
          valueYField: "search_volume",
          valueXField: "date_f",
          fill: am5.color(seriesColor),
          stroke: am5.color(seriesColor),
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      series.data.setAll(period == "current" ? current : previous);
      series.data.processor = am5.DataProcessor.new(root, {
        numericFields: ["value"],
        dateFields: ["date_f"],
      });

      series.bullets.push(function () {
        const circle = am5.Circle.new(root, {
          radius: 5,
          fill: series.get("fill"),
        });
        return am5.Bullet.new(root, {
          sprite: circle,
        });
      });

      series
        ?.get("tooltip")
        ?.label.set("text", "[bold; font-size: var(--regular_font_size);]{valueX}[/ font-size: var(--regular_font_size);]: {valueY}");
      series?.get("tooltip")?.label.adapters.add("text", (value, target) => {
        const data = target.dataItem?.dataContext;
        if (granularity === "quarterly") {
          //@ts-ignore
          const dateVal = formatDateAxisLabelToQuarterlyFY(data?.date);
          return (value = `[bold; font-size: var(--regular_font_size);] ${dateVal}[/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###.")}`);
        }
        return value;
      });
    };

    createSeries(
      `Online ${toggleActive ? "Media" : "FMI"} Spend (previous year)`,
      `${toggleActive ? "media_spend" : "fmi"}_amount_on`,
      "previous",
      PURPLE,
      dateAxis1,
      valueAxis1,
      true
    );

    createSeries(
      `Online ${toggleActive ? "Media" : "FMI"} Spend`,
      `${toggleActive ? "media_spend" : "fmi"}_amount_on`,
      "current",
      BROWN,
      dateAxis,
      valueAxis1,
      true
    );

    createSeries(
      `Offline ${toggleActive ? "Media" : "FMI"} Spend (previous year)`,
      `${toggleActive ? "media_spend" : "fmi"}_amount_off`,
      "previous",
      LIGHT_PURPLE,
      dateAxis1,
      valueAxis1,
      true
    );

    createSeries(
      `Offline ${toggleActive ? "Media" : "FMI"} Spend`,
      `${toggleActive ? "media_spend" : "fmi"}_amount_off`,
      "current",
      LIGHT_YELLOW,
      dateAxis,
      valueAxis1,
      true
    );

    createSeries(
      `Forecast Online ${toggleActive ? "Media" : "FMI"} Spend`,
      `${toggleActive ? "media_spend" : "fmi"}_amount_on_light`,
      "current",
      LIGHT_BROWN,
      dateAxis,
      valueAxis1,
      true
    );

    createSeries(
      `Forecast Offline ${toggleActive ? "Media" : "FMI"} Spend`,
      `${toggleActive ? "media_spend" : "fmi"}_amount_off_light`,
      "current",
      LIGHT_LIGHT_YELLOW,
      dateAxis,
      valueAxis1,
      true
    );

    createLineSeries("Search interest (previous year)", "previous", LIGHT_CYAN);

    createLineSeries("Search interest", "current", CYAN);

    // Add legend to top
    const legend = chart.children.unshift(
      am5.Legend.new(root, {
        paddingBottom: 20,
        paddingLeft: 0,
        marginLeft: 0,
        layout: root.gridLayout,
      })
    );
    legend.data.setAll(chart.series.values);

    legend.labels.template.setAll({
      fill: am5.color(theme == "light" ? BLACK : WHITE),
      fontSize: ".64rem",
    });

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    this.root = root;
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render(): ReactNode {
    return (
      <div className={"chartWrapper"}>
        <div id={"chartdiv"} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default SearchInterestVsFMIChart;
