import React from "react";

interface Props {
  versionNumber: string;
  releaseNotes: string;
  releaseDate: string;
}

export const AllReleaseNotesContainer = (props: Props): JSX.Element => {
  const { versionNumber, releaseNotes, releaseDate } = props;
  return (
    <div className="release_notes_container" id={"release_notes"}>
      <h3>{versionNumber && `Version ${versionNumber}`}</h3>
      <p className="release_date">{releaseDate}</p>
      <div dangerouslySetInnerHTML={{ __html: releaseNotes }} />
    </div>
  );
};
