import { isCustomDate, isDigitalPerformanceDate } from "../utils/dateFunctions";
import { newApiFetchV2 } from "./utils";

const postCCSDataV2 = (url: string, markets?: string, isRevenue?: boolean) => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);
  const interimDateRange = parameters.get("date_range");

  if (isCustomDate(interimDateRange as string) || isDigitalPerformanceDate(interimDateRange as string) || !interimDateRange)
    return { error: "wrong date parameters" };

  const date_range = parameters.get("date_range")?.split(",") || parameters.get("date_range");
  const model = parameters.get("model") === "All" ? (isRevenue ? ["All"] : []) : parameters.get("model")?.split(",");
  const topic = parameters.get("topic") === "All" ? [] : parameters.get("topic")?.split(",");
  const region = parameters.get("region");
  const market = markets ? markets.split(",") : parameters.get("market") === "All countries" ? [] : parameters.get("market")?.split(",");
  const parameterEntries: any = { ...Object.fromEntries(parameters.entries()), date_range, model, topic, region, market };

  delete parameterEntries["user_engagement_market"];
  delete parameterEntries["enrolled_user_market"];

  return newApiFetchV2(url, parameterEntries);
};

export const getAnalysisEnrollmentRateData = () => {
  return postCCSDataV2("/ccs-app-analysis/enrollment-rate");
};

export const getAnalysisUserEngagementRateData = (markets: string) => {
  return postCCSDataV2("/ccs/get-user-engagement?breakdown=true", markets);
};
