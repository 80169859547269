export const KPIDefinitionsData = [
  {
    element_name: "Book a service",
    definition: "Submit personal details for a request to book a service.",
    customer_job: "A customer browses the Nissan website and submits personal details to book a service for their car. ",
  },
  {
    element_name: "Click to chat",
    definition: "Initiation of live chat with a sales representative.",
    customer_job:
      "A customer browses the Nissan website and clicks on a chatbot to speak to a sales representative (for instance, about a car that they are interested in). ",
  },
  {
    element_name: "Click to dealer website",
    definition: "Click on a link out to a dealer's website.(Tier 1 to Tier 3 visits)",
    customer_job:
      "A customer browses the Nissan website and looks at dealers in their area. Then the customer clicks on a link to go to a dealers website.",
  },

  {
    element_name: "Compare tool interactions",
    definition: "Utilise the compare tool to assess differences between two or more versions of a car.",
    customer_job:
      "A customer browses the various models of Nissan cars and selects one that they are interested in. The customer is curious about the different versions and grades offered for this model. The customer then utilizes the compare tool to assess the differences between the versions and grades.",
  },
  {
    element_name: "Compare tool views",
    definition: "View the compare tool (without utilising it)",
    customer_job:
      "A customer browses the various models of Nissan cars and selects one that they are interested in. The customer is curious about the different versions and grades offered for this model. However, the customer has not yet interacted with the compare tool and cannot see the difference between each version or grade. ",
  },
  {
    element_name: "Competitive advantage",
    definition: "Compare similar vehicles from competitors and read about the Nissan competitive advantage.",
    customer_job:
      "A customer browses the Nissan website and selects a vehicle that they are interested in. The customer then reads a page that explains the advantage of a Nissan vehicle against the competition (e.g. Nissan Rogue versus Toyota RAV4 or Honda CR-V).",
  },
  {
    element_name: "Configurator completion",
    definition: "View a summary of the vehicle which was customised using the configurator tool.",
    customer_job:
      "A customer is browsing the Nissan website and sees an option to build a vehicle via what is called the configurator tool. They begin utilzing this tool to customize their ideal car, adjusting the make, model, color, and more. After adjusting the vehicle to their liking, the customer sees a summary of their completed vehicle.",
  },
  {
    element_name: "Configurator engagement",
    definition: "Interact with the car configurator by adding or editing an item to the car.",
    customer_job:
      "A customer is browsing the Nissan website and sees an option to build a vehicle via what is called the configurator tool. They begin utilzing this tool to customize their ideal car, adjusting the make, model, color, and more. ",
  },
  {
    element_name: "Contact Dealer",
    definition: "Submit personal details via the `contact us` page or the `contact your dealer` page to get in touch with a dealership.",
    customer_job: "A customer browses the Nissan website and fills out personal contact information to get in touch with a dealership. ",
  },
  {
    element_name: "Contact Dealer (Regional)",
    definition: "Submit personal details via the `contact us` page or the `contact your dealer` page to get in touch with a dealership.",
    customer_job:
      "A customer in the US browses the Regional Offers Pages and sees a car that they are interested in purchasing. As a result, the customer submits personal details to get in touch with a dealership.",
  },
  {
    element_name: "CPO",
    definition: "Submit personal details for an equiry related to a Nissan CPO [Certifed Pre-Owned/Used car]",
    customer_job:
      "A customer browses the Nissan Used Car website (Certified Pre-Owned) and fills out personal contact information to get in touch with a dealership about a specific pre-owned vehicle. ",
  },
  {
    element_name: "Credit score",
    definition: "Submit personal details from a 3rd party credit score tool. ",
    customer_job:
      "A customer in the US utilizes a 3rd party credit score tool and submits information to be contacted by their local Nissan dealer. ",
  },
  {
    element_name: "Dealer contact",
    definition: "Click on a link to contact a dealership via phone number or email.",
    customer_job:
      "A customer browses the Nissan website and looks at a map with all of the dealerships in their country. The customer then inputs their location to find a dealer nearby, select a dealership, and  contact the dealer either by clicking on a phone number or an email address. ",
  },
  {
    element_name: "Dealer search",
    definition: "Search for the nearest dealers in a given area.",
    customer_job:
      "A customer browses the Nissan website and looks at a map with all of the dealerships in their country. The customer then inputs their location to find dealer nearby.",
  },
  {
    element_name: "Downloads",
    definition: "Download of a PDF document.",
    customer_job:
      "A customer browses the Nissan website and downloads a document such as a price list for a given model, a digital brochure, etc. ",
  },
  {
    element_name: "Finance calculator",
    definition: "Utilisation of the finance calculator to estimate the total cost of buying a car.",
    customer_job:
      "A customer browses the Nissan website and selects a vehicle that they are interested in. The customer utilizes the finance calculator to select various payment options such as the number of installments, the numbre of months for the lease and more in order to estimate the cost of buying the car that they are interested in. ",
  },
  {
    element_name: "Find a dealer",
    definition:
      "View a page with with a dealer locator (either data-driven dealer locator with a map, or editoriable content with a list of dealership in the country)",
    customer_job:
      "A customer browses the Nissan website and looks at a map or a list of dealerships in the region in which they are searching.",
  },
  {
    element_name: "Inbound calls",
    definition: "Call a dealer after seeing a (personalized, DailogTech) dealer phone number on NissanUSA.com",
    customer_job: "A customer browses the Nissan USA website and finds a dealer that they want to call. The customer calls the dealer.",
  },
  {
    element_name: "Information request",
    definition:
      "Submit personal details to request further information about Nissan. (Used only in Australia during the website transition period.)",
    customer_job:
      "A customer browses the Nissan website and submits personal details to request more information. (Used only in Australia during the website transition period.)",
  },
  {
    element_name: "Inventory details request",
    definition: "Submit personal details to request information regarding a new car available at a dealership.",
    customer_job:
      "A customer browses the Nissan website and sees an option to discover new cars available at a dealership. After selecting a nearby dealer, the customer views cars available at their chosen dealership. The customer then proceeds to input personal details such as contact information to obtain more details about a specific car in the dealership inventory.",
  },
  {
    element_name: "Inventory search results",
    definition: "View or interact with details about a new car in a dealership inventory.",
    customer_job:
      "A customer browses the Nissan website and sees an option to discover new cars available at a dealership. After selecting a nearby dealer, the customer views cars available at their chosen dealership.",
  },
  {
    element_name: "Keep me informed",
    definition: "Submit personal details to be kept updated about Nissan via email (e.g. Newsletters).",
    customer_job:
      "A customer browses the Nissan website and sees an option to be kept informed about a car that they are intersted in. The customer submits personal details to receive updates.",
  },
  {
    element_name: "Offer details request",
    definition: "Submit personal details to be contacted by a local dealership for more detailed information on offers.",
    customer_job:
      "A customer browses the Nissan website and selects a vehicle that they are interested in. Next, the customer clicks on `View Offer` which shows them special pricing for the vehicle that they have selected. The customer then submits personal details to receive more information and eligibility of the offer.",
  },
  {
    element_name: "Offer engagement",
    definition: "View more details for a special pricing for a vehicle. ",
    customer_job:
      "A customer browses the Nissan website and selects a vehicle that they are interested in. Next, the customer clicks on `View Offer` which shows them special pricing for the vehicle they selected.",
  },
  {
    element_name: "Offer views",
    definition: "View a page with an offer (dealer offer or editorial content), or click on a link going to an offer page.",
    customer_job:
      "A customer browses the Nissan website and views a page with special vehicle pricing (an offer), or clicks on a link to go to a page with an offer. ",
  },
  {
    element_name: "Other forms",
    definition: "Catch all for all forms which are not mentioned above (only used in Australia during the website transition period).",
    customer_job: "A customer browses the Nissan website and submits a form. ",
  },
  {
    element_name: "Payment estimator",
    definition: "Submit personal details to determine financing. ",
    customer_job:
      "A customer browses the Nissan USA website and decides they are interested in purchasing a vehicle. In order to estimate financing options, the customer submits personal details. ",
  },
  {
    element_name: "Pre-approval",
    definition: "Submit personal details to the pre-approval credit application. ",
    customer_job:
      "A customer browses the Nissan USA website and decides they are interested in purchasing a vehicle. In order to get their vehicle financing approval quickly, the customer submits personal details on the NMAC Pre-Approval form. ",
  },
  {
    element_name: "Pre-launch information",
    definition: "Submit personal details to be kept informed about the launch of a vehicle or reservation process.",
    customer_job:
      "A customer browses the Nissan website and sees that a new vehicle will be launched soon. The customer is interested in this vehicle and submits personal details such as contact information to be kept informed about this.",
  },
  {
    element_name: "Online pre-order",
    definition: "Submit personal details and bank details for bank-preauthorisation in order to pre-order a new vehicle. ",
    customer_job:
      "A customer browses the Nissan website and selects a vehicle they are interested in. The customer decides to pre-order the car by submitting personal details and a holding fee so they are the first to be able to purchase the vehicle. ",
  },
  {
    element_name: "Request a brochure",
    definition: "Submit personal details to be sent a brochure for a given vehicle.",
    customer_job:
      "A customer browses the Nissan website and requests a brochure with more details on the car that they are interested in. ",
  },
  {
    element_name: "Request a brochure",
    definition: "Submit personal details to be sent a brochure for a given vehicle.",
    customer_job:
      "A customer browses the Nissan website and requests a brochure with more details on the car that they are interested in. ",
  },
  {
    element_name: "Request a call back",
    definition: "Submit personal details to be contacted by a local dealership.",
    customer_job:
      "A customer is browsing the Nissan website and finds a nearby dealer. The customer submits details to be contacted by the dealership.",
  },
  {
    element_name: "Request a quote",
    definition: "Submit personal details for a qoute request for a specified vehicle.",
    customer_job:
      "A customer browses the Nissan website and sees a car that they are interested in purchasing. As a result, the customer submits personal details such as contact information in order to request a quote for the vehicle that they selected.",
  },
  {
    element_name: "Request a quote (Build/Configurator)",
    definition:
      "Submit personal details for a qoute request for a specified vehicle. This form is located on the Summary page of the Car Configurator.",
    customer_job:
      "A customer browses the Nissan website and sees an option to build a vehicle via the configurator tool. They begin utilzing this tool to customize their ideal car, adjusting the make, model, color, and more. After adjusting the vehicle to their liking, the customer sees a summary of their build and submits personal details such as contact information to request a quote.",
  },
  {
    element_name: "Request a quote (Studio)",
    definition: "Submit personal details for a quote request for a specified vehicle (only used in USA on their Studio pages)",
    customer_job:
      "A customer browses the Nissan USA website and is interested in the Kicks model. The customer clicks on the `Color Studio` button, and begins customising their vehicle. Next, the customer submits personal details to request a quote. ",
  },
  {
    element_name: "Reserve online",
    definition: "Submit personal details for a vehicle reservation, without paying online.",
    customer_job:
      "A customer browses the Nissan website and finds a car that they would like to purchase. In order to reserve this vehicle, the customer submits personal details for a dealership or call center to proceed with the transaction.",
  },
  {
    element_name: "Test drive request",
    definition: "Submit personal details to request to book a test drive.",
    customer_job:
      "A customer browses the Nissan website and finds a nearby dealer with a car that they are interested in. The customer submits personal details such as contact information to request to book a test drive. ",
  },
  {
    element_name: "Trade-in",
    definition: "Submit personal details for trading-in an existing car (any brand) for a  Nissan car.",
    customer_job:
      "A customer browses the Nissan website and select a car they are interested in. The customer decides to get a trade-in value, where they input details of the current car they own (regardless of the brand) and personal details such as contact information in order to receive the trade-in value for their current vehicle.",
  },
  {
    element_name: "Vehicle brochure download",
    definition: "Download of a Vehicle Brochure PDF.",
    customer_job:
      "A customer browses the Nissan website and downloads a brochure with more details on the car(s) that they are interested in. ",
  },
  {
    element_name: "Video call request",
    definition: "Submit personal details to be contacted by a local dealership via video.",
    customer_job:
      "A customer browses the Nissan website and finds a nearby dealer that they would like to speak to. The customer submits personal details such as contact information to request a video call from the dealership.",
  },
];
