import { ErrorBoundary } from "@sentry/react";
import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg } from "../../../../components/AppMessages";
import { DefaultTable } from "../../../../components/Tables/subs";

interface Props {
  colorsMapping: Record<string, string>;
  unselectedMarkets: Array<string>;
  onClickMarket: (market: string) => void;
}

const EuropeAppRatingsBreakdownTable = (props: Props) => {
  const { colorsMapping, unselectedMarkets, onClickMarket } = props;
  const { avg_data: europeAvgData, total_data: europeTotalData } = useSelector(
    (state: RootStateOrAny) => state.ccs_cockpit.europe_app_ratings_avg_data
  );

  const evTotal = useMemo(() => {
    if (europeTotalData.length > 0) {
      return europeTotalData.filter((item: any) => item.app == "ev")[0]?.rating;
    }
    return 0;
  }, [europeTotalData]);

  const servicesTotal = useMemo(() => {
    if (europeTotalData.length > 0) {
      return europeTotalData.filter((item: any) => item.app == "services")[0]?.rating;
    }
    return 0;
  }, [europeTotalData]);

  const columns = useMemo(
    () => [
      {
        id: "market",
        Header: "Market",
        accessor: "market",
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "alphanumericIgnoreCase",
        Footer: () => {
          return <>Avg. of selected markets</>;
        },
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          const color = colorsMapping[value];
          return (
            <div
              className={`trend-legend-color ${unselectedMarkets.includes(value) ? "disabled-text" : ""}`}
              onClick={() => {
                return onClickMarket(value);
              }}
            >
              <div className="legend-item-color">
                <div
                  className={`legend-box-color ${unselectedMarkets.includes(value) ? "legend-box-disabled" : ""}`}
                  style={{ backgroundColor: color }}
                ></div>
                {value}
              </div>
            </div>
          );
        },
      },
      {
        id: "services",
        Header: "NissanConnect Services",
        accessor: "services",
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "basicCustom",
        Cell: ({ value, row }: { value: string; row: any }) => {
          const market = row.original.market;
          return <div className={`${unselectedMarkets.includes(market) ? "disabled-text" : ""}`}>{value}</div>;
        },
        Footer: () => {
          return <>{servicesTotal}</>;
        },
      },
      {
        id: "ev",
        Header: "NissanConnect EV (Legacy)",
        accessor: "ev",
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "basicCustom",
        Cell: ({ value, row }: { value: string; row: any }) => {
          const market = row.original.market;
          return <div className={`${unselectedMarkets.includes(market) ? "disabled-text" : ""}`}>{value}</div>;
        },
        Footer: (info: any) => {
          return <>{evTotal}</>;
        },
      },
    ],
    [colorsMapping, unselectedMarkets, europeAvgData, evTotal, servicesTotal]
  );

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="appRatingsBreakdownTableContainer">
        <DefaultTable
          columns={columns}
          data={europeAvgData}
          id={"europe_app_ratings_breakdown_table"}
          data-test-id={"europe_app_ratings_breakdown"}
          isChildComponent={false}
          initialSortColId={{ id: "market", desc: true }}
          hiddenCols={[]}
        />
      </div>
    </ErrorBoundary>
  );
};

export default EuropeAppRatingsBreakdownTable;
