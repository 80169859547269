import { all, put, retry, takeLatest } from "redux-saga/effects";
import {
  setAverageDaysInStockTrendData,
  setAverageListPriceTrendData,
  setAverageSalesPriceTrendData,
  setBaseTrendsData,
  setDealerTrendData,
  setInventoryByAgeData,
  setInventoryRatioData,
  setInventoryVolumeTrendData,
  setLastWeekDates,
  setLeadSalesConversionData,
  setListPriceData,
  setPerformancePerUnitData,
  setSalesRevenueTrendData,
  setSalesVolumeTrendData,
  setTotalValuesData,
  setTurnoverRatioData,
} from "../actions";
import {
  FETCH_AVERAGE_DAYS_IN_STOCK_TREND_DATA,
  FETCH_AVERAGE_LIST_PRICE_TREND_DATA,
  FETCH_AVERAGE_SALES_PRICE_TREND_DATA,
  FETCH_BASE_TRENDS_DATA,
  FETCH_DEALER_TREND_DATA,
  FETCH_INVENTORY_BY_AGE_DATA,
  FETCH_INVENTORY_RATIO_DATA,
  FETCH_INVENTORY_VOLUME_TREND_DATA,
  FETCH_LAST_WEEK_DATES,
  FETCH_LEAD_SALES_CONVERSION_DATA,
  FETCH_LIST_PRICE_DATA,
  FETCH_PERFORMANCE_PER_UNIT_DATA,
  FETCH_SALES_REVENUE_TREND_DATA,
  FETCH_SALES_VOLUME_TREND_DATA,
  FETCH_TOTAL_VALUES_DATA,
  FETCH_TURNOVER_RATIO_DATA,
} from "../actions/actionTypes";
import { setLoading } from "../actions/loadingActions";
import {
  getAverageDaysInStockTrendData,
  getAverageListPriceTrendData,
  getAverageSalesPriceTrendData,
  getBaseTrendsData,
  getDealerTrendData,
  getInventoryByAgeData,
  getInventoryRatioData,
  getInventoryVolumeTrendData,
  getLastWeekDates,
  getLeadSalesConversionData,
  getListPriceData,
  getPerformancePerUnitData,
  getSalesRevenueTrendData,
  getSalesVolumeTrendData,
  getTotalValuesData,
  getTurnoverRatioData,
} from "../api";
import { secondsDelay } from "../api/utils";

export function* watchCpoOperationalBaseTrend() {
  yield takeLatest(FETCH_BASE_TRENDS_DATA, fetchBaseTrendSaga);
}

export function* watchCpoOperationalDealerTrend() {
  yield takeLatest(FETCH_DEALER_TREND_DATA, fetchDealerTrendSaga);
}

export function* watchCpoOperationalInventoryByAgeTrend() {
  yield takeLatest(FETCH_INVENTORY_BY_AGE_DATA, fetchInventoryByAgeSaga);
}

export function* watchCpoOperationalPerformancePerUnitTrend() {
  yield takeLatest(FETCH_PERFORMANCE_PER_UNIT_DATA, fetchPerformancePerUnitSaga);
}

export function* watchCpoOperationalTotalValuesTrend() {
  yield takeLatest(FETCH_TOTAL_VALUES_DATA, fetchTotalValuesSaga);
}

export function* watchCpoOperationalListPriceTrend() {
  yield takeLatest(FETCH_LIST_PRICE_DATA, fetchListPriceSaga);
}

export function* watchCpoOperationalInventoryRatioTrend() {
  yield takeLatest(FETCH_INVENTORY_RATIO_DATA, fetchInventoryRatioSaga);
}

export function* watchCpoOperationalTurnoverRatioTrend() {
  yield takeLatest(FETCH_TURNOVER_RATIO_DATA, fetchTurnoverRatioSaga);
}

export function* watchCpoOperationalLeadSalesConversionTrend() {
  yield takeLatest(FETCH_LEAD_SALES_CONVERSION_DATA, fetchLeadSalesConversionSaga);
}

export function* watchCpoOperationalInventoryVolumeTrend() {
  yield takeLatest(FETCH_INVENTORY_VOLUME_TREND_DATA, fetchInventoryVolumeSaga);
}

export function* watchCpoOperationalAverageDaysInStockTrend() {
  yield takeLatest(FETCH_AVERAGE_DAYS_IN_STOCK_TREND_DATA, fetchAverageDaysInStockSaga);
}

export function* watchCpoOperationalSalesVolumeTrend() {
  yield takeLatest(FETCH_SALES_VOLUME_TREND_DATA, fetchSalesVolumeSaga);
}

export function* watchCpoOperationalSalesRevenueTrend() {
  yield takeLatest(FETCH_SALES_REVENUE_TREND_DATA, fetchSalesRevenueSaga);
}

export function* watchCpoOperationalAverageListPriceTrend() {
  yield takeLatest(FETCH_AVERAGE_LIST_PRICE_TREND_DATA, fetchAverageListPriceSaga);
}

export function* watchCpoOperationalAverageSalesPriceTrend() {
  yield takeLatest(FETCH_AVERAGE_SALES_PRICE_TREND_DATA, fetchAverageSalesPriceSaga);
}

export function* watchCpoOperationalLastWeekDates() {
  yield takeLatest(FETCH_LAST_WEEK_DATES, fetchLastWeekDatesSaga);
}

export function* fetchBaseTrendSaga() {
  try {
    yield put(setLoading("base_trend", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getBaseTrendsData, searchParams);

    if (!("error" in response)) {
      yield all([put(setBaseTrendsData(response)), put(setLoading("base_trend", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchDealerTrendSaga() {
  try {
    yield put(setLoading("dealer_trend", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getDealerTrendData, searchParams);

    if (!("error" in response)) {
      yield all([put(setDealerTrendData(response)), put(setLoading("dealer_trend", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchInventoryByAgeSaga() {
  try {
    yield put(setLoading("inventory_by_age", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getInventoryByAgeData, searchParams);

    if (!("error" in response)) {
      yield all([put(setInventoryByAgeData(response)), put(setLoading("inventory_by_age", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchPerformancePerUnitSaga() {
  try {
    yield put(setLoading("performance_per_unit", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getPerformancePerUnitData, searchParams);

    if (!("error" in response)) {
      yield all([put(setPerformancePerUnitData(response)), put(setLoading("performance_per_unit", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchTotalValuesSaga() {
  try {
    yield put(setLoading("total_values", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getTotalValuesData, searchParams);

    if (!("error" in response)) {
      yield all([put(setTotalValuesData(response)), put(setLoading("total_values", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchListPriceSaga() {
  try {
    yield put(setLoading("list_price", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getListPriceData, searchParams);

    if (!("error" in response)) {
      yield all([put(setListPriceData(response)), put(setLoading("list_price", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchInventoryRatioSaga() {
  try {
    yield put(setLoading("inventory_ratio", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getInventoryRatioData, searchParams);

    if (!("error" in response)) {
      yield all([put(setInventoryRatioData(response)), put(setLoading("inventory_ratio", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchTurnoverRatioSaga() {
  try {
    yield put(setLoading("turnover_ratio", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getTurnoverRatioData, searchParams);

    if (!("error" in response)) {
      yield all([put(setTurnoverRatioData(response)), put(setLoading("turnover_ratio", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchLeadSalesConversionSaga() {
  try {
    yield put(setLoading("lead_sales_conversion", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getLeadSalesConversionData, searchParams);

    if (!("error" in response)) {
      yield all([put(setLeadSalesConversionData(response)), put(setLoading("lead_sales_conversion", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchInventoryVolumeSaga() {
  try {
    yield put(setLoading("inventory_volume_trend", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getInventoryVolumeTrendData, searchParams);

    if (!("error" in response)) {
      yield all([put(setInventoryVolumeTrendData(response)), put(setLoading("inventory_volume_trend", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchAverageDaysInStockSaga() {
  try {
    yield put(setLoading("average_days_in_stock_trend", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getAverageDaysInStockTrendData, searchParams);

    if (!("error" in response)) {
      yield all([put(setAverageDaysInStockTrendData(response)), put(setLoading("average_days_in_stock_trend", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchSalesVolumeSaga() {
  try {
    yield put(setLoading("sales_volume_trend", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getSalesVolumeTrendData, searchParams);

    if (!("error" in response)) {
      yield all([put(setSalesVolumeTrendData(response)), put(setLoading("sales_volume_trend", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchSalesRevenueSaga() {
  try {
    yield put(setLoading("sales_revenue_trend", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getSalesRevenueTrendData, searchParams);

    if (!("error" in response)) {
      yield all([put(setSalesRevenueTrendData(response)), put(setLoading("sales_revenue_trend", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchAverageListPriceSaga() {
  try {
    yield put(setLoading("average_list_price", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getAverageListPriceTrendData, searchParams);

    if (!("error" in response)) {
      yield all([put(setAverageListPriceTrendData(response)), put(setLoading("average_list_price", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchAverageSalesPriceSaga() {
  try {
    yield put(setLoading("average_sales_price", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getAverageSalesPriceTrendData, searchParams);

    if (!("error" in response)) {
      yield all([put(setAverageSalesPriceTrendData(response)), put(setLoading("average_sales_price", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchLastWeekDatesSaga() {
  try {
    yield put(setLoading("last_week_dates", true));
    const response: Record<any, any> = yield retry(4, secondsDelay, getLastWeekDates);

    if (!("error" in response)) {
      yield all([put(setLastWeekDates(response)), put(setLoading("last_week_dates", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}
