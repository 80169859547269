import moment from "moment";
import { DEFAULT_BRAND_PERFORMANCE_DATE_PARAMS } from "../constants";
import { apiFetchV2, fetchDataV2 } from "./utils";

const getStartEndDateOfQuarter = (quarter: string, isChina?: boolean) => {
  if (isChina) {
    if (quarter?.includes("Q2")) return { startDate: "1 Apr", endDate: "30 Jun" };
    if (quarter?.includes("Q3")) return { startDate: "1 Jul", endDate: "30 Sep" };
    if (quarter?.includes("Q4")) return { startDate: "1 Oct", endDate: "31 Dec" };
    if (quarter?.includes("Q1")) return { startDate: "1 Jan", endDate: "31 Mar" };
  } else {
    if (quarter?.includes("Q1")) return { startDate: "1 Apr", endDate: "30 Jun" };
    if (quarter?.includes("Q2")) return { startDate: "1 Jul", endDate: "30 Sep" };
    if (quarter?.includes("Q3")) return { startDate: "1 Oct", endDate: "31 Dec" };
    if (quarter?.includes("Q4")) return { startDate: "1 Jan", endDate: "31 Mar" };
  }
  return { startDate: "", endDate: "" };
};

const getEarliestAndLatestDate = (dates: string[], isChina?: boolean) => {
  const quarterSortOrder = ["Q1", "Q2", "Q3", "Q4"];

  const availableYears = Array.from(new Set(dates?.map((date) => parseInt(date.split(" ")[2], 10))));
  const earliestYear = Math.min(...availableYears);
  const latestYear = Math.max(...availableYears);
  const earliestQuarter = dates
    ?.filter((date) => date?.includes(earliestYear.toString()))
    .sort((a, b) => quarterSortOrder?.indexOf(a) - quarterSortOrder?.indexOf(b))[0];

  const latestQuarter = dates
    ?.filter((date) => date?.includes(latestYear.toString()))
    .sort((a, b) => quarterSortOrder?.indexOf(a) - quarterSortOrder?.indexOf(b))
    .slice(-1)[0];

  const { startDate: startDateEarliest } = getStartEndDateOfQuarter(earliestQuarter, isChina);
  const { endDate: endDatelatest } = getStartEndDateOfQuarter(latestQuarter, isChina);

  return {
    startDate: `${startDateEarliest} ${earliestYear}`,
    endDate: `${endDatelatest} ${dates?.length > 1 && !isChina && latestQuarter?.includes("Q4") ? latestYear + 1 : latestYear}`,
  };
};

export const brandPerformanceApiParamsToObject = (params: string) => {
  const parameters = new URLSearchParams(params);
  const paramObj = Object.fromEntries(parameters.entries());

  const dateRangeParam = parameters?.get("date_range");
  const brandParam = paramObj?.brand?.split(",");
  const modelParameter = paramObj?.model?.split(",");
  const marketParam = paramObj?.market?.split(",");
  const subCategoryParam = paramObj?.sub_category?.split(",");
  const attributeParam = /all/gi.test(paramObj?.attribute) ? [] : paramObj?.attribute?.split(",");
  const categoryParam = paramObj?.category?.split(",");
  const competitorParam = /all/gi.test(paramObj?.competitor) ? [] : paramObj?.competitor?.split(",");
  const dateRangeBrandPerf = parameters?.get("date_range_brand_perf")?.split(",") ?? [];
  const performancePageParam = parameters?.get("performance_page") ?? "volume";
  const granularityParam = parameters?.get("granularity") ?? "monthly";

  let date = dateRangeParam
    ? DEFAULT_BRAND_PERFORMANCE_DATE_PARAMS.includes(dateRangeParam)
      ? dateRangeParam.split(" ").join("_").toLocaleLowerCase()
      : "custom"
    : "last_quarter";

  if (performancePageParam == "volume") {
    if (date == "custom") {
      paramObj["start_date"] = dateRangeParam
        ? moment(dateRangeParam.split("-")[0].trim(), "MMMM YYYY").startOf("month").format("YYYY-MM-DD")
        : "";
      paramObj["end_date"] = dateRangeParam
        ? moment(dateRangeParam.split("-")[1].trim(), "MMMM YYYY").endOf("month").format("YYYY-MM-DD")
        : "";
    }
  } else {
    const { startDate, endDate } = getEarliestAndLatestDate(dateRangeBrandPerf, marketParam[0] == "China");
    paramObj["start_date"] = moment(startDate.trim(), "DD MMM YYYY").format("YYYY-MM-DD");
    paramObj["end_date"] = moment(endDate.trim(), "DD MMM YYYY").format("YYYY-MM-DD");
    date = "custom";
  }

  delete paramObj?.model;
  delete paramObj?.market;
  delete paramObj?.brand;
  delete paramObj?.competitor;
  delete paramObj?.date_range;
  delete paramObj?.sub_category;
  delete paramObj?.attribute;
  delete paramObj?.category;
  delete paramObj?.performance_page;
  delete paramObj?.date_range_brand_perf;
  delete paramObj?.nissan_competitor;
  delete paramObj?.granularity;

  return {
    ...paramObj,
    date_range: date,
    model: modelParameter,
    market: marketParam,
    brand: brandParam,
    competitor: competitorParam,
    sub_category: subCategoryParam,
    attribute: attributeParam,
    category: categoryParam,
    granularity: granularityParam == undefined ? "monthly" : granularityParam,
  };
};

const getNissanCompetitorParam = (params: string): boolean => {
  const parameters = new URLSearchParams(params);
  const paramObj = Object.fromEntries(parameters.entries());
  return paramObj?.nissan_competitor == "nissan_competitor";
};

export const getBrandVolumeTrend = (params: string) =>
  apiFetchV2(
    `/brand-performance/brand-volume/brand-volume-trend?use_cache=true&nissan_competitor=${getNissanCompetitorParam(params)}`,
    brandPerformanceApiParamsToObject(params)
  );

export const getBrandVolumeAverage = (params: string) =>
  apiFetchV2(
    `/brand-performance/brand-volume/brand-volume-average?use_cache=true&nissan_competitor=${getNissanCompetitorParam(params)}`,
    brandPerformanceApiParamsToObject(params)
  );

export const getBrandOaOTrend = (params: string) =>
  apiFetchV2(
    `/brand-performance/brand-oao/brand-oao-trend?use_cache=true&nissan_competitor=${getNissanCompetitorParam(params)}`,
    brandPerformanceApiParamsToObject(params)
  );

export const getBrandOaOAverage = (params: string) =>
  apiFetchV2(
    `/brand-performance/brand-oao/brand-oao-average?use_cache=true&nissan_competitor=${getNissanCompetitorParam(params)}`,
    brandPerformanceApiParamsToObject(params)
  );

export const getBrandImageAttributesTrend = (params: string) =>
  apiFetchV2(
    `/brand-performance/brand-image-attributes/brand-image-attributes-trend?use_cache=true&nissan_competitor=${getNissanCompetitorParam(
      params
    )}`,
    brandPerformanceApiParamsToObject(params)
  );

export const getBrandImageAttributesAverage = (params: string) =>
  apiFetchV2(
    `/brand-performance/brand-image-attributes/brand-image-attributes-average?use_cache=true&nissan_competitor=${getNissanCompetitorParam(
      params
    )}`,
    brandPerformanceApiParamsToObject(params)
  );

export const getBrandPerformanceDataDefinitions = () => fetchDataV2(`/brand-performance/data-definition`);
