export const MIDAS_BTN_FILTERS = [
  { id: "fmi_run_rate_btn", name: "FMI run rate", navigateTo: "/midas/fmi_run_rate" },
  { id: "fmi_by_model_btn", name: "MEDIA-FMI by model", navigateTo: "/midas/fmi_by_model" },
  { id: "medi_mix_btn", name: "Media mix", navigateTo: "/midas/media_mix" },
  { id: "mtp_category_btn", name: "Mtp category", navigateTo: "/midas/mtp_category" },
  { id: "strategic_-_dealer_support_btn", name: "Strategic - Dealer support", navigateTo: "/midas/strategic_dealer_support" },
  { id: "strategic_-_ce_btn", name: "Strategic - CE", navigateTo: "/midas/strategic_ce" },
  { id: "fixed_btn", name: "Fixed", navigateTo: "/midas/fixed" },
  { id: "spend_vs_traffic_btn", name: "Spend Vs Traffic", navigateTo: "/midas/spend_vs_traffic" },
];
