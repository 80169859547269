import React from "react";
import { Tooltip } from "react-tippy";
import MarketDeepDiveTable from "./MarketDeepDiveTable";
import MarketDeepDiveTrendline from "./MarketDeepDiveTrendline";
import { getMarketDeepDiveTable, getMarketDeepDiveTrendline } from "../../../../api/quickVocSalesApi";

interface Props {
  tableQueryFn: () => Record<string, any>;
  trendlineQueryFn: () => Record<string, any>;
}

const MarketDeepDive = (props: Props) => {
  const { tableQueryFn, trendlineQueryFn } = props;

  return (
    <div className="tile">
      <div className="tile-header">
        <div className="title-container">
          <div className="title">Market Deep Dive</div>
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title="Number of feedback and overall scores per market. The 3-month rolling window (R3M) is looking at the last 3 months based on the Date range selection."
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      <div className="grid-row-2">
        <MarketDeepDiveTable queryFn={tableQueryFn} />
        <MarketDeepDiveTrendline queryFn={trendlineQueryFn} />
      </div>
    </div>
  );
};

export default MarketDeepDive;
