import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import Collapsible from "../../../../components/Collapsible/Collapsible";
import SpendVsTrafficMetric from "./SpendVsTrafficMetric";

const SpendVsTrafficGraph = () => {
  const marketData = useSelector((state: RootStateOrAny) => state.midas.midas_spend_vs_traffic_market_data);
  const modelData = useSelector((state: RootStateOrAny) => state.midas.midas_spend_vs_traffic_model_data);

  const models: string[] = useMemo(() => {
    if (modelData?.length) {
      return Array.from(new Set(modelData?.map((value: { model: string }) => value.model)));
    }
    return [];
  }, [modelData]);

  return (
    <div className="spend_vs_traffic_charts">
      <Collapsible myStyle="spend_sotu" show={true} title="Media buy x Web visits">
        <SpendVsTrafficMetric metric="visits" models={models} modelData={modelData} metricData={marketData} />
      </Collapsible>
      <Collapsible myStyle="spend_sotu" show={true} title="Media buy x KBA">
        <SpendVsTrafficMetric metric="kbas" models={models} modelData={modelData} metricData={marketData} />
      </Collapsible>
      <Collapsible myStyle="spend_sotu" show={true} title="Media buy x leads">
        <SpendVsTrafficMetric metric="leads" models={models} modelData={modelData} metricData={marketData} />
      </Collapsible>
    </div>
  );
};

export default SpendVsTrafficGraph;
