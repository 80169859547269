import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import ProductPerformancePieChart from "../../../components/Charts/ProductPerformance/ProducetPerformancePieChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../components/Ratings/Ratings";
import { ThemeContext } from "../../../context";
import { getGranularityFromDate, getValueOrNa, getValueOrNull } from "../../../utils/utilityFunctions";
import MetricTile from "./MetricTile";
import ReleaseGrid from "./ReleaseGrid";

const PoPLabelMapping: Record<string, string> = {
  weekly: "WoW",
  monthly: "MoM",
  quarterly: "QoQ",
};

const GlobalPerformance = () => {
  const themeContext = useContext(ThemeContext);

  const { granularity: granularityParam, date_range: dateRangeParam } = useSelector((state: RootStateOrAny) => state.parameters);

  const {
    Current: liveMarketsCurrent,
    PoP: liveMarketsPoP,
    YoY: liveMarketsYoY,
  } = useSelector((state: RootStateOrAny) => state.product_performance.live_markets);
  const {
    Current: featuresActivatedCurrent,
    PoP: featuresActivatedPoP,
    YoY: featuresActivatedYoY,
  } = useSelector((state: RootStateOrAny) => state.product_performance.features_activated);
  const {
    Current: totalBugsCurrent,
    PoP: totalBugsPoP,
    YoY: totalBugsYoY,
  } = useSelector((state: RootStateOrAny) => state.product_performance.total_bugs);
  const {
    live_markets: liveMarketsLoading,
    features_activated: featuresActivatedLoading,
    total_bugs: totalBugsLoading,
    bugs_release_breakdown: bugsReleaseBreakdownLoading,
    bugs_status_breakdown: bugsStatusBreakdownLoading,
    error_pages: errorPagesLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);
  const bugsStatusBreakdownData = useSelector((state: RootStateOrAny) => state.product_performance.bugs_status_breakdown);
  const bugsReleaseBreakdownData = useSelector((state: RootStateOrAny) => state.product_performance.bugs_release_breakdown);
  const {
    Current: errorPagesCurrent,
    PoP: errorPagesPoP,
    YoY: errorPagesYoY,
  } = useSelector((state: RootStateOrAny) => state.product_performance.error_pages);

  const PoPLabel = useMemo(() => {
    const dateGranularity = getGranularityFromDate(dateRangeParam);
    return PoPLabelMapping[dateGranularity];
  }, [dateRangeParam]);

  const showPoP = useMemo(() => {
    if (dateRangeParam.includes("-") || dateRangeParam.includes("year")) {
      return false;
    }
    return true;
  }, [dateRangeParam]);

  const preReleaseGridValues = useMemo((): Array<{ name: string; value: number }> => {
    const preReleaseValues = Object.entries(bugsReleaseBreakdownData).filter(([key, value]) => key.includes("pre_release"));
    return preReleaseValues.map(([key, value]): { name: string; value: number } => {
      const name = key.split("_").pop();
      return { name: (name as string).toUpperCase(), value: Number(value) };
    });
  }, [bugsReleaseBreakdownData]);

  const postReleaseGridValues = useMemo((): Array<{ name: string; value: number }> => {
    const preReleaseValues = Object.entries(bugsReleaseBreakdownData).filter(([key, value]) => key.includes("post_release"));
    return preReleaseValues.map(([key, value]): { name: string; value: number } => {
      const name = key.split("_").pop();
      return { name: (name as string).toUpperCase(), value: Number(value) };
    });
  }, [bugsReleaseBreakdownData]);

  return (
    <div className="grid-5 h-15">
      <MetricTile
        title="LIVE MARKETS"
        isLoading={liveMarketsLoading}
        currentData={liveMarketsCurrent}
        PoPData={liveMarketsPoP}
        YoYData={liveMarketsYoY}
        PoPLabel={PoPLabel}
        paramKey="live_markets"
        tooltipText="Markets with at least 10 visits in the period"
        showPoP={showPoP}
      />
      <MetricTile
        title="FEATURES ACTIVATED"
        isLoading={featuresActivatedLoading}
        currentData={featuresActivatedCurrent}
        PoPData={featuresActivatedPoP}
        YoYData={featuresActivatedYoY}
        PoPLabel={PoPLabel}
        paramKey="features"
        showPoP={showPoP}
      />
      <div className="grid-col-span-2 pp-tile">
        <div className="tile-header justify-content-left">BUGS</div>
        <div className="grid-6 full-height">
          <div className="grid-col-span-1 full-height flex-column">
            <LoadingEllipsis isLoading={featuresActivatedLoading} />
            <>
              <p className="bugs-value">
                {getValueOrNa(totalBugsCurrent, "total_bugs")}
                <br />
                <span className="sub-text">total bugs</span>
              </p>
              {showPoP && (
                <div className="comparison bugs-comparison">
                  <Ratings
                    value={getValueOrNull(totalBugsPoP, "pts_total_bugs")}
                    isPercentageValue={false}
                    ratingIsInverse={true}
                    testValue={false}
                  />
                  <span className="comparison_value">{getValueOrNa(totalBugsPoP, "pts_total_bugs")}</span>
                  <span className="comparison_label">{PoPLabel}</span>
                </div>
              )}
              <div className="comparison bugs-comparison">
                <Ratings
                  value={getValueOrNull(totalBugsYoY, "pts_total_bugs")}
                  isPercentageValue={false}
                  ratingIsInverse={true}
                  testValue={false}
                />
                <span className="comparison_value">{getValueOrNa(totalBugsYoY, "pts_total_bugs")}</span>
                <span className="comparison_label">{"YoY"}</span>
              </div>
            </>
          </div>
          <div className="grid-col-span-3 full-height">
            <ProductPerformancePieChart
              data={bugsStatusBreakdownData}
              chartId="bugsStatusBreakdownPieChart"
              categoryField="category"
              valueField="value"
              theme={themeContext.theme}
            />
          </div>
          <div className="grid-col-span-2 flex-v-center">
            <ReleaseGrid title="Pre-release" grigValues={preReleaseGridValues} extraClass="border-bottom-dotted" />
            <ReleaseGrid title="Post-release" grigValues={postReleaseGridValues} />
            <LoadingEllipsis isLoading={totalBugsLoading || bugsReleaseBreakdownLoading || bugsStatusBreakdownLoading} />
          </div>
        </div>
      </div>
      <MetricTile
        title="ERROR PAGES"
        isLoading={errorPagesLoading}
        currentData={errorPagesCurrent}
        PoPData={errorPagesPoP}
        YoYData={errorPagesYoY}
        PoPLabel={PoPLabel}
        paramKey="visits"
        showPoP={showPoP}
        ratingIsInverse={true}
      />
    </div>
  );
};

export default GlobalPerformance;
