import { makeStyles, withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import React, { Dispatch, SetStateAction } from "react";
import { WHITE } from "../../../../constants";

interface Props {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

const WhiteTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      color: WHITE, // Text color
      height: "28px",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#fff8", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: WHITE, // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: WHITE, // Solid underline on focus
    },
  },
})(TextField);

const UserSearchField = (props: Props) => {
  const { value, setValue } = props;

  const classes = makeStyles({
    textField: {
      height: "40px !important",
    },
    input: {
      color: `${WHITE} !important`,
      "&::before": {
        borderBottom: "1px solid #FFF",
      },
      "&::placeholder": {
        color: WHITE,
      },
    },
  })();

  return (
    <WhiteTextField
      value={value}
      placeholder="Search"
      InputProps={{
        classes: { input: classes.input },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setValue("")}>
              <CloseIcon style={{ color: WHITE }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{
        "data-test-id": "user-search-input",
      }}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

export default UserSearchField;
