import moment from "moment";
import React from "react";
import "../../../assets/styles/component/granularityOptions.scss";
import { getStartEnd } from "../../../utils/dateFunctions";
import { getGranularitiesToDisable } from "../helpers/helpers";

const GranularityOptions = (props: {
  dateValue: string | null;
  granularity: string;
  name: string;
  onHandleRadioClick: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  isSearchInterest?: boolean;
  granularitiesList?: string[];
  manualDisable?: boolean;
}) => {
  const { dateValue, granularity, isSearchInterest, onHandleRadioClick, name, granularitiesList, manualDisable } = props;
  const startEnd = getStartEnd(dateValue);
  const diff = moment(startEnd.end, "DD/MM/YYYY").diff(moment(startEnd.start, "DD/MM/YYYY"), "days");
  const toDisable = getGranularitiesToDisable(diff);

  return (
    <div className="radio" id={`${name}_radio`}>
      {(!manualDisable && toDisable.includes("daily")) ||
      isSearchInterest ||
      (granularitiesList && !granularitiesList?.includes("daily")) ? null : (
        <div className="trend" data-radio-value="daily">
          <input
            type="radio"
            className="dp_radio trend_period"
            id={`${name}_daily`}
            name={name}
            value="daily"
            onChange={onHandleRadioClick}
            checked={granularity === "daily"}
          />
          <label htmlFor={`${name}_daily`} className="dp_label trend_label" data-value="daily">
            Daily
          </label>
          <br />
        </div>
      )}

      {(!manualDisable && toDisable.includes("weekly")) ||
      (granularitiesList && !granularitiesList?.includes("weekly")) ||
      isSearchInterest ? null : (
        <div className="trend" data-radio-value="weekly">
          <input
            type="radio"
            className="dp_radio trend_period"
            id={`${name}_weekly`}
            name={name}
            value="weekly"
            onChange={onHandleRadioClick}
            checked={granularity === "weekly"}
          />
          <label htmlFor={`${name}_weekly`} className="dp_label trend_label" data-value="weekly">
            Weekly
          </label>
          <br />
        </div>
      )}

      {(granularitiesList && !granularitiesList?.includes("monthly")) ||
      (!manualDisable && toDisable.includes("monthly")) ||
      (!isSearchInterest == undefined && !isSearchInterest) ? null : (
        <div className="trend" data-radio-value="monthly">
          <input
            type="radio"
            className="dp_radio trend_period"
            id={`${name}_monthly`}
            name={name}
            value="monthly"
            onChange={onHandleRadioClick}
            checked={granularity === "monthly"}
          />
          <label htmlFor={`${name}_monthly`} className="dp_label trend_label" data-value="monthly">
            Monthly
          </label>
          <br />
        </div>
      )}

      {granularitiesList && granularitiesList?.includes("quarterly") ? (
        <div className="trend" data-radio-value="quarterly">
          <input
            type="radio"
            className="dp_radio trend_period"
            id={`${name}_quarterly`}
            name={name}
            value="quarterly"
            onChange={onHandleRadioClick}
            checked={granularity === "quarterly"}
          />
          <label htmlFor={`${name}_quarterly`} className="dp_label trend_label" data-value="quarterly">
            Quarterly
          </label>
          <br />
        </div>
      ) : null}
    </div>
  );
};

export default GranularityOptions;
