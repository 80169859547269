import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import mobileIcon from "../../../../assets/images/mobile-icon.png";
import pcIcon from "../../../../assets/images/pc-icon.png";
import tabletIcon from "../../../../assets/images/tablet-icon.png";
import { ErrorMsg } from "../../../../components/AppMessages";
import InAppBanner from "../../../../components/Banners/InAppBanner";
import VocDonutChart from "../../../../components/Charts/VOC/VocDonutChart";
import VocLineTrendChart from "../../../../components/Charts/VOC/VocLineTrendChart";
import { UserEngagementMarketFilter } from "../../../../components/Filters/common/UserEngagementMarketFiler";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { CYAN, FOREST_GREEN, GOLDEN_YELLOW, PURE_ORANGE, RACING_GREEN, RED, VOC_START_RATING_CHART_COLORS } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import { plusSignNumbers } from "../../../../utils/utilityFunctions";

const ratingTrendSeries = [
  {
    name: "Experience rating",
    field: "experience_rating",
    seriesColor: CYAN,
  },
];

const donutColorMapping: Record<number, string> = {
  1: RED,
  2: PURE_ORANGE,
  3: GOLDEN_YELLOW,
  4: RACING_GREEN,
  5: FOREST_GREEN,
};

const StarRatingOverview = () => {
  const themeContext = useContext(ThemeContext);

  const { star_rating_market: starRatingMarket, objective } = useSelector((state: RootStateOrAny) => state.parameters);
  const { Current: currentAverageRating, YoY: yoyAverageRating } = useSelector(
    (state: RootStateOrAny) => state.voc.star_rating.average_rating
  );
  const { Current: currentAverageRatingSelectedMarkets, YoY: yoyAverageRatingSelectedMarkets } = useSelector(
    (state: RootStateOrAny) => state.voc.star_rating.average_of_selected_markets
  );
  const averageRatingTrendData = useSelector((state: RootStateOrAny) => state.voc.star_rating.average_rating_trend);
  const { Current: currentFeedback, YoY: yoyFeedback } = useSelector((state: RootStateOrAny) => state.voc.star_rating.feedback);
  const scoreDistributionData = useSelector((state: RootStateOrAny) => state.voc.star_rating.score_distribution);
  const deviceDistributionData = useSelector((state: RootStateOrAny) => state.voc.star_rating.device_distribution);
  const { data: avgMarketTrend, series: avgMarketSeries } = useSelector(
    (state: RootStateOrAny) => state.voc.star_rating.average_rating_by_market
  );
  const { market: marketList } = useSelector((state: RootStateOrAny) => state.filters.voc_filters);
  const {
    star_rating_average_rating: starRatingAverageLoading,
    star_rating_average_rating_trend: starRatingAverageTrendLoading,
    star_rating_feedback: starRatingFeedbackLoading,
    star_rating_score_distribution: starRatingScoreDistributionLoading,
    star_rating_device_distribution: starRatingDeviceDistributionLoading,
    star_rating_average_rating_by_market: starRatingAverageRatingByMarketLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);

  const coloredScoreDistributionData = useMemo(() => {
    return scoreDistributionData
      .sort((a: any, b: any) => b.experience_rating - a.experience_rating)
      .map((item: { experience_rating: number }) => ({
        ...item,
        color: donutColorMapping[item.experience_rating],
      }));
  }, [scoreDistributionData]);

  const deviceRatings = useMemo(() => {
    const result: Record<string, any> = {
      current: {
        pc: null,
        tablet: null,
        mobile: null,
      },
      previous: {
        pc: null,
        tablet: null,
        mobile: null,
      },
    };
    if (deviceDistributionData && deviceDistributionData.Current) {
      const pcCurrentValue = deviceDistributionData.Current.find((item: any) => item.device_type == "Computer")?.experience_rating;
      const tabletCurrentValue = deviceDistributionData.Current.find((item: any) => item.device_type == "Tablet")?.experience_rating;
      const mobileCurrentValue = deviceDistributionData.Current.find((item: any) => item.device_type == "Mobile")?.experience_rating;
      result.current.pc = pcCurrentValue != null ? pcCurrentValue : null;
      result.current.tablet = tabletCurrentValue != null ? tabletCurrentValue : null;
      result.current.mobile = mobileCurrentValue != null ? mobileCurrentValue : null;
    }
    if (deviceDistributionData && deviceDistributionData.YoY) {
      const pcYoYValue = deviceDistributionData.YoY.find((item: any) => item.device_type == "Computer")?.pts_experience_rating;
      const tabletYoYValue = deviceDistributionData.YoY.find((item: any) => item.device_type == "Tablet")?.pts_experience_rating;
      const mobileYoYValue = deviceDistributionData.YoY.find((item: any) => item.device_type == "Mobile")?.pts_experience_rating;
      result.previous.pc = pcYoYValue != null ? pcYoYValue : null;
      result.previous.tablet = tabletYoYValue != null ? tabletYoYValue : null;
      result.previous.mobile = mobileYoYValue != null ? mobileYoYValue : null;
    }
    return result;
  }, [deviceDistributionData]);

  const formattedSeries = useMemo(() => {
    if (avgMarketSeries) {
      return avgMarketSeries.map((marketItem: any, index: number) => ({
        name: marketItem.market,
        field: marketItem.field,
        seriesColor: VOC_START_RATING_CHART_COLORS[marketItem.market as string],
      }));
    }
    return [];
  }, [avgMarketSeries, VOC_START_RATING_CHART_COLORS]);

  const marketFilterValue = useMemo(() => {
    let value = "All";
    if (marketList && starRatingMarket) {
      const selectedMarketsArr = starRatingMarket.split(",");
      value =
        selectedMarketsArr?.length === marketList.length
          ? "All"
          : selectedMarketsArr?.length > 1
          ? `${selectedMarketsArr?.length} markets selected`
          : starRatingMarket;
    }
    return value;
  }, [marketList, starRatingMarket]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      {objective != "Official Rating" && (
        <InAppBanner text={"WARNING: the below section aggregates the ratings based on the Objectives filter"} />
      )}
      <div className="row grid">
        <div className="tile min-h-20">
          <div className="tileHeader">Average rating</div>
          <div className="ratings">
            {starRatingAverageLoading ? (
              <LoadingEllipsis isLoading={starRatingAverageLoading} />
            ) : (
              <>
                <div className="value">
                  <span
                    className={`actualValue ${
                      currentAverageRating
                        ? currentAverageRating[0]?.experience_rating >= 4
                          ? "textGreen"
                          : currentAverageRating[0]?.experience_rating >= 3.9
                          ? "textAmber"
                          : "textRed"
                        : ""
                    }`}
                  >
                    {currentAverageRating ? currentAverageRating[0]?.experience_rating?.toFixed(1) : "n/a"}
                  </span>
                  /<span>5</span>
                </div>
                <div className="popValue">
                  {`${
                    yoyAverageRating && yoyAverageRating[0]?.pts_experience_rating != null
                      ? plusSignNumbers(yoyAverageRating[0]?.pts_experience_rating)
                      : "n/a"
                  }`}{" "}
                  vs prev. period
                </div>
              </>
            )}
          </div>
        </div>
        <div className="tile min-h-20 grid-col-span-4">
          <div className="tileHeader">Rating trendline</div>
          <VocLineTrendChart
            chartId="vocRatingTrendline"
            data={averageRatingTrendData}
            seriesList={ratingTrendSeries}
            theme={themeContext.theme}
            maxValue={5}
            minValue={0}
            isLoading={starRatingAverageTrendLoading}
          />
        </div>
      </div>
      <div className="row grid">
        <div className="tile min-h-20">
          <div className="tileHeader"># feedbacks</div>
          <div className="ratings">
            {starRatingFeedbackLoading ? (
              <LoadingEllipsis isLoading={starRatingFeedbackLoading} />
            ) : (
              <>
                <div className="value">
                  <span className="actualValue">
                    {currentFeedback && currentFeedback[0]?.feedback != null ? currentFeedback[0]?.feedback.toLocaleString() : "n/a"}
                  </span>
                </div>
                <div className="popValue">
                  {yoyFeedback && yoyFeedback[0]?.pts_feedback != null ? plusSignNumbers(yoyFeedback[0]?.pts_feedback) : "n/a"} vs prev.
                  period
                </div>
              </>
            )}
          </div>
        </div>
        <div className="tile min-h-20 grid-col-span-2">
          <div className="tileHeader">Score distribution</div>
          <VocDonutChart
            chartId="vocScoreDistribution"
            valueField="score"
            categoryField="experience_rating"
            theme={themeContext.theme}
            data={coloredScoreDistributionData}
            totalAvg={currentAverageRating ? currentAverageRating[0]?.experience_rating?.toFixed(1) : null}
            isLoading={starRatingScoreDistributionLoading}
          />
        </div>
        <div className="tile h-50 grid-col-span-2">
          <div className="tileHeader">Rating by device</div>
          <div className="devicesContainer">
            <div className="device">
              {starRatingDeviceDistributionLoading ? (
                <LoadingEllipsis isLoading={starRatingDeviceDistributionLoading} />
              ) : (
                <>
                  <div className={`rating ${deviceRatings.current.pc ? (deviceRatings.current.pc >= 4 ? "textGreen" : "textAmber") : ""}`}>
                    {deviceRatings?.current?.pc ? deviceRatings.current.pc?.toFixed(1) : "n/a"}
                  </div>
                  <img className="deviceIcon" src={pcIcon} />
                  <div className="variance">
                    {deviceRatings.previous.pc != null ? `${plusSignNumbers(deviceRatings.previous.pc)}` : "n/a"} vs prev. period
                  </div>
                </>
              )}
            </div>
            <div className="device">
              {starRatingDeviceDistributionLoading ? (
                <LoadingEllipsis isLoading={starRatingDeviceDistributionLoading} />
              ) : (
                <>
                  <div
                    className={`rating ${
                      deviceRatings.current.tablet ? (deviceRatings.current.tablet >= 4 ? "textGreen" : "textAmber") : ""
                    }`}
                  >
                    {deviceRatings?.current?.tablet ? deviceRatings.current.tablet?.toFixed(1) : "n/a"}
                  </div>
                  <img className="deviceIcon tableIcon" src={tabletIcon} />
                  <div className="variance">
                    {deviceRatings.previous.tablet != null ? `${plusSignNumbers(deviceRatings.previous.tablet)}` : "n/a"} vs prev. period
                  </div>
                </>
              )}
            </div>
            <div className="device">
              {starRatingDeviceDistributionLoading ? (
                <LoadingEllipsis isLoading={starRatingDeviceDistributionLoading} />
              ) : (
                <>
                  <div
                    className={`rating ${
                      deviceRatings.current.mobile ? (deviceRatings.current.mobile >= 4 ? "textGreen" : "textAmber") : ""
                    }`}
                  >
                    {deviceRatings?.current?.mobile ? deviceRatings.current.mobile?.toFixed(1) : "n/a"}
                  </div>
                  <img className="deviceIcon" src={mobileIcon} />
                  <div className="variance">
                    {deviceRatings.previous.mobile != null ? `${plusSignNumbers(deviceRatings.previous.mobile)}` : "n/a"} vs prev. period
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row grid">
        <div className="tile h-30 grid-col-span-5">
          <div className="space-between">
            <div className="tileHeader">
              <p>Average star rating by market</p>
              <Tooltip
                position="right"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                delay={1}
                hideDelay={1}
                duration={1}
                title={"The graphs have unique filters and won’t be impacted by the geography main filter"}
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
            </div>

            <UserEngagementMarketFilter
              filterValue={marketFilterValue}
              marketsList={marketList}
              marketParamValue={starRatingMarket}
              parameterName="star_rating_market"
            />
          </div>

          <div className="average-star-rating-tile" style={{ display: "flex", height: "100%" }}>
            <VocLineTrendChart
              chartId="vocAverageRatingSinceLaunch"
              data={avgMarketTrend ? avgMarketTrend : []}
              seriesList={formattedSeries}
              theme={themeContext.theme}
              showLegend={true}
              maxValue={5}
              minValue={0}
              disableLegendClick={true}
              isLoading={starRatingAverageRatingByMarketLoading}
            />
            <div className="m-auto text-center">
              <div className="tileHeader">Average of selected markets</div>
              <div className="ratings">
                <div className="value">
                  <span
                    className={`actualValue ${
                      currentAverageRatingSelectedMarkets
                        ? currentAverageRatingSelectedMarkets[0]?.experience_rating >= 4
                          ? "textGreen"
                          : "textAmber"
                        : ""
                    }`}
                  >
                    {currentAverageRatingSelectedMarkets ? currentAverageRatingSelectedMarkets[0]?.experience_rating?.toFixed(1) : "n/a"}
                  </span>
                  /<span>5</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default StarRatingOverview;
