import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getProductPerformanceDataDefinitions } from "../../../api/productPerformanceApi";
interface Data {
  KPI: string;
  Description: string;
  Data_source: string;
}

const ProductPerformance = () => {
  const [data, setData] = useState<Array<Data>>();

  useEffect(() => {
    getProductPerformanceDataDefinitions().then((response: any) => {
      if (response && "error" in response) {
        toast.error("Error fetching data updates");
      } else {
        setData(response);
      }
    });
  }, []);

  return (
    <div className="data_documentation">
      <h3>PRODUCT PERFORMANCE - DATA OVERVIEW</h3>

      {/* <h4>Purchase funnel KPI definitions and sources</h4> */}
      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>KPI</th>
            <th>Description</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((dataItem, index) => (
            <tr key={index}>
              <td>{dataItem.KPI}</td>
              <td style={{ whiteSpace: "pre-wrap" }}>{dataItem.Description}</td>
              <td>{dataItem.Data_source}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <br />
    </div>
  );
};

export default ProductPerformance;
