import React, { useContext, useEffect } from "react";
import DmeoDigitalSpendLineChart from "../../../components/Charts/Dmeo/DmeoDigitalSpendLineChart";
import { ThemeContext } from "../../../context";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getDmeoDigitalSpendTrend } from "../../../api/dmeoApi";

const DigitalSpendTrend = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["digitalSpendTrendData"],
    queryFn: getDmeoDigitalSpendTrend,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile">
      <div className="tile-header">Digital Spend</div>
      <div className="tile-content">
        <DmeoDigitalSpendLineChart
          chartId="dmeoDigitalSpendLineChart"
          data={data?.data ? data.data : []}
          seriesList={data?.series ? data.series : []}
          theme={theme}
          isLoading={isFetching}
        />
      </div>
    </div>
  );
});

export default DigitalSpendTrend;
