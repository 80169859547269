import React, { useCallback, useContext, useEffect, useState } from "react";
import { CockpitContext } from "../../subs/CockpitContext";

interface Props {
  title: string;
  hide_on_desktop?: boolean;
  hide_on_mobile?: boolean;
}

const metricMap: Record<string, string> = {
  business_revenue: "Business revenue",
  customer_quality: "Customer quality",
  engagement: "Engagement",
  operational: "Operational",
};

export const CockpitTitle = (props: Props) => {
  const { title, hide_on_desktop, hide_on_mobile } = props;
  const { metric, setMetric } = useContext(CockpitContext);
  const [activeTitle, setActiveTitle] = useState(false);

  useEffect(() => setActiveTitle(metric === title || (metric in metricMap && metricMap[metric] === title)), [metric, title]);

  const updateMetric = useCallback(() => {
    metric === title || (metric in metricMap && metricMap[metric] === title) ? setMetric("") : setMetric(title);
  }, [metric, title]);

  return (
    <div
      className={`section_title ${hide_on_desktop ? "hide_on_desktop" : ""} ${hide_on_mobile ? "hide_on_mobile" : ""} ${
        activeTitle ? "active" : ""
      }`}
      onClick={updateMetric}
    >
      <p className="title">{title}</p>
    </div>
  );
};
