import { ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { CCS_ANALYSIS_COLORS } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, legendConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  seriesList: Array<{ name: string; field: string }>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
}

class DmeoStackedBarLineChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.colors.list = CCS_ANALYSIS_COLORS.map((item) => color(item));

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.position = "bottom";

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.minGridDistance = 100;
    valueAxis.numberFormatter = new NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#a";

    const lineValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(lineValueAxis, theme);
    lineValueAxis.renderer.opposite = true;
    lineValueAxis.syncWithAxis = valueAxis;
    lineValueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return `${text}%`;
    });

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    seriesList.forEach(({ name, field }, idx) => {
      const series = this.chart.series.push(new ColumnSeries());
      series.name = name;
      series.dataFields.dateX = "date";
      series.stacked = true;
      series.dataFields.valueY = field;
      series.xAxis = dateAxis;
      series.columns.template.tooltipText = "[bold font-size: var(--regular_font_size)]{name}: ${valueY.formatNumber('###,###')}";
    });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default DmeoStackedBarLineChart;
