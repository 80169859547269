import { AnnotationsQueryParams } from "../api/annotationsApi";
import {
  FETCH_FUTURE_MARKET_BRAND_SEARCH,
  FETCH_FUTURE_MARKET_MEDIA_MLC,
  FETCH_FUTURE_MARKET_PAID_IMPRESSIONS,
  FETCH_FUTURE_MARKET_REACH,
  FETCH_IN_MARKET_CLOSE_RATE_SALES,
  FETCH_IN_MARKET_LEAD_JAPAN_VOLUME,
  FETCH_IN_MARKET_LEAD_VOLUME,
  FETCH_IN_MARKET_MEDIA_MLC,
  FETCH_IN_MARKET_TOTAL_SALES,
  FETCH_MTM_ANNOTATIONS,
  FETCH_MTM_CURRENCY,
  FETCH_MTM_MODELS,
  FETCH_NEAR_MARKET_KBA_VISITS,
  FETCH_NEAR_MARKET_MEDIA_MLC,
  FETCH_NEAR_MARKET_UNIQUE_VISITS,
  FETCH_NEAR_MARKET_VISITS,
  SET_FUTURE_MARKET_BRAND_SEARCH,
  SET_FUTURE_MARKET_MEDIA_MLC,
  SET_FUTURE_MARKET_PAID_IMPRESSIONS,
  SET_FUTURE_MARKET_REACH,
  SET_IN_MARKET_CLOSE_RATE_SALES,
  SET_IN_MARKET_LEAD_VOLUME,
  SET_IN_MARKET_MEDIA_MLC,
  SET_IN_MARKET_TOTAL_SALES,
  SET_MTM_ANNOTATIONS,
  SET_MTM_CURRENCY,
  SET_MTM_MODELS,
  SET_NEAR_MARKET_KBA_VISITS,
  SET_NEAR_MARKET_MEDIA_MLC,
  SET_NEAR_MARKET_UNIQUE_VISITS,
  SET_NEAR_MARKET_VISITS,
} from "./actionTypes";

// FUTURE MARKET ACTIONS
export const fetchFutureMarketMediaMlc = () => ({ type: FETCH_FUTURE_MARKET_MEDIA_MLC });
export const setFutureMarketMediaMlc = (data: any) => ({ type: SET_FUTURE_MARKET_MEDIA_MLC, payload: data });

export const fetchFutureMarketPaidImpressions = () => ({ type: FETCH_FUTURE_MARKET_PAID_IMPRESSIONS });
export const setFutureMarketPaidImpressions = (data: any) => ({ type: SET_FUTURE_MARKET_PAID_IMPRESSIONS, payload: data });

export const fetchFutureMarketReach = () => ({ type: FETCH_FUTURE_MARKET_REACH });
export const setFutureMarketReach = (data: any) => ({ type: SET_FUTURE_MARKET_REACH, payload: data });

export const fetchFutureMarketBrandSearch = () => ({ type: FETCH_FUTURE_MARKET_BRAND_SEARCH });
export const setFutureMarketBrandSearch = (data: any) => ({ type: SET_FUTURE_MARKET_BRAND_SEARCH, payload: data });

// NEAR MARKETS ACTIONS
export const fetchNearMarketMediaMlc = () => ({ type: FETCH_NEAR_MARKET_MEDIA_MLC });
export const setNearMarketMediaMlc = (data: any) => ({ type: SET_NEAR_MARKET_MEDIA_MLC, payload: data });

export const fetchNearMarketVisits = () => ({ type: FETCH_NEAR_MARKET_VISITS });
export const setNearMarketVisits = (data: any) => ({ type: SET_NEAR_MARKET_VISITS, payload: data });

export const fetchNearMarketUniqueVisits = () => ({ type: FETCH_NEAR_MARKET_UNIQUE_VISITS });
export const setNearMarketUniqueVisits = (data: any) => ({ type: SET_NEAR_MARKET_UNIQUE_VISITS, payload: data });

export const fetchNearMarketKbaVisits = () => ({ type: FETCH_NEAR_MARKET_KBA_VISITS });
export const setNearMarketKbaVisits = (data: any) => ({ type: SET_NEAR_MARKET_KBA_VISITS, payload: data });

// IN MARKET ACTIONS
export const fetchInMarketMediaMlc = () => ({ type: FETCH_IN_MARKET_MEDIA_MLC });
export const setInMarketMediaMlc = (data: any) => ({ type: SET_IN_MARKET_MEDIA_MLC, payload: data });

export const fetchInMarketLeadVolume = () => ({ type: FETCH_IN_MARKET_LEAD_VOLUME });
export const setInMarketLeadVolume = (data: any) => ({ type: SET_IN_MARKET_LEAD_VOLUME, payload: data });
export const fetchJapanInMarketLeadVolume = () => ({ type: FETCH_IN_MARKET_LEAD_JAPAN_VOLUME });

export const fetchInMarketCloseRateSales = () => ({ type: FETCH_IN_MARKET_CLOSE_RATE_SALES });
export const setInMarketCloseRateSales = (data: any) => ({ type: SET_IN_MARKET_CLOSE_RATE_SALES, payload: data });

export const fetchInMarketTotalSales = () => ({ type: FETCH_IN_MARKET_TOTAL_SALES });
export const setInMarketTotalSales = (data: any) => ({ type: SET_IN_MARKET_TOTAL_SALES, payload: data });

// Currency
export const fetchMtmCurrency = () => ({ type: FETCH_MTM_CURRENCY });
export const setMtmCurrency = (data: any) => ({ type: SET_MTM_CURRENCY, payload: data });

export const fetchMtmModels = () => ({ type: FETCH_MTM_MODELS });
export const setMtmModels = (data: Array<string>) => ({ type: SET_MTM_MODELS, payload: data });

export const fetchMtmAnnotations = (params: AnnotationsQueryParams, dashboard: string) => {
  return { type: FETCH_MTM_ANNOTATIONS, params, dashboard };
};
export const setMtmAnnotations = (data: any[]) => {
  return { type: SET_MTM_ANNOTATIONS, payload: data };
};
