import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { configureDateAxis, legendConfiguration } from "../../../../../components/Charts/helpers/configurations";
import { configureMTMLineSeries, configureMTMValueAxis } from "../../../../../components/Charts/helpers/mtmConfigurations";
import { ThemeInt } from "../../../../../components/Charts/helpers/types";
import { ARIYA_BLUE, ARIYA_GREEN, ARIYA_PINK, ARIYA_PURPLE, BLACK, WHITE } from "../../../../../constants";
import { ariyaChartConfiguration } from "../../../helpers/charts";

interface Props {
  data: Array<Record<string, number | string>>;
  chartName: string;
  series: Array<string>;
  theme: ThemeInt;
}

type SeriesPropsInt = {
  name: string;
  field: string;
  color: string;
};

export class SearchInterestLineGraph extends Component<Props> {
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, chartName, series, theme } = this.props;
    const chartColors = [theme === "dark" ? WHITE : BLACK, ARIYA_BLUE, ARIYA_PINK, ARIYA_GREEN, ARIYA_PURPLE];

    this.chart = create(chartName, XYChart);
    ariyaChartConfiguration(this.chart);
    this.chart.data = data;

    //Add legends
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.position = "right";
    this.chart.legend.fontSize = "0.75rem";
    this.chart.legend.valign = "top";

    //date format
    this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

    //Default x axis to show current date
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);

    //Y Axis that is used by default
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureMTMValueAxis(valueAxis, theme);
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.numberFormatter.numberFormat = "#a";

    const valueAxisJP = this.chart.yAxes.push(new ValueAxis());
    configureMTMValueAxis(valueAxisJP, theme);
    valueAxisJP.renderer.baseGrid.disabled = true;
    valueAxisJP.renderer.opposite = true;
    valueAxisJP.syncWithAxis = valueAxis;
    valueAxisJP.numberFormatter.numberFormat = "#a";

    //Function to create the series using the arguments given
    const createSeries = ({ name, field, color }: SeriesPropsInt) => {
      const series = this.chart.series.push(new LineSeries());
      const bullet = configureMTMLineSeries(series, {
        name,
        valueY: field,
        color,
        yAxis: name === "JP" ? valueAxisJP : valueAxis,
      });

      bullet.tooltipText =
        "[bold;font-size: 0.75rem;]{dateX.formatDate('dd MMM YYYY')} [/font-size: 0.75rem;]: {valueY.formatNumber('#,###.')}";

      if (series.tooltip) {
        series.tooltip.getFillFromObject = true;
        series.tooltip.autoTextColor = true;
      }
    };
    series.map((name, idx) => createSeries({ name: name, field: name, color: chartColors[idx] }));
  }

  render() {
    return <div id={this.props.chartName} className={"graph"} />;
  }
}
