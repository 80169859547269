import { ErrorBoundary } from "@sentry/react";
import React, { useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ErrorMsg } from "../../../../components/AppMessages";
import VocTopicChart from "../../../../components/Charts/VOC/VocTopicChart";
import { VOC_TOPIC_CLASSIFICATION_COLORS } from "../../../../constants";
import { ThemeContext } from "../../../../context";

interface Props {
  categories: Array<string>;
  chartData: any;
  topicClassificationLoading: boolean;
}

const TopCategories = withRouter((props: RouteComponentProps & Props) => {
  const { categories, chartData, topicClassificationLoading } = props;

  const themeContext = useContext(ThemeContext);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="chipContainer">
        {categories &&
          categories.map((category: string) => (
            <div key={category} className="chip" style={{ backgroundColor: VOC_TOPIC_CLASSIFICATION_COLORS[category] }}>
              {category}
            </div>
          ))}
      </div>
      <div>
        {chartData &&
          chartData.map((item: any) => {
            return item.topics && item.topics.length > 0 ? (
              <div className="row grid" key={item.market}>
                <div className="tile min-h-20 grid-col-span-6 flex-row">
                  <div className=" market-answers">
                    <p className="bold">{item.market}</p>
                    <p>{item.answers}% of answers</p>
                  </div>
                  <div className="categoryContainer">
                    <VocTopicChart
                      chartId={`vocTopicChart${item.market}`}
                      data={item.topics}
                      theme={themeContext.theme}
                      colorMapping={VOC_TOPIC_CLASSIFICATION_COLORS}
                      isLoading={topicClassificationLoading}
                    />
                  </div>
                </div>
              </div>
            ) : null;
          })}
      </div>
    </ErrorBoundary>
  );
});

export default TopCategories;
