import React, { useEffect } from "react";
import VisitsTile from "./VisitsTile";
import KbasLeadsTile from "./KbasLeadsTile";
import CostPerAqusitionTile from "./CostPerAqusitionTile";
import ConversionRateTile from "./ConversionRateTile";
import { useGetFetchQuery } from "../../../hooks/useGetQueryClient";
import LandingPagePerformanceTable from "./LandingPagePerformanceTable";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getDmeoVisists } from "../../../api/dmeoApi";

const CampaignPerformanceTiles = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["visitsData"],
    queryFn: getDmeoVisists,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="campaign-performance-tiles">
      <div className="leads-kbas-row">
        <VisitsTile data={data} />
        <div className="kbas-leads-container">
          <KbasLeadsTile
            title="Leads"
            value={data?.leads}
            valuePy={data?.yoy_perc_leads}
            valuePm={data?.mom_perc_leads}
            isFetching={isFetching}
          />
          <KbasLeadsTile
            title="Kbas"
            value={data?.kbas}
            valuePy={data?.yoy_perc_kbas}
            valuePm={data?.mom_perc_kbas}
            isFetching={isFetching}
          />
        </div>
        <CostPerAqusitionTile />
      </div>
      <div className="conversion-rate-row">
        <ConversionRateTile />
        <LandingPagePerformanceTable />
      </div>
    </div>
  );
});

export default CampaignPerformanceTiles;
