import { isNull } from "lodash";
import moment from "moment";

export const updateForecastData = (
  data: Array<Record<string, number | null>>,
  keysToCompare: Array<{ actualKey: string; forecastKey: string; tempForecastKey: string }>
) => {
  if (!data) return [];

  if (!keysToCompare || keysToCompare.length === 0) return data;

  const searchParams = new URLSearchParams(location.search);

  const final = data.flatMap((row) => {
    return keysToCompare.reduce((result, keys) => {
      const { tempForecastKey, actualKey, forecastKey } = keys;

      if (!(actualKey in row)) return { ...result, error: "object missing actualKey provided" };
      if (!(forecastKey in row)) return { ...result, error: "object missing forecastKey provided" };

      const granularity = searchParams.get("granularity") ?? "quarterly";
      const startOfPeriod = granularity === "quarterly" ? moment().startOf("quarter") : moment().startOf("month");
      const isCurrentOrAfter = startOfPeriod.startOf("month").isSameOrBefore(moment(row.date, "YYYY-MM-DD"));

      row[tempForecastKey] =
        isCurrentOrAfter || isNull(row[actualKey])
          ? isNull(row[forecastKey])
            ? null
            : (row[forecastKey] ?? 0) + (row[actualKey] ?? 0)
          : null;

      return { ...result, ...row };
    }, {});
  });
  return final;
};

export const dateFormattedForecast = (data: Array<Record<string, number | null>>) => {
  const parameters = new URLSearchParams(window.location.search);

  const granularity = parameters.get("granularity") as string;
  const startOfCurrentPeriod = granularity === "quarterly" ? moment().fquarter().start : moment().startOf("month").format("YYYY-MM-DD");

  return data.map((row) => {
    for (const key of Object.keys(row)) {
      if (key.includes("forecast") && moment(row.date).isBefore(moment(startOfCurrentPeriod, "YYYY-MM-DD"))) row[key] = null;
    }

    return row;
  });
};

export const updatePercentageData = (data: Array<Record<string, any>>, keys: Array<string>) => {
  if (!data || !data.length) return [];

  if (!keys || !keys.length) return data;

  return data.flatMap((row) => {
    const mapped = keys.map((key) => {
      if (!(key in row)) return row;

      if (!isNull(row[key])) row = { ...row, [key]: row[key] * 100 };

      return row;
    });

    return mapped[mapped.length - 1];
  });
};
