import {
  IAriyaMonitorData,
  IAriyaMonitorDataReportFormat,
  IHandraisersNAE,
  IPopularData,
  IPreorderdata,
  ISearchInterestData,
  ISocialNetworks,
} from "../constants/interface/ariya";
import {
  SET_ARIYA_DEPOSIT_TOTALS,
  SET_ARIYA_HANDRAISERS,
  SET_ARIYA_PREORDER,
  SET_ARIYA_VISITS,
  SET_GLOBAL_PAID_ORGANIC,
  SET_GLOBAL_VC,
  SET_GLOBAL_VC_PREVIOUS,
  SET_GLOBAL_VLP,
  SET_GLOBAL_VLP_PREVIOUS,
  SET_GLOBAL_WP,
  SET_GLOBAL_WP_PREVIOUS,
  SET_HANDRAISERS_NAE,
  SET_ONLINE_PREORDER,
  SET_POPULAR_CONTENT,
  SET_POPULAR_MOMENTS,
  SET_SEARCH_INTEREST,
  SET_SEARCH_INTEREST_VOLUME,
  SET_SOCIAL_NETWORKS,
} from "./actionTypes";

// Monitor
export const setAriyaVisits = (data: IAriyaMonitorData) => ({ type: SET_ARIYA_VISITS, payload: data });
export const setAriyaHandraisers = (data: IAriyaMonitorData) => ({ type: SET_ARIYA_HANDRAISERS, payload: data });
export const setAriyaPreOrder = (data: IAriyaMonitorData) => ({ type: SET_ARIYA_PREORDER, payload: data });
export const setAriyaDepositTotals = (data: IAriyaMonitorDataReportFormat) => ({ type: SET_ARIYA_DEPOSIT_TOTALS, payload: data });

// Experience Insights
export const setSearchInterest = (data: ISearchInterestData) => ({ type: SET_SEARCH_INTEREST, payload: data });
export const setSearchInterestVolume = (data: Array<{ country: string; date: string; search_term: string; search_volume: number }>) => ({
  type: SET_SEARCH_INTEREST_VOLUME,
  payload: data,
});
export const setGlobalVLP = (data: ISearchInterestData) => ({ type: SET_GLOBAL_VLP, payload: data });
export const setGlobalVLP_Previous = (data: ISearchInterestData) => ({ type: SET_GLOBAL_VLP_PREVIOUS, payload: data });
export const setGlobalVC = (data: ISearchInterestData) => ({ type: SET_GLOBAL_VC, payload: data });
export const setGlobalVC_Previous = (data: ISearchInterestData) => ({ type: SET_GLOBAL_VC_PREVIOUS, payload: data });
export const setGlobalWP = (data: ISearchInterestData) => ({ type: SET_GLOBAL_WP, payload: data });
export const setGlobalWP_Previous = (data: ISearchInterestData) => ({ type: SET_GLOBAL_WP_PREVIOUS, payload: data });
export const setGlobalPaidOrganic = (data: ISearchInterestData) => ({ type: SET_GLOBAL_PAID_ORGANIC, payload: data });
export const setPopularContent = (data: IPopularData) => ({ type: SET_POPULAR_CONTENT, payload: data });
export const setPopularMoments = (data: IPopularData) => ({ type: SET_POPULAR_MOMENTS, payload: data });
export const setSocialNetworks = (data: ISocialNetworks) => ({ type: SET_SOCIAL_NETWORKS, payload: data });

// HandraiserInsights
export const setHandraisersNAEData = (data: IHandraisersNAE) => ({ type: SET_HANDRAISERS_NAE, payload: data });
export const setOnlinePreorder = (data: IPreorderdata) => ({ type: SET_ONLINE_PREORDER, payload: data });
