import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { setParameters, setSpecificParameter } from "../../../actions";
import {
  ALL_OPTION_NO_SPACE,
  DEFAULT_BRAND_PERFORMANCE_DATE_PARAMS,
  DEFAULT_BRAND_PERFORMANCE_PARAMETERS,
  performancePages,
} from "../../../constants";
import { FilterContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../../utils/utilityFunctions";
import { ErrorMsg } from "../../AppMessages";
import { FiltersLayout } from "../../Layouts";
import { DefaultFilter, MultiSelectFilter } from "../common";
import PurchaseFunnelDateFilter from "../common/PurchaseFunnelDateFilter";
import { closeFilterOptions } from "../subs/helpers";

interface Props {
  name?: string;
  granularityOptions?: JSX.Element | null;
  activePage: performancePages;
  lastRefreshDate?: string;
}

const BrandPerformanceFilters = withRouter((props: RouteComponentProps & Props) => {
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const { history, granularityOptions, activePage, lastRefreshDate } = props;

  const {
    date_range: dateRangeParam,
    brand: brandParam,
    market: marketParam,
    model: modelParam,
    category: categoryParam,
    sub_category: subCategoryParam,
    attribute: attributeParam,
    competitor: competitorParam,
    date_range_brand_perf: dateRangePerfParam,
    granularity: granularityParam,
    nissan_competitor: nissanCompetitorParam,
    performance_page: performancePageParam,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("Brand Performance");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_BRAND_PERFORMANCE_PARAMETERS));
      currentDefaultFiltersSet("Brand Performance");
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParam);
    params.set("brand", brandParam);
    params.set("market", marketParam);
    params.set("model", modelParam);
    params.set("granularity", granularityParam);
    params.set("category", categoryParam);
    params.set("sub_category", subCategoryParam);
    params.set("attribute", attributeParam);
    params.set("competitor", competitorParam);
    params.set("nissan_competitor", nissanCompetitorParam);
    params.set("date_range_brand_perf", dateRangePerfParam);
    params.set("performance_page", activePage);
    history.push({ search: `?${params.toString()}` });
  }, [
    history,
    dateRangeParam,
    brandParam,
    marketParam,
    nissanCompetitorParam,
    dateRangePerfParam,
    granularityParam,
    competitorParam,
    categoryParam,
    subCategoryParam,
    attributeParam,
    performancePageParam,
    modelParam,
    activePage,
  ]);

  const volume_filters = useSelector((state: RootStateOrAny) => state.filters.brand_performance_volume_filters);
  const oao_filters = useSelector((state: RootStateOrAny) => state.filters.brand_performance_oao_filters);
  const image_attribute_filters = useSelector((state: RootStateOrAny) => state.filters.brand_performance_image_attribute_filters);
  const [competitorValue, setCompetitorValue] = useState("");

  const filters = useMemo(() => {
    if (activePage === "volume") return volume_filters;
    if (activePage === "oao") return oao_filters;
    return image_attribute_filters;
  }, [volume_filters, oao_filters, image_attribute_filters, performancePageParam, activePage]);

  const groupedDateRangeData = useMemo(() => {
    if (filters?.fiscal_year?.length) {
      return filters?.fiscal_year?.map((val: { fiscal_year: number; quarters: string[] }) => ({
        year: `FY ${val?.fiscal_year}`,
        child: val.quarters?.map((val) => ({ quarter: val })),
      }));
    }
    return [];
  }, [filters?.fiscal_year]);

  useEffect(() => {
    if (filters?.market?.length && !filters?.market?.includes(marketParam)) dispatch(setSpecificParameter("market", filters?.market[0]));
    if (filters?.model?.length && !filters?.model?.includes(modelParam)) dispatch(setSpecificParameter("model", filters?.model[0]));
    if (filters?.category?.length && !filters?.category?.includes(categoryParam))
      dispatch(setSpecificParameter("category", filters?.category[0]));
    if (filters?.sub_category?.length && !filters?.sub_category?.includes(subCategoryParam))
      dispatch(setSpecificParameter("sub_category", filters?.sub_category[0]));
    if (filters?.attribute?.length && !filters?.attribute?.includes(attributeParam))
      dispatch(setSpecificParameter("attribute", filters?.attribute[0]));
    if (
      !/all/gi.test(competitorParam) &&
      !competitorParam?.includes(",") &&
      filters?.competitor?.length &&
      !filters?.competitor?.includes(competitorParam)
    ) {
      dispatch(setSpecificParameter("competitor", filters?.competitor[0]));
    }
  }, [
    dateRangeParam,
    marketParam,
    modelParam,
    categoryParam,
    subCategoryParam,
    attributeParam,
    competitorParam,
    filters,
    dateRangePerfParam,
    performancePageParam,
  ]);

  useEffect(() => {
    if (filters?.fiscal_year?.length && !dateRangePerfParam?.length) {
      const latestYear = filters?.fiscal_year[0];
      const latestYearGroupedData = groupedDateRangeData?.filter((val: { year: any }) => val.year?.includes(latestYear?.toString()))[0];
      const dateParam = latestYearGroupedData?.child?.map((val: { quarter: string }) => `${val?.quarter} FY ${latestYear}`);
      dispatch(setSpecificParameter("date_range_brand_perf", dateParam));
    }
  }, [filters, groupedDateRangeData, dateRangePerfParam]);

  const resetFilters = React.useCallback(() => {
    eventTracking(MixpanelEvents.filter_reset, { dashboard: "Brand Performance", page: performancePageParam });

    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(DEFAULT_BRAND_PERFORMANCE_PARAMETERS)) {
      params.set(key, value);
    }

    history.push({ search: `?${params.toString()}` });
    window.location.reload();
  }, [history]);

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));
      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "Brand Performance",
        page: performancePageParam,
      });
    }
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const handleCompetitorFilterSubmission = useCallback(() => {
    const parameterName = "competitor";
    const selectedCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    closeFilterOptions();
    dispatch(setSpecificParameter("competitor", selectedValues.join(",")));
  }, [filters?.competitor]);

  const handleDateFilterSubmission = () => {
    const parameterName = "date_range_brand_perf";
    const selectedCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-parent-value]:checked`
    ) as NodeListOf<HTMLInputElement>;

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => {
      if (!/^\d+$/.test(checkbox.value) && checkbox.value != "All") selectedValues.push(checkbox.value);
    });

    closeFilterOptions();
    const value = selectedValues.join(",");
    dispatch(setSpecificParameter(parameterName, value));
    eventTracking(MixpanelEvents.filter_change, { filter: parameterName, value, dashboard: "Brand Performance", page: performancePageParam });
  };

  useEffect(() => {
    const selectedLength = competitorParam?.split(",")?.length;
    const val =
      selectedLength == filters?.competitor?.length
        ? ALL_OPTION_NO_SPACE
        : selectedLength == 1
        ? competitorParam
        : `${selectedLength} competitors selected`;
    setCompetitorValue(val);
    eventTracking(MixpanelEvents.filter_change, { filter: "competitor", value: competitorParam?.split(","), dashboard: "Brand Performance", page: performancePageParam });
  }, [filters?.competitor, competitorParam]);

  return (
    <FiltersLayout resetFilters={resetFilters} extraClass={"digital_filters"}>
      <>
        {activePage == "volume" ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <PurchaseFunnelDateFilter
              value={dateRangeParam}
              handleFilterOptionClick={handleFilterOptionClick}
              onFilterClick={showFilterOptionsFn}
              presetDatesList={DEFAULT_BRAND_PERFORMANCE_DATE_PARAMS}
              latestDate={lastRefreshDate}
            />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <MultiSelectFilter
              parentKey="year"
              // @ts-ignore
              filterList={groupedDateRangeData}
              filterName="DATE RANGE"
              childKey="quarter"
              value={dateRangePerfParam}
              parameterName={"date_range_brand_perf"}
              parameterValue={dateRangePerfParam}
              onShowFilterOptions={showFilterOptionsFn}
              handleFilterSubmission={handleDateFilterSubmission}
            />
          </ErrorBoundary>
        )}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            filterName={"brand"}
            list={filters?.brand ?? []}
            filterValue={brandParam}
            filterLabel={"BRAND"}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            filterName={"market"}
            list={filters?.market ?? []}
            filterValue={marketParam}
            filterLabel={"COUNTRY"}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            filterName={"model"}
            list={filters?.model ?? []}
            filterValue={modelParam}
            filterLabel={"MODEL"}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="competitor"
            // @ts-ignore
            filterList={filters?.competitor ?? []}
            filterName="COMPETITOR"
            value={competitorValue}
            parameterName={"competitor"}
            parameterValue={competitorParam}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleCompetitorFilterSubmission}
          />
        </ErrorBoundary>
        {activePage === "oao" && (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <DefaultFilter
              filterName={"category"}
              list={filters?.category ?? []}
              filterValue={categoryParam}
              filterLabel={"CATEGORY DETAIL"}
              handleFilterOptionClick={handleFilterOptionClick}
            />
          </ErrorBoundary>
        )}
        {activePage === "image-attributes" ? (
          <>
            <ErrorBoundary fallback={<ErrorMsg />}>
              <DefaultFilter
                filterName={"sub_category"}
                list={filters?.sub_category ?? []}
                filterValue={subCategoryParam}
                filterLabel={"SUB CATEGORY"}
                handleFilterOptionClick={handleFilterOptionClick}
              />
            </ErrorBoundary>
            <ErrorBoundary fallback={<ErrorMsg />}>
              <DefaultFilter
                filterName={"attribute"}
                list={filters?.attribute ?? []}
                filterValue={attributeParam}
                filterLabel={"ATTRIBUTE"}
                handleFilterOptionClick={handleFilterOptionClick}
              />
            </ErrorBoundary>
          </>
        ) : null}
        {granularityOptions}
      </>
    </FiltersLayout>
  );
});

export default BrandPerformanceFilters;
