export const ARIYA_COUNTRY_INITIALS: Record<string, string> = {
  Germany: "DE",
  Norway: "NO",
  Japan: "JP",
};

export const ARIYA_NAE_MARKETS = [
  "United Kingdom",
  "Norway",
  "Germany",
  "Spain",
  "Italy",
  "France",
  "Portugal",
  "Denmark",
  "Netherlands",
  "Finland",
  "Sweden",
  "Switzerland",
  "Belgium",
  "Austria",
  "Poland",
  "Luxembourg",
];
