import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { Dashboard } from "../../../constants/interface";
import { ThemeContext } from "../../../context";
import { InfoBoard } from "./infoBoard";

interface Props {
  dashboard: Dashboard;
  activeBoard: string;
  setActiveBoard: React.Dispatch<React.SetStateAction<string>>;
}

export const DashBoardButtons = (props: Props) => {
  const [showIcons, setShowIcons] = useState<boolean>(false);
  const { theme } = useContext(ThemeContext);
  const { dashboard, activeBoard, setActiveBoard } = props;

  //get user views
  const views = Cookies.get("views");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    dashboard && views && JSON.parse(views).some((view: string) => dashboard.id.includes(view))
      ? setIsDisabled(false)
      : setIsDisabled(true);
  }, [dashboard]);

  const screenWidth = window.matchMedia("(max-width: 768px)");
  useEffect(() => {
    setIsMobile(screenWidth.matches);
  }, [screenWidth]);

  useEffect(() => {
    if (isMobile) {
      setShowIcons(activeBoard === dashboard.id);
    }
  }, [activeBoard, isMobile]);

  //For mobile devices
  if (isMobile) {
    return (
      <div
        id={`dashboard_button_${dashboard.id}`}
        data-test-id={`dashboard_button_${dashboard.id}`}
        className={`dashboard_button ${isDisabled ? "disabled" : ""} `}
        onClick={() => {
          setShowIcons(!showIcons);
          setActiveBoard(dashboard.id);
        }}
      >
        <InfoBoard dashboard={dashboard} showIcons={showIcons} isDisabled={isDisabled} theme={theme} isMobile={isMobile} />
      </div>
    );
  }
  //For non mobile devices
  return (
    <div
      id={`dashboard_button_${dashboard.id}`}
      data-test-id={`dashboard_button_${dashboard.id}`}
      className={`dashboard_button ${isDisabled ? "disabled" : ""} `}
      onMouseEnter={() => setShowIcons(true)}
      onMouseLeave={() => setShowIcons(false)}
    >
      <InfoBoard dashboard={dashboard} showIcons={showIcons} isDisabled={isDisabled} theme={theme} isMobile={isMobile} />
    </div>
  );
};
