import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../../context";
import { useQuery } from "@tanstack/react-query";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ToggleSwitch } from "../../../../components/Toogle";
import DmeoFunnelChart from "../../../../components/Charts/Dmeo/DmeoFunnelChart";
import { getDmeoBounceRate, getDmeoConversionRate, getDmeoLandingPagePerformance } from "../../../../api/dmeoApi";
import DmeoLandingPagePerformanceLineChart from "../../../../components/Charts/Dmeo/DmeoLandingPagePerformanceLineChart";
import NewRatings from "../../../../components/Ratings/NewRatings";
import { BRIGHT_YELLOW, CYAN } from "../../../../constants";

const LandingPagePerformanceTile = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const [isLeads, setIsLeads] = useState(true);

  const {
    data: funnelData,
    isFetching: isFetchingFunnelData,
    refetch: refetchFunnelData,
  } = useQuery({
    queryKey: ["landingPagePerformanceFunnelData"],
    queryFn: getDmeoLandingPagePerformance,
    enabled: false,
  });

  const {
    data: conversionData,
    isFetching: isFetchingConversionData,
    refetch: refetchConversionData,
  } = useQuery({
    queryKey: ["conversionRateData", isLeads],
    queryFn: () => getDmeoConversionRate(isLeads),
    enabled: false,
  });

  const {
    data: bounceRateData,
    isFetching: isFetchingBounceRateData,
    refetch: refetchBounceRateData,
  } = useQuery({
    queryKey: ["bounceRateData", isLeads],
    queryFn: () => getDmeoBounceRate(isLeads),
    enabled: false,
  });

  useEffect(() => {
    refetchFunnelData();
    refetchConversionData();
    refetchBounceRateData();
  }, [history.location.search, isLeads]);

  return (
    <div className="tile">
      <div className="tile-header">
        <div className="title">Landing Page Performance</div>
        <ToggleSwitch
          active={isLeads}
          activeToggleLabel="Leads"
          inactiveToggleLabel="KBAs"
          handleToggleClick={() => setIsLeads((prevState) => !prevState)}
        />
      </div>
      <div className="tile-content landing-page-performance-tile">
        <div>
          <DmeoFunnelChart
            chartId="landingPagePerformanceFunnelChart"
            theme={theme}
            isLoading={isFetchingFunnelData}
            data={funnelData as any}
          />
        </div>
        <div className="metric-chart-title-container">
          <div className="metric-chart-tile conversion-rate-tile ">
            <div className="metric">
              <div>Conversion</div>
              <div className="value">{conversionData?.conversion_rate ? `${conversionData.conversion_rate}%` : "n/a"}</div>
              <div className="rating">
                {conversionData?.yoy && <NewRatings value={conversionData?.yoy} />} {conversionData?.yoy ? `${conversionData.yoy}%` : "n/a"}{" "}
                Vs PY
              </div>
            </div>
            <div className="chart ">
              <DmeoLandingPagePerformanceLineChart
                chartId={`conversionLandingPagePerformanceChart`}
                theme={theme}
                isLoading={isFetchingConversionData}
                data={conversionData?.data ? conversionData.data : []}
                seriesList={conversionData?.series ? conversionData.series : []}
                seriesColor={BRIGHT_YELLOW}
              />
            </div>
          </div>

          <div className="metric-chart-tile bounce-rate-tile">
            <div className="metric">
              <div>Bounce Rate</div>
              <div className="value">{bounceRateData?.conversion_rate ? `${bounceRateData.conversion_rate}%` : "n/a"}</div>
              <div className="rating">
                {conversionData?.yoy && <NewRatings value={bounceRateData?.yoy} />} {bounceRateData?.yoy ? `${bounceRateData.yoy}%` : "n/a"}{" "}
                Vs PY
              </div>
            </div>
            <div className="chart">
              <DmeoLandingPagePerformanceLineChart
                chartId={`bounceRateLandingPagePerformanceChart`}
                theme={theme}
                isLoading={isFetchingBounceRateData}
                data={bounceRateData?.data ? bounceRateData.data : []}
                seriesList={bounceRateData?.series ? bounceRateData.series : []}
                seriesColor={CYAN}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default LandingPagePerformanceTile;
