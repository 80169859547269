import {
  SET_CURRENCY,
  SET_DIFFERENCE_BY_MODEL,
  SET_DIS_BY_MODEL_AND_MONTH,
  SET_MSRP_MODEL_PERFORMANCE,
  SET_MSRP_OVER_TIME_BY_MODEL,
  SET_MSRP_PROFILE_BY_MONTH,
  SET_MSRP_SUMMARY,
  SET_MSRP_VME_DIFFERENCE,
  SET_SALES_BY_MODEL,
  SET_TOP_FIVE_MODELS,
  SET_TOTAL_ADDED_VALUE,
  SET_TOTAL_VALUE_DIS_BY_MONTH,
  SET_VME_DIFFERENCE_BY_MODEL,
  SET_VME_DISCOUNT_OVER_TIME_BY_MODEL,
} from "../actions/actionTypes/msrpActionTypes";

const initialState = {
  msrp_summary: {},
  sales_by_model: {},
  difference_by_model: {},
  vme_difference_by_model: {},
  total_value_dis_by_month: {},
  msrp_vme_difference: [],
  msrp_profile_by_month: [],
  msrp_model_performance: [],
  msrp_over_time_by_model: [],
  vme_discount_over_time_by_model: [],
  dis_by_model_and_month: {},
  total_added_value: {},
  top_five_models: [],
  currency: "¥",
};

const msrpReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_MSRP_SUMMARY:
      return { ...state, msrp_summary: action.payload };
    case SET_SALES_BY_MODEL:
      return { ...state, sales_by_model: action.payload };
    case SET_DIFFERENCE_BY_MODEL:
      return { ...state, difference_by_model: action.payload };
    case SET_VME_DIFFERENCE_BY_MODEL:
      return { ...state, vme_difference_by_model: action.payload };
    case SET_TOTAL_VALUE_DIS_BY_MONTH:
      return { ...state, total_value_dis_by_month: action.payload };
    case SET_MSRP_VME_DIFFERENCE:
      return { ...state, msrp_vme_difference: action.payload };
    case SET_MSRP_PROFILE_BY_MONTH:
      return { ...state, msrp_profile_by_month: action.payload };
    case SET_MSRP_MODEL_PERFORMANCE:
      return { ...state, msrp_model_performance: action.payload };
    case SET_MSRP_OVER_TIME_BY_MODEL:
      return { ...state, msrp_over_time_by_model: action.payload };
    case SET_VME_DISCOUNT_OVER_TIME_BY_MODEL:
      return { ...state, vme_discount_over_time_by_model: action.payload };
    case SET_DIS_BY_MODEL_AND_MONTH:
      return { ...state, dis_by_model_and_month: action.payload };
    case SET_TOTAL_ADDED_VALUE:
      return { ...state, total_added_value: action.payload };
    case SET_CURRENCY:
      return { ...state, currency: action.payload };
    case SET_TOP_FIVE_MODELS:
      return { ...state, top_five_models: action.payload };
    default:
      return state;
  }
};

export default msrpReducer;
