import React from "react";
import { CockpitTitle, OperationalTiles } from "../normalSections";

export const OperationalMetric = () => {
  return (
    <>
      <CockpitTitle title={"Business revenue"} hide_on_desktop={true} />
      <CockpitTitle title={"Customer quality"} hide_on_desktop={true} />
      <CockpitTitle title={"Engagement"} hide_on_desktop={true} />
      <CockpitTitle title={"Operational"} hide_on_desktop={true} />
      <OperationalTiles showMetric={true} />
    </>
  );
};
