import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../../components/AppMessages";
import { FmiByModelChart } from "../../../../components/Charts/Midas/FmiByModelChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../../context";
import { getChartData, getMaxAmount, getMinAmount } from "../Helpers/helpers";

interface Props {
  seriesList: Array<{ name: string; seriesColor: string }>;
}

const FmiByModelGraph = (props: Props) => {
  const themeContext = useContext(ThemeContext);
  const { seriesList } = props;
  const loading = useSelector((state: RootStateOrAny) => state.loading.midas_fmi_by_model_fmi_by_quarter);
  const data = useSelector((state: RootStateOrAny) => state.midas.midas_fmi_by_model_fmi_by_quarter);
  const modelOrder = useSelector((state: RootStateOrAny) => state.filters.midas_filters.model);
  const { unit: unitParamValue } = useSelector((state: RootStateOrAny) => state.parameters);

  const fullYearMax = useMemo(() => getMaxAmount(data?.full_year), [data]);
  const fullYearMin = useMemo(() => getMinAmount(data?.full_year), [data]);
  const q1Min = useMemo(() => getMinAmount(data?.q1), [data]);
  const q2Min = useMemo(() => getMinAmount(data?.q2), [data]);
  const q3Min = useMemo(() => getMinAmount(data?.q3), [data]);
  const q4Min = useMemo(() => getMinAmount(data?.q4), [data]);

  const lowestValue = Math.min(q1Min, q2Min, q3Min, q4Min, fullYearMin);

  const q1ChartData = useMemo(() => getChartData(data?.q1, modelOrder, fullYearMax), [data, modelOrder, fullYearMax]);

  const q2ChartData = useMemo(() => getChartData(data?.q2, modelOrder, fullYearMax), [data, modelOrder, fullYearMax]);

  const q3ChartData = useMemo(() => getChartData(data?.q3, modelOrder, fullYearMax), [data, modelOrder, fullYearMax]);

  const q4ChartData = useMemo(() => getChartData(data?.q4, modelOrder, fullYearMax), [data, modelOrder, fullYearMax]);
  const fullYearChartData = useMemo(() => getChartData(data?.full_year, modelOrder, fullYearMax), [data, modelOrder, fullYearMax]);

  const modelCount = useMemo(() => {
    if (data?.full_year) {
      return data.full_year.length;
    }
    return 0;
  }, [data]);

  return (
    <div className="fmi_by_model_charts primary_background">
      <div className="legend">
        {seriesList?.map(({ name, seriesColor }, index: number) => (
          <div className="legend_item" key={name}>
            <div className="legend_box" style={{ backgroundColor: seriesColor }}></div>
            {name.replace(new RegExp("_", "g"), " ")}
          </div>
        ))}
      </div>
      <div className="charts">
        <div className={`quarter_breakdown q1 ${modelCount > 60 ? "h-150" : modelCount > 45 ? "h-100" : ""}`}>
          <div className="quarter_title">Q1</div>

          {data?.q1?.length && !loading ? (
            <>
              <div className="vertical_line"></div>
              <ErrorBoundary fallback={<ErrorMsg />}>
                <FmiByModelChart
                  unitParamValue={unitParamValue}
                  data={q1ChartData}
                  maxYear={fullYearMax}
                  lowestValue={lowestValue}
                  theme={themeContext.theme}
                  seriesList={seriesList}
                  chartName="fmiByModelChartQ1"
                  isLoading={false}
                />
              </ErrorBoundary>
            </>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available" />
          )}
        </div>
        <div className="quarter_breakdown">
          <span className="quarter_title">Q2</span>
          {data?.q2?.length && !loading ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <FmiByModelChart
                unitParamValue={unitParamValue}
                data={q2ChartData}
                maxYear={fullYearMax}
                seriesList={seriesList}
                theme={themeContext.theme}
                chartName="fmiByModelChartQ2"
                isLoading={false}
                lowestValue={lowestValue}
              />
            </ErrorBoundary>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available" />
          )}
        </div>
        <div className="quarter_breakdown">
          <span className="quarter_title">Q3</span>
          {data?.q3?.length && !loading ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <FmiByModelChart
                unitParamValue={unitParamValue}
                data={q3ChartData}
                maxYear={fullYearMax}
                seriesList={seriesList}
                lowestValue={lowestValue}
                theme={themeContext.theme}
                chartName="fmiByModelChartQ3"
                isLoading={false}
              />
            </ErrorBoundary>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available" />
          )}
        </div>
        <div className="quarter_breakdown">
          <span className="quarter_title">Q4</span>
          {data?.q4?.length && !loading ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <FmiByModelChart
                unitParamValue={unitParamValue}
                data={q4ChartData}
                lowestValue={lowestValue}
                maxYear={fullYearMax}
                seriesList={seriesList}
                theme={themeContext.theme}
                chartName="fmiByModelChartQ4"
                isLoading={false}
              />
            </ErrorBoundary>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available" />
          )}
        </div>
        <div className="quarter_breakdown">
          <span className="quarter_title">Full year</span>
          {data?.full_year?.length && !loading ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <FmiByModelChart
                unitParamValue={unitParamValue}
                data={fullYearChartData}
                seriesList={seriesList}
                lowestValue={lowestValue}
                theme={themeContext.theme}
                chartName="fmiByModelChartFullYear"
                isLoading={false}
              />
            </ErrorBoundary>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available" />
          )}
        </div>
      </div>
    </div>
  );
};

export default FmiByModelGraph;
