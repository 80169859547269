import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { getMediaFmiAndCoreModel } from "../../../../api/JourneyExcellenceMarketPerformanceSummary";
import MediaFmiAndCoreModelChart from "../../../../components/Charts/MarketPerformanceSummary/MediaFmiAndCoreModelChart";
import { ThemeContext } from "../../../../context";
import { currencies } from "../../../../constants/currenctConstants";

const MediaFmiAndCoreModel = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["mediaFmiAndCoreModelData", history.location.search],
    queryFn: getMediaFmiAndCoreModel,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile">
      <div className="tile-header">
        <div className="title">
          <p>Media FMI and Core Model</p>
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            html={
              <div>
                <p>
                  Media FMI allocated in each model to reach its marketing goals or promote the brand messaging. Actual amount is based on
                  the most recent year scenario available for the selected FY and Geography.
                </p>
                <p>Source: MIDAS</p>
              </div>
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      <div className="chart-container">
        <MediaFmiAndCoreModelChart
          data={data?.data ?? []}
          seriesList={data?.series ?? []}
          theme={theme}
          chartId="mediaFmiAndCoreModelChart"
          isLoading={isFetching}
          currency={data?.currency ? currencies[data.currency].symbol : ""}
        />
      </div>
    </div>
  );
});

export default MediaFmiAndCoreModel;
