import { put, retry, takeEvery } from "redux-saga/effects";
import { setModelsData } from "../actions";
import { FETCH_MODELS } from "../actions/actionTypes";
import { getModels } from "../api";
import { secondsDelay } from "../api/utils";
import { IModelFilters } from "../constants/interface";

// Watcher Sagas
export function* watchFetchModels() {
  yield takeEvery(FETCH_MODELS, fetchModels);
}

// Worker Sagas
export function* fetchModels() {
  try {
    const data: IModelFilters = yield retry(4, secondsDelay, getModels);
    if (!("error" in data)) yield put(setModelsData(data));
  } catch (err) {
    console.log("Failed to get Model data");
  }
}
