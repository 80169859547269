import { ErrorBoundary } from "@sentry/react";
import { isEmpty } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  fetchAverageModel,
  fetchCustomerIntentionByColorData,
  fetchCustomerIntentionsVsSalePower,
  fetchCustomerIntentionVsSalesData,
  fetchLastDataRefresh,
  fetchMarketingActivityData,
  fetchNimCustomerIntentionVsSales,
  fetchNimGrades,
  fetchNimSalesByTrend,
  fetchPriceVolumeSalesData,
  fetchPriceVolumeTrendData,
  fetchProductMarketingOptimizationFilters,
  fetchProductMarketingOptimizationLastDataRefresh,
  fetchRegionalColorTrendData,
  fetchSalesByTrendData,
  fetchSearchInterestVsFMIData,
  fetchSovData,
  setPageName,
  setSpecificParameter,
} from "../../actions";
import "../../assets/styles/pages/midasUnderConstruction.scss";
import "../../assets/styles/pages/productMarketingOptimization.scss";
import { ErrorMsg, NoDataMsg } from "../../components/AppMessages";
import { GranularityOptions } from "../../components/Charts/components";
import ProductMarketingOptimizationFilters from "../../components/Filters/ProductMarketingOptimizationFilters";
import { DashboardLayout } from "../../components/Layouts";
import { DefaultPageContent } from "../../components/PageContent";
import { PageTitle } from "../../components/PageContent/subs";
import { PMO_CHART_COLORS } from "../../constants";
import { DashboardBannerContext } from "../../context";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import RegionalColorSection from "./MainSections/RegionalColorSection";
import SearchInterstSection from "./MainSections/SearchInterestSection";
import SpecificationSection from "./MainSections/SpecificationSection";

const ProductMarketingOptimization = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { history } = props;

  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);
  const responseError = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.response_error);
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.product_marketing_optimization?.date ?? "");
  const data = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.price_volume_sales?.data ?? {});

  const { market, model } = useSelector((state: RootStateOrAny) => state.parameters);

  const [inAccessibleDashboard, setInAccessibleDashboard] = useState<boolean>(false);
  const [hideModules, setHideModules] = useState<boolean>(false);
  const [showPowerModules, setShowPowerModules] = useState<boolean>(false);

  const seriesList = useMemo(() => {
    if (!isEmpty(data)) {
      return Object.keys(data).map((name: string, idx: number) => {
        return { name: name, seriesColor: PMO_CHART_COLORS[idx] };
      });
    }
    return [];
  }, [data]);

  useEffect(() => {
    setInAccessibleDashboard(responseError?.error?.includes("invalid_scope"));
  }, [responseError]);

  useEffect(() => {
    //Mex Kicks || Thai Kicks -•••••••••••••-> Only E power
    //Mex Versa All modules
    //Other geos __> No Epower
    if ((market == "Thailand" || market == "Mexico") && model == "Kicks") {
      setHideModules(true);
      setShowPowerModules(true);
    } else {
      setHideModules(false);
      setShowPowerModules(true);
    }
  }, [market, model]);

  const [priceType, setPriceType] = useState<string>("exact");
  const [chartGranularity, setChartGranularity] = useState("monthly");
  const [showVolumes, setShowVolumes] = useState<boolean>(false);

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const ref = useRef<any>(null);

  useEffect(() => {
    Promise.all([
      // Sets page name
      dispatch(setPageName("Product marketing optimization")),
      dispatch(fetchLastDataRefresh()),
      dispatch(fetchProductMarketingOptimizationFilters("Nissan")),
      dispatch(fetchProductMarketingOptimizationLastDataRefresh()),
    ]);
  }, [dispatch]);

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Product Marketing Optimization", page: "Product Marketing Optimization" });
  }, [location.pathname]);

  // Fetch all data
  useEffect(() => {
    if (parameters.market != "All countries" && parameters.model != "All") {
      setTimeout(
        () =>
          Promise.all([
            dispatch(fetchCustomerIntentionVsSalesData()),
            dispatch(fetchSearchInterestVsFMIData()),
            dispatch(fetchPriceVolumeSalesData(priceType)),
            dispatch(fetchSalesByTrendData()),
            dispatch(fetchPriceVolumeTrendData()),
            dispatch(fetchMarketingActivityData()),
            dispatch(fetchRegionalColorTrendData()),
            dispatch(fetchCustomerIntentionByColorData()),
            dispatch(fetchSovData()),
            dispatch(fetchAverageModel()),
            dispatch(fetchCustomerIntentionsVsSalePower()),
            dispatch(fetchNimCustomerIntentionVsSales()),
            dispatch(fetchNimSalesByTrend()),
            dispatch(fetchNimGrades()),
            dispatch(fetchMarketingActivityData()),
          ]),
        50
      );
    }
    if (parameters.market == "United States" && parameters.date_range != "Last quarter") {
      const startOfDateRange = new Date(parameters.date_range.split("-")[0].trim());

      const minDate = new Date("November 2021");

      if (parameters.date_range == "This financial year" || startOfDateRange < minDate) {
        setBannerText("US data is only available from November 2021 onwards.");
        setShowBanner(true);
      } else {
        setShowBanner(false);
      }
    } else {
      setShowBanner(false);
    }
  }, [parameters, dispatch, priceType]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("date_range", parameters.date_range);
    params.set("brand", parameters.brand);
    params.set("market", parameters.market);
    params.set("model", parameters.model);
    params.set("granularity", parameters.granularity);
    params.set("average_models", parameters.average_models);
    params.set("grade", parameters?.grade);
    history.push({ search: `?${params.toString()}` });
  }, [parameters, history]);

  const handleGranularityRadioChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = evt;

      setChartGranularity(value);
    },
    [setChartGranularity]
  );
  useEffect(() => {
    dispatch(setSpecificParameter("granularity", chartGranularity));
  }, [chartGranularity, dispatch]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (tooltipVisible && ref.current != null && !ref.current.contains(e.target)) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [tooltipVisible]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={
          <ProductMarketingOptimizationFilters
            granularityOptions={
              <GranularityOptions
                onHandleRadioClick={handleGranularityRadioChange}
                granularity={chartGranularity}
                name={"pmo_granularities"}
                dateValue={parameters.date_range}
                granularitiesList={["monthly", "quarterly"]}
              />
            }
            inAccessible={inAccessibleDashboard}
            filterBtns={[{ id: "dfp_btn", name: "DFP", navigateTo: "/product_marketing_optimization/dfp_dashboard" }]}
          />
        }
        dataDocumentation="product_marketing_optimization"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="product_marketing_optimization" />}
      >
        <ErrorBoundary fallback={<ErrorMsg />}>
          {inAccessibleDashboard ? (
            <div className="under_construction_content primary_background">
              <NoDataMsg message="This dashboard page is not available for the selected geographies." />
            </div>
          ) : (
            <div className="pmo_sotu">
              {/* customer intention vs sales volume by grade */}
              <SpecificationSection
                seriesList={seriesList}
                setPriceType={setPriceType}
                hideModules={hideModules}
                showPowerModules={showPowerModules}
              />

              {/* Search interest vs FMI spend */}
              {!hideModules && (
                <>
                  <RegionalColorSection />
                  <SearchInterstSection />
                </>
              )}
            </div>
          )}
        </ErrorBoundary>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default ProductMarketingOptimization;
