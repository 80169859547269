import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect, useMemo } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  fetchFmiByModelFmiByQuarter,
  fetchMidasFmiByModelFilters,
  fetchMidasFmiByModelLastDataRefresh,
  setPageName,
} from "../../../actions";
import "../../../assets/styles/pages/fmiByModel.scss";
import "../../../assets/styles/pages/midasUnderConstruction.scss";
import MarketingInvastmentDashboardFilters from "../../../components/Filters/MarketingInvastmentDashboardFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { MIDAS_BTN_FILTERS, PMO_CHART_COLORS } from "../../../constants";
import { DashboardBannerContext } from "../../../context";
import FmiByModelGraph from "./components/FmiByModelGraph";

const FmiByModel = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.midas_fmi_last_refresh_date);
  const { brand, nsc, yr_scnr_rv, mdl_nm_rev, model, unit, mc_group, subgroup, market } = useSelector(
    (state: RootStateOrAny) => state.parameters
  );
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);

  const pageName = "FMI by model";

  const data = useSelector((state: RootStateOrAny) => state.midas.midas_fmi_by_model_fmi_by_quarter);
  const allYrScenarios = useSelector((state: RootStateOrAny) => state.filters.midas_filters.yr_scnr_rv);

  useEffect(() => {
    Promise.all([
      // Sets page name
      dispatch(setPageName("MEDIA-FMI by model")),
      dispatch(fetchMidasFmiByModelLastDataRefresh()),
    ]);
    setBannerText("Forex Rate based from latest BP");
    setShowBanner(false);
  }, [dispatch]);

  //Endpoints that depend on parameters
  useEffect(() => {
    Promise.all([dispatch(fetchFmiByModelFmiByQuarter())]);
  }, [parameters, unit]);

  useEffect(() => {
    dispatch(fetchMidasFmiByModelFilters());
  }, [brand, yr_scnr_rv, nsc, mdl_nm_rev, model, mc_group, subgroup, market]);

  const seriesList: Array<{ name: string; seriesColor: string }> = useMemo(() => {
    if (data?.q4?.length) {
      const yrScenarios: string[] = Array.from(new Set(data?.q4?.map((val: { yr_scnr_rv: string }) => val.yr_scnr_rv)));
      const sortedYearScenarios = yrScenarios.sort(
        (a, b) =>
          allYrScenarios?.indexOf(a.replace(/FY/g, "FY ").replace(/_/g, " ")) -
          allYrScenarios?.indexOf(b.replace(/FY/g, "FY ").replace(/_/g, " "))
      );
      return sortedYearScenarios?.map((series: string, index: number) => {
        return { name: series, seriesColor: PMO_CHART_COLORS[index] };
      });
    }
    return [];
  }, [data]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<MarketingInvastmentDashboardFilters filterBtns={MIDAS_BTN_FILTERS} hasModel hasUnit isCustom />}
        dataDocumentation="midas"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="midas" />}
      >
        <ErrorBoundary>
          <div className="midas_fmi_by_model_sotu">
            {/* FMI charts */}
            <div className="fmi_model_by_quarter ">
              <FmiByModelGraph seriesList={seriesList} />
            </div>
          </div>
        </ErrorBoundary>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default FmiByModel;
