import { performancePages } from "../constants";
import { getParams as getExecutiveSummaryParams } from "./executiveSummaryWebsiteApi";
import { journeyExcellenceApiParamsToObject } from "./journeyExcellenceApi";
import { fetchDataV2, newApiFetch, newApiFetchV2 } from "./utils";

export const getMarketDataRefresh = (data: { table: string; brand: string; market: string }) => newApiFetch("/available_data/get", data);

export const getMidasFmiLastRefreshDate = () => fetchDataV2("/midas/fmi-run-rate/last-refresh-date", {});
export const getMidasFmiByModelLastRefreshDate = () => fetchDataV2("/midas/fmi-by-model/last-refresh-date", {});
export const getMidasMediaMixLastRefreshDate = () => fetchDataV2("/midas/media-mix/last-refresh-date", {});
export const getMidasMtpCategoryLastRefreshDate = () => fetchDataV2("/midas/mtp-category/last-refresh-date", {});
export const getMidasStrategicDealerSupportLastRefreshDate = () => fetchDataV2("/midas/strategic-dealer-support/last-refresh-date", {});
export const getMidasStrategicCELastRefreshDate = () => fetchDataV2("/midas/strategic-ce/last-refresh-date", {});
export const getMidasFixedLastRefreshDate = () => fetchDataV2("/midas/fixed/last-refresh-date", {});
export const getMidasSpendVsTrafficLastRefreshDate = () => fetchDataV2("/midas/spend-vs-traffic/last-refresh-date", {});
export const getCpoOperationalLastRefreshDate = () => fetchDataV2("/cpo-operational/last-refresh-date", {});
export const getProductPerformanceLastRefreshDate = () => fetchDataV2("/product-performance/last-refresh-date");
export const getJourneyExcellenceLastRefreshDate = (metric: string) => {
  if (metric == "Leads Management System") {
    return newApiFetchV2("/journey-excellence/lms/last-refresh-date", { ...journeyExcellenceApiParamsToObject() });
  }
  return fetchDataV2("/journey-excellence/last-refresh-date");
};
export const getVocLastRefreshDate = () => fetchDataV2("voc/last-refresh-date");
export const getUseOwnershipLastRefreshDate = (page: string) => fetchDataV2(`/use-ownership/${page}/last-data-refresh`);
export const getBrandPerformanceLastRefreshDate = (metric: performancePages) =>
  fetchDataV2(`/brand-performance/latest-refresh-date?use_cache=true&metric=${metric == "image-attributes" ? "image" : metric}`);
export const getExecutiveSummaryLastRefreshData = () =>
  newApiFetchV2("/executive-summary/dis/last-refresh-date", getExecutiveSummaryParams());
export const getExecutiveSummaryProductPerformanceLastRefreshData = () =>
  fetchDataV2("/executive-summary/product-performance/last-refresh-date", getExecutiveSummaryParams());
