import { ErrorBoundary } from "@sentry/react";
import { format, sub } from "date-fns";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { setParameters, setSpecificParameter } from "../../actions";
import {
  ALL_OPTION_NO_SPACE,
  DEFAULT_PARAMETER_VALUES,
  DEFAULT_PMO_PARAMETERS,
  MIN_SELECTABLE_DATE,
  PMO_DATE_FILTER_OPTIONS,
} from "../../constants";
import { FilterContext } from "../../context";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DefaultFilter, FilterBtns, ModelFilter } from "./common";
import PurchaseFunnelDateFilter from "./common/PurchaseFunnelDateFilter";
import { closeFilterOptions } from "./subs/helpers";

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
  granularityOptions?: JSX.Element | null;
  inAccessible?: boolean;
}

const ProductMarketingOptimizationFilters = withRouter((props: RouteComponentProps & Props): JSX.Element => {
  const { history, filterBtns, granularityOptions, inAccessible } = props;
  const dispatch = useDispatch();

  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const filters = useSelector((state: RootStateOrAny) => state.filters.product_marketing_optimization_filters);
  const dataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.prduct_marketing_optimization);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const brandParamValue = useSelector((state: RootStateOrAny) => state.parameters.brand);
  const marketParamValue = useSelector((state: RootStateOrAny) => state.parameters.market);
  const modelParamValue = useSelector((state: RootStateOrAny) => state.parameters.model);

  const [dateParam, setDateParam] = useState<string>(dateRangeParamValue);
  const [brandsList, setBrandsList] = useState<Array<string>>([]);
  const [availableMarkets, setAvailableMarkets] = useState<Array<string>>([]);
  const [availableModels, setAvailableModels] = useState<Array<string>>([]);

  //Sets the initial start and end selectable date to be 1st April 2019 and the latest data refresh | today() - 1 (yesterday) if the last data refresh is not yet defined
  const [marketDataAvailability, setMarketDataAvailability] = useState<{ start: string; end: string }>({
    start: MIN_SELECTABLE_DATE,
    end: dataRefresh || format(sub(new Date(), { days: 1 }), "dd/MM/yyyy"),
  });

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("Product marketing optimization");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_PMO_PARAMETERS));
      currentDefaultFiltersSet("Product marketing optimization");
    }
  }, []);

  useEffect(() => {
    const brands = [];

    for (const key in filters) {
      brands.push(key);
    }

    setBrandsList(brands);
  }, [filters]);

  useEffect(() => {
    if (Object.keys(filters).length > 0 && brandsList.includes(brandParamValue)) {
      setAvailableMarkets(Object.keys(filters[brandParamValue]).sort());
    }
  }, [filters, brandsList, brandParamValue]);

  useEffect(() => {
    if (
      Object.keys(filters).length > 0 &&
      marketParamValue != ALL_OPTION_NO_SPACE &&
      brandsList.includes(brandParamValue) &&
      availableMarkets.includes(marketParamValue)
    ) {
      setAvailableModels(filters[brandParamValue][marketParamValue].sort());
    }
  }, [filters, brandsList, availableMarkets, brandParamValue, marketParamValue]);

  useEffect(() => {
    if (brandsList.length > 0 && !brandsList.includes(brandParamValue)) {
      dispatch(setSpecificParameter("brand", brandsList[0]));
    }
  }, [brandsList, brandParamValue]);

  useEffect(() => {
    if (!PMO_DATE_FILTER_OPTIONS.includes(dateRangeParamValue)) {
      dispatch(setSpecificParameter("date_range", PMO_DATE_FILTER_OPTIONS[0]));
    }
  }, []);

  useEffect(() => {
    if (availableMarkets.length > 0)
      availableMarkets && !availableMarkets.includes(marketParamValue) && dispatch(setSpecificParameter("market", availableMarkets[0]));
    if (availableModels.length > 0)
      availableModels && !availableModels.includes(modelParamValue) && dispatch(setSpecificParameter("model", availableModels[0]));
  }, [availableMarkets, availableModels, marketParamValue, modelParamValue]);

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));
      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "Product Marketing Optimization",
        page: "Product Marketing Optimization",
      });
    }
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  const resetFilters = useCallback(() => {
    eventTracking(MixpanelEvents.filter_reset, { dashboard: "Product Marketing Optimization", page: "Product Marketing Optimization" });

    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(DEFAULT_PARAMETER_VALUES)) {
      params.set(key, value);
    }

    history.push({ search: `?${params.toString()}` });
    window.location.reload();
  }, [history]);

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  return (
    <FiltersLayout resetFilters={resetFilters} extraClass={"digital_filters"}>
      {!inAccessible ? (
        <>
          {/*Date Range*/}
          <ErrorBoundary fallback={<ErrorMsg />}>
            <PurchaseFunnelDateFilter
              value={dateRangeParamValue}
              handleFilterOptionClick={handleFilterOptionClick}
              onFilterClick={showFilterOptionsFn}
              presetDatesList={PMO_DATE_FILTER_OPTIONS}
              minDateValue="April 2021"
            />
          </ErrorBoundary>

          {/*Brand*/}
          <ErrorBoundary fallback={<ErrorMsg />}>
            <DefaultFilter
              list={brandsList}
              filterName={"brand"}
              filterLabel={"BRAND"}
              filterValue={brandParamValue}
              handleFilterOptionClick={handleFilterOptionClick}
            />
          </ErrorBoundary>

          {/*Geography*/}
          <ErrorBoundary fallback={<ErrorMsg />}>
            <DefaultFilter
              key="market_filter"
              filterName={"market"}
              list={availableMarkets}
              filterValue={marketParamValue}
              filterLabel={"GEOGRAPHY"}
              handleFilterOptionClick={handleFilterOptionClick}
            />
          </ErrorBoundary>

          {/*Model*/}
          <ErrorBoundary fallback={<ErrorMsg />}>
            <ModelFilter filterValue={modelParamValue} list={availableModels} handleFilterOptionClick={handleFilterOptionClick} />
          </ErrorBoundary>

          {granularityOptions}

          {filterBtns.map((btn) => (
            <FilterBtns id={btn.id} navigateTo={btn.navigateTo} name={btn.name} key={btn.id} showOnMobile={true} />
          ))}
        </>
      ) : (
        <></>
      )}
    </FiltersLayout>
  );
});

export default ProductMarketingOptimizationFilters;
