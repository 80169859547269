export const FETCH_LIVE_MARKETS = "FETCH_LIVE_MARKETS";
export const SET_LIVE_MARKETS = "SET_LIVE_MARKETS";

export const FETCH_FEATURES_ACTIVATED = "FETCH_FEATURES_ACTIVATED";
export const SET_FEATURES_ACTIVATED = "SET_FEATURES_ACTIVATED";

export const FETCH_DIGITAL_PERFORMANCE = "FETCH_DIGITAL_PERFORMANCE";
export const SET_DIGITAL_PERFORMANCE = "SET_DIGITAL_PERFORMANCE";

export const FETCH_INFLUENCE_ON_SHOPPING_TOOLS = "FETCH_INFLUENCE_ON_SHOPPING_TOOLS";
export const SET_INFLUENCE_ON_SHOPPING_TOOLS = "SET_INFLUENCE_ON_SHOPPING_TOOLS";

export const FETCH_INFLUENCE_ON_LEADS = "FETCH_INFLUENCE_ON_LEADS";
export const SET_INFLUENCE_ON_LEADS = "SET_INFLUENCE_ON_LEADS";

export const FETCH_TOTAL_BUGS = "FETCH_TOTAL_BUGS";
export const SET_TOTAL_BUGS = "SET_TOTAL_BUGS";

export const FETCH_BUGS_STATUS_BREAKDOWN = "FETCH_BUGS_STATUS_BREAKDOWN";
export const SET_BUGS_STATUS_BREAKDOWN = "SET_BUGS_STATUS_BREAKDOWN";

export const FETCH_BUGS_RELEASE_BREAKDOWN = "FETCH_BUGS_RELEASE_BREAKDOWN";
export const SET_BUGS_RELEASE_BREAKDOWN = "SET_BUGS_RELEASE_BREAKDOWN";

export const FETCH_PAGE_PERFORMANCE = "FETCH_PAGE_PERFORMANCE";
export const SET_PAGE_PERFORMANCE = "SET_PAGE_PERFORMANCE";

export const FETCH_PAGE_PERFORMANCE_BREAKDOWN = "FETCH_PAGE_PERFORMANCE_BREAKDOWN";
export const SET_PAGE_PERFORMANCE_BREAKDOWN = "SET_PAGE_PERFORMANCE_BREAKDOWN";

export const FETCH_ACCESSIBILITY = "FETCH_ACCESSIBILITY";
export const SET_ACCESSIBILITY = "SET_ACCESSIBILITY";

export const FETCH_CUSTOMER_EXPERIENCE = "FETCH_CUSTOMER_EXPERIENCE";
export const SET_CUSTOMER_EXPERIENCE = "SET_CUSTOMER_EXPERIENCE";

export const FETCH_ERROR_PAGES = "FETCH_ERROR_PAGES";
export const SET_ERROR_PAGES = "SET_ERROR_PAGES";
