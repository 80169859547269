import React, { useMemo, useState } from "react";
import "../../../assets/styles/docs/ccsData.scss";
import { MTM_DATA_DEFINITIONS } from "../../../constants/mtmConstants";

export const MtmData = (): JSX.Element => {
  const [definitions, setDefinitions] = useState(MTM_DATA_DEFINITIONS);

  const overviewDefinitions = useMemo(() => {
    if (definitions?.length) {
      return definitions?.filter((val: { market: string }) => val.market == "Overview");
    }
    return [];
  }, [definitions]);

  const futureMarketDefinitions = useMemo(() => {
    if (definitions?.length) {
      return definitions?.filter((val: { market: string }) => val.market == "Future Market");
    }
    return [];
  }, [definitions]);

  const nearMarketDefinitions = useMemo(() => {
    if (definitions?.length) {
      return definitions?.filter((val: { market: string }) => val.market == "Near Market");
    }
    return [];
  }, [definitions]);

  const inMarketDefinitions = useMemo(() => {
    if (definitions?.length) {
      return definitions?.filter((val: { market: string }) => val.market == "In market");
    }
    return [];
  }, [definitions]);

  return (
    <div className="data_documentation">
      <h3>MTM Metrics - DATA OVERVIEW</h3>
      {overviewDefinitions?.map((val: { definition: string; kpi: string }, idx: number) => (
        <p key={idx}>
          {val.kpi} - {val.definition}
        </p>
      ))}
      <br />
      <h4>Future Market Data Definitions and Sources</h4>
      <br />
      <table className="docs_table">
        <thead>
          <tr>
            <th>Category</th>
            <th>KPI</th>
            <th>Description</th>
            <th>Data Source</th>
            <th>Upload Destination</th>
          </tr>
        </thead>
        <tbody>
          {futureMarketDefinitions?.map(
            (
              val: {
                upload_destination: string;
                market: string;
                kpi: string;
                definition: string;
                source_data: string;
              },
              idx: number
            ) => (
              <tr key={idx}>
                <td>{val.market}</td>
                <td>{val.kpi}</td>
                <td>{val.definition}</td>
                <td>{val.source_data}</td>
                <td>{val.upload_destination}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
      <br />
      <h4>Near Market Data Definitions and Sources</h4>
      <br />
      <table className="docs_table">
        <thead>
          <tr>
            <th>Category</th>
            <th>KPI</th>
            <th>Description</th>
            <th>Data Source</th>
            <th>Upload Destination</th>
          </tr>
        </thead>
        <tbody>
          {nearMarketDefinitions?.map(
            (
              val: {
                upload_destination: string;
                market: string;
                kpi: string;
                definition: string;
                source_data: string;
              },
              idx: number
            ) => (
              <tr key={idx}>
                <td>{val.market}</td>
                <td>{val.kpi}</td>
                <td>{val.definition}</td>
                <td>{val.source_data}</td>
                <td>{val.upload_destination}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
      <br />
      <h4>In Market Data Definitions and Sources</h4>
      <br />
      <table className="docs_table">
        <thead>
          <tr>
            <th>Category</th>
            <th>KPI</th>
            <th>Description</th>
            <th>Data Source</th>
            <th>Upload Destination</th>
          </tr>
        </thead>
        <tbody>
          {inMarketDefinitions?.map(
            (
              val: {
                upload_destination: string;
                market: string;
                kpi: string;
                definition: string;
                source_data: string;
              },
              idx: number
            ) => (
              <tr key={idx}>
                <td>{val.market}</td>
                <td>{val.kpi}</td>
                <td>{val.definition}</td>
                <td>{val.source_data}</td>
                <td>{val.upload_destination}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
      <br />
    </div>
  );
};
