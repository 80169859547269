import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { setParameters, setSpecificParameter } from "../../actions";
import "../../assets/styles/component/filters.scss";
import { DEFAULT_SEO_PARAMETERS, DEVICE_FILTER_OPTIONS, MIN_SELECTABLE_DATE, SEO_DATE_FILTER_OPTIONS } from "../../constants";
import { FilterContext } from "../../context";
import { isCustomDate } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { compareValues } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DateFilter, DefaultFilter } from "./common";
import { SeoGeographyFilter } from "./common/SeoGeographyFilter";
import { closeFilterOptions, closeMarketOptions, objectToQuerystring } from "./subs/helpers";

export const SeoFilters = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();
  const dataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.seo);
  const geography = useSelector((state: RootStateOrAny) => state.filters.seo_markets);

  const params = useSelector((state: RootStateOrAny) => state.seo_parameters);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.seo_parameters.date_range);
  const siteParamValue = useSelector((state: RootStateOrAny) => state.seo_parameters.hostname);
  const marketParamValue = useSelector((state: RootStateOrAny) => state.seo_parameters.market);
  const deviceParamValue = useSelector((state: RootStateOrAny) => state.seo_parameters.mobile_device_type);

  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);
  const [availableDates, setAvailableDates] = useState<{ start: string; end: string }>({ start: MIN_SELECTABLE_DATE, end: dataRefresh });
  const [marketsList, setMarketsList] = useState<Array<Record<string, string>>>([]);
  const [geographyValue, setGeographyValue] = useState("");

  //Update markets once they are fetched
  useEffect(() => {
    if (geography) {
      setMarketsList(geography.sort(compareValues("market")));
    }
  }, [geography]);

  //Set available dates depending on the last data refresh to use in the custom date range picker
  useEffect(() => {
    if (dataRefresh) {
      setAvailableDates({ start: MIN_SELECTABLE_DATE, end: moment(dataRefresh).format("DD/MM/YYYY") });
    }
  }, [dataRefresh]);

  //Manipulate geography value
  useEffect(() => {
    const numberOfSites = marketsList.filter((row) => row.market === marketParamValue)[0]?.siteID?.length;
    const sitesValue = numberOfSites && numberOfSites > 1 ? `(${numberOfSites} sites)` : "";
    const geoValue = siteParamValue === "All" ? `${marketParamValue} ${sitesValue}` : siteParamValue;
    setGeographyValue(geoValue);
  }, [marketsList, siteParamValue, marketParamValue]);

  //Reset marketParam
  useEffect(() => {
    const markets = marketsList.map((row) => row.market);
    if (markets.length > 0) {
      if (!markets.includes(marketParamValue)) dispatch(setSpecificParameter("market", "United States"));
    }
  }, [marketParamValue, marketsList]);

  //Reset date range param
  useEffect(() => {
    if (dateRangeParamValue) {
      const isValidDate: boolean = SEO_DATE_FILTER_OPTIONS.includes(dateRangeParamValue) || isCustomDate(dateRangeParamValue);
      if (!isValidDate) dispatch(setSpecificParameter("date_range", "Last month"));
    }
  }, [dateRangeParamValue]);

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));

      eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, page: "SEO reporting", dashboard: "SEO" });
    }
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };
  const onRenderOptions = ({ list, filter }: { list: string[]; filter: string }) => {
    return (
      <>
        {list?.map((item: string) => {
          return (
            <li key={item} data-filter={filter} onClick={handleFilterOptionClick} data-value={item}>
              {item}
            </li>
          );
        })}
      </>
    );
  };

  //Set param values
  useEffect(() => {
    if (Object.keys(params).length > 0) {
      history.push({ search: `?${objectToQuerystring(params)}` });
    }
  }, [params]);

  const resetFilters = () => {
    eventTracking(MixpanelEvents.filter_reset, { page: location.pathname, dashboard: "SEO" });
    dispatch(setParameters(DEFAULT_SEO_PARAMETERS));
  };
  return (
    <FiltersLayout resetFilters={resetFilters} extraClass={"digital_filters"}>
      <>
        {/*Date Range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DateFilter
            onFilterClick={showFilterOptionsFn}
            renderOptions={onRenderOptions}
            value={dateRangeParamValue}
            maxDate={dataRefresh}
            availableDates={availableDates}
            dateOptions={SEO_DATE_FILTER_OPTIONS}
            page="SEO reporting"
          />
        </ErrorBoundary>

        {/*Geography*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <SeoGeographyFilter markets={marketsList} filterValue={geographyValue} />
        </ErrorBoundary>

        {/*Device*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            filterName={"mobile_device_type"}
            list={DEVICE_FILTER_OPTIONS}
            filterValue={deviceParamValue}
            filterLabel={"DEVICE"}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>
      </>
    </FiltersLayout>
  );
});
