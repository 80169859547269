import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { DmeoContext } from "./context/DmeoContext";
import { DashboardLayout } from "../../components/Layouts";
import { useDispatch } from "react-redux";
import { setPageName } from "../../actions";
import { BackgroundContext } from "../../context";
import { PageTitle } from "../../components/PageContent/subs";
import { DefaultPageContent } from "../../components/PageContent";
import DmeoFilters from "../../components/Filters/DmeoFilters";
import DmeoTitle from "./components/DmeoTitle";
import { ErrorMsg } from "../../components/AppMessages";
import { ErrorBoundary } from "@sentry/react";
import "../../assets/styles/pages/dmeoDashboard.scss";
import MainDashboardContent from "./content/MainDashboardContent";
import DigitalMarketingSpendContent from "./content/DigitalMarketingSpendContent";
import CampaignPerformanceContent from "./content/CampaignPerformanceContent";
import { getDmeoLastDataRefresh } from "../../api/dmeoApi";
import { useQuery } from "@tanstack/react-query";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";

// button to unselect the metric and return to the main dashboard
type IBackButton = {
  setMetric: Dispatch<SetStateAction<string>>;
  metric: string;
};
const BackButton = (props: IBackButton) => {
  const { setMetric } = props;
  // if metric is "" then return nothing
  if (props.metric === "") {
    return null;
  }
  return (
    <div className="back_button">
      <div onClick={() => setMetric("")}>
        &lt;
        <span className="back_text">Back</span>
      </div>
    </div>
  );
};

const Dmeo = () => {
  const dispatch = useDispatch();
  const { setHideBackgroundImage } = useContext(BackgroundContext);
  const [metric, setMetric] = useState<string>("");

  const { data: lastDataRefreshData, refetch: refetchLastDataRefresh } = useQuery({
    queryKey: ["dmeoLastDataRefresh"],
    queryFn: getDmeoLastDataRefresh,
    enabled: false,
  });

  useEffect(() => {
    dispatch(setPageName("DMEO Dashboard"));
    setHideBackgroundImage(true);
    refetchLastDataRefresh();
    eventTracking(MixpanelEvents.page_view, { dashboard: "DMEO" });
  }, []);

  return (
    <DmeoContext.Provider value={{ metric, setMetric }}>
      <DashboardLayout>
        <DefaultPageContent
          filter={<DmeoFilters metric={metric} />}
          dataDocumentation="dmeo"
          lastDataRefresh={lastDataRefreshData?.date ?? ""}
          pageTitle={<PageTitle dataDocumentation="dmeo" backButton={<BackButton setMetric={setMetric} metric={metric} />} />}
        >
          <div className="dmeo-layout">
            <div className="title-menu hide_on_mobile">
              <div id="digital_marketing_spend_title" className="title_row dmeo-row" data-target="digital_marketing_spend">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <DmeoTitle title={"Digital Marketing Spend"} />
                </ErrorBoundary>
              </div>

              <div id="campaign_performance_title" className="title_row dmeo-row" data-target="campaign_performance">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <DmeoTitle title={"Campaign Performance"} />
                </ErrorBoundary>
              </div>
            </div>

            {metric == "" && <MainDashboardContent />}
            {metric == "Digital Marketing Spend" && <DigitalMarketingSpendContent />}
            {metric == "Campaign Performance" && <CampaignPerformanceContent />}
          </div>
        </DefaultPageContent>
      </DashboardLayout>
    </DmeoContext.Provider>
  );
};

export default Dmeo;
