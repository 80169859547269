import React from "react";
import { ToggleSwitch } from "../../../../components/Toogle";

interface Props {
  showVolumes: boolean;
  setShowVolumes: React.Dispatch<React.SetStateAction<boolean>>;
  nimTech?: boolean;
  mixLabel?: boolean;
}

const VolumeToggle = (props: Props) => {
  const { showVolumes, setShowVolumes, nimTech, mixLabel } = props;
  return (
    <div className="toogle_container">
      <p>See:</p>
      <ToggleSwitch
        activeToggleLabel={nimTech ? "Volume" : mixLabel ? "Mix" : "Grade mix"}
        inactiveToggleLabel={nimTech ? "Mix" : "Volume"}
        active={nimTech ? showVolumes : !showVolumes}
        handleToggleClick={() => setShowVolumes(!showVolumes)}
        toggleClassName={"product_marketing_optimization_toggle"}
      />
    </div>
  );
};

export default VolumeToggle;
