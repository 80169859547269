import React from "react";
import { DEFAULT_LANGUAGE } from "../../../../constants";
import { getRating } from "../../../../pages/SEO/helpers/helpers";
import { formatToThreeDigits } from "../../../../utils/numberFormatting";

export const cellWithIndicator = (value: number, rating: string): JSX.Element => {
  return (
    <p className="td_comparison">
      <span className={`indicator ${rating}`}></span>
      <span className="value">{value > 999 || value < -999 ? ">999" : value}%</span>
    </p>
  );
};

export const renderComparisonCell = ({ value }: { value: number }): JSX.Element => {
  const rating = getRating(value);
  const valueToRender = Math.abs(Math.round(value));

  if (isNaN(value)) {
    return (
      <p className="td_comparison">
        <span className="indicator na"></span>
        <span className="value">n/a</span>
      </p>
    );
  }

  return cellWithIndicator(valueToRender, rating);
};

export const formatComparisonNumbers = ({ value, hideSign }: { value: number; hideSign?: boolean }) => {
  if (window.innerWidth < 878) {
    return isNaN(value) || value === null ? "n/a" : value > 0 && !hideSign ? `+${formatToThreeDigits(value)}` : formatToThreeDigits(value);
  } else {
    return isNaN(value) || value === null
      ? "n/a"
      : value > 0 && !hideSign
      ? `+${value.toLocaleString(DEFAULT_LANGUAGE)}`
      : value.toLocaleString(DEFAULT_LANGUAGE);
  }
};
