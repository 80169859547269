import React from "react";
import { LegendMarker } from "../../../../components/Charts/components/LegendMarker";

interface Props {
  title: string;
  applicationType: string;
  isSelected: boolean;
  isInactive: boolean;
  handleApplicationClick: (evt: React.MouseEvent<HTMLElement>) => void;
  borderStyle?: "inherit" | "initial" | "inset" | "dashed" | "dotted" | "double" | "groove" | "outset" | "ridge" | "solid" | "mixed";
}

export const ApplicationTypeTitle = (props: Props) => {
  const { title, applicationType, isInactive, borderStyle, isSelected, handleApplicationClick } = props;
  return (
    <div
      className={`clickable_app_type ${isSelected ? "active" : ""} ${isInactive ? "disabled" : ""}`}
      data-type={applicationType}
      data-test-id={title.replace(" ", "_")}
      id={title.replace(" ", "_")}
      onClick={handleApplicationClick}
    >
      <LegendMarker shape={"line"} borderStyle={borderStyle || "solid"} />
      <span>{title}</span>
    </div>
  );
};
