import { ErrorBoundary } from "@sentry/react";
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { fetchWebsiteVisits } from "../../../actions";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import { GranularityOptions } from "../../../components/Charts/components";
import { FullFiguresChart } from "../../../components/Charts/DigitalPerformance";
import { metricTypes, varianceMetricTypes } from "../../../constants";
import { ThemeContext } from "../../../context";

export interface Props {
  granularity: string;
  metric: metricTypes;
  kbas: boolean;
  leads: boolean;
  visits: boolean;
  checkedOptions: Array<metricTypes>;
  isCpoDashboard?: boolean;
  isVolume: boolean;
  newCarsChecked?: boolean;
  onHandleRadioClick: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  toggleAnnotationsDialog: () => void;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
}

export const FullFiguresGraph = (props: Props) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const data = useSelector((state: RootStateOrAny) => state.digital_performance.website_visits);
  const cpoKeyIndicatorTrendsData = useSelector((state: RootStateOrAny) => state.cpo_digital_performance.key_indicator_variance);
  const isLoading = useSelector((state: RootStateOrAny) => state.loading.full_figures_graph);
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const annotations = useSelector((state: RootStateOrAny) => state.digital_performance.baseline_chart_annotations);

  const {
    granularity,
    metric,
    onHandleRadioClick,
    leads,
    kbas,
    visits,
    isCpoDashboard,
    isVolume,
    newCarsChecked,
    setAnnotationEndDate,
    setAnnotationStartDate,
    toggleAnnotationsDialog,
    setAnnotationDefaultMetric,
  } = props;

  const [isLastWeek, setIsLastWeek] = useState(true);

  const [seriesList, setSeriesList] = useState<Array<varianceMetricTypes>>([]);

  useEffect(() => {
    parameters.date_range === "Last week" ? setIsLastWeek(true) : setIsLastWeek(false);
  }, [parameters.date_range]);

  useEffect(() => {
    !isCpoDashboard && dispatch(fetchWebsiteVisits());
  }, [dispatch, parameters, isLastWeek]);

  const graphData = useMemo(() => {
    if (Object.keys(data).length === 0) return;
    const chartDataCurrent = data?.Current;
    const chartDataYoY = data?.YoY;
    // if (annotations && data && metric && granularity) {
    //   resultCurrent = attachAnnotationsFullFigures(annotations, chartDataCurrent, metric, granularity);
    //   resultYoY = attachAnnotationsFullFigures(annotations, chartDataYoY, metric, granularity);
    // }
    return { Current: chartDataCurrent, YoY: chartDataYoY };
  }, [data, annotations, granularity, metric]);

  const cpoGraphData = useMemo(() => {
    if (Object.keys(cpoKeyIndicatorTrendsData).length === 0) return;
    const chartDataCurrent = cpoKeyIndicatorTrendsData?.Current;
    const chartDataYoY = cpoKeyIndicatorTrendsData?.YoY;
    return { Current: chartDataCurrent, YoY: chartDataYoY };
  }, [cpoKeyIndicatorTrendsData, annotations, granularity, metric]);

  useMemo(() => {
    setSeriesList([]);
    if (leads) setSeriesList((seriesList) => [...seriesList, "leads"]);
    if (kbas) setSeriesList((seriesList) => [...seriesList, "kbas"]);
    if (visits) setSeriesList((seriesList) => [...seriesList, "visits"]);
    if (newCarsChecked) setSeriesList((seriesList) => [...seriesList, `${metric}_oce`]);
    setSeriesList((seriesList) => [...seriesList, metric]);
  }, [leads, kbas, visits, newCarsChecked]);

  return (
    <>
      {graphData || cpoGraphData ? (
        <>
          <ErrorBoundary fallback={<ErrorMsg />}>
            <FullFiguresChart
              data={isCpoDashboard && cpoGraphData ? cpoGraphData : !isCpoDashboard && graphData ? graphData : []}
              activeMetric={metric}
              newCarsChecked={newCarsChecked}
              isCpoDashboard={isCpoDashboard}
              seriesList={seriesList}
              granularity={granularity}
              dateValue={parameters.date_range}
              setAnnotationStartDate={setAnnotationStartDate}
              setAnnotationEndDate={setAnnotationEndDate}
              toggleAnnotationsDialog={toggleAnnotationsDialog}
              setAnnotationDefaultMetric={setAnnotationDefaultMetric}
              theme={themeContext.theme}
              isLastWeek
              isVolume={isVolume}
            />
          </ErrorBoundary>
          <GranularityOptions
            onHandleRadioClick={onHandleRadioClick}
            granularity={granularity}
            name={"full_figures"}
            dateValue={parameters.date_range}
          />
        </>
      ) : isLoading ? null : (
        <NoDataMsg />
      )}
    </>
  );
};
