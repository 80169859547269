import React from "react";
import "../../assets/styles/component/breadcrumb.scss";

interface Props {
  list: Array<string>;
  activeValue: string;
  onBreadcrumbClick: (evt: React.MouseEvent<HTMLElement>) => void;
}

export const Breadcrumb = (props: Props) => {
  const { list, activeValue, onBreadcrumbClick: handleBreadcrumbClick } = props;
  return (
    <div className="breadcrumb">
      {list.map((value) => (
        <p
          className={`breadcrumb__step ${activeValue === value ? "breadcrumb__step--active" : ""}`}
          data-value={value}
          onClick={handleBreadcrumbClick}
          key={value.replace(" ", "_")}
        >
          {value}
        </p>
      ))}
    </div>
  );
};
