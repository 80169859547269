import React, { useContext, useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  fetchMidasStrategicDealerSupportFilters,
  fetchMidasStrategicDealerSupportLastDataRefresh,
  fetchStrategicDealerSupportDataByQuarter,
  setPageName,
} from "../../../actions";
import "../../../assets/styles/pages/MidasMediaMix.scss";
import "../../../assets/styles/pages/midasUnderConstruction.scss";
import Collapsible from "../../../components/Collapsible/Collapsible";
import MarketingInvastmentDashboardFilters from "../../../components/Filters/MarketingInvastmentDashboardFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { MIDAS_BTN_FILTERS } from "../../../constants";
import { DashboardBannerContext } from "../../../context";
import StrategicDealerSupportGraph from "./components/StrategicDealerSupportGraph";
import StrategicDealerSupportTables from "./components/StrategicDealerSupportTables";

const StrategicDealerSupport = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.midas_fmi_last_refresh_date);
  const { brand, nsc, unit, local_currency_name, yr_scnr_rv, show_touchpoints, mc_group, subgroup, market } = useSelector(
    (state: RootStateOrAny) => state.parameters
  );

  const pageName = "Strategic - Dealer support";
  useEffect(() => {
    Promise.all([
      // Sets page name
      dispatch(setPageName(pageName)),
      dispatch(fetchMidasStrategicDealerSupportLastDataRefresh()),
    ]);
    setBannerText("Forex Rate based from latest BP");
    setShowBanner(false);
  }, [dispatch]);

  useEffect(() => {
    Promise.all([dispatch(fetchStrategicDealerSupportDataByQuarter())]);
  }, [brand, yr_scnr_rv, nsc, unit, local_currency_name, show_touchpoints, mc_group, subgroup, market]);

  useEffect(() => {
    if (brand && yr_scnr_rv && nsc) dispatch(fetchMidasStrategicDealerSupportFilters());
  }, [brand, yr_scnr_rv, nsc, mc_group, subgroup, market]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<MarketingInvastmentDashboardFilters filterBtns={MIDAS_BTN_FILTERS} hasUnit isCustom />}
        dataDocumentation="midas"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="midas" />}
      >
        <div className="midas_media_mix_sotu">
          <Collapsible myStyle="pmo_sotu" show={true} title="">
            <div className="media_mix_by_quarter">
              <StrategicDealerSupportGraph />
            </div>
          </Collapsible>
          <div className="tables">
            <StrategicDealerSupportTables />
          </div>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default StrategicDealerSupport;
