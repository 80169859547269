import {
  FETCH_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL,
  FETCH_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL_TABLE,
  FETCH_EXECUTIVE_SUMMARY_CHANNEL_MIX_PERFORMANCE,
  FETCH_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_DATE,
  FETCH_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_MARKET,
  FETCH_EXECUTIVE_SUMMARY_DETAILED_COMPARISONS,
  FETCH_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_DATE,
  FETCH_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_MARKET,
  FETCH_EXECUTIVE_SUMMARY_DIS_SUMMARY,
  FETCH_EXECUTIVE_SUMMARY_KEY_INDICATORS,
  FETCH_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_DATE,
  FETCH_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_MARKET,
  SET_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL,
  SET_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL_TABLE,
  SET_EXECUTIVE_SUMMARY_CHANNEL_MIX_PERFORMANCE,
  SET_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_DATE,
  SET_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_MARKET,
  SET_EXECUTIVE_SUMMARY_DETAILED_COMPARISONS,
  SET_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_DATE,
  SET_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_MARKET,
  SET_EXECUTIVE_SUMMARY_DIS_SUMMARY,
  SET_EXECUTIVE_SUMMARY_KEY_INDICATORS,
  SET_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_DATE,
  SET_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_MARKET,
} from "./actionTypes/executiveSummaryActionTypes";

export const fetchExecutiveSummaryDisSummary = () => ({ type: FETCH_EXECUTIVE_SUMMARY_DIS_SUMMARY });
export const setExecutiveSummaryDisSummary = (data: any) => ({ type: SET_EXECUTIVE_SUMMARY_DIS_SUMMARY, payload: data });

export const fetchExecutiveSummaryAggregatedPurchaseFunnel = () => ({ type: FETCH_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL });
export const setExecutiveSummaryAggregatedPurchaseFunnel = (data: any) => ({
  type: SET_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL,
  payload: data,
});

export const fetchExecutiveSummaryAggregatedPurchaseFunnelTable = () => ({
  type: FETCH_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL_TABLE,
});
export const setExecutiveSummaryAggregatedPurchaseFunnelTable = (data: any) => ({
  type: SET_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL_TABLE,
  payload: data,
});

export const fetchExecutiveSummaryDetailedComparisons = () => ({ type: FETCH_EXECUTIVE_SUMMARY_DETAILED_COMPARISONS });
export const setExecutiveSummaryDetailedComparisons = (data: any) => ({ type: SET_EXECUTIVE_SUMMARY_DETAILED_COMPARISONS, payload: data });

export const fetchExecutiveSummaryChannelMixPerformance = () => ({ type: FETCH_EXECUTIVE_SUMMARY_CHANNEL_MIX_PERFORMANCE });
export const setExecutiveSummaryChannelMixPerformance = (data: any) => ({
  type: SET_EXECUTIVE_SUMMARY_CHANNEL_MIX_PERFORMANCE,
  payload: data,
});

export const fetchExecutiveSummaryPrivateSalesByMarket = () => ({ type: FETCH_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_MARKET });
export const setExecutiveSummaryPrivateSalesByMarket = (data: any) => ({
  type: SET_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_MARKET,
  payload: data,
});

export const fetchExecutiveSummaryPrivateSalesByDate = () => ({ type: FETCH_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_DATE });
export const setExecutiveSummaryPrivateSalesByDate = (data: any) => ({ type: SET_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_DATE, payload: data });

export const fetchExecutiveSummaryDisPercentageByMarket = () => ({ type: FETCH_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_MARKET });
export const setExecutiveSummaryDisPercentageByMarket = (data: any) => ({
  type: SET_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_MARKET,
  payload: data,
});

export const fetchExecutiveSummaryDisPercentageByDate = () => ({ type: FETCH_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_DATE });
export const setExecutiveSummaryDisPercentageByDate = (data: any) => ({
  type: SET_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_DATE,
  payload: data,
});

export const fetchExecutiveSummaryCloseRateByMarket = () => ({ type: FETCH_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_MARKET });
export const setExecutiveSummaryCloseRateByMarket = (data: any) => ({ type: SET_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_MARKET, payload: data });

export const fetchExecutiveSummaryCloseRateByDate = () => ({ type: FETCH_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_DATE });
export const setExecutiveSummaryCloseRateByDate = (data: any) => ({ type: SET_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_DATE, payload: data });

export const fetchExecutiveSummaryKeyIndicators = (metric: string, period: string) => ({
  type: FETCH_EXECUTIVE_SUMMARY_KEY_INDICATORS,
  metric,
  period,
});
export const setExecutiveSummaryKeyIndicators = (data: any) => ({ type: SET_EXECUTIVE_SUMMARY_KEY_INDICATORS, payload: data });
