import React, { useContext, useEffect } from "react";
import DmeoMultipleBarChart from "../../../components/Charts/Dmeo/DmeoMultipleBarChart";
import { ThemeContext } from "../../../context";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getDmeoDigitalSpendByChannelTrend } from "../../../api/dmeoApi";
const data = [
  {
    date: "2024-04-01",
    display: 4.5,
    paid: 3.5,
    programmatic: 2.5,
    social: 3,
  },
  {
    date: "2024-05-01",
    display: 3,
    paid: 5.5,
    programmatic: 6,
    social: 5,
  },
  {
    date: "2024-06-01",
    display: 4,
    paid: 5.5,
    programmatic: 6,
    social: 2.8,
  },
];

const series = [
  {
    name: "Display",
    field: "display",
  },
  {
    name: "Paid",
    field: "paid",
  },
  {
    name: "Programmatic",
    field: "programmatic",
  },
  {
    name: "Social",
    field: "social",
  },
];

const DigitalSpendByChannelTrend = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { theme } = useContext(ThemeContext);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["digitalSpendByChannelTrendData"],
    queryFn: getDmeoDigitalSpendByChannelTrend,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile">
      <div className="tile-header">Digital Spend by Channel</div>
      <div className="tile-content">
        <DmeoMultipleBarChart
          chartId="digitalSpendByChannelBarChart"
          data={data?.data ? data.data : []}
          seriesList={data?.series ? data.series : []}
          isLoading={isFetching}
          theme={theme}
        />
      </div>
    </div>
  );
});

export default DigitalSpendByChannelTrend;
