import { ErrorBoundary } from "@sentry/react";
import React, { useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import {
  fetchDepositInsightsByCity,
  fetchDepositInsightsByCity_Belgium,
  fetchDepositInsightsByCity_Canada,
  fetchDepositInsightsByCity_France,
  fetchDepositInsightsByCity_Germany,
  fetchDepositInsightsByCity_Italy,
  fetchDepositInsightsByCity_Netherlands,
  fetchDepositInsightsByCity_Norway,
  fetchDepositInsightsByCity_Sweden,
  fetchDepositInsightsByCity_UK,
  fetchDepositInsightsByCity_USA,
} from "../../../../api/Ariya";
import { ErrorMsg } from "../../../../components/AppMessages";
import { IPreoderDeposits } from "../../../../constants/interface/ariya";
import { plusSignNumbers } from "../../../../utils/utilityFunctions";
import { AriyaCityTable } from "../../Components";
import { reduceCityMetrics } from "../../helpers/dataFunctions";
import styles from "./preorderByCity.module.scss";

type tableDataType = Array<{
  city: string;
  completedShare: number;
  cancelledShare: number;
  depositShare: number;
  completedTotal: number;
  cancelledTotal: number;
  depositTotal: number;
  completedTotal_previous: number;
  cancelledTotal_previous: number;
  depositTotal_previous: number;
}>;

const geographyOptions = [
  { market: "Canada" },
  { market: "Japan" },
  { market: "Netherlands" },
  { market: "Norway" },
  { market: "United Kingdom" },
  { market: "United States" },
  { market: "France" },
  { market: "Germany" },
  { market: "Italy" },
  { market: "Sweden" },
  { market: "Belgium" },
];

const marketApiMap: Record<string, () => Promise<IPreoderDeposits>> = {
  Japan: fetchDepositInsightsByCity,
  Norway: fetchDepositInsightsByCity_Norway,
  "United States": fetchDepositInsightsByCity_USA,
  "United Kingdom": fetchDepositInsightsByCity_UK,
  France: fetchDepositInsightsByCity_France,
  Italy: fetchDepositInsightsByCity_Italy,
  Germany: fetchDepositInsightsByCity_Germany,
  Netherlands: fetchDepositInsightsByCity_Netherlands,
  Canada: fetchDepositInsightsByCity_Canada,
  Sweden: fetchDepositInsightsByCity_Sweden,
  Belgium: fetchDepositInsightsByCity_Belgium,
};

export const PreordersByCity = () => {
  const userTheme = useSelector((state: RootStateOrAny) => state.user.details.dark_mode);

  const [hiddenColumns] = useState([""]);
  const [tableData, setTableData] = useState<tableDataType>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [cityValue, setCityValue] = useState("Japan");
  const [theme, setTheme] = useState("dark");

  // Preorder city breakdown
  useEffect(() => {
    marketApiMap[cityValue]().then((response) => {
      if (response && !("error" in response)) {
        const allCitiesData = response.report.data;

        const groupedPreOrdersData = reduceCityMetrics(allCitiesData, cityValue === "United Kingdom");
        const lastSevenDayPreOrdersData = reduceCityMetrics(allCitiesData.slice(-7), cityValue === "United Kingdom");
        const groupedValues = [...Object.values(groupedPreOrdersData)];

        const highestPreOrdersValues = {
          completed: Math.max(...groupedValues.map((value) => value.completed)),
          deposit: Math.max(...groupedValues.map((value) => value.deposit)),
          cancelled: Math.max(...groupedValues.map((value) => value.cancelled)),
        };

        const mergedPreOrdersData = Object.entries(groupedPreOrdersData)
          .map(([key, value]) => {
            if (lastSevenDayPreOrdersData[key]) {
              const completedShare = parseFloat(((value.completed / highestPreOrdersValues.completed) * 100).toFixed(2));
              const depositShare = parseFloat(((value.deposit / highestPreOrdersValues.deposit) * 100).toFixed(2));
              const cancelledShare = parseFloat(((value.cancelled / highestPreOrdersValues.cancelled) * 100).toFixed(2));

              const totals = groupedPreOrdersData[key];
              const lastSevenDays = lastSevenDayPreOrdersData[key];
              return {
                city: key,
                completedShare,
                cancelledShare,
                depositShare,
                completedTotal: totals.completed,
                cancelledTotal: totals.cancelled,
                depositTotal: totals.deposit,
                completedTotal_previous: lastSevenDays.completed,
                cancelledTotal_previous: lastSevenDays.cancelled,
                depositTotal_previous: lastSevenDays.deposit,
              };
            }
          })
          .filter((row) => !!row); // Filters out undefined
        setTableData(mergedPreOrdersData as tableDataType);
      }
    });
  }, [cityValue]);

  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(
    () => [
      {
        id: "city",
        Header: "",
        accessor: "city",
        sortable: false,
      },
      {
        id: "completedShare",
        Header: "",
        accessor: cityValue === "Japan" ? "depositShare" : "completedShare",
        sortable: false,
        Cell: function renderProgressBar(props1: { value: number }) {
          return (
            <div className="progress-bar">
              <span style={{ width: `${props1.value}%` }}>&nbsp;</span>
            </div>
          );
        },
      },
      {
        id: "completedTotal",
        Header: "Total online pre-orders",
        accessor: cityValue === "Japan" ? "depositTotal" : "completedTotal",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: function renderVisitsType(props1: { value: number }) {
          const { value } = props1;

          return value ? value.toLocaleString() : "n/a";
        },
      },
      {
        Header: "In the last 7 days",
        accessor: `completedTotal_previous`,
        id: cityValue === "Japan" ? "depositTotal_previous" : "completedTotal_previous",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: function renderVisitsType(props1: { value: string }) {
          const { value } = props1;

          return plusSignNumbers(value);
        },
      },
    ],
    []
  );

  const handleFilterValueClick = (evt: React.MouseEvent<HTMLElement>) => {
    setCityValue(evt.currentTarget.dataset.value as string);
    setShowFilters(false);
  };

  useEffect(() => {
    userTheme ? setTheme("dark") : setTheme("light");
  }, [userTheme]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className={styles.filterContainer}>
        <div className={`${styles.cityFilter} border`}>
          <div className={`${styles.filterTitle} secondaryFont`}>Geography</div>
          <input
            type="text"
            className={`${styles.inputWithArrow} primaryFont`}
            value={cityValue}
            onClick={() => setShowFilters((prevState) => !prevState)}
            readOnly
          />
        </div>
        <ul className={`${styles.filterOptions} ${showFilters ? styles.showFilters : ""}`}>
          {geographyOptions.map((option) => (
            <li className={styles.filterItem} data-value={option.market} onClick={handleFilterValueClick} key={option.market}>
              {option.market}
            </li>
          ))}
        </ul>
      </div>

      {data.length ? (
        <AriyaCityTable
          columns={columns}
          data={data}
          id={"preorder_city_table"}
          data-test-id={"preorder_city_table"}
          // @ts-ignore
          renderRowSubComponent={() => {}}
          isChildComponent={false}
          initialSortColId={{ id: "completedTotal", desc: true }}
          hiddenCols={hiddenColumns}
          sortable={false}
        />
      ) : (
        <></>
      )}
    </ErrorBoundary>
  );
};
