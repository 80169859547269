import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { CCS_ANALYSIS_COLORS } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import {
  configureDateAxis,
  configureValueAxis,
  legendConfiguration,
  lineSeriesConfiguration,
  lineSeriesConfigurationWithoutBullets,
} from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  series: Array<{ name: string; dataKey: string }>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
  isPercentage?: boolean;
  showTooltip?: boolean;
}

export class AppPerformanceLineChart extends Component<Props> {
  chartId = this.props.chartId;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, series, theme, isPercentage, showTooltip } = this.props;

    this.chart = create(this.chartId, XYChart);
    this.chart.colors.list = CCS_ANALYSIS_COLORS.map((chartColor: string) => color(chartColor));
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "0|#.0";
    // Add legend
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.itemContainers.template.paddingTop = 2.5;
    this.chart.legend.itemContainers.template.marginBottom = 25;
    // Creating Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    if (isPercentage) {
      valueAxis.max = 100;
    }
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + `${isPercentage ? "%" : ""}`;
    });

    // Creating Series
    this.createSeries(series);
  }

  createSeries(seriesList: Array<{ name: string; dataKey: string }>) {
    seriesList.forEach((lineSeries) => {
      const series = this.chart.series.push(new LineSeries());
      if (this.props.showTooltip) {
        const bullet = lineSeriesConfiguration(series, {
          name: lineSeries.name,
          valueY: lineSeries.dataKey,
        });
        bullet.tooltipText = `[bold; font-size: var(--regular_font_size);] {dateX.formatDate('dd MMM YYYY')} [/ font-size: var(--regular_font_size);]: {valueY.formatNumber('#.##')}${
          this.props.isPercentage ? "%" : ""
        }`;
      } else {
        lineSeriesConfigurationWithoutBullets(series, {
          name: lineSeries.name,
          valueY: lineSeries.dataKey,
        });
      }
      series.dataFields.dateX = "date";
    });
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.chartId} className={"metricChart"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}
