import { SET_AVAILABLE_FILTERS, SET_CAN_ACCESS_VIEW, SET_PAGE_NAME, SET_PAGE_TITLE_DESCRIPTION } from "./actionTypes";

export const setPageName = (name: string) => {
  return { type: SET_PAGE_NAME, payload: name };
};

export const setPageAvailableFilters = (data: any) => {
  return { type: SET_AVAILABLE_FILTERS, payload: data };
};

export const setPageTitleDescription = (name: string) => {
  return { type: SET_PAGE_TITLE_DESCRIPTION, payload: name };
};

export const setCanAccessView = (data: boolean) => {
  return { type: SET_CAN_ACCESS_VIEW, payload: data };
};
