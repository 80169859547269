import {
  Box,
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  withStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import * as Sentry from "@sentry/react";
import moment, { MomentInput } from "moment";
import React, { Dispatch, SetStateAction, useState } from "react";
import DeleteIcon from "../../../../assets/images/trash_hover.svg";
import { ErrorMsg } from "../../../../components/AppMessages";
import { CYAN, WHITE } from "../../../../constants";
import { Order } from "../../../../constants/interface/admin/common";
import { IReleaseNote } from "../../../../constants/interface/admin/releaseNotes";
import { getComparator, stableSort } from "../../utils";

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(225, 225, 225, 0.15)",
    },
    "&:hover": {
      backgroundColor: CYAN,
      "& .MuiTableCell-root img": {
        filter: "invert(0%) sepia(93%) saturate(29%) hue-rotate(53deg) brightness(101%) contrast(106%)",
      },
    },
  },
}))(TableRow);

const WhiteBackgroundCheckbox = withStyles(() => ({
  root: {
    color: "black",
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      color: "#5ed2ff",
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "#5ed2ff",
      zIndex: -1,
    },
    "& .MuiIconButton-colorSecondary": {
      color: "#5ed2ff !important",
    },
  },
  checked: {},
}))(Checkbox);

interface Props {
  data: Array<IReleaseNote>;
  selectedReleaseNote: IReleaseNote | undefined;
  setSelectedReleaseNote: Dispatch<SetStateAction<IReleaseNote | undefined>>;
  setDeleteDialogOpen: Dispatch<SetStateAction<boolean>>;
}

export const ReleaseTable = (props: Props) => {
  const { data, selectedReleaseNote, setSelectedReleaseNote, setDeleteDialogOpen } = props;
  const [rowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("version_number");

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: any) => (event: any) => {
    handleRequestSort(event, property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const headCells = [
    { id: "version_number", numeric: false, disablePadding: false, label: "Version", sortable: true },
    { id: "major", numeric: true, disablePadding: false, label: "Major", sortable: true },
    { id: "minor", numeric: true, disablePadding: false, label: "Minor", sortable: true },
    { id: "patch_notes", numeric: false, disablePadding: false, label: "Popup", sortable: false },
    { id: "release_notes", numeric: false, disablePadding: false, label: "Release note", sortable: false },
    { id: "release_date", numeric: false, disablePadding: false, label: "Release date", sortable: true },
    { id: "valid", numeric: false, disablePadding: false, label: "Show popup?", sortable: true },
  ];

  const classes = makeStyles({
    noBorder: {
      border: "none",
    },
    tableCell: {
      color: "#FFF",
      border: "none",
      padding: "10px !important",
      verticalAlign: "top",
    },
    tableHeadCell: {
      color: "#FFF",
      border: "none",
      fontWeight: 600,
      padding: "10px !important",
    },
    orderIcon: {
      color: "#FFF !important",
      width: "2.5rem",
      height: "2.5rem",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    deleteIcon: {
      width: "1.5rem",
      filter: "invert(52%) sepia(96%) saturate(2947%) hue-rotate(165deg) brightness(93%) contrast(102%)",
      "&:hover": {
        filter: "invert(70%) sepia(45%) saturate(2008%) hue-rotate(346deg) brightness(104%) contrast(97%) !important",
      },
    },
    tablePagination: {
      color: WHITE,
      width: "auto",
    },
  })();

  return (
    <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
      <Box>
        <TableContainer component={Box}>
          <Table className={`${classes.noBorder}`} data-test-id="users-table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} style={{ width: "50px" }}></TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    className={classes.tableHeadCell}
                    align={"left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.sortable ? (
                      <TableSortLabel
                        style={{ color: WHITE, fontWeight: 600 }}
                        classes={{
                          icon: classes.orderIcon,
                        }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                        IconComponent={ArrowDropDownIcon}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(data, getComparator(order, orderBy, "version_number"))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((note) => {
                  return (
                    <StyledTableRow key={note.id} data-test-id={note.id}>
                      <TableCell className={`${classes.tableCell}`}>
                        <WhiteBackgroundCheckbox
                          checked={selectedReleaseNote?.id == note.id}
                          onChange={(e) => {
                            e.target.checked ? setSelectedReleaseNote(note) : setSelectedReleaseNote(undefined);
                          }}
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                        />
                      </TableCell>
                      <TableCell className={`${classes.tableCell}`} dangerouslySetInnerHTML={{ __html: note.version_number as string }} />
                      <TableCell className={`${classes.tableCell}`}>{note.major}</TableCell>
                      <TableCell className={`${classes.tableCell}`}>{note.minor}</TableCell>
                      <TableCell className={`${classes.tableCell}`} dangerouslySetInnerHTML={{ __html: note.popup_note as string }} />
                      <TableCell className={`${classes.tableCell}`} dangerouslySetInnerHTML={{ __html: note.release_note as string }} />
                      <TableCell className={`${classes.tableCell}`}>
                        {moment(note.release_date as MomentInput).format("DD-MM-YYYY HH:mm")}
                      </TableCell>
                      <TableCell className={`${classes.tableCell}`}>
                        {note.valid ? <CheckIcon style={{ fill: "green" }} /> : <CloseIcon style={{ fill: "red" }} />}
                      </TableCell>

                      <TableCell className={`${classes.tableCell}`} align="right">
                        <img
                          src={DeleteIcon}
                          alt="Delete Icon"
                          className={classes.deleteIcon}
                          onClick={() => {
                            setSelectedReleaseNote(note);
                            setDeleteDialogOpen(true);
                          }}
                        />
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={[]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          backIconButtonProps={{
            style: {
              color: WHITE,
            },
          }}
          nextIconButtonProps={{
            style: {
              color: WHITE,
            },
          }}
          labelDisplayedRows={({ from, to, count }) => `Showing ${from} to ${to} of ${count} entries`}
        />
      </Box>
    </Sentry.ErrorBoundary>
  );
};
