import React, { useContext, useEffect } from "react";
import DmeoDonutChart from "../../../components/Charts/Dmeo/DmeoDonutChart";
import { ThemeContext } from "../../../context";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getDmeoDigitalSpendByCampaignObjective } from "../../../api/dmeoApi";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";

const data = [
  {
    entry_page_mapped: "Consideration",
    perc_visits: 76,
  },
  {
    entry_page_mapped: "Conversion",
    perc_visits: 16,
  },
  {
    entry_page_mapped: "Awareness",
    perc_visits: 8,
  },
];

const DigitalSpendByCampaignObjectiveTile = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["digitalSpendByCampaignObjectiveDonutData"],
    queryFn: getDmeoDigitalSpendByCampaignObjective,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile">
      <div className="tile-header">Digital Spend by Campaign Objective</div>
      <div className="tile-content">
        <DmeoDonutChart
          theme={theme}
          chartId="digitalSpendCampaignObjectiveDonutChart"
          data={data}
          categoryField="entry_page_mapped"
          valueField="perc_visits"
          legendPosition="right"
          radius={110}
        />
      </div>
      <LoadingEllipsis isLoading={isFetching} />
    </div>
  );
});

export default DigitalSpendByCampaignObjectiveTile;
