import React, { useCallback, useContext } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toggle from "react-toggle";
import { fetchCurrentUserDetails } from "../../../actions";
import { updateUserDetails } from "../../../api";
import { ThemeContext } from "../../../context";

export const DarkLightToggle = (): JSX.Element => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state: RootStateOrAny) => state.user.details);
  const { setTheme } = useContext(ThemeContext);
  const wrapperClass = "input_field";

  const handleThemeChange = useCallback(() => {
    updateUserDetails(userDetails.email, { ...userDetails, dark_mode: !userDetails.dark_mode }).then((response) => {
      if (response && !("error" in response)) {
        response.dark_mode ? setTheme("dark") : setTheme("light");
        dispatch(fetchCurrentUserDetails());
        toast.success("Profile updated successfull");
      } else {
        toast.error("Failed to update profile, please try again later");
      }
    });
  }, [userDetails]);

  return (
    <div className={wrapperClass} style={{ marginBottom: "0" }}>
      <div className="field theme-button">
        <label htmlFor="theme_btn">Theme: </label>
        {
          <>
            {<span className="toggle-label">Light</span>}
            <Toggle checked={userDetails.dark_mode} name="dark_mode" onChange={handleThemeChange} id={"dark_mode"} icons={false} />
            <span className="toggle-label">Dark</span>
          </>
        }
      </div>
    </div>
  );
};
