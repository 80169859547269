import {
  SET_IMPRESSIONS_BREAKDOWN,
  SET_IMPRESSIONS_OVERVIEW,
  SET_VISITORS_COUNTRY,
  SET_VISITS_BREAKDOWN,
  SET_VISITS_OVERVIEW,
} from "../actions/actionTypes";

const initialState = {
  visits_overview: [],
  impressions_overview: [],
  visits_breakdown: [],
  impressions_breakdown: [],
  visitors_country: [],
};

const seoReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_VISITS_OVERVIEW:
      return { ...state, visits_overview: action.payload };

    case SET_VISITS_BREAKDOWN:
      return { ...state, visits_breakdown: action.payload };

    case SET_IMPRESSIONS_OVERVIEW:
      return { ...state, impressions_overview: action.payload };

    case SET_IMPRESSIONS_BREAKDOWN:
      return { ...state, impressions_breakdown: action.payload };

    case SET_VISITORS_COUNTRY:
      return { ...state, visitors_country: action.payload };
    default:
      return state;
  }
};

export default seoReducer;
