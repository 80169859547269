import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import { connect, RootStateOrAny } from "react-redux";
import WebFont from "webfontloader";
import { BIG_NUMBER_ABBREVIATION, CYAN, GRAY } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { legendConfigurationOCE, oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  data: any;
  theme: ThemeInt;
  seriesList: Array<{ name: string; field: string }>;
  isLoading: boolean;
  currency: string;
  isPercentage?: boolean;
}

class ProfileByMonthChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme, seriesList, currency, isPercentage } = this.props;

    // Global chart configuration
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.numberFormatter.numberFormat = "#.##";
    this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    this.chart.data = data;
    // Creates Legend
    this.chart.legend = legendConfigurationOCE(theme);
    this.chart.legend.reverseOrder = false;

    // Create current period date chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.dateFormats.setKey("month", "MMM yy");
    dateAxis.periodChangeDateFormats.setKey("month", "MMM yy");

    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return `${!isPercentage ? currency : ""}` + text + `${isPercentage ? "%" : ""}`;
    });
    if (!isPercentage) {
      valueAxis.renderer.numberFormatter.numberFormat = "#.0a";
      valueAxis.renderer.numberFormatter.bigNumberPrefixes = BIG_NUMBER_ABBREVIATION;
    }

    seriesList?.map(({ name, field }, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.data = data;
      const bullet = lineSeriesConfiguration(series, {
        valueY: field,
        name: name,
      });
      series.xAxis = dateAxis;
      series.dataFields.dateX = "date";
      bullet.tooltipText = `[bold; font-size: var(--regular_font_size);] {dateX.formatDate('dd MMM YYYY')} [/ font-size: var(--regular_font_size);]: {valueY.formatNumber('###,###.##')}${
        this.props.isPercentage ? "%" : ""
      }`;
      series.fill = color(idx == 0 ? CYAN : GRAY);
      series.stroke = color(idx == 0 ? CYAN : GRAY);
    });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
        </div>
      </>
    );
  }
}

function mapStateToProps(state: RootStateOrAny) {
  const currency = state.msrp.currency;
  return {
    currency,
  };
}
export default connect(mapStateToProps)(ProfileByMonthChart);
