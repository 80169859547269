import React, { useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { EuropeActivationChart } from "../../../../components/Charts/CCSCockpit/EuropeActivationChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { CustomProgressBar } from "../../../../components/Progress";
import { ThemeContext } from "../../../../context";
import { toLocaleInteger } from "../../../../utils/utilityFunctions";

type ActivationDataProps = {
  rate: number | "n/a";
  volume: number | "n/a";
  warrantyStart: number | "n/a";
  cumulative: boolean;
  chartData?: any;
  isOperational?: boolean;
  isMetric?: boolean;
  rate_difference?: number | "n/a";
  total_warranty_start_difference?: number | "n/a";
  bpG8ActivationTarget?: number | "n/a";
  isActivationPage?: boolean;
  isUae?: boolean;
};

interface ActivationOverviewProps extends ActivationDataProps {
  title: string;
  isLoading: boolean;
}

export const ActivationOverview = (props: ActivationOverviewProps): JSX.Element => {
  const { title, isLoading, ...rest } = props;

  return (
    <div className="activation_container activation_overview" id="activation_overview_container" data-test-id="activation_overview">
      <h3 className="activation_overview_title">{title}</h3>
      {ActivationOverviewContent(rest)}
      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};

export const ActivationOverviewContent = (props: ActivationDataProps) => {
  const {
    rate,
    volume,
    isActivationPage: isActivationPage,
    warrantyStart,
    chartData,
    cumulative,
    isOperational,
    rate_difference,
    bpG8ActivationTarget,
    total_warranty_start_difference,
    isUae,
  } = props;

  const themeContext = useContext(ThemeContext);

  const activationFormatParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.activation_format);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.date_range);
  const { region: regionParamValue, market_group: marketGroupParamValue } = useSelector(
    (state: RootStateOrAny) => state.activation_parameters
  );

  const showRates = activationFormatParamValue === "rates";
  const ratesValue = rate === "n/a" ? rate : `${rate}%`;

  return (
    <>
      <div className={`activation_overview_values ${isOperational ? "hidden" : ""}`}>
        <div className="value_container progress_content">
          <span className="updatable">{showRates ? ratesValue : volume}</span>
          <p>Activated</p>
          <div className="comparisons">
            <p className={"period_comparison comparison"}>
              <span className={"comparison_value"}>{rate_difference}</span>
            </p>
          </div>
          <CustomProgressBar value={Number(rate)} />
        </div>

        <div className="value_container warranty_content">
          <span className="updatable">{warrantyStart === "n/a" || warrantyStart == null ? "n/a" : toLocaleInteger(warrantyStart)}</span>
          <p>{isUae ? "Connected Cars Sold" : "Total warranty start"}</p>
          <div className="comparisons">
            <p className={"period_comparison comparison"}>
              <span className={"comparison_value"}>{total_warranty_start_difference}</span>
            </p>
          </div>
        </div>
      </div>
      {chartData && (
        <div className="chart_area">
          <EuropeActivationChart
            data={chartData}
            isCumulative={{ cumulative, startDate: dateRangeParamValue?.split(",")[0] }}
            theme={themeContext.theme}
            isActivationPage={isActivationPage}
            region={regionParamValue}
            marketGroup={marketGroupParamValue}
            isUae={isUae}
          />
        </div>
      )}
    </>
  );
};
