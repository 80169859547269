import React from "react";
import { formatToThreeDigits } from "../../../utils/numberFormatting";

interface Props {
  title: string;
  value: number;
  total: number;
  onofTotal?: number[];
  view: string;
  titleHelper: string;
  currency: string;
}

export const MtmTitle = (props: Props) => {
  const { title, total, value, onofTotal, currency, view, titleHelper } = props;

  return (
    <div className="mtm_title">
      <h4>{title}</h4>
      <div className="title_descr">
        <div>
          <p className={"investment_share_title"}>{view == "quarterly" ? `Q${titleHelper} media spend` : `${titleHelper} media spend`}</p>
          <p className={"investment_share_title"}>
            {currency}
            {value ? formatToThreeDigits(value) : "-"}
          </p>
        </div>
        <div>
          <p className={"investment_share"}>{view == "quarterly" ? `Q${titleHelper} share` : `${titleHelper} share`}</p>
          <p className={"investment_share"}>{total ? total.toFixed(2) + "%" : "-"}</p>
        </div>
      </div>
    </div>
  );
};
