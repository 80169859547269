import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import { connect, RootStateOrAny } from "react-redux";
import WebFont from "webfontloader";
import { BIG_NUMBER_ABBREVIATION, CYAN, DARK_CYAN, DARK_GRAY, GRAY } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { legendConfigurationOCE, oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  theme: ThemeInt;
  chartName: string;
  isLoading: boolean;
  seriesList: Array<{ name: string; field: string; color: string }>;
  isSingleModel: boolean;
  currency: string;
  isPercentage?: boolean;
}

interface ISeries {
  name: string;
  seriesColor: string;
}

class MsrpOverTimeChart extends Component<Props> {
  chartId = this.props.chartName;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, theme, seriesList, isSingleModel, currency, isPercentage } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;

    // Creates Legend
    this.chart.legend = legendConfigurationOCE(theme);
    this.chart.legend.reverseOrder = false;
    this.chart.legend.position = "bottom";

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 20;
    dateAxis.dateFormats.setKey("month", "MMM yy");
    dateAxis.periodChangeDateFormats.setKey("month", "MMM yy");

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.minGridDistance = 50;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return `${!isPercentage ? currency : ""}` + text + `${isPercentage ? "%" : ""}`;
    });
    if (!isPercentage) {
      valueAxis.renderer.numberFormatter.numberFormat = "#.0a";
      valueAxis.renderer.numberFormatter.bigNumberPrefixes = BIG_NUMBER_ABBREVIATION;
    }

    seriesList?.map(({ name, field }, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.data = data;
      series.xAxis = dateAxis;
      series.dataFields.dateX = "date";
      const bullet = lineSeriesConfiguration(series, {
        valueY: field,
        name: name,
      });
      bullet.tooltipText = `[bold; font-size: var(--regular_font_size);] {dateX.formatDate('dd MMM YYYY')} [/ font-size: var(--regular_font_size);]: {valueY.formatNumber('###,###.##')}${
        this.props.isPercentage ? "%" : ""
      }`;
      if (idx == 0) {
        series.strokeDasharray = isSingleModel ? "2.2" : "0,0";
        series.fill = color(isSingleModel ? DARK_CYAN : CYAN);
        series.stroke = color(isSingleModel ? DARK_CYAN : CYAN);
      } else {
        series.fill = color(isSingleModel ? DARK_GRAY : GRAY);
        series.stroke = color(isSingleModel ? DARK_GRAY : GRAY);
      }
    });
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

function mapStateToProps(state: RootStateOrAny) {
  const currency = state.msrp.currency;
  return {
    currency,
  };
}
export default connect(mapStateToProps)(MsrpOverTimeChart);
