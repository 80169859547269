import { REGION_CODES } from "../../../constants";
import { TOP_CATEGORIES } from "../../../constants/vocConstants";

const customSortTypes = () => {
  const reSplitAlphaNumeric = /([0-9]+)/gm;

  function compareIgnoreCase(a: any, b: any) {
    let row1 = a;
    let row2 = b;

    if (REGION_CODES[a]) {
      row1 = REGION_CODES[a];
    }
    if (REGION_CODES[b]) {
      row2 = REGION_CODES[b];
    }

    const r1 = String(row1).toLowerCase();
    const r2 = String(row2).toLowerCase();

    if (r1 < r2) {
      return -1;
    } else if (r1 > r2) {
      return 1;
    }
    return 0;
  }

  function compareBasic(a: any, b: any, desc: any) {
    if (a == "n/a") {
      return desc ? -1 : 1;
    }

    if (b == "n/a") {
      return desc ? 1 : -1;
    }

    return a === b ? 0 : a > b ? 1 : -1;
  }

  function getRowValueByColumnID(row: any, columnId: any) {
    return row.values[columnId];
  }
  function getNestedRowValueByColumnID(row: any, columnId: any) {
    return row.values[columnId]["rate"];
  }

  function toString(a: any) {
    if (typeof a === "number") {
      if (isNaN(a) || a === Infinity || a === -Infinity) {
        return "";
      }
      return String(a);
    }
    if (typeof a === "string") {
      return a;
    }
    return "";
  }

  function compareNumbersActivationRatio(row1: any, row2: any) {
    if (row1 > 999) {
      return -1;
    }
    if (row2 > 999) {
      return 1;
    }
    if (row1 < row2) {
      return -1;
    }
    if (row1 > row2) {
      return 1;
    }
    return 0;
  }

  return {
    kpiNestedValuesSort: (row1: any, row2: any, columnName: any, desc: any) => {
      return compareBasic(row1.values[columnName]["description"], row2.values[columnName]["description"], desc);
    },
    alphanumericIgnoreCase: (row1: any, row2: any, columnName: any) => {
      return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
    },
    rtpAlphanumericIgnoreCase: (row1: any, row2: any, columnName: any, desc: any) => {
      const value1 = row1.values[columnName].toLowerCase();
      const value2 = row2.values[columnName].toLowerCase();
      if (TOP_CATEGORIES.includes(value1) || TOP_CATEGORIES.includes(value2)) {
        return desc ? -1 : 1;
      }
      return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
    },
    compareNumbersActivationRatio: (row1: any, row2: any, columnName: any) => {
      return compareNumbersActivationRatio(row1.values[columnName], row2.values[columnName]);
    },

    alphanumericFalsyLast(rowA: any, rowB: any, columnId: any, desc: any) {
      let a = getRowValueByColumnID(rowA, columnId);
      let b = getRowValueByColumnID(rowB, columnId);
      // Force to strings (or "" for unsupported types)
      a = toString(a);
      b = toString(b);

      // Split on number groups, but keep the delimiter
      // Then remove falsey split values
      a = a.split().filter(Boolean);
      b = b.split().filter(Boolean);

      if (isNaN(a)) {
        return desc ? -1 : 1;
      }

      if (isNaN(b)) {
        return desc ? 1 : -1;
      }
      if (a?.length === 0) {
        return desc ? -1 : 1;
      }

      if (b?.length === 0) {
        return desc ? 1 : -1;
      }
      // While
      while (a?.length && b?.length) {
        const aa = a.shift();
        const bb = b.shift();

        const an = parseFloat(aa);
        const bn = parseFloat(bb);
        // Both are numbers
        if (an > bn) {
          return 1;
        }
        if (bn > an) {
          return -1;
        }
      }
      return a?.length - b?.length;
    },
    /**
     * Metric Type sort is for sort by region in both CPO AND OCE
     *
     * @param rowA
     * @param rowB
     * @param columnId
     * @param desc
     * @returns sorted types
     */
    metricTypesSort(rowA: any, rowB: any, columnId: any, desc: any) {
      const key = columnId == "period_comparison" ? "pop" : "yoy";

      const rowApercentage = ((rowA.original.visits - rowA.original[key]) / rowA.original[key]) * 100;
      const rowBpercentage = ((rowB.original.visits - rowB.original[key]) / rowB.original[key]) * 100;

      if (rowApercentage > rowBpercentage) {
        return 1;
      }
      if (rowApercentage < rowBpercentage) {
        return -1;
      }
      return 0;
    },
    /**
     * This is specifically for CPO Digital leads on the sort by types
     *
     * @param rowA
     * @param rowB
     * @param columnId
     * @param desc
     * @returns sorted params
     */
    metricTypesSortCPO(rowA: any, rowB: any, columnId: any, desc: any) {
      const key = columnId == "period_comparison" ? "pop_data_difference" : "yoy_data_difference";

      const rowApercentage = rowA.original[key].visits;
      const rowBpercentage = rowB.original[key].visits;

      if (rowApercentage > rowBpercentage) {
        return 1;
      }
      if (rowApercentage < rowBpercentage) {
        return -1;
      }
      return 0;
    },
    activationRateSort(rowA: any, rowB: any, columnId: any) {
      let a = getNestedRowValueByColumnID(rowA, columnId);
      let b = getNestedRowValueByColumnID(rowB, columnId);
      // Force to strings (or "" for unsupported types)
      a = toString(a);
      b = toString(b);

      // Split on number groups, but keep the delimiter
      // Then remove falsey split values
      a = a.split(reSplitAlphaNumeric).filter(Boolean);
      b = b.split(reSplitAlphaNumeric).filter(Boolean);

      // While
      while (a.length && b.length) {
        const aa = a.shift();
        const bb = b.shift();

        const an = parseInt(aa, 10);
        const bn = parseInt(bb, 10);

        const combo = [an, bn].sort();

        // Both are string
        if (isNaN(combo[0])) {
          if (aa > bb) {
            return 1;
          }
          if (bb > aa) {
            return -1;
          }
          continue;
        }

        // One is a string, one is a number
        if (isNaN(combo[1])) {
          return isNaN(an) ? -1 : 1;
        }

        // Both are numbers
        if (an > bn) {
          return 1;
        }
        if (bn > an) {
          return -1;
        }
      }

      return a.length - b.length;
    },
    alphanumericCustom(rowA: any, rowB: any, columnId: any) {
      let a = getRowValueByColumnID(rowA, columnId);
      let b = getRowValueByColumnID(rowB, columnId);
      // Force to strings (or "" for unsupported types)
      a = toString(a);
      b = toString(b);

      // Split on number groups, but keep the delimiter
      // Then remove falsey split values
      a = a.split(reSplitAlphaNumeric).filter(Boolean);
      b = b.split(reSplitAlphaNumeric).filter(Boolean);

      // While
      while (a?.length && b?.length) {
        const aa = a.shift();
        const bb = b.shift();

        const an = parseInt(aa, 10);
        const bn = parseInt(bb, 10);

        const combo = [an, bn].sort();

        // Both are string
        if (isNaN(combo[0])) {
          if (aa > bb) {
            return 1;
          }
          if (bb > aa) {
            return -1;
          }
          continue;
        }

        // One is a string, one is a number
        if (isNaN(combo[1])) {
          return isNaN(an) ? -1 : 1;
        }

        // Both are numbers
        if (an > bn) {
          return 1;
        }
        if (bn > an) {
          return -1;
        }
      }

      return a?.length - b?.length;
    },

    basicCustom(rowA: any, rowB: any, columnId: any, desc: any) {
      const a = getRowValueByColumnID(rowA, columnId);
      const b = getRowValueByColumnID(rowB, columnId);

      return compareBasic(a, b, desc);
    },

    expandableRowCustom(rowA: any, rowB: any, columnId: any, desc: any) {
      if ((rowA.original.market == "Summary" && desc) || (rowB.original.market == "Summary" && !desc)) {
        return 1;
      }

      if ((rowB.original.market == "Summary" && desc) || (rowA.original.market == "Summary" && !desc)) {
        return -1;
      }
      if (rowA.depth == 0 && rowB.depth == 0) {
        const a = getRowValueByColumnID(rowA, columnId);
        const b = getRowValueByColumnID(rowB, columnId);

        return compareBasic(a, b, desc);
      }
      return -1;
    },
  };
};

export default customSortTypes;
