import {
  SET_DIGITAL_CLOSE_RATE,
  SET_DIGITAL_LEADS_BREAKDOWN,
  SET_DIGITAL_SALES_BREAKDOWN,
  SET_DIGITAL_SALES_PERCENTAGE,
  SET_KEY_INDICATORS_PERCENTAGE,
  SET_LEADS_SALES_TREND,
  SET_PF_ANNOTATIONS,
  SET_PRIVATE_SALES_BREAKDOWN,
  SET_PURCHASE_FUNNEL_BOTTOM_RANKED_MODELS,
  SET_PURCHASE_FUNNEL_MODEL_PERFORMANCE_VARIANCE,
  SET_PURCHASE_FUNNEL_TOP_RANKED_MODELS,
  SET_PURCHASE_FUNNEL_VOLUME_BY_MODEL,
} from "../actions/actionTypes";

const initialState = {
  key_indicators: [],
  digital_sales_percentage: [],
  private_sales_breakdown: [],
  digital_close_rate: [],
  leads_sales_trend: [],
  digital_leads_breakdown: [],
  digital_sales_breakdown: [],
  annotations: [],
  volume_by_model: {},
  model_performance_variance: {},
  top_ranked_models: {},
  bottom_ranked_models: {},
};

const purchaseFunnelReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_KEY_INDICATORS_PERCENTAGE:
      return { ...state, key_indicators: action.payload };

    case SET_DIGITAL_SALES_PERCENTAGE:
      return { ...state, digital_sales_percentage: action.payload };

    case SET_PRIVATE_SALES_BREAKDOWN:
      return { ...state, private_sales_breakdown: action.payload };

    case SET_DIGITAL_CLOSE_RATE:
      return { ...state, digital_close_rate: action.payload };

    case SET_LEADS_SALES_TREND:
      return { ...state, leads_sales_trend: action.payload };

    case SET_DIGITAL_LEADS_BREAKDOWN:
      return { ...state, digital_leads_breakdown: action.payload };

    case SET_DIGITAL_SALES_BREAKDOWN:
      return { ...state, digital_sales_breakdown: action.payload };

    case SET_PURCHASE_FUNNEL_VOLUME_BY_MODEL:
      return { ...state, volume_by_model: action.payload };

    case SET_PURCHASE_FUNNEL_MODEL_PERFORMANCE_VARIANCE:
      return { ...state, model_performance_variance: action.payload };

    case SET_PURCHASE_FUNNEL_TOP_RANKED_MODELS:
      return { ...state, top_ranked_models: action.payload };

    case SET_PURCHASE_FUNNEL_BOTTOM_RANKED_MODELS:
      return { ...state, bottom_ranked_models: action.payload };

    case SET_PF_ANNOTATIONS:
      return { ...state, annotations: action.payload };

    default:
      return state;
  }
};

export default purchaseFunnelReducer;
