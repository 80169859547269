import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";
import { getGranularityFromDate } from "../../../../utils/utilityFunctions";

interface Props {
  privateSales: number;
  privateSalesYoY: number;
  privateSalesPoP: number;
  privateSalesValue: number;
  privateSalesValueYoY: number;
  privateSalesValuePoP: number;
  isLoading: boolean;
}

const PoPLabelMapping: Record<string, string> = {
  monthly: "MoM",
  quarterly: "QoQ",
};

const PrivateSalesTile = (props: Props) => {
  const { privateSales, privateSalesYoY, privateSalesPoP, privateSalesValue, privateSalesValueYoY, privateSalesValuePoP, isLoading } =
    props;

  const dateRangeParam = useSelector((state: RootStateOrAny) => state.parameters.date_range);

  const PoPLabel = useMemo(() => {
    const dateGranularity = getGranularityFromDate(dateRangeParam, "monthly");
    return PoPLabelMapping[dateGranularity];
  }, [dateRangeParam]);

  return (
    <div className="private-sales-tile tile">
      <div>
        <div className="title-container">
          <div>Private Sales</div>
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={"Digitally influenced sales + Others (dealer walk ins + other)	"}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>

        <div className="value">{privateSales?.toLocaleString()}</div>

        <div className="variance">
          <div className="yoy-variance">
            <div>
              <Ratings value={privateSalesYoY} isPercentageValue={false} testValue={false} />
            </div>
            {privateSalesYoY ? `${privateSalesYoY}% YoY` : "n/a"}
          </div>

          {privateSalesPoP && (
            <div className="pop-variance">
              <div>
                <Ratings value={privateSalesPoP} isPercentageValue={false} testValue={false} />
              </div>
              {privateSalesPoP ? `${privateSalesPoP}% ${PoPLabel}` : "n/a"}
            </div>
          )}
        </div>
      </div>

      <div>
        <div className="title-container">
          <div>Private Sales Value</div>
        </div>

        <div className="value">{privateSalesValue ? `$ ${privateSalesValue?.toLocaleString()}` : "n/a"}</div>

        <div className="variance">
          <div className="yoy-variance">
            <div>
              <Ratings value={privateSalesValueYoY} isPercentageValue={false} testValue={false} />
            </div>
            {privateSalesValueYoY ? `${privateSalesValueYoY}% YoY` : "n/a"}
          </div>
          {privateSalesValuePoP && (
            <div className="pop-variance">
              <div>
                <Ratings value={privateSalesValuePoP} isPercentageValue={false} testValue={false} />
              </div>
              {privateSalesValuePoP ? `${privateSalesValuePoP}% ${PoPLabel}` : "n/a"}
            </div>
          )}
        </div>
      </div>
      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};

export default PrivateSalesTile;
