import { isNull } from "lodash";
import { PMO_CHART_COLORS } from "../../constants";
import { toLocaleFloat } from "../../utils/utilityFunctions";

export type mainKeyTypes = "fmi_category" | "tch_pnt_nm";

export const getSortedYrScenarios = (allYearScenarios: string[], selectedYearScenarios: string[]) => {
  if (allYearScenarios?.length && selectedYearScenarios?.length) {
    return selectedYearScenarios?.sort(
      (a, b) => allYearScenarios?.indexOf(a?.replace(/FY/g, "FY ")) - allYearScenarios?.indexOf(b?.replace(/FY/g, "FY "))
    );
  }
  return [];
};

export const getAvailableYearScenarios = (fullYearData: { yr_scnr_rv: string }[], keepUnderScore?: boolean) => {
  if (fullYearData?.length) {
    return Array.from(
      new Set(fullYearData?.map((val: { yr_scnr_rv: string }) => (keepUnderScore ? val?.yr_scnr_rv : val?.yr_scnr_rv?.replace(/_/g, " "))))
    );
  }
  return [];
};

export const getTouchPointNames = (fullYearData: { tch_pnt_nm: string }[]) => {
  if (fullYearData?.length) {
    return Array.from(new Set(fullYearData?.map((val: { tch_pnt_nm: string }) => val?.tch_pnt_nm)));
  }
  return [];
};

export const getFmiCategories = (fullYearData: { fmi_category: string }[]) => {
  if (fullYearData?.length) {
    return Array.from(new Set(fullYearData?.map((val: { fmi_category: string }) => val?.fmi_category)));
  }
  return [];
};

export const getTableData = (data: any, categories: string[], mainKey: mainKeyTypes, quarters?: number[]) => {
  return categories
    ?.map((category: string) => {
      const result: any = { model: category };
      quarters?.map((quarter: number) => {
        data?.map(
          (val: {
            quarter: number | null;
            yr_scnr_rv_total_variance?: any;
            yr_scnr_rv_total: number;
            variance: number;
            yr_scnr_rv: string;
            fmi_category?: string;
            tch_pnt_nm?: string;
            amount: number;
          }) => {
            if (category === val[mainKey] && Number(val.quarter) == quarter) {
              result[`share_${Number(val.quarter)}_${val.yr_scnr_rv.replace(/_/g, " ")}`] = isNull(val.amount)
                ? 0
                : toLocaleFloat(val.amount, 1);
              result[`variance_${Number(val.quarter)}_${val.yr_scnr_rv.replace(/_/g, " ")}`] = isNull(val?.variance)
                ? 0
                : toLocaleFloat(val.variance, 1);
              result[`${val.yr_scnr_rv.replace(/_/g, " ")}_${Number(val.quarter)}_total`] = isNull(val.yr_scnr_rv_total)
                ? 0
                : toLocaleFloat(val.yr_scnr_rv_total, 1);
              result[`${val.yr_scnr_rv.replace(/_/g, " ")}_${Number(val.quarter)}_total_variance`] = isNull(val?.yr_scnr_rv_total_variance)
                ? 0
                : toLocaleFloat(val?.yr_scnr_rv_total_variance, 1);
            }
          }
        );
      });

      return result;
    })
    ?.sort((a: { model: string }, b: { model: string }) => b.model.localeCompare(a.model));
};

export const getTotals = (data: any, yrScenarios: string[], quarters: number[]) => {
  if (yrScenarios.length) {
    const result: any = { model: "Total" };
    quarters?.map((quarter: number) => {
      result[`share_${quarter}_${yrScenarios[0].replace(/_/g, " ")}`] = data[0][`${yrScenarios[0].replace(/_/g, " ")}_${quarter}_total`];
      result[`share_${quarter}_${yrScenarios[1]?.replace(/_/g, " ")}`] = data[0][`${yrScenarios[1]?.replace(/_/g, " ")}_${quarter}_total`];
      result[`variance_${quarter}_${yrScenarios[0].replace(/_/g, " ")}`] =
        data[0][`${yrScenarios[0].replace(/_/g, " ")}_${quarter}_total_variance`];
    });
    return result;
  }
  return {};
};

export const getChartData = (data: any, allYrScenario: string[]) => {
  const visited: string[] = [];
  const chartData = data
    ?.map((val: { quarter: string | null; yr_scnr_rv: string; yr_scnr_rv_total: number }) => {
      if (!visited?.includes(val.yr_scnr_rv)) {
        const result: any = { yr_scnr_rv: val.yr_scnr_rv.replace(/_/g, " ").replace(/FY/g, "FY ") };
        result["quarter"] = Number(val?.quarter);
        result[`total_${val.yr_scnr_rv}`] = val.yr_scnr_rv_total;
        visited.push(val.yr_scnr_rv);
        return result;
      }
    })
    ?.filter((val: undefined) => val !== undefined);

  return chartData?.sort(
    (a: { yr_scnr_rv: string }, b: { yr_scnr_rv: string }) => allYrScenario?.indexOf(b.yr_scnr_rv) - allYrScenario?.indexOf(a.yr_scnr_rv)
  );
};

export const getMaxAmount = (data: any) => {
  let max = -Infinity;
  data?.map((val: { yr_scnr_rv: string; yr_scnr_rv_total: number }) => {
    if (val.yr_scnr_rv_total > max) max = val.yr_scnr_rv_total;
  });
  return max;
};

export const getMinAmount = (data: any) => {
  let min = Infinity;
  data?.map((val: { yr_scnr_rv: string; yr_scnr_rv_total: number }) => {
    if (val.yr_scnr_rv_total < min) min = val.yr_scnr_rv_total;
  });
  return min;
};

export const getSeriesList = (yrScenarios: string[], yr_scnr_rv: string[]) => {
  if (yrScenarios?.length && yr_scnr_rv?.length) {
    const sortedYrScenarios = yrScenarios?.sort(
      (a, b) =>
        yr_scnr_rv?.indexOf(a.replace(/FY/g, "FY ").replace(/_/, " ")) - yr_scnr_rv?.indexOf(b.replace(/FY/g, "FY ").replace(/_/, " "))
    );
    return sortedYrScenarios?.map((yrScenario: string, idx: number) => ({ name: yrScenario, seriesColor: PMO_CHART_COLORS[idx] }));
  }
  return [];
};
