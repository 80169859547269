import { addLicense, useTheme } from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { postDataV2 } from "../../api/utils";
import "../../assets/styles/main.scss";
import { __chart_license__ } from "../../constants";
import { BackgroundContext, DocumentationContext, PopUpMessagesContext } from "../../context";
import SelectDepartmentForm from "../Forms/SelectDepartmentForm";
import LoadingEllipsis from "../Loading/LoadingEllipsis";
import { DashboardMenu } from "../Menu";
import { ReleaseNotes } from "../Modals/messages";

interface Props {
  children: JSX.Element;
}

export const DashboardLayout = (props: Props): JSX.Element => {
  const { children } = props;
  const chartLicense: string = __chart_license__ || "";

  const [documentation, setDocumentation] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const { hideBackgroundImage } = useContext(BackgroundContext);

  const { setShowFlashMessage, showFlashMessage, flashMessage, releaseNote, setShowReleaseMessage, showReleaseMessage } =
    useContext(PopUpMessagesContext);

  // Sets chart settings
  useEffect(() => {
    addLicense(chartLicense);
  }, []);

  useTheme(am4themes_animated);

  const handleReleaseNotesClose = () => {
    setShowReleaseMessage(false);

    // Updates release notes table
    postDataV2(`/user-messages/mark-release-note-seen?id=${releaseNote?.id}`, {});
  };

  const handleFlashMessageClose = () => {
    localStorage.setItem("latestFlashMsgSeen", "true");
    setShowFlashMessage(false);
  };

  return (
    <div className={`pageWrapper ${hideBackgroundImage ? "hide_background" : ""}`} id={"mainDashboard"}>
      <div className="dashboard" id="oceDashboard">
        <Suspense fallback={<LoadingEllipsis isLoading={true} />}>
          <DashboardMenu />
          <DocumentationContext.Provider value={{ documentation, setDocumentation, isVisible, setIsVisible }}>
            {children}
            <ReleaseNotes
              isVisible={showReleaseMessage}
              onCloseClick={handleReleaseNotesClose}
              notes={releaseNote?.popup_note ? releaseNote?.popup_note : ""}
            />
          </DocumentationContext.Provider>
        </Suspense>
      </div>
      <SelectDepartmentForm />
    </div>
  );
};
