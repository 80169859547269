import { matchSorter } from "match-sorter";
import React, { useMemo, useState } from "react";
import { KPIDefinitionsData } from "../../constants";
import { useDropdownVisible } from "../../hooks";
import { DefaultTableWithColumnFilter } from "./subs";

const DataDictionaryTable = (): JSX.Element => {
  const data = useMemo(() => KPIDefinitionsData, []);
  const [filter, setFilter] = useState<string>("");
  const { wrapperRef, isVisible, setIsVisible } = useDropdownVisible(false);
  const columns = useMemo(
    () => [
      {
        id: "element_name",
        Header: "Element name",
        accessor: "element_name",
        defaultCanSort: true,
        defaultSortDesc: true,
        minWidth: 5,
        width: 5,
        maxWidth: 5,
      },
      {
        id: "definition",
        Header: "Definition",
        accessor: "definition",
        disableSortBy: true,
      },
      {
        id: "customer_job",
        Header: "Customer job",
        accessor: "customer_job",
        disableSortBy: true,
      },
    ],
    []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setFilter(value);
  };

  const filters = [{ threshold: matchSorter.rankings.WORD_STARTS_WITH, key: "values.element_name" }, "values.element_name"];

  return (
    <>
      <h3>Data dictionary</h3>
      <div className="input_field" id="" ref={wrapperRef}>
        <div className="dropdown" onClick={() => setIsVisible(!isVisible)}>
          <input value={filter} onChange={handleInputChange} placeholder="Search elements" />
          <span className="arrow_down"></span>
        </div>
        {isVisible && (
          <ul className="dropdown-options" id="businessFunction_options">
            {data
              ?.sort((a, b) => a.element_name.localeCompare(b.element_name))
              .map((option: any, index: number) => (
                <li
                  key={index}
                  onClick={() => {
                    setFilter(option.element_name);
                    setIsVisible(false);
                  }}
                >
                  {option.element_name}
                </li>
              ))}
          </ul>
        )}
      </div>
      <DefaultTableWithColumnFilter data={data} columns={columns} filters={filters} filter={filter} hasColumnFilters={false} />
    </>
  );
};

export default DataDictionaryTable;
