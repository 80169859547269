import React, { useCallback, useContext, useEffect, useState } from "react";
import DmeoMultipleBarLineTrendChart from "../../../components/Charts/Dmeo/DmeoMultipleBarLineChart";
import { ThemeContext } from "../../../context";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getDmeoKpiPerformance } from "../../../api/dmeoApi";
import { useQuery } from "@tanstack/react-query";
import { ErrorBoundary } from "@sentry/react";
import { ErrorMsg } from "../../../components/AppMessages";
import { closeFilterOptions } from "../../../components/Filters/subs/helpers";
import { InAppMultiSelectFilter } from "../../../components/Filters/common/InAppMultiselectFilter";

const KPI_FILTER_OPTIONS = ["Investment", "KBAs", "Leads", "Qualified Visits", "Visits"];

const KpisPerformanceTile = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const [kpi, setKpi] = useState(KPI_FILTER_OPTIONS.join(","));
  const [kpiFilterValue, setKpiFilterValue] = useState("All");

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["kpisPerformanceData", kpi],
    queryFn: () =>
      getDmeoKpiPerformance(
        kpi
          .split(",")
          .map((item) => item.toLocaleLowerCase().replaceAll(" ", "_"))
          .join("&metric=")
      ),
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search, kpi]);

  useEffect(() => {
    if (kpi) {
      const selectedKpis =
        KPI_FILTER_OPTIONS?.length === kpi.split(",")?.length
          ? "All"
          : kpi.includes(",")
          ? `${kpi.split(",")?.length} revenues selected`
          : kpi;
      setKpiFilterValue(selectedKpis);
    }
  }, [kpi]);

  const handleModelMultiselectSubmit = useCallback(() => {
    const parameterName = "kpi";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = selectedValues?.filter((value) => value != "All")?.join(",");
    closeFilterOptions();
    setKpi(filterValue);
  }, []);

  return (
    <div className="tile">
      <div className="tile-header">
        <div className="title">KPIs Performance</div>
        <div className="toggle_container">
          <ErrorBoundary fallback={<ErrorMsg />}>
            <InAppMultiSelectFilter
              filterLabel="KPIs"
              optionsList={KPI_FILTER_OPTIONS}
              filterValue={kpiFilterValue}
              parameterName={`kpi`}
              parameterValue={kpi}
              handleFilterSubmission={handleModelMultiselectSubmit}
            />
          </ErrorBoundary>
        </div>
      </div>
      <div className="tile-content">
        <DmeoMultipleBarLineTrendChart
          chartId="kpisPerformanceChart"
          theme={theme}
          data={data?.data ? data.data : []}
          seriesList={data?.series ? data.series : []}
          isLoading={isFetching}
        />
      </div>
    </div>
  );
});

export default KpisPerformanceTile;
