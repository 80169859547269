import React, { useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { MetricBreadcrumbPill } from ".";
import bookdark from "../../../assets/images/PageContent/book-light.png";
import booklight from "../../../assets/images/PageContent/book.png";
import { DocumentationContext, ThemeContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { ThemeInt } from "../../Charts/helpers/types";

interface Props {
  showPageTitleDescription?: boolean;
  flag?: string;
  backButton?: JSX.Element;
  tooltipText?: string;
  dataDocumentation?: string;
}

const PageTitle = (props: Props) => {
  const pageName = useSelector((state: RootStateOrAny) => state.page.pageName);
  const pageTitleDescription = useSelector((state: RootStateOrAny) => state.page.pageTitleDescription);
  const { showPageTitleDescription, flag, backButton, tooltipText, dataDocumentation } = props;
  const { isVisible, setIsVisible, documentation, setDocumentation } = useContext(DocumentationContext);
  const pathExcludingDataLink: Array<string> = [];
  const themeContext: {
    theme: ThemeInt;
  } = useContext(ThemeContext);

  return (
    <>
      {dataDocumentation && !pathExcludingDataLink?.includes(location.pathname) ? (
        <>
          {" "}
          <div
            id="data-dictionary-button"
            className="data_dictionary_button"
            style={{
              position: "fixed",
              display: "flex",
              zIndex: "999",
              width: "50px",
              height: "50px",
              bottom: "10px",
              borderRadius: "50%",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              eventTracking(MixpanelEvents.data_info_click, { documentation: dataDocumentation });
              setDocumentation(dataDocumentation);
              setIsVisible(true);
            }}
          >
            <img
              style={{
                width: "40px",
                height: "40px",
              }}
              src={themeContext.theme == "light" ? bookdark : booklight}
              alt="book"
            />
          </div>
        </>
      ) : null}

      <ul className={"dashboard_options"}>
        <li className={"active"} id={"pageTitle"}>
          {flag && <span className={`flagIcon ${flag}`}></span>}
          <pre className="pageTitleText">
            {tooltipText ? (
              <div style={{ display: "flex" }}>
                {pageName}
                <Tooltip
                  position="top-end"
                  className={"normal_tippy"}
                  trigger="mouseenter"
                  animation="none"
                  interactive={true}
                  distance={50}
                  delay={1}
                  hideDelay={1}
                  duration={1}
                  size={"small"}
                  title={tooltipText}
                >
                  <span className="info_tooltip" />
                </Tooltip>
              </div>
            ) : (
              pageName
            )}
          </pre>{" "}
          <MetricBreadcrumbPill />
          {showPageTitleDescription && <span className="page_title_description">{pageTitleDescription}</span>}
        </li>
        {backButton ? backButton : <></>}
      </ul>
    </>
  );
};

export default PageTitle;
