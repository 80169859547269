import { ColumnSeries, DateAxis, LabelBullet, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, BLUE, DARK_PURPLE, GRADE_MIX_COLORS, LIGHT_PURPLE, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { dateAxisLabelFormatter_QuarterlyFY, formatDateAxisLabelToQuarterlyFY } from "../helpers/pmoConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  data: any;
  theme: ThemeInt;
  seriesList: Array<string>;
  isLoading: boolean;
  metric: string;
  granularity: string;
  currency: string;
}

class SpendVsTrafficChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chartId = this.props.chartId;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { currency, granularity, data, theme, seriesList, metric } = this.props;

    // Global chart configuration
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    this.chart.data = data;
    // Creates Legend

    this.chart.colors.list = GRADE_MIX_COLORS.map((chartColor) => color(chartColor));

    // Create data chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.cellStartLocation = 0.2;
    dateAxis.renderer.cellEndLocation = 0.8;

    // 2nd date axis for comparison data
    const dateAxis2 = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis2, theme);
    dateAxis2.renderer.labels.template.disabled = true;
    dateAxis2.renderer.cellStartLocation = 0.5;
    dateAxis2.renderer.cellEndLocation = 0.5;

    const dateAxis3 = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis3, theme);
    dateAxis3.renderer.labels.template.disabled = true;
    dateAxis3.renderer.cellStartLocation = 0.5;
    dateAxis3.renderer.cellEndLocation = 0.5;

    dateAxis.renderer.minGridDistance = 30;
    dateAxis2.renderer.minGridDistance = 30;
    dateAxis3.renderer.minGridDistance = 30;

    granularity?.includes("Quarter") && dateAxis.renderer.labels.template.adapter.add("text", dateAxisLabelFormatter_QuarterlyFY);

    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    valueAxis.numberFormatter.numberFormat = "#a";

    const valueAxis1 = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis1, theme);
    valueAxis1.min = 0;
    valueAxis1.numberFormatter.numberFormat = "#a";
    valueAxis1.renderer.opposite = true;
    valueAxis1.renderer.minGridDistance = 50;
    valueAxis.syncWithAxis = valueAxis1;

    dateAxis.renderer.labels.template.fontSize = "13px";
    valueAxis.renderer.labels.template.fontSize = "13px";
    valueAxis1.renderer.labels.template.fontSize = "13px";

    const units: string[] = [];
    const units1: string[] = [];
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      const unit = text?.replace(/[^a-z]/gi, "");
      if (unit) units.push(unit);
      return text;
    });
    valueAxis1.renderer.labels.template.adapter.add("text", function (text) {
      const unit = text?.replace(/[^a-z]/gi, "");
      if (unit) units1.push(unit);
      return text;
    });

    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      if (units?.includes("B")) {
        if (text?.includes("B")) return currency + text;
        if (text?.includes("M")) return currency + Number(parseInt(text) * 1000000) / 1000000000 + "B";
        if (text?.includes("K")) return currency + Number(parseInt(text) * 1000) / 1000000000 + "B";
        return currency + text;
      } else if (units?.includes("M")) {
        if (text?.includes("M")) return currency + text;
        if (text?.includes("K")) return currency + (Number(parseInt(text)) * 1000) / 1000000 + "M";
        return currency + text;
      }
      return currency + text;
    });

    valueAxis1.renderer.labels.template.adapter.add("text", function (text) {
      if (units1?.includes("B")) {
        if (text?.includes("B")) return text;
        if (text?.includes("M")) return Number(parseInt(text) * 1000000) / 1000000000 + "B";
        if (text?.includes("K")) return Number(parseInt(text) * 1000) / 1000000000 + "B";
        return text;
      } else if (units1?.includes("M")) {
        if (text?.includes("M")) return text;
        if (text?.includes("K")) return (Number(parseInt(text)) * 1000) / 1000000 + "M";
        return text;
      }
      return text;
    });

    valueAxis.renderer.minGridDistance = 50;

    //Amount Columns
    const series = this.chart.series.push(new ColumnSeries());
    series.xAxis = dateAxis;
    series.yAxis = valueAxis;
    series.name = "Media spend";
    series.dataFields.valueY = "amount";
    series.dataFields.dateX = "date";
    series.fill = color(BLUE);
    series.stroke = color(BLUE);
    series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
    if (!this.props.chartId?.includes("model")) {
      const labelBullet = series.bullets.push(new LabelBullet());
      labelBullet.label.text = `{valueY.formatNumber("#.0a")}`;
      labelBullet.label.fill = color(this.props.theme === "light" ? BLACK : WHITE);
      labelBullet.label.truncate = false;
      labelBullet.label.adapter.add("verticalCenter", () => "bottom");
      labelBullet.label.fontSize = "13px";
    }

    //Line series
    seriesList?.map((seriesItem: string) => {
      const series = this.chart.series.push(new LineSeries());
      const bullet = lineSeriesConfiguration(series, {
        valueY: seriesItem,
        name: seriesItem?.includes("yoy")
          ? `${metric == "leads" ? "Lead" : metric == "kbas" ? "KBA" : "Website"} visits (previous year)`
          : `${metric == "leads" ? "Lead" : metric == "kbas" ? "KBA" : "Website"} visits`,
      });
      series.dataFields.dateX = seriesItem?.includes("yoy") ? "date_yoy" : "date";
      series.xAxis = seriesItem?.includes("yoy") ? dateAxis2 : dateAxis3;
      series.fill = color(seriesItem?.includes("yoy") ? LIGHT_PURPLE : DARK_PURPLE);
      series.stroke = color(seriesItem?.includes("yoy") ? LIGHT_PURPLE : DARK_PURPLE);
      series.yAxis = valueAxis1;
      series.tensionX = 0.8;
      series.tensionY = 0.8;

      bullet.adapter.add("tooltipText", (text, target) => {
        const data = target.dataItem?.dataContext;
        if (granularity?.includes("Quarter")) {
          //@ts-ignore
          const dateVal = formatDateAxisLabelToQuarterlyFY(seriesItem?.includes("yoy") ? data?.date_yoy : data?.date);
          return (text = `[bold; font-size: var(--regular_font_size);] ${dateVal}[/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###.")}`);
        } else {
          return (text = `[bold; font-size: var(--regular_font_size);]{name}\n{dateX.formatDate('MMM YYYY')} [/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###.")}`);
        }
      });
    });

    //@ts-ignore
    series.columns.template.adapter.add("tooltipText", (text: string, target: { dataItem: { dataContext: any } }) => {
      const data = target.dataItem?.dataContext;
      if (granularity?.includes("Quarter")) {
        //@ts-ignore
        const dateVal = formatDateAxisLabelToQuarterlyFY(data?.date);
        return (text = `[bold; font-size: var(--regular_font_size);] ${dateVal}[/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###.")}`);
      } else {
        return (text = `[bold; font-size: var(--regular_font_size);]{name}\n{dateX.formatDate('MMM YYYY')} [/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###.")}`);
      }
    });
  }
  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default SpendVsTrafficChart;
