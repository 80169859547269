import React from "react";
import { ProgressRow } from ".";
import { popularState } from "../../../../../constants/interface/ariya";
import { ExperienceCard } from "../Card";
import styles from "./progressTable.module.scss";

interface Props {
  id: string;
  title: string;
  data: popularState;
  resetStyles?: boolean;
}

export const ProgressTable = (props: Props) => {
  const { data, id, title, resetStyles = true } = props;

  return (
    <ExperienceCard
      id={id}
      title={title}
      className={styles.momentsCardReset}
      contentClassName={resetStyles ? styles.momentsContentReset : ""}
      data-test-id={id}
    >
      <>
        {data.records.map((record) => (
          <ProgressRow key={record.name} name={record.name} value={record.percentage} />
        ))}
      </>
    </ExperienceCard>
  );
};
