import React, { useEffect, useMemo, useState } from "react";
import { getKpiUpdateData } from "../../api";
import { NoDataMsg } from "../AppMessages";
import LoadingEllipsis from "../Loading/LoadingEllipsis";
import { ColumnFilter, DefaultTableWithColumnFilter } from "./subs";

const DataUpdateLogTable = () => {
  const [kpiUpdateData, setKpiUpdateData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getKpiUpdateData().then((data) => {
      setKpiUpdateData(data?.data);
      setLoading(false);
    });
  }, []);

  const filterElementColumn = ({ column }: { column: any }) => {
    return <ColumnFilter column={column} data={kpiUpdateData} value={"element_name"} showDropdown={true} />;
  };

  const filterUpdateDateColumn = ({ column }: { column: any }) => {
    return <ColumnFilter column={column} data={kpiUpdateData} value={"update_date"} showDropdown={true} />;
  };

  const filterUpdateTextColumn = ({ column }: { column: any }) => {
    return <ColumnFilter column={column} data={kpiUpdateData} value={"update_text"} showDropdown={true} />;
  };

  const filterMarketImpactedColumn = ({ column }: { column: any }) => {
    return <ColumnFilter column={column} data={kpiUpdateData} value={"update_text"} showDropdown={true} />;
  };

  const columns = useMemo(
    () => [
      {
        id: "element_name",
        Header: "Element name",
        accessor: "element_name",
        defaultCanSort: true,
        defaultCanFilter: true,
        sortDescFirst: true,
        Filter: filterElementColumn,
      },
      {
        id: "update_date",
        Header: "Update date",
        accessor: "update_date",
        defaultCanSort: true,
        defaultCanFilter: true,
        sortDescFirst: true,
        Filter: filterUpdateDateColumn,
      },
      {
        id: "update_text",
        Header: "Update",
        accessor: "update_text",
        defaultCanSort: true,
        defaultCanFilter: true,
        sortDescFirst: true,
        Filter: filterUpdateTextColumn,
      },
      {
        id: "market_impacted",
        Header: "Market impacted",
        accessor: "market_impacted",
        defaultCanSort: true,
        defaultCanFilter: true,
        sortDescFirst: true,
        Filter: filterMarketImpactedColumn,
      },
    ],
    [kpiUpdateData]
  );

  return (
    <>
      <h3>Data update log</h3>
      {kpiUpdateData?.length > 0 ? (
        <DefaultTableWithColumnFilter data={kpiUpdateData} columns={columns} hasColumnFilters={true} />
      ) : (
        <NoDataMsg />
      )}
      <LoadingEllipsis isLoading={loading} />
    </>
  );
};

export default DataUpdateLogTable;
