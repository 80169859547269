import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import "../../assets/styles/component/tooltip.scss";
import { ErrorMsg, NoDataMsg } from "../AppMessages";
import { ConversionWithRating, DefaultTable } from "./subs";
import { fetchLeadBreakdownData } from "../../actions";

interface ITableData {
  name: string;
  visits: number;
  pop: number;
  yoy: number;
}

const LeadBreakdownTable = (props: {
  showPop: boolean;
  popLabel: string;
  specificMetric: string;
  isCpoDashboard?: boolean;
  onClickSpecificMetricClick: (evt: React.MouseEvent<HTMLElement>) => void;
}) => {
  const { showPop, popLabel, isCpoDashboard, onClickSpecificMetricClick, specificMetric } = props;

  const dispatch = useDispatch();
  const tableData = useSelector((state: RootStateOrAny) =>
    isCpoDashboard ? state.cpo_digital_performance.cpo_lead_breakdown_table : state.digital_performance.trend_breakdown.leads
  );
  const isLoading = useSelector((state: RootStateOrAny) => state.loading.lead_trend_table);
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const leadLabels = useSelector((state: RootStateOrAny) =>
    isCpoDashboard ? state.cpo_digital_performance.cpo_lead_label_mapping : state.digital_performance.lead_label_mapping
  );

  useEffect(() => {
    !isCpoDashboard && dispatch(fetchLeadBreakdownData());
  }, [parameters, specificMetric]);

  const hiddenCols = showPop ? [] : ["period_comparison"];

  const renderLeadTypes = useCallback(
    (props1: { value: string }) => {
      const { value } = props1;
      const isActiveClass = specificMetric && specificMetric === value ? "active" : "";
      return (
        <>
          <span
            className={`${`specific_link ${isActiveClass}`}`}
            data-value={value}
            data-metric={"leads"}
            data-specified-value={specificMetric}
            onClick={onClickSpecificMetricClick}
          >
            {value}
          </span>
          {/**Check if lead type is Online pre-order and show tooltip */}
          {value === "Online pre-order" && (
            <Tooltip
              position="bottom"
              className="key_indicator_tooltip"
              html={
                <p>
                  Number of online pre-order completed during a visit. This number shows how many visits reach the online pre-order
                  confirmation page, it does not take into account cancelled pre-orders which is why it could differ from the actual number
                  of pre-orders recorded by the markets
                </p>
              }
              trigger="click"
              theme="dark"
              interactive={true}
              delay={0}
              hideDelay={0}
              duration={0}
            >
              <span
                className="info_tooltip hide_on_mobile"
                onClick={(evt: React.MouseEvent<HTMLElement>) => {
                  evt.stopPropagation();
                }}
              />
            </Tooltip>
          )}
        </>
      );
    },
    [specificMetric]
  );

  const renderVisitsType = useCallback(
    (props1: { value: string; cell: { row: { values: { lead_types: string } } } }) => {
      const {
        value,
        cell: {
          row: { values },
        },
      } = props1;

      const isActiveClass = specificMetric && specificMetric === values.lead_types ? "active" : "";

      return <span className={isActiveClass}>{value ? value?.toLocaleString() : "n/a"}</span>;
    },
    [specificMetric]
  );

  const renderVisitsPoPOCE = useCallback(
    (props: { cell: { row: { values: { lead_types: string }; original: { visits: number; pop: number } } } }) => {
      const {
        cell: {
          row: {
            original: { visits, pop },
            values,
          },
        },
      } = props;
      const isActiveClass = specificMetric && specificMetric === values.lead_types ? "active" : "";

      const currentValue = visits;
      const popValue = pop;
      return (
        <span className={isActiveClass}>
          <ConversionWithRating currentValue={currentValue} comparisonValue={popValue} />
        </span>
      );
    },
    [specificMetric]
  );

  const renderVisitsYoYOCE = useCallback(
    (props: { cell: { row: { values: { lead_types: string }; original: { visits: number; yoy: number } } } }) => {
      const {
        cell: {
          row: {
            original: { visits, yoy },
            values,
          },
        },
      } = props;
      const isActiveClass = specificMetric && specificMetric === values.lead_types ? "active" : "";

      const currentValue = visits;
      const yoyValue = yoy;
      return (
        <span className={isActiveClass}>
          <ConversionWithRating currentValue={currentValue} comparisonValue={yoyValue} />
        </span>
      );
    },
    [specificMetric]
  );

  const data = useMemo(() => {
    const result: Array<ITableData> = [];

    if (tableData?.Current?.data) {
      tableData.Current.data.map((item: { metric_type: string; metric_visits: number }) => {
        const obj = {} as ITableData;
        obj["name"] = leadLabels[item.metric_type];
        obj["visits"] = item.metric_visits;
        obj["pop"] = tableData?.PoP?.data?.find(
          (dataItem: { metric_type: string; metric_visits: number }) => dataItem.metric_type == item.metric_type
        )?.metric_visits;
        obj["yoy"] = tableData.YoY.data.find(
          (dataItem: { metric_type: string; metric_visits: number }) => dataItem.metric_type == item.metric_type
        )?.metric_visits;
        result.push(obj);
      });
    }

    return result;
  }, [tableData]);

  const columns = useMemo(
    () => [
      {
        id: "lead_types",
        Header: "Digital lead types",
        accessor: "name",
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "alphanumericIgnoreCase",
        Cell: renderLeadTypes,
      },
      {
        Header: "Visits",
        accessor: `visits`,
        id: "leads",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: renderVisitsType,
      },
      {
        Header: popLabel,
        accessor: "visitsPop",
        id: "period_comparison",
        sortType: "metricTypesSort",
        Cell: renderVisitsPoPOCE,
      },
      {
        Header: "YoY",
        accessor: "visitsYoY",
        id: "year_comparison",
        sortType: "metricTypesSort",
        Cell: renderVisitsYoYOCE,
      },
    ],
    [specificMetric, popLabel, parameters.market]
  );

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <>
        {data.length > 0 ? (
          <DefaultTable
            columns={columns}
            data={data ?? []}
            id={"lead_breakdown"}
            data-test-id={"lead_breakdown"}
            extraClass={specificMetric ? "specified_metric metricBreakdown" : "metricBreakdown"}
            // @ts-ignore
            renderRowSubComponent={function () {}}
            isChildComponent={false}
            initialSortColId={{ id: "leads", desc: true }}
            hiddenCols={hiddenCols}
          />
        ) : isLoading ? null : (
          <NoDataMsg />
        )}
      </>
    </ErrorBoundary>
  );
};

export default LeadBreakdownTable;
