import { Button, makeStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as Sentry from "@sentry/react";
import React, { Dispatch, SetStateAction } from "react";
import { Controller, useForm } from "react-hook-form";
import { postDataV2, updateDataV2 } from "../../../../../api/utils";
import { ErrorMsg } from "../../../../../components/AppMessages";
import { CYAN, __local__ } from "../../../../../constants";
import { Brand, Market, Region } from "../../../../../constants/interface/admin/common";

interface Props {
  open: boolean;
  toggleOpen: () => void;
  setUserGroups: Dispatch<SetStateAction<Array<any>>>;
  selectedUserGroup: any | undefined;
  brands: Brand[];
  regions: Region[];
  markets: Market[];
}

type FormData = {
  name: string;
  brands: Array<any>;
  regions: Array<any>;
  markets: Array<any>;
};

const UserGroupFormDialog = (props: Props) => {
  const { open, toggleOpen, setUserGroups, selectedUserGroup, brands, markets, regions } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: selectedUserGroup?.name,
      brands: selectedUserGroup?.brands ?? [],
      regions: selectedUserGroup?.regions ?? [],
      markets: selectedUserGroup?.markets ?? [],
    },
  });

  const classes = makeStyles({
    button: {
      backgroundColor: CYAN,
      color: "#FFF !important",
      borderRadius: "0px !important",
      border: "none !important",
      marginRight: "10px",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      "&:hover": {
        backgroundColor: "#5ed2ff !important",
        border: "none !important",
      },
    },
  })();

  const submitHandler = (values: FormData) => {
    if (selectedUserGroup?.id) {
      const endpoint = `/user_service/update-group${__local__ ? "/" + selectedUserGroup.id : "?id=" + selectedUserGroup.id}`;
      updateDataV2(endpoint, values).then((res) => {
        if (!res.error) {
          setUserGroups((prevState) =>
            prevState.map((userGroup) => {
              if (userGroup.id !== res.id) {
                return userGroup;
              }
              return res;
            })
          );
          toggleOpen();
        } else {
          console.log(res.error);
        }
      });
    } else {
      postDataV2("/user_service/create-group", values).then((res) => {
        if (!res.error) {
          setUserGroups((prevState) => [...prevState, res]);
          toggleOpen();
        } else {
          console.log(res.error);
        }
      });
    }
  };

  return (
    <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
      <Dialog onClose={toggleOpen} open={open} scroll="paper" fullWidth>
        <DialogTitle>Create new entry</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl fullWidth>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Name"
                      id="user-group-name"
                      type="string"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      error={Boolean(errors.name)}
                      helperText={Boolean(errors.name) && "This field is required"}
                      required
                      inputProps={{
                        "data-test-id": "user-group-name-input",
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>

            <Grid item md={12}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="brands"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      multiple
                      id="user-group-brand-select"
                      options={brands}
                      getOptionLabel={(option) => option.brand}
                      getOptionSelected={(option, value) => option.id == value.id}
                      value={value}
                      onChange={(_, data) => onChange(data)}
                      renderTags={(value: Brand[], getTagProps) =>
                        value.map((option: Brand, index: number) => <Chip key={index} label={option.brand} {...getTagProps({ index })} />)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Brand"
                          error={Boolean(errors.brands)}
                          helperText={Boolean(errors.brands) && "This field is required"}
                          required
                          InputProps={{ ...params.InputProps, ...{ "data-test-id": "user-group-brands-input" } }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item md={12}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="regions"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      multiple
                      id="user-group-region-select"
                      options={regions}
                      getOptionLabel={(option) => option.region}
                      getOptionSelected={(option, value) => option.id == value.id}
                      value={value}
                      onChange={(_, data) => onChange(data)}
                      renderTags={(value: Region[], getTagProps) =>
                        value.map((option: Region, index: number) => <Chip key={index} label={option.region} {...getTagProps({ index })} />)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Region"
                          error={Boolean(errors.regions)}
                          helperText={Boolean(errors.regions) && "This field is required"}
                          required
                          InputProps={{ ...params.InputProps, ...{ "data-test-id": "user-group-regions-input" } }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item md={12}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="markets"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      multiple
                      id="user-group-market-select"
                      options={markets}
                      getOptionLabel={(option) => option.market}
                      getOptionSelected={(option, value) => option.id == value.id}
                      value={value}
                      onChange={(_, data) => onChange(data)}
                      renderTags={(value: Market[], getTagProps) =>
                        value.map((option: Market, index: number) => <Chip key={index} label={option.market} {...getTagProps({ index })} />)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Markets"
                          error={Boolean(errors.markets)}
                          helperText={Boolean(errors.markets) && "This field is required"}
                          required
                          InputProps={{ ...params.InputProps, ...{ "data-test-id": "user-group-markets-input" } }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            className={classes.button}
            variant="outlined"
            type="submit"
            onClick={handleSubmit(submitHandler)}
            disableElevation
            data-test-id={`${selectedUserGroup?.id ? "btn-update-user-group" : "btn-create-user-group"}`}
          >
            {selectedUserGroup?.id ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </Sentry.ErrorBoundary>
  );
};

export default UserGroupFormDialog;
