import { DateAxis, LineSeries, ValueAxis, XYChart, XYCursor } from "@amcharts/amcharts4/charts";
import { create } from "@amcharts/amcharts4/core";
import { endOfMonth, endOfQuarter } from "date-fns";
import Cookies from "js-cookie";
import _, { isNull } from "lodash";
import React, { Component, Dispatch, SetStateAction } from "react";
import WebFont from "webfontloader";
import { CYAN, GREEN, ORANGE, PURPLE, RED } from "../../../constants";
import { Annotation } from "../../../pages/Admin/types";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import { convertToAbsolutePercentage } from "../../../utils/utilityFunctions";
import ShareAnnotationModal from "../../Annotations/Dialogs/ShareAnnotationModal";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { createAnnotationTooltips } from "../helpers/annotationTooltips";
import {
  configureMTMDateAxis,
  configureMTMLineSeries,
  configureMTMValueAxis,
  formatDateAxisLabelToMonthly,
  formatDateAxisLabelToQuarterly,
  mtmChartConfiguration,
  mtmLegendConfiguration,
} from "../helpers/mtmConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  chartName: string;
  isLoading: boolean;
  competitors: Array<string>;
  useCircleLegend: boolean;
  legendMaker: Record<string, { value: number; text: string }>;
  legendGran: string;
  isQuarterly: boolean;
  keyToCompare: Record<string, string>;
  theme: ThemeInt;
  toggleAnnotationsDialog: () => void;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
}

type SeriesPropsInt = {
  name: string;
  field: string;
  color: string;
  showDashedLine?: boolean;
};

interface State {
  shareModalOpen: boolean;
  annotations: Annotation[] | undefined;
}

const competitorColors = [CYAN, ORANGE, PURPLE, GREEN];

class MTMTrendLineChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { shareModalOpen: false, annotations: undefined };

    this.toogleShareModal = this.toogleShareModal.bind(this);
  }
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  toogleShareModal() {
    this.setState({
      shareModalOpen: !this.state.shareModalOpen,
    });
  }

  handleShareAnnotation(annotations: Annotation[]) {
    this.setState({
      annotations: annotations,
    });
    this.toogleShareModal();
  }

  initChart() {
    const {
      data,
      chartName,
      competitors,
      useCircleLegend,
      legendMaker,
      legendGran,
      isQuarterly,
      keyToCompare,
      theme,
      toggleAnnotationsDialog,
      setAnnotationStartDate,
      setAnnotationEndDate,
      setAnnotationDefaultMetric,
    } = this.props;

    this.chart = create(chartName, XYChart);
    mtmChartConfiguration(this.chart);
    this.chart.data = data;

    //Add legends
    this.chart.legend = mtmLegendConfiguration("{date}", theme);
    this.chart.legend.useDefaultMarker = useCircleLegend;
    this.chart.legend.labels.template.wrap = false;

    if (useCircleLegend) {
      const marker = this.chart.legend.markers.template.children.getIndex(0);
      if (marker) {
        // @ts-ignore
        marker.cornerRadius(12, 12, 12, 12);
        marker.strokeWidth = 1;
      }
    }

    //Default x axis to show current date
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureMTMDateAxis(dateAxis, theme, isQuarterly);

    //Y Axis that is used by default
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureMTMValueAxis(valueAxis, theme);

    const getCompetiterName = (competitor: string) => {
      const data_impression = legendGran == "quarterly" ? competitor + "_QoQ" : competitor + "_MoM";
      const myValue = legendMaker[data_impression] && legendMaker[data_impression].value;
      const myText = legendMaker[data_impression] && legendMaker[data_impression].text;
      const sign = myValue && String(myValue).includes("-") ? "-" : "+";
      return `${competitor} 
      ${myValue == null ? "" : sign + convertToAbsolutePercentage(myValue, 0)} ${myText == null ? "" : myText}`;
    };

    //Function to create the series using the arguments given
    const createSeries = ({ name, field, color, showDashedLine }: SeriesPropsInt) => {
      const userViewsList = Cookies.get("views");
      const series = this.chart.series.push(new LineSeries());
      const bullet = configureMTMLineSeries(series, {
        name,
        valueY: field,
        color,
      });
      series.strokeDasharray = showDashedLine ? "2,4" : "";

      //Setup tooltip
      series.adapter.add("tooltipText", (value, target) => {
        const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

        let text = `[bold]${
          isQuarterly ? formatDateAxisLabelToQuarterly(dateValue?.date) : formatDateAxisLabelToMonthly(dateValue?.date)
        }[/]\n`;

        this.chart.series.each((item) => {
          if (!item.isHidden) {
            const value = item?.dataFields?.valueY && dateValue ? (dateValue[item.dataFields.valueY] as number) : null;

            const itemText = item.name.replace(/(\r\n|\n|\r)/gm, "").includes("+")
              ? item.name.replace(/\+.*/, "").replace(/(\r\n|\n|\r)/gm, "")
              : item.name.replace(/-.*/, "").replace(/(\r\n|\n|\r)/gm, "");

            text += `[${item.stroke}]●[/] ${itemText}: ${isNull(value) ? "n/a" : formatToThreeDigits(value as number)}\n`;
          }
        });
        return text;
      });

      if (userViewsList?.includes("annotations") && name == "Nissan") {
        bullet.events.on("hit", (ev: any) => {
          const startDate = new Date(ev.target.dataItem.dateX);
          const endDate = isQuarterly ? endOfQuarter(startDate) : endOfMonth(startDate);
          setAnnotationStartDate(startDate);
          setAnnotationEndDate(endDate);
          setAnnotationDefaultMetric("Search interest Nissan");
          toggleAnnotationsDialog();
        });
      }

      if (name == "Nissan") {
        let annotationBullet = createAnnotationTooltips.bind(this);
        annotationBullet = annotationBullet(series, "annotations", {
          metric: "Search interest Nissan",
        });
        // @ts-ignore
        annotationBullet.adapter.add("disabled", function (disabled: any, target: any) {
          if (!target.dataItem) {
            return disabled;
          }
          if (target.dataItem.dataContext?.annotations) {
            return false;
          } else {
            return true;
          }
        });
        //@ts-ignore
        annotationBullet.tooltip.pointerOrientation = "right";
      }
    };
    createSeries({
      name: getCompetiterName(keyToCompare["name"]),
      field: keyToCompare["field"],
      color: RED,
    });

    competitors.map((competitor, idx) => createSeries({ name: competitor, field: competitor, color: competitorColors[idx] }));

    this.chart.cursor = new XYCursor();
    this.chart.cursor.maxTooltipDistance = 0;
    this.chart.cursor.lineX.disabled = true;
    this.chart.cursor.lineY.disabled = true;
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
        <ShareAnnotationModal
          open={this.state.shareModalOpen}
          handleToogleDialog={this.toogleShareModal}
          annotations={this.state.annotations}
        />
      </div>
    );
  }
}

export default MTMTrendLineChart;
