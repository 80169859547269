import React from "react";
import "../../../assets/styles/component/granularityOptions.scss";

const UsedCarsGranularityOptions = (props: {
  dateValue: string | null;
  granularity: string;
  name: string;
  onHandleRadioClick: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  isSearchInterest?: boolean;
  granularitiesList?: string[];
}) => {
  const { granularity, onHandleRadioClick, name, granularitiesList } = props;

  return (
    <div className="radio" id={`${name}_radio`}>
      {granularitiesList?.includes("daily") && (
        <div className="trend" data-radio-value="daily">
          <input
            type="radio"
            className="dp_radio trend_period"
            id={`${name}_daily`}
            name={name}
            value="daily"
            onChange={onHandleRadioClick}
            checked={granularity === "daily"}
          />
          <label htmlFor={`${name}_daily`} className="dp_label trend_label" data-value="daily">
            Daily
          </label>
          <br />
        </div>
      )}

      {granularitiesList?.includes("weekly") && (
        <div className="trend" data-radio-value="weekly">
          <input
            type="radio"
            className="dp_radio trend_period"
            id={`${name}_weekly`}
            name={name}
            value="weekly"
            onChange={onHandleRadioClick}
            checked={granularity === "weekly"}
          />
          <label htmlFor={`${name}_weekly`} className="dp_label trend_label" data-value="weekly">
            Weekly
          </label>
          <br />
        </div>
      )}

      {granularitiesList?.length && granularitiesList?.includes("monthly") ? (
        <div className="trend" data-radio-value="monthly" style={{ marginLeft: "10px", marginRight: "5px" }}>
          <input
            type="radio"
            className="dp_radio trend_period"
            id={`${name}_monthly`}
            name={name}
            value="monthly"
            onChange={onHandleRadioClick}
            checked={granularity === "monthly"}
          />
          <label htmlFor={`${name}_monthly`} className="dp_label trend_label" data-value="monthly">
            Monthly
          </label>
          <br />
        </div>
      ) : null}

      {granularitiesList?.length && granularitiesList?.includes("quarterly") ? (
        <div className="trend" data-radio-value="quarterly" style={{ marginLeft: "5px", marginRight: "5px" }}>
          <input
            type="radio"
            className="dp_radio trend_period"
            id={`${name}_quarterly`}
            name={name}
            value="quarterly"
            onChange={onHandleRadioClick}
            checked={granularity === "quarterly"}
          />
          <label htmlFor={`${name}_quarterly`} className="dp_label trend_label" data-value="quarterly">
            Quarterly
          </label>
          <br />
        </div>
      ) : null}

      {granularitiesList?.length && granularitiesList?.includes("yearly") ? (
        <div className="trend" data-radio-value="yearly" style={{ marginLeft: "5px", marginRight: "10px" }}>
          <input
            type="radio"
            className="dp_radio trend_period"
            id={`${name}_yearly`}
            name={name}
            value="yearly"
            onChange={onHandleRadioClick}
            checked={granularity === "yearly"}
          />
          <label htmlFor={`${name}_yearly`} className="dp_label trend_label" data-value="yearly">
            Yearly
          </label>
          <br />
        </div>
      ) : null}
    </div>
  );
};

export default UsedCarsGranularityOptions;
