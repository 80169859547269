import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { fetchKbaBreakdownData } from "../../actions";
import { ErrorMsg, NoDataMsg } from "../AppMessages";
import { ConversionWithRating, DefaultTable } from "./subs";

interface ITableData {
  name: string;
  visits: number;
  pop: number;
  yoy: number;
}

const KbaBreakdownTable = (props: {
  showPop: boolean;
  popLabel: string;
  specificMetric: string;
  isCpoDashboard?: boolean;
  onClickSpecificMetricClick: (evt: React.MouseEvent<HTMLElement>) => void;
}) => {
  const { showPop, popLabel, isCpoDashboard, onClickSpecificMetricClick, specificMetric } = props;

  const dispatch = useDispatch();
  const tableData = useSelector((state: RootStateOrAny) =>
    isCpoDashboard ? state.cpo_digital_performance.cpo_kba_breakdown_table : state.digital_performance.trend_breakdown.kbas
  );
  const isLoading = useSelector((state: RootStateOrAny) => state.loading.kba_trend_table);
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const kbaLabels = useSelector((state: RootStateOrAny) =>
    isCpoDashboard ? state.cpo_digital_performance.cpo_kba_label_mapping : state.digital_performance.kba_label_mapping
  );
  const hiddenCols = showPop ? [] : ["period_comparison"];

  useEffect(() => {
    !isCpoDashboard && dispatch(fetchKbaBreakdownData());
  }, [parameters, specificMetric]);

  const renderKbaType = useCallback(
    (props1: { value: string }) => {
      const { value } = props1;
      const isActiveClass = specificMetric && specificMetric === value ? "active" : "";

      return (
        <>
          <span
            className={`specific_link ${isActiveClass}`}
            data-value={value}
            data-metric={"kbas"}
            data-specified-value={specificMetric}
            onClick={onClickSpecificMetricClick}
          >
            {value}
          </span>
          {value === "Configurator engagement" && parameters.market === "Canada" && (
            <Tooltip
              position="bottom"
              className="key_indicator_tooltip"
              html={<p>Visits with at least one configurator item selected</p>}
              trigger="click"
              theme="dark"
              interactive={true}
              delay={0}
              hideDelay={0}
              duration={0}
            >
              <span
                className="info_tooltip hide_on_mobile"
                onClick={(evt: React.MouseEvent<HTMLElement>) => {
                  evt.stopPropagation();
                }}
              />
            </Tooltip>
          )}
        </>
      );
    },
    [specificMetric]
  );

  const renderVisits = useCallback(
    (props1: { value: string; cell: { row: { values: { kba_types: string } } } }) => {
      const {
        value,
        cell: {
          row: { values },
        },
      } = props1;

      const isActiveClass = specificMetric && specificMetric === values.kba_types ? "active" : "";

      return <span className={isActiveClass}>{value ? value.toLocaleString() : "n/a"}</span>;
    },
    [specificMetric]
  );

  const renderPeriodComparisonOCE = useCallback(
    (props: { cell: { row: { values: { kba_types: string }; original: { visits: number; pop: number } } } }) => {
      const {
        cell: {
          row: {
            original: { visits, pop },
            values,
          },
        },
      } = props;
      const isActiveClass = specificMetric && specificMetric === values.kba_types ? "active" : "";

      const currentValue = visits;
      const popValue = pop;
      return (
        <span className={isActiveClass}>
          <ConversionWithRating currentValue={currentValue} comparisonValue={popValue} />
        </span>
      );
    },
    [specificMetric]
  );

  const renderYearComparisonOCE = useCallback(
    (props: { cell: { row: { values: { kba_types: string }; original: { visits: number; yoy: number } } } }) => {
      const {
        cell: {
          row: {
            original: { visits, yoy },
            values,
          },
        },
      } = props;

      const isActiveClass = specificMetric && specificMetric === values.kba_types ? "active" : "";

      const currentValue = visits;
      const yoyValue = yoy;
      return (
        <span className={isActiveClass}>
          <ConversionWithRating currentValue={currentValue} comparisonValue={yoyValue} />
        </span>
      );
    },
    [specificMetric]
  );

  const data = useMemo(() => {
    const result: Array<ITableData> = [];

    if (tableData?.Current?.data) {
      tableData.Current.data.map((item: { metric_type: string; metric_visits: number }) => {
        const obj = {} as ITableData;
        obj["name"] = kbaLabels[item.metric_type];
        obj["visits"] = item.metric_visits;
        obj["pop"] = tableData?.PoP?.data?.find(
          (dataItem: { metric_type: string; metric_visits: number }) => dataItem.metric_type == item.metric_type
        )?.metric_visits;
        obj["yoy"] = tableData?.YoY?.data?.find(
          (dataItem: { metric_type: string; metric_visits: number }) => dataItem.metric_type == item.metric_type
        )?.metric_visits;
        result.push(obj);
      });
    }

    return result;
  }, [tableData]);

  const columns = useMemo(
    () => [
      {
        id: "kba_types",
        Header: "KBA types",
        accessor: "name",
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "alphanumericIgnoreCase",
        Cell: renderKbaType,
      },
      {
        Header: "Visits",
        accessor: "visits",
        id: "kbas",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: renderVisits,
      },
      {
        id: "period_comparison",
        Header: popLabel,
        accessor: "pop",
        sortType: "metricTypesSort",
        Cell: renderPeriodComparisonOCE,
      },
      {
        id: "year_comparison",
        Header: "YoY",
        accessor: "yoy",
        sortType: "metricTypesSort",
        Cell: renderYearComparisonOCE,
      },
    ],
    [specificMetric, parameters.market, popLabel]
  );

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <>
        {data.length > 0 ? (
          <DefaultTable
            columns={columns}
            data={data}
            // data={cpoTableData}
            id={"kba_breakdown"}
            data-test-id={"kba_breakdown"}
            extraClass={specificMetric ? "specified_metric metricBreakdown" : "metricBreakdown"}
            isChildComponent={false}
            initialSortColId={{ id: "kbas", desc: true }}
            hiddenCols={hiddenCols}
          />
        ) : isLoading ? null : (
          <NoDataMsg />
        )}
      </>
    </ErrorBoundary>
  );
};

export default KbaBreakdownTable;
