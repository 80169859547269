import {
  FETCH_INFLUENCE_OF_SHOPPING_TOOLS,
  FETCH_NON_TDS_LEADS,
  FETCH_ORDERS,
  FETCH_TDS_LEADS,
  FETCH_VISITS_DROP_OFF,
  SET_INFLUENCE_OF_SHOPPING_TOOLS,
  SET_NON_TDS_LEADS,
  SET_ORDERS,
  SET_TDS_LEADS,
  SET_VISITS_DROP_OFF,
} from "./actionTypes/ecommerceTypes";

export const fetchVisitsDropOff = (segment: string) => ({ type: FETCH_VISITS_DROP_OFF, payload: segment });
export const setVisitsDropOff = (data: any) => ({ type: SET_VISITS_DROP_OFF, payload: data });

export const fetchInfluenceOfShoppingTools = () => ({ type: FETCH_INFLUENCE_OF_SHOPPING_TOOLS });
export const setInfluenceOfShoppingTools = (data: any) => ({ type: SET_INFLUENCE_OF_SHOPPING_TOOLS, payload: data });

export const fetchOrders = () => ({ type: FETCH_ORDERS });
export const setOrders = (data: any) => ({ type: SET_ORDERS, payload: data });

export const fetchTdsLeads = () => ({ type: FETCH_TDS_LEADS });
export const setTdsLeads = (data: any) => ({ type: SET_TDS_LEADS, payload: data });

export const fetchNonTdsLeads = () => ({ type: FETCH_NON_TDS_LEADS });
export const setNonTdsLeads = (data: any) => ({ type: SET_NON_TDS_LEADS, payload: data });
