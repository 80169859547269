import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getPerformanceByMarketTable } from "../../../../api/executiveSummaryProductPerformanceApi";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";

const metricMapping: Record<string, string> = {
  Visits: "visits",
  KBAs: "kbas",
  "Leads Visits": "leads",
  "Total Products": "total_products",
  "CR - visits to KBA": "visits_to_kbas",
  "CR - visits to Leads": "visits_to_leads",
};

interface TableProps {
  data: Array<Record<string, string | number>>;
  title: string;
  extraTableClass?: string;
  metric: string;
}

const percMetrics = ["CR - visits to KBA", "CR - visits to Leads"];

const Table = (props: TableProps) => {
  const { data, title, extraTableClass, metric } = props;

  return (
    <div className="performance-by-market-table">
      <table className={`${extraTableClass ? extraTableClass : ""}`}>
        <thead>
          <tr>
            <td></td>
            <td colSpan={3}>{title}</td>
          </tr>
          <tr>
            <td></td>
            <td>Market</td>
            <td>{metric}</td>
            <td>YoY</td>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.market}>
              <td>{item.row}</td>
              <td>{item.market}</td>
              <td>
                {item[metricMapping[metric]]?.toLocaleString()}
                {item[metricMapping[metric]] && percMetrics.includes(metric) ? "%" : ""}
              </td>
              <td>{`${(item[`${percMetrics.includes(metric) ? "pts" : "perc"}_${metricMapping[metric]}`] as number) > 0 ? "+" : ""}${item[`${percMetrics.includes(metric) ? "pts" : "perc"}_${metricMapping[metric]}`] ?? "n/a"
                }${typeof item[`${percMetrics.includes(metric) ? "pts" : "perc"}_${metricMapping[metric]}`] == "string" ||
                  typeof item[`${percMetrics.includes(metric) ? "pts" : "perc"}_${metricMapping[metric]}`] == "number"
                  ? percMetrics.includes(metric)
                    ? "PTS"
                    : "%"
                  : ""
                }`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface Props {
  metricFilterValue: string;
}

const PerformanceByMarketTables = withRouter((props: RouteComponentProps & Props) => {
  const { history, metricFilterValue } = props;
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["performanceByMarketTableData", metricFilterValue, history.location.search],
    queryFn: () => getPerformanceByMarketTable(metricMapping[metricFilterValue]),
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search, metricFilterValue]);

  return (
    <div className="tile performance-by-market-tables">
      <Table data={data?.top ?? []} extraTableClass="top-performance-table" title={"Top performance markets"} metric={metricFilterValue} />
      <Table
        data={data?.bottom ?? []}
        extraTableClass="bottom-performance-table"
        title={"Bottom performance markets"}
        metric={metricFilterValue}
      />
      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
});

export default PerformanceByMarketTables;
