import { CategoryAxis, ColumnSeries, LineSeries, ValueAxis, XYChart, XYCursor } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { CYAN, LIGHT_CYAN, RED } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import { columnSeriesConfiguration, configureCategoryAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  barSeriesName: string;
  barDataKey: string;
  lineSeriesName: string;
  lineDataKey: string;
  data: Array<{ label: string; value: number; color: string }>;
  theme: ThemeInt;
  region?: string;
}

class DottedValueCombined extends Component<Props> {
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.props.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.props.chartId);
  }

  initChart() {
    const { data, barDataKey, chartId, barSeriesName, lineSeriesName, lineDataKey, theme, region } = this.props;

    const startDateOfDash = moment().subtract(2, "months").startOf("month");

    const chartData = data.map((row) => ({
      ...row,
      dash: moment(row.label, "MMM YY").isSameOrAfter(startDateOfDash) ? 5 : 0,
      fillOpacity: moment(row.label, "MMM YY").isSameOrAfter(startDateOfDash) ? 0 : 1,
    }));

    this.chart = create(chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = chartData;
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.colors.list = [color(CYAN), color(LIGHT_CYAN), color(RED)];

    // Creating Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "label";

    categoryAxis.cursorTooltipEnabled = false;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.numberFormatter.numberFormat = "#a";

    const percentageAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(percentageAxis, theme);
    percentageAxis.renderer.opposite = true;
    percentageAxis.min = 0;
    percentageAxis.strictMinMax = true;
    percentageAxis.renderer.labels.template.adapter.add("text", (text) => {
      return text + "%";
    });
    percentageAxis.cursorTooltipEnabled = false;
    percentageAxis.syncWithAxis = valueAxis;

    // Creating Series
    const series = this.chart.series.push(new ColumnSeries());
    columnSeriesConfiguration(series, {
      name: barSeriesName,
      valueY: barDataKey,
      yAxis: valueAxis,
    });
    series.dataFields.categoryX = "label";
    series.tooltipText = "{categoryX} {name}: {valueY.formatNumber('#,###.')}";
    series.clustered = false;
    series.columns.template.width = percent(50);
    series.columns.template.propertyFields.strokeDasharray = "dash";
    series.columns.template.propertyFields.fillOpacity = "fillOpacity";
    series.strokeWidth = 2;

    const lineSeries = this.chart.series.push(new LineSeries());
    const bullet = lineSeriesConfiguration(lineSeries, {
      name: lineSeriesName,
      valueY: lineDataKey,
      yAxis: percentageAxis,
    });
    lineSeries.dataFields.categoryX = "label";
    lineSeries.strokeWidth = 2;
    lineSeries.tooltipText = "{categoryX} {name}: {valueY.formatNumber('#.')}%";
    lineSeries.propertyFields.strokeDasharray = "dash";

    bullet.tooltipText = "{categoryX}: {valueY.formatNumber('#.')}%";

    if (region === "Europe") {
      const bpTargetLineSeries = this.chart.series.push(new LineSeries());
      const bpTargetBullet = lineSeriesConfiguration(bpTargetLineSeries, {
        name: "BP activation ratio target",
        valueY: "bp_g8_private_activation_ratio",
        yAxis: percentageAxis,
      });
      bpTargetLineSeries.dataFields.categoryX = "label";
      bpTargetLineSeries.strokeWidth = 2;
      bpTargetLineSeries.tooltipText = "{categoryX} {name}: {valueY.formatNumber('#.')}%";
      bpTargetLineSeries.propertyFields.strokeDasharray = "dash";

      bpTargetBullet.tooltipText = "{categoryX}: {valueY.formatNumber('#.')}%";
    }

    this.chart.cursor = new XYCursor();
    this.chart.cursor.lineX.disabled = true;
    this.chart.cursor.lineY.disabled = true;
  }
  render() {
    return <div id={this.props.chartId} className={"metricChart"} />;
  }
}

export default DottedValueCombined;
