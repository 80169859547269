import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import "../../../assets/styles/component/filters.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import { ImpressionsChart } from "../../../components/Charts/SEO/ImpressionsChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import ImpressionsBreakdownTable from "../../../components/Tables/SEO/ImpressionsBreakdownTable";
import { ThemeContext } from "../../../context";
import { useMetricVisible } from "../../../hooks/dropdownVisibleHook";
import { VisitorCountryFilter } from "../common/VisitorCountryFilter";
import { generatePreviousRange } from "../helpers/helpers";

export const ImpressionsTrendBreakdown = (): JSX.Element => {
  //Param values
  const {
    hostname: siteParamValue,
    date_range: dateRangeParamValue,
    visitor_country: visitorsCountryParamValue,
    query: impressionsQueryParamValue,
    impressions_disabled: impressionsDisabled,
  } = useSelector((state: RootStateOrAny) => state.seo_parameters);
  const { theme } = useContext(ThemeContext);

  //Search location state actions
  const countries = useSelector((state: RootStateOrAny) => state.seo_data.visitors_country);
  const [countriesList, setCountriesList] = useState([]);
  const [visitorCountryFilterValue, setVisitorCountryFilterValue] = useState("");
  //Metric filter state actions
  const { metricRef, isMetricVisible, setIsMetricVisible } = useMetricVisible(false);
  const { metricRef: sortRef, isMetricVisible: isSortMetricVisible, setIsMetricVisible: setIsSortMetricVisible } = useMetricVisible(false);
  const [isClicksChecked, setIsClicksChecked] = useState(false);
  const [isImpressionsChecked, setIsImpressionsChecked] = useState(true);
  const [sortTableBy, setSortTableBy] = useState("Impressions");

  //Chart data actions
  const impressions_overview = useSelector((state: RootStateOrAny) => state.seo_data.impressions_overview);
  const [weekCommence, setWeekCommence] = useState("");
  const [minGridDistance, setMinGridDistance] = useState(120);
  const [chartData, setChartData] = useState([]);
  const [impressionsChartData, setImpressionsChartData] = useState<any>([]);
  const legendLabels = generatePreviousRange(dateRangeParamValue);
  //Loading actions
  const { impressions_overview: isImpressionsOverviewLoading, impressions_breakdown: isImpressionsBreakdownLoading } = useSelector(
    (state: RootStateOrAny) => state.loading
  );

  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  //Manipulate visitors countries to fetch countries depending on the site
  useEffect(() => {
    const siteID = siteParamValue === "All" ? null : siteParamValue;
    const filteredCountries = countries?.visitors_country?.filter((country: any) => country.siteID === siteID);
    if (filteredCountries?.length > 0) {
      const formattedData = filteredCountries[0]?.country
        .filter((option: string) => option.charAt(0) !== option.charAt(0).toLowerCase())
        .sort();
      setCountriesList(formattedData);
    } else {
      setCountriesList([]);
    }
  }, [countries, siteParamValue]);

  //Set visitor country filter value
  useEffect(() => {
    if (countriesList && visitorsCountryParamValue) {
      const selectedCountries = visitorsCountryParamValue.split(",");
      const visitorCountryValue =
        selectedCountries === "All" || selectedCountries?.length === countriesList.length
          ? "All"
          : selectedCountries.length > 1
          ? `${selectedCountries?.length} selected`
          : visitorsCountryParamValue;
      setVisitorCountryFilterValue(visitorCountryValue);
    }
  }, [visitorsCountryParamValue, countriesList]);

  useEffect(() => {
    if (dateRangeParamValue.includes("month") || dateRangeParamValue.includes("quarter")) {
      setImpressionsChartData(impressions_overview[0]?.data);
      setWeekCommence("");
    } else {
      if (impressions_overview[0]?.data?.length > 90) {
        setImpressionsChartData(impressions_overview[0]?.week);
        setWeekCommence("w/c");
      } else {
        setImpressionsChartData(impressions_overview[0]?.data);
        setWeekCommence("");
      }
    }
  }, [dateRangeParamValue, impressions_overview]);

  useEffect(() => {
    if (dateRangeParamValue.includes("quarter")) {
      window.innerWidth < 1400 ? setMinGridDistance(50) : setMinGridDistance(120);
    }
  }, [dateRangeParamValue]);

  //format the data from the api to use flat data on am charts
  useEffect(() => {
    if (impressionsChartData !== 0) {
      const formattedData = impressionsChartData?.map((row: any) => {
        const {
          row_date,
          current_data: { impressions: currentValue, clicks: currentClicks },
          pop_data,
        } = row;
        const popDate = pop_data?.date;
        const popValue = pop_data?.impressions;
        const popClicks = pop_data?.clicks;
        return { row_date, currentValue, currentClicks, popValue, popDate, popClicks };
      });

      setChartData(formattedData);
    } else {
      setChartData([]);
    }
  }, [impressionsChartData]);

  //By default check impressions if none of the checkboxes is ticked
  useEffect(() => {
    if (!isClicksChecked && !isImpressionsChecked) {
      setIsImpressionsChecked(true);
    }
  }, [isClicksChecked, isImpressionsChecked]);

  //Disable visits if query is not All or visitor country is not All
  useEffect(() => {
    if (impressionsQueryParamValue !== "All" || visitorCountryFilterValue !== "All") {
      dispatch(setSpecificParameter("visits_disabled", true));
    } else {
      dispatch(setSpecificParameter("visits_disabled", false));
    }
  }, [impressionsQueryParamValue, visitorCountryFilterValue]);

  const handleVisitorCountryFilterSubmission = useCallback(() => {
    const parameterName = "visitor_country";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    dispatch(setSpecificParameter(parameterName, countriesList.length === selectedValues.length ? "All" : selectedValues.join(",")));
  }, []);

  return (
    <div className={`seo_tile impressions_trend_breakdown ${impressionsDisabled ? "disabled" : ""}`}>
      <h3>Search metrics trend and breakdown</h3>
      <div className="filters_container">
        {/*METRIC FILTER */}
        <div className="filter" ref={metricRef} data-test-id={"impressions_metrics_filter_div"}>
          <label className="filter_header" htmlFor="metrics_filter">
            METRICS
          </label>
          <div className="input_arrow" onClick={() => setIsMetricVisible(!isMetricVisible)}>
            <input
              readOnly
              type="text"
              id={"metric_filter"}
              data-test-id={"impressions_metrics_filter_input"}
              value={isClicksChecked && isImpressionsChecked ? "Multiple" : isClicksChecked ? "Clicks" : "Impressions"}
              className="filter_input"
            />
            <span className="arrow_down" />
          </div>

          <ul className={`filter_menu metrics_list ${isMetricVisible ? "showOptions" : ""}`} data-test-id={"impressions_metrics_filter_ul"}>
            <label className="metric-option">
              <input
                className="metric-option"
                type="checkbox"
                checked={isImpressionsChecked}
                onChange={() => {
                  setIsImpressionsChecked(!isImpressionsChecked);
                  setIsMetricVisible(false);
                }}
              />
              Impressions
            </label>
            <label className="metric-option">
              <input
                className="metric-option"
                type="checkbox"
                checked={isClicksChecked}
                onChange={() => {
                  setIsClicksChecked(!isClicksChecked);
                  setIsMetricVisible(false);
                }}
              />
              Clicks
            </label>
          </ul>
        </div>

        <VisitorCountryFilter
          filterName="SEARCH LOCATION"
          parentKey="visitor_country"
          filterList={countriesList}
          parameterName={"visitor_country"}
          parameterValue={visitorsCountryParamValue}
          handleFilterSubmission={handleVisitorCountryFilterSubmission}
          value={visitorCountryFilterValue}
        />

        {/*SORT BY FILTER */}
        {isClicksChecked && isImpressionsChecked && (
          <div className="filter" ref={sortRef} data-test-id={"impressions_sort_by_filter_div"}>
            <label className="filter_header" htmlFor="metrics_filter">
              SORT TABLE BY
            </label>
            <div className="input_arrow" onClick={() => setIsSortMetricVisible(!isSortMetricVisible)}>
              <input className="filter_input" value={sortTableBy} readOnly data-test-id={"impressions_sort_by_filter_input"} />
              <span className="arrow_down"></span>
            </div>
            <ul
              className={`filter_menu metrics_list ${isSortMetricVisible ? "showOptions" : ""}`}
              data-test-id={"impressions_sort_by_filter_ul"}
            >
              <label className="metric-option">
                <input
                  className="metric-option"
                  type="radio"
                  checked={sortTableBy === "Impressions"}
                  onChange={() => {
                    setSortTableBy("Impressions");
                    setIsSortMetricVisible(false);
                  }}
                />
                Impressions
              </label>
              <label className="metric-option">
                <input
                  className="metric-option"
                  type="radio"
                  checked={sortTableBy !== "Impressions"}
                  onChange={() => {
                    setSortTableBy("Clicks");
                    setIsSortMetricVisible(false);
                  }}
                />
                Clicks
              </label>
            </ul>
          </div>
        )}
      </div>

      {/*IMPRESSIONS CHART */}
      <ErrorBoundary fallback={<ErrorMsg />}>
        <ImpressionsChart
          data={chartData}
          chartName={"impressions-chart"}
          legendLabels={legendLabels}
          disabled={impressionsDisabled}
          showClicks={isClicksChecked}
          minDistance={minGridDistance}
          weekCommence={weekCommence}
          showImpressions={isImpressionsChecked}
          dateRange={dateRangeParamValue}
          theme={theme}
        />
      </ErrorBoundary>

      {/*IMPRESSIONS TABLE */}
      <ErrorBoundary fallback={<ErrorMsg />}>
        <ImpressionsBreakdownTable clicksChecked={isClicksChecked} impressionsChecked={isImpressionsChecked} sortTableBy={sortTableBy} />
      </ErrorBoundary>
      <LoadingEllipsis isLoading={isImpressionsOverviewLoading} className="joined_graph_overlay" />
      <LoadingEllipsis isLoading={isImpressionsBreakdownLoading} className={"joined_table_overlay"} />
    </div>
  );
};
