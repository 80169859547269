import { ValueAxis } from "@amcharts/amcharts4/charts";
import * as am5xy from "@amcharts/amcharts5/xy";
import { CREATE_CHART_INSTANCE, DELETE_CHART_INSTANCE } from "../../../actions/actionTypes";
import { CHART_TOOLTIP_FORMAT, CHART_TOOLTIP_FORMAT_NORMALIZED, getCurrencyPMO } from "../../../constants";

export const getTooltipDateFormat = (granularity = "daily", isNormalized = false) => {
  if (!Object.keys(CHART_TOOLTIP_FORMAT).includes(granularity))
    return isNormalized ? CHART_TOOLTIP_FORMAT_NORMALIZED["daily"].dateFormat : CHART_TOOLTIP_FORMAT["daily"].dateFormat;

  return isNormalized ? CHART_TOOLTIP_FORMAT_NORMALIZED["daily"].dateFormat : CHART_TOOLTIP_FORMAT[granularity].dateFormat;
};

export const getTooltipTextFormat = (granularity = "daily", isNormalized = false) => {
  if (!Object.keys(CHART_TOOLTIP_FORMAT).includes(granularity))
    return isNormalized ? CHART_TOOLTIP_FORMAT_NORMALIZED["daily"].tooltipFormat : CHART_TOOLTIP_FORMAT["daily"].tooltipFormat;
  return isNormalized ? CHART_TOOLTIP_FORMAT_NORMALIZED["daily"].tooltipFormat : CHART_TOOLTIP_FORMAT[granularity].tooltipFormat;
};

export const createChart = (chartId: string, chartType: any) => {
  // create new chart
  return { type: CREATE_CHART_INSTANCE, action: { id: chartId, payload: chartType } };
};

export const maybeDisposeChart = (chartId: string, chartRegistry: { [index: string]: any }) => {
  chartRegistry?.[chartId]?.dispose();
  return { type: DELETE_CHART_INSTANCE, action: { id: chartId } };
};

export const getGranularitiesToDisable = (diff: number): string[] => {
  let toDisable = ["weekly", "monthly", "quarterly"];

  if (!diff) return toDisable;

  if (diff > 7 && diff <= 61) {
    toDisable = ["monthly", "quarterly"];
  } else if (diff > 61 && diff <= 92) {
    toDisable = ["quarterly"];
  } else if (diff > 92 && diff <= 360) {
    toDisable = [];
  } else if (diff > 360) {
    toDisable = ["daily"];
  }

  return toDisable;
};

export const getGranularitiesToEnable = (diff: number, dashboard?: string): string[] => {
  let toEnable: string[] = [];
  if (!diff) return toEnable;
  if (dashboard == "brand-performance") {
    if (diff > 0 && diff <= 92) {
      toEnable = ["monthly"];
    } else if (diff > 92 && diff <= 364) {
      toEnable = ["monthly", "quarterly"];
    } else {
      toEnable = ["monthly", "quarterly", "yearly"];
    }
  } else {
    if (diff > 0 && diff <= 31) {
      toEnable = ["daily", "weekly"];
    } else if (diff > 31 && diff <= 92) {
      toEnable = ["weekly", "monthly"];
    } else if (diff > 92) {
      toEnable = ["monthly", "quarterly"];
    }
  }

  return toEnable;
};

export const getDefaultGranularity = (diff: number): string => {
  let granularity = "daily";

  if (!diff) return granularity;

  if (diff > 92) {
    granularity = "monthly";
  } else if (diff > 61) {
    granularity = "weekly";
  }

  return granularity;
};

export const formatValueAxisUnits = (valueAxis: ValueAxis, currency?: string) => {
  const units: string[] = [];
  valueAxis.renderer.labels.template.adapter.add("text", function (text) {
    const unit = text?.replace(/[^a-z]/gi, "");
    if (unit) units.push(unit);
    return text;
  });

  valueAxis.renderer.labels.template.adapter.add("text", function (text) {
    if (units?.includes("B")) {
      if (text?.includes("B"))
        if (currency) {
          return getCurrencyPMO(currency) + text;
        } else {
          return text;
        }
      if (text?.includes("M"))
        if (currency) {
          return getCurrencyPMO(currency) + Number(parseInt(text!) * 1000000) / 1000000000 + "B";
        } else {
          return Number(parseInt(text!) * 1000000) / 1000000000 + "B";
        }
      if (text?.includes("K"))
        if (currency) {
          return getCurrencyPMO(currency) + Number(parseInt(text!) * 1000) / 1000000000 + "B";
        } else {
          return Number(parseInt(text!) * 1000) / 1000000000 + "B";
        }
      if (currency) {
        return getCurrencyPMO(currency) + text;
      } else {
        return text;
      }
    } else if (units?.includes("M")) {
      if (text?.includes("M"))
        if (currency) {
          return getCurrencyPMO(currency) + text;
        } else {
          return text;
        }
      if (text?.includes("K"))
        if (currency) {
          return getCurrencyPMO(currency) + (Number(parseInt(text!)) * 1000) / 1000000 + "M";
        } else {
          return (Number(parseInt(text!)) * 1000) / 1000000 + "M";
        }
      if (currency) {
        return getCurrencyPMO(currency) + text;
      } else {
        return text;
      }
    } else if (units?.includes("K")) {
      if (text?.includes("K")) {
        if (currency) {
          return getCurrencyPMO(currency) + text;
        } else {
          return text;
        }
      } else {
        if (currency) {
          return getCurrencyPMO(currency) + Number(parseInt(text!)) / 1000 + "K";
        } else {
          return Number(parseInt(text!)) / 1000 + "K";
        }
      }
    }
    if (currency) {
      return getCurrencyPMO(currency) + text;
    } else {
      return text;
    }
  });
};

export const formatValueAxisUnitsAm5 = (valueAxis: am5xy.ValueAxis<am5xy.AxisRenderer>, currency?: string) => {
  const units: string[] = [];

  valueAxis.get("renderer").labels.template.adapters.add("text", (text, target) => {
    const unit = text?.replace(/[^a-z]/gi, "");

    if (unit) units.push(unit);
    return text;
  });

  valueAxis.get("renderer").labels.template.adapters.add("text", (text, target) => {
    if (units?.includes("b") || units?.includes("B")) {
      if (text?.includes("b") || units?.includes("B"))
        if (currency) {
          return getCurrencyPMO(currency) + text;
        } else {
          return text;
        }
      if (text?.includes("m") || text?.includes("M")) {
        if (currency) {
          return getCurrencyPMO(currency) + Number(parseInt(text!) * 1000000) / 1000000000 + "B";
        } else {
          return Number(parseInt(text!) * 1000000) / 1000000000 + "B";
        }
      }
      if (text?.includes("K") || text?.includes("k")) {
        if (currency) {
          return getCurrencyPMO(currency) + Number(parseInt(text!) * 1000) / 1000000000 + "B";
        } else {
          return Number(parseInt(text!) * 1000) / 1000000000 + "B";
        }
      }
      if (currency) {
        return getCurrencyPMO(currency) + text;
      } else {
        return text;
      }
    } else if (units?.includes("m") || units?.includes("M")) {
      if (text?.includes("m") || text?.includes("M"))
        if (currency) {
          return getCurrencyPMO(currency) + text;
        } else {
          return text;
        }
      if (text?.includes("k") || text?.includes("K"))
        if (currency) {
          return getCurrencyPMO(currency) + (Number(parseInt(text!)) * 1000) / 1000000 + "M";
        } else {
          return (Number(parseInt(text!)) * 1000) / 1000000 + "M";
        }
      if (currency) {
        return getCurrencyPMO(currency) + text;
      } else {
        return text;
      }
    } else if (units?.includes("k") || text?.includes("K")) {
      if (text?.includes("k") || text?.includes("K")) {
        if (currency) {
          return getCurrencyPMO(currency) + text;
        } else {
          return text;
        }
      } else {
        if (currency) {
          return getCurrencyPMO(currency) + Number(parseInt(text!)) / 1000 + "K";
        } else {
          return Number(parseInt(text!)) / 1000 + "K";
        }
      }
    } else {
      if (currency) {
        return getCurrencyPMO(currency) + text;
      } else {
        return text;
      }
    }
  });
};
