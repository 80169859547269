import Cookies from "js-cookie";
import { put, retry } from "redux-saga/effects";
import { setParameters } from "../actions";
import { isValidParameters } from "../api";
import { secondsDelay } from "../api/utils";
import { __local__ } from "../constants";

const digital_performance_filters = [
  "date_range",
  "brand",
  "group",
  "region",
  "market",
  "model",
  "last_touch_marketing_channel",
  "last_touch_marketing_channel_breakdown",
  "mobile_device_type",
];

// Worker sagas
export function* parameterSaga() {
  try {
    const isValidParam: boolean = __local__ ? true : yield retry(4, secondsDelay, isValidParameters, window.location.search);

    let parameters = new URLSearchParams(window.location.search);

    if (!isValidParam) {
      const default_url = Cookies.get("default_params") || "";
      parameters = new URLSearchParams(default_url);
    }

    const data = Object.fromEntries(parameters.entries());

    const dpFilters: Record<string, string> = {};

    for (const [key, values] of Object.entries(data)) {
      if (digital_performance_filters.includes(key)) dpFilters[key] = values;
    }

    const dataKeys = Object.keys(dpFilters);

    yield dataKeys?.length > 0 ? put(setParameters(dpFilters)) : "";
  } catch (err) {
    console.log("Failed to set parameters", err);
  }
}
