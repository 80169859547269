export const FETCH_BRAND_VOLUME_TREND = "FETCH_BRAND_VOLUME_TREND";
export const SET_BRAND_VOLUME_TREND = "SET_BRAND_VOLUME_TREND";

export const FETCH_BRAND_VOLUME_AVERAGE = "FETCH_BRAND_VOLUME_AVERAGE";
export const SET_BRAND_VOLUME_AVERAGE = "SET_BRAND_VOLUME_AVERAGE";

export const FETCH_BRAND_OAO_TREND = "FETCH_BRAND_OAO_TREND";
export const SET_BRAND_OAO_TREND = "SET_BRAND_OAO_TREND";

export const FETCH_BRAND_OAO_AVERAGE = "FETCH_BRAND_OAO_AVERAGE";
export const SET_BRAND_OAO_AVERAGE = "SET_BRAND_OAO_AVERAGE";

export const FETCH_BRAND_IMAGE_ATTRIBUTES_TREND = "FETCH_BRAND_IMAGE_ATTRIBUTES_TREND";
export const SET_BRAND_IMAGE_ATTRIBUTES_TREND = "SET_BRAND_IMAGE_ATTRIBUTES_TREND";

export const FETCH_BRAND_IMAGE_ATTRIBUTES_AVERAGE = "FETCH_BRAND_IMAGE_ATTRIBUTES_AVERAGE";
export const SET_BRAND_IMAGE_ATTRIBUTES_AVERAGE = "SET_BRAND_IMAGE_ATTRIBUTES_AVERAGE";
