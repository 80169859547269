import { retry, takeLatest } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { put } from "redux-saga/effects";
import { FETCH_CCS_ANALYSIS_ENROLLMENT_RATE, FETCH_CCS_ANALYSIS_USER_ENGAGEMENT_RATE } from "../actions/actionTypes/ccsAnalysisActionTypes";
import { setCcsAnalysisEnrollmentRateData, setCcsAnalysisUserEngagementRateData } from "../actions/ccsAnalysisActions";
import { setLoading } from "../actions/loadingActions";
import { getAnalysisEnrollmentRateData, getAnalysisUserEngagementRateData } from "../api";
import { secondsDelay } from "../api/utils";

// Watcher sagas
export function* watchFetchCCSAnalysisEnrollmentRateData() {
  yield takeLatest(FETCH_CCS_ANALYSIS_ENROLLMENT_RATE, fetchCcsAnalysisEnrollmentRateData);
}

export function* watchFetchCCSAnalysisUserEngagementRateData() {
  yield takeLatest(FETCH_CCS_ANALYSIS_USER_ENGAGEMENT_RATE, fetchCcsAnalysisUserEngagementRateData);
}

// Worker sagas
export function* fetchCcsAnalysisEnrollmentRateData() {
  try {
    yield put(setLoading("ccs_analysis_enrolement_rate", true));
    const response: any[] = yield retry(4, secondsDelay, getAnalysisEnrollmentRateData);

    if (!("error" in response)) {
      yield put(setCcsAnalysisEnrollmentRateData(response));
    }
  } catch (err) {
    console.log("failed to get enrolement rate data", err);
  } finally {
    yield put(setLoading("ccs_analysis_enrolement_rate", false));
  }
}

export function* fetchCcsAnalysisUserEngagementRateData({ payload: markets }: AnyAction) {
  try {
    yield put(setLoading("ccs_analysis_user_engagement_rate", true));
    const response: any[] = yield retry(4, secondsDelay, getAnalysisUserEngagementRateData, markets);

    if (!("error" in response)) {
      yield put(setCcsAnalysisUserEngagementRateData(response));
    }
  } catch (err) {
    console.log("failed to get user engagement rate data", err);
  } finally {
    yield put(setLoading("ccs_analysis_user_engagement_rate", false));
  }
}
