import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { performancePages } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import BrandPerformanceGraph from "../BrandPerformanceGraph";

interface Props {
  onHandleRadioClick: any;
  nissanCompetitor: string;
  activePage: performancePages;
  diff: number;
}

const TooltipParagraphs = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Overall opinion </span>
      shows the percentage of &#34;Excellent&#34; given as raiting as result of the following question: &#34;What is your overall oponion of
      each make/model (based on what you know or have heard)?&#34;
    </p>
    <p>
      <u>Source</u>: GfK Imagery and Awareness
    </p>
    <br />
  </>
);

const TooltipParagraphAvg = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Overall opinion average </span>
      considers the average score given to a make/model in the selected period. Please note that OaO average is calculated by the sum of all
      scores divided by the number of months selected.
    </p>
    <p>
      <u>Source</u>: GfK Imagery and Awareness
    </p>
    <br />
  </>
);

const BrandOaO = (props: Props) => {
  const { theme } = useContext(ThemeContext);
  const { onHandleRadioClick, nissanCompetitor, activePage, diff } = props;
  const model = useSelector((state: RootStateOrAny) => state.parameters.model);

  const chartData = useSelector((state: RootStateOrAny) => state.brand_performance.brand_oao_trend);
  const { data: tableData, date_range: dateRange } = useSelector((state: RootStateOrAny) => state.brand_performance.brand_oao_average);
  const loadingTrend = useSelector((state: RootStateOrAny) => state.loading.brand_oao_trend);
  const loadingTable = useSelector((state: RootStateOrAny) => state.loading.brand_oao_average);

  const columns = [
    {
      id: "model",
      Header: `${model == "CORPORATE" ? "Brand" : "Model"} `,
      accessor: "model",
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericIgnoreCase",
      Cell: (props1: { value: string }) => {
        const { value } = props1;

        return value;
      },
    },
    {
      Header: "OaO",
      accessor: `percentage_average_score`,
      id: "percentage_average_score",
      defaultCanSort: true,
      defaultSortDesc: true,
      sortDescFirst: true,
      sortType: "basicCustom",
      Cell: (props: { cell: { row: { original: any } } }) => {
        const {
          cell: {
            row: { original },
          },
        } = props;
        const value = original?.["percentage_average_score"];
        return value ? `${value}%` : "n/a";
      },
    },
    {
      Header: "YoY",
      accessor: `yoy`,
      id: "yoy",
      defaultCanSort: true,
      defaultSortDesc: true,
      sortDescFirst: true,
      sortType: "basicCustom",
      Cell: (props: { cell: { row: { original: any } } }) => {
        const {
          cell: {
            row: { original },
          },
        } = props;
        const value = original?.["yoy"];
        return value ? `${value}%` : "n/a";
      },
    },
  ];

  const dateTitle = useMemo(() => {
    if (dateRange && dateRange.length > 1) {
      return `(${dateRange[0]} - ${dateRange.slice(-1)})`;
    }

    return "";
  }, [dateRange]);

  return (
    <div className=" brand_volume performance_page">
      <BrandPerformanceGraph
        chartData={chartData}
        tableData={tableData?.sort((a: { volume: number }, b: { volume: number }) => a.volume < b.volume)}
        columns={columns}
        onHandleRadioClick={onHandleRadioClick}
        nissanCompetitor={nissanCompetitor}
        tableId="oao_table"
        trendChartId="oao_chart"
        TooltipParagraphs={TooltipParagraphs}
        trendTitle="Brand OaO Trend"
        tableTitle="Brand OaO Average"
        loadingTable={loadingTable}
        loadingTrend={loadingTrend}
        TooltipParagraphAvg={TooltipParagraphAvg}
        initialSortCol={{ id: "percentage_average_score", desc: true }}
        activePage={activePage}
        diff={diff}
        dateTitle={dateTitle}
        percentageChart
      />
    </div>
  );
};

export default BrandOaO;
