import React, { Fragment, useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { FilterContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { closeFilterOptions, closeMarketOptions } from "../subs/helpers";

interface Props {
  markets: any[];
  filterValue: string;
}

export const SeoGeographyFilter = (props: Props): JSX.Element => {
  const { markets, filterValue } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);
  const [sitesListVisible, setSitesListVisible] = useState(false);
  /**
   * @param option selected market option in the geography filter
   * Since this is the market level, set the site filter to All whenever the market is selected
   * set the visibility of the dropdown selection to false
   */
  const handleMarketChange = (option: string, site: string) => {
    dispatch(setSpecificParameter("market", option));
    dispatch(setSpecificParameter("hostname", site));

    eventTracking(MixpanelEvents.filter_change, { filter: "market", value: option, page: "SEO reporting", dashboard: "SEO" });
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  const handleSiteChange = (option: string, i: number) => {
    const selectedMarket = markets.filter((market: any, index: number) => index === i)[0];
    dispatch(setSpecificParameter("market", selectedMarket?.market));
    dispatch(setSpecificParameter("hostname", option));
    eventTracking(MixpanelEvents.filter_change, { filter: "hostname", value: option, page: "SEO reporting", dashboard: "SEO" });
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const { currentTarget } = evt;
      const { dataset } = currentTarget;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  const handleShowSites = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { id: currentId, dataset },
    } = evt;
    const sitesListId = dataset?.siteTarget;
    const currentElement = currentId ? document.getElementById(currentId) : undefined;
    const sitesListElement = sitesListId ? document.getElementById(sitesListId) : undefined;
    const shownClass = "show-markets";
    const marketDownClass = "down";

    if (sitesListVisible && sitesListElement?.classList.contains(shownClass)) {
      document.querySelectorAll(`.market-arrow-container`)?.forEach((childElement) => childElement.classList.remove("down"));
      document.querySelectorAll(`.region_country_filter`)?.forEach((childElement) => childElement.classList.remove("show-markets"));
      setSitesListVisible(false);
    } else {
      document.querySelectorAll(`.market-arrow-container`)?.forEach((childElement) => childElement.classList.remove("down"));
      document.querySelectorAll(`.region_country_filter`)?.forEach((childElement) => childElement.classList.remove("show-markets"));
      currentElement?.classList.toggle(marketDownClass);
      sitesListElement?.classList.add(shownClass);
      setSitesListVisible(true);
    }
  };

  return (
    <div className="filter" id="geography_filter_div" data-test-id="geography_filter_div">
      <label className="filter_header" htmlFor="date_filter_hidden">
        CP2022 SITE
      </label>
      <div className="input_arrow" onClick={showFilterOptionsFn} data-options-ul={"geography_ul"}>
        <input
          readOnly
          type="text"
          id={"geography_filter_hidden"}
          data-test-id={"geography_filter_hidden"}
          value={filterValue}
          className="filter_input"
        />
        <span className="arrow_down" />
      </div>

      <ul className="filter_options" id={"geography_ul"} data-test-id={"geography_ul"}>
        {markets &&
          markets.map((geography: any, index: number) => {
            const { siteID: sites, market } = geography;
            const siteUlId = `site_${market}${index}`;
            return (
              <Fragment key={index}>
                <div className={"market-filter "}>
                  {sites.length > 1 ? (
                    <span
                      className="region-span market-arrow-container"
                      data-site-target={siteUlId}
                      id={`${market}-div-${index}`}
                      onClick={handleShowSites}
                    >
                      <span className={`market-arrow`} />
                    </span>
                  ) : (
                    <span className="market-arrow-container" />
                  )}

                  <li
                    onClick={() => {
                      let site;
                      sites.length <= 1 ? (site = sites[0]) : (site = "All");
                      handleMarketChange(market, site);
                    }}
                  >
                    {sites.length <= 1 ? <span>{`${market} (${sites[0]})`}</span> : `${market} (${sites.length} sites)`}
                  </li>
                </div>
                <ul className={`region_country_filter`} id={siteUlId}>
                  {sites.map((site: string) => {
                    return (
                      <li key={Math.random()} className="filter__option" onClick={() => handleSiteChange(site, index)}>
                        {`${market} (${site})`}
                      </li>
                    );
                  })}
                </ul>
              </Fragment>
            );
          })}
      </ul>
    </div>
  );
};
