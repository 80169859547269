import { PieChart, PieSeries } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { BLACK, CCS_ANALYSIS_COLORS, CYAN, MOST_POPULAR_ENTRY_POINTS_COLORS, WHITE } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import { legendConfiguration } from "../helpers/configurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  theme: ThemeInt;
  chartId: string;
  valueField: string;
  categoryField: string;
  legendPosition: "left" | "right" | "top" | "bottom";
  innerRadius?: number;
  radius?: number;
}

class DmeoDonutChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: PieChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme, valueField, categoryField, legendPosition, innerRadius, radius } = this.props;
    this.chart = create(this.chartId, PieChart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.";
    this.chart.radius = radius ? radius : 80;
    this.chart.paddingTop = 20;

    this.chart.layout = "vertical";
    this.chart.innerRadius = innerRadius ? percent(innerRadius) : percent(45);

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.valueLabels.template.disabled = true;
    this.chart.legend.position = legendPosition;
    this.chart.legend.itemContainers.template.paddingBottom = 10;

    const series = this.chart.series.push(new PieSeries());
    series.dataFields.value = valueField;
    series.dataFields.category = categoryField;
    series.ticks.template.disabled = true;
    series.labels.template.text = "{value}%";
    series.alignLabels = false;
    series.labels.template.radius = percent(0);
    series.labels.template.fill = theme == "dark" ? color(WHITE) : color(BLACK);
    series.colors.list = CCS_ANALYSIS_COLORS.map((chartColor: string) => color(chartColor));
    series.slices.template.tooltipText = "{category}: {value}%";

    if (series.tooltip) {
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = color(WHITE);
      series.tooltip.label.fontSize = 15;
    }
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
        </div>
      </>
    );
  }
}

export default DmeoDonutChart;
