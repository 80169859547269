import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getExecutiveSummaryDataDefinitions } from "../../../api/executiveSummaryWebsiteApi";

const ExecutiveSummaryData = () => {
  const [dataUpdates, setDataUpdates] = useState<Array<any>>();

  useEffect(() => {
    const page = location.pathname?.split("/")?.at(-1)?.replaceAll("_", "-");

    getExecutiveSummaryDataDefinitions(page as string).then((response: any) => {
      if (response && "error" in response) {
        toast.error("Error fetching data updates");
      } else {
        setDataUpdates(response);
      }
    });
  }, []);

  return (
    <div className="data_documentation">
      <h3>EXECUTIVE SUMMARY - DATA OVERVIEW</h3>
      <h4>Executive Summary KPI definitions</h4>
      <br />
      <table className="docs_table">
        <thead>
          <tr>
            <th>KPI</th>
            <th>KPI definition</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {dataUpdates?.map((data, index) => (
            <tr key={index}>
              <td>{data.KPI}</td>
              <td>{data.KPI_definition}</td>
              <td>{data.Source}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h4>Digital Lead Sources definition</h4>
      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>
              Digital Lead Channel
            </th>
            <th>
              Definition
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Brand Website
            </td>
            <td>
              Visitors to the brand website who submit a lead form, tracked by PACE.
            </td>
          </tr>
          <tr>
            <td>
              Dealer Website
            </td>
            <td>
              Visitors to dealer websites, controlled / influenced by Nissan, who perform a lead-type action.
            </td>
          </tr>
          <tr>
            <td>
              Ecommerce
            </td>
            <td>
              Leads and sales generated through partnership with the e-commerce sites.
            </td>
          </tr>
          <tr>
            <td>
              3rd Party
            </td>
            <td>
              Leads from independent websites purchased by Nissan and distributed to Dealers.
            </td>
          </tr>
          <tr>
            <td>
              Social Media
            </td>
            <td>
              Visitors who arrive via social media and submit a lead form.
            </td>
          </tr>
          <tr>
            <td>
              OEM Online Ads
            </td>
            <td>
              Visitors who arrive via OEM online ads and submit a lead form.
            </td>
          </tr>
          <tr>
            <td>
              Customer Database
            </td>
            <td>
              Visitors  from customer database based on email subscriptions for Japan. It is used for official DIS reporting.
            </td>
          </tr>
          <tr>
            <td>
              Tracked Leads
            </td>
            <td>
              Sales Navi data for Japan and it is used for internal reference only.
            </td>
          </tr>
          <tr>
            <td>
              NRFM
            </td>
            <td>
              Leads generated through NR Finance Mexico (NRFM).
            </td>
          </tr>
          <tr>
            <td>
              Unsourced Leads
            </td>
            <td>
              Leads from unidentified sources.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ExecutiveSummaryData;
