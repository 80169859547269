import { createContext, Dispatch, SetStateAction } from "react";

export const MtmInvestmentContext = createContext<{
  futureMarket: { value: number; share: number };
  setFutureMarket: Dispatch<SetStateAction<{ value: number; share: number }>>;
  nearMarket: { value: number; share: number };
  setNearMarket: Dispatch<SetStateAction<{ value: number; share: number }>>;
  inMarket: { value: number; share: number };
  setInMarket: Dispatch<SetStateAction<{ value: number; share: number }>>;
}>({
  inMarket: { value: 0, share: 0 },
  nearMarket: { value: 0, share: 0 },
  futureMarket: { value: 0, share: 0 },
  setInMarket(): void {},
  setNearMarket(): void {},
  setFutureMarket(): void {},
});
