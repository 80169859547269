export const DEFAULT_URL = "/digital_performance";

export const DEFAULT_NORMAL_PERMISSION = "true";
export const DEFAULT_ADMIN_PERMISSION = "false";
export const DEFAULT_CAR_CONFIG_BETA_PERMISSION = "false";
export const DEFAULT_CCS_PLUS_PERMISSION = "false";
export const DEFAULT_CCS_PURCHASE_FUNNEL_ONLINE_TO_PHYSICAL = "false";
export const DEFAULT_CCS_PERMISSION = "false";
export const DEFAULT_CCS_PURCHASE_FUNNEL_PERMISSION = "false";
export const DEFAULT_PURCHASE_FUNNEL_PERMISSION = "false";
export const DEFAULT_CAR_CONFIG_PERMISSION = "false";
export const DEFAULT_CCS_CAR_CONFIG_PERMISSION = "false";
export const DEFAULT_BETA_GROUP_PERMISSION = "false";
export const DEFAULT_MTM_GROUP_PERMISSION = "false";
export const DEFAULT_ONLINE_TO_PHYSICAL_PERMISSION = "false";
