import moment from "moment";
import React, { Fragment, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { setSpecificParameter } from "../../../../actions";
import BusinessRevenueLineTrendChart from "../../../../components/Charts/CCSCockpit/BusinessRevenueLineTrendChart";
import BusinessRevenueStackedBarLineChart from "../../../../components/Charts/CCSCockpit/BusinessRevenueStackedBarLineChart";
import { LegendMarker } from "../../../../components/Charts/components/LegendMarker";
import { DefaultFilter } from "../../../../components/Filters/common";
import { InAppMultiSelectFilter } from "../../../../components/Filters/common/InAppMultiselectFilter";
import { closeFilterOptions } from "../../../../components/Filters/subs/helpers";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";
import { ALL_OPTION, CCS_BUSINESS_REVENUE_AND_SUBSCRIPTION_COLORS, getCurrency, RED } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import { abbreviateNumMaxMillion, formatToThreeDigits } from "../../../../utils/numberFormatting";
import { isNull } from "../../../../utils/utilityFunctions";
import { CockpitContext } from "../../subs/CockpitContext";

interface Props {
  title: string;
  splitData: Array<Record<string, string | number>> | undefined;
  yoySplitData: Array<Record<string, string | number>> | undefined;
  splitValueKey: string;
  isLoading: boolean;
  chartData?: Array<any>;
  chartSeries?: Array<any>;
  chartDataKey?: string;
  showCurrency: boolean;
  region: string;
  onClick?: () => void;
  toggle?: ReactNode;
  toogleType?: string;
  isConverted: boolean;
  financialYear?: Array<number>;
  isSalesRevenueTile?: boolean;
  financialYearSpan?: number;
}

const regionAcronyms: Record<string, string> = {
  China: "DFN",
  Europe: "EU",
  Japan: "JP",
  "North America": "US",
  All: "Total",
};

const foreignCurrencyMarkets = {
  JP: "¥",
  CN: "¥",
};

export const ValueSplitTile = (props: Props) => {
  const {
    title,
    chartDataKey,
    splitValueKey,
    splitData,
    yoySplitData,
    region,
    chartData,
    isLoading,
    onClick: handleClick,
    toggle,
    isConverted,
    financialYear,
    isSalesRevenueTile,
    chartSeries,
    financialYearSpan,
  } = props;

  const { metric } = useContext(CockpitContext);
  const {
    date_range: dateRange,
    region: regionParamValue,
    brand: brandParamValue,
    revenue_type: revenueTypeParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  const revenueSourceOptions = useSelector((state: RootStateOrAny) => state.ccs_cockpit.sales_revenue_sources);

  const seriesKeys = useMemo(() => {
    if (splitData && chartDataKey) {
      return splitData.map((splitRow) => splitRow.region as string);
    }
    return [];
  }, [splitData, chartDataKey]);

  const comparisonLabel = "YoY";

  const [isBefore2021, setIsBefore2021] = useState<boolean>(false);
  const [financialYearMessage, setfinancialYearMessage] = useState<Array<string>>([]);
  const [revenueTypeFilterValue, setRevenueTypeFilterValue] = useState("");
  const [businessRevenueType, setBusinessRevenueType] = useState("CCS business revenue Cumulative");

  useEffect(() => {
    const dates = dateRange.split(",");
    setIsBefore2021(dates.some((date: string) => moment(date).isBefore(moment("January 2021"))));
  }, [dateRange]);

  const [all_are_zero, setIsAllZeroes] = useState(false);
  useEffect(() => {
    if (splitData && splitData?.length > 1) {
      const isAllZero = splitData?.every((splitRow, idx, arr) => {
        const value = typeof splitRow[splitValueKey] === "number" ? splitRow[splitValueKey] : undefined;
        return value === 0;
      });
      setIsAllZeroes(isAllZero!);
    }
  }, [splitData]);

  useEffect(() => {
    const messages = [];
    for (const [k, v] of Object.entries(foreignCurrencyMarkets)) {
      const fmessage: string[] = [];
      financialYear?.includes(2019) && fmessage.push(`FY19 : ${k == "JP" ? 0.0092 : 0.1488}`);
      financialYear?.includes(2020) && fmessage.push(`FY20 : ${k == "JP" ? 0.0095 : 0.141}`);
      financialYear?.includes(2021) && fmessage.push(`FY21 : ${k == "JP" ? 0.0095 : 0.1445}`);
      financialYear?.includes(2022) && fmessage.push(`FY22 : ${k == "JP" ? 0.0083 : 0.1538}`);

      messages.push(`${k}${v} > $:${fmessage.join(" | ")}`);
    }

    metric != "Business revenue" ? setfinancialYearMessage(messages) : isConverted && setfinancialYearMessage(messages);
  }, [financialYear, isConverted]);

  // Sets model value
  useEffect(() => {
    if (revenueTypeParamValue) {
      const selectedRevenueTypes =
        revenueSourceOptions?.length === revenueTypeParamValue.split(",")?.length
          ? "All"
          : revenueTypeParamValue.includes(",")
          ? `${revenueTypeParamValue.split(",")?.length} revenues selected`
          : revenueTypeParamValue;
      setRevenueTypeFilterValue(selectedRevenueTypes);
    }
  }, [revenueTypeParamValue, revenueSourceOptions]);

  const handleRevenueTypeMultiselectSubmit = useCallback(() => {
    const parameterName = "revenue_type";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    closeFilterOptions();
  }, [revenueSourceOptions]);

  const filteredSeriesList = useMemo(() => {
    if (chartSeries && chartSeries.length > 0) {
      let validSeries: any = [];

      if (!isSalesRevenueTile && !/all/gi.test(regionParamValue)) {
        if (businessRevenueType == "CCS business revenue Cumulative") {
          validSeries = chartSeries.filter((series: any) => series.field.includes("target") || series.field.includes("cumulative"));
        } else {
          validSeries = chartSeries.filter((series: any) => !series.field.includes("target") && !series.field.includes("cumulative"));
        }
      } else {
        validSeries = chartSeries;
      }

      if (isConverted) {
        return validSeries.filter((series: any) => series.field.includes("converted"));
      } else {
        return validSeries.filter((series: any) => !series.field.includes("converted"));
      }
    }
    return [];
  }, [isConverted, chartSeries, isSalesRevenueTile, businessRevenueType, regionParamValue]);

  const generalTooltip = (
    <Tooltip
      position="top"
      className={"normal_tippy"}
      trigger="mouseenter"
      animation="none"
      interactive={true}
      distance={60}
      delay={1}
      hideDelay={1}
      duration={1}
      title={title}
      size={"small"}
      html={
        <Fragment>
          <div>
            {metric == "Business revenue" && title == "CCS business revenue" && (
              <p>The revenue target would only appear if you select 1 market and the Cumulative view.</p>
            )}

            {metric != "Business revenue"
              ? financialYearMessage.map((message, idx) => <p key={idx}>{message}</p>)
              : isConverted && financialYearMessage.map((message, idx) => <p key={idx}>{message}</p>)}
          </div>
        </Fragment>
      }
    >
      <span className="info_tooltip" />
    </Tooltip>
  );

  useEffect(() => {
    if (!revenueTypeParamValue && revenueSourceOptions.length > 0) {
      dispatch(setSpecificParameter("revenue_type", revenueSourceOptions[0]));
    }
  }, [revenueSourceOptions, revenueTypeParamValue]);

  const handlBusinessRevenueFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      setBusinessRevenueType(optionValue);
    }

    closeFilterOptions();
  };

  return (
    <div
      className="tile revenue_tile"
      id={title.replaceAll(" ", "_")}
      key={title.replaceAll(" ", "_")}
      data-test-id={title.replaceAll(" ", "_")}
      onClick={handleClick}
    >
      <div className={`tile_header ${metric == "Business revenue" ? "space-between" : "flex-column"}`} onClick={(e) => e.stopPropagation()}>
        <div className="tile_header" onClick={(e) => e.stopPropagation()}>
          {isSalesRevenueTile && region == "North America" ? (
            <InAppMultiSelectFilter
              filterValue={revenueTypeFilterValue}
              parameterValue={revenueTypeParamValue}
              parameterName="revenue_type"
              optionsList={revenueSourceOptions}
              hideAllOption={true}
              handleFilterSubmission={handleRevenueTypeMultiselectSubmit}
            />
          ) : chartData && !isSalesRevenueTile && !/all/gi.test(regionParamValue) ? (
            <DefaultFilter
              list={["CCS business revenue Non-Cumulative", "CCS business revenue Cumulative"]}
              filterName="business_revenue_type"
              filterLabel=""
              filterValue={businessRevenueType}
              handleFilterOptionClick={handlBusinessRevenueFilterOptionClick}
            />
          ) : (
            title
          )}{" "}
          {title == "CCS business revenue" || metric != "Business revenue" ? generalTooltip : null}
        </div>

        <div>{toggle ? toggle : null}</div>
      </div>

      <div className="CCS_main_content">
        {!isSalesRevenueTile && chartData && financialYearSpan && financialYearSpan > 1 && regionParamValue != ALL_OPTION ? (
          <div className="multiple-fy-info">Please select only 1 FY to visualize the CCS business revenue and the FY revenue target.</div>
        ) : (
          <>
            <div className="tile_content">
              <div className="currencyContent">
                {splitData && splitData?.length > 1 && all_are_zero ? (
                  <div className={"currencyRow"}>
                    <div className="value" data-test-region={region} data-test-value={"n/a"}>
                      <span className="main">n/a</span>
                    </div>
                  </div>
                ) : null}

                {splitData?.length ? (
                  splitData?.map((splitRow, idx, arr) => {
                    const region: string = splitRow.region as string;
                    if (metric == "" && region == "Cumulative business revenue") return;
                    const value: number = (
                      isConverted || region == "All" ? splitRow.revenue_value_converted : splitRow.revenue_value
                    ) as number;
                    const target = isConverted ? splitRow?.target_converted : splitRow?.target;

                    const comparisonRow = yoySplitData?.find((comparisonRow) => comparisonRow.region === region);

                    const comparisonPercentageValue = comparisonRow
                      ? isConverted || region == "All"
                        ? comparisonRow.perc_revenue_value_converted
                        : comparisonRow.perc_revenue_value
                      : null;

                    const isComparisonValid =
                      yoySplitData && typeof comparisonPercentageValue !== "undefined" && !isNull(comparisonPercentageValue);

                    const targetComparison = splitRow?.perc_target;

                    return arr.length === 1 || value ? (
                      <>
                        <div className={"currencyRow"} key={`${title}_${region}`}>
                          <>
                            <div className="value" data-test-region={region} data-test-value={value ? value : "n/a"}>
                              <span className="main">
                                {chartData && value && region != "All" ? (
                                  <LegendMarker
                                    shape={"line"}
                                    color={
                                      region == "Cumulative business revenue"
                                        ? RED
                                        : CCS_BUSINESS_REVENUE_AND_SUBSCRIPTION_COLORS[seriesKeys.indexOf(region)]
                                    }
                                  />
                                ) : null}
                                {value ? (
                                  <>
                                    <span>
                                      {regionParamValue == "All "
                                        ? `${region == "Cumulative business revenue" ? "Cumulative" : regionAcronyms[region]} :`
                                        : "Actual: "}{" "}
                                    </span>
                                    <span className="valueNumber">
                                      {getCurrency(
                                        region === "North America" || regionParamValue === ALL_OPTION || isConverted
                                          ? "United States"
                                          : region
                                      )}{" "}
                                      {formatToThreeDigits(value as number)}
                                    </span>
                                  </>
                                ) : (
                                  `n/a`
                                )}{" "}
                              </span>
                            </div>

                            {isComparisonValid && (
                              <p className={`period_comparison comparison ${metric == "Business revenue" ? "conversion" : ""}`}>
                                <span className="main">
                                  <Ratings
                                    value={
                                      comparisonPercentageValue
                                        ? isNull(comparisonPercentageValue)
                                          ? "n/a"
                                          : comparisonPercentageValue
                                        : "n/a"
                                    }
                                    isPercentageValue={true}
                                  />
                                  <span className="comparison_value">{Math.abs(comparisonPercentageValue as number)}%</span>{" "}
                                  {comparisonLabel}
                                </span>
                              </p>
                            )}
                          </>
                        </div>
                        {target && chartData && (
                          <div className={"currencyRow"} key={`${title}_${region}_target`}>
                            <>
                              <div className="value" data-test-region={region} data-test-value={target ? target : "n/a"}>
                                <span className="main">
                                  {chartData && target && region != "All" ? (
                                    <LegendMarker shape={"line"} color={CCS_BUSINESS_REVENUE_AND_SUBSCRIPTION_COLORS[1]} />
                                  ) : null}
                                  <>
                                    <span>Target: </span>{" "}
                                    <span className="valueNumber">
                                      {getCurrency(
                                        region === "North America" || region === "All" || isConverted ? "United States" : region
                                      )}{" "}
                                      {abbreviateNumMaxMillion(target as number)}
                                    </span>
                                  </>
                                </span>
                              </div>

                              <p className={`period_comparison ${metric == "Business revenue" ? "conversion" : ""}`}>
                                <span className="main">
                                  <span className="comparison_value">{targetComparison}%</span> of the target
                                </span>
                              </p>
                            </>
                          </div>
                        )}
                      </>
                    ) : null;
                  })
                ) : (
                  <div className="currencyContent">
                    <div className={"currencyRow"}>
                      <div className="value" data-test-region={region} data-test-value={"n/a"}>
                        <span className="main">n/a</span>
                      </div>
                    </div>
                    {(/all/gi.test(brandParamValue) || brandParamValue.includes("Nissan")) &&
                      (/all/gi.test(regionParamValue) || regionParamValue === "North America") &&
                      isBefore2021 && (
                        <div className="asterisk">
                          <p>*US data prior to January 2021 represents profits and not revenue</p>
                        </div>
                      )}
                  </div>
                )}
                {(/all/gi.test(brandParamValue) || brandParamValue.includes("Nissan")) &&
                  (/all/gi.test(regionParamValue) || regionParamValue === "North America") &&
                  isBefore2021 && (
                    <div className="asterisk">
                      <p>*US data prior to January 2021 represents profits and not revenue</p>
                    </div>
                  )}
              </div>
            </div>
            {chartData && (
              <div className="metric_content">
                {/all/gi.test(region) ||
                (!isSalesRevenueTile && !/all/gi.test(regionParamValue) && businessRevenueType == "CCS business revenue Non-Cumulative") ? (
                  <BusinessRevenueStackedBarLineChart
                    chartId={`${title}Chart`}
                    theme={themeContext.theme}
                    isLoading={isLoading}
                    data={chartData ?? []}
                    seriesList={filteredSeriesList}
                  />
                ) : (
                  <BusinessRevenueLineTrendChart
                    chartId={`${title}Chart`}
                    theme={themeContext.theme}
                    isLoading={isLoading}
                    data={chartData ?? []}
                    seriesList={filteredSeriesList}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>

      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};
