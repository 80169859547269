import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

interface Props {
  yrScenarios?: string[];
}

const YrScenarioRows = (props: Props) => {
  const { yrScenarios } = props;
  const period = useSelector((state: RootStateOrAny) => state.parameters.period);

  if (yrScenarios) {
    if (yrScenarios?.length == 1) {
      return (
        <div className="blue">
          <span>{yrScenarios[0]}</span>
        </div>
      );
    }
    return (
      <div
        className={`primary_background yr_scnr_rows ${period == "FY Month" ? "month_period" : "quarter_period"} ${
          yrScenarios?.length == 1 ? "one_yr_scnr_no_var" : "two_yr_scnr"
        }`}
      >
        <div className="blue">{yrScenarios[0]}</div>
        <div className="dark_purple">{yrScenarios[1]}</div>
        <div className="total_highlight">Var</div>
      </div>
    );
  }
  return <></>;
};

export default YrScenarioRows;
