import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import React, { useCallback } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { DATE_FILTER_VALUE_MAPPING } from "../../../constants";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { closeFilterOptions } from "../subs/helpers";

interface Props {
  value: string;
  maxDate: string;
  availableDates: { start: string; end: string };
  onFilterClick: any;
  dateOptions: Array<string>;
  renderOptions: ({ list, filter }: { list: string[]; filter: string }) => JSX.Element;
  page: string;
}

const DateFilter = ({ onFilterClick, value, availableDates, renderOptions, dateOptions, page }: Props) => {
  const dispatch = useDispatch();
  const handleCustomDate = useCallback(
    (event: any, picker: any) => {
      const customDateRange = `${moment(picker.startDate).format("DD/MM/YYYY")} - ${moment(picker.endDate).format("DD/MM/YYYY")}`;
      dispatch(setSpecificParameter("date_range", customDateRange));
      eventTracking(MixpanelEvents.filter_change, { filter: "date_range", value: customDateRange, page, dashboard: page });
      closeFilterOptions();
    },
    [page]
  );

  return (
    <div className="filter " id="date_filter_div">
      <label className="filter_header" htmlFor="date_filter_hidden">
        DATE RANGE
      </label>
      <div
        className="input_arrow"
        onClick={(evt) => {
          onFilterClick(evt);
        }}
        data-options-ul={"date_ul"}
      >
        <input
          readOnly
          type="text"
          id="date_filter_hidden"
          data-test-id="date_range"
          className={"filter_input"}
          value={Object.keys(DATE_FILTER_VALUE_MAPPING).includes(value) ? DATE_FILTER_VALUE_MAPPING[value] : value}
        />
        <span className="arrow_down " />
      </div>

      <ul className="filter_options date_options" id="date_ul" data-test-id="date_range_list">
        {renderOptions({ list: dateOptions, filter: "date_range" })}
        <li className="custom">
          <DateRangePicker
            initialSettings={{
              autoApply: true,
              autoUpdateInput: false,
              locale: {
                format: "DD/MM/YYYY",
              },
              minDate: availableDates.start,
              maxDate: availableDates.end,
            }}
            onApply={(e, picker) => handleCustomDate(e, picker)}
            key={availableDates.start + availableDates.end}
          >
            <input
              type="text"
              id="custom_date_filter"
              name="custom_date_range"
              placeholder="Custom Range"
              readOnly
              value={Object.keys(DATE_FILTER_VALUE_MAPPING).includes(value) ? "Custom Range" : value}
              className={"filter_input clean_input"}
            />
          </DateRangePicker>
        </li>
      </ul>
      <div className="filter_options date_options date_multiselect hidden" data-test-id="date_range_list" />
    </div>
  );
};

export default DateFilter;
