import {
  SET_AVERAGE_DAYS_IN_STOCK_TREND_DATA,
  SET_AVERAGE_LIST_PRICE_TREND_DATA,
  SET_AVERAGE_SALES_PRICE_TREND_DATA,
  SET_BASE_TRENDS_DATA,
  SET_DEALER_TREND_DATA,
  SET_INVENTORY_BY_AGE_DATA,
  SET_INVENTORY_RATIO_DATA,
  SET_INVENTORY_VOLUME_TREND_DATA,
  SET_LAST_WEEK_DATES,
  SET_LEAD_SALES_CONVERSION_DATA,
  SET_LIST_PRICE_DATA,
  SET_PERFORMANCE_PER_UNIT_DATA,
  SET_SALES_REVENUE_TREND_DATA,
  SET_SALES_VOLUME_TREND_DATA,
  SET_TOTAL_VALUES_DATA,
  SET_TURNOVER_RATIO_DATA,
} from "../actions/actionTypes";

const initialState: {
  base_trend_data: any[];
  dealer_trend_data: any[];
  inventory_by_age_data: any[];
  performance_per_unit: any[];
  response_error: any[];
  total_values_data: any[];
  list_price_data: any[];
  inventory_ratio_data: any[];
  turnover_ratio_data: any[];
  lead_sales_conversion_data: any[];
  inventory_volume_data: Record<any, any>;
  avg_days_in_stock_data: Record<any, any>;
  sales_volume_data: Record<any, any>;
  sales_revenue_data: Record<any, any>;
  avg_list_price_data: Record<any, any>;
  avg_sales_price_data: Record<any, any>;
  last_week_dates: Record<any, any>;
} = {
  base_trend_data: [],
  dealer_trend_data: [],
  inventory_by_age_data: [],
  performance_per_unit: [],
  total_values_data: [],
  list_price_data: [],
  inventory_ratio_data: [],
  turnover_ratio_data: [],
  lead_sales_conversion_data: [],
  inventory_volume_data: {},
  avg_days_in_stock_data: {},
  sales_volume_data: {},
  sales_revenue_data: {},
  avg_list_price_data: {},
  avg_sales_price_data: {},
  response_error: [],
  last_week_dates: {},
};

const cpoOperationalReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_BASE_TRENDS_DATA:
      return { ...state, base_trend_data: action.payload };

    case SET_DEALER_TREND_DATA:
      return { ...state, dealer_trend_data: action.payload };

    case SET_INVENTORY_BY_AGE_DATA:
      return { ...state, inventory_by_age_data: action.payload };

    case SET_PERFORMANCE_PER_UNIT_DATA:
      return { ...state, performance_per_unit_data: action.payload };

    case SET_TOTAL_VALUES_DATA:
      return { ...state, total_values_data: action.payload };

    case SET_LIST_PRICE_DATA:
      return { ...state, list_price_data: action.payload };

    case SET_INVENTORY_RATIO_DATA:
      return { ...state, inventory_ratio_data: action.payload };

    case SET_TURNOVER_RATIO_DATA:
      return { ...state, turnover_ratio_data: action.payload };

    case SET_LEAD_SALES_CONVERSION_DATA:
      return { ...state, lead_sales_conversion_data: action.payload };

    case SET_INVENTORY_VOLUME_TREND_DATA:
      return { ...state, inventory_volume_data: action.payload };

    case SET_AVERAGE_DAYS_IN_STOCK_TREND_DATA:
      return { ...state, avg_days_in_stock_data: action.payload };

    case SET_SALES_VOLUME_TREND_DATA:
      return { ...state, sales_volume_data: action.payload };

    case SET_SALES_REVENUE_TREND_DATA:
      return { ...state, sales_revenue_data: action.payload };

    case SET_AVERAGE_LIST_PRICE_TREND_DATA:
      return { ...state, avg_list_price_data: action.payload };

    case SET_AVERAGE_SALES_PRICE_TREND_DATA:
      return { ...state, avg_sales_price_data: action.payload };

    case SET_LAST_WEEK_DATES:
      return { ...state, last_week_dates: action.payload };

    default:
      return state;
  }
};

export default cpoOperationalReducer;
