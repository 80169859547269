import { CategoryAxis, ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, Sprite } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BIG_NUMBER_ABBREVIATION, BLACK, BRIGHT_BLUE, CYAN, GRAY, PURPLE, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import {
  columnSeriesConfiguration,
  configureCategoryAxis,
  configureDateAxis,
  configureValueAxis,
  lineSeriesConfiguration,
} from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { legendConfigurationOCE, oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  seriesList: Array<{ name: string; field: string; type: string }>;
  theme: ThemeInt;
  chartName: string;
  isLoading: boolean;
  isCategoryAxis?: boolean;
  categoryAxisField?: string;
}

const BAR_COLORS = [CYAN, BRIGHT_BLUE, GRAY, PURPLE];

class ExecutiveSummaryStackedBarLineChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.tooltipConfig = this.tooltipConfig.bind(this);
  }

  chartId = this.props.chartName;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, seriesList, theme, categoryAxisField, isCategoryAxis } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.colors.list = BAR_COLORS.map((item) => color(item));

    // Creates Legend
    this.chart.legend = legendConfigurationOCE(theme);
    this.chart.legend.reverseOrder = false;

    // Create current period date chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme, true);
    dateAxis.renderer.minGridDistance = 20;
    dateAxis.dateFormats.setKey("month", "MMM");
    // dateAxis.periodChangeDateFormats.setKey("month", "MMM yy");

    // Create category chart axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.dataFields.category = categoryAxisField;
    categoryAxis.startLocation = 0.2;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.maxWidth = 102;

    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.numberFormatter.numberFormat = "#a";
    valueAxis.renderer.numberFormatter.bigNumberPrefixes = BIG_NUMBER_ABBREVIATION;

    seriesList?.forEach(({ name, field, type }, idx) => {
      const series = type == "bar" ? this.chart.series.push(new ColumnSeries()) : this.chart.series.push(new LineSeries());
      series.data = data;
      if (isCategoryAxis) {
        series.xAxis = categoryAxis;
        series.dataFields.categoryX = categoryAxisField;
      } else {
        series.xAxis = dateAxis;
        series.dataFields.dateX = "date";
      }

      if (type == "bar" && series instanceof ColumnSeries) {
        columnSeriesConfiguration(series, {
          name: name,
          valueY: field,
        });
        series.stacked = true;
        series.yAxis = valueAxis;
        series.columns.template.tooltipText = "test";
        //@ts-ignore
        series.columns.template.adapter.add("tooltipText", this.tooltipConfig);
      } else if (series instanceof LineSeries) {
        lineSeriesConfiguration(series, {
          valueY: field,
          name: name,
        });
        series.yAxis = valueAxis;
      }

      if (series.tooltip) {
        series.tooltip.getFillFromObject = false;
        series.tooltip.autoTextColor = false;
        series.tooltip.background.fill = color(WHITE);
        series.tooltip.label.fill = color(BLACK);
      }

      if (type == "dot") {
        series.strokeWidth = 0;
      }
    });

    this.chart.series.each((series) => {
      series.bullets.getIndex(0)?.adapter.add("tooltipText", this.tooltipConfig);
    });
  }

  tooltipConfig(value: string | undefined, target: Sprite) {
    const dataValue = target?.tooltipDataItem?.dataContext as { date: string;[index: string]: string | number };

    let text = this.props.isCategoryAxis
      ? `[bold]${dataValue[this.props.categoryAxisField as string]}[/]\n`
      : `[bold]${formatDateAxisLabelToMonthly(dataValue?.date)}[/]\n`;

    this.chart.series.each((item) => {
      const value = item?.dataFields?.valueY && dataValue ? (dataValue[item.dataFields.valueY] as number) : null;
      text += `[${item.stroke}]●[/] ${item.name}: ${value ? Number(value).toLocaleString() : "n/a"}\n`;
    });

    return text;
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default ExecutiveSummaryStackedBarLineChart;
