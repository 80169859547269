import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { DATE_FILTER_VALUE_MAPPING, DATE_FILTER_VALUE_MAPPING_MSRP, PURCHASE_FUNNEL_DATE_FILTER_OPTIONS } from "../../../constants";
import { isMonthlyCustomDate } from "../../../utils/dateFunctions";

interface Props {
  value: string;
  onFilterClick: any;
  handleFilterOptionClick: (evt: React.MouseEvent<HTMLElement>) => void;
  presetDatesList?: string[];
  minDateValue?: string;
  isMsrp?: boolean;
  latestDate?: string;
}

const PurchaseFunnelDateFilter = ({
  onFilterClick,
  value,
  handleFilterOptionClick,
  presetDatesList,
  minDateValue,
  isMsrp,
  latestDate,
}: Props) => {
  const dispatch = useDispatch();
  const { date_range: dateRangeParamValue } = useSelector((state: RootStateOrAny) => state.purchase_funnel_parameters);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const minDate = minDateValue ? new Date(minDateValue) : new Date("May 2019");
  const maxDate = latestDate
    ? new Date(moment(latestDate, "YYYY-MM-DD").format("MMMM YYYY"))
    : new Date(moment().subtract(1, "month").format("MMMM YYYY"));
  const dateStrings = presetDatesList ? presetDatesList : PURCHASE_FUNNEL_DATE_FILTER_OPTIONS;
  const filterValueMapping = isMsrp ? DATE_FILTER_VALUE_MAPPING_MSRP : DATE_FILTER_VALUE_MAPPING;

  //Set selected date range
  useEffect(() => {
    if (startDate && endDate) {
      const customDateRange = `${moment(startDate, "MMMM YYYY").format("MMMM YYYY")} - ${moment(endDate, "MMMM YYYY").format("MMMM YYYY")}`;
      dispatch(setSpecificParameter("date_range", customDateRange));
    }
  }, [startDate, endDate]);

  //Reset start and end date values of calendar when date range is not custom month date range
  useEffect(() => {
    if (dateRangeParamValue) {
      if (!isMonthlyCustomDate(dateRangeParamValue)) {
        setStartDate(null);
        setEndDate(null);
      }
    }
  }, [dateRangeParamValue]);

  const handleCalendarClose = () => setCalendarOpen(false);
  const handleCalendarOpen = () => setCalendarOpen(true);

  return (
    <div className="filter " id="date_filter_div">
      <label className="filter_header" htmlFor="date_filter_hidden">
        DATE RANGE
      </label>
      <div
        className="input_arrow"
        onClick={(evt) => {
          onFilterClick(evt);
        }}
        data-options-ul={"date_ul"}
      >
        <input
          readOnly
          type="text"
          id="date_filter_hidden"
          data-test-id="date_range"
          className={"filter_input"}
          value={Object.keys(filterValueMapping).includes(value) ? filterValueMapping[value] : value}
        />
        <span className="arrow_down " />
      </div>

      <ul className="filter_options date_options" id="date_ul" data-test-id="date_range_list">
        {dateStrings?.map((item: string) => {
          return (
            <li key={item} data-filter={"date_range"} onClick={handleFilterOptionClick} data-value={item}>
              {item}
            </li>
          );
        })}
        <div className={`${calendarOpen ? "open_calendar_options" : ""}`}>
          <li className="custom">
            <>
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => {
                  setStartDate(date);
                  setEndDate(null);
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                placeholderText="Start date"
                popperPlacement="bottom"
                minDate={minDate}
                maxDate={maxDate}
                onCalendarOpen={handleCalendarOpen}
                onCalendarClose={handleCalendarClose}
              />
              <DatePicker
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
                selectsEnd
                minDate={startDate}
                startDate={startDate}
                endDate={endDate}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                placeholderText="End date"
                disabled={startDate === null}
                maxDate={maxDate}
                onCalendarOpen={handleCalendarOpen}
                onCalendarClose={handleCalendarClose}
              />
            </>
          </li>
        </div>
      </ul>
      <div className="filter_options date_options date_multiselect hidden" data-test-id="date_range_list" />
    </div>
  );
};

export default PurchaseFunnelDateFilter;
