import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { fetchSovData } from "../../../actions";
import { ErrorMsg } from "../../../components/AppMessages";
import ComparisonBarChart from "../../../components/Charts/ProductMarketingOptimization/ComparisonBarChart";
import { MultiSelectFilter } from "../../../components/Filters/common";
import { closeFilterOptions, closeMarketOptions } from "../../../components/Filters/subs/helpers";
import { SOV_MIX_COLORS } from "../../../constants";
import { FilterContext, ThemeContext } from "../../../context";

const SovModule = () => {
  const dispatch = useDispatch();
  const ref = useRef<any>(null);
  const themeContext = useContext(ThemeContext);
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);
  const granularity = useSelector((state: RootStateOrAny) => state.parameters.granularity);
  const marketParam = useSelector((state: RootStateOrAny) => state.parameters.market);
  const modelParam = useSelector((state: RootStateOrAny) => state.parameters.model);

  const parameters = useSelector((state: RootStateOrAny) => state.parameters);

  const data = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.sov_data?.data);

  const models = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.sov_data?.models);

  const mediagroups = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.sov_data?.media_groups);

  const loading = useSelector((state: RootStateOrAny) => state.loading.sov_data);

  const [filterList, setFilterList] = useState<string[]>([]);

  const [filteredList, setFilteredList] = useState<string[]>([]);

  useEffect(() => {
    if (filteredList.length > 0) {
      setFilterList(filteredList);
    } else {
      setFilterList(mediagroups?.length > 0 ? mediagroups : []);
    }
  }, [mediagroups, filteredList]);

  useEffect(() => {
    setFilteredList([]);
  }, [parameters]);

  const seriesList = useMemo(() => {
    return models?.map((e: any, idx: number) => {
      return {
        name: e && e,
        gradeField: e,
        color: SOV_MIX_COLORS[idx],
        hide: false,
      };
    });
  }, [models]);

  const date = useMemo(() => {
    const dates = data?.map((e: any) => e?.date);
    return Array.from(new Set(dates));
  }, [data]);

  const dataChart = useMemo(() => {
    const chartSeries: any = [];
    date?.map((e: any) => {
      let min = {};
      data.map((dta: any) => {
        if (dta.date == e) min = { ...min, [dta.model]: dta.spend };
      });
      min = { ...min, date: e };
      chartSeries.push(min);
    });
    return chartSeries;
  }, [date, data]);

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (tooltipVisible && ref.current != null && !ref.current.contains(e.target)) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [tooltipVisible]);

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  const handleMediaGroupFilterSubmission = useCallback(() => {
    const parameterName = "mediagroups";
    const selectedCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    setFilteredList(selectedValues);

    let filterMediaList = "";
    selectedValues.map((e: any) => {
      filterMediaList += `&media_groups=${e}`;
    });

    dispatch(fetchSovData(filterMediaList)), closeFilterOptions();
  }, []);

  const value = useMemo(() => {
    if (filteredList.length > 0 && filteredList.length != mediagroups.length) {
      return filteredList.join(",");
    }

    return "All";
  }, [filteredList]);

  const disclaimerMessage = useMemo(() => {
    let message = "";
    if (["Philippines", "Canada", "Australia", "South Africa", "United Arab Emirates"].includes(marketParam)) {
      message = "Please note that Internet SOV data is not available for the selected geography";
    } else if (marketParam == "France") {
      message = "Please note that Ioniq5 and Ioniq6 can not be segregated in SOV data";
    } else if (marketParam == "United States" && modelParam == "Sentra") {
      message = "Please note that Chevrolet Bolt is the sum of Chevrolet Bolt and Chevrolet Bolt EV";
    } else if (
      (marketParam == "United Kingdom" && modelParam == "X-Trail") ||
      (marketParam == "United Kingdom" && modelParam == "Qashqai")
    ) {
      message = "Please note that Ioniq5 and Ioniq6 can not be segregated in SOV data";
    }
    return message;
  }, [marketParam, modelParam]);

  return (
    <div className="sov_outer_container primary_tile_background">
      <div className="header">
        <div className="title_container">
          <h3>Sov </h3>
          <Tooltip
            position="left"
            className="key_indicator_tooltip"
            html={
              <div ref={ref}>
                <p>
                  <span style={{ fontWeight: "bold" }}>Share of voice</span> is a measure of the market each model owns compared to the
                  competitors. It is based on the investments made in different media groupings as Cinema, Internet, OOH, Print, Radio and
                  TV and considers the models in the same segment and geography. The calculation uses Japanese Yen as currency.
                </p>
                <br />
                <p>
                  <u>Source:</u> BIG3
                </p>

                <br />
                <br />
              </div>
            }
            trigger="click"
            animation="none"
            theme="dark"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            open={tooltipVisible}
            //@ts-ignore
            onShown={() => setTooltipVisible(!tooltipVisible)}
          >
            <span
              className="info_tooltip hide_on_mobile"
              onClick={(evt: React.MouseEvent<HTMLElement>) => {
                evt.stopPropagation();
                setTooltipVisible(!tooltipVisible);
              }}
            />
          </Tooltip>
          <p>{disclaimerMessage}</p>
        </div>
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="mediagroup"
            // @ts-ignore
            filterList={mediagroups?.length > 0 ? mediagroups : []}
            filterName="Select media group"
            value={value}
            parameterName={"mediagroups"}
            parameterValue={filterList?.length > 0 ? filterList.join() : ""}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleMediaGroupFilterSubmission}
            unCheckNissan={false}
          />
        </ErrorBoundary>
      </div>
      <div className="sov">
        <div className="sov_trend">
          <div className="main_content">
            <ErrorBoundary fallback={<ErrorMsg />}>
              <ComparisonBarChart
                chartName="sovChart"
                data={dataChart}
                granularity={granularity}
                isLoading={loading}
                showVolume={false}
                seriesList={seriesList}
                theme={themeContext.theme}
              />
            </ErrorBoundary>
          </div>
        </div>

        {data?.length > 0 ? (
          <div className="sov-trend-legend hideOnMobileOnly">
            {seriesList?.map((series: { name: string; color: string }, index: number) => {
              return (
                <div className="legend-item" key={series.name}>
                  <div className="legend-box" style={{ backgroundColor: series.color }}></div>
                  {` ${series.name}`}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SovModule;
