import { DateAxis, LabelBullet, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, MouseCursorStyle } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { BLACK, LIME_GREEN, ORANGE, RED, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, legendConfiguration, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  seriesList: Array<{ name: string; field: string; seriesColor: string }>;
  theme: ThemeInt;
  chartId: string;
  showLegend?: boolean;
  maxValue?: number;
  minValue?: number;
  isPercentage?: boolean;
  disableLegendClick?: boolean;
  isLoading: boolean;
}

class VocLineTrendChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme, showLegend, maxValue, minValue, isPercentage, disableLegendClick } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.0";

    // Legend configuration
    if (showLegend) {
      this.chart.legend = legendConfiguration(theme);
      this.chart.legend.position = "right";
      this.chart.legend.itemContainers.template.paddingBottom = 20;
      this.chart.legend.useDefaultMarker = true;
      this.chart.legend.scrollable = true;
    }

    if (disableLegendClick) {
      this.chart.legend.itemContainers.template.clickable = false;
      this.chart.legend.itemContainers.template.focusable = false;
      this.chart.legend.itemContainers.template.cursorOverStyle = MouseCursorStyle.default;
    }

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + `${isPercentage ? "%" : ""}`;
    });
    if (maxValue || minValue) {
      valueAxis.strictMinMax = true;
      valueAxis.renderer.minGridDistance = 10;
      valueAxis.max = maxValue;
      valueAxis.min = minValue;
    }

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    seriesList?.forEach(({ name, field, seriesColor }, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.dataFields.dateX = "date";
      series.fill = color(seriesColor);
      series.stroke = color(seriesColor);

      const circleBullet = lineSeriesConfiguration(series, {
        valueY: field,
        name: name,
      });

      if (!showLegend) {
        const labelBullet = series.bullets.push(new LabelBullet());
        labelBullet.label.text = "{valueY.formatNumber('#.0')}";
        labelBullet.dy = 12;
        labelBullet.label.fill = color(theme == "light" ? BLACK : WHITE);
        circleBullet.adapter.add("fill", (value, target) => {
          const rating = target?.dataItem?.values?.valueY?.value;
          if (rating && rating <= 3.4) {
            value = color(RED);
          } else if (rating && rating >= 3.5 && rating <= 3.9) {
            value = color(ORANGE);
          } else {
            value = color(LIME_GREEN);
          }
          return value;
        });
      } else {
        circleBullet.tooltipText =
          "[bold font-size: var(--regular_font_size)]{name}:[bold font-size: var(--regular_font_size)] {valueY.formatNumber('#.0')}";
      }
    });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default VocLineTrendChart;
