import React, { useEffect } from "react";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import DmeoFilters from "../../../components/Filters/DmeoFilters";
import { PageTitle } from "../../../components/PageContent/subs";
import BackButton from "../../../components/PageContent/subs/BackButtonLink";
import LandingPagePerformanceTile from "./components/LandingPagePerformanceTile";
import LandingPageEvaluationMetrics from "./components/LandingPageEvaluationMetrics";
import { useQuery } from "@tanstack/react-query";
import { getDmeoLastDataRefresh } from "../../../api/dmeoApi";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";

const DmeoLandingPagePerformance = () => {
  const { data: lastDataRefreshData, refetch: refetchLastDataRefresh } = useQuery({
    queryKey: ["dmeoLastDataRefresh"],
    queryFn: getDmeoLastDataRefresh,
    enabled: false,
  });

  useEffect(() => {
    refetchLastDataRefresh();
    eventTracking(MixpanelEvents.page_view, { dashboard: "DMEO", page: "Landing Page Performance" });
  }, []);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<DmeoFilters hasLandingPage={true} />}
        dataDocumentation=""
        lastDataRefresh={lastDataRefreshData?.date ?? ""}
        pageTitle={<PageTitle dataDocumentation="dmeo" backButton={<BackButton id="dmeoLandingPageBackButton" link={"/dmeo"} />} />}
      >
        <div className="dmeo-landing-page">
          <LandingPagePerformanceTile />
          <LandingPageEvaluationMetrics />
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
};

export default DmeoLandingPagePerformance;
