import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { performancePages } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import BrandPerformanceGraph from "../BrandPerformanceGraph";

interface Props {
  onHandleRadioClick: any;
  nissanCompetitor: string;
  activePage: performancePages;
  diff: number;
}

const TooltipParagraphs = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Brand Image </span>
      considers the ratings given by public opinion to car models and brands based on their attributes. Please note that because the data
      collection methos is different between each country, the figure can not be compared as absolute value.
    </p>
    <p>
      <u>Source</u>: GfK Imagery and Awareness
    </p>
    <br />
  </>
);

const TooltipParagraphAvg = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Brand Image average </span>
      considers the average score given to a make/model in the selected period. Please note that Image average is calculated by the sum of
      all scores divided by the number of months selected.
    </p>
    <p>
      <u>Source</u>: GfK Imagery and Awareness
    </p>
    <br />
  </>
);

const BrandImageAttributes = (props: Props) => {
  const { theme } = useContext(ThemeContext);
  const { onHandleRadioClick, nissanCompetitor, activePage, diff } = props;

  const chartData = useSelector((state: RootStateOrAny) => state.brand_performance.brand_image_attributes_trend);
  const loadingTrend = useSelector((state: RootStateOrAny) => state.loading.brand_image_attributes_trend);
  const { data: tableData, date_range: dateRange } = useSelector(
    (state: RootStateOrAny) => state.brand_performance.brand_image_attributes_average
  );
  const loadingTable = useSelector((state: RootStateOrAny) => state.loading.brand_image_attributes_average);
  const { market, granularity, model } = useSelector((state: RootStateOrAny) => state.parameters);

  const columns = [
    {
      id: "model",
      Header: `${model == "CORPORATE" ? "Brand" : "Model"} `,
      accessor: "model",
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericIgnoreCase",
      Cell: (props1: { value: string }) => {
        const { value } = props1;

        return value;
      },
    },
    {
      Header: "Image Attribute Average",
      accessor: `percentage_average_score`,
      id: "percentage_average_score",
      defaultCanSort: true,
      defaultSortDesc: true,
      sortDescFirst: true,
      sortType: "alphanumericCustom",
      Cell: (props: { cell: { row: { original: any } } }) => {
        const {
          cell: {
            row: { original },
          },
        } = props;
        const value = original?.["percentage_average_score"];
        return value ? `${value}%` : "n/a";
      },
    },
    {
      Header: "YoY",
      accessor: `yoy`,
      id: "yoy",
      defaultCanSort: true,
      defaultSortDesc: true,
      sortDescFirst: true,
      sortType: "basicCustom",
      Cell: (props: { cell: { row: { original: any } } }) => {
        const {
          cell: {
            row: { original },
          },
        } = props;
        const value = original?.["yoy"];
        return value ? `${value}%` : "n/a";
      },
    },
  ];

  const dateTitle = useMemo(() => {
    if (dateRange && dateRange.length > 1) {
      return `(${dateRange[0]} - ${dateRange.slice(-1)})`;
    }

    return "";
  }, [dateRange]);

  return (
    <div className=" brand_volume performance_page">
      <BrandPerformanceGraph
        chartData={chartData}
        tableData={tableData?.sort((a: { volume: number }, b: { volume: number }) => a.volume < b.volume)}
        columns={columns}
        onHandleRadioClick={onHandleRadioClick}
        nissanCompetitor={nissanCompetitor}
        tableId="image_attr_table"
        trendChartId="image_attr_chart"
        TooltipParagraphs={TooltipParagraphs}
        trendTitle="Brand Image Attribute Trend"
        tableTitle="Brand Image Attribute Average"
        loadingTable={loadingTable}
        loadingTrend={loadingTrend}
        TooltipParagraphAvg={TooltipParagraphAvg}
        initialSortCol={{ id: "percentage_average_score", desc: true }}
        activePage={activePage}
        diff={diff}
        dateTitle={dateTitle}
        percentageChart
      />
    </div>
  );
};

export default BrandImageAttributes;
