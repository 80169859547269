import { CategoryAxis, ColumnSeries, ILineSeriesDataFields, LabelBullet, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  theme: ThemeInt;
  chartName: string;
  isLoading: boolean;
  seriesList: Array<{ name: string; seriesColor: string }>;
  maxVal: number;
  minVal: number;
}

export class MtpSpendChart extends Component<Props> {
  chartId = this.props.chartName;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, theme, seriesList, maxVal, minVal } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;

    const screenWidth = window.matchMedia("(max-width: 768px)");

    // Create Axes
    const categoryAxis = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "mtp_category";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.cellStartLocation = 0;
    categoryAxis.renderer.cellEndLocation = 0.55;
    categoryAxis.renderer.minGridDistance = 0;

    const categoryAxis1 = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis1, theme);
    categoryAxis1.dataFields.category = "mtp_category";
    categoryAxis1.renderer.inversed = true;
    categoryAxis1.renderer.grid.template.location = 0;
    categoryAxis1.renderer.cellStartLocation = 0.45;
    categoryAxis1.renderer.cellEndLocation = 1;
    categoryAxis1.renderer.minGridDistance = 0;
    categoryAxis1.renderer.maxWidth = 10;
    categoryAxis1.renderer.labels.template.opacity = 0;
    categoryAxis.renderer.labels.template.fontSize = "13px";

    if (this.props.chartName !== "mtpSpendChartQ1" && !screenWidth.matches) {
      categoryAxis.renderer.labels.template.opacity = 0;
      categoryAxis.renderer.maxWidth = 10;
    }

    const valueAxis = this.chart.xAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.labels.template.fontSize = "13px";
    valueAxis.extraMax = maxVal > 5000000 && maxVal < 7500000 ? 0 : 0.3;
    valueAxis.min = 0;
    if (maxVal <= 0) {
      valueAxis.max = 0;
      valueAxis.extraMax = 0;
    }
    if (minVal < 0) {
      valueAxis.min = minVal;
      valueAxis.extraMin = 0.3;
    }

    if (maxVal > 5000000 && maxVal < 7500000) valueAxis.max = 10000000;
    valueAxis.renderer.labels.template.minWidth = 60;
    valueAxis.numberFormatter.numberFormat = "#";
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.minGridDistance = 10;
    valueAxis.numberFormatter.numberFormat = "#a";

    function createGrid(value: number) {
      const range = valueAxis.axisRanges.create();
      range.value = value;
      range.label.text = "{value.formatNumber('#.a')}";
    }
    const numStr = maxVal.toString();
    const newNum =
      Number(numStr.charAt(1)) >= 5
        ? Number(
            Number(numStr.substring(0, 1) + numStr.slice(1).replace(/[0-9]/g, "0")) + Number(1 + numStr.slice(1).replace(/[0-9]/g, "0"))
          )
        : Number(
            Number(numStr.substring(0, 2) + numStr.slice(2).replace(/[0-9]/g, "0")) + Number(1 + numStr.slice(2).replace(/[0-9]/g, "0"))
          );

    if (maxVal > 0) {
      if (minVal > 0) {
        createGrid(0);
        createGrid(newNum / 2);
      } else {
        createGrid(newNum / 2);
        createGrid(minVal);
      }
      createGrid(newNum);
    } else {
      createGrid(newNum / 2);

      createGrid(minVal);
    }
    const dataFields: Record<string, string> =
      seriesList?.length > 1
        ? {
            fy0Act: `${seriesList[0]?.name}_amount`,
            fy1Act: `${seriesList[1]?.name}_amount`,
            categoryY: "mtp_category",
          }
        : {
            fy0Act: `${seriesList[0]?.name}_amount`,
            categoryY: "mtp_category",
          };

    if (seriesList?.length > 1) {
      this.createSeries(`fy${0}Act`, seriesList[0]?.name, seriesList[0]?.seriesColor, dataFields, 0, categoryAxis);
      this.createSeries(`fy${1}Act`, seriesList[1]?.name, seriesList[1]?.seriesColor, dataFields, 1, categoryAxis1);
    } else {
      this.createSeries(`fy${0}Act`, seriesList[0]?.name, seriesList[0]?.seriesColor, dataFields, 0, categoryAxis);
    }
  }

  createSeries(
    field: string,
    name: string,
    seriesColor: string,
    dataFields: Record<string, string>,
    idx: number,
    categoryAxis: CategoryAxis
  ) {
    const series = this.chart.series.push(new ColumnSeries());
    const valueX = dataFields[field];
    series.dataFields = {
      valueX: valueX,
      ...dataFields,
    } as ILineSeriesDataFields;
    series.name = name;
    series.columns.template.height = percent(80);
    series.sequencedInterpolation = true;
    series.clustered = false;
    series.yAxis = categoryAxis;
    series.stroke = color(seriesColor);
    series.fill = color(seriesColor);
    series.columns.template.maxWidth = 20;
    series.columns.template.maxHeight = 50;

    const labelBullet = series.bullets.push(new LabelBullet());
    labelBullet.label.text = `{valueX.formatNumber("#.0a")}`;
    labelBullet.label.fill = color(this.props.theme === "light" ? BLACK : WHITE);
    labelBullet.label.truncate = false;
    labelBullet.label.zIndex = 25;
    labelBullet.label.adapter.add("horizontalCenter", function (center, target) {
      if (target?.currentText?.includes("-")) return "right";
      return "left";
    });
    labelBullet.locationY = 0.5;
    labelBullet.label.hideOversized = false;
    labelBullet.label.fontSize = "12px";
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}
