import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Login } from "./Login";

export const Logout = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  useEffect(() => {
    const cookies = Cookies.get();

    for (const cookie in cookies) {
      Cookies.remove(cookie);
    }

    localStorage.removeItem("LAST_VISITED_DASHBOARD");

    history.push({ pathname: "/login" });
    if (typeof window !== "undefined") {
      // @ts-ignore
      window.SI_API && window.SI_API.hideTab();
    }
  }, [history]);

  return <Login />;
});
