import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { ErrorMsg } from "../../../../components/AppMessages";
import CompletionRateTable from "./CompletionRateTable";
import ElectrificationTable from "./ElectrificationTable";
import MarketBreakdownTable from "./MarketBreakdownTable";
import MediaCampaignsTable from "./MediaCampaignsTable";
import OtherTouchpointsTable from "./OtherTouchpointsTable";
import VlpFocusTable from "./VlpFocusTable";

const RatingPerTouchpoint = () => {
  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="row grid">
        <MarketBreakdownTable />
        <VlpFocusTable />
        <ElectrificationTable />
      </div>
      <OtherTouchpointsTable />
      <MediaCampaignsTable />
      {/* <div className="row grid">
        <div className="tile grid-col-span-6 min-h-15">
          <div className="tileHeader">Editorial content</div>
          <EditorialTable />
        </div>
      </div>
      <div className="row grid">
        <div className="tile grid-col-span-6 min-h-15">
          <div className="tileHeader">Ownership</div>
          <OwnershipTable />
        </div>
      </div> */}
      <CompletionRateTable />
    </ErrorBoundary>
  );
};

export default RatingPerTouchpoint;
