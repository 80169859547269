import { CategoryAxis, ColumnSeries, ILineSeriesDataFields, LabelBullet, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, RED, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  theme: ThemeInt;
  chartName: string;
  isLoading: boolean;
  seriesList: Array<{ name: string; seriesColor: string }>;
  maxYear?: number;
  lowestValue: number;
  unitParamValue: string;
}

export class FmiByModelChart extends Component<Props> {
  chartId = this.props.chartName;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, theme, seriesList, lowestValue, maxYear } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;

    const screenWidth = window.matchMedia("(max-width: 768px)");
    this.chart.zoomOutButton.align = "left";
    this.chart.zoomOutButton.valign = "bottom";
    this.chart.zoomOutButton.marginLeft = 10;
    this.chart.zoomOutButton.marginBottom = 10;

    // Create Axes
    const categoryAxis = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "mdl_nm_rev";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.cellStartLocation = 0.05;
    categoryAxis.renderer.cellEndLocation = 0.45;
    categoryAxis.renderer.minGridDistance = 0;
    categoryAxis.renderer.grid.template.disabled = false;
    categoryAxis.renderer.grid.template.fill = theme === "light" ? color(BLACK) : color(WHITE);
    categoryAxis.renderer.grid.template.stroke = theme === "light" ? color(BLACK) : color(WHITE);
    categoryAxis.renderer.grid.template.strokeOpacity = 0.5;
    categoryAxis.renderer.labels.template.wrap = true;
    if (this.props.chartName == "fmiByModelChartQ1" && !screenWidth.matches) categoryAxis.renderer.width = 140;

    const categoryAxis1 = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis1, theme);
    categoryAxis1.dataFields.category = "mdl_nm_rev";
    categoryAxis1.renderer.inversed = true;
    categoryAxis1.renderer.grid.template.location = 0;
    categoryAxis1.renderer.cellStartLocation = 0.55;
    categoryAxis1.renderer.cellEndLocation = 0.95;
    categoryAxis1.renderer.minGridDistance = 0;
    categoryAxis1.renderer.maxWidth = 2;
    categoryAxis1.renderer.labels.template.opacity = 0;
    categoryAxis.renderer.labels.template.fontSize = "13px";

    if (this.props.chartName !== "fmiByModelChartQ1" && !screenWidth.matches) {
      categoryAxis.renderer.labels.template.opacity = 0;
      categoryAxis.renderer.maxWidth = 2;
    }

    const valueAxis = this.chart.xAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.labels.template.fontSize = "13px";
    valueAxis.extraMax = 0.3;
    if (lowestValue < 0) {
      valueAxis.extraMin = 0.6;
      valueAxis.extraMax = 0.5;
      valueAxis.min = lowestValue;
    }
    valueAxis.numberFormatter.numberFormat = "#";
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.minGridDistance = 10;
    valueAxis.renderer.grid.template.zIndex = 1000;
    valueAxis.renderer.grid.template.stroke = color(RED);

    if (maxYear) valueAxis.max = maxYear;

    const dataFields: Record<string, string> =
      seriesList?.length > 1
        ? {
            fy0Act: `${seriesList[0]?.name}_amount`,
            fy1Act: `${seriesList[1]?.name}_amount`,
            fy0ActRaw: `${seriesList[0]?.name}_raw_amount`,
            fy1ActRaw: `${seriesList[1]?.name}_raw_amount`,
            unit: `unit`,
            categoryY: "mdl_nm_rev",
          }
        : {
            fy0Act: `${seriesList[0]?.name}_amount`,
            fy0ActRaw: `${seriesList[0]?.name}_raw_amount`,
            unit: `unit`,
            categoryY: "mdl_nm_rev",
          };

    if (seriesList?.length > 1) {
      this.createSeries(`fy${0}Act`, `fy${0}ActRaw`, `unit`, seriesList[0]?.name, seriesList[0]?.seriesColor, dataFields, 0, categoryAxis);
      this.createSeries(`fy${1}Act`, `fy${1}ActRaw`, `unit`, seriesList[1]?.name, seriesList[1]?.seriesColor, dataFields, 1, categoryAxis1);
    } else {
      this.createSeries(`fy${0}Act`, `fy${0}ActRaw`, `unit`, seriesList[0]?.name, seriesList[0]?.seriesColor, dataFields, 0, categoryAxis);
    }
  }

  createSeries(
    field: string,
    fieldRaw: string,
    unit: string,
    name: string,
    seriesColor: string,
    dataFields: Record<string, string>,
    idx: number,
    categoryAxis: CategoryAxis
  ) {
    const series = this.chart.series.push(new ColumnSeries());
    const valueX = dataFields[fieldRaw];
    const valueY = dataFields[field];
    const categoryX = dataFields[unit];
    series.dataFields = {
      valueX: valueX,
      categoryX: categoryX,
      valueY: valueY,
      ...dataFields,
    } as ILineSeriesDataFields;
    series.name = name;
    series.columns.template.height = percent(80);
    series.sequencedInterpolation = true;
    series.clustered = false;
    series.yAxis = categoryAxis;
    series.stroke = color(seriesColor);
    series.fill = color(seriesColor);
    series.columns.template.maxWidth = 20;
    series.columns.template.maxHeight = 50;

    const labelBullet = series.bullets.push(new LabelBullet());
    labelBullet.label.text = this.props.unitParamValue == "K" ? `{valueY.formatNumber("#,###.")}` : `{valueY.formatNumber("#,###.0")}`;
    labelBullet.label.fill = color(this.props.theme === "light" ? BLACK : WHITE);
    labelBullet.label.truncate = false;
    labelBullet.label.minWidth = 120;
    labelBullet.label.zIndex = 25;
    labelBullet.label.paddingLeft = 4;
    labelBullet.label.paddingRight = 4;
    labelBullet.label.adapter.add("horizontalCenter", () => "left");
    labelBullet.locationY = 0.5;
    labelBullet.label.hideOversized = false;
    labelBullet.label.fontSize = "13px";

    series.columns.template.events.on("sizechanged", function (ev) {
      //    @ts-ignore
      const data = ev?.target?.dataItem?.dataContext[`${name}_amount`];
      //    @ts-ignore
      if (ev.target?.dataItem && ev.target?.dataItem?.bullets) {
        //    @ts-ignore
        ev.target?.dataItem?.bullets.each(function (id, bullet) {
          if (data < 0) {
            bullet.label.paddingLeft = -50;
          }
        });
      }
    });
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}
