import { createContext, Dispatch, SetStateAction } from "react";

export const DocumentationContext = createContext<{
  documentation: string;
  setDocumentation: Dispatch<SetStateAction<string>>;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}>({
  documentation: "",
  isVisible: false,
  setDocumentation(): void {},
  setIsVisible(): void {},
});
