export const FETCH_ADMIN_BRANDS = "FETCH_ADMIN_BRANDS";
export const SET_ADMIN_BRANDS = "SET_ADMIN_BRANDS";
export const FETCH_ADMIN_REGIONS = "FETCH_ADMIN_REGIONS";
export const SET_ADMIN_REGIONS = "SET_ADMIN_REGIONS";
export const FETCH_ADMIN_MARKETS = "FETCH_ADMIN_MARKETS";
export const SET_ADMIN_MARKETS = "SET_ADMIN_MARKETS";
export const FETCH_ADMIN_VIEWS = "FETCH_ADMIN_VIEWS";
export const SET_ADMIN_VIEWS = "SET_ADMIN_VIEWS";
export const FETCH_ADMIN_NEW_MODELS = "FETCH_ADMIN_NEW_MODELS";
export const SET_ADMIN_NEW_MODELS = "SET_ADMIN_NEW_MODELS";
export const FETCH_ADMIN_CHANNELS_DATA = "FETCH_ADMIN_CHANNELS_DATA";
export const SET_ADMIN_CHANNELS_DATA = "SET_ADMIN_CHANNELS_DATA";
export const FETCH_ADMIN_CHANNEL_BREAKDOWNS_DATA = "FETCH_ADMIN_CHANNEL_BREAKDOWNS_DATA";
export const SET_ADMIN_CHANNEL_BREAKDOWNS_DATA = "SET_ADMIN_CHANNEL_BREAKDOWNS_DATA";
export const FETCH_ADMIN_METRICS_DATA = "FETCH_ADMIN_METRICS_DATA";
export const SET_ADMIN_METRICS_DATA = "SET_ADMIN_METRICS_DATA";
