import React, { useContext, useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchMsrpLastDataRefresh, setPageName } from "../../actions";
import {
  fetchDifferenceByModel,
  fetchDisByModelAndMonth,
  fetchMsrpProfileByMonth,
  fetchMsrpSummary,
  fetchMsrpVmeDifference,
  fetchSalesByModel,
  fetchTopFiveModels,
  fetchTotalAddedValue,
  fetchTotalValueDisByMonth,
  fetchVmeDifferenceByModel,
} from "../../actions/msrpActions";
import "../../assets/styles/pages/msrp.scss";
import Collapsible from "../../components/Collapsible/Collapsible";
import MsrpDashboardFilters from "../../components/Filters/MsrpDashboardFilters";
import { DashboardLayout } from "../../components/Layouts";
import { DefaultPageContent } from "../../components/PageContent";
import { PageTitle } from "../../components/PageContent/subs";
import { DashboardBannerContext } from "../../context";
import ModelPerformance from "./components/ModelPerformance";
import MsrpDisByModelAndMonth from "./components/MsrpDisByModelAndMonth";
import MsrpSummary from "./components/MsrpSummary";
import MsrpTrends from "./components/MsrpTrends";
import OverTimeGraphs from "./components/OverTimeGraphs";
import TotalValueDisByMonth from "./components/TotalValueDisByMonth";

interface Props {
  id: string;
  handleClick: () => void;
}

const BackButton = (props: Props) => {
  const { id, handleClick } = props;
  return (
    <div id={id} onClick={handleClick} className="back_button" data-test-id={`${id}_back_button`}>
      &lt;
      <span className="back_text">Back</span>
    </div>
  );
};

const MsrpDashboard = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();

  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);

  const canAccessView = useSelector((state: RootStateOrAny) => state.page.can_access_view);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.msrp_last_data_refresh ?? "");

  const handleClickBackButton = () => {
    history.push({ pathname: "/purchase_funnel_report" });
  };

  useEffect(() => {
    Promise.all([dispatch(setPageName("")), dispatch(fetchMsrpLastDataRefresh())]);
  }, [dispatch]);

  useEffect(() => {
    Promise.all([
      dispatch(fetchMsrpSummary()),
      dispatch(fetchSalesByModel()),
      dispatch(fetchDifferenceByModel()),
      dispatch(fetchVmeDifferenceByModel()),
      dispatch(fetchMsrpVmeDifference()),
      dispatch(fetchMsrpProfileByMonth()),
      dispatch(fetchTotalValueDisByMonth()),
      dispatch(fetchDisByModelAndMonth()),
      dispatch(fetchTotalAddedValue()),
      dispatch(fetchTopFiveModels()),
    ]);
  }, [history.location.search]);

  useEffect(() => {
    if (!canAccessView) {
      setShowBanner(true);
      setBannerText(
        "MSRP report is not available for the selected market. If you would like have MSRP dashboard in your region please contact the CEDAR Team."
      );
    }
    return () => {
      setShowBanner(false);
    };
  }, [canAccessView]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<MsrpDashboardFilters />}
        dataDocumentation="msrp"
        lastDataRefresh={lastDataRefresh}
        pageTitle={
          <PageTitle dataDocumentation="msrp" backButton={<BackButton id={"msrp_back_button"} handleClick={handleClickBackButton} />} />
        }
      >
        <div className="msrp-page-container">
          <Collapsible title="MSRP Summary" show={true}>
            <MsrpSummary />
            <TotalValueDisByMonth />
          </Collapsible>
          <Collapsible title="MSRP Trends" show={true}>
            <MsrpTrends />
            <MsrpDisByModelAndMonth />
            <ModelPerformance />
            <OverTimeGraphs />
          </Collapsible>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default MsrpDashboard;
