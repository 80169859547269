import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { SOV_MIX_COLORS } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { legendConfigurationOCE, oceChartConfiguration } from "../helpers/oceConfigurations";
import {
  dateAxisLabelFormatter_MonthlyFY,
  dateAxisLabelFormatter_QuarterlyFY,
  dateAxisLabelFormatter_YearlyFY,
  dateChinaAxisLabelFormatter_QuarterlyFY,
  dateChinaAxisLabelFormatter_YearlyFY,
  formatChinaDateAxisLabelToQuarterlyFY,
  formatChinaDateAxisLabelToYearlyFY,
  formatDateAxisLabelToQuarterlyFY,
  formatDateAxisLabelToYearlyFY,
} from "../helpers/pmoConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  granularity: string;
  theme: ThemeInt;
  chartId: string;
  seriesList: { name: string; field: string; hide: boolean; colour?: string }[];
  percentageChart?: boolean;
  nissanCompetitor: string;
  market: string;
  diff: number;
}

class BrandPerformanceTrendChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chartId = this.props.chartId;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, granularity, theme, seriesList, percentageChart, nissanCompetitor, market, diff } = this.props;

    // Global chart configuration
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    this.chart.data = data;
    // Creates Legend
    this.chart.legend = legendConfigurationOCE(theme);
    this.chart.colors.list = SOV_MIX_COLORS.map((chartColor) => color(chartColor));
    this.chart.legend.marginBottom = 25;
    this.chart.legend.maxHeight = 100;
    this.chart.legend.scrollable = true;

    // Create data chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.cellStartLocation = 0.0;
    dateAxis.renderer.cellEndLocation = 0.0;
    dateAxis.renderer.minGridDistance = 45;
    // add logic to display this for more than one year monthly
    if ((diff > 360 && granularity == "monthly") || diff > 720) {
      dateAxis.renderer.labels.template.rotation = -90;
      dateAxis.renderer.labels.template.verticalCenter = "middle";
      dateAxis.renderer.labels.template.horizontalCenter = "left";
    }
    granularity === "monthly" && dateAxis.renderer.labels.template.adapter.add("text", dateAxisLabelFormatter_MonthlyFY);
    granularity === "quarterly" &&
      market !== "China" &&
      dateAxis.renderer.labels.template.adapter.add("text", dateAxisLabelFormatter_QuarterlyFY);
    granularity === "yearly" &&
      market !== "China" &&
      dateAxis.renderer.labels.template.adapter.add("text", dateAxisLabelFormatter_YearlyFY);

    granularity === "quarterly" &&
      market == "China" &&
      dateAxis.renderer.labels.template.adapter.add("text", dateChinaAxisLabelFormatter_QuarterlyFY);
    granularity === "yearly" &&
      market == "China" &&
      dateAxis.renderer.labels.template.adapter.add("text", dateChinaAxisLabelFormatter_YearlyFY);

    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    valueAxis.extraMax = 0.1;
    if (this.props.percentageChart) {
      // valueAxis.max = 100;
      valueAxis.extraMax = 0;
    }
    valueAxis.numberFormatter.numberFormat = "#.#a";
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + (percentageChart ? "%" : "");
    });

    seriesList?.forEach(({ name, hide, colour }, idx) => {
      const series = this.chart.series.push(new LineSeries());

      const bullet = lineSeriesConfiguration(series, {
        valueY: `${name}`,
        name: `${name}`,
      });
      nissanCompetitor == "all_competitors" ? (series.hidden = hide) : (series.hidden = false);
      series.xAxis = dateAxis;
      series.dataFields.dateX = "date";
      if (colour) {
        series.fill = color(colour);
        series.stroke = color(colour);
      }
      bullet.adapter.add("tooltipText", (text, target) => {
        const date = target?.dataItem?.dates?.dateX;
        if (granularity === "quarterly") {
          //@ts-ignore
          const dateVal = market == "China" ? formatChinaDateAxisLabelToQuarterlyFY(date) : formatDateAxisLabelToQuarterlyFY(date);
          text = `[bold; font-size: var(--regular_font_size);] ${dateVal} ${name}[/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###")}${
            percentageChart ? "%" : ""
          }`;
        } else if (granularity === "yearly") {
          //@ts-ignore
          const dateVal = market == "China" ? formatChinaDateAxisLabelToYearlyFY(date) : formatDateAxisLabelToYearlyFY(date);
          text = `[bold; font-size: var(--regular_font_size);] ${dateVal} ${name}[/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###")}${
            percentageChart ? "%" : ""
          }`;
        } else {
          text = `[bold; font-size: var(--regular_font_size);]{dateX.formatDate('MMM YYYY')} ${name} [/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###")}${
            percentageChart ? "%" : ""
          }`;
        }

        return text;
      });
    });
  }
  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
      </div>
    );
  }
}

export default BrandPerformanceTrendChart;
