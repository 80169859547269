import { put, retry, takeEvery } from "redux-saga/effects";
import { setMarketingChannelData } from "../actions";
import { FETCH_CHANNELS } from "../actions/actionTypes";
import { getMarketingChannelsData } from "../api";
import { secondsDelay } from "../api/utils";
import { IChannelData } from "../constants/interface";
// Watcher Sagas
export function* watchFetchChannels() {
  yield takeEvery(FETCH_CHANNELS, fetchChannels);
}

// Worker Sagas
export function* fetchChannels() {
  try {
    const data: IChannelData = yield retry(4, secondsDelay, getMarketingChannelsData);

    if (!("error" in data)) {
      yield put(setMarketingChannelData(data));
    }
  } catch (err) {
    console.log("Failed to get Marketing channels data");
  }
}
