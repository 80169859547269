import { ColumnSeries, DateAxis, LabelBullet, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component, ReactNode } from "react";
import WebFont from "webfontloader";
import { BLACK, CYAN, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { columnSeriesConfiguration, configureDateAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  theme: ThemeInt;
  isLoading: boolean;
  seriesList: Array<{ name: string; seriesColor: string }>;
  maxAmount: number;
  minAmount: number;
}

class CumulativeBarChart extends Component<Props> {
  chartId = "cumulativeBarChart";
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { theme, seriesList, data, maxAmount, minAmount } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    // this.chart.legend = legendConfiguration(theme);
    this.chart.colors.list = [color(CYAN), theme === "light" ? color(BLACK) : color(WHITE)];

    // Create data chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    dateAxis.renderer.cellStartLocation = 0;
    dateAxis.renderer.cellEndLocation = 0.55;
    dateAxis.renderer.minGridDistance = 20;
    configureDateAxis(dateAxis, theme);
    // 2nd date axis for comparison data
    const dateAxis2 = this.chart.xAxes.push(new DateAxis());
    dateAxis2.renderer.labels.template.disabled = true;
    dateAxis2.renderer.cellStartLocation = 0.45;
    dateAxis2.renderer.cellEndLocation = 1;
    configureDateAxis(dateAxis2, theme);
    dateAxis2.renderer.labels.template.strokeWidth = 0;
    dateAxis2.renderer.labels.template.fillOpacity = 0;
    dateAxis2.renderer.minGridDistance = 20;
    dateAxis.renderer.labels.template.adapter.add("text", function (text) {
      return `Q${text?.charAt(text?.length - 1)}`;
    });
    dateAxis.renderer.labels.template.fontSize = "13px";
    dateAxis2.renderer.labels.template.fontSize = "13px";

    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.extraMax = 0.12;
    valueAxis.min = minAmount < 0 ? minAmount : 0;
    if (minAmount >= 0) valueAxis.min = 0;
    if (maxAmount <= 0) {
      valueAxis.max = 0;
      valueAxis.extraMax = 0;
    }
    valueAxis.numberFormatter.numberFormat = "#.0";
    valueAxis.renderer.labels.template.fontSize = "13px";
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.numberFormatter.numberFormat = "#.0a";

    function createGrid(value: number) {
      const range = valueAxis.axisRanges.create();
      range.value = value;
      range.label.text = "{value}";
    }

    createGrid(0);
    if (maxAmount > 0) {
      const numStr = maxAmount.toFixed(0).toString();
      const newNum =
        Number(numStr.charAt(1)) >= 5
          ? Number(
              Number(numStr.substring(0, 1) + numStr.slice(1).replace(/[0-9]/g, "0")) + Number(1 + numStr.slice(1).replace(/[0-9]/g, "0"))
            )
          : Number(
              Number(numStr.substring(0, 2) + numStr.slice(2).replace(/[0-9]/g, "0")) + Number(1 + numStr.slice(2).replace(/[0-9]/g, "0"))
            );

      if (minAmount > 0) {
        createGrid(newNum / 2);
      } else {
        createGrid(minAmount);
      }
      createGrid(newNum);
    } else {
      createGrid(minAmount);
    }

    seriesList?.forEach(({ name, seriesColor }, idx: number) => {
      const series1 = this.chart.series.push(new ColumnSeries());
      series1.data = data;

      columnSeriesConfiguration(series1, {
        valueY: `${name}_amount`,
        name: name.replace(new RegExp("_", "g"), " "),
      });
      series1.xAxis = idx == 0 ? dateAxis : dateAxis2;
      series1.dataFields.dateX = "quarter";
      series1.fill = color(seriesColor);
      series1.stroke = color(seriesColor);
      series1.dataFields.categoryX = "quarter";
      series1.yAxis = valueAxis;
      series1.zIndex = idx;
      const labelBullet = series1.bullets.push(new LabelBullet());
      labelBullet.label.text = `{valueY.formatNumber("#.0a")}`;
      labelBullet.label.fill = color(this.props.theme === "light" ? BLACK : WHITE);
      labelBullet.label.truncate = false;
      labelBullet.label.adapter.add("verticalCenter", () => "bottom");
      labelBullet.label.fontSize = "12px";
    });
  }

  render(): ReactNode {
    return (
      <div className={"chartWrapper"}>
        <div id={"cumulativeBarChart"} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default CumulativeBarChart;
