import moment from "moment";
import { PMO_DATE_FILTER_OPTIONS } from "../constants";
import { fetchDataV2, newApiFetchV2 } from "./utils";

export const getPmoQueryParams = (excludeWeekly?: boolean) => {
  const parameters = new URLSearchParams(window.location.search);

  const dateRangeParam = parameters.get("date_range");
  const marketParam = parameters.get("market")?.split(",") ?? [];
  const modelParam = parameters.get("model")?.split(",") ?? [];
  const brandParam = parameters.get("brand");
  const granularityParam = parameters.get("granularity");

  const date = dateRangeParam
    ? PMO_DATE_FILTER_OPTIONS.includes(dateRangeParam)
      ? dateRangeParam.split(" ").join("_").toLocaleLowerCase()
      : "custom"
    : "";

  const pmoParams: Record<string, string | null | Array<string>> = {
    date_range: date,
    brand: brandParam,
    market: marketParam,
    model: modelParam,
    granularity: excludeWeekly && granularityParam === "weekly" ? "monthly" : granularityParam,
  };

  if (date == "custom") {
    pmoParams["start_date"] = dateRangeParam
      ? moment(dateRangeParam.split("-")[0].trim(), "MMMM YYYY").startOf("month").format("YYYY-MM-DD")
      : "";
    pmoParams["end_date"] = dateRangeParam
      ? moment(dateRangeParam.split("-")[1].trim(), "MMMM YYYY").endOf("month").format("YYYY-MM-DD")
      : "";
  }

  return pmoParams;
};

export const pmoParamsExcludeGranularity = () => {
  const paramObj = getPmoQueryParams();
  delete paramObj["granularity"];
  return paramObj;
};

export const pmoParamsExcludeAverageModels = () => {
  const paramObj = getPmoQueryParams();
  delete paramObj["average_models"];
  return paramObj;
};

export const pmoParamsIncludeAvgModels = () => {
  const parameters = new URLSearchParams(window.location.search);
  const paramObj = Object.fromEntries(parameters.entries());
  const pmoParams = getPmoQueryParams();

  if (paramObj?.average_models.length === 0) {
    return { ...pmoParams, average_models: [] };
  }
  const avgModelsParam = JSON.parse(paramObj?.average_models);
  return { ...pmoParams, average_models: avgModelsParam };
};

export const pmoParamsIncludeGrades = () => {
  const parameters = new URLSearchParams(window.location.search);
  const paramObj = Object.fromEntries(parameters.entries());
  const pmoParams = getPmoQueryParams();

  if (paramObj?.grade.length === 0) {
    return { ...pmoParams, grades: [] };
  }
  const gradeParam = parameters.get("grade")?.split(",") ?? [];
  return { ...pmoParams, grades: gradeParam };
};
export const getMarketingActivityData = () =>
  newApiFetchV2(`/product-marketing-optimization/get-marketing-activity-data?use_cache=false`, {
    ...getPmoQueryParams(),
  });

export const getCustomerIntentionVsSalesData = () =>
  newApiFetchV2(`/product-marketing-optimization/get-customer-intention-vs-sales-data?use_cache=true`, { ...getPmoQueryParams() });

export const getPriceVolumeSalesData = (priceType: string) =>
  newApiFetchV2(`product-marketing-optimization/get-price-volume-data?use_cache=true&price_type=${priceType}`, pmoParamsIncludeAvgModels());

export const getProductMarketingOptimizationLastDataRefresh = () => {
  return fetchDataV2("/product-marketing-optimization/get-last-refresh-date?use_cache=true");
};

export const getSearchInterestVsFMIData = () =>
  newApiFetchV2(`/product-marketing-optimization/get-search-interest-vs-fmi-data?use_cache=true`, {
    ...getPmoQueryParams(),
  });

export const getSalesByTrendData = () =>
  newApiFetchV2(`/product-marketing-optimization/get-sales-by-trend-data?use_cache=true`, {
    ...getPmoQueryParams(),
  });

export const getSovData = (mediaGroups: string) =>
  newApiFetchV2(`/product-marketing-optimization/get-sov-data?use_cache=true${mediaGroups ? mediaGroups : ""}`, {
    ...getPmoQueryParams(),
  });

export const getPriceVolumeTrendData = () =>
  newApiFetchV2(`/product-marketing-optimization/get-price-volume-trend-data?use_cache=true`, {
    ...pmoParamsIncludeAvgModels(),
  });

export const getRegionalColorTrendData = () =>
  newApiFetchV2(`/product-marketing-optimization/get-regional-color-trend-data?chart=sales&use_cache=true`, {
    ...pmoParamsExcludeAverageModels(),
  });

export const getCustomerIntentionByColor = () =>
  newApiFetchV2(`/product-marketing-optimization/get-regional-color-trend-data?chart=customer-intention&use_cache=true`, {
    ...pmoParamsExcludeAverageModels(),
  });

export const getAverageModel = () =>
  newApiFetchV2(`/product-marketing-optimization/get-price-volume-models?use_cache=true`, {
    ...getPmoQueryParams(),
  });

export const getCustomerIntentionsVsSalePower = () =>
  newApiFetchV2(`/product-marketing-optimization/get-ev-ice-customer-intention-data?use_cache=false`, {
    ...getPmoQueryParams(),
  });
export const getNimSalesByTrend = () =>
  newApiFetchV2(`/product-marketing-optimization/get-nim-sales-by-trend-data?use_cache=true`, { ...pmoParamsIncludeGrades() });

export const getNimCustomerIntentionVsSalesTrend = () =>
  newApiFetchV2(`/product-marketing-optimization/get-nim-customer-intention-vs-sales-data?use_cache=true`, { ...pmoParamsIncludeGrades() });

export const getNimGrades = () =>
  newApiFetchV2(`/product-marketing-optimization/get-nim-grades?use_cache=true`, { ...getPmoQueryParams() });

export const getMediaSpendData = () =>
  newApiFetchV2(`/drivers-for-flash-performance/media-spending-chart?use_cache=true`, { ...getPmoQueryParams() });
export const getMediaSpendTotalsData = () =>
  newApiFetchV2(`/drivers-for-flash-performance/total-media-spending?use_cache=true`, { ...getPmoQueryParams() });
export const getVisitsTotalsData = () =>
  newApiFetchV2(`drivers-for-flash-performance/total-visits?use_cache=true`, { ...getPmoQueryParams() });
export const getLeadsFunnelData = () =>
  newApiFetchV2(`drivers-for-flash-performance/leads-funnel?use_cache=true`, { ...pmoParamsExcludeGranularity() });
export const getTotalLeadsData = () =>
  newApiFetchV2(`drivers-for-flash-performance/total-leads?use_cache=true`, { ...pmoParamsExcludeGranularity() });
export const getWebVisitsByChannelData = () =>
  newApiFetchV2(`drivers-for-flash-performance/web-visits-by-channel?use_cache=true`, { ...getPmoQueryParams() });
export const getLeadsToTestDriveRequestData = () =>
  newApiFetchV2(`drivers-for-flash-performance/lead-to-booking-ratio?use_cache=true`, { ...getPmoQueryParams() });
export const getTestDriveRequestData = () =>
  newApiFetchV2(`drivers-for-flash-performance/test-drive-booking-numbers?use_cache=true`, { ...getPmoQueryParams() });
export const getRetailSalesData = () =>
  newApiFetchV2(`drivers-for-flash-performance/retail-sales?use_cache=true`, { ...getPmoQueryParams(true) });

export const getGradeMixData = () =>
  newApiFetchV2(`drivers-for-flash-performance/grade-mix-chart?use_cache=true`, { ...getPmoQueryParams(true) });
