import { Box, Button, Dialog, TextField, Typography } from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, withStyles, WithStyles } from "@material-ui/styles";
import { format } from "date-fns";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { postDataV2 } from "../../../api/utils";
import { DASHBOARD_URLS } from "../../../constants";
import { Annotation } from "../../../pages/Admin/types";
import { convertParamsToQueryParams } from "../../../utils/annotationFunctions";

interface Props {
  open: boolean;
  handleToogleDialog: () => void;
  annotations: Annotation[] | undefined;
}

const styles = () =>
  createStyles({
    root: {
      margin: 0,
      padding: 15,
    },
    closeButton: {
      position: "absolute",
      right: 1,
      top: 6,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: 15,
    width: 500,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(() => ({
  root: {
    margin: 0,
    padding: 15,
  },
}))(MuiDialogActions);

const ShareAnnotationModal = (props: Props) => {
  const { open, handleToogleDialog, annotations } = props;
  const [email, setEmail] = useState<string>("");
  const [annotation, setAnnotation] = useState<Annotation | undefined>();
  const [message, setMessage] = useState<string>("");
  const [validEmail, setValidEmail] = useState(true);

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    emailValue.match(validRegex) ? setValidEmail(true) : setValidEmail(false);
    setEmail(emailValue);
  };

  const handleChangeAnnotation = (event: React.ChangeEvent<Record<string, unknown>>, newValue: Annotation | null) => {
    newValue && setAnnotation(newValue);
  };

  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const messageValue = event.target.value;
    setMessage(messageValue);
  };

  const handelCloseDialog = () => {
    handleToogleDialog();
    setEmail("");
    setAnnotation(undefined);
    setMessage("");
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    let annotationUrl = "";
    if (annotation) {
      const params = convertParamsToQueryParams(annotation);
      annotationUrl = `${window.location.origin}${DASHBOARD_URLS[annotation.dashboard]}?${new URLSearchParams(params).toString()}`;
    }
    const requestObj = {
      annotation_id: annotation?.id,
      receiver: email,
      redirect_url: encodeURI(annotationUrl),
      message: message,
    };
    postDataV2("/annotation/share-annotation", requestObj).then((response) => {
      if (response.error) {
        toast("Error sending annotation.");
      } else {
        handelCloseDialog();
      }
    });
    event.preventDefault();
  };

  return (
    <Dialog onClose={handelCloseDialog} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handelCloseDialog}>
        Share Annotation
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Box marginBottom={2}>
            <TextField label="Email" name="email" value={email} onChange={handleChangeEmail} variant="outlined" required fullWidth />
            {!validEmail && <span style={{ color: "red" }}>Invalid email</span>}
          </Box>
          <Box marginBottom={2}>
            <Autocomplete
              fullWidth
              value={annotation}
              options={annotations ?? []}
              onChange={handleChangeAnnotation}
              getOptionLabel={(option) => {
                //get annotation parameters
                const parameters = [
                  option.regions.map((region) => region.region),
                  option.markets.map((market) => market.market),
                  option.models.map((model) => model.model),
                  option.channels.map((channel) => channel.channel),
                  option.channel_breakdowns.map((channel_breakdown) => channel_breakdown.channel_breakdown),
                ]
                  .flat()
                  .filter((param) => param != "All");

                return `${format(new Date(option.start_date), "d LLL Y")}, ${option.insight} ${
                  parameters.length > 0 ? "(parameters: " + parameters.join(", ") + ")" : ""
                }`;
              }}
              renderInput={(params) => <TextField name="annotation" {...params} label="Annotation" variant="outlined" required />}
            />
          </Box>
          <Box>
            <TextField
              label="Message"
              name="message"
              value={message}
              onChange={handleChangeMessage}
              variant="outlined"
              minRows={10}
              fullWidth
              multiline
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button id="shareAnnotationBtn" autoFocus type="submit" disabled={!validEmail} color="primary">
            Share
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ShareAnnotationModal;
