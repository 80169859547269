import React from "react";
import { NoDataMsg } from "../../../../components/AppMessages";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { MtpCategoryTable } from "../../../../components/Tables";
import { QuarterMonths } from "./QuarterMonths";

interface Props {
  data: any;
  period: string;
  loading: boolean;
  quarterTitle: string;
  yrScenarios: string[];
  months: number[];
}

const QuarterBreakDown = (props: Props) => {
  const { data, period, months, yrScenarios, loading, quarterTitle } = props;
  return (
    <div className={`quarter_breakdown primary_background `}>
      {data?.length && !loading ? (
        <>
          <span className={`quarter_title`}>{quarterTitle}</span>
          {period == "FY Month" && <QuarterMonths quarter={quarterTitle} />}
          <MtpCategoryTable tableId={`mtpCategory${quarterTitle}`} tableData={data} keys={yrScenarios} months={months} />
        </>
      ) : loading ? (
        <LoadingEllipsis isLoading={loading} />
      ) : (
        <NoDataMsg message="No data available" />
      )}
    </div>
  );
};

export default QuarterBreakDown;
