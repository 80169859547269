import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import React, { Dispatch, SetStateAction } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { toast } from "react-toastify";
import { postDataV2 } from "../../../api/utils";
import { Tag } from "../../../constants/interface/admin/common";
import { FormFields } from "../Dialogs/AnnotationDialog";

interface Props {
  control: Control<FormFields>;
  tags: Tag[];
  setTags: Dispatch<SetStateAction<Array<Tag>>>;
  errors: FieldErrors | undefined;
}

interface TagOptionType extends Tag {
  newTag?: boolean;
  inputValue?: string;
}

const filter = createFilterOptions<TagOptionType>();

const TagsField = (props: Props) => {
  const { control, tags, setTags, errors } = props;

  const createTag = (data: TagOptionType) => {
    const requestObj = { tag: data.inputValue };
    return postDataV2("/annotation/tag/create", requestObj);
  };

  return (
    <Controller
      name="tags"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          multiple
          options={tags}
          disableCloseOnSelect
          getOptionLabel={(option: Tag) => option.tag}
          getOptionSelected={(option, value) => option.tag == value.tag}
          value={value}
          onChange={(_, data) => {
            // @ts-ignore
            if (data.at(-1) && data.at(-1).newTag) {
              const newTag = data.pop();
              newTag &&
                createTag(newTag)
                  .then((res) => {
                    setTags((prevState) => [...prevState, res]);
                    onChange([...data, res]);
                  })
                  .catch((err) => {
                    toast("Error creating tag.");
                    console.log(err);
                  });
            } else {
              onChange(data);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (filtered.length == 0 && params.inputValue != "") {
              filtered.push({
                id: 3,
                tag: `Add "${params.inputValue}"`,
                inputValue: params.inputValue,
                newTag: true,
              });
            }

            return filtered;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Tag"
              placeholder="Tag"
              error={errors?.tags ? true : false}
              helperText={errors?.tags?.message}
            />
          )}
        />
      )}
      rules={{
        required: {
          value: true,
          message: "Please select a metric",
        },
      }}
    />
  );
};

export default TagsField;
