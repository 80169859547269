import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import MediaSpendingChart from "../../../components/Charts/DfpDashboard/MediaSpendingChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../components/Ratings/Ratings";
import { getCurrencyPMO } from "../../../constants";
import { ThemeContext } from "../../../context";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import { isNull, toLocaleInteger } from "../../../utils/utilityFunctions";

interface Props {
  currency: string;
  market: string;
}

const MediaSpendGraph = (props: Props) => {
  const themeContext = useContext(ThemeContext);
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const loading = useSelector((state: RootStateOrAny) => state.loading.media_spend);
  const data = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.media_spend);
  const { total_media_spending: totalMediaSpending, percentage_vs_plan: mediaSpendComparisonValue } = useSelector(
    (state: RootStateOrAny) => state.product_marketing_optimization.media_spend_totals
  );

  const marketParamValue = useSelector((state: RootStateOrAny) => state.parameters.market);

  const { currency } = props;

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const ref = useRef<any>(null);

  const hasOaoData = useMemo(() => {
    return data?.column_chart?.some((val: { oao: number }) => !isNull(val?.oao) && val?.oao > 0);
  }, [data]);
  const [headerTooltipVisible, setHeaderTooltipVisible] = useState<boolean>(false);
  const headerRef = useRef<any>(null);
  const market = useSelector((state: RootStateOrAny) => state.parameters.market);

  const currencyForMarket = getCurrencyPMO(market);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (headerTooltipVisible && headerRef.current != null && !headerRef.current.contains(e.target)) {
        setHeaderTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [headerTooltipVisible]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (tooltipVisible && ref.current != null && !ref.current.contains(e.target)) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [tooltipVisible]);

  return (
    <div className="media_spend">
      <div className="dfp_tile_header kpi-tile primary_background">
        <div className="title">
          <h3>Total media spending</h3>
          <Tooltip
            position="left"
            className="customer_intention_tooltip"
            html={
              <div ref={headerRef}>
                <p>This figure shows total actual media spend in the selected period</p>
                <br />
              </div>
            }
            trigger="click"
            animation="none"
            theme="dark"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            open={headerTooltipVisible}
            //@ts-ignore
            onShown={() => setHeaderTooltipVisible(!headerTooltipVisible)}
          >
            <span
              className="info_tooltip hide_on_mobile"
              onClick={(evt: React.MouseEvent<HTMLElement>) => {
                evt.stopPropagation();
                setHeaderTooltipVisible(!headerTooltipVisible);
              }}
            />
          </Tooltip>
        </div>
        <p className="period_comparison" id={`period_yoy_comparison`} data-test-id={`period_yoy_comparison`}>
          <p className="main_value">
            {totalMediaSpending && data?.column_chart?.length ? (
              <span className="main_value_number" id={`$yoy_value`} data-test-id={`yoy_value`}>
                {getCurrencyPMO(market)}
                {totalMediaSpending > 1000000 ? `${formatToThreeDigits(totalMediaSpending)}illion` : toLocaleInteger(totalMediaSpending)}
              </span>
            ) : (
              <span className="main_value_number" id={`$yoy_value`} data-test-id={`yoy_value`}>
                n/a
              </span>
            )}
          </p>
          {mediaSpendComparisonValue ? (
            <span className="comparison_value" id={`media_spend_yoy_comparison_value`} data-test-id={`media_spend_yoy_comparison_value`}>
              <Ratings
                value={!isNull(mediaSpendComparisonValue) ? mediaSpendComparisonValue : "n/a"}
                isPercentageValue={true}
                testValue={false}
              />
              <span className="comparison_value_number">
                {mediaSpendComparisonValue ? `${Math.abs(mediaSpendComparisonValue).toFixed(2)}%` : "n/a"}
              </span>
              vs plan
            </span>
          ) : null}
        </p>
      </div>
      <div className="main_content primary_background">
        <div className="dfp_tile_header">
          <div className="title">
            <h3>Media spending trend</h3>
            <Tooltip
              position="left"
              className="customer_intention_tooltip"
              html={
                <div ref={ref}>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Media spend</span> is a major component of total FMI that Nissan spends. Media
                    spend focuses on the creative development and promotion of models through media channels (internet, OOH, Print, RADIO,
                    TV, etc.).
                  </p>
                  <p>
                    <u>Source</u>: MIDAS
                  </p>
                  <br />
                  <p>
                    <span style={{ fontWeight: "bold" }}>OaO</span> stand for Overall Opinion. Data will only be displayed when quarterly is
                    the selected granularity.
                  </p>
                  <p>
                    <u>Source</u>: BIG3
                  </p>

                  <br />
                </div>
              }
              trigger="click"
              animation="none"
              theme="dark"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              open={tooltipVisible}
              //@ts-ignore
              onShown={() => setTooltipVisible(!tooltipVisible)}
            >
              <span
                className="info_tooltip hide_on_mobile"
                onClick={(evt: React.MouseEvent<HTMLElement>) => {
                  evt.stopPropagation();
                  setTooltipVisible(!tooltipVisible);
                }}
              />
            </Tooltip>
          </div>
        </div>

        {data?.column_chart?.length ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <MediaSpendingChart
              data={data}
              theme={themeContext.theme}
              isLoading={loading}
              granularity={parameters.granularity}
              market={marketParamValue}
              hasOaoData={hasOaoData}
            />
          </ErrorBoundary>
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available" />
        )}
      </div>
    </div>
  );
};

export default MediaSpendGraph;
