import React, { useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import TotalValueDisByMonthChart from "../../../components/Charts/MSRP/TotalValueDisByMonthChart";
import { ThemeContext } from "../../../context";

const TotalValueDisByMonth = () => {
  const themeContext = useContext(ThemeContext);
  const { data, series } = useSelector((state: RootStateOrAny) => state.msrp.total_value_dis_by_month);
  const { total_value_dis_by_month: totalValueDisByMonthLoading } = useSelector((state: RootStateOrAny) => state.loading);
  return (
    <div className="full-width-row">
      <div className="msrp-tile">
        <div className="tile-header">
          Total Value DIS by Month
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            html={
              <div>
                <p>VME as value reflects the VME discount in Yen or USD.</p>
                <p>MSRP added value represents the value generated by selling online vs selling offline.</p>
                <p>DIS volume represents the number of cars sold.</p>
              </div>
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <TotalValueDisByMonthChart
          data={data}
          seriesList={series}
          theme={themeContext.theme}
          isLoading={totalValueDisByMonthLoading}
          chartName="totalValueDisByMonthChart"
        />
      </div>
    </div>
  );
};

export default TotalValueDisByMonth;
