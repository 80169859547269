import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { ErrorMsg } from "../../../../components/AppMessages";
import CustomerJourneyBarChart from "../../../../components/Charts/JourneyExcellence/CustomerJourneyBarChart";
import JourneyExcellenceLineTrendChart from "../../../../components/Charts/JourneyExcellence/JourneyExcellenceLineTrendChart";
import JourneyConversionTable from "../../../../components/Tables/JourneyConversionTable";
import { ToggleSwitch } from "../../../../components/Toogle";
import { ThemeContext } from "../../../../context";

const JourneyConversion = () => {
  const themeContext = useContext(ThemeContext);

  const customerJourneyData = useSelector((state: RootStateOrAny) => state.journey_excellence.customer_journey);

  const customerJourneyLoading = useSelector((state: RootStateOrAny) => state.loading.customer_journey);
  const conversionData = useSelector((state: RootStateOrAny) => state.journey_excellence.conversion);
  const conversionLoading = useSelector((state: RootStateOrAny) => state.loading.conversion);
  const { series: conversionTrendSeries, data: conversionTrendData } = useSelector(
    (state: RootStateOrAny) => state.journey_excellence.conversion_trend
  );
  const conversionTrendLoading = useSelector((state: RootStateOrAny) => state.loading.conversion_trend);

  const [journeyConversionToggle, setJourneyConversionToggle] = useState(true);

  const tableData = useMemo(() => {
    if (conversionData) {
      if (journeyConversionToggle) {
        return conversionData.filter(({ toggle }: Record<string, any>) => toggle == "standard" || toggle == "all");
      }
      return conversionData.filter(({ toggle }: Record<string, any>) => toggle == "funnel" || toggle == "all");
    }
    return [];
  }, [journeyConversionToggle, conversionData]);

  const trendSeries = useMemo(() => {
    if (conversionTrendSeries) {
      if (journeyConversionToggle) {
        return conversionTrendSeries.filter(({ toggle }: Record<string, any>) => toggle == "standard" || toggle == "all");
      }
      return conversionTrendSeries.filter(({ toggle }: Record<string, any>) => toggle == "funnel" || toggle == "all");
    }
    return [];
  }, [journeyConversionToggle, conversionTrendSeries]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="journey-conversion-toggle">
        <ToggleSwitch
          activeToggleLabel={"Standard reporting"}
          inactiveToggleLabel={"Funnel reporting"}
          active={journeyConversionToggle}
          handleToggleClick={() => setJourneyConversionToggle((prevState) => !prevState)}
          toggleClassName={"filter_toggle"}
        />
      </div>
      <div className="journey-conversion-row">
        <div className="customer-journey">
          <div className="tile-header">
            Customer Journey
            <Tooltip
              position="top"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              html={
                <ul>
                  <li>Website visits: Website visit happens every time the site has at least one page fully loaded.</li>
                  <li>
                    KBA visits: KBA or Key Buying Actions visits are the count of visits including at least one key buying action as Model
                    detail compare, Saved searches views, Compare tools views and Dealer search.
                  </li>
                  <li>
                    Leads visits: Lead visits are the count of visits including at least one digital lead as Enquire, Test drive, Request a
                    video and Reserve.
                  </li>
                  <li>Leads: Visitors who express interest (lead-type actions) and leave PII data</li>
                  <li>DIS: Number of confirmed new car sales that can be attributed to OEM managed digital activity</li>
                  <li>Website visits to KBA visits: Percentage of visits that have performed a KBA.</li>
                  <li>Website visits to Leads visits: Percentage of website visits that have performed a lead.</li>
                  <li>
                    Website visits to Leads: Conversion from website visits to leads that express interest (lead-type actions) and leave PII
                    data
                  </li>
                  <li>
                    Leads to DIS: Conversion from Visitors who express interest (lead-type actions) and leave PII data into car sales that
                    can be attributed to OEM managed digital activity
                  </li>
                </ul>
              }
              size={"small"}
            >
              <span className="info_tooltip" />
            </Tooltip>
          </div>
          <div className="tile-content">
            <CustomerJourneyBarChart
              theme={themeContext.theme}
              data={customerJourneyData}
              chartId="customerJourneyChart"
              isLoading={customerJourneyLoading}
            />
          </div>
        </div>
        <JourneyConversionTable data={tableData} isLoading={conversionLoading} />
        <div className="conversion-trend">
          <div className="tile-header">Conversion trend</div>
          <div className="main-content">
            <JourneyExcellenceLineTrendChart
              chartId="conversionTrendChart"
              seriesList={trendSeries}
              data={conversionTrendData}
              theme={themeContext.theme}
              isLoading={conversionTrendLoading}
              isPercentage={true}
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default JourneyConversion;
