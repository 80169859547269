import { CategoryAxis, ColumnSeries, LineSeries, ValueAxis, XYChart, XYCursor } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter, percent, Tooltip } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { CYAN, LIGHT_CYAN, RED } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import { columnSeriesConfiguration, configureCategoryAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  barSeriesName: string;
  barDataKey: string;
  lineSeriesName: string;
  lineDataKey: string;
  data: Array<{ title: string; value: number; color: string }>;
  theme: ThemeInt;
  region?: string;
}

class ValueCombinedChart extends Component<Props> {
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.props.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.props.chartId);
  }

  initChart() {
    const { data, barDataKey, chartId, barSeriesName, lineSeriesName, lineDataKey, theme, region } = this.props;

    this.chart = create(chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;

    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.colors.list = [color(CYAN), color(LIGHT_CYAN), color(RED)];

    // Creating Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "label";

    categoryAxis.cursorTooltipEnabled = false;
    const screenWidth = window.matchMedia("(min-width: 1920px)");

    categoryAxis.renderer.minGridDistance = screenWidth.matches ? 95 : 75;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.numberFormatter = new NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#a";
    valueAxis.tooltipPosition = "fixed";

    const percentageAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(percentageAxis, theme);
    percentageAxis.renderer.opposite = true;
    percentageAxis.min = 0;
    percentageAxis.strictMinMax = true;
    percentageAxis.renderer.labels.template.adapter.add("text", (text) => {
      return text + "%";
    });
    percentageAxis.cursorTooltipEnabled = false;
    percentageAxis.syncWithAxis = valueAxis;

    // Creating Series
    const series = this.chart.series.push(new ColumnSeries());
    columnSeriesConfiguration(series, {
      name: barSeriesName,
      valueY: barDataKey,
      yAxis: valueAxis,
    });
    series.dataFields.categoryX = "label";
    series.tooltipText = "{categoryX} {name}: {valueY.formatNumber('#,###.')}";
    series.clustered = false;
    series.columns.template.width = percent(50);
    if (series?.tooltip) series.tooltip.pointerOrientation = "right";

    const lineSeries = this.chart.series.push(new LineSeries());
    const bullet = lineSeriesConfiguration(lineSeries, {
      name: lineSeriesName,
      valueY: lineDataKey,
      yAxis: percentageAxis,
    });
    lineSeries.dataFields.categoryX = "label";
    lineSeries.strokeWidth = 2;
    lineSeries.tooltipText = "{categoryX} {name}: {valueY.formatNumber('#.')}%";
    if (lineSeries?.tooltip) lineSeries.tooltip.pointerOrientation = "right";

    bullet.tooltipText = "{categoryX}: {valueY.formatNumber('#.')}%";
    if (bullet?.tooltip) bullet.tooltip.pointerOrientation = "right";

    this.chart.cursor = new XYCursor();
    this.chart.cursor.lineX.disabled = true;
    this.chart.cursor.lineY.disabled = true;

    // Creating Series
    if (region === "North America" || region === "Japan") {
      const series2 = this.chart.series.push(new LineSeries());
      const bullet2 = lineSeriesConfiguration(series2, {
        name: "bp_enrollement_rate",
        valueY: "bpEnrollmentRate",
        yAxis: percentageAxis,
      });
      series2.dataFields.categoryX = "label";

      bullet2.tooltipText = "{categoryX}: {valueY.formatNumber('#.')}%";
    }

    if (region === "North America") {
      const series3 = this.chart.series.push(new LineSeries());
      const bullet3 = lineSeriesConfiguration(series3, {
        name: "bp_ppu_user_engagement",
        valueY: "bpPpuUserEngagement",
        yAxis: percentageAxis,
      });
      series3.dataFields.categoryX = "label";

      bullet3.tooltipText = "{categoryX}: {valueY.formatNumber('#.')}%";
      series3.stroke = color(RED);
      series3.fill = color(RED);
    }

    if (region === "Europe") {
      const series4 = this.chart.series.push(new LineSeries());
      const bullet4 = lineSeriesConfiguration(series4, {
        name: "Bp Target",
        valueY: "bpTarget",
        yAxis: percentageAxis,
      });
      series4.dataFields.categoryX = "label";

      bullet4.tooltip = new Tooltip();
      bullet4.tooltip.pointerOrientation = "down";
      bullet4.tooltipText = "{categoryX}: {valueY.formatNumber('#.')}%";
      series4.stroke = color(RED);
      series4.fill = color(RED);
    }
  }
  render() {
    return <div id={this.props.chartId} className={"metricChart"} />;
  }
}

export default ValueCombinedChart;
