import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg } from "../../../components/AppMessages";
import MetricComparisonsChart from "../../../components/Charts/DfpDashboard/MetricComparisons";
import VisitsByChannelChart from "../../../components/Charts/DfpDashboard/VisitsByChannelChart";
import { ToggleSwitch } from "../../../components/Toogle";
import { ThemeContext } from "../../../context";
import { isNull } from "../../../utils/utilityFunctions";
import MetricComparisonGraph from "./MetricComparisonGraph";

const MetricComparisons = () => {
  const themeContext = useContext(ThemeContext);
  const {
    visits_totals: visitsTotalLoading,
    visits_totals_by_channel: visitsTotalsByChannelLoading,
    lead_to_test_drive_request: leadToTestDriveRequestLoading,
    test_drive_request: testDriveRequestLoading,
    retail_sales: retailSalesLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);
  const { web_visits_by_channel, totals_visits, lead_to_test_drive_requests, test_drive_requests, retail_sales } = useSelector(
    (state: RootStateOrAny) => state.product_marketing_optimization
  );

  const parameters = useSelector((state: RootStateOrAny) => state.parameters);

  const { webVisitsCurrentTotal, kbaVisitsCurrentTotal, leadVisitsCurrentTotal, webVisitsYoYTotal, leadVisitsYoYTotal, kbaVisitsYoYTotal } =
    useMemo(() => {
      if (!isNull(totals_visits)) {
        const webVisitsCurrentTotal = totals_visits?.Current?.reduce((a: any, c: { visits: any }) => a + c.visits, 0);
        const kbaVisitsCurrentTotal = totals_visits?.Current?.reduce((a: any, c: { kbas: any }) => a + c.kbas, 0);
        const leadVisitsCurrentTotal = totals_visits?.Current?.reduce((a: any, c: { leads: any }) => a + c.leads, 0);
        const webVisitsYoYTotal = totals_visits?.YoY?.length ? totals_visits?.YoY[0]["perc_total_visits"] : null;
        const kbaVisitsYoYTotal = totals_visits?.YoY?.length ? totals_visits?.YoY[0]["perc_total_kbas"] : null;
        const leadVisitsYoYTotal = totals_visits?.YoY?.length ? totals_visits?.YoY[0]["perc_total_leads"] : null;
        return {
          webVisitsCurrentTotal,
          kbaVisitsCurrentTotal,
          leadVisitsCurrentTotal,
          webVisitsYoYTotal,
          leadVisitsYoYTotal,
          kbaVisitsYoYTotal,
        };
      }
      return {
        webVisitsCurrentTotal: null,
        kbaVisitsCurrentTotal: null,
        leadVisitsCurrentTotal: null,
        webVisitsYoYTotal: null,
        leadVisitsYoYTotal: null,
        kbaVisitsYoYTotal: null,
      };
    }, [totals_visits]);

  const { retailSalesCurrentTotal, retailSalesYoYTotal } = useMemo(() => {
    if (!isNull(retail_sales)) {
      const retailSalesCurrentTotal = retail_sales?.Current?.reduce((a: any, c: { volume_sold: any }) => a + c.volume_sold, 0);
      const retailSalesYoYTotal = retail_sales?.YoY?.length ? retail_sales?.YoY[0]["perc_total_volume_sold"] : null;
      return { retailSalesCurrentTotal, retailSalesYoYTotal };
    }
    return { retailSalesCurrentTotal: null, retailSalesYoYTotal: null };
  }, [retail_sales]);

  const { testDriveRequestCurrentTotal, testDriveRequestYoYTotal } = useMemo(() => {
    if (!isNull(test_drive_requests)) {
      const testDriveRequestCurrentTotal = test_drive_requests?.Current?.reduce(
        (a: any, c: { leads_book_a_test_drive: any }) => a + c.leads_book_a_test_drive,
        0
      );
      const testDriveRequestYoYTotal = test_drive_requests?.YoY?.length
        ? test_drive_requests?.YoY[0]["perc_total_leads_book_a_test_drive"]
        : null;
      return { testDriveRequestCurrentTotal, testDriveRequestYoYTotal };
    }
    return { testDriveRequestCurrentTotal: null, testDriveRequestYoYTotal: null };
  }, [test_drive_requests]);

  const { leadToTestDriveRequestCurrentTotal, leadToTestDriveRequestYoYTotal } = useMemo(() => {
    if (!isNull(lead_to_test_drive_requests)) {
      const leadToTestDriveRequestCurrentTotal = lead_to_test_drive_requests?.Current?.length
        ? lead_to_test_drive_requests?.Current[0]["total_ratio"]
        : null;
      const leadToTestDriveRequestYoYTotal = lead_to_test_drive_requests?.YoY?.length
        ? lead_to_test_drive_requests?.YoY[0]["pts_test_drive_to_leads"]
        : null;
      return {
        leadToTestDriveRequestCurrentTotal: leadToTestDriveRequestCurrentTotal,
        leadToTestDriveRequestYoYTotal: leadToTestDriveRequestYoYTotal,
      };
    }
    return { leadToTestDriveRequestCurrentTotal: null, leadToTestDriveRequestYoYTotal: null };
  }, [lead_to_test_drive_requests]);

  const channelSeriesList = useMemo(() => {
    if (web_visits_by_channel) {
      const services: Set<string> = new Set(web_visits_by_channel?.map((row: any) => row.channel));
      return Array.from(services)?.map((service) => {
        const key = service?.replace(/\s+/g, "_").toLowerCase();
        return { name: service, volumeField: `${key}_visits` };
      });
    }
    return [];
  }, [web_visits_by_channel]);

  const chartData = useMemo(() => {
    if (web_visits_by_channel?.length) {
      const res: any = [];

      web_visits_by_channel?.map((row: any) => {
        const key = row.channel?.replace(/\s+/g, "_").toLowerCase();
        const dates = Array.from(new Set(res.map((val: { date: string }) => val.date)));
        if (dates.includes(row.date)) {
          res?.map((val: { date: any }, idx: number) => {
            if (val.date === row.date) {
              const value: any = { ...val };
              value[`${key}_visits`] = row.visits;
              res.splice(idx, 1, value);
            }
          });
        } else {
          const result: any = { date: row.date };
          result[`${key}_visits`] = row.visits;
          result["quarter"] = row.quarter;
          res.push(result);
        }
      });
      return res;
    }
    return [];
  }, [web_visits_by_channel]);

  const [showTotalWebsiteVisits, setShowTotalWebsiteVisits] = useState<boolean>(true);

  return (
    <div className="metric_comparisons">
      <ErrorBoundary fallback={<ErrorMsg />}>
        <MetricComparisonGraph
          data={totals_visits}
          loading={visitsTotalLoading}
          totalValue={webVisitsCurrentTotal}
          comparisonValue={webVisitsYoYTotal}
          title="Website visits"
          showTotals={showTotalWebsiteVisits}
          ToggleSwitch={
            <ToggleSwitch
              activeToggleLabel="Total"
              inactiveToggleLabel="By Channel"
              active={showTotalWebsiteVisits}
              handleToggleClick={() => setShowTotalWebsiteVisits(!showTotalWebsiteVisits)}
            />
          }
          chart={
            showTotalWebsiteVisits ? (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <MetricComparisonsChart
                  granularity={parameters.granularity}
                  isLoading={visitsTotalLoading}
                  chartId={"websiteVisitsChart"}
                  dateValue={parameters.date_range}
                  data={totals_visits}
                  theme={themeContext.theme}
                  seriesList={["visits_yoy", "visits"]}
                />
              </ErrorBoundary>
            ) : (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <VisitsByChannelChart
                  granularity={parameters.granularity}
                  isLoading={visitsTotalsByChannelLoading}
                  data={chartData}
                  chartName="visitsByChannel"
                  theme={themeContext.theme}
                  seriesList={channelSeriesList}
                />
              </ErrorBoundary>
            )
          }
        />
      </ErrorBoundary>

      <ErrorBoundary fallback={<ErrorMsg />}>
        <MetricComparisonGraph
          loading={visitsTotalLoading}
          data={totals_visits}
          totalValue={kbaVisitsCurrentTotal}
          comparisonValue={kbaVisitsYoYTotal}
          title="KBA visits"
          chart={
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MetricComparisonsChart
                granularity={parameters.granularity}
                isLoading={visitsTotalLoading}
                chartId={"kbasVisitsChart"}
                dateValue={parameters.date_range}
                data={totals_visits}
                theme={themeContext.theme}
                seriesList={["kbas_yoy", "kbas"]}
              />
            </ErrorBoundary>
          }
        />
      </ErrorBoundary>

      <ErrorBoundary fallback={<ErrorMsg />}>
        <MetricComparisonGraph
          loading={visitsTotalLoading}
          data={totals_visits}
          totalValue={leadVisitsCurrentTotal}
          comparisonValue={leadVisitsYoYTotal}
          title="Lead visits"
          chart={
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MetricComparisonsChart
                granularity={parameters.granularity}
                isLoading={visitsTotalLoading}
                chartId={"leadsVisitsChart"}
                dateValue={parameters.date_range}
                data={totals_visits}
                theme={themeContext.theme}
                seriesList={["leads_yoy", "leads"]}
              />
            </ErrorBoundary>
          }
        />
      </ErrorBoundary>

      <ErrorBoundary fallback={<ErrorMsg />}>
        <MetricComparisonGraph
          ratio={true}
          data={lead_to_test_drive_requests}
          loading={leadToTestDriveRequestLoading}
          totalValue={leadToTestDriveRequestCurrentTotal}
          comparisonValue={leadToTestDriveRequestYoYTotal}
          title="Lead visits to test drive requests"
          chart={
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MetricComparisonsChart
                granularity={parameters.granularity}
                isLoading={leadToTestDriveRequestLoading}
                chartId={"leadsToBookingChart"}
                dateValue={parameters.date_range}
                data={lead_to_test_drive_requests}
                theme={themeContext.theme}
                seriesList={["ratio_yoy", "ratio"]}
              />
            </ErrorBoundary>
          }
        />
      </ErrorBoundary>

      <ErrorBoundary fallback={<ErrorMsg />}>
        <MetricComparisonGraph
          loading={testDriveRequestLoading}
          data={test_drive_requests}
          totalValue={testDriveRequestCurrentTotal}
          comparisonValue={testDriveRequestYoYTotal}
          title="Test drive requests"
          chart={
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MetricComparisonsChart
                granularity={parameters.granularity}
                isLoading={testDriveRequestLoading}
                chartId={"testDriveRequestChart"}
                dateValue={parameters.date_range}
                data={test_drive_requests}
                theme={themeContext.theme}
                seriesList={["leads_book_a_test_drive_yoy", "leads_book_a_test_drive"]}
              />
            </ErrorBoundary>
          }
        />
      </ErrorBoundary>

      <ErrorBoundary fallback={<ErrorMsg />}>
        <MetricComparisonGraph
          loading={retailSalesLoading}
          totalValue={retailSalesCurrentTotal}
          comparisonValue={retailSalesYoYTotal}
          title="Retail sales"
          data={retail_sales}
          chart={
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MetricComparisonsChart
                granularity={parameters.granularity}
                isLoading={retailSalesLoading}
                chartId={"retailSalesChart"}
                dateValue={parameters.date_range}
                data={retail_sales}
                theme={themeContext.theme}
                seriesList={["volume_sold_yoy", "volume_sold"]}
              />
            </ErrorBoundary>
          }
        />
      </ErrorBoundary>
    </div>
  );
};

export default MetricComparisons;
