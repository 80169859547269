import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { OCE_CHART_COLORS, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { legendConfigurationOCE, oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  seriesList: Array<Record<string, string>>;
  data: Array<Record<string, number | string>>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
}

class OrdersChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { seriesList, data, theme, chartId } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    this.chart.data = data;

    // Creates Legend
    this.chart.legend = legendConfigurationOCE(theme);
    this.chart.colors.list = OCE_CHART_COLORS.map((chartColor) => color(chartColor));

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    // Create data chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.cellStartLocation = 0.5;
    dateAxis.renderer.cellEndLocation = 0.8;

    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    valueAxis.extraMax = 0.1;

    seriesList?.forEach(({ name, field }) => {
      const series = this.chart.series.push(new LineSeries());
      series.xAxis = dateAxis;
      series.dataFields.dateX = "date";
      if (series.tooltip) {
        series.tooltip.autoTextColor = false;
        series.tooltip.label.fill = color(WHITE);
      }
      const bullet = lineSeriesConfiguration(series, {
        valueY: field,
        name: name,
      });
      bullet.tooltipText = `[bold; font-size: var(--regular_font_size);]{dateX.formatDate('dd MMM YYYY')} [/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###")}`;
    });
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default OrdersChart;
