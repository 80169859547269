import React, { useEffect, useState } from "react";
import "../../../assets/styles/component/tableSearchFilter.scss";
import { useVisible } from "../../../hooks";
import { searchCriteriaOptions, SEARCH_TERM_MAP } from "../SEO/subs/seoConstants";

interface Props {
  handleSelectSeachCriteria: (value: string) => void;
  handlefilterValueChange: (value: string) => void;
}

const TableSearchFilter = (props: Props) => {
  const { handleSelectSeachCriteria, handlefilterValueChange } = props;
  const { wrapperRef, isVisible, setIsVisible } = useVisible(false);

  const [searchCriteria, setSearchCriteria] = useState<any>("CONTAINS");
  const [searchCriteriaInputValue, setSearchCriteriaInputValue] = useState<keyof typeof SEARCH_TERM_MAP>("Contains");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const selectedSearchCriteria = searchCriteriaOptions.filter(
      (searchCriteriaOption) => searchCriteriaOption.ranking === searchCriteria
    )[0];
    setSearchCriteriaInputValue(selectedSearchCriteria.option);
    handleSelectSeachCriteria(selectedSearchCriteria.ranking);
  }, [searchCriteria]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setFilter(value);
    handlefilterValueChange(value);
  };

  return (
    <div className="search_container" data-test-id="impressions_search_container">
      <div className="search_criteria " data-test-id="impressions_search_criteria_div">
        <div className="filter no-border" ref={wrapperRef}>
          <label className="filter_header">SEARCH QUERY</label>
          <div className="input_arrow" onClick={() => setIsVisible(!isVisible)}>
            <span className="arrow_down" />
            <input className={"filter_input"} value={searchCriteriaInputValue} readOnly data-test-id="impressions_search_criteria_input" />
          </div>
          <ul className={`filter_menu ${isVisible ? "showOptions" : ""}`} data-test-id="impressions_search_criteria_ul">
            {searchCriteriaOptions.map((option) => {
              return (
                <li className="filter__option" key={Math.random()} onClick={() => setSearchCriteria(option.ranking)}>
                  {option.option}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="search_bar" data-test-id="impressions_search_bar_div">
        <div className="input_arrow">
          <input
            className={"filter_input"}
            value={filter}
            onChange={handleInputChange}
            placeholder="Search..."
            data-test-id="impressions_search_bar_input"
          />
          {!filter ? (
            <span className="search_bar_icon search" />
          ) : (
            <span
              className="search_bar_icon cancel"
              onClick={() => {
                setFilter("");
                handlefilterValueChange("");
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TableSearchFilter;
