import { AxisLabel, ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color } from "@amcharts/amcharts4/core";
import moment from "moment";
import { BIG_NUMBER_ABBREVIATION, BLACK, DARK_GREY, WHITE } from "../../../constants";
import {
  columnSeriesConfiguration,
  configureDateAxis,
  configureValueAxis,
  legendConfiguration,
  lineSeriesConfiguration,
} from "./configurations";
import { MtmSeriesConfigInt, ThemeInt } from "./types";

export const formatDateAxisLabelToQuarterly = (dateString: string) =>
  `Q${moment(dateString).fquarter().quarter} ${moment(dateString).fquarter().year.toString().substr(-2)}`;

export const formatDateAxisLabelToMonthly = (dateString: string) => moment(dateString, "YYYY-MM-DD").format("MMM");

export const dateAxisLabelFormatter_Quarterly = (text: string | undefined, axisLabel: AxisLabel): string | undefined => {
  const dataDate = axisLabel.dataItem.dates.date;

  return formatDateAxisLabelToQuarterly(dataDate as unknown as string) || text;
};

export const mtmLegendConfiguration = (tooltipText: string, theme: ThemeInt, setWidth = true) => {
  const legend = legendConfiguration(theme);

  legend.itemContainers.template.paddingTop = 2.5;
  legend.itemContainers.template.marginBottom = 25;

  legend.itemContainers.template.tooltipText = tooltipText;

  legend.labels.template.fontSize = "var(--form_font)";

  legend.markers.template.width = 12;
  legend.markers.template.height = 12;

  return legend;
};

export const configureMTMValueAxis = (valueAxis: ValueAxis, theme: ThemeInt) => {
  configureValueAxis(valueAxis, theme);
  valueAxis.renderer.grid.template.stroke = color(DARK_GREY);
  valueAxis.cursorTooltipEnabled = false;
  valueAxis.min = 0.5;
  valueAxis.numberFormatter.numberFormat = "#,###.#a";
  valueAxis.renderer.grid.template.strokeOpacity = 1;
  valueAxis.renderer.grid.template.strokeWidth = 1.5;
};

export const configureMTMDateAxis = (dateAxis: DateAxis, theme: ThemeInt, isQuarterly: boolean) => {
  configureDateAxis(dateAxis, theme);
  dateAxis.renderer.minGridDistance = 30;
  dateAxis.cursorTooltipEnabled = false;

  isQuarterly && dateAxis.renderer.labels.template.adapter.add("text", dateAxisLabelFormatter_Quarterly);
};

export const configureMTMColumnSeries = (columnSeries: ColumnSeries, options: MtmSeriesConfigInt) => {
  columnSeriesConfiguration(columnSeries, options);
  columnSeries.dataFields.dateX = "date";
  // @ts-ignore
  if (options.forecastAxis) columnSeries.dataFields.forecastY = options.forecastAxis;
  columnSeries.columns.template.tooltipText = "[bold]{dateX.formatDate('MMM YYYY')} [/]: {valueY.format('#.##a')}";
  columnSeries.cursorTooltipEnabled = false;
  columnSeries.stroke = color(options.color);
  columnSeries.fill = color(options.color);

  if (columnSeries.tooltip) {
    columnSeries.tooltip.getFillFromObject = false;
    columnSeries.tooltip.autoTextColor = false;
    columnSeries.tooltip.background.fill = color(WHITE);
    columnSeries.tooltip.label.fill = color(BLACK);
  }
};

export const configureMTMLineSeries = (lineSeries: LineSeries, options: MtmSeriesConfigInt) => {
  const bullet = lineSeriesConfiguration(lineSeries, options);
  lineSeries.dataFields.dateX = "date";
  lineSeries.fill = color(options.color);
  lineSeries.stroke = color(options.color);

  if (lineSeries.tooltip) {
    lineSeries.tooltip.getFillFromObject = false;
    lineSeries.tooltip.autoTextColor = false;
    lineSeries.tooltip.background.fill = color(WHITE);
    lineSeries.tooltip.label.fill = color(BLACK);
    lineSeries.tooltip.defaultState.transitionDuration = 0;
    lineSeries.tooltip.hiddenState.transitionDuration = 0;
  }
  bullet.tooltipText = "[bold]{dateX.formatDate('MMM YYYY')} [/]: {valueY.formatNumber('#.##')}";

  return bullet;
};

export const mtmChartConfiguration = (chart: XYChart) => {
  chart.zoomOutButton.disabled = true;
  chart.fontSize = "var(--form_font)";
  chart.paddingLeft = 0;
  chart.paddingBottom = 0;
  chart.fontFamily = "nissan";
  chart.numberFormatter.numberFormat = "#.##a";
  chart.numberFormatter.bigNumberPrefixes = BIG_NUMBER_ABBREVIATION;
};
