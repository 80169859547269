import { format, parse } from "date-fns";
import { ALL_OPTION_NO_SPACE, DEFAULT_DATE_RANGE, ONE_MARKET_REGIONS } from "../constants";
import { isCustomDate, isDigitalPerformanceDate } from "../utils/dateFunctions";
import { apiFetchV2, fetchDataV2, newApiFetch, newApiFetchV2, simpleFetch, simpleFetchV2 } from "./utils";

const NAE_MARKETS = [
  "Belgium",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Italy",
  "Kazakhstan",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Netherlands",
  "Norway",
  "Poland",
  "Russia",
  "Spain",
  "Sweden",
  "Ukraine",
  "United Kingdom",
  "Turkey",
];
const AMIO_MARKETS = [
  "Abu Dhabi",
  "Algeria",
  "Armenia",
  "Australia",
  "Bahrain",
  "Dubai",
  "Egypt",
  "India",
  "Iraq",
  "Jordan",
  "Kuwait",
  "Lebanon",
  " Morocco",
  "New Zealand",
  "Oman",
  "Qatar",
  "Saudi Arabia",
  "South Africa",
];

export const oceApiParamsToObject = (params: string) => {
  const parameters = new URLSearchParams(params);
  let paramObj = Object.fromEntries(parameters.entries());

  const dateParameter = parameters.get("date_range") as string;
  const regionParameter = parameters.get("region") as string;
  const validOceDate = dateParameter && (isDigitalPerformanceDate(dateParameter) || isCustomDate(dateParameter));

  if (!validOceDate) paramObj = { ...paramObj, date_range: DEFAULT_DATE_RANGE };
  if (regionParameter === "ASEAN/Japan") paramObj = { ...paramObj, region: "ASEAN" };
  if (regionParameter in ONE_MARKET_REGIONS) paramObj = { ...paramObj, market: ONE_MARKET_REGIONS[regionParameter] };

  return paramObj;
};

export const oceNewParameter = (params: string, excludeArr?: Array<string>) => {
  const paramObj = oceApiParamsToObject(params);

  const dates = paramObj.date_range.includes("/") ? "custom" : paramObj.date_range.split(" ").join("_").toLocaleLowerCase();
  const regionParam = /all/gi?.test(paramObj?.region) ? [] : paramObj?.region?.split(",");
  const index = regionParam?.indexOf("ASEAN/Japan");
  const modelParam = /all/gi?.test(paramObj?.model) ? [] : paramObj?.model?.split(",");
  const channelParam = /all/gi?.test(paramObj?.last_touch_marketing_channel) ? ["All"] : paramObj?.last_touch_marketing_channel?.split(",");
  const channelBreakdownParam = /all/gi?.test(paramObj?.last_touch_marketing_channel_breakdown)
    ? ["All"]
    : paramObj?.last_touch_marketing_channel_breakdown?.split(",");
  const marketParam =
    regionParam?.length === 1 && regionParam[0] === "NAE"
      ? NAE_MARKETS
      : regionParam?.length === 1 && regionParam[0] === "AMIO"
      ? AMIO_MARKETS
      : /all/gi?.test(paramObj?.market)
      ? []
      : paramObj?.market.split(",");
  if (regionParam && regionParam[0] === "NAE") regionParam.pop();
  if (regionParam && regionParam[0] === "AMIO") regionParam.pop();
  if (regionParam && index && index > -1) regionParam[index] = "ASEAN";

  const currentParams = Object.keys(paramObj);
  for (const val of currentParams) {
    if (/all/gi.test(paramObj[val])) paramObj[val] = ALL_OPTION_NO_SPACE;
  }
  delete paramObj?.model;
  delete paramObj?.region;

  delete paramObj.start_date;
  delete paramObj.end_date;

  if (excludeArr) {
    excludeArr.forEach((param) => {
      delete paramObj[param];
    });
  }

  if (dates == "custom") {
    paramObj["start_date"] = format(parse(paramObj.date_range.split("-")[0].trim(), "dd/MM/yyyy", new Date()), "y-MM-dd");
    paramObj["end_date"] = format(parse(paramObj.date_range.split("-")[1].trim(), "dd/MM/yyyy", new Date()), "y-MM-dd");
  }
  paramObj.date_range = dates;
  return {
    ...paramObj,
    market: marketParam,
    region: regionParam,
    model: modelParam,
    channel: channelParam,
    channel_breakdown: channelBreakdownParam,
  };
};

export function getLastDataRefresh(page: string) {
  page = page || "digital_performance";

  return simpleFetch(`/last_update_msg/${page}`);
}

export const getNewTrendBreakdown = (params: string) =>
  apiFetchV2("/digital-performance/get-trend-breakdown-table?use_cache=true", oceNewParameter(params, ["granularity"]));
export const getNewKeyIndicatorTotals = (params: string) =>
  apiFetchV2("/digital-performance/get-key-indicators?use_cache=true", oceNewParameter(params, ["granularity"]));
export const getVisitsTrend = (params: string) =>
  apiFetchV2("/digital-performance/get-trend-breakdown-graph?use_cache=true", oceNewParameter(params));
export const getVisitsTrendKbas = (params: string) =>
  apiFetchV2("/digital-performance/get-kba-breakdown-graph?use_cache=true", { ...oceNewParameter(params) });
export const getKbaTrendBreakdown = (params: string) =>
  newApiFetchV2("/digital-performance/get-kba-breakdown-table?use_cache=true", oceNewParameter(params, ["granularity"]));
export const getVisitsTrendLeads = (params: string) =>
  newApiFetchV2("/digital-performance/get-lead-breakdown-graph?use_cache=true", { ...oceNewParameter(params) });
export const getLeadsTrendBreakdown = (params: string) =>
  newApiFetchV2("/digital-performance/get-lead-breakdown-table?use_cache=true", oceNewParameter(params, ["granularity"]));
export const getMtmLastDataRefresh = () => newApiFetch("/mtm_api/last_updated", {});
export const getKbaLabelMapping = (params: string) => fetchDataV2("/digital-performance/get-kba-label-mapping?use_cache=true");
export const getLeadLabelMapping = (params: string) => fetchDataV2("/digital-performance/get-lead-label-mapping?use_cache=true");
export const getWebsiteVisits = (params: string) =>
  apiFetchV2("/digital-performance/get-website-visits?use_cache=false", oceNewParameter(params));

export const getOCELastDataRefresh = () => {
  return simpleFetchV2(`digital-performance/get-last-refresh-date`);
};
