import React, { Fragment, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { MultiSelectFilter } from ".";
import { setSpecificParameter } from "../../../actions";
import { FilterContext } from "../../../context";
import { closeFilterOptions, closeMarketOptions } from "../subs/helpers";

interface Props {
  filterValue: string;
  marketsList: string[];
  marketParamValue: string;
  marketGroupsList?: string[];
  parameterName: string;
}

export const UserEngagementMarketFilter = (props: Props) => {
  const dispatch = useDispatch();

  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const { filterValue, marketsList, marketGroupsList, marketParamValue, parameterName } = props;
  const handleMarketMultiselectSubmit = useCallback(() => {
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = marketsList?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    dispatch(setSpecificParameter(parameterName, filterValue));
  }, [marketsList]);

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;

    const filterName = dataset?.filter;
    const optionValue = dataset?.value;
    closeFilterOptions();
    if (filterName && optionValue) {
      dispatch(setSpecificParameter(parameterName, optionValue));
    }
  }, []);

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  return (
    <div className={"filter"} id="market_filter_div">
      <label className="filter_header" htmlFor="market_filter">
        Select Markets
      </label>
      <div className="input_arrow" onClick={showFilterOptionsFn} data-options-ul={`${parameterName}_ul`}>
        <input readOnly type="text" name="market" id="market_filter" className={"filter_input"} data-test-id="market" value={filterValue} />
        <span className="arrow_down " />
      </div>
      <ul className="filter_options" id={`${parameterName}_ul`} data-test-id="market_list">
        {marketsList?.length > 1 && (
          <Fragment>
            {marketGroupsList &&
              marketGroupsList?.map((marketGroup) => {
                return (
                  <li
                    className={`${parameterName}_group`}
                    data-filter={"market_group"}
                    data-value={marketGroup}
                    onClick={handleFilterOptionClick}
                    key={marketGroup}
                  >
                    {marketGroup}
                  </li>
                );
              })}
            <li className={"filter_divider market_group_divider"} />
            <MultiSelectFilter
              parentKey={parameterName}
              showOptionsOnly={true}
              // @ts-ignore
              filterList={marketsList}
              value={filterValue}
              parameterName={`${parameterName}`}
              parameterValue={marketParamValue}
              onShowFilterOptions={showFilterOptionsFn}
              handleFilterSubmission={handleMarketMultiselectSubmit}
            />
          </Fragment>
        )}
      </ul>
    </div>
  );
};
