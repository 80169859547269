// Todo: Add missing dates for last data refresh alert
import { Box, Button, Grid } from "@material-ui/core";
import { format, parse } from "date-fns";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { setLastDataRefresh } from "../../../actions";
import {
  fetchAriyaCancellations,
  fetchPreorderCancellationTrendData,
  fetchPreorderCancellationTrendData_Belgium,
  fetchPreorderCancellationTrendData_Canada,
  fetchPreorderCancellationTrendData_France,
  fetchPreorderCancellationTrendData_Germany,
  fetchPreorderCancellationTrendData_Italy,
  fetchPreorderCancellationTrendData_Last_4_Weeks,
  fetchPreorderCancellationTrendData_Last_4_Weeks_Norway,
  fetchPreorderCancellationTrendData_Netherlands,
  fetchPreorderCancellationTrendData_Norway,
  fetchPreorderCancellationTrendData_Sweden,
  fetchPreorderCancellationTrendData_UK,
  fetchPreorderCancellationTrendData_USA,
  fetchPreorderData,
  fetchPreorderData_Belgium,
  fetchPreorderData_Canada,
  fetchPreorderData_France,
  fetchPreorderData_Germany,
  fetchPreorderData_Italy,
  fetchPreorderData_Netherlands,
  fetchPreorderData_Sweden,
  fetchPreorderData_UK,
  fetchPreorderData_USA,
  fetchPreOrderDepositsChartData,
  fetchPreOrderDepositsData,
  fetchVisitsData,
} from "../../../api/Ariya";
import { DrilldownLayout } from "../../../components/Layouts/Ariya";
import { IChartData, IMetricTotal, IPreorderTableData } from "../../../constants/interface/ariya";
import { ThemeContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import commonStyles from "../ariya.module.scss";
import { ExperienceCard } from "../Experience Insight/components/Card";
import { getGraphData } from "../helpers/charts";
import { extractMonitorData, mergeGraphData, sortChartData, sumObjectsByKey } from "../helpers/dataFunctions";
import { MonitorCard } from "../Monitor/components/Card";
import { MonitorTrendline } from "../Monitor/components/Charts";
import { PreorderCard } from "./Cards/PreorderCard";
import styles from "./styles.module.scss";
import { PreordersByCity, TotalPreorder } from "./Tables";

export const PreorderInsight = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const themeContext = useContext(ThemeContext);

  const dispatch = useDispatch();
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.ariya_monitor);

  const [updatedPreorderTotal, setUpdatedPreorderTotal] = useState<number | null>(null);
  const [updatedLastDayPreorder, setUpdatedLastDayPreorder] = useState<number | null>(null);

  // Merged pre-order / deposits chart
  const [mergedPreOrderDepositsChartData, setMergedPreOrderDepositsChartData] = useState<Array<IChartData>>([]);

  //ASEAN/Japan chart data
  const [aseanTotalPreorders, setAseanTotalPreorders] = useState<IMetricTotal>({ current: null, previous: null });
  const [aseanTotalCancellations, setAseanTotalCancellations] = useState<IMetricTotal>({ current: null, previous: null });
  const [aseanPreordersChart, setAseanPreordersChart] = useState<Array<IChartData>>([]);
  const [aseanCancellationsChart, setAseanCancellationsChart] = useState<Array<IChartData>>([]);
  const [mergedAseanChartData, setMergedAseanChartData] = useState<Array<IChartData>>([]);

  // Americas Total
  const [americasPreorderTotal, setAmericasPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [americasTotalCancellations, setAmericasTotalCancellations] = useState<IMetricTotal>({ current: null, previous: null });

  const [usaPreorderTotal, setUsaPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [usaCancellationTotal, setUsaCancellationTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [usaPreorderChart, setUsaPreorderChart] = useState<Array<IChartData>>([]);
  const [usaCancellationChart, setUsaCancellationChart] = useState<Array<IChartData>>([]);
  const [mergedUsaChartData, setMergedUsaChartData] = useState<Array<IChartData>>([]);

  //Norway chart data
  const [norwayTotalPreorders, setNorwayTotalPreorders] = useState<IMetricTotal>({ current: null, previous: null });
  const [norwayTotalCancellations, setNorwayTotalCancellations] = useState<IMetricTotal>({ current: null, previous: null });
  const [norwayPreordersChart, setNorwayPreordersChart] = useState<Array<IChartData>>([]);
  const [norwayCancellationsChart, setNorwayCancellationsChart] = useState<Array<IChartData>>([]);

  // UK Total
  const [ukPreorderTotal, setUKPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [ukTotalCancellations, setUkTotalCancellations] = useState<IMetricTotal>({ current: null, previous: null });
  const [ukPreorderChart, setUKPreorderChart] = useState<Array<IChartData>>([]);
  const [ukCancellationChart, setUKCancellationChart] = useState<Array<IChartData>>([]);

  // Italy Total
  const [italyPreorderTotal, setItalyPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [italyTotalCancellations, setItalyTotalCancellations] = useState<IMetricTotal>({ current: null, previous: null });
  const [italyPreorderChart, setItalyPreorderChart] = useState<Array<IChartData>>([]);
  const [italyCancellationChart, setItalyCancellationChart] = useState<Array<IChartData>>([]);

  //Sweden TotalPreorder
  const [swedenPreorderTotal, setSwedenPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [swedenTotalCancellations, setSwedenTotalCancellations] = useState<IMetricTotal>({ current: null, previous: null });
  const [swedenPreorderChart, setSwedenPreorderChart] = useState<Array<IChartData>>([]);
  const [swedenCancellationChart, setSwedenCancellationChart] = useState<Array<IChartData>>([]);

  //Belgium TotalPreorder
  const [belgiumPreorderTotal, setBelgiumPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [belgiumTotalCancellations, setBelgiumTotalCancellations] = useState<IMetricTotal>({ current: null, previous: null });
  const [belgiumPreorderChart, setBelgiumPreorderChart] = useState<Array<IChartData>>([]);
  const [belgiumCancellationChart, setBelgiumCancellationChart] = useState<Array<IChartData>>([]);

  // Germany Total
  const [germanyPreorderTotal, setGermanyPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [germanyTotalCancellations, setGermanyTotalCancellations] = useState<IMetricTotal>({ current: null, previous: null });
  const [germanyPreorderChart, setGermanyPreorderChart] = useState<Array<IChartData>>([]);
  const [germanyCancellationChart, setGermanyCancellationChart] = useState<Array<IChartData>>([]);

  // Netherlands Total
  const [netherlandsPreorderTotal, setNetherlandsPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [netherlandsTotalCancellations, setNetherlandsTotalCancellations] = useState<IMetricTotal>({ current: null, previous: null });
  const [netherlandsPreorderChart, setNetherlandsPreorderChart] = useState<Array<IChartData>>([]);
  const [netherlandsCancellationChart, setNetherlandsCancellationChart] = useState<Array<IChartData>>([]);

  // Canada Total
  const [canadaPreorderTotal, setCanadaPreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [canadaTotalCancellations, setCanadaTotalCancellations] = useState<IMetricTotal>({ current: null, previous: null });
  const [canadaPreorderChart, setCanadaPreorderChart] = useState<Array<IChartData>>([]);
  const [canadaCancellationChart, setCanadaCancellationChart] = useState<Array<IChartData>>([]);

  //france total

  const [francePreorderTotal, setFrancePreorderTotal] = useState<IMetricTotal>({ current: null, previous: null });
  const [franceTotalCancellations, setFranceTotalCancellations] = useState<IMetricTotal>({ current: null, previous: null });
  const [francePreorderChart, setFrancePreorderChart] = useState<Array<IChartData>>([]);
  const [franceCancellationChart, setFranceCancellationChart] = useState<Array<IChartData>>([]);

  const [fileUpdatedDate, setFileUpdatedDate] = useState<string>("");

  const [amieoTotalPreorders, setAmieoTotalPreorders] = useState<IMetricTotal>({ current: null, previous: null });
  const [amieoTotalCancellations, setAmieoTotalCancellations] = useState<IMetricTotal>({ current: null, previous: null });
  const [mergedAmieoChartData, setMergedAmieoChartData] = useState<Array<IChartData>>([]);
  //Total online pre order table data
  const [preordersTableData, setPreordersTableData] = useState<Array<IPreorderTableData>>([]);

  const pageNavigation = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;

      history.push({ pathname: pageLink, search: "" });
    },
    [history]
  );

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Ariya", page: "Ariya Preorder" });
  }, []);

  // Last data refresh
  useEffect(() => {
    fetchVisitsData().then((response) => {
      if (response && !("error" in response)) {
        const {
          chart_data: { year, month, day },
        } = extractMonitorData(response);
        dispatch(setLastDataRefresh("ariya_monitor", `${year}-${month}-${day}`));
      }
    });
  }, []);

  // AMIEO Pre orders and cancellations data
  useEffect(() => {
    Promise.all([
      fetchPreorderData(["Norway"]).then((response) => {
        if (response && !("error" in response)) {
          const data = response.chart_data.data;
          const lastDay = data[data.length - 1].counts[0];
          const total = response.chart_data.totals[0];
          setNorwayTotalPreorders({ current: total, previous: lastDay });
          setNorwayPreordersChart(getGraphData(data));
        }
      }),
      fetchPreorderCancellationTrendData_Last_4_Weeks_Norway().then((response) => {
        if (response && !("error" in response)) {
          setNorwayCancellationsChart(getGraphData(response.report.data));
        }
      }),
      fetchPreorderCancellationTrendData_Norway().then((response) => {
        if (response && !("error" in response)) {
          const data = response.report.data;
          const lastDay = +data[data.length - 1].counts[0];
          const total = +response.report.totals[0];
          setNorwayTotalCancellations({ current: total, previous: lastDay });
        }
      }),
    ]);
  }, []);

  // Merge chart data
  useEffect(() => {
    const sortedTotalsData: Array<IChartData> = sortChartData([
      ...norwayPreordersChart,
      ...francePreorderChart,
      ...ukPreorderChart,
      ...swedenPreorderChart,
      ...belgiumPreorderChart,
      ...italyPreorderChart,
      ...germanyPreorderChart,
      ...netherlandsPreorderChart,
    ]);
    const sortedCancellationData: Array<IChartData> = sortChartData([
      ...norwayCancellationsChart,
      ...franceCancellationChart,
      ...ukCancellationChart,
      ...swedenCancellationChart,
      ...belgiumCancellationChart,
      ...italyCancellationChart,
      ...germanyCancellationChart,
      ...netherlandsCancellationChart,
    ]);
    const mergedTotalsData = sumObjectsByKey(sortedTotalsData);
    const mergedCancellationData = sumObjectsByKey(sortedCancellationData);

    //@ts-ignore
    setMergedAmieoChartData(mergeGraphData(mergedTotalsData, mergedCancellationData));
  }, [
    norwayPreordersChart,
    francePreorderChart,
    ukPreorderChart,
    swedenPreorderChart,
    belgiumPreorderChart,
    italyPreorderChart,
    germanyPreorderChart,
    netherlandsPreorderChart,
    norwayCancellationsChart,
    franceCancellationChart,
    ukCancellationChart,
    swedenCancellationChart,
    belgiumCancellationChart,
    italyCancellationChart,
    germanyCancellationChart,
    netherlandsCancellationChart,
  ]);

  // ASEAN Preorder deposits and cancellations
  useEffect(() => {
    Promise.all([
      fetchPreOrderDepositsData().then((response) => {
        if (response && !("error" in response)) {
          const {
            report: { data, totals },
          } = response;

          Promise.all([setAseanTotalPreorders({ current: +totals[0], previous: +data[data.length - 1].counts[0] })]);
        }
      }),
      fetchPreOrderDepositsChartData().then((response) => {
        if (response && !("error" in response)) {
          const {
            report: { data },
          } = response;

          Promise.all([setAseanPreordersChart(getGraphData(data))]);
        }
      }),

      fetchPreorderCancellationTrendData_Last_4_Weeks().then((response) => {
        if (response && !("error" in response)) {
          setAseanCancellationsChart(getGraphData(response.report.data));
        }
      }),
      fetchPreorderCancellationTrendData().then((response) => {
        if (response && !("error" in response)) {
          const data = response.report.data;
          const lastDay = +data[data.length - 1].counts[0];
          setAseanTotalCancellations((prevState) => ({ ...prevState, previous: lastDay }));
        }
      }),
      fetchAriyaCancellations().then((response) => {
        if (response && !("error" in response)) {
          setAseanTotalCancellations((prevState) => ({ ...prevState, current: +response.report.totals[0] }));
        }
      }),
    ]);
  }, []);

  // Merge chart data
  useEffect(() => {
    setMergedAseanChartData(mergeGraphData(aseanPreordersChart, aseanCancellationsChart));
  }, [aseanPreordersChart, aseanCancellationsChart]);

  // USA preorder deposits and cancellations
  useEffect(() => {
    Promise.all([
      fetchPreorderData_USA().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setUsaPreorderTotal({ current, previous }), setUsaPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderCancellationTrendData_USA().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const lastDay = +data[data.length - 1].counts[0];
          const total = +totals[0];
          setUsaCancellationChart(getGraphData(data));
          setUsaCancellationTotal({ current: total, previous: lastDay });
        }
      }),
    ]);
  }, []);

  // Merge chart data
  useEffect(() => {
    const sortedTotalsData: Array<IChartData> = sortChartData([...usaPreorderChart, ...canadaPreorderChart]);
    const sortedCancellationData: Array<IChartData> = sortChartData([...usaCancellationChart, ...canadaCancellationChart]);
    const mergedTotalsData = sumObjectsByKey(sortedTotalsData);
    const mergedCancellationData = sumObjectsByKey(sortedCancellationData);
    //@ts-ignore
    setMergedUsaChartData(mergeGraphData(mergedTotalsData, mergedCancellationData));
  }, [usaPreorderChart, usaCancellationChart, canadaPreorderChart, canadaCancellationChart]);

  // UK preorder deposits and cancellations
  useEffect(() => {
    Promise.all([
      fetchPreorderData_UK().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setUKPreorderTotal({ current, previous }), setUKPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderCancellationTrendData_UK().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const lastDay = +data[data.length - 1].counts[0];
          const total = totals[0];
          setUkTotalCancellations({ current: +total, previous: lastDay });
          setUKCancellationChart(getGraphData(data));
        }
      }),
    ]);
  }, []);

  // Sweden preorder deposits and cancellations
  useEffect(() => {
    Promise.all([
      fetchPreorderData_Sweden().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setSwedenPreorderTotal({ current, previous }), setSwedenPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderCancellationTrendData_Sweden().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const lastDay = +data[data.length - 1].counts[0];
          const total = totals[0];
          setSwedenTotalCancellations({ current: +total, previous: lastDay });
          setSwedenCancellationChart(getGraphData(data));
        }
      }),
    ]);
  }, []);

  // Belgium preorder deposits and cancellations
  useEffect(() => {
    Promise.all([
      fetchPreorderData_Belgium().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setBelgiumPreorderTotal({ current, previous }), setBelgiumPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderCancellationTrendData_Belgium().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const lastDay = +data[data.length - 1].counts[0];
          const total = totals[0];
          setBelgiumTotalCancellations({ current: +total, previous: lastDay });
          setBelgiumCancellationChart(getGraphData(data));
        }
      }),
    ]);
  }, []);

  // Italy preorder deposits and cancellations
  useEffect(() => {
    Promise.all([
      fetchPreorderData_Italy().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setItalyPreorderTotal({ current, previous }), setItalyPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderCancellationTrendData_Italy().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const lastDay = +data[data.length - 1].counts[0];
          const total = totals[0];
          setItalyTotalCancellations({ current: +total, previous: lastDay });
          setItalyCancellationChart(getGraphData(data));
        }
      }),
    ]);
  }, []);

  // Germany preorder deposits and cancellations
  useEffect(() => {
    Promise.all([
      fetchPreorderData_Germany().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setGermanyPreorderTotal({ current, previous }), setGermanyPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderCancellationTrendData_Germany().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const lastDay = +data[data.length - 1].counts[0];
          const total = totals[0];
          setGermanyTotalCancellations({ current: +total, previous: lastDay });
          setGermanyCancellationChart(getGraphData(data));
        }
      }),
    ]);
  }, []);

  useEffect(() => {
    Promise.all([
      fetchPreorderData_France().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setFrancePreorderTotal({ current, previous }), setFrancePreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderCancellationTrendData_France().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const lastDay = +data[data.length - 1].counts[0];
          const total = totals[0];
          setFranceTotalCancellations({ current: +total, previous: lastDay });
          setFranceCancellationChart(getGraphData(data));
        }
      }),
    ]);
  }, []);

  // Netherlands preorder deposits and cancellations
  useEffect(() => {
    Promise.all([
      fetchPreorderData_Netherlands().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setNetherlandsPreorderTotal({ current, previous }), setNetherlandsPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderCancellationTrendData_Netherlands().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const lastDay = +data[data.length - 1].counts[0];
          const total = totals[0];
          setNetherlandsTotalCancellations({ current: +total, previous: lastDay });
          setNetherlandsCancellationChart(getGraphData(data));
        }
      }),
    ]);
  }, []);

  // Canada preorder deposits and cancellations
  useEffect(() => {
    Promise.all([
      fetchPreorderData_Canada().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals, last_date_in_file } = response.report;
          setFileUpdatedDate(last_date_in_file);
          const current = +totals[0];
          const previous = +data[data.length - 1]?.counts[0];
          Promise.all([setCanadaPreorderTotal({ current, previous }), setCanadaPreorderChart(getGraphData(data))]);
        }
      }),
      fetchPreorderCancellationTrendData_Canada().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const lastDay = +data[data.length - 1].counts[0];
          const total = totals[0];
          setCanadaTotalCancellations({ current: +total, previous: lastDay });
          setCanadaCancellationChart(getGraphData(data));
        }
      }),
    ]);
  }, []);

  //Sum of online preorders with deposits and norway data
  useEffect(() => {
    const totalPreOrders =
      (norwayTotalPreorders.current ?? 0) +
      (aseanTotalPreorders.current ?? 0) +
      (usaPreorderTotal.current ?? 0) +
      (ukPreorderTotal.current ?? 0) +
      (swedenPreorderTotal.current ?? 0) +
      (belgiumPreorderTotal.current ?? 0) +
      (italyPreorderTotal.current ?? 0) +
      (germanyPreorderTotal.current ?? 0) +
      (francePreorderTotal.current ?? 0) +
      (netherlandsPreorderTotal.current ?? 0) +
      (canadaPreorderTotal.current ?? 0);

    setUpdatedPreorderTotal(totalPreOrders);

    const tableData = [
      {
        market: "Canada",
        total: canadaPreorderTotal.current,
        share: Math.round(((canadaPreorderTotal.current ?? 0) / totalPreOrders) * 100),
        pageNavigation: "/ariya/preorder/canada",
      },
      {
        market: "Japan",
        total: aseanTotalPreorders.current,
        share: Math.round(((aseanTotalPreorders.current ?? 0) / totalPreOrders) * 100),
        pageNavigation: "/ariya/preorder/japan",
      },

      {
        market: "United States",
        total: usaPreorderTotal.current,
        share: Math.round(((usaPreorderTotal.current ?? 0) / totalPreOrders) * 100),
        pageNavigation: "/ariya/preorder/united-states",
      },
      {
        market: "Norway",
        total: norwayTotalPreorders.current,
        share: Math.round(((norwayTotalPreorders.current ?? 0) / totalPreOrders) * 100),
        pageNavigation: "/ariya/preorder/norway",
      },
      {
        market: "United Kingdom",
        total: ukPreorderTotal.current,
        share: Math.round(((ukPreorderTotal.current ?? 0) / totalPreOrders) * 100),
        pageNavigation: "/ariya/preorder/united-kingdom",
      },
      {
        market: "Italy",
        total: italyPreorderTotal.current,
        share: Math.round(((italyPreorderTotal.current ?? 0) / totalPreOrders) * 100),
        pageNavigation: "/ariya/preorder/italy",
      },
      {
        market: "Sweden",
        total: swedenPreorderTotal.current,
        share: Math.round(((swedenPreorderTotal.current ?? 0) / totalPreOrders) * 100),
        pageNavigation: "/ariya/preorder/sweden",
      },
      {
        market: "Belgium",
        total: belgiumPreorderTotal.current,
        share: Math.round(((belgiumPreorderTotal.current ?? 0) / totalPreOrders) * 100),
        pageNavigation: "/ariya/preorder/belgium",
      },
      {
        market: "Germany",
        total: germanyPreorderTotal.current,
        share: Math.round(((germanyPreorderTotal.current ?? 0) / totalPreOrders) * 100),
        pageNavigation: "/ariya/preorder/germany",
      },
      {
        market: "Netherlands",
        total: netherlandsPreorderTotal.current,
        share: Math.round(((netherlandsPreorderTotal.current ?? 0) / totalPreOrders) * 100),
        pageNavigation: "/ariya/preorder/netherlands",
      },
      {
        market: "France",
        total: francePreorderTotal.current,
        share: Math.round(((francePreorderTotal.current ?? 0) / totalPreOrders) * 100),
        pageNavigation: "/ariya/preorder/france",
      },
    ];
    // @ts-ignore
    setPreordersTableData(tableData);
  }, [
    norwayTotalPreorders,
    aseanTotalPreorders,
    usaPreorderTotal,
    ukPreorderTotal,
    swedenPreorderTotal,
    belgiumPreorderTotal,
    italyPreorderTotal,
    germanyPreorderTotal,
    netherlandsPreorderTotal,
    francePreorderTotal,
  ]);

  // Merge preoder / deposits chart data
  useEffect(() => {
    const sortedChartData: Array<IChartData> = sortChartData([
      ...aseanPreordersChart,
      ...norwayPreordersChart,
      ...usaPreorderChart,
      ...ukPreorderChart,
      ...swedenPreorderChart,
      ...belgiumPreorderChart,
      ...italyPreorderChart,
      ...germanyPreorderChart,
      ...netherlandsPreorderChart,
      ...canadaPreorderChart,
      ...francePreorderChart,
    ]);

    const aggregatedChart: any = sumObjectsByKey(sortedChartData);

    const updatedAggregatedChartData = aggregatedChart?.map((value: IChartData) => {
      if (value === aggregatedChart[aggregatedChart.length - 1]) {
        return (value = { ...value, bulletRadius: 4, showBullet: true });
      } else {
        return (value = { ...value, bulletRadius: 2, showBullet: false });
      }
    });

    setUpdatedLastDayPreorder(aggregatedChart[aggregatedChart?.length - 1]?.value);
    setMergedPreOrderDepositsChartData(updatedAggregatedChartData);
  }, [
    aseanPreordersChart,
    norwayPreordersChart,
    francePreorderChart,
    usaPreorderChart,
    ukPreorderChart,
    swedenPreorderChart,
    belgiumPreorderChart,
    italyPreorderChart,
    germanyPreorderChart,
    netherlandsPreorderChart,
  ]);

  // Amieo Total sum
  useEffect(() => {
    // Updated Current
    setAmieoTotalPreorders({ current: null, previous: null });

    if (
      !!norwayTotalPreorders.current ||
      !!francePreorderTotal.current ||
      !!netherlandsPreorderTotal.current ||
      !!germanyPreorderTotal.current ||
      !!italyPreorderTotal.current ||
      !!swedenPreorderTotal.current ||
      !!belgiumPreorderTotal.current ||
      !!ukPreorderTotal.current
    ) {
      norwayTotalPreorders.current &&
        setAmieoTotalPreorders((prevState) => ({ ...prevState, current: (prevState.current ?? 0) + (norwayTotalPreorders.current ?? 0) }));
      francePreorderTotal.current &&
        setAmieoTotalPreorders((prevState) => ({ ...prevState, current: (prevState.current ?? 0) + (francePreorderTotal.current ?? 0) }));
      italyPreorderTotal.current &&
        setAmieoTotalPreorders((prevState) => ({ ...prevState, current: (prevState.current ?? 0) + (italyPreorderTotal.current ?? 0) }));
      swedenPreorderTotal.current &&
        setAmieoTotalPreorders((prevState) => ({ ...prevState, current: (prevState.current ?? 0) + (swedenPreorderTotal.current ?? 0) }));
      belgiumPreorderTotal.current &&
        setAmieoTotalPreorders((prevState) => ({ ...prevState, current: (prevState.current ?? 0) + (belgiumPreorderTotal.current ?? 0) }));

      germanyPreorderTotal.current &&
        setAmieoTotalPreorders((prevState) => ({ ...prevState, current: (prevState.current ?? 0) + (germanyPreorderTotal.current ?? 0) }));

      netherlandsPreorderTotal.current &&
        setAmieoTotalPreorders((prevState) => ({
          ...prevState,
          current: (prevState.current ?? 0) + (netherlandsPreorderTotal.current ?? 0),
        }));
      ukPreorderTotal.current &&
        setAmieoTotalPreorders((prevState) => ({ ...prevState, current: (prevState.current ?? 0) + (ukPreorderTotal.current ?? 0) }));
    }

    if (
      !!norwayTotalPreorders.previous ||
      !!francePreorderTotal.previous ||
      !!italyPreorderTotal.previous ||
      !!swedenPreorderTotal.previous ||
      !!belgiumPreorderTotal.previous ||
      !!germanyPreorderTotal.previous ||
      !!netherlandsPreorderTotal.previous ||
      !!ukPreorderTotal.previous
    ) {
      norwayTotalPreorders.previous &&
        setAmieoTotalPreorders((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (norwayTotalPreorders.previous ?? 0),
        }));
      francePreorderTotal.previous &&
        setAmieoTotalPreorders((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (francePreorderTotal.previous ?? 0),
        }));
      italyPreorderTotal.previous &&
        setAmieoTotalPreorders((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (italyPreorderTotal.previous ?? 0),
        }));
      swedenPreorderTotal.previous &&
        setAmieoTotalPreorders((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (swedenPreorderTotal.previous ?? 0),
        }));
      belgiumPreorderTotal.previous &&
        setAmieoTotalPreorders((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (belgiumPreorderTotal.previous ?? 0),
        }));
      germanyPreorderTotal.previous &&
        setAmieoTotalPreorders((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (germanyPreorderTotal.previous ?? 0),
        }));
      netherlandsPreorderTotal.previous &&
        setAmieoTotalPreorders((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (netherlandsPreorderTotal.previous ?? 0),
        }));
      ukPreorderTotal.previous &&
        setAmieoTotalPreorders((prevState) => ({ ...prevState, previous: (prevState.previous ?? 0) + (ukPreorderTotal.previous ?? 0) }));
    }
  }, [
    norwayTotalPreorders,
    francePreorderTotal,
    netherlandsPreorderTotal,
    ukPreorderTotal,
    swedenPreorderTotal,
    belgiumPreorderTotal,
    italyPreorderTotal,
    germanyPreorderTotal,
  ]);

  useEffect(() => {
    // Updated Current
    setAmieoTotalCancellations({ current: null, previous: null });

    if (
      !!norwayTotalCancellations.current ||
      !!franceTotalCancellations.current ||
      !!germanyTotalCancellations.current ||
      !!italyTotalCancellations.current ||
      !!swedenTotalCancellations.current ||
      !!belgiumTotalCancellations.current ||
      !!netherlandsTotalCancellations.current ||
      !!ukTotalCancellations.current
    ) {
      franceTotalCancellations.current &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          current: (prevState.current ?? 0) + (franceTotalCancellations.current ?? 0),
        }));
      germanyTotalCancellations.current &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          current: (prevState.current ?? 0) + (germanyTotalCancellations.current ?? 0),
        }));
      italyTotalCancellations.current &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          current: (prevState.current ?? 0) + (italyTotalCancellations.current ?? 0),
        }));
      swedenTotalCancellations.current &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          current: (prevState.current ?? 0) + (swedenTotalCancellations.current ?? 0),
        }));
      belgiumTotalCancellations.current &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          current: (prevState.current ?? 0) + (belgiumTotalCancellations.current ?? 0),
        }));
      netherlandsTotalCancellations.current &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          current: (prevState.current ?? 0) + (netherlandsTotalCancellations.current ?? 0),
        }));
      ukTotalCancellations.current &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          current: (prevState.current ?? 0) + (ukTotalCancellations.current ?? 0),
        }));
    }

    if (
      !!norwayTotalCancellations.previous ||
      !!franceTotalCancellations.previous ||
      !!germanyTotalCancellations.previous ||
      !!italyTotalCancellations.previous ||
      !!netherlandsTotalCancellations.previous ||
      !!swedenTotalCancellations.previous ||
      !!belgiumTotalCancellations.previous ||
      !!ukTotalCancellations.previous
    ) {
      norwayTotalCancellations.previous &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (norwayTotalCancellations.previous ?? 0),
        }));
      franceTotalCancellations.previous &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (franceTotalCancellations.previous ?? 0),
        }));
      italyTotalCancellations.previous &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (italyTotalCancellations.previous ?? 0),
        }));

      swedenTotalCancellations.previous &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (swedenTotalCancellations.previous ?? 0),
        }));
      belgiumTotalCancellations.previous &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (belgiumTotalCancellations.previous ?? 0),
        }));

      germanyTotalCancellations.previous &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (germanyTotalCancellations.previous ?? 0),
        }));

      netherlandsTotalCancellations.previous &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (netherlandsTotalCancellations.previous ?? 0),
        }));
      ukTotalCancellations.previous &&
        setAmieoTotalCancellations((prevState) => ({
          ...prevState,
          previous: (prevState.previous ?? 0) + (ukTotalCancellations.previous ?? 0),
        }));
    }
  }, [
    norwayTotalCancellations,
    franceTotalCancellations,
    netherlandsTotalCancellations,
    ukTotalCancellations,
    italyTotalCancellations,
    swedenTotalCancellations,
    belgiumTotalCancellations,
    germanyTotalCancellations,
  ]);

  useEffect(() => {
    const americasPerorderTotalCurrent = (usaPreorderTotal.current ?? 0) + (canadaPreorderTotal.current ?? 0);
    const americasPreorderTotalPrevious = (usaPreorderTotal.previous ?? 0) + (canadaPreorderTotal.previous ?? 0);
    const americasCancellationTotalCurrent = (usaCancellationTotal.current ?? 0) + (canadaTotalCancellations.current ?? 0);
    const americasCancellationTotalPrevious = (usaCancellationTotal.previous ?? 0) + (canadaTotalCancellations.previous ?? 0);
    setAmericasPreorderTotal({ current: americasPerorderTotalCurrent, previous: americasPreorderTotalPrevious });
    setAmericasTotalCancellations({ current: americasCancellationTotalCurrent, previous: americasCancellationTotalPrevious });
  }, [usaPreorderTotal, canadaPreorderTotal, usaCancellationTotal, canadaTotalCancellations]);

  return (
    <DrilldownLayout
      title="Online pre-order insights"
      lastDataRefresh={lastDataRefresh ? format(parse(lastDataRefresh, "yyyy-M-dd", new Date()), "Y-MM-dd") : ""}
      lastCanadaUpdate={fileUpdatedDate ? format(parse(fileUpdatedDate, "yyyy-MM-dd", new Date()), "Y-MM-dd") : ""}
      previousPagePath="/ariya/monitor"
    >
      <>
        <Box display={"grid"} gridGap={15} className={styles.cardContainer}>
          <PreorderCard
            id="version_colours_insights"
            content={[
              {
                id: "total_preorders",
                component: (
                  <MonitorCard
                    metric={{
                      title: "Total pre-orders",
                      lastDayValue: updatedLastDayPreorder,
                      value: updatedPreorderTotal,
                    }}
                    chart={
                      <MonitorTrendline
                        data={mergedPreOrderDepositsChartData}
                        chartId={"handRaisers"}
                        series={["handRaiser"]}
                        theme={themeContext.theme}
                      />
                    }
                    extraClass={styles.monitorCardReset}
                    isMarketSplit={true}
                    hideMore={true}
                  />
                ),
              },
              {
                id: "ASEAN_Japan_pre_orders",
                component: (
                  <MonitorCard
                    cardTitle={"ASEAN/Japan pre-orders"}
                    metric={{
                      title: "Online pre-orders",
                      lastDayValue: aseanTotalPreorders.previous,
                      value: aseanTotalPreorders.current,
                      legend: "solid",
                    }}
                    metric2={{
                      title: "Cancellations",
                      value: aseanTotalCancellations.current,
                      lastDayValue: aseanTotalCancellations.previous,
                      legend: "dotted",
                    }}
                    chart={
                      <MonitorTrendline
                        data={mergedAseanChartData}
                        chartId={"asean_chart"}
                        series={["preorder", "cancellation"]}
                        theme={themeContext.theme}
                      />
                    }
                    extraClass={styles.monitorCardReset}
                    isMarketSplit={true}
                    hideMore={true}
                  />
                ),
              },
              {
                id: "Americas_pre_orders",
                component: (
                  <MonitorCard
                    cardTitle={"Americas pre-orders"}
                    metric={{
                      title: "Online pre-orders",
                      lastDayValue: americasPreorderTotal.previous,
                      value: americasPreorderTotal.current,
                      legend: "solid",
                    }}
                    metric2={{
                      title: "Cancellations",
                      value: americasTotalCancellations.current,
                      lastDayValue: americasTotalCancellations.previous,
                      legend: "dotted",
                    }}
                    chart={
                      <MonitorTrendline
                        data={mergedUsaChartData}
                        chartId={"americas_chart"}
                        series={["preorder", "cancellation"]}
                        theme={themeContext.theme}
                      />
                    }
                    extraClass={styles.monitorCardReset}
                    isMarketSplit={true}
                    hideMore={true}
                  />
                ),
              },
              {
                id: "AMIEO_pre_orders",
                component: (
                  <MonitorCard
                    cardTitle={"AMIEO pre-orders"}
                    metric={{
                      title: "Online pre-orders",
                      lastDayValue: amieoTotalPreorders.previous,
                      value: amieoTotalPreorders.current,
                      legend: "solid",
                    }}
                    metric2={{
                      title: "Cancellations",
                      value: amieoTotalCancellations.current,
                      lastDayValue: amieoTotalCancellations.previous,
                      legend: "dotted",
                    }}
                    chart={
                      <MonitorTrendline
                        data={mergedAmieoChartData}
                        chartId={"amieo_chart"}
                        series={["preorder", "cancellation"]}
                        theme={themeContext.theme}
                      />
                    }
                    extraClass={styles.monitorCardReset}
                    isMarketSplit={true}
                    hideMore={true}
                  />
                ),
              },
            ]}
            className={styles.majorCard}
          />

          <ExperienceCard title="Pre-orders by markets" id="total_preorders" className={styles.totalHandraisers}>
            <>
              <TotalPreorder tableData={preordersTableData} pageNavigationFn={pageNavigation} />
            </>
          </ExperienceCard>

          <ExperienceCard title="Online pre-orders by city" id="preorders_city" className={styles.handraisersCity}>
            <PreordersByCity />
          </ExperienceCard>
        </Box>

        <Grid container spacing={10} justifyContent="space-evenly">
          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page={"/ariya/monitor"}
              data-test-id="ariyaHome"
            >
              Ariya home
            </Button>
          </Grid>

          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page={"/ariya/experience"}
              data-test-id="ariyaExperience"
            >
              Experience insights
            </Button>
          </Grid>

          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page={"/ariya/handraisers"}
              data-test-id="ariyaHandraisers"
            >
              Hand-raisers insights
            </Button>
          </Grid>
        </Grid>
      </>
    </DrilldownLayout>
  );
});
