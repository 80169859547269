import { ErrorBoundary } from "@sentry/react";
import React, { useEffect, useMemo, useState } from "react";
import "../../assets/styles/component/topUsedServices.scss";
import { ErrorMsg, NoDataMsg } from "../AppMessages";
import { CustomProgressBar } from "../Progress";
import { DefaultTable } from "./subs";

interface Props {
  data: Array<any>;
  keyAccessor: string;
  showRates: boolean;
  tableId: string;
  header: string;
  showMoreBtn?: boolean;
  isKeyNumeric?: boolean;
}

export const ActivationTable = (props: Props) => {
  const { data, keyAccessor, showRates, tableId, header, showMoreBtn, isKeyNumeric } = props;
  const [hiddenCols] = useState([""]);
  const [showHiddenRows, setShowHiddenRows] = useState(false);
  const [topFiveValues, setTopFiveValues] = useState<Array<any>>([]);

  useEffect(() => {
    if (data && data?.length > 0) {
      setTopFiveValues([...data].slice(0, 5));
    }
  }, [data]);

  const renderProgression = <T extends { value: number; row: { original: { bar_width: number; rate: number; volume: number } } }>(
    props: T
  ) => {
    return (
      <div className="value_container">
        <span className="updatable">
          {showRates
            ? props.row.original.rate < 999 && props.row.original.rate > -999
              ? `${props.row.original.rate}%`
              : "n/a"
            : props.row.original.volume}
        </span>
        <CustomProgressBar value={Number(props.row.original.rate) > 100 ? 100 : Number(props.row.original.rate)} />
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        id: keyAccessor,
        Header: header,
        accessor: keyAccessor,
        sortType: isKeyNumeric ? "alphanumericCustom" : "alphanumericIgnoreCase",
      },
      {
        id: "progression",
        Header: showRates ? "Activation ratio" : "Activation volume",
        accessor: "rate",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "compareNumbersActivationRatio",
        Cell: renderProgression,
      },
      {
        id: "totalWarrantyStart",
        Header: "Warranty start",
        accessor: "total_warranty_start",
        sortType: "alphanumericCustom",
      },
    ],
    [data, showRates]
  );

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <>
        {data?.length > 0 ? (
          <div className="tableWrapper activationTableWrapper">
            <div className="show_more_div">
              <a className="show_more_btn" onClick={() => setShowHiddenRows(!showHiddenRows)} data-test-id={`${tableId}_show_more_btn`}>
                {showMoreBtn && `Show ${showHiddenRows ? "less" : "more"}`}
              </a>
            </div>

            <DefaultTable
              columns={columns}
              data={showMoreBtn && !showHiddenRows ? topFiveValues : data}
              id={tableId}
              isChildComponent={false}
              initialSortColId={{ id: "progression", desc: true }}
              hiddenCols={hiddenCols}
            />
          </div>
        ) : (
          <NoDataMsg />
        )}
      </>
    </ErrorBoundary>
  );
};
