export const FETCH_LAST_DATA_REFRESH = "FETCH_LAST_DATA_REFRESH";
export const SET_LAST_DATA_REFRESH = "SET_LAST_DATA_REFRESH";

export const FETCH_MARKET_DATA_REFRESH = "FETCH_MARKET_DATA_REFRESH";
export const SET_MARKET_DATA_REFRESH = "SET_MARKET_DATA_REFRESH";

export const FETCH_ACTIVATION_DATA_REFRESH = "FETCH_ACTIVATION_DATA_REFRESH";

export const FETCH_MTM_LAST_DATA_REFRESH = "FETCH_MTM_LAST_DATA_REFRESH";

export const FETCH_SEO_LAST_DATA_REFRESH = "FETCH_SEO_LAST_DATA_REFRESH";

export const FETCH_PURCHASE_FUNNEL_LAST_DATA_REFRESH = "FETCH_PURCHASE_FUNNEL_LAST_DATA_REFRESH";
export const SET_PURCHASE_FUNNEL_LAST_DATA_REFRESH = "SET_PURCHASE_FUNNEL_LAST_DATA_REFRESH";

export const FETCH_LAST_DATA_REFRESH_CPO = "FETCH_LAST_DATA_REFRESH_CPO";
export const SET_LAST_DATA_REFRESH_CPO = "SET_LAST_DATA_REFRESH_CPO";

export const FETCH_PRODUCT_MARKETING_OPTIMIZATION_LAST_DATA_REFRESH = "FETCH_PRODUCT_MARKETING_OPTIMIZATION_LAST_DATA_REFRESH";

export const FETCH_OCE_LAST_REFRESH_DATE = "FETCH_OCE_LAST_REFRESH_DATE";
export const SET_OCE_LAST_REFRESH_DATE = "SET_OCE_LAST_REFRESH_DATE";

export const FETCH_MIDAS_FMI_LAST_REFRESH_DATE = "FETCH_MIDAS_FMI_LAST_REFRESH_DATE";
export const FETCH_MIDAS_FMI_BY_MODEL_LAST_REFRESH_DATE = "FETCH_MIDAS_FMI_BY_MODEL_LAST_REFRESH_DATE";
export const FETCH_MIDAS_MEDIA_MIX_LAST_REFRESH_DATE = "FETCH_MIDAS_MEDIA_MIX_LAST_REFRESH_DATE";
export const SET_MIDAS_FMI_LAST_REFRESH_DATE = "SET_MIDAS_FMI_LAST_REFRESH_DATE";
export const FETCH_MIDAS_MTP_CATEGORY_LAST_REFRESH_DATE = "FETCH_MIDAS_MTP_CATEGORY_LAST_REFRESH_DATE";
export const FETCH_MIDAS_STRATEGIC_DEALER_SUPPORT_LAST_REFRESH_DATE = "FETCH_MIDAS_STRATEGIC_DEALER_SUPPORT_LAST_REFRESH_DATE";
export const FETCH_MIDAS_STRATEGIC_CE_LAST_REFRESH_DATE = "FETCH_MIDAS_STRATEGIC_CE_LAST_REFRESH_DATE";
export const FETCH_MIDAS_FIXED_LAST_REFRESH_DATE = "FETCH_MIDAS_FIXED_LAST_REFRESH_DATE";
export const FETCH_MIDAS_SPEND_VS_TRAFFIC_LAST_REFRESH_DATE = "FETCH_MIDAS_SPEND_VS_TRAFFIC_LAST_REFRESH_DATE";

export const FETCH_CPO_OPERATIONAL_LAST_REFRESH_DATE = "FETCH_CPO_OPERATIONAL_LAST_REFRESH_DATE";
export const SET_CPO_OPERATIONAL_LAST_REFRESH_DATE = "SET_CPO_OPERATIONAL_LAST_REFRESH_DATE";

export const FETCH_PRODUCT_PERFORMANCE_LAST_REFRESH_DATE = "FETCH_PRODUCT_PERFORMANCE_LAST_REFRESH_DATE";
export const SET_PRODUCT_PERFORMANCE_LAST_REFRESH_DATE = "SET_PRODUCT_PERFORMANCE_LAST_REFRESH_DATE";

export const FETCH_ECOMMERCE_LAST_REFRESH_DATE = "FETCH_ECOMMERCE_LAST_REFRESH_DATE";
export const SET_ECOMMERCE_LAST_REFRESH_DATE = "SET_ECOMMERCE_LAST_REFRESH_DATE";

export const FETCH_MSRP_LAST_DATA_REFRESH = "FETCH_MSRP_LAST_DATA_REFRESH";
export const SET_MSRP_LAST_DATA_REFRESH = "SET_MSRP_LAST_DATA_REFRESH";

export const FETCH_JOURNEY_EXCELLENCE_LAST_DATA_REFRESH = "FETCH_JOURNEY_EXCELLENCE_LAST_DATA_REFRESH";
export const SET_JOURNEY_EXCELLENCE_LAST_DATA_REFRESH = "SET_JOURNEY_EXCELLENCE_LAST_DATA_REFRESH";

export const FETCH_VOC_LAST_DATA_REFRESH = "FETCH_VOC_LAST_DATA_REFRESH";
export const SET_VOC_LAST_DATA_REFRESH = "SET_VOC_LAST_DATA_REFRESH";

export const FETCH_USE_OWNERSHIP_LAST_DATA_REFRESH = "FETCH_USE_OWNERSHIP_LAST_DATA_REFRESH";
export const SET_USE_OWNERSHIP_LAST_DATA_REFRESH = "SET_USE_OWNERSHIP_LAST_DATA_REFRESH";

export const FETCH_BRAND_PERFORMANCE_LAST_DATA_REFRESH = "FETCH_BRAND_PERFORMANCE_LAST_DATA_REFRESH";
export const SET_BRAND_PERFORMANCE_LAST_DATA_REFRESH = "SET_BRAND_PERFORMANCE_LAST_DATA_REFRESH";

export const FETCH_EXECUTIVE_SUMMARY_LAST_DATA_REFRESH = "FETCH_EXECUTIVE_SUMMARY_LAST_DATA_REFRESH";
export const SET_EXECUTIVE_SUMMARY_LAST_DATA_REFRESH = "SET_EXECUTIVE_SUMMARY_LAST_DATA_REFRESH";
