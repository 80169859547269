import {
  SET_FLOP_SERVICES,
  SET_SERVICE_GROUPS,
  SET_TOP_FLOP_SERVICES,
  SET_TOP_MODELS,
  SET_TOP_REGIONS,
  SET_TOP_SERVICES,
} from "../actions/actionTypes";

const initialState = {
  services: [],
  top_services: [],
  flop_services: [],
  top_models: [],
  service_groups: [],
  top_regions: [],
};

const TopFlopReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_TOP_FLOP_SERVICES:
      return { ...state, services: action.payload };

    case SET_TOP_SERVICES:
      return { ...state, top_services: action.payload };

    case SET_FLOP_SERVICES:
      return { ...state, flop_services: action.payload };

    case SET_TOP_MODELS:
      return { ...state, top_models: action.payload };

    case SET_SERVICE_GROUPS:
      return { ...state, service_groups: action.payload };

    case SET_TOP_REGIONS:
      return { ...state, top_regions: action.payload };

    default:
      return state;
  }
};

export default TopFlopReducer;
