import React, { useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import InfluenceOfShoppingToolsChart from "../../../components/Charts/Ecommerce/InfluenceOfShoppingTools";
import { ThemeContext } from "../../../context";

const data = [
  {
    metric: "Financial Calculator",
    value: 1.5,
  },
  {
    metric: "Trade-in",
    value: 0.8,
  },
  {
    metric: "Finance application",
    value: 1.5,
  },
  {
    metric: "Credit pass",
    value: 1.2,
  },
  {
    metric: "Contract signed",
    value: 0.1,
  },
];

const InfluenceShoppingTools = () => {
  const themeContext = useContext(ThemeContext);

  const influenceOfShoppingTools = useSelector((state: RootStateOrAny) => state.ecommerce.influence_of_shopping_tools);
  const loading = useSelector((state: RootStateOrAny) => state.loading.influence_of_shopping_tools);

  return (
    <div className="ecommerce-tile">
      <div className="tile-header">
        <div className="header-title">
          Influence of shopping tools
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            title={"How many interacted with Tools converted into order"}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      <InfluenceOfShoppingToolsChart
        data={influenceOfShoppingTools}
        theme={themeContext.theme}
        chartId="InfluenceOfShoppingToolsChart"
        isLoading={loading}
      />
    </div>
  );
};

export default InfluenceShoppingTools;
