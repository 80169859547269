import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchUserGroupMarkets } from "../../actions";
import { getPowerBiReportToken } from "../../api";
import { PurchaseFunnelFilters } from "../../components/Filters";
import { IframeLayout } from "../../components/Layouts";
import { PowerBiContent } from "../../components/PageContent";
import { PURCHASE_FUNNEL_REPORTS_MAPPING } from "../../constants";
import { IPowerBiToken } from "../../constants/interface";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";

export const PurchaseFunnel = withRouter((props: RouteComponentProps): JSX.Element => {
  const [powerBiToken, setPowerBiToken] = useState<IPowerBiToken>({
    embed_token: "",
    embed_url: "",
    report_id: "",
    token_id: "",
    token_expiration: "",
    report_name: "",
  });
  const dispatch = useDispatch();
  const { history } = props;

  //Fetch markets
  useEffect(() => {
    dispatch(fetchUserGroupMarkets());
  }, [dispatch]);

  // Usage tracking
  useEffect(() => eventTracking(MixpanelEvents.page_view, { dashboard: "Purchase Funnel", page: "Purchase Funnel" }), [location.pathname]);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const marketParamValue = params.get("market");

    if (marketParamValue) {
      getPowerBiReportToken(PURCHASE_FUNNEL_REPORTS_MAPPING[marketParamValue]).then((token) => setPowerBiToken(token));
    }
  }, [history.location.search]);

  return (
    <IframeLayout hasFilter={true}>
      <PowerBiContent reportId="purchase_funnel" filter={<PurchaseFunnelFilters />} token={powerBiToken} showPagePane={false} />
    </IframeLayout>
  );
});
