import Cookies from "js-cookie";
import { IUserDetails } from "../constants/interface";
import { defaultApiInt, fetchData, getDataNoTokenV2, newApiFetch, updateDataV2 } from "./utils";

export const updateHomePageUrl = (data: Record<string, string>): defaultApiInt => newApiFetch("/update_default_url/", data);

export const updateUserDetails = (email: string, payload: IUserDetails): Promise<IUserDetails> =>
  updateDataV2(`/user_service/update-userdata?email=${email}`, payload);

export const fetchTokenCXOPage = (): defaultApiInt => newApiFetch("/cxo_experiment_token", {});

export const isValidParameters = (paramString: string): defaultApiInt => fetchData(`/digital_performance_filter_check${paramString}`);

export const fetchUserDetailsData = (email: string): defaultApiInt =>
  getDataNoTokenV2(`/user_service/get-current-user?email=${email}`, Cookies.get("access_token"));

export const requestDashboardAccess = (dashboard: string): defaultApiInt =>
  getDataNoTokenV2(`/user_service/request-dashboard-access?dashboard=${dashboard}`, Cookies.get("access_token"));
