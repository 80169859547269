import { ErrorBoundary } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { getBottomGrowthModels, getModelsVolumeBreakdown, getTopGrowthModels } from "../../../api";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import DISVolumeBreakdownChart from "../../../components/Charts/PurchaseFunnel/DISVolumeBreakdown";
import Collapsible from "../../../components/Collapsible/Collapsible";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { ToggleSwitch } from "../../../components/Toogle";
import { AVG_APP_RATINGS_COLORS } from "../../../constants";
import { ThemeContext } from "../../../context";
import DisByCarModelGraph from "../Components/DisByCarModelGraph";
import ModelPerformanceTable from "../Components/ModelPerformanceTable";

const DisByCarModel = withRouter((props: RouteComponentProps) => {
  const themeContext = useContext(ThemeContext);
  const { history } = props;

  const [showVolume, setShowVolume] = useState(true);
  const { data, model_list } = useSelector((state: RootStateOrAny) => state.purchase_funnel.volume_by_model);
  const loadingVolumeByModel = useSelector((state: RootStateOrAny) => state.loading.volume_by_model);

  const {
    data: topGrowthModels,
    isFetching: isFetchingTopGrowthModels,
    refetch: refetchTopGrowthModels,
  } = useQuery({
    queryKey: ["topGrowthModels"],
    queryFn: getTopGrowthModels,
    enabled: false,
    initialData: [],
  });

  const {
    data: bottomGrowthModels,
    isFetching: isFetchingBottomGrowthModels,
    refetch: refetchBottomGrowthModels,
  } = useQuery({
    queryKey: ["bottomGrowthModels"],
    queryFn: getBottomGrowthModels,
    enabled: false,
    initialData: [],
  });

  const {
    data: { series: modelsVolumeBreakdownSeries, data: modelsVolumeBreakdownData },
    isFetching: isFetchingModelsVolumeBreakdown,
    refetch: refetchModelsVolumeBreakdown,
  } = useQuery({
    queryKey: ["modelsVolumeBreakdown", showVolume],
    queryFn: () => getModelsVolumeBreakdown(!showVolume),
    enabled: false,
    initialData: {},
  });

  useEffect(() => {
    refetchTopGrowthModels();
    refetchBottomGrowthModels();
  }, [history.location.search]);

  useEffect(() => {
    refetchModelsVolumeBreakdown();
  }, [history.location.search, showVolume]);

  const topModelsColorMapping = useMemo(() => {
    const colorMapping: Record<string, string> = {};
    if (topGrowthModels?.length) {
      topGrowthModels.map((val: any, idx: number) => {
        colorMapping[val.model] = AVG_APP_RATINGS_COLORS[idx];
      });
    }
    return colorMapping;
  }, [topGrowthModels, AVG_APP_RATINGS_COLORS]);

  const bottomModelsColorMapping = useMemo(() => {
    const colorMapping: Record<string, string> = {};
    if (bottomGrowthModels?.length) {
      bottomGrowthModels.map((val: any, idx: number) => {
        colorMapping[val.model] = AVG_APP_RATINGS_COLORS[idx];
      });
    }
    return colorMapping;
  }, [bottomGrowthModels, AVG_APP_RATINGS_COLORS]);

  const modelsVolumeColorMapping = useMemo(() => {
    const colorMapping: Record<string, string> = {};
    if (modelsVolumeBreakdownSeries?.length) {
      modelsVolumeBreakdownSeries.map((val: any, idx: number) => {
        colorMapping[val.name] = AVG_APP_RATINGS_COLORS[idx];
      });
    }
    return colorMapping;
  }, [modelsVolumeBreakdownSeries, AVG_APP_RATINGS_COLORS]);

  const topModelsMaxValue = useMemo(() => {
    let maxValue = 0;
    if (topGrowthModels && topGrowthModels.length > 0) {
      topGrowthModels.forEach((model: any) => {
        if (showVolume) {
          maxValue = Math.max(maxValue, model.volume);
        } else {
          maxValue = Math.max(maxValue, model.proportion);
        }
      });
    }
    if (bottomGrowthModels && bottomGrowthModels.length > 0) {
      bottomGrowthModels.forEach((model: any) => {
        if (showVolume) {
          maxValue = Math.max(maxValue, model.volume);
        } else {
          maxValue = Math.max(maxValue, model.proportion);
        }
      });
    }
    return maxValue;
  }, [topGrowthModels, bottomGrowthModels, showVolume]);

  return (
    <Collapsible title="Digitally Influenced Sales by Car model" show={true}>
      <div className="sales_by_car_model ">
        <div className="title">
          <ToggleSwitch
            inactiveToggleLabel={"Proportion"}
            activeToggleLabel={"Volume"}
            active={showVolume}
            handleToggleClick={() => setShowVolume(!showVolume)}
            toggleTitle={"See:"}
            toggleClassName="volume_proprtion_form"
          />
        </div>
        <div className="charts">
          <div className="model_performance primary_background">
            <div className="car_model_charts">
              <DisByCarModelGraph
                chartId="top_growth_car_models"
                chartTitle="Top growth car models"
                chartData={topGrowthModels}
                loading={isFetchingTopGrowthModels}
                showVolume={showVolume}
                colorMapping={topModelsColorMapping}
                maxValue={topModelsMaxValue}
                tooltipText="Shows the top 5 car models in number of Digitally Influenced Sales on the selected period."
              />
              <DisByCarModelGraph
                chartId="top_decrease_car_models"
                chartTitle="Top decrease car models"
                chartData={bottomGrowthModels}
                loading={isFetchingBottomGrowthModels}
                showVolume={showVolume}
                colorMapping={bottomModelsColorMapping}
                maxValue={topModelsMaxValue}
                tooltipText="Shows the 5 car models with the lowest number of Digitally Influenced Sales on the selected period."
              />
            </div>
            <div className="model_performance_variance">
              <div className="title">
                <h3>Models performance variance</h3>
                <Tooltip
                  position="top"
                  className={"normal_tippy"}
                  trigger="mouseenter"
                  animation="none"
                  interactive={true}
                  delay={1}
                  hideDelay={1}
                  duration={1}
                  title={
                    "Variance numbers are given by the latest month selected on the date range. MoM compares the DIS by car model with previous month and YoY compares the DIS by car model with the same month in the last fiscal year."
                  }
                  size={"small"}
                >
                  <span className="info_tooltip" />
                </Tooltip>
              </div>
              <div className="table">
                <ModelPerformanceTable />
              </div>
            </div>
          </div>
          <div className="dis_volume_breakdown primary_background">
            <div className="title">
              <h3>DIS volume breakdown</h3>
              <Tooltip
                position="top"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                delay={1}
                hideDelay={1}
                duration={1}
                title={"Displays number of sales of all car models by month in the selected period, brand and geography."}
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
            </div>
            <div className="chart_content">
              {modelsVolumeBreakdownData?.length && !isFetchingModelsVolumeBreakdown ? (
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <DISVolumeBreakdownChart
                    chartName="dis_volume_breakdown_chart"
                    data={modelsVolumeBreakdownData ?? []}
                    seriesList={modelsVolumeBreakdownSeries ?? []}
                    showVolume={showVolume}
                    theme={themeContext.theme}
                    colorMapping={modelsVolumeColorMapping}
                  />
                </ErrorBoundary>
              ) : isFetchingModelsVolumeBreakdown ? (
                <LoadingEllipsis isLoading={isFetchingModelsVolumeBreakdown} />
              ) : (
                <NoDataMsg message="No data available" />
              )}
            </div>
          </div>
        </div>
      </div>
    </Collapsible>
  );
});

export default DisByCarModel;
