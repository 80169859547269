import { IReleaseNote } from "../constants/interface/admin/releaseNotes";
import { defaultApiInt, fetchDataV2, newApiFetch } from "./utils";

export type IFlashResponse = {
  note: {
    version_number: string;
  };
};

export const getReleaseNotesData = (): defaultApiInt => fetchDataV2("/user-messages/get-latest-release-note");
export const getFlashMessage = (): Promise<IFlashResponse> => newApiFetch("release_notes/message", {});
export const getAllReleaseNotes = (): Promise<Array<IReleaseNote>> => fetchDataV2("/user-messages/get-all-release-notes");
