import { Box, Button, Grid } from "@material-ui/core";
import { format, parse } from "date-fns";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { setLastDataRefresh } from "../../../../actions";
import {
  fetchPreorderCancellationTrendData_Canada,
  fetchPreorderData_Canada,
  fetchPreorderDrilldownColors_Canada,
  fetchPreorderDrilldownVersions_Canada,
  fetchVisitsData,
} from "../../../../api/Ariya";
import { DrilldownLayout } from "../../../../components/Layouts/Ariya";
import { IChartData, IMetricTotal, popularState } from "../../../../constants/interface/ariya";
import { ThemeContext } from "../../../../context";
import { eventTracking, MixpanelEvents } from "../../../../utils/userTracking";
import commonStyles from "../../ariya.module.scss";
import { ExperienceCard } from "../../Experience Insight/components/Card";
import { ProgressTable } from "../../Experience Insight/components/Tables";
import { MajorCard } from "../../HandraisersInsights/Cards";
import { getGraphData } from "../../helpers/charts";
import { extractMonitorData, extractProgressTableDataFromData, mergeGraphData } from "../../helpers/dataFunctions";
import { MonitorCard } from "../../Monitor/components/Card";
import { MonitorTrendline } from "../../Monitor/components/Charts";
import { PreordersByCity_Canada } from "../Tables";
import japanStyles from "./japan.module.scss";
import norwayStyles from "./norway.module.scss";

export const PreorderInsights_Canada = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const themeContext = useContext(ThemeContext);

  const dispatch = useDispatch();
  const lastDataRefresh: string = useSelector((state: RootStateOrAny) => state.data_refresh.ariya_monitor);

  const [preorderTotals, setPreorderTotals] = useState<IMetricTotal>({ current: null, previous: null });
  const [cancellationsTotals, setCancellationsTotals] = useState<IMetricTotal>({ current: null, previous: null });
  const [preorderChartData, setPreorderChartData] = useState<Array<IChartData>>([]);
  const [cancellationsChartData, setCancellationsChartData] = useState<Array<IChartData>>([]);
  const [mergedChartData, setMergedChartData] = useState<Array<IChartData>>([]);
  const [topVersions, setTopVersions] = useState<popularState>({ records: [] });
  const [topColours, setTopColours] = useState<popularState>({ records: [] });
  const [fileUpdatedDate, setFileUpdatedDate] = useState<string>("");

  const pageNavigation = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;

      history.push({ pathname: pageLink, search: "" });
    },
    [history]
  );

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Ariya", page: "Ariya Preorder - Canada" });
  }, []);

  // Last data refresh
  useEffect(() => {
    fetchVisitsData().then((response) => {
      if (response && !("error" in response)) {
        const {
          chart_data: { year, month, day },
        } = extractMonitorData(response);
        dispatch(setLastDataRefresh("ariya_monitor", `${year}-${month}-${day}`));
      }
    });
  }, []);

  // Preorder
  useEffect(() => {
    Promise.all([
      fetchPreorderData_Canada(true).then((response) => {
        if (response && !("error" in response)) {
          const { data, totals, last_date_in_file } = response.report;
          setFileUpdatedDate(last_date_in_file);
          const lastDay = data[data.length - 1].counts[0];
          setPreorderTotals({ current: +totals[0], previous: +lastDay });
          const formattedDate = moment(last_date_in_file).format("DD MMM YYYY");
          const chartData = getGraphData(data).filter((element, idx) => {
            return moment(formattedDate).add(1, "days").isAfter(moment(element.label).format("DD MMM YYYY"));
          });
          setPreorderChartData(chartData);
        }
      }),
      fetchPreorderCancellationTrendData_Canada(true).then((response) => {
        if (response && !("error" in response)) {
          const data = response.report.data;
          const lastDay = +data[data.length - 1].counts[0];
          const total = +response.report.totals[0];
          setCancellationsChartData(getGraphData(data));
          setCancellationsTotals({ current: total, previous: lastDay });
        }
      }),
    ]);
  }, []);

  // Merge chart data
  useEffect(() => {
    setMergedChartData(mergeGraphData(preorderChartData, cancellationsChartData));
  }, [preorderChartData, cancellationsChartData]);

  // Grades data
  useEffect(() => {
    fetchPreorderDrilldownVersions_Canada().then((response) => {
      if (response && !("error" in response)) {
        const data = response.report.data
          .filter((row) => !row.name.includes("No version selected"))
          .sort((a, b) => +b.counts[0] - +a.counts[0])
          .slice(0, 5);
        const total = Number(response.report.totals[0]);
        setTopVersions({ records: extractProgressTableDataFromData(data, total) });
      }
    });
  }, []);

  // Colours data
  useEffect(() => {
    fetchPreorderDrilldownColors_Canada().then((response) => {
      if (response && !("error" in response)) {
        const data = response.report.data.sort((a, b) => +b.counts[0] - +a.counts[0]);
        const total = Number(response.report.totals[0]);
        setTopColours({ records: extractProgressTableDataFromData(data, total) });
      }
    });
  }, []);

  return (
    <DrilldownLayout
      flagName={"CA"}
      title="Canada online pre-order insights"
      lastDataRefresh={lastDataRefresh ? format(parse(lastDataRefresh, "yyyy-M-dd", new Date()), "Y-MM-dd") : ""}
      lastCanadaUpdate={fileUpdatedDate ? format(parse(fileUpdatedDate, "yyyy-MM-dd", new Date()), "Y-MM-dd") : ""}
      previousPagePath="/ariya/preorder"
    >
      <>
        <Box display={"grid"} gridGap={15} className={norwayStyles.cardContainer}>
          <MonitorCard
            metric={{
              title: "Online pre-orders",
              lastDayValue: preorderTotals.previous,
              value: preorderTotals.current,
              legend: "solid",
            }}
            metric2={{
              title: "Cancellations",
              lastDayValue: cancellationsTotals.previous,
              value: cancellationsTotals.current,
              legend: "dotted",
            }}
            chart={
              <MonitorTrendline
                data={mergedChartData}
                chartId={"preorder_canada"}
                series={["preorder", "cancellation"]}
                theme={themeContext.theme}
              />
            }
            extraClass={norwayStyles.chartCard}
            hideMore={true}
            spanBy={0}
          />

          <MajorCard
            id="version_colours_insights"
            content1={<ProgressTable id="top_versions" title="Top grades" data={topVersions} />}
            content2={<ProgressTable id="top_colours" title="Top body colours" data={topColours} resetStyles={false} />}
            className={norwayStyles.tablesCard}
          />

          <ExperienceCard title="Insights by city" id="handraisers_city" className={japanStyles.major2}>
            <PreordersByCity_Canada />
          </ExperienceCard>
        </Box>

        <Grid container spacing={10} justifyContent="space-evenly">
          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page={"/ariya/monitor"}
              data-test-id="ariyaHome"
            >
              Ariya home
            </Button>
          </Grid>

          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page={"/ariya/experience"}
              data-test-id="ariyaExperience"
            >
              Experience insights
            </Button>
          </Grid>

          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page={"/ariya/handraisers"}
              data-test-id="ariyaHandraisers"
            >
              Hand-raisers insights
            </Button>
          </Grid>
        </Grid>
      </>
    </DrilldownLayout>
  );
});
