import React from "react";
import "../../../assets/styles/component/legendMarker.scss";

interface Props {
  shape: "square" | "line";
  color?: string;
  borderStyle?: "dotted" | "dashed" | "solid" | "double" | "groove" | "ridge" | "inset" | "outset" | "initial" | "inherit" | "mixed";
  width?: string;
}

export const LegendMarker = (props: Props) => {
  const styles = {
    marginRight: "1em",
    borderColor: props.color,
    backgroundColor: props.color,
    borderStyle: props.borderStyle ? props.borderStyle : "solid",
    width: props.width,
  };

  return props.borderStyle == "mixed" ? (
    <>
      <span className={`LegendMarker ${props.shape}`} style={{ ...styles, borderStyle: "solid" }} />
      <span className={`LegendMarkerMixed ${props.shape}`} style={{ ...styles, borderStyle: "dotted" }} />
    </>
  ) : (
    <span className={`LegendMarker ${props.shape}`} style={styles} />
  );
};
