import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { DEFAULT_LANGUAGE } from "../../../constants";
import { useVisible } from "../../../hooks";
import { getDateDependentValues } from "../../Online CE Performance/subs/helpers";
import { getRating } from "../helpers/helpers";

interface IVolumeProps {
  title: string;
  metric: string;
  data: any;
  searchEngine: string;
  showTooltip: boolean;
  tooltipText?: string;
  disabled?: boolean;
}

export const VolumeIndicator = (props: IVolumeProps): JSX.Element => {
  const { title, metric, data, disabled, searchEngine, showTooltip } = props;
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.seo_parameters.date_range);

  const [yoyComparison, setYoYComparison] = useState<any>("-");
  const [popComparison, setPoPComparison] = useState<any>("-");
  const [currentValue, setCurrentValue] = useState<any>("-");
  const { wrapperRef: tooltipRef, isVisible: isTooltipVisible, setIsVisible: setIsTooltipVisible } = useVisible(false);
  const { popLabel, showPop: popVisible } = getDateDependentValues(dateRangeParamValue);

  useEffect(() => {
    if (data.length !== 0) {
      setYoYComparison(data[0]?.yoyPercentage);
      setPoPComparison(data[0]?.popPercentage);
      setCurrentValue(data[0]?.current_data[metric]);
    }
  }, [data, metric]);

  return (
    <div className={`volume_indicator ${disabled ? "disabled" : ""}`} data-test-id={`${metric}_volume_indicator`}>
      <div className="volume_indicator_title">
        <h4 className="">{searchEngine}</h4>
        {showTooltip && (
          <>
            <span className="seo_tooltip__icon" onClick={() => setIsTooltipVisible(!isTooltipVisible)} ref={tooltipRef}></span>
            <p className={`seo_tooltip__data indicator_tooltip ${isTooltipVisible ? "show" : ""}`}>
              Natural search visits from Google Search (source: Adobe analytics) can be higher than Google Search clicks (source: Google
              Search Console) due to tracking and reporting specificities within both data sources
            </p>
          </>
        )}
      </div>
      <h4>{title}</h4>
      <div>
        <p className="main">{isNaN(currentValue) || !currentValue ? "n/a" : currentValue.toLocaleString(DEFAULT_LANGUAGE)}</p>
      </div>
      <div className="comparison-values" data-test-id={`${metric}_comparison_values`}>
        {popVisible && (
          <p className="period_comparison">
            <span className={`indicator ${getRating(popComparison)}`}></span>
            <span className="comparison_value">{isNaN(popComparison) ? "n/a" : `${Math.abs(popComparison)}%`}</span>

            <span className="label">{popLabel}</span>
          </p>
        )}

        <p className="year_comparison" data-test-id={`${metric}_year_comparison`}>
          <span className={`indicator ${getRating(yoyComparison)}`}></span>
          <span className="comparison_value">{isNaN(yoyComparison) ? "n/a" : `${Math.abs(yoyComparison)}%`}</span>
          <span className="label">YoY</span>
        </p>
      </div>
    </div>
  );
};
