import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { MtmContent, MtmTitle } from ".";
import {
  fetchFutureMarketBrandSearch,
  fetchFutureMarketMediaMlc,
  fetchFutureMarketPaidImpressions,
  fetchFutureMarketReach,
  fetchMtmCurrency,
} from "../../../actions";
import { AnnotationDialog } from "../../../components/Annotations/Dialogs/AnnotationDialog";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import { MTMActualTargetForecastChart, MTMTrendLineChart } from "../../../components/Charts/MTMReport";
import { MtmInvestmentContext, ThemeContext } from "../../../context";
import { attachAnnotationsMtm } from "../../../utils/annotationFunctions";
import { convertToAbsolutePercentage, convertToAbsolutePts, isNullArrayKey } from "../../../utils/utilityFunctions";
import { updateForecastData, updatePercentageData } from "../helpers/dataFormatting";

type data = {
  date: string;
  quarter: number;
  impressions_actual: number;
  impressions_target: number | null;
  impressions_forecast: number | null;
  temp_impressions_forecast: number | null;
  unique_reach_actual: number | null;
  unique_reach_target: number | null;
  reach_percentage_share_actual: number | null;
  reach_percentage_share_target: number | null;
  unique_reach_forecast: number | null;
  reach_percentage_share_forecast: number | null;
  temp_unique_reach_forecast: number | null;
  temp_reach_percentage_share_forecast: number | null;
};

export const FutureMarket = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const themeContext = useContext(ThemeContext);

  const dispatch = useDispatch();
  const { setFutureMarket, futureMarket, nearMarket, inMarket } = useContext(MtmInvestmentContext);
  const [currencyActive, setCurrencyActive] = useState("");
  const [periodTitle, setPeriodTitle] = useState("");

  const [unitedData, setunitedData] = useState<data[]>([]);

  const [mediastat, setmediastat] = useState<number[]>([]);
  const {
    date_range: dateRangeParam,
    model: modelParam,
    market: marketParam,
    granularity: granularityParam,
  } = useSelector((state: RootStateOrAny) => state.mtm_parameters);

  const mediaMlcData = useSelector((state: RootStateOrAny) => state.mtm_data.future_market_media_mlc);
  const paidImpressionsData = useSelector((state: RootStateOrAny) => state.mtm_data.future_market_paid_impressions);
  const reachData = useSelector((state: RootStateOrAny) => state.mtm_data.future_market_reach);
  const brandSearchData = useSelector((state: RootStateOrAny) => state.mtm_data.future_market_brand_search);
  const isMediaMlcLoading = useSelector((state: RootStateOrAny) => state.loading.future_market_media_mlc);
  const isPaidImpressionsLoading = useSelector((state: RootStateOrAny) => state.loading.future_market_paid_impressions);
  const isReachLoading = useSelector((state: RootStateOrAny) => state.loading.future_market_reach);
  const isBrandSearchLoading = useSelector((state: RootStateOrAny) => state.loading.future_market_brand_search);
  const chartGranularity = useSelector((state: RootStateOrAny) => state.mtm_parameters.granularity);
  const currency = useSelector((state: RootStateOrAny) => state.mtm_data.currency);
  const modelParamValue = useSelector((state: RootStateOrAny) => state.mtm_parameters.model);
  const annotations = useSelector((state: RootStateOrAny) => state.mtm_data.annotations);

  const [competitors, setCompetitors] = useState<Array<string>>([]);
  const [keyToCompare, setKeyToCompare] = useState<Record<string, string>>({ name: "Nissan", field: "Nissan" });

  const [momLegendTooltipText, setMomLegendTooltipText] = useState<Record<string, string>>({});
  const [yoyLegendTooltipText, setYoyLegendTooltipText] = useState<Record<string, string>>({});

  // Annotations
  const [annotationDialogOpen, setAnnotationDialogOpen] = useState<boolean>(false);
  const [annotationStartDate, setAnnotationStartDate] = useState<Date | null>(null);
  const [annotationEndDate, setAnnotationEndDate] = useState<Date | null>(null);
  const [annotationDefaultMetric, setAnnotationDefaultMetric] = useState<string>("visits");

  // ForecastFormatted
  const forecastPaidImpressionsData = useMemo(() => {
    const data =
      paidImpressionsData && paidImpressionsData?.data?.length > 0
        ? updateForecastData(paidImpressionsData.data, [
            { actualKey: "impressions_actual", forecastKey: "impressions_forecast", tempForecastKey: "temp_impressions_forecast" },
          ])
        : [];
    //@ts-ignore
    return attachAnnotationsMtm(annotations, data, chartGranularity, ["Media paid impressions"]);
  }, [paidImpressionsData, annotations]);

  const forecastPaidImpressionsLegend = useMemo(() => {
    const data_impression_mom = granularityParam == "quarterly" ? "impressions_QoQ" : "impressions_MoM";
    const data_impression_yoy = "impressions_YoY";
    const myValueMom = paidImpressionsData[data_impression_mom] && paidImpressionsData[data_impression_mom].pop_value;
    const myValueYoy = paidImpressionsData[data_impression_yoy] && paidImpressionsData[data_impression_yoy].yoy_value;
    const myTextMom = paidImpressionsData[data_impression_mom] && paidImpressionsData[data_impression_mom].pop_text;
    const myTextYoy = paidImpressionsData[data_impression_yoy] && paidImpressionsData[data_impression_yoy].yoy_text;
    const signMom = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const signYoy = myValueYoy && String(myValueYoy).includes("-") ? "-" : "+";
    const popLabel = granularityParam == "monthly" ? "MoM" : "QoQ";
    const varianceMom = myValueMom == null ? "" : `${signMom}${convertToAbsolutePercentage(myValueMom, 2)}`;
    const varianceYoy = myValueYoy == null ? "" : `${signYoy}${convertToAbsolutePercentage(myValueYoy, 2)}`;
    myTextMom && setMomLegendTooltipText((prevState) => ({ ...prevState, impressions_actual: `${popLabel}: ${myTextMom}` }));
    myTextYoy && setYoyLegendTooltipText((prevState) => ({ ...prevState, impressions_actual: `YoY: ${myTextYoy}` }));
    return `Impressions
    ${varianceMom ? `${popLabel}: ${varianceMom}` : ""} ${varianceYoy ? `YoY: ${varianceYoy}` : ""}`;
  }, [paidImpressionsData, granularityParam]);

  const forecastReachData = useMemo(() => {
    const data =
      reachData && reachData?.data?.length > 0
        ? updateForecastData(
            updatePercentageData(reachData.data, [
              "reach_percentage_share_actual",
              "reach_percentage_share_forecast",
              "reach_percentage_share_target",
              "temp_reach_percentage_share_forecast",
            ]),
            [
              { actualKey: "unique_reach_actual", forecastKey: "unique_reach_forecast", tempForecastKey: "temp_unique_reach_forecast" },
              {
                actualKey: "reach_percentage_share_actual",
                forecastKey: "reach_percentage_share_forecast",
                tempForecastKey: "temp_reach_percentage_share_forecast",
              },
            ]
          )
        : [];
    const annotationMetrics = ["Unique reach actual", "Reach % actual"];
    //@ts-ignore
    return attachAnnotationsMtm(annotations, data, chartGranularity, annotationMetrics);
  }, [reachData, annotations]);

  const forecastReachDataLegend = useMemo(() => {
    const data_impression_reach_mom = granularityParam == "quarterly" ? "reach_percentage_share_QoQ" : "reach_percentage_share_MoM";
    const data_impression_reach_yoy = "reach_percentage_share_YoY";
    const myValueMom = reachData[data_impression_reach_mom] && reachData[data_impression_reach_mom].pop_value;
    const myValueYoy = reachData[data_impression_reach_yoy] && reachData[data_impression_reach_yoy].pop_value;
    const myTextMom = reachData[data_impression_reach_mom] && reachData[data_impression_reach_mom].pop_text;
    const myTextYoy = reachData[data_impression_reach_yoy] && reachData[data_impression_reach_yoy].yoy_text;
    const signMom = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const signYoY = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const popLabel = granularityParam == "monthly" ? "MoM" : "QoQ";
    const varianceMom = myValueMom == null ? "" : `${signMom}${convertToAbsolutePts(myValueMom, 2).toLowerCase()}`;
    const varianceYoy = myValueYoy == null ? "" : `${signYoY}${convertToAbsolutePts(myValueYoy, 2).toLowerCase()}`;
    myTextMom && setMomLegendTooltipText((prevState) => ({ ...prevState, reach_percentage_share_actual: `${popLabel}: ${myTextMom}` }));
    myTextYoy && setYoyLegendTooltipText((prevState) => ({ ...prevState, reach_percentage_share_actual: `YoY: ${myTextYoy}` }));
    return `Reach % 
    ${varianceMom ? `${popLabel}: ${varianceMom}` : ""} ${varianceYoy ? `YoY: ${varianceYoy}` : ""}`;
  }, [reachData, granularityParam]);

  useEffect(() => {
    Promise.all([
      dispatch(fetchMtmCurrency()),
      dispatch(fetchFutureMarketReach()),
      dispatch(fetchFutureMarketMediaMlc()),
      dispatch(fetchFutureMarketBrandSearch()),
      dispatch(fetchFutureMarketPaidImpressions()),
    ]);
  }, [history.location.search, dispatch]);

  // Set investments total and share
  useEffect(() => {
    if (mediaMlcData && Array.isArray(mediaMlcData.data)) {
      const period = chartGranularity === "monthly" ? "month" : "quarter";

      const periodData = mediaMlcData.data.find((row: { date: string; fmi_spend_future_market: number; fmi_future_share: number }) => {
        return period === "month"
          ? moment().subtract(1, "months").startOf(period).format("YYYY-MM-DD") === row.date
          : moment().startOf(period).format("YYYY-MM-DD") === row.date;
      });

      if (periodData) {
        const { fmi_spend_future_market, fmi_future_share } = periodData;
        setFutureMarket({ value: fmi_spend_future_market, share: fmi_future_share });
        setPeriodTitle(chartGranularity === "monthly" ? `${moment().subtract(1, "months").format("MMM")}` : `${periodData.quarter}`);
      } else {
        setFutureMarket({ value: 0, share: 0 });
      }
    }
  }, [mediaMlcData, chartGranularity]);

  useEffect(() => {
    if (brandSearchData && brandSearchData?.data?.length > 0) {
      const sampleRow = brandSearchData.data[0];
      let keysToExclude = ["date", "quarter", "Nissan"];

      if (modelParamValue !== "Nissan") {
        keysToExclude = ["date", "quarter", `Nissan ${modelParamValue}`];
        setKeyToCompare({ name: modelParamValue, field: `Nissan ${modelParamValue}` });
      } else {
        setKeyToCompare({ name: "Nissan", field: "Nissan" });
      }
      setCompetitors(Object.keys(sampleRow).filter((key) => !keysToExclude.includes(key)));
    }
  }, [brandSearchData, modelParamValue]);

  useEffect(() => {
    currency ? setCurrencyActive(currency) : setCurrencyActive("");
  }, [currency]);

  useEffect(() => {
    currency ? setCurrencyActive(currency) : setCurrencyActive("");
  }, [currency]);

  const toggleAnnotationsDialog = useCallback(() => {
    setAnnotationDialogOpen(!annotationDialogOpen);
  }, []);

  const mediaMlcDataWithAnnotations = useMemo(() => {
    if (annotations && annotations.length > 0 && Array.isArray(mediaMlcData.data)) {
      return attachAnnotationsMtm(annotations, mediaMlcData.data, chartGranularity, [
        "Future market online media investment",
        "Future market offline media investment",
      ]);
    }
    return mediaMlcData;
  }, [mediaMlcData, annotations]);

  const brandSearchDataWithAnnotations = useMemo(() => {
    if (annotations && annotations.length > 0 && brandSearchData?.data?.length > 0) {
      return attachAnnotationsMtm(annotations, brandSearchData.data, chartGranularity, ["Search interest Nissan"]);
    }
    return brandSearchData.data;
  }, [brandSearchData, annotations]);

  const brandSearchDataLegend = useMemo(() => {
    const brandObj = Object.assign({}, brandSearchData);
    delete brandObj.data;
    return brandObj;
  }, [brandSearchData, granularityParam]);

  useEffect(() => {
    let onlineTotal = 0;
    let offlineTotal = 0;

    mediaMlcData &&
      Array.isArray(mediaMlcData.data) &&
      mediaMlcData.data.forEach((element: any) => {
        onlineTotal += element.future_market_online_fmi;
      });
    mediaMlcData &&
      Array.isArray(mediaMlcData.data) &&
      mediaMlcData.data.forEach((element: any) => {
        offlineTotal += element.future_market_offline_fmi;
      });

    setmediastat(
      onlineTotal && offlineTotal ? [onlineTotal / (onlineTotal + offlineTotal), offlineTotal / (onlineTotal + offlineTotal)] : []
    );
  }, [modelParam, marketParam, dateRangeParam, mediaMlcData]);

  useEffect(() => {
    if (forecastPaidImpressionsData && forecastReachData && forecastPaidImpressionsData.length > 0 && forecastReachData.length > 0) {
      setunitedData(
        forecastPaidImpressionsData.map((con, i) => {
          return Object.assign(con, forecastReachData[i]);
        })
      );
    }
  }, [modelParam, marketParam, dateRangeParam, forecastPaidImpressionsData, forecastReachData]);

  return (
    <div className="mtm_row">
      <ErrorBoundary fallback={<ErrorMsg />}>
        <MtmTitle
          title={"Future market"}
          value={futureMarket.value}
          currency={mediaMlcData.currency}
          view={granularityParam}
          onofTotal={mediastat}
          titleHelper={periodTitle}
          total={futureMarket.share}
        />
      </ErrorBoundary>

      <div className="mtm_container">
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MtmContent
            title={"Impressions and reach %"}
            chart={
              isNullArrayKey("impressions_actual", unitedData) && isNullArrayKey("impressions_actual", unitedData) ? (
                <NoDataMsg />
              ) : (
                <MTMActualTargetForecastChart
                  data={unitedData ? unitedData : []}
                  chartName="paidImpressionsChart"
                  isLoading={isPaidImpressionsLoading}
                  actualField="impressions_actual"
                  actualName={forecastPaidImpressionsLegend}
                  targetField="reach_percentage_share_actual"
                  targetName={forecastReachDataLegend}
                  forecastField="impressions_forecast"
                  tooltipForecastField="impressions_forecast"
                  isQuarterly={chartGranularity === "quarterly"}
                  theme={themeContext.theme}
                  toggleAnnotationsDialog={toggleAnnotationsDialog}
                  setAnnotationEndDate={setAnnotationEndDate}
                  setAnnotationStartDate={setAnnotationStartDate}
                  setAnnotationDefaultMetric={setAnnotationDefaultMetric}
                  annotationMetric="Media paid impressions"
                  momLegendTooltipText={momLegendTooltipText}
                  yoyLegendTooltipText={yoyLegendTooltipText}
                />
              )
            }
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <MtmContent
            title={"Search interest"}
            // tooltipText={
            //   "This search interest KPI represents the number of exact match searches for the keyword &quot;Nissan&quot; in the primary language of the market (or a particular model if selected in the filter) within a given month. Please reference the Leading Indicator - Search Interest dashboard which powers this view. <br />This number will always be smaller than visits since it only represents exact &quot;Nissan&quot; searches, although directionally is a strong indicator of customer's intent to reach the Nissan website."
            // }
            chart={
              <MTMTrendLineChart
                data={brandSearchDataWithAnnotations ? brandSearchDataWithAnnotations : []}
                chartName="brand_search"
                competitors={competitors}
                isLoading={isBrandSearchLoading}
                useCircleLegend={true}
                legendMaker={brandSearchDataLegend}
                legendGran={granularityParam}
                isQuarterly={chartGranularity === "quarterly"}
                keyToCompare={keyToCompare}
                theme={themeContext.theme}
                toggleAnnotationsDialog={toggleAnnotationsDialog}
                setAnnotationEndDate={setAnnotationEndDate}
                setAnnotationStartDate={setAnnotationStartDate}
                setAnnotationDefaultMetric={setAnnotationDefaultMetric}
              />
            }
          />
        </ErrorBoundary>

        {/*/!*Annotations modal *!/*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          {annotationDialogOpen && (
            <AnnotationDialog
              open={annotationDialogOpen}
              setOpen={setAnnotationDialogOpen}
              startDate={annotationStartDate}
              endDate={annotationEndDate}
              defaultMetric={annotationDefaultMetric}
              dashboard="mtm_metrics_that_matter"
            />
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
});
