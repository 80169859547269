import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchDigitalSpend, fetchDigitalSpendVisitsTrend } from "../../../../actions/journeyExcellenceActions";
import FmiBarTrendChart from "../../../../components/Charts/JourneyExcellence/FmiBarTrendChart";
import { ToggleSwitch } from "../../../../components/Toogle";
import { CYAN, HUMMING_BIRD } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import { eventTracking, MixpanelEvents } from "../../../../utils/userTracking";
import DetailedValueTile from "../tiles/DetailedValueTile";

const CHART_COLORS: Record<string, string> = {
  ["Digital spend ACT"]: CYAN,
  ["Digital spend BP"]: HUMMING_BIRD,
};

const FmiContent = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const {
    Current: currentData,
    PoP: popData,
    YoY: yoyData,
    last_three_months: lastThreeMonthsData,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.digital_spend);
  const { series, data: chartData } = useSelector((state: RootStateOrAny) => state.journey_excellence.digital_spend_visits_trend);
  const loading = useSelector((state: RootStateOrAny) => state.loading.digital_spend);
  const chartLoading = useSelector((state: RootStateOrAny) => state.loading.digital_spend_visits_trend);

  const [toggleActive, setToggleActive] = useState(true);

  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Journey Excellence", page: "FMI" });
  }, []);

  useEffect(() => {
    dispatch(fetchDigitalSpend(toggleActive));
  }, [history.location.search, toggleActive]);

  useEffect(() => {
    dispatch(fetchDigitalSpendVisitsTrend());
  }, [history.location.search]);

  const handleBtnClick = useCallback(() => {
    history.push("/midas/fmi_run_rate");
  }, []);

  const formattedSeries = useMemo(() => {
    if (series) {
      return series?.map((series: any, idx: number) => {
        if (CHART_COLORS[series.name]) {
          return { ...series, color: CHART_COLORS[series.name] };
        }
        return series;
      });
    }
    return [];
  }, [series]);

  return (
    <div className="journey-excellence-fmi">
      <div className="section-content journey-excellence-row">
        <div className="section-header">
          <ToggleSwitch
            activeToggleLabel="Show only ACT"
            inactiveToggleLabel="Show ACT + BP"
            handleToggleClick={() => setToggleActive((prevState) => !prevState)}
            active={toggleActive}
            toggleClassName="toggle-justify-end"
          />
        </div>
        <div className="section-content-row">
          <DetailedValueTile
            title="Digital spends"
            mainValue={currentData?.digital_spend}
            yoyValue={yoyData?.perc_digital_spend}
            popValue={popData?.perc_digital_spend}
            lastThreeMonthsValue={lastThreeMonthsData?.digital_spend}
            lastThreeMonthsStartDate={lastThreeMonthsData?.start_date}
            lastThreeMonthsEndDate={lastThreeMonthsData?.end_date}
            isLoading={loading}
            showCurrency={true}
            showSign={true}
            ratingInverse={true}
            ratingArrowInverse={true}
            tooltipText="Media FMI allocated through digital channels to reach its marketing goals or promote the brand messaging. Amount is based on the most recent year scenario available for the selected FY. Forex Rate based from latest BP."
          />
          <DetailedValueTile
            title="Digital spend vs BP"
            mainValue={currentData?.digital_spend_vs_bp}
            yoyValue={yoyData?.perc_digital_spend_vs_bp}
            popValue={popData?.perc_digital_spend_vs_bp}
            lastThreeMonthsValue={lastThreeMonthsData?.digital_spend_vs_bp}
            lastThreeMonthsStartDate={lastThreeMonthsData?.start_date}
            lastThreeMonthsEndDate={lastThreeMonthsData?.end_date}
            isLoading={loading}
            showCurrency={true}
            showSign={true}
            ratingInverse={true}
            ratingArrowInverse={true}
            tooltipText="Media FMI allocated through digital channels to reach its marketing goals or promote the brand messaging. Amount is based on the most recent year scenario available for the selected FY. Forex Rate based from latest BP."
          />
          <DetailedValueTile
            title="Digital spends share"
            mainValue={currentData?.digital_spend_share}
            yoyValue={yoyData?.perc_digital_spend_share}
            popValue={popData?.perc_digital_spend_share}
            lastThreeMonthsValue={lastThreeMonthsData?.digital_spend_share}
            lastThreeMonthsStartDate={lastThreeMonthsData?.start_date}
            lastThreeMonthsEndDate={lastThreeMonthsData?.end_date}
            isLoading={loading}
            isPercentage={true}
            ratingInverse={true}
            ratingArrowInverse={true}
            tooltipText="Media FMI allocated through digital channels to reach its marketing goals or promote the brand messaging. Amount is based on the most recent year scenario available for the selected FY. Forex Rate based from latest BP."
          />
        </div>
      </div>
      <div className="fmi-trend-chart">
        <FmiBarTrendChart
          data={chartData ?? []}
          seriesList={formattedSeries}
          theme={themeContext.theme}
          chartId="digitalSpendVisits"
          isLoading={chartLoading}
          barsStacked={true}
          isBp={true}
          barAxisIsCurrency={true}
        />
      </div>
      <div className="more-info">
        <div>For more FMI information access:</div>
        <button className="more-info-btn" onClick={handleBtnClick}>
          Marketing Investment Dashboard
        </button>
      </div>
    </div>
  );
});

export default FmiContent;
