import {
  SET_ADMIN_BRANDS,
  SET_ADMIN_CHANNELS_DATA,
  SET_ADMIN_CHANNEL_BREAKDOWNS_DATA,
  SET_ADMIN_MARKETS,
  SET_ADMIN_METRICS_DATA,
  SET_ADMIN_NEW_MODELS,
  SET_ADMIN_REGIONS,
  SET_ADMIN_VIEWS,
} from "../actions/actionTypes";

const initialState = {
  brands: [],
  markets: [],
  regions: [],
  views: [],
  models: [],
  channels: [],
  channelBreakdowns: [],
  metrics: [],
};

const adminReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_ADMIN_BRANDS:
      return { ...state, brands: action.payload };

    case SET_ADMIN_MARKETS:
      return { ...state, markets: action.payload };

    case SET_ADMIN_REGIONS:
      return { ...state, regions: action.payload };

    case SET_ADMIN_VIEWS:
      return { ...state, views: action.payload };

    case SET_ADMIN_NEW_MODELS:
      return { ...state, models: action.payload };

    case SET_ADMIN_CHANNELS_DATA:
      return { ...state, channels: action.payload };

    case SET_ADMIN_CHANNEL_BREAKDOWNS_DATA:
      return { ...state, channelBreakdowns: action.payload };

    case SET_ADMIN_METRICS_DATA:
      return { ...state, metrics: action.payload };

    default:
      return state;
  }
};

export default adminReducer;
