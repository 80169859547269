import React from 'react'
import { IframeLayout } from '../../../components/Layouts'
import { ErrorBoundary } from '@sentry/react'
import { ErrorMsg } from '../../../components/AppMessages'
import { IframePageContent } from '../../../components/PageContent'
import { LeadManagementReportFilters } from '../../../components/Filters/LeadManagementReportFilters'
import { LEAD_MANAGEMENT_REPORT_BTN_FILTERS } from '../../../constants/leadManagementReportContants'
import { eventTracking, MixpanelEvents } from '../../../utils/userTracking'

const DetailLevelTwo = () => {
  eventTracking(MixpanelEvents.page_view, { dashboard: "Lead Managment Report", page: "Detail Level 2" });
  return (
    <IframeLayout hasFilter={true}>
      <ErrorBoundary fallback={<ErrorMsg />}>
        <IframePageContent
          frameId="lead_management_report_detail_level_two"
          filter={<LeadManagementReportFilters filterBtns={LEAD_MANAGEMENT_REPORT_BTN_FILTERS} />}
          iframeUrl={"https://lookerstudio.google.com/embed/reporting/4ed55a81-11e6-47cf-9d81-28a37d5ca428/page/p_530vh8r1ld"}
        />
      </ErrorBoundary>
    </IframeLayout>
  )
}

export default DetailLevelTwo