import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, withStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import { WHITE } from "../../../../../constants/colors";

interface Props {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

const WhiteTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      color: WHITE, // Text color
      height: "28px",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#fff8", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: WHITE, // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: WHITE, // Solid underline on focus
    },
  },
})(TextField);

const AnnotationStatusSearchField = (props: Props) => {
  const { value, setValue } = props;
  const options = ["All", "Approved", "Pending", "Unapproved"];

  const classes = makeStyles({
    textField: {
      height: "40px !important",
    },
    input: {
      color: `${WHITE} !important`,
      "&::before": {
        borderBottom: "1px solid #FFF",
      },
      "&::placeholder": {
        color: WHITE,
      },
    },
    popupIndicator: {
      color: WHITE,
    },
  })();

  return (
    <Autocomplete
      value={value}
      options={options}
      defaultValue={"All"}
      disableClearable
      onChange={(event, value) => setValue(value)}
      renderOption={(option, { selected }) => {
        return (
          <>
            <Checkbox style={{ marginRight: 8 }} checked={selected} color="primary" />
            {option}
          </>
        );
      }}
      classes={{
        input: classes.input,
        popupIndicator: classes.popupIndicator,
      }}
      renderInput={(params) => <WhiteTextField {...params} label="Status" InputLabelProps={{ style: { color: WHITE } }} />}
    />
  );
};

export default AnnotationStatusSearchField;
