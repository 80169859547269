import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getTopDecreaseModels, getTopIncreaseModels } from "../../../api";
import "../../../assets/styles/component/disByCarModelTable.scss";
import { plusSignNumbers } from "../../../utils/utilityFunctions";

const ModelPerformanceTable = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const rankedData = useSelector((state: RootStateOrAny) => state.purchase_funnel.model_performance_variance ?? []);

  const {
    data: topIncreaseModels,
    isFetching: isFetchingTopIncreaseModels,
    refetch: refetchTopIncreaseModels,
  } = useQuery({
    queryKey: ["topIncreaseModels"],
    queryFn: getTopIncreaseModels,
    enabled: false,
    initialData: [],
  });

  const {
    data: topDecreaseModels,
    isFetching: isFetchingTopDecreaseModels,
    refetch: refetchTopDecreaseModels,
  } = useQuery({
    queryKey: ["topDecreaseModels"],
    queryFn: getTopDecreaseModels,
    enabled: false,
    initialData: [],
  });

  useEffect(() => {
    refetchTopIncreaseModels();
    refetchTopDecreaseModels();
  }, [history.location.search]);

  const ranks = useMemo(() => {
    const ranks = Math.max(topIncreaseModels?.length, topDecreaseModels?.length);
    const r = [];
    for (let i = 0; i < ranks; i++) {
      r.push(
        <tr key={i + 1} className="ranks">
          <td>{i + 1}</td>
        </tr>
      );
    }
    return r;
  }, [topIncreaseModels, topDecreaseModels]);

  return (
    <div className="model_performance_variancet">
      <div className="table_container">
        <table className="by_car_model_table">
          <thead>
            <tr>
              <th className="rank_head">Rank</th>
              <th className="sec_title inc_titles">
                <tbody>Top increase YoY</tbody>
                <tbody className="col_heads">
                  <th className="col_head">Model</th>
                  <th className="col_head">YoY growth</th>
                  <th className="col_head">MoM growth</th>
                </tbody>
              </th>
            </tr>
          </thead>
          <tbody className="top_inc_yoy_tb1">
            <tr className="top_inc_yoy_tr1">
              <td className="top_inc_yoy_td">
                <tbody className="top_inc_yoy">{ranks}</tbody>
              </td>
              <td className="top_inc_yoy_td">
                <tbody className="top_inc_yoy">
                  {topIncreaseModels?.map((val: { model: string; yoy: number; mom: number }, idx: number) => {
                    return (
                      <tr key={idx} className="top_inc_yoy_tr">
                        <td>{val?.model}</td>
                        <td>{plusSignNumbers(val?.yoy)}%</td>
                        <td>{plusSignNumbers(val?.mom)}%</td>
                      </tr>
                    );
                  })}
                </tbody>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="table_container">
        <table className="by_car_model_table">
          <thead>
            <tr>
              <th className="sec_title dec_titles">
                <tbody>Top decrease YoY</tbody>
                <tbody className="col_heads">
                  <th className="col_head">Model</th>
                  <th className="col_head">YoY growth</th>
                  <th className="col_head">MoM growth</th>
                </tbody>
              </th>
            </tr>
          </thead>
          <tbody className="top_inc_yoy_tb1">
            <tr className="top_inc_yoy_tr1">
              <td className="top_inc_yoy_td">
                <tbody className="top_inc_yoy">
                  {topDecreaseModels?.map((val: { model: string; yoy: number; mom: number }, idx: number) => {
                    return (
                      <tr key={idx} className="top_inc_yoy_tr">
                        <td>{val?.model}</td>
                        <td>{plusSignNumbers(val?.yoy)}%</td>
                        <td>{plusSignNumbers(val?.mom)}%</td>
                      </tr>
                    );
                  })}
                </tbody>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default ModelPerformanceTable;
