import React from "react";

interface Props {
  id: string;
  name: string;
  value: string | number;
  labelName: string;
  checked: boolean;
  handleOnChangeCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const EcommerceCheckBox = (props: Props) => {
  const { id, name, value, labelName, checked, handleOnChangeCheckbox } = props;

  return (
    <label htmlFor={name} className="metric_selection">
      <input type="checkbox" id={id} data-test-id={`${id}_radio_btn`} value={value} checked={checked} onChange={handleOnChangeCheckbox} />
      <span className="checkmark"></span>
      <span className="description">{labelName}</span>
    </label>
  );
};
