import { SET_RELEASE_NOTES } from "../actions/actionTypes";

const initialState = { note: {} };

const releaseNoteReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_RELEASE_NOTES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default releaseNoteReducer;
