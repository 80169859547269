import { Button } from "@material-ui/core";
import { ErrorBoundary } from "@sentry/react";
import { capitalize } from "lodash";
import "moment-fquarter";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { setPageName, setSpecificParameter } from "../../../actions";
import {
  fetchAppRatings,
  fetchAppRatingsChartData,
  fetchAppReviewBreakdown,
  fetchAppSatisfactionData,
  fetchBusinessRevenueChartData,
  fetchBusinessRevenueData,
  fetchConnectedCarsChartData,
  fetchConnectedCarsData,
  fetchEnrolledCustomersChartData,
  fetchEnrolledCustomersData,
  fetchG8PrivateActivationRatioChartData,
  fetchG8PrivateActivationRatioData,
  fetchRenewalChurnRateChartData,
  fetchRenewalChurnRateData,
  fetchSalesRevenueChartData,
  fetchSalesRevenueData,
  fetchSalesRevenueSource,
  fetchSubscriptionSalesChartData,
  fetchSubscriptionSalesData,
  fetchTopUsedServices,
  fetchUserEngagementChartData,
  fetchUserEngagementData,
  fetchVOCCallCentreChartData,
  fetchVOCCallCentreData,
} from "../../../actions/ccsCockpitActions";
import "../../../assets/styles/pages/ccsCockpit.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import { CcsCockpitFilters } from "../../../components/Filters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { BackButton, PageTitle } from "../../../components/PageContent/subs";
import { ALL_COUNTRIES, ALL_OPTION } from "../../../constants";
import { BackgroundContext, DashboardBannerContext } from "../../../context";
import { isCustomDate, isDigitalPerformanceDate } from "../../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { CockpitContext } from "../subs/CockpitContext";
import { MetricContent, NormalContent } from "./content";
import { CockpitTitle } from "./normalSections";

const filterBtns = [
  { id: "ccs_journey_btn", name: "See CCS journey", navigateTo: "/ccs_vital_signs/ccs_journey" },
  { id: "ccs_analysis_btn", name: "See CCS analysis", navigateTo: "/ccs_vital_signs/ccs_analysis" },
];

export const Cockpit = withRouter((props: RouteComponentProps) => {
  const { history, location } = props;

  const dispatch = useDispatch();

  const { setBannerText, setDetailText, setShowBanner, setRegion } = useContext(DashboardBannerContext);

  const ccsCockpitLastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.ccs_cockpit);
  const { geography: availableGeographies } = useSelector((state: RootStateOrAny) => state.filters.cockpit_data);
  const { user_engagement_geography } = useSelector((state: RootStateOrAny) => state.filters.cockpit_data);

  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const {
    region: regionParamValue,
    market: marketParamValue,
    brand: brandParamValue,
    model: modelParamValue,
    app_store: appStoreParamValue,
    user_engagement_market: userEngagementMarket,
    enrolled_user_market: enrolledUserMarket,
    revenue_type: revenuetypeParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const [metric, setMetric] = useState<string>("");

  const { setHideBackgroundImage } = useContext(BackgroundContext);

  const [availableMarkets, setAvailableMarkets] = useState<Array<any>>([]);

  useEffect(() => {
    if (user_engagement_geography) {
      const markets: any = [];
      user_engagement_geography.map((market: any) => {
        market.region == regionParamValue.toLowerCase() && markets.push(capitalize(market.market));
      });
      setAvailableMarkets(markets);
    }
  }, [regionParamValue, user_engagement_geography]);

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "CCS", page: "CCS Vital Signs" });
  }, [location.pathname]);

  // Sets page name
  useEffect(() => {
    setHideBackgroundImage(true);
    Promise.all([dispatch(setPageName("CCS vital signs"))]);
    return () => setHideBackgroundImage(false);
  }, []);

  // Reset geography filter if invalid
  useEffect(() => {
    if (availableGeographies && availableGeographies.length) {
      const isRegionValid =
        regionParamValue && availableGeographies.filter((geo: { region: string }) => geo.region === regionParamValue).length !== 0;
      const isMarketValid =
        marketParamValue &&
        (availableGeographies.filter((geo: { market: string }) => geo.market === marketParamValue).length !== 0 ||
          (availableMarkets && marketParamValue.split(",").every((market: any) => availableMarkets.includes(market))));
      !isMarketValid && dispatch(setSpecificParameter("market", ALL_COUNTRIES));
      !isRegionValid &&
        Promise.all([dispatch(setSpecificParameter("region", ALL_OPTION)), dispatch(setSpecificParameter("market", ALL_COUNTRIES))]);
    }
  }, [regionParamValue, marketParamValue, availableGeographies]);

  // Fetching main data on filter change
  useEffect(() => {
    (!isDigitalPerformanceDate(dateRangeParamValue) || !isCustomDate(dateRangeParamValue)) &&
      setTimeout(
        // ensures all parameters are set in history search object
        () =>
          Promise.all([
            dispatch(fetchTopUsedServices()),
            dispatch(fetchBusinessRevenueData()),
            dispatch(fetchBusinessRevenueChartData(true)),
            dispatch(fetchSubscriptionSalesData()),
            dispatch(fetchSubscriptionSalesChartData(true)),
            dispatch(fetchConnectedCarsData()),
            dispatch(fetchConnectedCarsChartData(true)),
            dispatch(fetchAppRatings(appStoreParamValue)),
            dispatch(fetchAppRatingsChartData(appStoreParamValue)),
            dispatch(fetchAppReviewBreakdown(appStoreParamValue)),
            dispatch(fetchVOCCallCentreData()),
            dispatch(fetchVOCCallCentreChartData(true)),
            dispatch(fetchAppSatisfactionData()),
            dispatch(fetchRenewalChurnRateData()),
            dispatch(fetchRenewalChurnRateChartData()),
            dispatch(fetchG8PrivateActivationRatioData()),
            dispatch(fetchG8PrivateActivationRatioChartData()),
            dispatch(fetchSalesRevenueSource()),
          ]),
        100
      );
  }, [history.location.search, appStoreParamValue]);

  useEffect(() => {
    (!isDigitalPerformanceDate(dateRangeParamValue) || !isCustomDate(dateRangeParamValue)) &&
      Promise.all([dispatch(fetchUserEngagementData(userEngagementMarket)), dispatch(fetchUserEngagementChartData(userEngagementMarket))]);
  }, [history.location.search, dateRangeParamValue, userEngagementMarket]);

  useEffect(() => {
    (!isDigitalPerformanceDate(dateRangeParamValue) || !isCustomDate(dateRangeParamValue)) &&
      Promise.all([
        dispatch(fetchEnrolledCustomersData(enrolledUserMarket)),
        dispatch(fetchEnrolledCustomersChartData(enrolledUserMarket)),
      ]);
  }, [history.location.search, dateRangeParamValue, enrolledUserMarket]);

  useEffect(() => {
    (!isDigitalPerformanceDate(dateRangeParamValue) || !isCustomDate(dateRangeParamValue)) &&
      Promise.all([dispatch(fetchSalesRevenueData()), dispatch(fetchSalesRevenueChartData())]);
  }, [history.location.search, dateRangeParamValue, revenuetypeParamValue]);

  useEffect(() => {
    if (regionParamValue && regionParamValue === "Japan") {
      setBannerText("Japan FY22 priority KPIs: Enrolled customers and Renewal rate with targets and monitoring");
      setDetailText("(hover on the banner to highlight KPIs)");
      setShowBanner(true);
    }

    if (regionParamValue && regionParamValue === "Europe") {
      setBannerText(
        "Europe FY22 priority KPIs: G8 Private activation rate and User engagement - country level breakdown can be accessed by clicking on the click through button (⇒)"
      );
      setDetailText("(hover on the banner to highlight KPIs)");
      setShowBanner(true);
    }

    if (marketParamValue && marketParamValue === "United States") {
      setBannerText("United States FY22 priority KPIs: Enrolled customers and User engagement (PPU) with targets and monitoring");
      setDetailText("(hover on the banner to highlight KPIs)");
      setShowBanner(true);
    }

    setRegion(regionParamValue);

    return () => {
      setBannerText("");
      setDetailText("");
      setShowBanner(false);
    };
  }, [regionParamValue, marketParamValue]);

  const onBtnLinkClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;

      history.push({ pathname: pageLink, search: "" });
    },
    [history]
  );

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={
          <ErrorBoundary fallback={<ErrorMsg />}>
            <CcsCockpitFilters filterBtns={filterBtns} />
          </ErrorBoundary>
        }
        lastDataRefresh={ccsCockpitLastDataRefresh}
        dataDocumentation="ccs_data"
        pageTitle={<PageTitle dataDocumentation="ccs_data" backButton={metric ? <BackButton id={"ccs"} setMetric={setMetric} /> : <></>} />}
      >
        <CockpitContext.Provider value={{ metric, setMetric }}>
          <div className="cockpit_layout">
            <div className="title_menu hide_on_mobile">
              <div id="business_revenue_title" className="cockpit_row normal title_row" data-target="business_revenue">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <CockpitTitle title={"Business revenue"} />
                </ErrorBoundary>
              </div>

              <div id="customer_quality_title" className="cockpit_row normal title_row" data-target="customer_quality">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <CockpitTitle title={"Customer quality"} />
                </ErrorBoundary>
              </div>

              <div id="engagement_title" className="cockpit_row normal title_row" data-target="engagement">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <CockpitTitle title={"Engagement"} />
                </ErrorBoundary>
              </div>

              <div id="operational_title" className="cockpit_row title_row" data-target="operational">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <CockpitTitle title={"Operational"} />
                </ErrorBoundary>
              </div>
            </div>
            <div className={`cockpit_content ${metric ? "cockpit_metric" : ""}`}>
              {!metric ? <NormalContent /> : <MetricContent metric={metric} />}
            </div>
          </div>

          <div className="container_footer hide_on_desktop">
            <Button variant={"outlined"} data-page={"/ccs_vital_signs/ccs_journey"} onClick={(evt) => onBtnLinkClick(evt)}>
              See CCS journey
            </Button>
          </div>
        </CockpitContext.Provider>
      </DefaultPageContent>
    </DashboardLayout>
  );
});
