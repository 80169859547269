import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { ErrorMsg } from "../../../../components/AppMessages";
import { ValueLineChart } from "../../../../components/Charts/CCSCockpit";
import { LegendMarker } from "../../../../components/Charts/components/LegendMarker";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { CYAN, RED } from "../../../../constants";
import { DashboardBannerContext, ThemeContext } from "../../../../context";
import { isNull, plusSignNumbers } from "../../../../utils/utilityFunctions";

interface Props {
  title: string;
  value: number | null;
  comparisonValue: number | null;
  chartId: string;
  isLoading: boolean;
  chartDataKey?: string;
  chartData?: Array<any>;
  onClick?: () => void;
  isChurnRate?: boolean;
  bpComparisonValue?: number | null;
  bpPts?: number | null;
  showMetric?: boolean;
  isSingleYear?: boolean;
}

export const ValueWithFY = (props: Props) => {
  const {
    title,
    value,
    comparisonValue,
    chartData,
    isChurnRate,
    chartDataKey,
    chartId,
    isLoading,
    showMetric,
    isSingleYear,
    onClick: handleClick,
    bpComparisonValue,
    bpPts,
  } = props;
  const themeContext = useContext(ThemeContext);

  const churn_rate_toolTip =
    " When different models/months are selected the churn rate is calculated based on the average of the individual monthly churn rates by models";

  const comparisonLabel = "YoY";
  const [highLight, setHighLight] = useState(false);

  const { region: regionParamValue, model: modelParamValue } = useSelector((state: RootStateOrAny) => state.parameters);

  const { bannerHovered } = useContext(DashboardBannerContext);

  useEffect(() => {
    if (regionParamValue === "Japan" && title === "Renewal rate" && bannerHovered) {
      setHighLight(true);
    } else {
      setHighLight(false);
    }
  }, [regionParamValue, bannerHovered, title]);
  const [isAllUndefined, setIsAllUndefined] = useState<boolean>(false);
  useEffect(() => {
    const key = `${chartDataKey}`;
    setIsAllUndefined(
      chartData?.every((splitRow) => typeof splitRow[key] === "undefined" || isNull(splitRow[key]) || splitRow[key] === 0) ? true : false
    );
  }, [chartData]);

  return (
    <div
      className={`tile ${highLight ? "isBlue" : ""}`}
      id={title.replaceAll(" ", "_")}
      data-test-id={title.replaceAll(" ", "_")}
      onClick={handleClick}
    >
      <div className="tile_header">
        {title}
        {isChurnRate && (
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={churn_rate_toolTip}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        )}
      </div>
      <div className="CCS_main_content">
        <div className="tile_content">
          {chartData && !isChurnRate && regionParamValue === "Japan" && (
            <div className="tile_header">
              <LegendMarker color={CYAN} shape={"line"} />
              Renewal rate
            </div>
          )}
          <div className="value" data-test-id={"value"} data-test-value={value ? value : "n/a"}>
            {isNull(value) ? "n/a" : `${Math.round(value as number)}%`}
          </div>

          {!isNull(comparisonValue) && typeof comparisonValue !== "undefined" && (
            <p className="period_comparison comparison">
              <span className="main">
                <span className="comparison_value">
                  {isNull(comparisonValue) || isNull(value)
                    ? "n/a"
                    : `${plusSignNumbers(Math.round((value as number) - (comparisonValue as number)))} PTS`}
                </span>
                {comparisonLabel}
              </span>
            </p>
          )}

          {chartData && !isChurnRate && regionParamValue === "Japan" && (
            <>
              <div className="tile_header">
                <LegendMarker color={RED} shape={"line"} />
                BP target
              </div>
              <div className="value" data-test-id={"value"} data-test-value={value ? value : "n/a"}>
                {modelParamValue.includes("All") ? (
                  <span className="main">{isNull(bpComparisonValue) ? "n/a" : `${bpComparisonValue}%`}</span>
                ) : (
                  <span className="main">n/a </span>
                )}
              </div>
            </>
          )}

          {!isChurnRate && regionParamValue === "Japan" && bpPts && !showMetric && (
            <p className="period_comparison comparison">
              <span className="main">
                <span className="comparison_value">{isNull(bpPts) ? "n/a" : `${plusSignNumbers(bpPts)} PTS`}</span>
                vs BP
              </span>
            </p>
          )}
        </div>
        {chartData && (
          <div className="metric_content">
            <ErrorBoundary fallback={<ErrorMsg />}>
              <ValueLineChart
                chartId={chartId}
                data={isAllUndefined ? [] : chartData}
                seriesName={title}
                dataKey={chartDataKey ? chartDataKey : ""}
                theme={themeContext.theme}
                isChurnRate={isChurnRate}
                region={regionParamValue}
              />
            </ErrorBoundary>
          </div>
        )}
      </div>

      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};
