import React, { Fragment, useCallback, useContext } from "react";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { FilterContext } from "../../../context";
import { closeFilterOptions } from "../subs/helpers";

const ModelFilter = (props: {
  filterValue: string;
  list: string[];
  handleFilterOptionClick: (evt: React.MouseEvent<HTMLElement>) => void;
}) => {
  const { filterValue, list, handleFilterOptionClick } = props;

  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  return (
    <div className={"filter"} id="model_filter_div">
      <label className="filter_header" htmlFor="model_filter">
        MODEL
      </label>
      <div className="input_arrow" onClick={showFilterOptionsFn} data-options-ul={"model_ul"}>
        <input readOnly type="text" name="model" id="model_filter" className={"filter_input"} data-test-id="model" value={filterValue} />
        <span className="arrow_down " />
      </div>
      <ul className="filter_options" id="model_ul" data-test-id="group_list">
        {list?.map((model: string) => {
          const modelDividerClass = model === ALL_OPTION_NO_SPACE ? "filter_divider model_divider" : "";
          return list?.length === 1 && list[0] == "All" ? (
            <Fragment key={model}>
              <li className={modelDividerClass} key={model}>
                {model}
              </li>
              <li className={"error_msg"}>Please select a region or market to see options</li>
            </Fragment>
          ) : (
            <li className={modelDividerClass} key={model} data-filter={"model"} onClick={handleFilterOptionClick} data-value={model}>
              {model}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ModelFilter;
