import React, { useContext, useEffect } from "react";
import Ratings from "../../../components/Ratings/Ratings";
import DmeoDonutChart from "../../../components/Charts/Dmeo/DmeoDonutChart";
import { ThemeContext } from "../../../context";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import { isNull } from "../../../utils/utilityFunctions";
import { useQuery } from "@tanstack/react-query";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getDmeoVisistsBreakdown } from "../../../api/dmeoApi";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";

interface Props {
  data: any;
}

const VisitsTile = withRouter((props: RouteComponentProps & Props) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const { data } = props;

  const {
    data: chartData,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["visitsBreakdownData"],
    queryFn: getDmeoVisistsBreakdown,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile visits-tile">
      <div className="tile-header">Visits</div>
      <div className="tile-content">
        <div>
          <div className="main-value">{data?.visits ? formatToThreeDigits(data.visits) : "n/a"}</div>
          <div>
            <div className="sub-text first-sub-text">
              {data?.yoy_perc_visits && !isNull(data.yoy_perc_visits) ? (
                <Ratings value={data?.yoy_perc_visits} isPercentageValue={true} />
              ) : null}{" "}
              <div>{data?.yoy_perc_visits && !isNull(data.yoy_perc_visits) ? `${Math.abs(data.yoy_perc_visits)}%` : "n/a"}</div>{" "}
              <div>&nbsp;vs PY</div>
            </div>
            <div className="sub-text">
              {data?.mom_perc_visits && !isNull(data.mom_perc_visits) ? (
                <Ratings value={data?.mom_perc_visits} isPercentageValue={true} />
              ) : null}{" "}
              <div>{data?.mom_perc_visits && !isNull(data.mom_perc_visits) ? `${Math.abs(data.mom_perc_visits)}%` : "n/a"}</div>{" "}
              <div>&nbsp;vs PM</div>
            </div>
          </div>
        </div>
        <div className="chart-column">
          <DmeoDonutChart
            theme={theme}
            chartId="campaignPerformanceVisitsDonutChart"
            data={chartData}
            categoryField="entry_page_mapped"
            valueField="perc_visits"
            legendPosition="bottom"
          />
        </div>
      </div>
      <LoadingEllipsis isLoading={isFetching} />
    </div>
  );
});

export default VisitsTile;
