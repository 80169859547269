import React, { Fragment, useCallback } from "react";
import { useDispatch } from "react-redux";
import { MultiSelectFilter } from ".";
import { setSpecificParameter } from "../../../actions";
import { closeFilterOptions } from "../subs/helpers";

interface Props {
  filterValue: string;
  marketList: string[];
  showFilterOptions: (evt: React.MouseEvent<HTMLElement>) => void;
  marketParamValue: string;
}

export const AppRatingsBreakdownGeoFilter = (props: Props) => {
  const dispatch = useDispatch();

  const { filterValue, marketList, showFilterOptions, marketParamValue } = props;

  const handleMarketMultiselectSubmit = useCallback(() => {
    const parameterName = "market";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = selectedValues?.length === marketList?.length ? "All" : selectedValues?.join(",");
    if (filterValue) {
      closeFilterOptions();
      dispatch(setSpecificParameter(parameterName, filterValue));
    }
  }, [marketList]);

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;
    closeFilterOptions();
    if (filterName && optionValue) {
      dispatch(setSpecificParameter("market", optionValue));
    }
  }, []);

  return (
    <div className={"filter"} id="market_filter_div">
      <label className="filter_header" htmlFor="market_filter">
        GEOGRAPHY
      </label>
      <div className="input_arrow" onClick={showFilterOptions} data-options-ul={"market_ul"}>
        <input readOnly type="text" name="region" id="market_filter" className={"filter_input"} data-test-id="market" value={filterValue} />
        <span className="arrow_down " />
      </div>
      <ul className="filter_options" id="market_ul" data-test-id="market_list">
        <Fragment>
          <li data-filter={"region"} data-value={"G8"} onClick={handleFilterOptionClick} key={"G8"}>
            G8
          </li>
          <li className="filter_divider" data-filter={"region"} data-value={"G5"} onClick={handleFilterOptionClick} key={"G5"}>
            G5
          </li>
          <MultiSelectFilter
            parentKey="region_filter"
            showOptionsOnly={true}
            // @ts-ignore
            filterList={marketList}
            value={filterValue}
            parameterName={"market"}
            parameterValue={marketParamValue.includes("All") ? marketList?.join(",") : marketParamValue}
            onShowFilterOptions={showFilterOptions}
            handleFilterSubmission={handleMarketMultiselectSubmit}
          />
        </Fragment>
      </ul>
    </div>
  );
};
