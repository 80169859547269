import {
  SET_CONVERSION,
  SET_CONVERSION_AND_COST,
  SET_CONVERSION_TREND,
  SET_CUSTOMER_EXPERIENCE_DXS,
  SET_CUSTOMER_JOURNEY,
  SET_DIGITAL_SPEND,
  SET_DIGITAL_SPEND_VISITS_DATA,
  SET_DIGITAL_SPEND_VISITS_TREND,
  SET_EXIT_SURVEY_FEEDBACK,
  SET_EXIT_SURVEY_OBJECTIVE_COMPLETION,
  SET_FMI_LAST_ACT_MONTH,
  SET_JOURNEY_COST,
  SET_JOURNEY_COST_TREND,
  SET_JOURNEY_EXCELLENCE_CURRENCY,
  SET_LMS_CONVERSION_AND_COST,
  SET_LMS_CONVERSION_AND_COST_TREND,
  SET_LMS_DIGITALLY_INFLUENCED_SALES,
  SET_LMS_DIGITALLY_INFLUENCED_SALES_TREND,
  SET_LMS_DIS_AND_LEADS,
  SET_LMS_SALES_AND_LEADS_TREND,
  SET_METRIC_CHANNEL,
  SET_METRIC_CHANNEL_TREND,
  SET_PERFORMANCE_OVERVIEW,
  SET_PRODUCT_JOURNEY_ENTRY_POINTS,
  SET_PRODUCT_JOURNEY_EXIT_POINTS,
  SET_PRODUCT_JOURNEY_PERFORMANCE_OVERVIEW,
  SET_PRODUCT_JOURNEY_WEBSITE_ENTRY_POINTS,
  SET_STAR_RATING_AVERAGE,
  SET_STAR_RATING_FEEDBACK,
  SET_WEBSITE_PERFORMANCE,
  SET_WEBSITE_PERFORMANCE_TREND,
} from "../actions/actionTypes";

const initialState = {
  digital_spend: {},
  digital_spend_visits: {},
  digital_spend_visits_trend: {},
  conversion_and_cost: {},
  performance_overview: {},
  website_performance: {},
  website_performance_trend: {},
  journey_cost: {},
  journey_cost_trend: {},
  customer_journey: {},
  conversion: [],
  conversion_trend: {},
  metric_channel: {},
  metric_channel_trend: {},
  customer_experience_dxs: {},
  star_rating_average: {},
  star_rating_feedback: {},
  exit_survey_objective_completion: {},
  exit_survey_feedback: {},
  lms_digitally_influenced_sales: {},
  lms_digitally_influenced_sales_trend: {},
  lms_dis_and_leads: {},
  lms_sales_and_leads_trend: {},
  lms_conversion_and_cost: {},
  lms_conversion_and_cost_trend: {},
  product_journey_performance_overview: {},
  product_journey_entry_points: {},
  product_journey_exit_points: {},
  product_journey_website_entry_points: {},
  fmi_last_act_month: {},
  currency: "$",
};

const journeyExcellenceReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_DIGITAL_SPEND:
      return { ...state, digital_spend: action.payload };
    case SET_DIGITAL_SPEND_VISITS_DATA:
      return { ...state, digital_spend_visits: action.payload };
    case SET_DIGITAL_SPEND_VISITS_TREND:
      return { ...state, digital_spend_visits_trend: action.payload };
    case SET_CONVERSION_AND_COST:
      return { ...state, conversion_and_cost: action.payload };
    case SET_PERFORMANCE_OVERVIEW:
      return { ...state, performance_overview: action.payload };
    case SET_WEBSITE_PERFORMANCE:
      return { ...state, website_performance: action.payload };
    case SET_WEBSITE_PERFORMANCE_TREND:
      return { ...state, website_performance_trend: action.payload };
    case SET_JOURNEY_COST:
      return { ...state, journey_cost: action.payload };
    case SET_JOURNEY_COST_TREND:
      return { ...state, journey_cost_trend: action.payload };
    case SET_CUSTOMER_JOURNEY:
      return { ...state, customer_journey: action.payload };
    case SET_CONVERSION:
      return { ...state, conversion: action.payload };
    case SET_CONVERSION_TREND:
      return { ...state, conversion_trend: action.payload };
    case SET_METRIC_CHANNEL:
      return { ...state, metric_channel: action.payload };
    case SET_METRIC_CHANNEL_TREND:
      return { ...state, metric_channel_trend: action.payload };
    case SET_CUSTOMER_EXPERIENCE_DXS:
      return { ...state, customer_experience_dxs: action.payload };
    case SET_STAR_RATING_AVERAGE:
      return { ...state, star_rating_average: action.payload };
    case SET_STAR_RATING_FEEDBACK:
      return { ...state, star_rating_feedback: action.payload };
    case SET_EXIT_SURVEY_OBJECTIVE_COMPLETION:
      return { ...state, exit_survey_objective_completion: action.payload };
    case SET_EXIT_SURVEY_FEEDBACK:
      return { ...state, exit_survey_feedback: action.payload };
    case SET_LMS_DIGITALLY_INFLUENCED_SALES:
      return { ...state, lms_digitally_influenced_sales: action.payload };
    case SET_LMS_DIGITALLY_INFLUENCED_SALES_TREND:
      return { ...state, lms_digitally_influenced_sales_trend: action.payload };
    case SET_LMS_DIS_AND_LEADS:
      return { ...state, lms_dis_and_leads: action.payload };
    case SET_LMS_SALES_AND_LEADS_TREND:
      return { ...state, lms_sales_and_leads_trend: action.payload };
    case SET_LMS_CONVERSION_AND_COST:
      return { ...state, lms_conversion_and_cost: action.payload };
    case SET_LMS_CONVERSION_AND_COST_TREND:
      return { ...state, lms_conversion_and_cost_trend: action.payload };
    case SET_PRODUCT_JOURNEY_PERFORMANCE_OVERVIEW:
      return { ...state, product_journey_performance_overview: action.payload };
    case SET_PRODUCT_JOURNEY_ENTRY_POINTS:
      return { ...state, product_journey_entry_points: action.payload };
    case SET_PRODUCT_JOURNEY_EXIT_POINTS:
      return { ...state, product_journey_exit_points: action.payload };
    case SET_PRODUCT_JOURNEY_WEBSITE_ENTRY_POINTS:
      return { ...state, product_journey_website_entry_points: action.payload };
    case SET_FMI_LAST_ACT_MONTH:
      return { ...state, fmi_last_act_month: action.payload };
    case SET_JOURNEY_EXCELLENCE_CURRENCY:
      return { ...state, currency: action.payload };
    default:
      return state;
  }
};

export default journeyExcellenceReducer;
