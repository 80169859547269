import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo, useState } from "react";
import "../../assets/styles/component/topUsedServices.scss";
import { CYAN, LIGHT_CYAN, PURPLE } from "../../constants";
import { ThemeContext } from "../../context";
import { toLocaleInteger } from "../../utils/utilityFunctions";
import { ErrorMsg, NoDataMsg } from "../AppMessages";
import { LegendMarker } from "../Charts/components/LegendMarker";
import LoadingEllipsis from "../Loading/LoadingEllipsis";
import { DefaultTable } from "./subs";

interface Props {
  data: Array<any>;
  isLoading: boolean;
  overflow: string;
}

export const TopUsedServicesTable = (props: Props) => {
  const { data, isLoading, overflow } = props;
  const { theme } = useContext(ThemeContext);

  const [hiddenCols] = useState([""]);

  const columns = useMemo(
    () => [
      {
        id: "name",
        Header: "Service",
        accessor: "name",
      },
      {
        id: "progression",
        Header: "",
        disableSortBy: true,
        accessor: "bar_width",
        Cell: function renderProgression(props1: {
          value: number;
          row: { original: { bar_width: number; name: string; service_type: string; share: number; value: number } };
        }) {
          return (
            <div className={`progress-bar ${props1.row.original.service_type}`}>
              <span style={{ width: `${props1.value}%` }}>&nbsp;</span>
            </div>
          );
        },
      },
      {
        id: "share",
        Header: "Share",
        accessor: "share",
        Cell: function renderShare(props1: { value: number }) {
          return <span>{Math.round(props1.value)}%</span>;
        },
      },
      {
        id: "events",
        accessor: "value",
        Header: "Events",
        Cell: function renderEvents(props1: { value: number }) {
          return toLocaleInteger(props1.value);
        },
      },
    ],
    [data]
  );

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <>
        <div className="table_legend">
          <div className="legend_item">
            <LegendMarker color={CYAN} shape={"square"} />
            <span>Onboard services</span>
          </div>
          <div className="legend_item">
            <LegendMarker color={theme === "light" ? PURPLE : LIGHT_CYAN} shape={"square"} />
            <span>Offboard services</span>
          </div>
        </div>
        {data.length > 0 ? (
          <div className={`tableWrapper ${overflow}`} id="top_used_services_wrapper">
            <DefaultTable
              columns={columns}
              data={data}
              id={"top_used_services_table"}
              isChildComponent={false}
              initialSortColId={{ id: "events", desc: true }}
              hiddenCols={hiddenCols}
            />
          </div>
        ) : (
          <NoDataMsg />
        )}
      </>

      <LoadingEllipsis isLoading={isLoading} />
    </ErrorBoundary>
  );
};
