import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter } from "@amcharts/amcharts4/core";
import { endOfMonth } from "date-fns";
import Cookies from "js-cookie";
import _ from "lodash";
import React, { Component, Dispatch, SetStateAction } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { WEBSITE_VISITS_COLORS } from "../../../constants";
import { Annotation } from "../../../pages/Admin/types";
import ShareAnnotationModal from "../../Annotations/Dialogs/ShareAnnotationModal";
import { createAnnotationTooltips } from "../helpers/annotationTooltips";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, legendConfiguration } from "../helpers/configurations";
import { configureOCELineSeries, oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, string | number>>;
  granularity: string;
  seriesList: Array<{ name: string; key: string }>;
  theme: ThemeInt;
  metric: string;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
  setAnnotationDefaultMetricType: Dispatch<SetStateAction<string | undefined>>;
  toggleAnnotationsDialog: () => void;
  isCpoDashboard?: boolean;
}

interface State {
  shareModalOpen: boolean;
  annotations: Annotation[] | undefined;
}

class MetricTrendChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { shareModalOpen: false, annotations: undefined };

    this.toogleShareModal = this.toogleShareModal.bind(this);
  }

  static defaultProps = {
    granularity: "daily",
  };

  chartId = "kbasTrendGraph";

  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  toogleShareModal() {
    this.setState({
      shareModalOpen: !this.state.shareModalOpen,
    });
  }

  handleShareAnnotation(annotations: Annotation[]) {
    this.setState({
      annotations: annotations,
    });
    this.toogleShareModal();
  }

  initChart() {
    const { data, seriesList, granularity, theme } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.colors.list = WEBSITE_VISITS_COLORS.map((chartColor) => color(chartColor));
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#a";

    // Configure legend
    this.chart.legend = legendConfiguration(theme);

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.extraMax = 0.1;
    valueAxis.numberFormatter = new NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#a";

    // Creating series
    this.createSeries(seriesList?.slice(0, 6), granularity);
  }

  createSeries(SeriesList: any[], granularity: string) {
    SeriesList?.forEach((market: { key: string; name: string }) => {
      const {
        metric,
        toggleAnnotationsDialog,
        setAnnotationStartDate,
        setAnnotationEndDate,
        setAnnotationDefaultMetric,
        setAnnotationDefaultMetricType,
      } = this.props;
      const series = this.chart.series.push(new LineSeries());
      const userViewsList = Cookies.get("views");

      const bullet = configureOCELineSeries(series, {
        name: market.name,
        valueY: `${market.key}`,
        granularity,
      });

      if (!this.props.isCpoDashboard && userViewsList?.includes("annotations")) {
        bullet.events.on("hit", (ev: any) => {
          const startDate = new Date(ev.target.dataItem.dateX);
          const endDate = endOfMonth(startDate);
          setAnnotationStartDate(startDate);
          setAnnotationEndDate(endDate);
          setAnnotationDefaultMetric(metric);
          setAnnotationDefaultMetricType(market.name);
          toggleAnnotationsDialog();
        });
      }

      let annotationBullet = createAnnotationTooltips.bind(this);
      annotationBullet = annotationBullet(series);
      // @ts-ignore
      annotationBullet.adapter.add("disabled", function (disabled: any, target: any) {
        if (!target.dataItem) {
          return disabled;
        }
        if (target.dataItem?.dataContext?.annotations) {
          const marketAnnotations: Annotation[] = [];
          target.dataItem.dataContext.annotations.map(
            (annotation: Annotation) =>
              annotation.metric_types.flatMap((metric_type) => metric_type.metric_type).includes(market.name) &&
              marketAnnotations.push(annotation)
          );
          if (marketAnnotations.length > 0) {
            return disabled;
          } else {
            return true;
          }
          return disabled;
        } else {
          return true;
        }
      });
    });
  }
  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={"kbasTrendGraph"} data-test-id={"metricTrendGraph"} className={"graph"} />
        <ShareAnnotationModal
          open={this.state.shareModalOpen}
          handleToogleDialog={this.toogleShareModal}
          annotations={this.state.annotations}
        />
      </div>
    );
  }
}

export default MetricTrendChart;
