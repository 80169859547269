import { SET_AVAILABLE_CHANNELS, SET_MARKETING_CHANNELS_DATA } from "../actions/actionTypes";

const initialState = { data: [], list: [""] };

const marketingChannelReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_MARKETING_CHANNELS_DATA:
      return { ...state, data: action.payload };

    case SET_AVAILABLE_CHANNELS:
      return { ...state, list: action.payload };

    default:
      return state;
  }
};
export default marketingChannelReducer;
