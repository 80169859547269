import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import CustomerExperienceTile from "../tiles/CustomerExperienceTile";

const CustomerExperience = () => {
  const {
    Current: customerExperienceDxsCurrent,
    PoP: customerExperienceDxsPop,
    YoY: customerExperienceDxsYoy,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.customer_experience_dxs);
  const customerExperienceDxsLoading = useSelector((state: RootStateOrAny) => state.loading.customer_experience_dxs);
  const {
    Current: starRatingAverageCurrent,
    PoP: starRatingAveragePop,
    YoY: starRatingAverageYoy,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.star_rating_average);
  const starRatingAverageLoading = useSelector((state: RootStateOrAny) => state.loading.star_rating_average);
  const {
    Current: starRatingFeedbackCurrent,
    PoP: starRatingFeedbackPop,
    YoY: starRatingFeedbackYoy,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.star_rating_feedback);
  const starRatingFeedbackLoading = useSelector((state: RootStateOrAny) => state.loading.star_rating_feedback);
  const {
    Current: exitSurveyObjectiveCompletionCurrent,
    PoP: exitSurveyObjectiveCompletionPop,
    YoY: exitSurveyObjectiveCompletionYoy,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.exit_survey_objective_completion);
  const exitSurveyObjectiveCompletionLoading = useSelector((state: RootStateOrAny) => state.loading.exit_survey_objective_completion);
  const {
    Current: exitSurveyFeedbackCurrent,
    PoP: exitSurveyFeedbackPop,
    YoY: exitSurveyFeedbackYoy,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.exit_survey_feedback);
  const exitSurveyFeedbackLoading = useSelector((state: RootStateOrAny) => state.loading.je_exit_survey_feedback);

  return (
    <div className="customer-experience-row">
      <div className="customer-experience-dxs">
        <div className="title">
          CUSTOMER EXPERIENCE - DXS
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={"Evaluation that customers have from the website. Scores can go from 0 to 10."}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <CustomerExperienceTile
          title=""
          mainValue={customerExperienceDxsCurrent?.customer_experience}
          yoyValue={customerExperienceDxsYoy?.pts_customer_experience}
          popValue={customerExperienceDxsPop?.pts_customer_experience}
          isRating={true}
          maxRatingValue={10}
          isLoading={customerExperienceDxsLoading}
          comparisonIsPercentage={true}
        />
      </div>
      <div className="website-star-rating">
        <div className="title">WEBSITE STAR RATING</div>
        <div className="website-star-rating-row">
          <CustomerExperienceTile
            title="Average rating"
            mainValue={starRatingAverageCurrent?.experience_rating}
            yoyValue={starRatingAverageYoy?.pts_experience_rating}
            popValue={starRatingAveragePop?.pts_experience_rating}
            isRating={true}
            maxRatingValue={5}
            isLoading={starRatingAverageLoading}
            tooltipText={"Average out of 5 of all ratings given by users to evaluate their experience on the site"}
          />
          <CustomerExperienceTile
            title="# feedbacks"
            mainValue={starRatingFeedbackCurrent?.feedback}
            yoyValue={starRatingFeedbackYoy?.pts_feedback}
            popValue={starRatingFeedbackPop?.pts_feedback}
            isLoading={starRatingFeedbackLoading}
            tooltipText={"Number of people who rated their experience"}
          />
        </div>
      </div>
      <div className="exit-survey">
        <div className="title">EXIT SURVEY</div>
        <div className="exit-survey-row">
          <CustomerExperienceTile
            title="Proportional objective completion"
            mainValue={exitSurveyObjectiveCompletionCurrent?.objective_completion}
            isPercentage={true}
            yoyValue={exitSurveyObjectiveCompletionYoy?.pts_objective_completion}
            popValue={exitSurveyObjectiveCompletionPop?.pts_objective_completion}
            isLoading={exitSurveyObjectiveCompletionLoading}
            comparisonIsPts={true}
            tooltipText={"People who claimed they achieved their objective on the website"}
          />
          <CustomerExperienceTile
            title="# feedbacks"
            mainValue={exitSurveyFeedbackCurrent?.feedback}
            yoyValue={exitSurveyFeedbackYoy?.pts_feedback}
            popValue={exitSurveyFeedbackPop?.pts_feedback}
            isLoading={exitSurveyFeedbackLoading}
            tooltipText={"Number of people who answered to the survey"}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerExperience;
