import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DmeoContext } from "../context/DmeoContext";

interface Props {
  title: string;
}

const DmeoTitle = (props: Props) => {
  const { title } = props;
  const { metric, setMetric } = useContext(DmeoContext);
  const [activeTitle, setActiveTitle] = useState(false);

  useEffect(() => {
    setActiveTitle(metric === title);
  }, [metric, title]);

  const updateMetric = useCallback(() => {
    metric === title ? setMetric("") : setMetric(title);
  }, [metric, title]);

  return (
    <div className={`section-title journey-excellence-row ${activeTitle ? "active" : ""}`} onClick={updateMetric}>
      <p className="title">{title}</p>
      <ArrowCircleRightOutlinedIcon style={{ color: "#00a0dd" }} />
    </div>
  );
};

export default DmeoTitle;
