import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getExecutiveSummaryKeyIndicators } from "../../../../api/executiveSummaryWebsiteApi";
import { NoDataMsg } from "../../../../components/AppMessages";
import ExecutiveSummaryMapChart from "../../../../components/Charts/ExecutiveSummary/ExecutiveSummaryMapChart";
import { DefaultFilter } from "../../../../components/Filters/common";
import { closeFilterOptions } from "../../../../components/Filters/subs/helpers";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { DATE_FILTER_VALUE_MAPPING } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import { eventTracking, MixpanelEvents } from "../../../../utils/userTracking";

const metricMapping: Record<string, string> = {
  "Private Sales": "private_sales",
  "DIS Volume": "dis_vol",
  "DIS %": "dis_perc",
  "Close Rate": "close_rate",
};

const KeyIndicators = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);
  const { date_range } = useSelector((state: RootStateOrAny) => state.parameters);

  const [metricFilterValue, setMetricFilterValue] = useState("Private Sales");
  const [varianceFilterValue, setVarianceFilterValue] = useState("YoY");
  const [dateString, setDateString] = useState("");

  const {
    data,
    refetch,
    isFetching: isLoading,
  } = useQuery({
    queryKey: ["keyIndicatorsData", metricMapping[metricFilterValue], varianceFilterValue.toLowerCase(), history.location.search],
    queryFn: () => getExecutiveSummaryKeyIndicators(metricMapping[metricFilterValue], varianceFilterValue.toLowerCase()),
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search, metricFilterValue, varianceFilterValue]);

  useEffect(() => {
    if (Object.keys(DATE_FILTER_VALUE_MAPPING).includes(date_range)) {
      setDateString(DATE_FILTER_VALUE_MAPPING[date_range]);
    } else {
      const [start, end] = date_range.split("-");
      const startDate = moment(start, "MMMM YYYY").format("MMM YYYY");
      const endDate = moment(end, "MMMM YYYY").format("MMM YYYY");
      setDateString(`${startDate} - ${endDate}`);
    }
  }, [date_range]);

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      filterName == "metric" ? setMetricFilterValue(optionValue) : setVarianceFilterValue(optionValue);
      eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "Executive Summary" });
    }
    closeFilterOptions();
  }, []);

  return (
    <div className="tile map-tile">
      <div className="tile-header">
        <div className="title">Global Key Indicators</div>
        <div className="filters-container">
          <DefaultFilter
            list={["Private Sales", "DIS Volume", "DIS %", "Close Rate"]}
            filterName={"metric"}
            filterLabel={"METRIC FILTER BY"}
            filterValue={metricFilterValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
          <DefaultFilter
            list={["YoY", "MoM"]}
            filterName={"variance"}
            filterLabel={"VARIANCE BY"}
            filterValue={varianceFilterValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </div>
      </div>
      {data && data.length > 0 ? (
        <ExecutiveSummaryMapChart
          chartId="keyIndicatorsMapChart"
          data={data}
          metric={metricFilterValue}
          variance={varianceFilterValue}
          currentPeriodLabel={dateString}
          isLoading={isLoading}
          theme={theme}
        />
      ) : (
        <NoDataMsg />
      )}

      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
});

export default KeyIndicators;
