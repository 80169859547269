import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Page404 } from "../../../pages/Error Pages/Login";

// This component redirects the user to either their default homepage or the login page if they are not logged in
export const HomeRedirect = withRouter((props: RouteComponentProps) => {
  const { history, location } = props;
  const [component, setComponent] = useState<JSX.Element>(<></>);

  useEffect(() => {
    const hasToken = Cookies.get("access_token");
    const defaultUrl = Cookies.get("default_url");
    const { pathname } = location;

    if (pathname === "/") {
      if (hasToken) {
        const splitDefaultUrl = defaultUrl?.split("?");
        defaultUrl
          ? history.push({ pathname: splitDefaultUrl?.[0], search: `${splitDefaultUrl?.[1]}` })
          : history.push({ pathname: "/home" });
      } else {
        history.push({ pathname: "/login" });
      }
    } else {
      setComponent(Page404);
    }
  }, []);
  return component;
});
