import { CategoryAxis, ColumnSeries, ValueAxis, XYChart, XYCursor } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { CYAN } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import { columnSeriesConfiguration, configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  seriesName: string;
  dataKey: string;
  data: Array<{ title: string; value: number; color: string }>;
  theme: ThemeInt;
}

class ValueBarChart extends Component<Props> {
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.props.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.props.chartId);
  }

  initChart() {
    const { data, chartId, dataKey, seriesName, theme } = this.props;

    this.chart = create(chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.colors.list = [color(CYAN)];

    // Creating Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "label";
    categoryAxis.cursorTooltipEnabled = false;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.numberFormatter.numberFormat = "#a";

    // Creating Series
    const series = this.chart.series.push(new ColumnSeries());
    columnSeriesConfiguration(series, {
      name: seriesName,
      valueY: dataKey,
    });
    series.dataFields.categoryX = "label";
    series.tooltipText = "{categoryX}: {valueY.formatNumber('#,###.')}";

    this.chart.cursor = new XYCursor();
    this.chart.cursor.lineX.disabled = true;
    this.chart.cursor.lineY.disabled = true;
  }
  render() {
    return <div id={this.props.chartId} className={"metricChart"} />;
  }
}

export default ValueBarChart;
