import {
  add,
  addQuarters,
  endOfMonth,
  endOfQuarter,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  sub,
  subMonths,
  subQuarters,
} from "date-fns";
import { ALL_OPTION_NO_SPACE } from "../constants";
import { store } from "../store";
import { convertToMetricType } from "../utils/annotationFunctions";
import { fetchDataV2 } from "./utils";

export interface AnnotationsQueryParams {
  startDate: string;
  endDate: string;
  brand: string;
  regions: string[];
  markets: string[];
  model: string;
  channel: string;
  metric: string;
  view: string;
}

const MTM_ANNOTATIONS_PARAMS = ["start_date", "end_date", "markets", "models", "view", "approval_status"];

export const getAnnotations = (parameters: any, dashboard: string) => {
  const { kba_types, lead_types } = store.getState().digital_performance;
  const metricTypes = [...convertToMetricType(kba_types), ...convertToMetricType(lead_types)];
  let startDate;
  let endDate;
  if (parameters.date_range == "Last week") {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    endDate = add(startOfWeek(date), { days: 6 });
    startDate = sub(new Date(), { weeks: 52 });
  } else if (parameters.date_range == "This month") {
    startDate = startOfMonth(new Date());
    endDate = endOfMonth(new Date());
  } else if (parameters.date_range == "Last month") {
    startDate = startOfMonth(sub(new Date(), { months: 1 }));
    endDate = endOfMonth(sub(new Date(), { months: 1 }));
  } else if (parameters.date_range == "This quarter") {
    startDate = startOfQuarter(new Date());
    endDate = endOfQuarter(new Date());
  } else if (parameters.date_range == "Last quarter") {
    const dateInLastQuarter = sub(new Date(), { months: 3 });
    startDate = startOfQuarter(dateInLastQuarter);
    endDate = endOfQuarter(dateInLastQuarter);
  } else if (parameters.date_range == "This financial year") {
    const today = new Date();
    startDate =
      today > new Date(today.getFullYear(), 2, 31) ? new Date(today.getFullYear(), 3, 1) : new Date(today.getFullYear() - 1, 3, 1);
    endDate =
      today >= new Date(today.getFullYear(), 3, 1) ? new Date(today.getFullYear() + 1, 2, 31) : new Date(today.getFullYear(), 2, 31);
  } else if (parameters.date_range == "Last financial year") {
    const today = new Date();
    startDate =
      today > new Date(today.getFullYear(), 2, 31) ? new Date(today.getFullYear() - 1, 3, 1) : new Date(today.getFullYear() - 2, 3, 1);
    endDate =
      today >= new Date(today.getFullYear(), 3, 1) ? new Date(today.getFullYear(), 2, 31) : new Date(today.getFullYear() - 1, 2, 31);
  } else if (parameters.date_range == "Last 6 months") {
    const today = new Date();
    startDate = startOfMonth(subMonths(today, 6));
    endDate = endOfMonth(today);
  } else if (parameters.date_range == "Q2 20 - Q1 22") {
    const today = new Date();
    endDate = endOfQuarter(addQuarters(today, 1));
    startDate = startOfQuarter(subQuarters(endDate, 4));
  } else if (parameters.date_range != "") {
    const startDateArray = parameters.date_range.split("-")[0].trim().split("/");
    const endDateArray = parameters.date_range.split("-")[1].trim().split("/");
    startDate = new Date(startDateArray[2], startDateArray[1] - 1, startDateArray[0]);
    endDate = new Date(endDateArray[2], endDateArray[1] - 1, endDateArray[0]);
  }
  const annotationsParams = {
    start_date: startDate ? format(startDate, "Y-MM-dd") : "",
    end_date: endDate ? format(endDate, "Y-MM-dd") : "",
    brand: parameters.brand,
    regions: parameters.region ? parameters.region.trim().split(",") : ALL_OPTION_NO_SPACE,
    markets: parameters.market ? (parameters.market == "All countries" ? ["All"] : parameters.market.trim().split(",")) : [],
    models: parameters.model ? (parameters.model == "All" ? ALL_OPTION_NO_SPACE : parameters.model.trim().split(",")) : [],
    channels: parameters.last_touch_marketing_channel ? parameters.last_touch_marketing_channel.trim() : undefined,
    channel_breakdowns: parameters.last_touch_marketing_channel_breakdown
      ? parameters.last_touch_marketing_channel_breakdown.trim()
      : undefined,
    metrics: parameters.metric ? parameters.metric.split(",") : undefined,
    view: dashboard,
    tags: parameters.tag,
    lead_sources: parameters.lead_source,
    metric_types: parameters?.specific_kba
      ? metricTypes.find((metric) => metric.metric_type.toLowerCase() == parameters.specific_kba.toLowerCase())?.metric_type
      : parameters?.specific_lead
      ? metricTypes.find((metric) => metric.metric_type.toLowerCase() == parameters.specific_lead.toLowerCase())?.metric_type
      : undefined,
  };

  if (dashboard == "purchase_funnel") {
    delete annotationsParams.models;
    delete annotationsParams.channels;
    delete annotationsParams.channel_breakdowns;
    delete annotationsParams.metrics;
    delete annotationsParams.tags;
    delete annotationsParams.metric_types;
    delete annotationsParams.regions;
  }

  if (dashboard == "mtm_metrics_that_matter") {
    for (const key in annotationsParams) {
      // @ts-ignore
      !MTM_ANNOTATIONS_PARAMS.includes(key) && delete annotationsParams[key];
    }
  }

  const getQueryParamString = (params: any) => {
    let str = "";
    const keys = Object.keys(params);

    keys.forEach((key, index) => {
      if (params[`${key}`]) {
        if (typeof params[`${key}`] == "object") {
          params[`${key}`] = params[`${key}`].join(`&${key}=`);
        }
        if (index == 0) {
          str += `${key}=${params[key]}`;
        } else {
          str += `&${key}=${params[key]}`;
        }
      }
    });

    return str + `&approval_status=approved`;
  };

  return fetchDataV2(`annotation/get-annotation-filtered?${getQueryParamString(annotationsParams)}`);
};

export const getOCEKbaTypes = () => fetchDataV2("/ocx/get-all-kba-types");
export const getOCELeadTypes = () => fetchDataV2("/ocx/get-all-lead-types");
