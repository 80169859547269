import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, Tooltip } from "@amcharts/amcharts4/core";
import { PointedCircle } from "@amcharts/amcharts4/plugins/bullets";
import { format } from "date-fns";
import Cookies from "js-cookie";
import _ from "lodash";
import moment from "moment";
import React, { Component, Dispatch, SetStateAction } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import {
  BLACK,
  BLUE,
  CYAN,
  DATE_FILTER_VALUE_MAPPING,
  OCE_CHART_COLORS,
  OCE_METRIC_CONVERSIONS,
  OCE_METRIC_CONVERSION_SERIES_NAMES,
  OCE_SERIES,
  varianceMetricTypes,
  WHITE,
} from "../../../constants";
import { Annotation } from "../../../pages/Admin/types";
import { getFiscalYearTimestamps, getFYQuarter, getStartEnd } from "../../../utils/dateFunctions";
import ShareAnnotationModal from "../../Annotations/Dialogs/ShareAnnotationModal";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { getTooltipTextFormat } from "../helpers/helpers";
import { legendConfigurationOCE, oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  activeMetric: varianceMetricTypes;
  granularity: string;
  dateValue: string;
  newCarsChecked?: boolean;
  isCpoDashboard?: boolean;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
  toggleAnnotationsDialog: () => void;
  theme: ThemeInt;
  seriesList: Array<varianceMetricTypes>;
  isLastWeek: boolean;
  isVolume: boolean;
}

interface State {
  highlightDataStartDate: string | undefined;
  highlightDataEndDate: string | undefined;
  shareModalOpen: boolean;
  annotations: Annotation[] | undefined;
}

class FullFiguresChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { highlightDataStartDate: undefined, highlightDataEndDate: undefined, shareModalOpen: false, annotations: undefined };

    this.toogleShareModal = this.toogleShareModal.bind(this);
  }

  static defaultProps = {
    granularity: "daily",
  };

  chartId = "fullFiguresGraph";
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any, prevState: State) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props) || !_.isEqual(prevState, this.state)) {
      disposeChart(this.chartId);
      setTimeout(() => this.initChart(), 1500);
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  setHighlightDataDates(startDate: string | undefined, endDate: string | undefined) {
    this.setState({
      highlightDataStartDate: startDate,
      highlightDataEndDate: endDate,
    });
  }

  toogleShareModal() {
    this.setState({
      shareModalOpen: !this.state.shareModalOpen,
    });
  }

  handleShareAnnotation(annotations: Annotation[]) {
    this.setState({
      annotations: annotations,
    });
    this.toogleShareModal();
  }

  initChart() {
    const { data, granularity, dateValue, theme, seriesList, activeMetric, isCpoDashboard, isLastWeek, isVolume } = this.props;

    const isMultiFY =
      getFiscalYearTimestamps(moment(data[0]?.ref_date).format("DD/MM/YYYY")).current.start.substr(-2, 2) !==
      getFiscalYearTimestamps(moment(data[data?.length - 1]?.ref_date).format("DD/MM/YYYY")).current.start.substr(-2, 2);

    // Current and previous chart labels
    const previousPeriod = moment(getStartEnd(dateValue).end, "DD/MM/YYYY").subtract(1, "years").format("MMMM YYYY");

    const currentLabel = dateValue.includes("week")
      ? "Selected period"
      : isMultiFY
      ? "Selected period"
      : dateValue.includes("month") || dateValue.includes("quarter")
      ? DATE_FILTER_VALUE_MAPPING[dateValue]
      : `FY${getFiscalYearTimestamps(getStartEnd(dateValue).start).current.start.substr(-2, 2)}`;

    const previousLabel = dateValue.includes("week")
      ? "Previous period"
      : isMultiFY
      ? "Previous year"
      : dateValue.includes("month")
      ? previousPeriod
      : dateValue.includes("quarter")
      ? getFYQuarter(previousPeriod)
      : `FY${getFiscalYearTimestamps(getStartEnd(dateValue).end).last.start.substr(-2, 2)}`;

    // Global chart configuration
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    this.chart.data = data;
    // Creates Legend
    this.chart.legend = legendConfigurationOCE(theme);
    this.chart.colors.list = OCE_CHART_COLORS.map((chartColor) => color(chartColor));

    // Create data chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.cellStartLocation = 0.5;
    dateAxis.renderer.cellEndLocation = 0.8;

    // 2nd date axis for comparison data
    const dateAxis2 = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis2, theme);
    dateAxis2.renderer.labels.template.disabled = true;
    dateAxis2.renderer.cellStartLocation = 0.2;
    dateAxis2.renderer.cellEndLocation = 0.5;
    dateAxis.renderer.minGridDistance = 100;

    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    if (seriesList?.length > 1 && isVolume) {
      valueAxis.max = 1;
      valueAxis.maxPrecision = 0;
    }
    valueAxis.extraMax = 0.1;
    valueAxis.numberFormatter.numberFormat = "#.#a";
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + `${isVolume ? "" : "%"}`;
    });

    seriesList?.forEach((metric, idx) => {
      const userViewsList = Cookies.get("views");
      const series = this.chart.series.push(new LineSeries());
      series.data = data.Current;

      const { toggleAnnotationsDialog, setAnnotationStartDate, setAnnotationEndDate, setAnnotationDefaultMetric } = this.props;
      const bullet = lineSeriesConfiguration(series, {
        valueY: `${
          !isVolume && !isCpoDashboard ? OCE_METRIC_CONVERSIONS[metric] : seriesList.length === 1 ? activeMetric : `normalized_${metric}`
        }`,
        name: `${isVolume ? OCE_SERIES[metric].seriesName : OCE_METRIC_CONVERSION_SERIES_NAMES[metric]}`,
      });
      series.xAxis = dateAxis;
      series.dataFields.dateX = "row_date";
      if (idx === seriesList.length - 1) {
        series.stroke = color(BLUE);
        series.fill = color(BLUE);
      }

      bullet.tooltipText = `[bold; font-size: var(--regular_font_size);]{dateX.formatDate('dd MMM YYYY')} [/ font-size: var(--regular_font_size);]: {valueY.formatNumber(${
        seriesList?.length > 2 ? "0|#.#" : "#."
      })}`;

      if (!this.props.isCpoDashboard && userViewsList?.includes("annotations")) {
        bullet.events.on("hit", (ev: any) => {
          const startDate = new Date(ev.target.dataItem.dateX);
          const endDate = new Date(startDate);
          endDate.setDate(startDate.getDate() + 6);
          setAnnotationStartDate(startDate);
          setAnnotationEndDate(endDate);
          setAnnotationDefaultMetric(metric);
          toggleAnnotationsDialog();
        });
      }

      // display annotations
      // @ts-ignore
      const annotationBullet = series.bullets.push(new PointedCircle());
      annotationBullet.fill = color("#FFF");
      annotationBullet.tooltip = new Tooltip();
      annotationBullet.tooltip.align = "right";
      annotationBullet.tooltip.pointerOrientation = "left";
      annotationBullet.tooltip.getFillFromObject = false;
      annotationBullet.tooltip.background.fill = color(BLACK);
      annotationBullet.tooltip.getStrokeFromObject = true;
      annotationBullet.tooltip.strokeWidth = 10;
      annotationBullet.tooltip.interactionsEnabled = true;
      annotationBullet.tooltip.keepTargetHover = true;
      annotationBullet.radius = 4;
      annotationBullet.tooltipHTML = ``;
      annotationBullet.adapter.add("tooltipHTML", function (html: any, target: any) {
        if (target.dataItem?.dataContext?.ref_annotations) {
          target.dataItem?.dataContext?.ref_annotations.forEach((annotation: Annotation, index: number) => {
            //get annotation parameters
            const parameters = [
              annotation.regions.map((region) => region.region),
              annotation.markets.map((market) => market.market),
              annotation.models.map((model) => model.model),
              annotation.channels.map((channel) => channel.channel),
              annotation.channel_breakdowns.map((channel_breakdown) => channel_breakdown.channel_breakdown),
            ]
              .flat()
              .filter((param) => param != "All");
            const startDate = new Date(annotation.start_date);
            const endDate = new Date(annotation.end_date);
            index > 0 ? (html += `<hr style="border-color: ${WHITE}; margin-top: 20px;"/>`) : false;
            html += `<div style="margin-top: 5px; margin-bottom: 5px;">
              <p style="font-size: var(--smaller_regular_font_size);">${format(startDate, "d LLL Y")} - ${format(endDate, "d LLL Y")}</p>
              <p style="font-size: var(--smaller_regular_font_size);">${parameters.length > 0 ? parameters.join(", ") : ""}</p>
              <p style="font-size: var(--regular_font_size); margin-top: 10px;">${annotation.insight}</p>
              ${annotation.tags.map(
                (tag) =>
                  `<span style="background-color: ${CYAN}; color: ${WHITE}; padding: 0px 5px; margin-right: 5px; font-size: var(--smaller_regular_font_size);">${tag.tag}</span>`
              )}
            </div>`;
          });
        }
        html += `
          <div style="text-align: center; margin-top: 20px;">
            <hr style="border-color: ${CYAN};"/>
            <p style="color: ${CYAN}; font-size: var(--smaller_regular_font_size); margin: 10px 0px;">Share insights</p>
          </div>
        `;
        return html;
      });
      annotationBullet.tooltip.events.on("hit", (event) => {
        //@ts-ignore
        const annotations = event.target.dataItem?.dataContext?.ref_annotations;
        this.handleShareAnnotation(annotations);
      });
      annotationBullet.adapter.add("disabled", function (disabled: any, target: any) {
        if (!target.dataItem) {
          return disabled;
        }
        if (target.dataItem?.dataContext?.ref_annotations) {
          const metricAnnotations: Annotation[] = target.dataItem?.dataContext?.ref_annotations.filter((annotation: Annotation) =>
            annotation.metrics.flatMap((annotationMetric) => annotationMetric.metric).includes(metric)
          );
          if (metricAnnotations.length > 0) {
            return disabled;
          }
          return true;
        } else {
          return true;
        }
      });

      bullet.tooltipText = getTooltipTextFormat(granularity, seriesList.length > 1);
      if (seriesList.length === 1) {
        const series2 = this.chart.series.push(new LineSeries());
        series2.data = data.YoY;
        const bullet1 = lineSeriesConfiguration(series2, {
          valueY: `${!isVolume && !this.props.isCpoDashboard ? OCE_METRIC_CONVERSIONS[metric] : `${activeMetric}`}`,
          name: `${previousLabel}`,
        });

        series2.dataFields.dateX = "row_date";
        series2.xAxis = dateAxis2;

        series2.stroke = color(theme === "light" ? BLACK : WHITE);
        series2.fill = color(theme === "light" ? BLACK : WHITE);

        bullet1.tooltipText = `[bold; font-size: var(--regular_font_size);] {dateX.formatDate('dd MMM YYYY')} [/ font-size: var(--regular_font_size);]: {valueY.formatNumber(${
          seriesList?.length > 1 ? "#.#" : "#."
        })}`;

        if (userViewsList?.includes("annotations")) {
          bullet1.events.on("hit", (ev: any) => {
            const startDate = new Date(ev.target.dataItem.dateX);
            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + 6);
            setAnnotationStartDate(startDate);
            setAnnotationEndDate(endDate);
            setAnnotationDefaultMetric(metric);
            toggleAnnotationsDialog();
          });
        }

        // display annotations
        // @ts-ignore
        const annotationBullet2 = series2.bullets.push(new PointedCircle());
        annotationBullet2.fill = color("#FFF");
        annotationBullet2.tooltip = new Tooltip();
        annotationBullet2.tooltip.align = "right";
        annotationBullet2.tooltip.pointerOrientation = "left";
        annotationBullet2.tooltip.getFillFromObject = false;
        annotationBullet2.tooltip.background.fill = color(BLACK);
        annotationBullet2.tooltip.getStrokeFromObject = true;
        annotationBullet2.tooltip.strokeWidth = 10;
        annotationBullet2.radius = 4;
        annotationBullet2.tooltip.interactionsEnabled = true;
        annotationBullet2.tooltip.keepTargetHover = true;
        annotationBullet2.tooltipHTML = ``;
        annotationBullet2.adapter.add("tooltipHTML", function (html: any, target: any) {
          if (target.dataItem?.dataContext?.comparison_annotations) {
            target.dataItem?.dataContext?.comparison_annotations.forEach((annotation: Annotation, index: number) => {
              //get annotation parameters
              const parameters = [
                annotation.regions.map((region) => region.region),
                annotation.markets.map((market) => market.market),
                annotation.models.map((model) => model.model),
                annotation.channels.map((channel) => channel.channel),
                annotation.channel_breakdowns.map((channel_breakdown) => channel_breakdown.channel_breakdown),
              ]
                .flat()
                .filter((param) => param != "All");
              const startDate = new Date(annotation.start_date);
              const endDate = new Date(annotation.end_date);
              index > 0 ? (html += `<hr style="border-color: ${WHITE}; margin-top: 20px;"/>`) : false;
              html += `<div style="margin-top: 5px; margin-bottom: 5px;">
                <p style="font-size: var(--smaller_regular_font_size);">${format(startDate, "d LLL Y")} - ${format(endDate, "d LLL Y")}</p>
                <p style="font-size: var(--smaller_regular_font_size);">${parameters.length > 0 ? parameters.join(", ") : ""}</p>
                <p style="font-size: var(--regular_font_size); margin-top: 10px;">${annotation.insight}</p>
                ${annotation.tags.map(
                  (tag) =>
                    `<span style="background-color: ${CYAN}; color: ${WHITE}; padding: 0px 5px; margin-right: 5px; font-size: var(--smaller_regular_font_size);">${tag.tag}</span>`
                )}
              </div>`;
            });
          }
          html += `
            <div style="text-align: center; margin-top: 20px;">
              <hr style="border-color: ${CYAN};"/>
              <p style="color: ${CYAN}; font-size: var(--smaller_regular_font_size); margin: 10px 0px;">Share insights</p>
            </div>
          `;
          return html;
        });
        annotationBullet2.tooltip.events.on("hit", (event) => {
          //@ts-ignore
          const annotations = dataItem?.dataContext?.comparison_annotations;
          this.handleShareAnnotation(annotations);
        });
        annotationBullet2.adapter.add("disabled", function (disabled: any, target: any) {
          if (!target.dataItem) {
            return disabled;
          }
          if (target.dataItem?.dataContext?.comparison_annotations) {
            const metricAnnotations: Annotation[] = target.dataItem?.dataContext?.comparison_annotations.filter((annotation: Annotation) =>
              annotation.metrics.flatMap((annotationMetric) => annotationMetric.metric).includes(metric)
            );

            if (metricAnnotations.length > 0) {
              return disabled;
            }
            return true;
          } else {
            return true;
          }
        });
        bullet1.tooltipText = getTooltipTextFormat(granularity, seriesList.length > 1);
      }
    });
  }
  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={"fullFiguresGraph"} data-test-id={"fullFiguresGraph"} className={"graph"} />
        <ShareAnnotationModal
          open={this.state.shareModalOpen}
          handleToogleDialog={this.toogleShareModal}
          annotations={this.state.annotations}
        />
      </div>
    );
  }
}

export default FullFiguresChart;
