import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "../../assets/styles/component/menu.scss";

export const InfoMenu = withRouter((props: RouteComponentProps) => {
  const { history, location } = props;

  const isDashboardInfoRe = new RegExp("dashboard_info", "gi");
  const isDataInfoRe = new RegExp("data_info", "gi");
  const isReleaseNoteRe = new RegExp("release_notes", "gi");

  // Handles page navigation
  const onMenuLinkNavigationHandler = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();

    const {
      currentTarget: { dataset },
    } = evt;
    const pageLink = dataset?.page;

    history.push({ pathname: pageLink, search: "" });
  };

  return (
    <div className="info_dashboard_options">
      <a
        className={isDashboardInfoRe.test(location.pathname) ? "active" : ""}
        data-page="/dashboard_info"
        onClick={onMenuLinkNavigationHandler}
      >
        Dashboards info
      </a>
      <a className={isDataInfoRe.test(location.pathname) ? "active" : ""} data-page="/data_info" onClick={onMenuLinkNavigationHandler}>
        Data info
      </a>
      <a
        className={isReleaseNoteRe.test(location.pathname) ? "active" : ""}
        data-page="/release_notes"
        onClick={onMenuLinkNavigationHandler}
      >
        Release notes
      </a>
    </div>
  );
});
