import { marketingChannelsData } from "../constants/interface";
import { FETCH_CHANNELS, SET_AVAILABLE_CHANNELS, SET_MARKETING_CHANNELS_DATA } from "./actionTypes";

export const fetchMarketingChannelData = () => {
  return { type: FETCH_CHANNELS };
};

export const setMarketingChannelData = (data: marketingChannelsData) => {
  return { type: SET_MARKETING_CHANNELS_DATA, payload: data };
};

export const setAvailableMarketingChannels = (data: string[]) => {
  return { type: SET_AVAILABLE_CHANNELS, payload: data };
};
