import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { DefaultTable } from "../../../components/Tables/subs";

const percentageRows = ["% of total visits", "V > KBA visits", "V > Lead visits"];

const DigitalPerformanceTable = () => {
  const digitalPerformanceData = useSelector((state: RootStateOrAny) => state.product_performance.digital_performance);
  const { product } = useSelector((state: RootStateOrAny) => state.parameters);

  const columns = useMemo(
    () => [
      {
        id: "metric",
        Header: "",
        accessor: "metric",
        disableSortBy: true,
      },
      {
        id: "product_visits",
        Header: `${product}`,
        accessor: "product_visits",
        disableSortBy: true,
        Cell: (cell: { value: number; row: any }) => {
          const { value } = cell;
          return value ? `${value.toLocaleString()} ${percentageRows.includes(cell.row.original.metric) ? "%" : ""}` : "-";
        },
      },
      {
        id: "all_visits",
        Header: "All visits",
        accessor: "all_visits",
        disableSortBy: true,
        Cell: (cell: { value: number; row: any }) => {
          const { value } = cell;
          return value ? `${value.toLocaleString()} ${percentageRows.includes(cell.row.original.metric) ? "%" : ""}` : "-";
        },
      },
    ],
    [product]
  );

  const tableData = useMemo(() => {
    const rows = [
      "V > Lead visits",
      "Visits submitting a Lead",
      "V > KBA visits",
      "Visits performing a KBA",
      "% of total visits",
      "Visits",
    ];
    const productVisits = digitalPerformanceData.find((item: any) => item.key == product);
    const allVisits = digitalPerformanceData.find((item: any) => item.key == "All visits");

    if (digitalPerformanceData.length > 0 && productVisits) {
      return rows.map((metric: string) => {
        return {
          metric,
          product_visits: productVisits[metric],
          all_visits: allVisits[metric],
        };
      });
    }
    return [];
  }, [digitalPerformanceData, product]);

  return (
    <DefaultTable
      columns={columns}
      data={tableData}
      id="digitalPerformanceTable"
      hiddenCols={[]}
      isChildComponent={false}
      initialSortColId={{ id: "none", desc: false }}
    />
  );
};

export default DigitalPerformanceTable;
