import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  fetchProductJourneyEntryPoints,
  fetchProductJourneyExitPoints,
  fetchProductJourneyPerformanceOverview,
  fetchProductJourneyWebsiteEntryPoints,
} from "../../../../actions/journeyExcellenceActions";
import JourneyExcellenceLineTrendChart from "../../../../components/Charts/JourneyExcellence/JourneyExcellenceLineTrendChart";
import Collapsible from "../../../../components/Collapsible/Collapsible";
import { DefaultFilter } from "../../../../components/Filters/common";
import { closeFilterOptions } from "../../../../components/Filters/subs/helpers";
import { FilterContext, ThemeContext } from "../../../../context";
import { eventTracking, MixpanelEvents } from "../../../../utils/userTracking";
import DonutChartTile from "../tiles/DonutChartTile";

const ProductJourneyContent = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { setShowFilterOptions } = useContext(FilterContext);

  const { series: performanceOverviewSeries, data: performanceOverviewData } = useSelector(
    (state: RootStateOrAny) => state.journey_excellence.product_journey_performance_overview
  );
  const entryPointsData = useSelector((state: RootStateOrAny) => state.journey_excellence.product_journey_entry_points);
  const websiteEntryPointsData = useSelector((state: RootStateOrAny) => state.journey_excellence.product_journey_website_entry_points);

  const [trendMetric, setTrendMetric] = useState("Visits");

  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Journey Excellence", page: "Product Journey" });
  }, []);

  useEffect(() => {
    dispatch(fetchProductJourneyEntryPoints());
    dispatch(fetchProductJourneyExitPoints());
    dispatch(fetchProductJourneyWebsiteEntryPoints());
  }, [history.location.search]);

  useEffect(() => {
    dispatch(fetchProductJourneyPerformanceOverview(trendMetric));
  }, [history.location.search, trendMetric]);

  const handleMetricFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionValue = dataset?.value;
    if (optionValue) setTrendMetric(optionValue);
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  const handleBtnClick = useCallback(() => {
    history.push("/product_performance");
  }, []);

  return (
    <div className="journey-excellence-product-journey">
      <Collapsible
        title="Product website performance overview"
        tooltipText=""
        show={true}
        tooltipHtml={
          <>
            <p>Website visits: Counts every time the site has at least one page fully loaded for the selected product</p>
            <br />
            <p>
              KBAS: Key buying actions for the specific product: model detail comparison, saved searches views, compare tools views, dealer
              search, etc.
            </p>
            <br />
            <p>Leads visits: Volume of visits including one digital lead in the product page</p>
          </>
        }
      >
        <div className="product-website-performance-overview tile">
          <div className="tile-header">
            <div className="title"></div>
            <DefaultFilter
              list={["Visits", "Kbas", "Leads"]}
              filterName={"metric"}
              filterLabel={"Select Metric"}
              filterValue={trendMetric}
              handleFilterOptionClick={handleMetricFilterOptionClick}
            />
          </div>
          <div className="tile-content">
            <JourneyExcellenceLineTrendChart
              chartId="performanceWebsiteChart"
              seriesList={performanceOverviewSeries}
              data={performanceOverviewData}
              theme={theme}
              isLoading={false}
            />
          </div>
        </div>
      </Collapsible>
      <Collapsible title="Website entry points" tooltipText="Most popular entry points to the website" show={true}>
        <div className="donut-charts-row">
          {websiteEntryPointsData &&
            Object.entries(websiteEntryPointsData).map(([key, value]: any) => (
              <DonutChartTile
                key={key}
                chartId={`${key}EntryDonutChart`}
                title={key}
                tooltipText=""
                data={value}
                valueField="perc_visits"
                categoryField="entry_page_mapped"
              />
            ))}
        </div>
      </Collapsible>
      <Collapsible title="Most popular entry points by PACE product" tooltipText="Top Landing page per product" show={true}>
        <div className="donut-charts-row">
          {entryPointsData &&
            Object.entries(entryPointsData).map(([key, value]: any) => (
              <DonutChartTile
                key={key}
                chartId={`${key}EntryDonutChart`}
                title={key}
                tooltipText=""
                data={value}
                valueField="perc_visits"
                categoryField="entry_page_mapped"
              />
            ))}
        </div>
      </Collapsible>
      <div className="more-info">
        <div>For more information access:</div>
        <button className="more-info-btn" onClick={handleBtnClick}>
          Product Performance Dashboard
        </button>
      </div>
    </div>
  );
});

export default ProductJourneyContent;
