import React, { useContext, useEffect } from "react";
import DmeoDigitalSpendByChannelChart from "../../../components/Charts/Dmeo/DmeoDigitalSpendByChannelChart";
import { ThemeContext } from "../../../context";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getDmeoDigitalSpendByChannel } from "../../../api/dmeoApi";
import { useQuery } from "@tanstack/react-query";

const data = [
  {
    category: "Display",
    value: 6800000,
  },
  {
    category: "Search",
    value: 4000000,
  },
  {
    category: "Programmatic",
    value: 9000000,
  },
  {
    category: "Social",
    value: 3800000,
  },
];

const DigitalSpendByChannel = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { theme } = useContext(ThemeContext);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["digitalSpendByChanelObjectiveDonutData"],
    queryFn: getDmeoDigitalSpendByChannel,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile">
      <div className="tile-header">Digital Spend by Channel</div>
      <div className="tile-content">
        <DmeoDigitalSpendByChannelChart data={data} chartId="dmeoDigitalSpendByChannelBarChart" theme={theme} isLoading={isFetching} />
      </div>
    </div>
  );
});

export default DigitalSpendByChannel;
