import {
  FETCH_FIXED_DATA_BY_QUARTER,
  FETCH_FMI_BY_MODEL_FMI_BY_QUARTER,
  FETCH_FMI_BY_QUARTER,
  FETCH_MEDIA_MIX_DATA_BY_QUARTER,
  FETCH_MTP_CATEGORY_DATA_BY_QUARTER,
  FETCH_MTP_SPEND,
  FETCH_SPEND_VS_TRAFFIC_MARKET_DATA,
  FETCH_SPEND_VS_TRAFFIC_MODEL_DATA,
  FETCH_STRATEGIC_CE_DATA_BY_QUARTER,
  FETCH_STRATEGIC_DEALER_SUPPORT_DATA_BY_QUARTER,
  SET_FIXED_DATA_BY_QUARTER,
  SET_FMI_BY_MODEL_FMI_BY_QUARTER,
  SET_FMI_BY_QUARTER,
  SET_MEDIA_MIX_DATA_BY_QUARTER,
  SET_MTP_CATEGORY_DATA_BY_QUARTER,
  SET_MTP_CATEGORY_HIDE_YR_SCNR_RV,
  SET_MTP_SPEND,
  SET_SPEND_VS_TRAFFIC_MARKET_DATA,
  SET_SPEND_VS_TRAFFIC_MODEL_DATA,
  SET_STRATEGIC_CE_DATA_BY_QUARTER,
  SET_STRATEGIC_DEALER_SUPPORT_DATA_BY_QUARTER,
} from "./actionTypes";

export const fetchMidasFmiByQuarter = () => {
  return { type: FETCH_FMI_BY_QUARTER };
};

export const setMidasFmiByQuarter = (data: any) => {
  return { type: SET_FMI_BY_QUARTER, payload: data };
};

export const fetchFmiByModelFmiByQuarter = () => {
  return { type: FETCH_FMI_BY_MODEL_FMI_BY_QUARTER };
};

export const setFmiByModelFmiByQuarter = (data: any) => {
  return { type: SET_FMI_BY_MODEL_FMI_BY_QUARTER, payload: data };
};

export const fetchMtpSpend = () => {
  return { type: FETCH_MTP_SPEND };
};

export const setMtpSpend = (data: any) => {
  return { type: SET_MTP_SPEND, payload: data };
};

export const fetchMediaMixDataByQuarter = () => {
  return { type: FETCH_MEDIA_MIX_DATA_BY_QUARTER };
};

export const setMediaMixDataByQuarter = (data: any) => {
  return { type: SET_MEDIA_MIX_DATA_BY_QUARTER, payload: data };
};

export const fetchMtpCategoryDataByQuarter = () => {
  return { type: FETCH_MTP_CATEGORY_DATA_BY_QUARTER };
};

export const setMtpCategoryDataByQuarter = (data: any) => {
  return { type: SET_MTP_CATEGORY_DATA_BY_QUARTER, payload: data };
};

export const fetchStrategicDealerSupportDataByQuarter = () => {
  return { type: FETCH_STRATEGIC_DEALER_SUPPORT_DATA_BY_QUARTER };
};

export const setStrategicDealerSupportDataByQuarter = (data: any) => {
  return { type: SET_STRATEGIC_DEALER_SUPPORT_DATA_BY_QUARTER, payload: data };
};

export const fetchStrategicCEDataByQuarter = () => {
  return { type: FETCH_STRATEGIC_CE_DATA_BY_QUARTER };
};

export const setStrategicCEDataByQuarter = (data: any) => {
  return { type: SET_STRATEGIC_CE_DATA_BY_QUARTER, payload: data };
};

export const fetchFixedDataByQuarter = () => {
  return { type: FETCH_FIXED_DATA_BY_QUARTER };
};

export const setFixedDataByQuarter = (data: any) => {
  return { type: SET_FIXED_DATA_BY_QUARTER, payload: data };
};

export const setMidasMtpCategoryHideYrScnrRv = (data: any) => {
  return { type: SET_MTP_CATEGORY_HIDE_YR_SCNR_RV, payload: data };
};

export const fetchSpendVsTrafficModelData = () => {
  return { type: FETCH_SPEND_VS_TRAFFIC_MODEL_DATA };
};

export const setSpendVsTrafficModelData = (data: any) => {
  return { type: SET_SPEND_VS_TRAFFIC_MODEL_DATA, payload: data };
};

export const fetchSpendVsTrafficMarketData = () => {
  return { type: FETCH_SPEND_VS_TRAFFIC_MARKET_DATA };
};

export const setSpendVsTrafficMarketData = (data: any) => {
  return { type: SET_SPEND_VS_TRAFFIC_MARKET_DATA, payload: data };
};
