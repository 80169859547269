import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg } from "../../../components/AppMessages";
import { CustomerIntentionClusterChart } from "../../../components/Charts/ProductMarketingOptimization/CustomerIntentionClusterChart";
import { ThemeContext } from "../../../context";

interface Props {
  showVolumes: boolean;
  nimTech?: boolean;
}

const CustomerIntentionCluster = (props: Props) => {
  const { showVolumes, nimTech } = props;

  const themeContext = useContext(ThemeContext);
  const customerIntegrationData = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.customer_intention_vs_sales);
  const customerIntegrationDataLoading: boolean = useSelector((state: RootStateOrAny) => state.loading.customer_intention_vs_sales);
  const nimTechCustomerIntegrationData = useSelector(
    (state: RootStateOrAny) => state.product_marketing_optimization.nim_tech_customer_intention_vs_sales
  );
  const nimTechcustomerIntegrationDataLoading: boolean = useSelector(
    (state: RootStateOrAny) => state.loading.nim_tech_customer_intention_vs_sales
  );

  const sortedNimTechData = useMemo(() => {
    if (nimTechCustomerIntegrationData?.length) {
      return nimTechCustomerIntegrationData;
    }
    return [];
  }, [nimTechCustomerIntegrationData]);

  return (
    <>
      <div
        className={`${
          nimTech ? (sortedNimTechData?.length > 10 ? "nim tall" : "nim") : ""
        } customer_intention_cluster primary_tile_background`}
      >
        <div className="main_content">
          <ErrorBoundary fallback={<ErrorMsg />}>
            <CustomerIntentionClusterChart
              data={nimTech ? sortedNimTechData : customerIntegrationData}
              theme={themeContext.theme}
              chartName={`${nimTech ? "nimTech" : ""}customerIntegrationChart`}
              isLoading={nimTech ? nimTechcustomerIntegrationDataLoading : customerIntegrationDataLoading}
              showVolumes={showVolumes}
              nimTech={nimTech}
            />
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};

export default CustomerIntentionCluster;
