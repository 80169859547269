import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import SalesByColorChart from "../../../components/Charts/ProductMarketingOptimization/SalesByColorChart";
import { RegionalColorFilter } from "../../../components/Filters/common/RegionalColorFIlter";
import { closeFilterOptions, closeMarketOptions } from "../../../components/Filters/subs/helpers";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { REGIONAL_COLORS } from "../../../constants";
import { FilterContext, ThemeContext } from "../../../context";
import { isEmpty, isNull } from "../../../utils/utilityFunctions";

interface Props {
  regionalColorSortOrder: string[];
}

const CustomerIntentionByColor = (props: Props) => {
  const ref = useRef<any>(null);

  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);
  const { regionalColorSortOrder } = props;

  const loading = useSelector((state: RootStateOrAny) => state.loading.price_volume_trend);

  const colors = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.customer_intention?.colors);
  const data = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.customer_intention?.data);

  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const granularity = useSelector((state: RootStateOrAny) => state.parameters.granularity);

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [filterChange, setFilterChange] = useState([]);
  const someColorsInSortOrder = useMemo(() => {
    let includes = false;
    colors?.map((color: string) => {
      if (regionalColorSortOrder?.includes(color.toUpperCase())) includes = true;
    });
    return includes;
  }, [colors, regionalColorSortOrder]);

  const seriesData = useMemo(() => {
    const groupedDataArray: any = [];
    colors
      ?.filter((color: string) => colors?.indexOf(color) < 9)
      ?.forEach((e: any) => {
        const groupedData = data?.filter((element: any) => e === element.color);
        groupedDataArray.push({
          name: e,
          data: groupedData,
          hide: false,
          color: regionalColorSortOrder?.includes(e.toUpperCase())
            ? REGIONAL_COLORS[regionalColorSortOrder?.indexOf(e.toUpperCase())]
            : isNull(groupedData[0]?.hex)
            ? `${REGIONAL_COLORS[regionalColorSortOrder?.length ? regionalColorSortOrder?.length + 1 : colors?.indexOf(e)]}`
            : groupedData[0]?.hex,
        });
      });

    const newGrouping = groupedDataArray.map((e: any, idx: number) => {
      if (idx > 8) return { ...e, hide: true };
      else return { ...e };
    });

    return newGrouping;
  }, [data, colors, regionalColorSortOrder, someColorsInSortOrder]);

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  const getColourChange = (changes: any) => {
    setFilterChange(changes);
  };

  // Fetch all data
  useEffect(() => {
    setFilterChange([]);
  }, [parameters, dispatch]);

  const seriesListFilter = useMemo(() => {
    if (filterChange.length > 0) {
      return seriesData.map((e: any) => {
        const singleData: any = filterChange.filter((d: any) => d.value === e.name);
        return { ...e, hide: !singleData[0].checked };
      });
    }
    return [];
  }, [filterChange]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (tooltipVisible && ref.current != null && !ref.current.contains(e.target)) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [tooltipVisible]);

  return (
    <div className="customer_intention_color primary_tile_background">
      <div className="header">
        <div className="title_container">
          <h3>Customer intention by color</h3>

          <Tooltip
            position="left"
            className="customer_intention_tooltip"
            html={
              <div ref={ref}>
                <p>
                  <span style={{ fontWeight: "bold" }}>Customer intention</span> is based on the volume of configurations completed by
                  visitors online.
                </p>
                <p>
                  <u>Note</u>: visitors are able to complete more than one configuration, and can add to the volume of completed
                  configurations.
                </p>
                <p>
                  <u>Source</u>: Nissan website data from Adobe Analytics
                </p>
                <br />
              </div>
            }
            trigger="click"
            animation="none"
            theme="dark"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            open={tooltipVisible}
            //@ts-ignore
            onShown={() => setTooltipVisible(!tooltipVisible)}
          >
            <span
              className="info_tooltip hide_on_mobile"
              onClick={(evt: React.MouseEvent<HTMLElement>) => {
                evt.stopPropagation();
                setTooltipVisible(!tooltipVisible);
              }}
            />
          </Tooltip>
        </div>
        <div className="model_filter" style={{ position: "absolute", right: 0, marginTop: "-1.8em" }}>
          <ErrorBoundary fallback={<ErrorMsg />}>
            <RegionalColorFilter
              type={colors?.length > 0 ? colors : []}
              onShowFilterOptions={showFilterOptionsFn}
              value={""}
              colorChange={getColourChange}
              id={"customer_intention_by_color"}
              series={seriesListFilter.length > 0 ? seriesListFilter : seriesData}
            />
          </ErrorBoundary>
        </div>
      </div>

      <div className="main_content shared_container">
        {!isEmpty(data) ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <SalesByColorChart
              isLoading={false}
              theme={themeContext.theme}
              seriesList={seriesListFilter.length > 0 ? seriesListFilter : seriesData}
              id={"CustomerIntentionByColorChart"}
              granularity={granularity}
            />
          </ErrorBoundary>
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available for this market" />
        )}

        {seriesData?.length && (
          <div className="trend-legend-color hideOnMobileOnly">
            {seriesListFilter.length === 0
              ? seriesData?.map((series: { name: string; color: string; hide: boolean }, index: number) => {
                  if (!series.hide) {
                    return (
                      <div className="legend-item-color" key={series.name}>
                        <div className="legend-box-color" style={{ backgroundColor: series.color }}></div>
                        {` ${series.name}`}
                      </div>
                    );
                  }
                })
              : seriesListFilter?.map((series: { name: string; hide: boolean; color: string }, index: number) => {
                  if (!series.hide) {
                    return (
                      <div className="legend-item-color" key={series.name}>
                        <div className="legend-box-color" style={{ backgroundColor: series.color }}></div>
                        {` ${series.name}`}
                      </div>
                    );
                  }
                })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerIntentionByColor;
