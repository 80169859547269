import { ErrorBoundary } from "@sentry/react";
import React, { useEffect, useMemo, useState } from "react";
import { fetchHandraisersConversionData } from "../../../../api/Ariya";
import { ErrorMsg } from "../../../../components/AppMessages";
import { ARIYA_NAE_MARKETS } from "../../../../constants";
import { SocialRow } from "../../../../constants/interface/ariya";
import { AriyaDefaultTable } from "../../Components";
import styles from "./handraisersConversion.module.scss";

export const HandraisersConversion = () => {
  const [hiddenColumns] = useState([""]);
  const [tableData, setTableData] = useState<Array<{ market: string; total: number; share: number }>>([]);

  useEffect(() => {
    fetchHandraisersConversionData().then((response) => {
      if (response && !("error" in response)) {
        setTableData(
          response.report.data
            .filter((row) => !row.name.includes("unspecified") && ARIYA_NAE_MARKETS.includes(row.name))
            .map((row) => ({
              market: row.name === "United States of America" ? "USA" : row.name,
              total: +row.counts[0],
              share: +row.counts[1],
              breakdown: row.breakdown
                .filter((row) => !row.name.includes("unspecified"))
                .map((breakdown) => ({
                  market: breakdown.name,
                  total: +breakdown.counts[0],
                  share: +breakdown.counts[1],
                })),
            }))
        );
      }
    });
  }, []);

  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(
    () => [
      {
        id: "market",
        Header: "Market",
        accessor: "market",
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "alphanumericIgnoreCase",
        Cell: function renderMarketCell(props: {
          row: { values: { breakdown: any }; getToggleRowExpandedProps: () => any };
          cell: { row: { original: any } };
        }) {
          const {
            row,
            cell: {
              row: { original },
            },
          } = props;

          const isBreakdown = !("breakdown" in original);

          const spanProps = !isBreakdown ? { ...row.getToggleRowExpandedProps() } : {};
          const marketClass = isBreakdown ? "" : styles.clickableMarket;

          return (
            <span {...spanProps} className={marketClass}>
              {original.market}
            </span>
          );
        },
      },
      {
        id: "handraisers",
        Header: "Hand-raisers",
        accessor: "total",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: function renderVisitsType(props1: { value: number }) {
          const { value } = props1;

          return value.toLocaleString();
        },
      },
      {
        Header: "Conversion rate",
        accessor: `share`,
        id: "share",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: function renderVisitsType(props1: { value: string }) {
          const { value } = props1;

          return `${value}%`;
        },
      },
    ],
    []
  );

  function renderRowSubComponent(props: { row: { original: { breakdown: Array<SocialRow & { breakdown?: Array<SocialRow> }> } } }) {
    const {
      row: {
        original: { breakdown: breakdownData },
      },
    } = props;

    return (
      <ErrorBoundary fallback={<ErrorMsg />}>
        {breakdownData?.length ? (
          <AriyaDefaultTable
            columns={columns}
            data={breakdownData}
            id={"social_networks_child"}
            isChildComponent={true}
            initialSortColId={{ id: "share", desc: true }}
            hiddenCols={hiddenColumns}
            sortable={true}
          />
        ) : (
          <></>
        )}
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      {data.length ? (
        <AriyaDefaultTable
          columns={columns}
          data={data}
          id={"nae_handraisers_split"}
          data-test-id={"nae_handraisers_split"}
          renderRowSubComponent={renderRowSubComponent}
          isChildComponent={false}
          initialSortColId={{ id: "handraisers", desc: true }}
          hiddenCols={hiddenColumns}
          sortable={false}
        />
      ) : (
        <></>
      )}
    </ErrorBoundary>
  );
};
