import { OCE_DATE_FILTER_OPTIONS } from "../constants";
import { isEmpty } from "../utils/utilityFunctions";
import { apiFetchV2, fetchDataV2, newApiFetchV2 } from "./utils";
import moment from "moment";

export const getParams = () => {
  const parameters = new URLSearchParams(window.location.search);

  const dateRangeParam = parameters?.get("date_range");
  const brandParameter = parameters.get("brand") == "All" || isEmpty(parameters.get("brand")) ? [] : parameters.get("brand")?.split(",");
  const marketParameter =
    parameters.get("market") == "All" || parameters.get("market") == "All countries" || isEmpty(parameters.get("market"))
      ? []
      : parameters.get("market")?.split(",");
  const modelParameter = parameters.get("model") == "All" || isEmpty(parameters.get("model")) ? [] : parameters.get("model")?.split(",");
  const yearScenarioParameter =
    parameters.get("yr_scnr_rv") == "All" || isEmpty(parameters.get("yr_scnr_rv")) || parameters.get("yr_scnr_rv") == "undefined"
      ? []
      : parameters.get("yr_scnr_rv")?.split(",");

  const paramObj: any = {
    brand: brandParameter,
    market: marketParameter,
    model: modelParameter,
    yr_scnr_rv: yearScenarioParameter,
  };

  if (dateRangeParam) {
    if (OCE_DATE_FILTER_OPTIONS.includes(dateRangeParam)) {
      paramObj["date_range"] = dateRangeParam.split(" ").join("_").toLocaleLowerCase();
    } else {
      paramObj["date_range"] = "custom";
      paramObj["start_date"] = moment(dateRangeParam.split("-")[0], "MMMM YYYY").format("YYYY-MM-DD");
      paramObj["end_date"] = moment(dateRangeParam.split("-")[1], "MMMM YYYY").endOf("month").format("YYYY-MM-DD");
    }
  }

  return paramObj;
};

export const getLastDataRefresh = () => fetchDataV2("/mtm/last-refresh-date");
export const getMediaFmiAndRetailSales = () => newApiFetchV2("/mtm/media-fmi-and-retail-sales", getParams());
export const getSearchInterestSummary = () => newApiFetchV2("/mtm/search-interest-summary", getParams());
export const getSearchInterestTrend = (coreModels: boolean) =>
  newApiFetchV2(`/mtm/search-interest-trend?core_models=${coreModels}`, getParams());
export const getSearchInterestTable = (coreModels: boolean) =>
  newApiFetchV2(`/mtm/search-interest-table?core_models=${coreModels}`, getParams());
export const getQualifiedVisitsAndKbasSummary = (qualifiedVisits: boolean) =>
  newApiFetchV2(`/mtm/qualified-visits-and-kbas-summary?qualified_visits=${qualifiedVisits}`, getParams());
export const getQualifiedVisitsAndKbasTrend = (qualifiedVisits: boolean) =>
  newApiFetchV2(`/mtm/qualified-visits-and-kbas-trend?qualified_visits=${qualifiedVisits}`, getParams());
export const getQualifiedVisitsAndKbasTable = (qualifiedVisits: boolean) =>
  newApiFetchV2(`/mtm/qualified-visits-and-kbas-table?qualified_visits=${qualifiedVisits}`, getParams());
export const getKbasAndLeadsSummary = (showroomTraffic: boolean) =>
  newApiFetchV2(`/mtm/kbas-and-leads-summary?showroom_traffic=${showroomTraffic}`, getParams());
export const getKbasAndLeadsTrend = (showroomTraffic: boolean) =>
  newApiFetchV2(`/mtm/kbas-and-leads-trend?showroom_traffic=${showroomTraffic}`, getParams());
export const getKbasAndLeadsTable = (showroomTraffic: boolean) =>
  newApiFetchV2(`/mtm/kbas-and-leads-table?showroom_traffic=${showroomTraffic}`, getParams());
export const getMediaFmiAndCoreModel = () => newApiFetchV2("/mtm/media-fmi-and-core-model", getParams());
export const getMediaFmiJourney = () => newApiFetchV2("/mtm/media-fmi-and-sov", getParams());
export const getMarketPerformanceSummaryDataDefinitions = () => fetchDataV2("/mtm/data-dictionary");
