import React from "react";
import { NoDataMsg } from "../../../../components/AppMessages";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { MtpCategoryTable } from "../../../../components/Tables";
import QuarterBreakDown from "./QuarterBreakDown";

interface Props {
  period: string;
  loading: boolean;
  tableData: any;
  yrScenarios: string[];
}

const TablesMobile = (props: Props) => {
  const { yrScenarios, period, loading, tableData } = props;
  return (
    <>
      <QuarterBreakDown
        data={tableData}
        period={period}
        loading={loading}
        quarterTitle="Q1"
        yrScenarios={yrScenarios}
        months={period == "FY Month" ? [4, 5, 6] : [1]}
      />
      <QuarterBreakDown
        data={tableData}
        period={period}
        loading={loading}
        quarterTitle="Q2"
        yrScenarios={yrScenarios}
        months={period == "FY Month" ? [7, 8, 9] : [2]}
      />
      <QuarterBreakDown
        data={tableData}
        period={period}
        loading={loading}
        quarterTitle="Q3"
        yrScenarios={yrScenarios}
        months={period == "FY Month" ? [10, 11, 12] : [3]}
      />
      <QuarterBreakDown
        data={tableData}
        period={period}
        loading={loading}
        quarterTitle="Q4"
        yrScenarios={yrScenarios}
        months={period == "FY Month" ? [1, 2, 3] : [4]}
      />
      <div className="quarter_breakdown primary_background">
        {tableData?.length && !loading ? (
          <>
            <span className="quarter_title" style={{ display: "block" }}>
              Full year
            </span>
            <MtpCategoryTable tableId="mtpCategoryfullyear" tableData={tableData} keys={yrScenarios} months={[0]} />
          </>
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available" />
        )}
      </div>
    </>
  );
};

export default TablesMobile;
