import { CategoryAxis, ColumnSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { columnSeriesConfiguration, configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  seriesList: Array<{ name: string }>;
  theme: ThemeInt;
  chartName: string;
  isLoading: boolean;
  colorMapping: Record<string, string>;
}

interface ISeries {
  name: string;
  color: string;
}

class SalesByModelChart extends Component<Props> {
  chartId = this.props.chartName;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, seriesList, theme, colorMapping } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.fontSize = "var(--chart_small)";
    this.chart.numberFormatter.numberFormat = "#.##";

    // Create Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.minGridDistance = 5;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + "%";
    });

    seriesList?.map((series: { name: string }) => {
      this.createSeries({
        name: series.name,
        color: colorMapping[series.name],
      });
    });
  }

  createSeries({ name, color: seriesColor }: ISeries) {
    const series = this.chart.series.push(new ColumnSeries());
    columnSeriesConfiguration(series, {
      name: name,
      valueY: name,
    });
    series.stacked = true;
    series.dataFields.categoryX = "category";
    series.columns.template.width = percent(75);
    series.columns.template.tooltipText = "{name}: {valueY.formatNumber('#.#')}%[/]";
    series.columns.template.fill = color(seriesColor);
    series.columns.template.stroke = color(seriesColor);
    if (series.tooltip) {
      series.tooltip.getFillFromObject = false;
      series.tooltip.autoTextColor = false;
      series.tooltip.background.fill = color(WHITE);
      series.tooltip.label.fill = color(BLACK);
    }
    series.columns.template.adapter.add("tooltipText", (value, target) => {
      if (target?.tooltipDataItem) {
        const dataValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };
        // @ts-ignore
        const name = target.tooltipDataItem.component?.dataFields?.valueY;

        let text = `${name}: ${dataValue[name]}%\n`;

        text += `Sales volume: ${dataValue[name + "_volume"]}`;

        return text;
      }
    });
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default SalesByModelChart;
