import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { setPageName } from "../../../actions";
import { getExecutiveSummaryProductPerformanceLastRefreshData } from "../../../api";
import { getNoUniqueProductsLive, getTotalLiveMarkets, getTotalProductsLive } from "../../../api/executiveSummaryProductPerformanceApi";
import "../../../assets/styles/pages/executiveSummaryProductPerformance.scss";
import ExecutiveSummaryProductPerformanceFilters from "../../../components/Filters/ExecutiveSummaryDashboard/ExecutiveSummaryProductPerformanceFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { EXECUTIVE_SUMMARY_BTN_FILTERS } from "../../../constants/executiveSummaryConstants";
import EntryPointByVisits from "./components/EntryPointByVisits";
import MetricTile from "./components/MetricTile";
import PerformanceByMarketMap from "./components/PerformanceByMarketMap";
import PerformanceByMarketTables from "./components/PerformanceByMarketTables";
import ProductsActivatedByMarket from "./components/ProductsActivatedByMarket";
import TopFiveProductsVisited from "./components/TopFiveProductsVisited";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";

const ExecutiveSummaryProductPerformance = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();


  const [productPerformanceByMetric, setProductPerformanceByMetric] = useState("Visits");

  useEffect(() => {
    dispatch(setPageName("Executive Summary - Product Performance"));
    eventTracking(MixpanelEvents.page_view, { dashboard: "Executive Summary", page: "Product Performance" });
  }, []);

  const { data: lastDataRefreshData, refetch: refetchLastDataRefresh } = useQuery({
    queryKey: ["executiveSummaryProductPerformanceLastDataRefresh", history.location.search],
    queryFn: getExecutiveSummaryProductPerformanceLastRefreshData,
    enabled: false,
  });

  const {
    data: nuplData,
    isLoading: nuplLoading,
    refetch: refetchNplData,
  } = useQuery({
    queryKey: ["uniqueProductsLiveData", history.location.search],
    queryFn: getNoUniqueProductsLive,
    enabled: false,
  });

  const {
    data: totalProductsLiveData,
    isLoading: totalProductsLiveLoading,
    refetch: refetchTotalProductsLive,
  } = useQuery({
    queryKey: ["totalProductsLiveData", history.location.search],
    queryFn: getTotalProductsLive,
    enabled: false,
  });

  const {
    data: totalLiveMarketsData,
    isLoading: totalLiveMarketsLoading,
    refetch: refetchTotalLiveMarkets,
  } = useQuery({
    queryKey: ["totalLiveMarketsData", history.location.search],
    queryFn: getTotalLiveMarkets,
    enabled: false,
  });

  useEffect(() => {
    refetchLastDataRefresh();
    refetchNplData();
    refetchTotalProductsLive();
    refetchTotalLiveMarkets();
  }, [history.location.search]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<ExecutiveSummaryProductPerformanceFilters filterBtns={EXECUTIVE_SUMMARY_BTN_FILTERS} />}
        dataDocumentation="executive_summary"
        lastDataRefresh={lastDataRefreshData?.date ?? ""}
        pageTitle={<PageTitle dataDocumentation="executive_summary" />}
      >
        <div className="executive-summary-product-performance">
          <div className="grid-3">
            <div className="metric-tiles-container">
              <MetricTile
                title="NCX products live"
                value={nuplData?.Current?.count}
                yoyValue={nuplData?.YoY?.perc_count}
                popValue={nuplData?.PoP?.perc_count}
                isLoading={nuplLoading}
                tooltipText="Number of unique products (within the dashboard scope products) live in the selected markets. If a product is live in more than one market it will only be counted once."
              />
              <MetricTile
                title="NCX total products lives"
                value={totalProductsLiveData?.Current?.count}
                yoyValue={totalProductsLiveData?.YoY?.perc_count}
                popValue={totalProductsLiveData?.PoP?.perc_count}
                isLoading={totalProductsLiveLoading}
                tooltipText="Cumulative number of  products (within the dashboard scope products) live in the selected markets. If a product is live in more than one market it will be counted as many times as markets that have this product activated."
              />
              <MetricTile
                title="Total Markets Live"
                value={totalLiveMarketsData?.Current?.count}
                yoyValue={totalLiveMarketsData?.YoY?.perc_count}
                popValue={totalLiveMarketsData?.PoP?.perc_count}
                isLoading={totalLiveMarketsLoading}
                tooltipText="Markets with at least 10 visits for the selected products in the selected period"
              />
            </div>
            <TopFiveProductsVisited />
            {/* <TopFiveFeaturesUsed /> */}
          </div>
          <div className="performance-row">
            <PerformanceByMarketMap metricFilterValue={productPerformanceByMetric} setMetricFilterValue={setProductPerformanceByMetric} />
            <PerformanceByMarketTables metricFilterValue={productPerformanceByMetric} />
          </div>
          <ProductsActivatedByMarket />
          <div className="points-by-visits">
            <EntryPointByVisits />
            {/* <ExitPointByVisits /> */}
          </div>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default ExecutiveSummaryProductPerformance;
