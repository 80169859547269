import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import ComparisonBarChart from "../../../components/Charts/ProductMarketingOptimization/ComparisonBarChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { GRADE_MIX_COLORS } from "../../../constants";
import { ThemeContext } from "../../../context";

interface Props {
  showVolume: boolean;
  target?: string;
}

const CustomerIntentionComparisonPower = (props: Props) => {
  const { showVolume, target } = props;
  const themeContext = useContext(ThemeContext);
  const loading = useSelector((state: RootStateOrAny) => state.loading.power_trains);
  const granularity = useSelector((state: RootStateOrAny) => state.parameters.granularity);

  const data = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.power_customer ?? []);

  const powertrains = useMemo(() => {
    const powertrainArray = new Set(
      data.map((e: any) => {
        return e.powertrain;
      })
    );
    return Array.from(powertrainArray);
  }, [data]);

  const chartData = useMemo(() => {
    if (data?.length) {
      const res: any = [];

      data?.map((row: any) => {
        const key = row.powertrain.replace(/\s+/g, "_");
        const dates = Array.from(new Set(res.map((val: { date: string }) => val.date)));
        if (dates.includes(row.date)) {
          res?.map((val: { date: any }, idx: number) => {
            if (val.date === row.date) {
              const value: any = { ...val };
              value[`${key}_customer_intention`] = row.customer_intention;
              value[`${key}_customer_intention_ratio`] = row.customer_intention_ratio;
              value[`${key}_actual_sales`] = row.actual_sales;
              value[`${key}_actual_sales_ratio`] = row.actual_sales_ratio;
              res.splice(idx, 1, value);
            }
          });
        } else {
          const result: any = { date: row.date };
          result[`${key}_customer_intention`] = row.customer_intention;
          result[`${key}_customer_intention_ratio`] = row.customer_intention_ratio;
          result[`${key}_actual_sales`] = row.actual_sales;
          result[`${key}_actual_sales_ratio`] = row.actual_sales_ratio;
          result["quarter"] = row.quarter;
          res.push(result);
        }
      });
      return res;
    }
    return [];
  }, [data]);

  const customerIntentionSeriesList = useMemo(() => {
    return powertrains.map((e: any) => {
      return {
        name: e,
        volumeField: `${e}_customer_intention`,
        color: `${e == "ICE" ? GRADE_MIX_COLORS[1] : GRADE_MIX_COLORS[0]}`,
        gradeField: `${e}_customer_intention_ratio`,
      };
    });
  }, [data]);

  const salesSeriesList = useMemo(() => {
    return powertrains.map((e: any) => {
      return {
        name: e,
        volumeField: `${e}_actual_sales`,
        color: `${e == "ICE" ? GRADE_MIX_COLORS[1] : GRADE_MIX_COLORS[0]}`,
        gradeField: `${e}_actual_sales_ratio`,
      };
    });
  }, [data]);

  return (
    <div className="customer_intention_comparison ">
      <div className="customer_intention_trend primary_tile_background">
        <div className="header">
          <div className="title_container">
            <h3>Customer intention</h3>
          </div>
        </div>

        <div className="main_content ">
          {data?.length ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <ComparisonBarChart
                chartName={target ? `customerIntentionTrend${target}` : "customerIntentionTrend"}
                data={chartData}
                granularity={granularity}
                isLoading={loading}
                showVolume={showVolume}
                seriesList={customerIntentionSeriesList}
                theme={themeContext.theme}
              />
            </ErrorBoundary>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available for this market" />
          )}
        </div>
      </div>
      {/* 
      <div className="sales_by_grade primary_tile_background">
        <div className="header">
          <div className="title_container">
            <h3>Sales</h3>
          </div>
        </div>

        <div className="main_content">
          {data?.length ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <ComparisonBarChart
                chartName={target ? `salesByGrade${target}` : "salesByGrade"}
                data={chartData}
                granularity={granularity}
                isLoading={loading}
                showVolume={showVolume}
                seriesList={salesSeriesList}
                theme={themeContext.theme}
              />
            </ErrorBoundary>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available for this market" />
          )}
        </div>
      </div> */}
    </div>
  );
};

export default CustomerIntentionComparisonPower;
