import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { fetchMetricChannel, fetchMetricChannelTrend } from "../../../../actions/journeyExcellenceActions";
import JourneyExcellenceLineTrendChart from "../../../../components/Charts/JourneyExcellence/JourneyExcellenceLineTrendChart";
import MetricChannelShareChart from "../../../../components/Charts/JourneyExcellence/MetricChannelShareChart";
import { DefaultFilter } from "../../../../components/Filters/common";
import { closeFilterOptions } from "../../../../components/Filters/subs/helpers";
import { ToggleSwitch } from "../../../../components/Toogle";
import { FilterContext, ThemeContext } from "../../../../context";

const series = [
  { name: "Paid social media", field: "paid_social_media" },
  { name: "Paid search", field: "paid_search" },
  { name: "Natural search", field: "natural_search" },
];

const data = [
  {
    category: "Visit share",
    paid_social_media: 5,
    paid_search: 7,
    natural_search: 20,
  },
];

const WebsiteVisitSource = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const { data: metricChannelData, series: metricChannelSeries } = useSelector(
    (state: RootStateOrAny) => state.journey_excellence.metric_channel
  );
  const metricChannelLoading = useSelector((state: RootStateOrAny) => state.loading.metric_channel);
  const { data: metricChannelTrendData, series: metricChannelTrendSeries } = useSelector(
    (state: RootStateOrAny) => state.journey_excellence.metric_channel_trend
  );
  const metricChannelTrendLoading = useSelector((state: RootStateOrAny) => state.loading.metric_channel_trend);
  const [metricTrendlineToggle, setMetricTrendlineToggle] = useState(true);
  const [trendMetric, setTrendMetric] = useState("Visits");

  useEffect(() => {
    dispatch(fetchMetricChannelTrend(metricTrendlineToggle, trendMetric));
  }, [history.location.search, metricTrendlineToggle, trendMetric]);

  useEffect(() => {
    dispatch(fetchMetricChannel());
  }, [history.location.search]);

  const handleMetricFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionValue = dataset?.value;
    if (optionValue) setTrendMetric(optionValue);
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  return (
    <div className="website-visit-source-row">
      <div className="website-visit-source-tile">
        <div className="tile-header">
          <div className="title">
            Metric channel share
            <Tooltip
              position="top"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              html={
                <ul>
                  <li>Visits share: Share of each channel in the total number of website visits.</li>
                  <li>KBA conversion: Share of each channel in the conversion of website visits to KBA visits.</li>
                </ul>
              }
              size={"small"}
            >
              <span className="info_tooltip" />
            </Tooltip>
          </div>
        </div>
        <div className="tile-content">
          <MetricChannelShareChart
            chartId="metricChannelShare"
            data={metricChannelData}
            seriesList={metricChannelSeries}
            isLoading={metricChannelLoading}
            theme={themeContext.theme}
          />
        </div>
      </div>
      <div className="website-visit-source-tile">
        <div className="tile-header">
          <div className="title">
            Metric trendline {metricTrendlineToggle ? "by channel" : "by model"}
            <Tooltip
              position="top"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              html={
                <ul>
                  <li>Website visits: Website visit happens every time the site has at least one page fully loaded.</li>
                  <li>
                    KBA visits: KBA or Key Buying Actions visits are the count of visits including at least one key buying action as Model
                    detail compare, Saved searches views, Compare tools views and Dealer search.
                  </li>
                  <li>
                    Leads visits: Lead visits are the count of visits including at least one digital lead as Enquire, Test drive, Request a
                    video and Reserve.
                  </li>
                </ul>
              }
              size={"small"}
            >
              <span className="info_tooltip" />
            </Tooltip>
          </div>
          <DefaultFilter
            list={["Visits", "Kbas", "Leads"]}
            filterName={"metric"}
            filterLabel={"Select Metric"}
            filterValue={trendMetric}
            handleFilterOptionClick={handleMetricFilterOptionClick}
          />
        </div>
        <ToggleSwitch
          activeToggleLabel={"By channel"}
          inactiveToggleLabel={"By model"}
          active={metricTrendlineToggle}
          handleToggleClick={() => setMetricTrendlineToggle((prevState) => !prevState)}
          toggleClassName={"filter_toggle"}
        />
        <div className="tile-content">
          <JourneyExcellenceLineTrendChart
            chartId="metricChannelTrend"
            seriesList={metricChannelTrendSeries}
            data={metricChannelTrendData}
            isLoading={metricChannelTrendLoading}
            theme={themeContext.theme}
          />
        </div>
      </div>
    </div>
  );
});

export default WebsiteVisitSource;
