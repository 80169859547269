import { __local__ } from "../constants";
import { IPowerBiToken } from "../constants/interface";
import { fetchData, newApiFetch, postData } from "./utils";

export const getPowerBiReportToken = (report_path: string): Promise<IPowerBiToken> => newApiFetch(`/power_bi_api/${report_path}/`, {});

export function getMtmReports(download = false): Promise<any> {
  const params = new URLSearchParams(window.location.search);
  const geography = params.get("market");
  const granularity = params.get("granularity");

  return __local__
    ? fetchData(download ? "/mtm_reports_download" : "/mtm_reports_token")
    : postData("/power_bi_api/mtm_reports", { geography, granularity, download });
}
