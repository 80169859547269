import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, RouteComponentProps, RouteProps, withRouter } from "react-router-dom";
import { fetchCurrentUserDetails, fetchCXOToken, setCanAccessView } from "../../../actions";
import { getLastDataRefresh } from "../../../api";
import { getFlashMessage, getReleaseNotesData } from "../../../api/releaseNotesApi";
import { ALL_PATHS } from "../../../constants";
import { IReleaseNote } from "../../../constants/interface/admin/releaseNotes";
import { PopUpMessagesContext } from "../../../context";
import { AccessDeniedPage } from "../../../pages/Error Pages/Login";

const PAGE_VIEW_MAPPING: Record<string, string> = {
  online_ce_performance: "digital_performance",
  ariya_experience_monitor: "ariya",
  seo_report: "seo_dashboard",
  digital_customer_experience: "digital_user_experience",
  mtm_beta: "mtm_metrics",
  mtm_metrics_that_matter: "mtm_metrics",
  online_to_physical_insights: "online_to_physical",
  car_configurator_g5: "configurator-g5",
  car_configurator_nibu: "configurator-nibu",
  pace_feature_activation_report: "pace",
  car_configurator_jp: "car_configurator",
  midas: "midas",
};

const isUserAuthenticated = () => !!Cookies.get("access_token");

interface Props extends RouteProps {
  search?: string;
}

export const PrivateRoute = withRouter((props: Props & RouteComponentProps) => {
  const { path, history, location, ...rest } = props;
  const { setFlashMessage, setShowFlashMessage, setReleaseNote, setShowReleaseMessage } = useContext(PopUpMessagesContext);

  const dispatch = useDispatch();

  const [isAuthenticated, setIsAuthentication] = useState<boolean>(true);
  const [canAccessRoute, setCanAccessRoute] = useState<boolean>(true);

  const { search } = location;

  const updatedPath = search ? path + search : path;

  useEffect(() => {
    setIsAuthentication(isUserAuthenticated());
  }, []);

  // Redirects user to login page if not authenticated
  useEffect(() => {
    if (!isAuthenticated || !Cookies.get("views")) {
      history.push({ pathname: "/login", search: `?redirect=${updatedPath}` });
    } else {
      Promise.all([
        //Fetch flash message
        getFlashMessage().then((response) => {
          if (response && localStorage.getItem("latestFlashMsgSeen") === "false") {
            setFlashMessage(response as unknown as string);
            setShowFlashMessage(true);
          }
        }),
        // Fetch release notes
        getReleaseNotesData().then((response) => {
          if (response && response.valid && !response.seen) {
            const notesValue: string = response.popup_note;
            setReleaseNote(response as IReleaseNote);
            setShowReleaseMessage(true);
          }
        }),
        dispatch(fetchCurrentUserDetails()),
        dispatch(fetchCXOToken()),
      ]);
    }
  }, [isAuthenticated]);

  //HACK to logout users whenever an endpoint throws an error.
  //The function will run every 15 minutes to check if a user is logged in by doing an api call and checking the response status
  useEffect(() => {
    const authenticateInterval = setInterval(() => {
      getLastDataRefresh("digital_performance").then((response: any) => {
        if (response && "error" in response) {
          const responseStatus = response.error?.response?.status;
          if (responseStatus === 403) {
            setIsAuthentication(false);
          }
        }
      });
    }, 900000);
    return () => clearInterval(authenticateInterval);
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const viewsCookie = Cookies.get("views") as string;

    const views: string[] = viewsCookie ? JSON.parse(viewsCookie) : [];

    if (views.includes("annotation_admin")) {
      views.push("admin");
    }

    const availablePaths = views.reduce((result: string[], view) => {
      const paths = ALL_PATHS.filter((path) => {
        if (view in PAGE_VIEW_MAPPING) return path.includes(PAGE_VIEW_MAPPING[view]);

        return path.includes(view);
      });
      return [...result, ...paths];
    }, []);

    setCanAccessRoute(!!availablePaths.find((value) => currentPath.includes(value)));
  }, [location.pathname]);

  // reset flag that checks whether a user can access a page
  useEffect(() => {
    dispatch(setCanAccessView(true));
  }, [history.location]);

  return canAccessRoute ? <Route {...rest} path={updatedPath} /> : <AccessDeniedPage />;
});
