import { format, parse } from "date-fns";
import { ALL_COUNTRIES, ALL_OPTION, OCE_DATE_FILTER_OPTIONS } from "../constants";
import { newApiFetch, newApiFetchV2, newFetchDataV2, simpleFetchV2 } from "./utils";

const NAE_MARKETS = [
  "Austria",
  "Belgium",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Italy",
  "Kazakhstan",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Netherlands",
  "Norway",
  "Poland",
  "Russia",
  "Turkey",
  "Spain",
  "Sweden",
  "Switzerland",
  "Ukraine",
  "United Kingdom",
];
const AMIO_MARKETS = [
  "Abu Dhabi",
  "Algeria",
  "Armenia",
  "Australia",
  "Bahrain",
  "Dubai",
  "Egypt",
  "India",
  "Iraq",
  "Jordan",
  "Kuwait",
  "Lebanon",
  " Morocco",
  "New Zealand",
  "Oman",
  "Qatar",
  "Saudi Arabia",
  "South Africa",
];

export const getCpoParams = () => {
  const parameters = new URLSearchParams(window.location.search);

  const dateRangeParam = parameters.get("date_range");
  const brandParam = parameters.get("brand");
  const granularityParam = parameters.get("granularity");
  const regionParam = parameters.get("region") == ALL_OPTION ? [] : parameters.get("region")?.split(",");
  const marketParam = parameters.get("market") == ALL_COUNTRIES ? [] : parameters.get("market")?.split(",");
  const modelParam = parameters.get("model") == "All" ? [] : parameters.get("model")?.split(",");

  const paramObj: any = {
    brand: brandParam,
    region: regionParam,
    market: marketParam,
    model: modelParam,
    granularity: granularityParam,
  };

  if (dateRangeParam) {
    if (OCE_DATE_FILTER_OPTIONS.includes(dateRangeParam)) {
      paramObj["date_range"] = dateRangeParam.split(" ").join("_").toLocaleLowerCase();
    } else {
      paramObj["date_range"] = "custom";
      paramObj["start_date"] = format(parse(dateRangeParam.split("-")[0].trim(), "dd/MM/yyyy", new Date()), "y-MM-dd");
      paramObj["end_date"] = format(parse(dateRangeParam.split("-")[1].trim(), "dd/MM/yyyy", new Date()), "y-MM-dd");
    }
  }

  if (parameters.get("specific_kba")) paramObj.specific_kba = parameters.get("specific_kba");

  if (parameters.get("specific_lead")) paramObj.specific_lead = parameters.get("specific_lead");

  return paramObj;
};

export const getCpoLastDataRefresh = () => {
  return simpleFetchV2(`/certified-pre-owned/get-last-refresh-date`);
};
//replaces full_figures
export const getCpoModelTrend = () => newApiFetchV2("/certified-pre-owned/get-model-detail-visits-graph", getCpoParams());
export const getCpoModelKeyIndicators = () => newApiFetchV2("/certified-pre-owned/get-model-detail-visits-key-indicators", getCpoParams());
//replaces trendline
export const getCpoKeyIndicatorVariance = () => newApiFetchV2("/certified-pre-owned/get-website-visits", getCpoParams());
//replaces key visits trend all
export const getCpoKeyIndicatorAll = () => newApiFetchV2("/certified-pre-owned/get-key-indicators", getCpoParams());
//replaces trend_breakdown
export const getCpoBreakdownTrends = () => newApiFetchV2("/certified-pre-owned/get-trend-breakdown-graph", getCpoParams());
export const getCpoBreakdownAll = () => newApiFetchV2("/certified-pre-owned/get-trend-breakdown-table", getCpoParams());

export const getCpoLeadBreakdownTrends = (params: string) => newApiFetch("/cpo/breakdown/leads_kbas/trends", getCpoParams());

export const getCpoKbaLeadBreakdownAll = (params: string) => newApiFetch("/cpo/breakdown/leads_kbas/all", getCpoParams());
export const getCpoKbaBreakdownGraph = () => newApiFetchV2("/certified-pre-owned/get-kba-breakdown-graph", getCpoParams());
export const getCpoLeadBreakdownGraph = () => newApiFetchV2("/certified-pre-owned/get-lead-breakdown-graph", getCpoParams());
export const getCpoKbaBreakdownTable = () => newApiFetchV2("/certified-pre-owned/get-kba-breakdown-table", getCpoParams());
export const getCpoLeadBreakdownTable = () => newApiFetchV2("/certified-pre-owned/get-lead-breakdown-table", getCpoParams());
export const getCpoKbaLabelMapping = () => newFetchDataV2("/certified-pre-owned/get-kba-label-mapping");
export const getCpoLeadLabelMapping = () => newFetchDataV2("/certified-pre-owned/get-lead-label-mapping");
