import { ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import { connect, RootStateOrAny } from "react-redux";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import {
  BLACK,
  CCS_ANALYSIS_COLORS,
  CYAN,
  FOREST_GREEN,
  HUMMING_BIRD,
  MEDIA_MIX_CHART_COLORS,
  ORANGE,
  TOP_FIVE_MODEL_EXTRA_COLOR,
  WHITE,
} from "../../../constants";
import { isNull } from "../../../utils/utilityFunctions";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, legendConfiguration, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  seriesList: Array<{ name: string; field: string; type: string }>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
}

const barSeriesColors = [CYAN, FOREST_GREEN];

const lineSeriesColors = [ORANGE, HUMMING_BIRD];

class DmeoConversionRateBarLineChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.0a";
    this.chart.colors.list = MEDIA_MIX_CHART_COLORS.map((colorStr: string) => color(colorStr));

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.marginBottom = 15;
    this.chart.colors.list = CCS_ANALYSIS_COLORS.map((item) => color(item));
    this.chart.legend.position = "right";

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 40;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);

    const lineValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(lineValueAxis, theme);
    lineValueAxis.numberFormatter.numberFormat = "#a";
    lineValueAxis.renderer.minGridDistance = 50;
    lineValueAxis.renderer.opposite = true;
    lineValueAxis.syncWithAxis = valueAxis;
    lineValueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return `${text}${"%"}`;
    });

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    seriesList
      .filter((series) => series.type == "bar")
      ?.forEach(({ name, field }, idx) => {
        const series = this.chart.series.push(new ColumnSeries());
        series.dataFields.dateX = "date";
        series.name = name;
        series.fill = color(barSeriesColors[idx]);
        series.stroke = color(barSeriesColors[idx]);
        series.dataFields.valueY = field;
        series.xAxis = dateAxis;
        series.numberFormatter = new NumberFormatter();
        series.numberFormatter.numberFormat = "#.0a";
        series.columns.template.tooltipText =
          "[bold font-size: var(--regular_font_size)]{name}:[bold font-size: var(--regular_font_size)] {valueY.formatNumber('###,###')}";
        // if (series.tooltip) {
        //   series.tooltip.getFillFromObject = false;
        //   series.tooltip.autoTextColor = false;
        //   series.tooltip.background.fill = color(WHITE);
        //   series.tooltip.label.fill = color(BLACK);
        // }
      });

    seriesList
      .filter((series) => series.type == "line")
      ?.forEach(({ name, field }, idx) => {
        const series = this.chart.series.push(new LineSeries());
        series.dataFields.dateX = "date";
        series.fill = color(lineSeriesColors[idx]);
        series.stroke = color(lineSeriesColors[idx]);

        if (field.includes("bp")) {
          series.strokeDasharray = "2,4";
        }

        const circleBullet = lineSeriesConfiguration(series, {
          valueY: field,
          name: name,
          yAxis: lineValueAxis,
        });
        circleBullet.tooltipText =
          "[bold font-size: var(--regular_font_size)]{name}:[bold font-size: var(--regular_font_size)] {valueY.formatNumber('###,###')}%";
      });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default DmeoConversionRateBarLineChart;
