import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";
import { getGranularityFromDate } from "../../../../utils/utilityFunctions";

interface Props {
  title: string;
  mainValue: number | null;
  isPercentage?: boolean;
  subText?: string;
  yoyValue?: number;
  popValue?: number;
  isLoading: boolean;
  isRating?: boolean;
  maxRatingValue?: number;
  comparisonIsPercentage?: boolean;
  comparisonIsPts?: boolean;
  tooltipText?: string;
}

const PoPLabelMapping: Record<string, string> = {
  weekly: "WoW",
  monthly: "MoM",
  quarterly: "QoQ",
};

const CustomerExperienceTile = (props: Props) => {
  const {
    title,
    mainValue,
    isPercentage,
    subText,
    yoyValue,
    popValue,
    isLoading,
    isRating,
    maxRatingValue,
    comparisonIsPercentage,
    comparisonIsPts,
    tooltipText,
  } = props;

  const dateRangeParam = useSelector((state: RootStateOrAny) => state.parameters.date_range);

  const PoPLabel = useMemo(() => {
    const dateGranularity = getGranularityFromDate(dateRangeParam);
    return PoPLabelMapping[dateGranularity];
  }, [dateRangeParam]);
  return (
    <div className="customer-experience-tile">
      <div className="tile-header">
        {title}
        {tooltipText && (
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={tooltipText}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        )}
      </div>
      <div className="tile-content">
        <div className="main-value">
          {mainValue ? (
            <>
              {mainValue ? mainValue.toLocaleString() : ""}
              {isRating ? `/${maxRatingValue}` : ""}
              {isPercentage ? "%" : ""}
            </>
          ) : (
            "n/a"
          )}

          <div className="main-value-subtext"></div>
        </div>
        <div className="comparison">
          <Ratings value={yoyValue ?? "n/a"} testValue={false} isPercentageValue={isPercentage ?? false} />
          {yoyValue ? `${Number(yoyValue) > 0 ? "+" : ""}${yoyValue}` : "n/a"}
          {yoyValue ? (comparisonIsPercentage ? "%" : comparisonIsPts ? "PTS" : "") : ""} YoY
        </div>
        {popValue ? (
          <div className="comparison">
            <Ratings value={popValue ?? "n/a"} testValue={false} isPercentageValue={isPercentage ?? false} />
            {popValue}
            {popValue ? (comparisonIsPercentage ? "%" : comparisonIsPts ? "PTS" : "") : ""} {PoPLabel}
          </div>
        ) : null}
      </div>
      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};

export default CustomerExperienceTile;
