import { put, retry, takeLatest } from "redux-saga/effects";
import {
  FETCH_ACCESSIBILITY,
  FETCH_BUGS_RELEASE_BREAKDOWN,
  FETCH_BUGS_STATUS_BREAKDOWN,
  FETCH_CUSTOMER_EXPERIENCE,
  FETCH_DIGITAL_PERFORMANCE,
  FETCH_ERROR_PAGES,
  FETCH_FEATURES_ACTIVATED,
  FETCH_INFLUENCE_ON_LEADS,
  FETCH_INFLUENCE_ON_SHOPPING_TOOLS,
  FETCH_LIVE_MARKETS,
  FETCH_PAGE_PERFORMANCE,
  FETCH_PAGE_PERFORMANCE_BREAKDOWN,
  FETCH_TOTAL_BUGS,
} from "../actions/actionTypes/productPerformanceActionTypes";
import { setLoading } from "../actions/loadingActions";
import {
  setAccessibility,
  setBugsReleaseBreakdown,
  setBugsStatusBreakdown,
  setCustomerExperience,
  setDigitalPerformance,
  setErrorPages,
  setFeaturesActivated,
  setInfluenceOnLeads,
  setInfluenceOnShoppingTools,
  setLiveMarkets,
  setPagePerformance,
  setPagePerformanceBreakdown,
  setTotalBugs,
} from "../actions/productPerformanceActions";
import {
  getAccessibility,
  getBugsReleaseBreakdown,
  getBugsStatusBreakdown,
  getCustomerExperience,
  getDigitalPerformance,
  getErrorPages,
  getFeaturesActivated,
  getInfluenceOnLeads,
  getInfluenceOnShoppingTools,
  getLiveMarkets,
  getPagePerformance,
  getPagePerformanceBreakdown,
  getTotalBugs,
} from "../api/productPerformanceApi";
import { secondsDelay } from "../api/utils";

//WATCHER SAGAS
export function* watchFetchLiveMarketsSaga() {
  yield takeLatest(FETCH_LIVE_MARKETS, fetchLiveMarketsSaga);
}

export function* watchFetchFeaturesActivatedSaga() {
  yield takeLatest(FETCH_FEATURES_ACTIVATED, fetchFeaturesActivatedSaga);
}

export function* watchFetchDigitalPerformanceSaga() {
  yield takeLatest(FETCH_DIGITAL_PERFORMANCE, fetchDigitalPerformanceSaga);
}

export function* watchFetchInfluenceOnShoppingToolsSaga() {
  yield takeLatest(FETCH_INFLUENCE_ON_SHOPPING_TOOLS, fetchInfluenceOnShoppingToolsSaga);
}

export function* watchFetchInfluenceOnLeadsSaga() {
  yield takeLatest(FETCH_INFLUENCE_ON_LEADS, fetchInfluenceOnLeadsSaga);
}

export function* watchFetchTotalBugsSaga() {
  yield takeLatest(FETCH_TOTAL_BUGS, fetchTotalBugsSaga);
}

export function* watchFetchBugsStatusBreakdownSaga() {
  yield takeLatest(FETCH_BUGS_STATUS_BREAKDOWN, fetchBugsStatusBreakdownSaga);
}

export function* watchFetchBugsReleaseBreakdownSaga() {
  yield takeLatest(FETCH_BUGS_RELEASE_BREAKDOWN, fetchBugsReleaseBreakdownSaga);
}

export function* watchFetchPagePerformanceSaga() {
  yield takeLatest(FETCH_PAGE_PERFORMANCE, fetchPagePerformanceSaga);
}

export function* watchFetchPagePerformanceBreakdownSaga() {
  yield takeLatest(FETCH_PAGE_PERFORMANCE_BREAKDOWN, fetchPagePerformanceBreakdownSaga);
}

export function* watchFetchAccessibilitySaga() {
  yield takeLatest(FETCH_ACCESSIBILITY, fetchAccessibilitySaga);
}

export function* watchFetchCustomerExperienceSaga() {
  yield takeLatest(FETCH_CUSTOMER_EXPERIENCE, fetchCustomerExperienceSaga);
}

export function* watchFetchErrorPagesSaga() {
  yield takeLatest(FETCH_ERROR_PAGES, fetchErrorPagesSaga);
}
//WORKER SAGAS
export function* fetchLiveMarketsSaga() {
  try {
    yield put(setLoading("live_markets", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getLiveMarkets);
    if (!("error" in response)) {
      yield put(setLiveMarkets(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("live_markets", false));
  }
}

export function* fetchFeaturesActivatedSaga() {
  try {
    yield put(setLoading("features_activated", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getFeaturesActivated);
    if (!("error" in response)) {
      yield put(setFeaturesActivated(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("features_activated", false));
  }
}

export function* fetchDigitalPerformanceSaga() {
  try {
    yield put(setLoading("digital_performance", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getDigitalPerformance);
    if (!("error" in response)) {
      yield put(setDigitalPerformance(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("digital_performance", false));
  }
}

export function* fetchInfluenceOnShoppingToolsSaga() {
  try {
    yield put(setLoading("influence_on_shopping_tools", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getInfluenceOnShoppingTools);
    if (!("error" in response)) {
      yield put(setInfluenceOnShoppingTools(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("influence_on_shopping_tools", false));
  }
}

export function* fetchInfluenceOnLeadsSaga() {
  try {
    yield put(setLoading("influence_on_leads", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getInfluenceOnLeads);
    if (!("error" in response)) {
      yield put(setInfluenceOnLeads(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("influence_on_leads", false));
  }
}

export function* fetchTotalBugsSaga() {
  try {
    yield put(setLoading("total_bugs", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getTotalBugs);
    if (!("error" in response)) {
      yield put(setTotalBugs(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("total_bugs", false));
  }
}

export function* fetchBugsStatusBreakdownSaga() {
  try {
    yield put(setLoading("bugs_status_breakdown", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getBugsStatusBreakdown);
    if (!("error" in response)) {
      yield put(setBugsStatusBreakdown(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("bugs_status_breakdown", false));
  }
}

export function* fetchBugsReleaseBreakdownSaga() {
  try {
    yield put(setLoading("bugs_release_breakdown", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getBugsReleaseBreakdown);
    if (!("error" in response)) {
      yield put(setBugsReleaseBreakdown(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("bugs_release_breakdown", false));
  }
}

export function* fetchPagePerformanceSaga() {
  try {
    yield put(setLoading("page_performance", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getPagePerformance);
    if (!("error" in response)) {
      yield put(setPagePerformance(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("page_performance", false));
  }
}

export function* fetchPagePerformanceBreakdownSaga() {
  try {
    yield put(setLoading("page_performance_breakdown", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getPagePerformanceBreakdown);
    if (!("error" in response)) {
      yield put(setPagePerformanceBreakdown(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("page_performance_breakdown", false));
  }
}

export function* fetchAccessibilitySaga() {
  try {
    yield put(setLoading("accessibility", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getAccessibility);
    if (!("error" in response)) {
      yield put(setAccessibility(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("accessibility", false));
  }
}

export function* fetchCustomerExperienceSaga() {
  try {
    yield put(setLoading("customer_experience", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getCustomerExperience);
    if (!("error" in response)) {
      yield put(setCustomerExperience(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("customer_experience", false));
  }
}

export function* fetchErrorPagesSaga() {
  try {
    yield put(setLoading("error_pages", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getErrorPages);
    if (!("error" in response)) {
      yield put(setErrorPages(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("error_pages", false));
  }
}
