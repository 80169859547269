import { ErrorBoundary } from "@sentry/react";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import { getMarketingInvestmentReports } from "../../api/marketingInvestmentDashboardApi";
import { ErrorMsg } from "../../components/AppMessages";
import MarketingInvastmentDashboardFilters from "../../components/Filters/MarketingInvastmentDashboardFilters";
import { IframeLayout } from "../../components/Layouts";
import { IframePageContent } from "../../components/PageContent";

const MarketingInvestmentDashboard = withRouter((props: RouteComponentProps): JSX.Element => {
  const [reportsList, setReportsList] = useState<Record<string, string>>({});
  const [selectedDashboard, setSelectedDashboard] = useState<string>("fmi_by_model");
  const location = useLocation();
  useEffect(() => {
    //@ts-ignore
    setSelectedDashboard(location?.state?.selectedDashboard ? location?.state?.selectedDashboard : "fmi_by_model");
  }, [location]);
  //Fetch the search interest data
  useEffect(() => {
    getMarketingInvestmentReports().then((response) => {
      const reportsObj: Record<string, string> = {};
      if (response && !("error" in response && response.data)) {
        response.data.map((item: { dashboard: string; link: string }) => {
          reportsObj[item.dashboard.split(" ").join("_").toLowerCase()] = item.link;
        });
      }
      setReportsList(reportsObj);
    });
  }, []);

  const iframeUrl = useMemo(() => {
    if (selectedDashboard && selectedDashboard != "") {
      return reportsList[selectedDashboard];
    }
    return "";
  }, [reportsList, selectedDashboard]);

  return (
    <IframeLayout hasFilter={true}>
      <ErrorBoundary fallback={<ErrorMsg />}>
        <IframePageContent
          frameId="marketing_investment_dashboard"
          filter={
            <MarketingInvastmentDashboardFilters
              filterBtns={[{ id: "fmi_run_rate_btn", name: "FMI run rate", navigateTo: "/midas/fmi_run_rate" }]}
            />
          }
          iframeUrl={iframeUrl}
        />
      </ErrorBoundary>
    </IframeLayout>
  );
});

export default MarketingInvestmentDashboard;
