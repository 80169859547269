import React, { useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import ProfileByMonthChart from "../../../components/Charts/MSRP/ProfileByMonthChart";
import { ThemeContext } from "../../../context";

const SERIES_LIST = [
  { name: "DIS Avg VME", field: "dis_vme" },
  { name: "Non DIS Avg VME", field: "non_dis_vme" },
];

const VmeDiscountProfileByMonth = () => {
  const themeContext = useContext(ThemeContext);
  const data = useSelector((state: RootStateOrAny) => state.msrp.msrp_profile_by_month);
  const { msrp_profile_by_month: msrpProfileByMonthLoading } = useSelector((state: RootStateOrAny) => state.loading);

  return (
    <div className="msrp-tile">
      <div className="tile-header">
        VME Discount Profile by Month
        <Tooltip
          position="top-end"
          className={"normal_tippy"}
          trigger="mouseenter"
          animation="none"
          interactive={true}
          distance={50}
          delay={1}
          hideDelay={1}
          duration={1}
          title={"Average Non-DIS VME discount vs DIS VME discount in percentage"}
          size={"small"}
        >
          <span className="info_tooltip" />
        </Tooltip>
      </div>
      <ProfileByMonthChart
        data={data}
        chartId="msrpVmeProfileByMonth"
        theme={themeContext.theme}
        seriesList={SERIES_LIST}
        isLoading={msrpProfileByMonthLoading}
        isPercentage={true}
      />
    </div>
  );
};

export default VmeDiscountProfileByMonth;
