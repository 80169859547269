import React from "react";
import "../../assets/styles/component/toggleSwitch.scss";

interface ToggleSwitchProps {
  firstElement: string;
  secondElement: string;

  toggleTitle?: string;
  handleToggleClick: any;
  activeSelection: string[];
  firstLabel?: string;
  secondLabel?: string;
}

export const ThreeToggleSwitch = (props: ToggleSwitchProps): JSX.Element => {
  const { handleToggleClick, toggleTitle, firstElement, secondElement, activeSelection, firstLabel, secondLabel } = props;

  return (
    <div className={`toggle_container`}>
      {toggleTitle && <span className="toggle_title">{toggleTitle} </span>}

      <div className="toggle_buttons toggle_switch">
        <span className="input_span">
          <input
            id="active_toggl"
            type="radio"
            checked={activeSelection.includes(firstElement)}
            onClick={() => handleToggleClick(firstElement)}
            className={`toggle_btn `}
          />
          <span className={`${activeSelection.includes(firstElement) ? "active_label" : ""} toggle_label`}>
            {firstLabel ? firstLabel : firstElement}
          </span>
        </span>
        <span className="input_span">
          <input
            id="active_toggl"
            type="radio"
            checked={activeSelection.includes(secondElement)}
            onClick={() => handleToggleClick(secondElement)}
          />
          <span className={`${activeSelection.includes(secondElement) ? "active_label" : ""} toggle_label`}>
            {secondLabel ? secondLabel : secondElement}
          </span>
        </span>
      </div>
    </div>
  );
};
