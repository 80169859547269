import React from "react";
// TODO: ask Max about the logic for % above 999 being assigned the class 'na'
// import Ratings from "../../../components/Ratings/Ratings";
import Ratings from "../../../components/Ratings/NewRatings";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";

interface Props {
  title: string;
  value: number;
  valuePy: number;
  valuePm: number;
  isFetching?: boolean;
}

const KbasLeadsTile = (props: Props) => {
  const { title, value, valuePy, valuePm, isFetching = false } = props;
  return (
    <div className="tile kbas-leads-tile">
      <div className="tile-header">{title}</div>
      <div className="tile-content">
        <div className="main-value">{value ? formatToThreeDigits(value) : "n/a"}</div>
        <div>
          <div className="sub-text first-sub-text">
            {valuePy && <Ratings value={valuePy} />} <div>{valuePy ? `${Math.abs(valuePy)}%` : "n/a"}</div> <div>&nbsp;vs PY</div>
          </div>
          <div className="sub-text">
            {valuePm && <Ratings value={valuePm} />} <div>{valuePm ? `${Math.abs(valuePm)}%` : "n/a"}</div> <div>&nbsp;vs PM</div>
          </div>
        </div>
      </div>
      <LoadingEllipsis isLoading={isFetching} />
    </div>
  );
};

export default KbasLeadsTile;
