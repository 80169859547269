import { AnyAction } from "redux";
import { put, retry, takeLatest } from "redux-saga/effects";
import {
  FETCH_CONVERSION,
  FETCH_CONVERSION_AND_COST,
  FETCH_CONVERSION_TREND,
  FETCH_CUSTOMER_EXPERIENCE_DXS,
  FETCH_CUSTOMER_JOURNEY,
  FETCH_DIGITAL_SPEND,
  FETCH_DIGITAL_SPEND_VISITS_DATA,
  FETCH_DIGITAL_SPEND_VISITS_TREND,
  FETCH_EXIT_SURVEY_FEEDBACK,
  FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION,
  FETCH_FMI_LAST_ACT_MONTH,
  FETCH_JOURNEY_COST,
  FETCH_JOURNEY_COST_TREND,
  FETCH_LMS_CONVERSION_AND_COST,
  FETCH_LMS_CONVERSION_AND_COST_TREND,
  FETCH_LMS_DIGITALLY_INFLUENCED_SALES,
  FETCH_LMS_DIGITALLY_INFLUENCED_SALES_TREND,
  FETCH_LMS_DIS_AND_LEADS,
  FETCH_LMS_SALES_AND_LEADS_TREND,
  FETCH_METRIC_CHANNEL,
  FETCH_METRIC_CHANNEL_TREND,
  FETCH_PERFORMANCE_OVERVIEW,
  FETCH_PRODUCT_JOURNEY_ENTRY_POINTS,
  FETCH_PRODUCT_JOURNEY_EXIT_POINTS,
  FETCH_PRODUCT_JOURNEY_PERFORMANCE_OVERVIEW,
  FETCH_PRODUCT_JOURNEY_WEBSITE_ENTRY_POINTS,
  FETCH_STAR_RATING_AVERAGE,
  FETCH_STAR_RATING_FEEDBACK,
  FETCH_WEBSITE_PERFORMANCE,
  FETCH_WEBSITE_PERFORMANCE_TREND,
} from "../actions/actionTypes";
import {
  setConversion,
  setConversionAndCost,
  setConversionTrend,
  setCustomerExperienceDxs,
  setCustomerJourney,
  setDigitalSpend,
  setDigitalSpendVisitsData,
  setDigitalSpendVisitsTrend,
  setExitSurveyFeedback,
  setExitSurveyObjectiveCompletion,
  setFmiLastActMonth,
  setJourneyCost,
  setJourneyCostTrend,
  setLmsConversionAndCost,
  setLmsConversionAndCostTrend,
  setLmsDigitallyInfluencedSales,
  setLmsDigitallyInfluencedSalesTrend,
  setLmsDisAndLeads,
  setLmsSalesAndLeadsTrend,
  setMetricChannel,
  setMetricChannelTrend,
  setPerformanceOverview,
  setProductJourneyEntryPoints,
  setProductJourneyExitPoints,
  setProductJourneyPerformanceOverview,
  setProductJourneyWebsiteEntryPoints,
  setStarRatingAverage,
  setStarRatingFeedback,
  setWebsitePerformance,
  setWebsitePerformanceTrend,
} from "../actions/journeyExcellenceActions";
import { setLoading } from "../actions/loadingActions";
import {
  getConversion,
  getConversionAndCost,
  getConversionTrend,
  getCustomerExperienceDxs,
  getCustomerJourney,
  getDigitalSpend,
  getDigitalSpendVisitsData,
  getDigitalSpendVisitsTrend,
  getExitSurveyFeedback,
  getExitSurveyObjectiveCompletion,
  getFmiLastActMonth,
  getJourneyCost,
  getJourneyCostTrend,
  getLmsConversionAndCost,
  getLmsConversionAndCostTrend,
  getLmsDigitallyInfluencedSales,
  getLmsDigitallyInfluencedSalesTrend,
  getLmsDisAndLeads,
  getLmsSalesAndLeadsTrend,
  getMetricChannel,
  getMetricChannelTrend,
  getPerformanceOverview,
  getProductJourneyEntryPoints,
  getProductJourneyExitPoints,
  getProductJourneyPerformanceOverview,
  getProductJourneyWebsiteEntryPoints,
  getStarRatingAverage,
  getStarRatingFeedback,
  getWebsitePerformance,
  getWebsitePerformanceTrend,
} from "../api/journeyExcellenceApi";
import { secondsDelay } from "../api/utils";

//WATCHER SAGAS
export function* watchDigitalSpendSaga() {
  yield takeLatest(FETCH_DIGITAL_SPEND, fetchDigitalSpendSaga);
}

export function* watchDigitalSpendVisitsSaga() {
  yield takeLatest(FETCH_DIGITAL_SPEND_VISITS_DATA, fetchDigitalSpendVisitsSaga);
}

export function* watchDigitalSpendVisitsTrendSaga() {
  yield takeLatest(FETCH_DIGITAL_SPEND_VISITS_TREND, fetchDigitalSpendVisitsTrendSaga);
}

export function* watchConversionAndCostSaga() {
  yield takeLatest(FETCH_CONVERSION_AND_COST, fetchConversionAndCostSaga);
}

export function* watchPerformanceOverviewSaga() {
  yield takeLatest(FETCH_PERFORMANCE_OVERVIEW, fetchPerformanceOverviewSaga);
}

export function* watchWebsitePerformanceSaga() {
  yield takeLatest(FETCH_WEBSITE_PERFORMANCE, fetchWebsitePerformanceSaga);
}

export function* watchWebsitePerformanceTrendSaga() {
  yield takeLatest(FETCH_WEBSITE_PERFORMANCE_TREND, fetchWebsitePerformanceTrendSaga);
}

export function* watchJourneyCostSaga() {
  yield takeLatest(FETCH_JOURNEY_COST, fetchJourneyCostSaga);
}

export function* watchJourneyCostTrendSaga() {
  yield takeLatest(FETCH_JOURNEY_COST_TREND, fetchJourneyCostTrendSaga);
}

export function* watchCustomerJourneySaga() {
  yield takeLatest(FETCH_CUSTOMER_JOURNEY, fetchCustomerJourneySaga);
}
export function* watchConversionSaga() {
  yield takeLatest(FETCH_CONVERSION, fetchConversionSaga);
}

export function* watchConversionTrendSaga() {
  yield takeLatest(FETCH_CONVERSION_TREND, fetchConversionTrendSaga);
}

export function* watchMetricChannelSaga() {
  yield takeLatest(FETCH_METRIC_CHANNEL, fetchMetricChannelSaga);
}
export function* watchMetricChannelTrendSaga() {
  yield takeLatest(FETCH_METRIC_CHANNEL_TREND, fetchMetricChannelTrendSaga);
}

export function* watchCustomerExperienceDxsSaga() {
  yield takeLatest(FETCH_CUSTOMER_EXPERIENCE_DXS, fetchCustomerExperienceDxsSaga);
}

export function* watchStarRatingAverageSaga() {
  yield takeLatest(FETCH_STAR_RATING_AVERAGE, fetchStarRatingAverageSaga);
}

export function* watchStarRatingFeedbackSaga() {
  yield takeLatest(FETCH_STAR_RATING_FEEDBACK, fetchStarRatingFeedbackSaga);
}

export function* watchExitSurveyObjectiveCompletionSaga() {
  yield takeLatest(FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION, fetchExitSurveyObjectiveCompletionSaga);
}

export function* watchExitSurveyFeedbackSaga() {
  yield takeLatest(FETCH_EXIT_SURVEY_FEEDBACK, fetchExitSurveyFeedbackSaga);
}

export function* watchLmsDigitallyInfluencedSalesSaga() {
  yield takeLatest(FETCH_LMS_DIGITALLY_INFLUENCED_SALES, fetchLmsDigitallyInfluencedSalesSaga);
}

export function* watchLmsDigitallyInfluencedSalesTrendSaga() {
  yield takeLatest(FETCH_LMS_DIGITALLY_INFLUENCED_SALES_TREND, fetchLmsDigitallyInfluencedSalesTrendSaga);
}

export function* watchLmsDisAndLeadsSaga() {
  yield takeLatest(FETCH_LMS_DIS_AND_LEADS, fetchLmsDisAndLeadsSaga);
}

export function* watchLmsSalesAndLeadsTrendSaga() {
  yield takeLatest(FETCH_LMS_SALES_AND_LEADS_TREND, fetchLmsSalesAndLeadsTrendSaga);
}

export function* watchLmsConversionAndCostSaga() {
  yield takeLatest(FETCH_LMS_CONVERSION_AND_COST, fetchLmsConversionAndCostSaga);
}

export function* watchLmsConversionAndCostTrendSaga() {
  yield takeLatest(FETCH_LMS_CONVERSION_AND_COST_TREND, fetchLmsConversionAndCostTrendSaga);
}

export function* watchProductJourneyPerformanceOverviewSaga() {
  yield takeLatest(FETCH_PRODUCT_JOURNEY_PERFORMANCE_OVERVIEW, fetchProductJourneyPerformanceOverviewSaga);
}

export function* watchProductJourneyEntryPointsSaga() {
  yield takeLatest(FETCH_PRODUCT_JOURNEY_ENTRY_POINTS, fetchProductJourneyEntryPointsSaga);
}

export function* watchProductJourneyExitPointsSaga() {
  yield takeLatest(FETCH_PRODUCT_JOURNEY_EXIT_POINTS, fetchProductJourneyExitPointsSaga);
}

export function* watchProductJourneyWebsiteEntryPointsSaga() {
  yield takeLatest(FETCH_PRODUCT_JOURNEY_WEBSITE_ENTRY_POINTS, fetchProductJourneyWebsiteEntryPointsSaga);
}

export function* watchFmiLastActMonthSaga() {
  yield takeLatest(FETCH_FMI_LAST_ACT_MONTH, fetchFmiLastActMonth);
}
//WORKER SAGAS
export function* fetchDigitalSpendSaga({ payload }: AnyAction) {
  try {
    yield put(setLoading("digital_spend", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getDigitalSpend, payload);
    if (!("error" in response)) {
      yield put(setDigitalSpend(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/fmi/digital-spend");
    console.log(err);
  } finally {
    yield put(setLoading("digital_spend", false));
  }
}

export function* fetchDigitalSpendVisitsSaga({ payload }: AnyAction) {
  try {
    yield put(setLoading("digital_spend_visits", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getDigitalSpendVisitsData, payload);
    if (!("error" in response)) {
      yield put(setDigitalSpendVisitsData(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/fmi/digital-spend-visits");
    console.log(err);
  } finally {
    yield put(setLoading("digital_spend_visits", false));
  }
}

export function* fetchDigitalSpendVisitsTrendSaga() {
  try {
    yield put(setLoading("digital_spend_visits_trend", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getDigitalSpendVisitsTrend);
    if (!("error" in response)) {
      yield put(setDigitalSpendVisitsTrend(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/fmi/digital-spend-visits-trend");
    console.log(err);
  } finally {
    yield put(setLoading("digital_spend_visits_trend", false));
  }
}

export function* fetchConversionAndCostSaga({ payload }: AnyAction) {
  try {
    yield put(setLoading("conversion_and_cost", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getConversionAndCost, payload);
    if (!("error" in response)) {
      yield put(setConversionAndCost(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/lms/conversion-and-cost");
    console.log(err);
  } finally {
    yield put(setLoading("conversion_and_cost", false));
  }
}

export function* fetchPerformanceOverviewSaga() {
  try {
    yield put(setLoading("performance_overview", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getPerformanceOverview);
    if (!("error" in response)) {
      yield put(setPerformanceOverview(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/product-journey/performance-overview");
    console.log(err);
  } finally {
    yield put(setLoading("performance_overview", false));
  }
}

export function* fetchWebsitePerformanceSaga() {
  try {
    yield put(setLoading("website_performance", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getWebsitePerformance);
    if (!("error" in response)) {
      yield put(setWebsitePerformance(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/online-journey/website-performance");
    console.log(err);
  } finally {
    yield put(setLoading("website_performance", false));
  }
}

export function* fetchWebsitePerformanceTrendSaga() {
  try {
    yield put(setLoading("website_performance_trend", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getWebsitePerformanceTrend);
    if (!("error" in response)) {
      yield put(setWebsitePerformanceTrend(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/online-journey/website-performance-trend");
    console.log(err);
  } finally {
    yield put(setLoading("website_performance_trend", false));
  }
}

export function* fetchJourneyCostSaga({ payload }: AnyAction) {
  try {
    yield put(setLoading("journey_cost", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getJourneyCost, payload);
    if (!("error" in response)) {
      yield put(setJourneyCost(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/online-journey/journey-costs");
    console.log(err);
  } finally {
    yield put(setLoading("journey_cost", false));
  }
}

export function* fetchJourneyCostTrendSaga() {
  try {
    yield put(setLoading("journey_cost_trend", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getJourneyCostTrend);
    if (!("error" in response)) {
      yield put(setJourneyCostTrend(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/online-journey/journey-costs-trend");
    console.log(err);
  } finally {
    yield put(setLoading("journey_cost_trend", false));
  }
}

export function* fetchCustomerJourneySaga() {
  try {
    yield put(setLoading("customer_journey", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getCustomerJourney);
    if (!("error" in response)) {
      yield put(setCustomerJourney(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/online-journey/customer-journey");
    console.log(err);
  } finally {
    yield put(setLoading("customer_journey", false));
  }
}

export function* fetchConversionSaga() {
  try {
    yield put(setLoading("conversion", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getConversion);
    if (!("error" in response)) {
      yield put(setConversion(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/online-journey/conversion");
    console.log(err);
  } finally {
    yield put(setLoading("conversion", false));
  }
}

export function* fetchConversionTrendSaga() {
  try {
    yield put(setLoading("conversion_trend", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getConversionTrend);
    if (!("error" in response)) {
      yield put(setConversionTrend(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/online-journey/conversion-trend");
    console.log(err);
  } finally {
    yield put(setLoading("conversion_trend", false));
  }
}

export function* fetchMetricChannelSaga() {
  try {
    yield put(setLoading("metric_channel", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getMetricChannel);
    if (!("error" in response)) {
      yield put(setMetricChannel(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/online-journey/metric-channel");
    console.log(err);
  } finally {
    yield put(setLoading("metric_channel", false));
  }
}

export function* fetchMetricChannelTrendSaga({ payload: { channel, metric } }: AnyAction) {
  try {
    yield put(setLoading("metric_channel_trend", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getMetricChannelTrend, channel, metric);
    if (!("error" in response)) {
      yield put(setMetricChannelTrend(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/online-journey/metric-channel-trend");
    console.log(err);
  } finally {
    yield put(setLoading("metric_channel_trend", false));
  }
}

export function* fetchCustomerExperienceDxsSaga() {
  try {
    yield put(setLoading("customer_experience_dxs", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getCustomerExperienceDxs);
    if (!("error" in response)) {
      yield put(setCustomerExperienceDxs(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/online-journey/customer-experience-dxs");
    console.log(err);
  } finally {
    yield put(setLoading("customer_experience_dxs", false));
  }
}

export function* fetchStarRatingAverageSaga() {
  try {
    yield put(setLoading("star_rating_average", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getStarRatingAverage);
    if (!("error" in response)) {
      yield put(setStarRatingAverage(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/online-journey/star-rating-average");
    console.log(err);
  } finally {
    yield put(setLoading("star_rating_average", false));
  }
}

export function* fetchStarRatingFeedbackSaga() {
  try {
    yield put(setLoading("star_rating_feedback", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getStarRatingFeedback);
    if (!("error" in response)) {
      yield put(setStarRatingFeedback(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/online-journey/star-rating-feedback");
    console.log(err);
  } finally {
    yield put(setLoading("star_rating_feedback", false));
  }
}

export function* fetchExitSurveyObjectiveCompletionSaga() {
  try {
    yield put(setLoading("exit_survey_objective_completion", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getExitSurveyObjectiveCompletion);
    if (!("error" in response)) {
      yield put(setExitSurveyObjectiveCompletion(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/online-journey/exit-survey-objective-completion");
    console.log(err);
  } finally {
    yield put(setLoading("exit_survey_objective_completion", false));
  }
}

export function* fetchExitSurveyFeedbackSaga() {
  try {
    yield put(setLoading("je_exit_survey_feedback", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getExitSurveyFeedback);
    if (!("error" in response)) {
      yield put(setExitSurveyFeedback(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/online-journey/exit-survey-feedback");
    console.log(err);
  } finally {
    yield put(setLoading("je_exit_survey_feedback", false));
  }
}

export function* fetchLmsDigitallyInfluencedSalesSaga() {
  try {
    yield put(setLoading("lms_digitally_influenced_sales", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getLmsDigitallyInfluencedSales);
    if (!("error" in response)) {
      yield put(setLmsDigitallyInfluencedSales(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/lms/digitally-influenced-sales");
    console.log(err);
  } finally {
    yield put(setLoading("lms_digitally_influenced_sales", false));
  }
}

export function* fetchLmsDigitallyInfluencedSalesTrendSaga() {
  try {
    yield put(setLoading("lms_digitally_influenced_sales_trend", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getLmsDigitallyInfluencedSalesTrend);
    if (!("error" in response)) {
      yield put(setLmsDigitallyInfluencedSalesTrend(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/lms/digitally-influenced-sales-trend");
    console.log(err);
  } finally {
    yield put(setLoading("lms_digitally_influenced_sales_trend", false));
  }
}

export function* fetchLmsDisAndLeadsSaga() {
  try {
    yield put(setLoading("lms_dis_and_leads", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getLmsDisAndLeads);
    if (!("error" in response)) {
      yield put(setLmsDisAndLeads(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/lms/dis-and-leads");
    console.log(err);
  } finally {
    yield put(setLoading("lms_dis_and_leads", false));
  }
}

export function* fetchLmsSalesAndLeadsTrendSaga() {
  try {
    yield put(setLoading("lms_sales_and_leads_trend", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getLmsSalesAndLeadsTrend);
    if (!("error" in response)) {
      yield put(setLmsSalesAndLeadsTrend(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/lms/dis-and-leads");
    console.log(err);
  } finally {
    yield put(setLoading("lms_sales_and_leads_trend", false));
  }
}

export function* fetchLmsConversionAndCostSaga({ payload }: AnyAction) {
  try {
    yield put(setLoading("lms_conversion_and_cost", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getLmsConversionAndCost, payload);
    if (!("error" in response)) {
      yield put(setLmsConversionAndCost(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/lms/conversion-and-cost");
    console.log(err);
  } finally {
    yield put(setLoading("lms_conversion_and_cost", false));
  }
}

export function* fetchLmsConversionAndCostTrendSaga() {
  try {
    yield put(setLoading("lms_conversion_and_cost_trend", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getLmsConversionAndCostTrend);
    if (!("error" in response)) {
      yield put(setLmsConversionAndCostTrend(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/lms/conversion-and-cost-trend");
    console.log(err);
  } finally {
    yield put(setLoading("lms_conversion_and_cost_trend", false));
  }
}

export function* fetchProductJourneyPerformanceOverviewSaga({ payload }: AnyAction) {
  try {
    yield put(setLoading("product_journey_performance_overview", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getProductJourneyPerformanceOverview, payload);
    if (!("error" in response)) {
      yield put(setProductJourneyPerformanceOverview(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/product-journey/performance-overview");
    console.log(err);
  } finally {
    yield put(setLoading("product_journey_performance_overview", false));
  }
}

export function* fetchProductJourneyEntryPointsSaga() {
  try {
    yield put(setLoading("product_journey_entry_points", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getProductJourneyEntryPoints);
    if (!("error" in response)) {
      yield put(setProductJourneyEntryPoints(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/product-journey/entry-points");
    console.log(err);
  } finally {
    yield put(setLoading("product_journey_entry_points", false));
  }
}

export function* fetchProductJourneyExitPointsSaga() {
  try {
    yield put(setLoading("product_journey_exit_points", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getProductJourneyExitPoints);
    if (!("error" in response)) {
      yield put(setProductJourneyExitPoints(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/product-journey/exit-points");
    console.log(err);
  } finally {
    yield put(setLoading("product_journey_exit_points", false));
  }
}

export function* fetchProductJourneyWebsiteEntryPointsSaga() {
  try {
    yield put(setLoading("product_journey_website_entry_points", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getProductJourneyWebsiteEntryPoints);
    if (!("error" in response)) {
      yield put(setProductJourneyWebsiteEntryPoints(response));
    }
  } catch (err) {
    console.log("failed to fetch /journey-excellence/product-journey/website-entry-points");
    console.log(err);
  } finally {
    yield put(setLoading("product_journey_website_entry_points", false));
  }
}

export function* fetchFmiLastActMonth() {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getFmiLastActMonth);
    if (!("error" in response)) {
      yield put(setFmiLastActMonth(response));
    }
  } catch (err) {
    console.log("failed to fetch /fmi/last-act-month");
    console.log(err);
  }
}
