import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { useVisible } from "../../../hooks";
import { getDateDependentValues } from "../../../pages/Online CE Performance/subs/helpers";
import { computeComparison, percentageShareCalculation, periodComparison } from "../../../pages/SEO/helpers/helpers";
import { move } from "../../../utils/utilityFunctions";
import { ToggleSwitch } from "../../Toogle";
import { getClicksColumns, getImpressionsColumns } from "./subs/columns";
import SeoDefaultChildTable from "./subs/seoChildTable";
import { searchCriteriaOptions, SEARCH_TERM_MAP } from "./subs/seoConstants";
import SeoDefaultTable from "./subs/seoDefaultTable";

const ImpressionsBreakdownTable = ({
  clicksChecked,
  impressionsChecked,
  sortTableBy,
}: {
  clicksChecked: boolean;
  impressionsChecked: boolean;
  sortTableBy: string;
}): JSX.Element => {
  const dispatch = useDispatch();
  //Param values
  const impressions_breakdown = useSelector((state: RootStateOrAny) => state.seo_data.impressions_breakdown);
  const impressions_search_term = useSelector((state: RootStateOrAny) => state.seo_parameters.impressions_search_term);
  const impressionsDisabled = useSelector((state: RootStateOrAny) => state.seo_parameters.impressions_disabled);
  const impressionsQuery = useSelector((state: RootStateOrAny) => state.seo_parameters.query);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.seo_parameters.date_range);

  const [showImpressionsHidden, setShowImpressionsHidden] = useState(false);
  const [showPercentageShare, setShowPercentageShare] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const { wrapperRef, isVisible, setIsVisible } = useVisible(false);
  const metric = "impressions";
  const { popLabel } = getDateDependentValues(dateRangeParamValue);

  // Search variables
  const [filter, setFilter] = useState<string>(impressions_search_term);
  const [searchCriteria, setSearchCriteria] = useState<any>("CONTAINS");
  const [searchCriteriaInputValue, setSearchCriteriaInputValue] = useState<keyof typeof SEARCH_TERM_MAP>("Contains");

  // Resets filters selection on component mount
  useEffect(() => {
    setFilter("");
    dispatch(setSpecificParameter("impressions_search_term", ""));
    dispatch(setSpecificParameter("impressions_search_type", ""));
  }, [dispatch]);

  //Data manipulation to store the impressions data and format it to the desired output
  useEffect(() => {
    if (impressions_breakdown?.length > 0) {
      const { impressions: totalImpressions, clicks: totalClicks } = impressions_breakdown[0].totals[0].current_data;
      const myData = impressions_breakdown[0]?.data?.map((data: any) => {
        const {
          current_data: { [metric]: currentValue, clicks },
        } = data;

        const yoy_data = data.yoy_data;
        const pop_data = data.pop_data;

        const percentageShare = percentageShareCalculation(currentValue, totalImpressions);
        const clicksPercentageShare = percentageShareCalculation(clicks, totalClicks);

        const popValue = typeof pop_data?.[metric] !== "number" ? 0 : pop_data?.[metric];
        const yoyValue = typeof yoy_data?.[metric] !== "number" ? 0 : yoy_data?.[metric];
        const clicksPopValue = typeof pop_data?.clicks !== "number" ? 0 : pop_data?.clicks;
        const clicksYoyValue = typeof yoy_data?.clicks !== "number" ? 0 : yoy_data?.clicks;

        const popComparison = periodComparison(currentValue, popValue);
        const yoyComparison = periodComparison(currentValue, yoyValue);
        const clicksPopComparison = periodComparison(clicks, clicksPopValue);
        const clicksYoyComparison = periodComparison(clicks, clicksYoyValue);

        const popPercentage = parseFloat(computeComparison(currentValue, popValue));
        const yoyPercentage = parseFloat(computeComparison(currentValue, yoyValue));
        const clicksPopPercentage = parseFloat(computeComparison(clicks, clicksPopValue));
        const clicksYoyPercentage = parseFloat(computeComparison(clicks, clicksYoyValue));

        const clicksData = [
          {
            clicks,
            clicksPercentageShare,
            clicksPopComparison,
            clicksPopPercentage,
            clicksYoyComparison,
            clicksYoyPercentage,
          },
        ];

        return {
          ...data,
          popPercentage,
          yoyPercentage,
          popComparison,
          yoyComparison,
          percentageShare,
          clicksData,
          clicks,
          clicksPercentageShare,
          clicksPopComparison,
          clicksPopPercentage,
          clicksYoyComparison,
          clicksYoyPercentage,
        };
      });
      //Remove row with All query on the impressions breakdown table
      const impressionsData = myData?.filter(({ hostname }: { hostname: string }) => hostname !== "All");
      const selectedRow = impressionsData?.filter((row: any) => row.hostname === impressionsQuery)[0];

      const formattedData = selectedRow ? move(impressionsData, impressionsData.indexOf(selectedRow), 0) : impressionsData;
      setTableData(formattedData);
    } else {
      setTableData([]);
    }
  }, [dispatch, impressions_breakdown, impressionsQuery]);

  //Function to handle clicking of a row
  const handleImpressionsClick = (event: { preventDefault?: any; target?: any; currentTarget?: any }) => {
    event.preventDefault();
    const { target } = event;
    const query = target.getAttribute("data-text");
    if (query === impressionsQuery) {
      dispatch(setSpecificParameter("query", "All"));
    } else {
      dispatch(setSpecificParameter("query", query));
    }
  };

  const tableSortBy = impressionsQuery === "All" ? [{ id: "volumeColumn", desc: true }] : [];

  const renderRowSubComponent = React.useCallback(
    ({ row }) =>
      clicksChecked &&
      impressionsChecked && (
        <SeoDefaultChildTable
          key={Math.random()}
          data={row.original.clicksData}
          columns={getClicksColumns({ showPercentageShare, handleImpressionsClick, showImpressions: true })}
          showHidden={showImpressionsHidden}
        />
      ),
    [clicksChecked, impressionsChecked, showPercentageShare, showImpressionsHidden]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setFilter(value);
  };

  useEffect(() => {
    const selectedSearchCriteria = searchCriteriaOptions.filter(
      (searchCriteriaOption) => searchCriteriaOption.ranking === searchCriteria
    )[0];
    setSearchCriteriaInputValue(selectedSearchCriteria.option);
  }, [searchCriteria]);

  const handleApplyToTrendline = () => {
    dispatch(setSpecificParameter("impressions_search_term", filter));
    dispatch(setSpecificParameter("impressions_search_type", SEARCH_TERM_MAP[searchCriteriaInputValue]));
  };

  return (
    <div className={`breakdown-table ${metric}-breakdown ${impressionsDisabled ? "disabled" : ""}`}>
      {tableData.length === 0 && impressions_search_term === "" ? (
        <p>No table data</p>
      ) : (
        <>
          <>
            <div className="d-flex table-menu">
              <ToggleSwitch
                activeToggleLabel={""}
                inactiveToggleLabel={"Show % share"}
                active={!showPercentageShare}
                handleToggleClick={() => setShowPercentageShare(!showPercentageShare)}
                toggleClassName={"seo_table_toggle"}
              />

              <div className="hidden-columns">
                <span onClick={() => setShowImpressionsHidden(!showImpressionsHidden)}>
                  {showImpressionsHidden ? "Hide variations in volume" : "Show variations in volume"}
                </span>
              </div>
            </div>
            <div className="search_container search_bar" data-test-id="impressions_search_container">
              <div className="search_criteria " data-test-id="impressions_search_criteria_div">
                <div className="filter no-border" ref={wrapperRef}>
                  <label className="filter_header">SEARCH QUERY</label>
                  <div className="input_arrow" onClick={() => setIsVisible(!isVisible)}>
                    <span className="arrow_down" />
                    <input
                      className={"filter_input"}
                      value={searchCriteriaInputValue}
                      readOnly
                      data-test-id="impressions_search_criteria_input"
                    />
                  </div>
                  <ul className={`filter_menu ${isVisible ? "showOptions" : ""}`} data-test-id="impressions_search_criteria_ul">
                    {searchCriteriaOptions.map((option) => {
                      return (
                        <li className="filter__option" key={Math.random()} onClick={() => setSearchCriteria(option.ranking)}>
                          {option.option}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="search_bar" data-test-id="impressions_search_bar_div">
                <div className="input_arrow">
                  <input
                    className={"filter_input"}
                    value={filter}
                    onChange={handleInputChange}
                    placeholder="Search..."
                    data-test-id="impressions_search_bar_input"
                  />
                  {!filter ? (
                    <span className="search_bar_icon search" />
                  ) : (
                    <span
                      className="search_bar_icon cancel"
                      onClick={() => {
                        setFilter("");
                        dispatch(setSpecificParameter("impressions_search_term", ""));
                        dispatch(setSpecificParameter("impressions_search_type", ""));
                      }}
                    />
                  )}
                </div>
              </div>
              {filter && (
                <div className="chart_apply_btn">
                  <div className="btn" onClick={handleApplyToTrendline}>
                    Apply to trendline
                  </div>
                </div>
              )}
            </div>
          </>
          {tableData.length === 0 && impressions_search_term !== "" ? (
            <p>There are no records matching this search criteria. Kindly update the filter selection.</p>
          ) : (
            <SeoDefaultTable
              data={tableData}
              columns={
                !impressionsChecked
                  ? getClicksColumns({ showPercentageShare, popLabel, handleImpressionsClick, showImpressions: false })
                  : getImpressionsColumns({ handleImpressionsClick, popLabel, showPercentageShare, clicksChecked })
              }
              sortBy={tableSortBy}
              showHidden={showImpressionsHidden}
              selected={impressionsQuery}
              renderRowSubComponent={renderRowSubComponent}
              sortByValue={sortTableBy}
              showChildRows={true}
              canSort={!impressionsDisabled}
              impressionsChecked={impressionsChecked}
              filters={searchCriteria}
              filter={filter}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ImpressionsBreakdownTable;
