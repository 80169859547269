import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Controller, FieldErrors, FieldValues, UseControllerProps, UseFormSetValue } from "react-hook-form";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { Market, Region } from "../../../constants/interface/admin/common";
import { FormFields } from "../Dialogs/AnnotationDialog";

interface Props<T> extends UseControllerProps<any> {
  regions: Region[];
  availableMarkets: Market[];
  filteredRegionsList: string[];
  error: FieldErrors | undefined;
  setValue: UseFormSetValue<FormFields>;
}

const RegionsField = <T extends FieldValues>({
  name,
  control,
  error,
  regions,
  availableMarkets,
  filteredRegionsList,
  setValue,
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          multiple
          options={regions.filter((region: Region) => filteredRegionsList.includes(region.region) || region.region == ALL_OPTION_NO_SPACE)}
          disableCloseOnSelect
          getOptionLabel={(option: Region) => option.region}
          getOptionSelected={(option, value) => option.region == value.region}
          value={value as Region[]}
          onChange={(_, data) => {
            data.length == 0
              ? setValue("markets", [])
              : setValue(
                  "markets",
                  availableMarkets.filter((market) => market.market == ALL_OPTION_NO_SPACE)
                );
            data.length > 0 && data[data.length - 1].region == ALL_OPTION_NO_SPACE
              ? onChange([data.find((region) => region.region == ALL_OPTION_NO_SPACE)])
              : onChange(data.filter((region) => region.region != ALL_OPTION_NO_SPACE));
          }}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox style={{ marginRight: 8 }} checked={selected} color="primary" />
              {option.region}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Regions"
              placeholder="Regions"
              error={Boolean(error?.message)}
              helperText={error?.message}
            />
          )}
        />
      )}
      rules={{
        required: {
          value: true,
          message: "Please select a Region",
        },
      }}
    />
  );
};

export default RegionsField;
