import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import * as Sentry from "@sentry/react";
import { format } from "date-fns";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchBaselineChartAnnotations, fetchVisitsChartAnnotations } from "../../../actions";
import { fetchDataV2, postDataV2, updateDataV2 } from "../../../api/utils";
import { ALL_OPTION, ALL_OPTION_NO_SPACE, DASHBOARD_URLS } from "../../../constants";
import { ANNOTATION_DASHBOARD_FIELDS } from "../../../constants/annotationFields";
import {
  Brand,
  Channel,
  ChannelBreakdown,
  LeadSource,
  Market,
  Metric,
  MetricType,
  Model,
  Region,
  Tag,
} from "../../../constants/interface/admin/common";
import { Annotation } from "../../../pages/Admin/types";
import {
  convertParamsToQueryParams,
  convertToMetricType,
  getAvailableAnnotationMarkets,
  getAvailableAnnotationModels,
} from "../../../utils/annotationFunctions";
import { getVisitsAnnotationApiParams } from "../../../utils/utilityFunctions";
import { ErrorMsg } from "../../AppMessages";
import { getGeographyList } from "../../Filters/subs/filterFunctions";
import StatusField from "../Fields/StatusField";
import StatusNoteField from "../StatusNoteField";
import AnnotationParamsDialog from "./AnnotationParamsDialog";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  startDate?: Date | null;
  endDate?: Date | null;
  annotationData?: Annotation;
  setAnnotations?: Dispatch<SetStateAction<Array<Annotation>>>;
  defaultMetric?: string;
  defaultRegion?: string;
  defaultMarket?: string;
  defaultModel?: string;
  defaultLeadSource?: string;
  defaultMetricType?: string;
  dashboard: string;
}

export interface FormFields {
  brands: Brand[];
  regions: Region[];
  markets: Market[];
  models: Model[];
  channels: Channel[];
  channel_breakdowns: ChannelBreakdown[];
  start_date: string;
  end_date: string;
  insight: string;
  apply_to_filters_only: boolean;
  apply_to_aggregated_filters: boolean;
  on_ga: boolean;
  metrics: Metric[];
  tags: Tag[];
  metric_types: MetricType[];
  approval_status: string;
  lead_sources: LeadSource[];
  approval_status_note: string;
}

export const AnnotationDialog = (props: Props) => {
  const dispatch = useDispatch();
  const {
    open,
    setOpen,
    startDate,
    endDate,
    annotationData,
    setAnnotations,
    defaultMetric,
    defaultLeadSource,
    defaultRegion,
    defaultMarket,
    defaultModel,
    defaultMetricType,
    dashboard,
  } = props;
  const filters = useSelector((state: RootStateOrAny) => state.filters);
  const { data: OCEfiltersData, purchase_funnel_filters: PFfiltersData, mtm_markets: MTMfiltersData } = filters;
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const brands = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].brands) ?? [];
  const regions = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].regions) ?? [];
  const markets = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].markets) ?? [];
  const models = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].models) ?? [];
  const channels = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].channels) ?? [];
  const channelBreakdowns = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].channelBreakdowns) ?? [];
  const metrics = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].metrics) ?? [];
  const leadSources = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].lead_sources) ?? [];
  const brandParamValue = useSelector((state: RootStateOrAny) => state.parameters.brand);
  const regionParamValue = useSelector((state: RootStateOrAny) => state.parameters.region);
  const marketParamValue = useSelector((state: RootStateOrAny) =>
    dashboard == "mtm_metrics_that_matter" ? state.mtm_parameters.market : state.parameters.market
  );
  const modelParamValue: string = useSelector((state: RootStateOrAny) =>
    dashboard == "mtm_metrics_that_matter" ? state.mtm_parameters.model : state.parameters.model
  );
  const channelParamValue = useSelector((state: RootStateOrAny) => state.parameters.last_touch_marketing_channel);
  const channelBreakdownParamValue = useSelector((state: RootStateOrAny) => state.parameters.last_touch_marketing_channel_breakdown);
  const leadSourceParamValue = useSelector((state: RootStateOrAny) => state.purchase_funnel_parameters.lead_source);
  const modelsList = useSelector((state: RootStateOrAny) => state.models);
  const mtmModelsList = useSelector((state: RootStateOrAny) => state.mtm_data.models);
  const channelsList = useSelector((state: RootStateOrAny) => state.marketing_channels);
  const kbaTypes = useSelector((state: RootStateOrAny) => state.digital_performance.kba_types);
  const leadTypes = useSelector((state: RootStateOrAny) => state.digital_performance.lead_types);
  const [tags, setTags] = useState<Array<Tag>>([]);
  const [availableMarkets, setAvailableMarkets] = useState<Array<Market>>([]);
  const [filteredRegionsList, setFilteredRegionsList] = useState<Array<string>>([]);
  const [filteredModelsList, setFilteredModelsList] = useState<Array<string>>([]);
  const [filteredChannelsList, setFilteredChannelsList] = useState<Array<string>>([]);
  const [filteredChannelBreakdownsList, setFilteredChannelBreakdownsList] = useState<Array<string>>([]);
  const [filteredLeadSources, setFilteredLeadSources] = useState<Array<string>>([]);

  const data = useSelector((state: RootStateOrAny) => state.digital_performance.visits_trend.Current ?? []);

  const marketList: Array<string> = useMemo(() => {
    if (data) {
      return data.map((item: Record<string, string | Array<any>>) => {
        const [first] = Object.keys(item);
        return item[first];
      });
    } else {
      return [];
    }
  }, [data]);

  const [paramsSelectionDialogOpen, setParamsSelectionDialogOpen] = useState<boolean>(false);

  const fields: string[] = ANNOTATION_DASHBOARD_FIELDS[dashboard];

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm<FormFields>({
    defaultValues: {
      brands: annotationData?.brands ? annotationData?.brands : brands.filter((brand: Brand) => brand.brand == brandParamValue),
      regions: annotationData?.regions
        ? annotationData?.regions
        : defaultRegion
        ? regions.filter((region: Region) => region.region == defaultRegion)
        : regionParamValue == ALL_OPTION
        ? [{ region: ALL_OPTION_NO_SPACE }]
        : regions.filter(
            (region: Region) =>
              regionParamValue.split(",").includes(region.region) ||
              ((regionParamValue.includes("AMIO") || regionParamValue.includes("NAE")) && region.region == "AMIEO")
          ),
      markets: annotationData?.markets
        ? annotationData?.markets
        : defaultMarket
        ? markets.filter((market: Market) => market.market == defaultMarket)
        : marketParamValue == "All countries"
        ? [{ market: ALL_OPTION_NO_SPACE }]
        : markets.filter((market: Market) => marketParamValue.split(",").includes(market.market)),
      models: annotationData?.models
        ? annotationData?.models
        : defaultModel
        ? models.filter((model: Model) => model.model == defaultModel)
        : modelParamValue == ALL_OPTION_NO_SPACE
        ? models.filter((model: Model) => model.model == ALL_OPTION_NO_SPACE)
        : models.filter((model: Model) => modelParamValue.toLowerCase().split(",").includes(model.model.toLowerCase())),
      channels: annotationData?.channels
        ? annotationData?.channels
        : channelParamValue == ALL_OPTION
        ? channels.filter((channel: Channel) => channel.channel == ALL_OPTION_NO_SPACE)
        : channels.filter((channel: Channel) => channelParamValue.split(",").includes(channel.channel)),
      channel_breakdowns: annotationData?.channel_breakdowns
        ? annotationData?.channel_breakdowns
        : channelBreakdownParamValue == ALL_OPTION
        ? channelBreakdowns.filter((channelBreakdown: ChannelBreakdown) => channelBreakdown.channel_breakdown == ALL_OPTION_NO_SPACE)
        : channelBreakdowns.filter((channel: ChannelBreakdown) =>
            channelBreakdownParamValue.split(",").includes(channel.channel_breakdown)
          ),
      start_date: "",
      end_date: "",
      insight: "",
      apply_to_filters_only: false,
      apply_to_aggregated_filters: false,
      metrics: annotationData?.metrics
        ? annotationData?.metrics
        : defaultMetric
        ? metrics.filter((metric: Metric) => metric.metric == defaultMetric)
        : [],
      tags: annotationData?.tags ? annotationData?.tags : [],
      metric_types: annotationData?.metric_types
        ? annotationData?.metric_types
        : defaultMetricType
        ? [...convertToMetricType(kbaTypes), ...convertToMetricType(leadTypes)].filter(
            (metricType: MetricType) => metricType.metric_type == defaultMetricType
          )
        : [],
      approval_status: annotationData?.approval_status,
      lead_sources: annotationData?.lead_sources
        ? annotationData?.lead_sources
        : defaultLeadSource
        ? leadSources.filter((leadSource: LeadSource) => leadSource.lead_source == defaultLeadSource)
        : leadSourceParamValue == ALL_OPTION
        ? leadSources.filter((leadSource: LeadSource) => leadSource.lead_source == ALL_OPTION_NO_SPACE)
        : leadSources.filter((leadSource: LeadSource) => leadSourceParamValue.split(",").includes(leadSource.lead_source)),
      approval_status_note: annotationData?.approval_status_note,
    },
    criteriaMode: "all",
    mode: "onChange",
  });

  const selectedBrands = useWatch({ control: control, name: "brands" });
  const selectedRegions = useWatch({ control: control, name: "regions" });
  const selectedMarkets = useWatch({ control: control, name: "markets" });

  const selectedChannels = useWatch({ control: control, name: "channels" });

  const classes = makeStyles((theme) => ({
    bold: {
      fontWeight: 450,
    },
    subtitleText: {
      color: "grey",
      fontStyle: "italic",
    },
    textArea: {
      minWidth: "100%",
      borderRadius: "10px",
      padding: "1em",
    },
    btnPublish: {
      backgroundColor: "#00a0dd",
      color: "#FFF!important",
      padding: "0 30px!important",
    },
    btnCancel: {
      backgroundColor: "#ED2939",
      color: "#FFF!important",
      padding: "0 30px!important",
      borderColor: "#ED2939!important",
      "&:hover": {
        backgroundColor: "#ED2939!important",
        borderColor: "#ED2939!important",
      },
    },
    formControl: {
      fontSize: ".9em",
    },
    chip: {
      backgroundColor: "#2C394E",
      color: "#FFF",
      marginRight: "10px",
      borderRadius: "5px",
      marginBottom: "8px",
    },
    selectFieldChipsContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    selectFieldChip: {
      margin: 2,
    },
    paramSelectionFormControl: {
      marginBottom: "10px",
    },
    fieldTitle: {
      fontWeight: 450,
      marginBottom: "3px",
    },
    dateRangePicker: {
      zIndex: 10000,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      padding: "15px",
      [theme.breakpoints.up("md")]: {
        maxWidth: "500px",
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: "700px",
      },
    },
    errorBorder: {
      borderColor: "#FF0000",
    },
    dateRangeContainer: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "inherit",
      },
    },
    dateRangeDelimiter: {
      marginLeft: 20,
      marginRight: 20,
      [theme.breakpoints.down("sm")]: {
        alignSelf: "center",
      },
    },
  }))();

  const handleClose = useCallback(() => {
    setOpen(!open);
    reset();
  }, []);

  const toogleParamSelection = () => {
    setParamsSelectionDialogOpen(!paramsSelectionDialogOpen);
  };

  const fetchTags = () => {
    fetchDataV2("/annotation/tag/get-all-tags")
      .then((res) => setTags(res))
      .catch((err) => console.log(err));
  };

  // filter geographies based on brands selected
  useEffect(() => {
    const brandsList = selectedBrands.flatMap((brand: Brand) => brand.brand);
    const geographyList = getGeographyList({ filters: OCEfiltersData, brand: brandsList });
    setFilteredRegionsList(geographyList.flatMap((geo: any) => geo.region));
  }, [selectedBrands, OCEfiltersData]);

  const handlePublishAnnotation = (values: any) => {
    const requestObj = {
      start_date: values.start_date,
      end_date: values.end_date,
      insight: values.insight,
      dashboard: dashboard,
      metrics: values.metrics,
      applies_to: values.apply_to_filters_only ? "filters_only" : values.apply_to_aggregated_filters ? "filters_and_aggregated_views" : "",
      brands: values.brands,
      regions: dashboard == "online_ce_performance" ? values.regions : [],
      markets: values.markets,
      models: values.models,
      channels: values.channels,
      channel_breakdowns: values.channel_breakdowns,
      tags: values.tags,
      metric_types: values.metric_types,
      approval_status: values.approval_status,
      lead_sources: values.lead_sources,
      approval_status_note: values.approval_status_note,
    };
    if (annotationData) {
      const params = convertParamsToQueryParams(requestObj);
      const annotationUrl = `${window.location.origin}${DASHBOARD_URLS[requestObj.dashboard]}?${new URLSearchParams(params).toString()}`;
      updateDataV2(
        `/annotation/update-annotation?id=${annotationData.id}&annotation_url=${encodeURIComponent(annotationUrl)}`,
        requestObj
      ).then((res) => {
        if (!res.error && setAnnotations) {
          setAnnotations((prevState) =>
            prevState.map((annotation) => {
              if (annotation.id !== res.id) {
                return annotation;
              }
              return res;
            })
          );
          handleClose();
        } else {
          console.log(res.error);
        }
      });
    } else {
      postDataV2("/annotation/create", requestObj).then(() => {
        dispatch(fetchBaselineChartAnnotations(parameters, dashboard));
        dispatch(fetchVisitsChartAnnotations(getVisitsAnnotationApiParams(parameters, marketList), dashboard));
        toast.success("The annotation will appear on the chart once a member of the CEDAR team has approved it", {
          autoClose: 5000,
          pauseOnHover: true,
        });
        handleClose();
      });
    }
  };

  useEffect(() => {
    setValue("start_date", startDate != null && startDate != undefined ? format(startDate, "yyyy-MM-dd") : "");
    setValue("end_date", endDate != null && endDate != undefined ? format(endDate, "yyyy-MM-dd") : "");
  }, [startDate, endDate]);

  // get available markets based on dashboard, brand and region.
  useEffect(() => {
    const brandsList = selectedBrands.flatMap((brand: Brand) => brand.brand);
    const filteredRegions = selectedRegions.map((region: Region) => region.region);
    let filteredMarkets: Market[] = [];
    if (dashboard == "online_ce_performance") {
      filteredMarkets = getAvailableAnnotationMarkets(OCEfiltersData, brandsList, markets, dashboard, filteredRegions);
    } else if (dashboard == "purchase_funnel") {
      filteredMarkets = getAvailableAnnotationMarkets(PFfiltersData, brandsList, markets, dashboard);
    } else if (dashboard == "mtm_metrics_that_matter") {
      filteredMarkets = getAvailableAnnotationMarkets(MTMfiltersData, brandsList, markets, dashboard);
    }
    setAvailableMarkets(filteredMarkets);
  }, [selectedRegions, selectedBrands]);

  // get available models based on brand, region and market
  useEffect(() => {
    if (fields.includes("models") && fields.includes("regions") && dashboard == "online_ce_performance") {
      const modelsByBrand = modelsList.data[selectedBrands[0].brand];
      const regionList = selectedRegions.flatMap((region: Region) => region.region);
      const marketList = selectedMarkets.flatMap((market: Market) => market.market);
      const filteredModels = getAvailableAnnotationModels(regionList, marketList, modelsByBrand);
      setFilteredModelsList(["All", ...filteredModels]);
    } else if (dashboard == "mtm_metrics_that_matter") {
      setFilteredModelsList(["Nissan", ...mtmModelsList]);
    }
  }, [selectedMarkets, modelsList, mtmModelsList]);

  // get available channels and channel breakdowns based on brand and market
  useEffect(() => {
    let availableChannels = [];
    let availableChannelBreakdowns = [];
    if (selectedBrands.length > 0) {
      availableChannels = channelsList.data
        .filter((channel: { brand: string }) => channel.brand == selectedBrands[0].brand)
        .map((channel: { last_touch_marketing_channel: string }) => channel.last_touch_marketing_channel);
    }
    if (selectedBrands.length > 0 && selectedMarkets.length == 1 && selectedChannels.length > 0) {
      availableChannelBreakdowns = channelsList.data
        .filter(
          (channel: { brand: string; last_touch_marketing_channel: string; market: string }) =>
            channel.brand == selectedBrands[0].brand &&
            channel.market == selectedMarkets[0].market &&
            channel.last_touch_marketing_channel == selectedChannels[0].channel
        )
        .map((channel: { channel_type: string }) => channel.channel_type);
    }
    setFilteredChannelsList(Array.from(new Set(availableChannels)));
    setFilteredChannelBreakdownsList(availableChannelBreakdowns);
  }, [selectedBrands, selectedMarkets, selectedChannels]);

  //get available lead sources based on brand and market
  useEffect(() => {
    if (fields.includes("lead_sources")) {
      const marketsList = selectedMarkets.map((market) => market.market);
      const brandsList = selectedBrands.map((brand) => brand.brand);
      const brandSourcesByMarkets = PFfiltersData.filter((brandSource: { market: string }) =>
        marketsList.includes(brandSource.market)
      ).flatMap((brandSource: any) => brandSource.brand_source);
      const brandSourcesByBrands = brandSourcesByMarkets.filter((brandSource: { brand: string }) => brandsList.includes(brandSource.brand));
      const leadSourcesList: Set<string> = new Set(
        brandSourcesByBrands.flatMap((brandSource: { leads_source: string[] }) => brandSource.leads_source)
      );
      setFilteredLeadSources(Array.from(leadSourcesList));
    }
  }, [PFfiltersData, selectedBrands, selectedMarkets]);

  useEffect(() => {
    annotationData?.brands && setValue("brands", annotationData?.brands);
    annotationData?.start_date && setValue("start_date", annotationData?.start_date);
    annotationData?.end_date && setValue("end_date", annotationData?.end_date);
    annotationData?.insight && setValue("insight", annotationData?.insight);
    annotationData?.applies_to == "filters_only" && setValue("apply_to_filters_only", true);
    annotationData?.applies_to == "filters_and_aggregated_views" && setValue("apply_to_aggregated_filters", true);
  }, [annotationData, setValue]);

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
      {/* insights dialog */}
      <Dialog open={open} className={classes.modal} disableEnforceFocus>
        <Paper className={classes.paper}>
          <Typography className={classes.bold}>Your insight refers to:</Typography>
          <Typography className={classes.subtitleText}>Please choose the most granular parameters your insight applies to</Typography>
          <Box mt={1} display="flex" flexWrap="wrap">
            {fields.includes("brands") &&
              selectedBrands.map((brand: Brand, index) => (
                <Chip key={index} className={classes.chip} label={brand.brand} onClick={toogleParamSelection} />
              ))}

            {fields.includes("regions") &&
              selectedRegions.map((region: Region, index) => (
                <Chip
                  key={index}
                  className={classes.chip}
                  label={region.region == ALL_OPTION_NO_SPACE ? "All Regions" : region.region}
                  onClick={toogleParamSelection}
                />
              ))}

            {fields.includes("markets") &&
              selectedMarkets.map((market: Market, index) => (
                <Chip
                  key={index}
                  className={classes.chip}
                  label={market.market == ALL_OPTION_NO_SPACE ? "All Markets" : market.market}
                  onClick={toogleParamSelection}
                />
              ))}

            {fields.includes("models") &&
              watch("models").map((model: Model, index) => (
                <Chip
                  key={index}
                  className={classes.chip}
                  label={model.model == ALL_OPTION_NO_SPACE ? "All Models" : model.model}
                  onClick={toogleParamSelection}
                />
              ))}

            {fields.includes("channels") &&
              watch("channels").map((channel: Channel, index) => (
                <Chip
                  key={index}
                  className={classes.chip}
                  label={channel.channel == ALL_OPTION_NO_SPACE ? "All Channels" : channel.channel}
                  onClick={toogleParamSelection}
                />
              ))}

            {fields.includes("channel_breakdowns") &&
              watch("channel_breakdowns").map((channelBreakdown: ChannelBreakdown, index) => (
                <Chip
                  key={index}
                  className={classes.chip}
                  label={
                    channelBreakdown.channel_breakdown == ALL_OPTION_NO_SPACE
                      ? "All Channel Breakdowns"
                      : channelBreakdown.channel_breakdown
                  }
                  onClick={toogleParamSelection}
                />
              ))}

            {fields.includes("metrics") &&
              watch("metrics").map((metric: Metric, index) => (
                <Chip key={index} className={classes.chip} label={metric.metric} onClick={toogleParamSelection} />
              ))}

            {fields.includes("metric_types") &&
              watch("metric_types").map((metricType: MetricType, index) => (
                <Chip key={index} className={classes.chip} label={metricType.metric_type} onClick={toogleParamSelection} />
              ))}

            {fields.includes("lead_sources") &&
              watch("lead_sources").map((leadSource: LeadSource, index) => (
                <Chip
                  key={index}
                  className={classes.chip}
                  label={leadSource.lead_source == ALL_OPTION_NO_SPACE ? "All Lead Sources" : leadSource.lead_source}
                  onClick={toogleParamSelection}
                />
              ))}

            <Chip className={classes.chip} label="+" onClick={toogleParamSelection} />
          </Box>

          <Box my={2}>
            <Typography className={classes.fieldTitle} style={{ marginBottom: "3px" }}>
              Date:
            </Typography>
            <Box className={classes.dateRangeContainer}>
              <FormControl>
                <Controller
                  name="start_date"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      value={value}
                      type="date"
                      label="Date From"
                      size="small"
                      onChange={onChange}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(errors?.start_date?.message)}
                      helperText={errors?.start_date?.message}
                    />
                  )}
                />
              </FormControl>

              <Typography className={classes.dateRangeDelimiter}>to</Typography>

              <FormControl>
                <Controller
                  name="end_date"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    validate: (value) => {
                      const startDate = watch("start_date");
                      if (value != null && startDate != null && value < startDate) {
                        return "End date cannot be before start date";
                      }
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      value={value}
                      type="date"
                      label="Date To"
                      size="small"
                      onChange={onChange}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(errors?.end_date?.message)}
                      helperText={errors?.end_date?.message}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </Box>

          <Box>
            <FormControl fullWidth>
              <Typography className={classes.fieldTitle}>Insight:</Typography>
              <Controller
                name="insight"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter your insight",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextareaAutosize
                    value={value}
                    onChange={onChange}
                    className={`${classes.textArea} ${errors?.insight ? classes.errorBorder : ""}`}
                    aria-label="insight"
                    minRows={4}
                    placeholder="Type your insight here"
                  />
                )}
              />
              <FormHelperText error>{errors?.insight?.message}</FormHelperText>
            </FormControl>
            <FormControl fullWidth>
              <Controller
                name="apply_to_filters_only"
                control={control}
                rules={{
                  required: watch("apply_to_aggregated_filters") == false,
                }}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    className={classes.formControl}
                    control={
                      <Checkbox
                        color="primary"
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          e.target.checked && setValue("apply_to_aggregated_filters", false);
                        }}
                        data-test-id="apply_to_aggregated_filters_checkbox"
                      />
                    }
                    label="Apply to the filters and metric(s) selected only"
                  />
                )}
              />

              <Controller
                name="apply_to_aggregated_filters"
                control={control}
                rules={{
                  required: watch("apply_to_filters_only") == false,
                }}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    className={classes.formControl}
                    control={
                      <Checkbox
                        color="primary"
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          e.target.checked && setValue("apply_to_filters_only", false);
                        }}
                        data-test-id="apply_to_filters_only_checkbox"
                      />
                    }
                    label="Apply to the filters and metric(s) selected and all aggregated views that contain them"
                  />
                )}
              />

              <FormHelperText error>
                {errors?.apply_to_filters_only && errors?.apply_to_aggregated_filters && "Please select the annotation logic"}
              </FormHelperText>
            </FormControl>
            {annotationData && (
              <>
                <FormControl fullWidth variant="outlined">
                  <StatusField name="approval_status" control={control} setValue={setValue} />
                </FormControl>
                <Box pt={3}>
                  <FormControl fullWidth variant="outlined">
                    <StatusNoteField name="approval_status_note" control={control} setValue={setValue} />
                  </FormControl>
                </Box>
              </>
            )}
          </Box>

          <Box display="flex" flexDirection="row" justifyContent="center">
            <Box m={2}>
              <Button
                className={classes.btnCancel}
                variant="outlined"
                size="small"
                onClick={handleClose}
                data-test-id="annotation_close_btn"
              >
                Cancel
              </Button>
            </Box>
            <Box m={2}>
              <Button
                className={classes.btnPublish}
                variant="outlined"
                size="small"
                onClick={handleSubmit(handlePublishAnnotation)}
                data-test-id={`annotation_${annotationData ? "update" : "publish"}_btn`}
              >
                {annotationData ? "Update" : "Publish"}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Dialog>
      {/* params selection dialog */}
      <AnnotationParamsDialog
        paramsSelectionDialogOpen={paramsSelectionDialogOpen}
        setParamsSelectionDialogOpen={setParamsSelectionDialogOpen}
        tags={tags}
        setTags={setTags}
        filteredRegionsList={filteredRegionsList}
        filteredModelsList={filteredModelsList}
        filteredChannelsList={filteredChannelsList}
        filteredChannelBreakdownsList={filteredChannelBreakdownsList}
        filteredLeadSources={filteredLeadSources}
        availableMarkets={availableMarkets}
        fields={fields}
        setValue={setValue}
        control={control}
        errors={errors}
        dashboard={dashboard}
      />
    </Sentry.ErrorBoundary>
  );
};
