import React, { Fragment, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { MultiSelectFilter } from ".";
import { setSpecificParameter } from "../../../actions";
import { FilterContext } from "../../../context";
import { closeFilterOptions, closeMarketOptions } from "../subs/helpers";

interface Props {
  filterLabel?: string;
  filterValue: string;
  optionsList: string[];
  parameterValue: string;
  groupsList?: string[];
  parameterName: string;
  hideAllOption?: boolean;
  handleFilterSubmission: () => void;
}

export const InAppMultiSelectFilter = (props: Props) => {
  const dispatch = useDispatch();

  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const { filterValue, optionsList, groupsList, parameterValue, parameterName, filterLabel, hideAllOption, handleFilterSubmission } = props;

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;

    const filterName = dataset?.filter;
    const optionValue = dataset?.value;
    closeFilterOptions();
    if (filterName && optionValue) {
      dispatch(setSpecificParameter(parameterName, optionValue));
    }
  }, []);

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  return (
    <div className={"filter"} id="market_filter_div">
      {filterLabel ? (
        <label className="filter_header" htmlFor={`${parameterName}_filter`}>
          {filterLabel}
        </label>
      ) : null}
      <div className="input_arrow" onClick={showFilterOptionsFn} data-options-ul={`${parameterName}_ul`}>
        <input
          readOnly
          type="text"
          name={parameterName}
          id={`${parameterName}_filter`}
          className={"filter_input"}
          data-test-id={`${parameterName}`}
          value={filterValue}
        />
        <span className="arrow_down " />
      </div>
      <ul className="filter_options" id={`${parameterName}_ul`} data-test-id={`${parameterName}_list`}>
        {optionsList?.length > 0 && (
          <Fragment>
            {groupsList &&
              groupsList?.map((marketGroup) => {
                return (
                  <li
                    className={`${parameterName}_group`}
                    data-filter={`${parameterName}_group`}
                    data-value={marketGroup}
                    onClick={handleFilterOptionClick}
                    key={marketGroup}
                  >
                    {marketGroup}
                  </li>
                );
              })}
            <li className={"filter_divider market_group_divider"} />
            <MultiSelectFilter
              parentKey={parameterName}
              showOptionsOnly={true}
              // @ts-ignore
              filterList={optionsList}
              value={filterValue}
              parameterName={`${parameterName}`}
              parameterValue={parameterValue}
              onShowFilterOptions={showFilterOptionsFn}
              handleFilterSubmission={handleFilterSubmission}
              hideAllOption={hideAllOption}
            />
          </Fragment>
        )}
      </ul>
    </div>
  );
};
