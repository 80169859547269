import {
  SET_BASELINE_CHART_ANNOTATIONS_DATA,
  SET_BASELINE_DATA,
  SET_FULL_FIGURES,
  SET_FULL_FIGURES_DAILY,
  SET_FULL_FIGURES_MONTHLY,
  SET_FULL_FIGURES_WEEKLY,
  SET_KBA_LABEL_MAPPING,
  SET_KBA_TREND_BREAKDOWN,
  SET_LEAD_LABEL_MAPPING,
  SET_LEAD_TREND_BREAKDOWN,
  SET_NEW_KEY_INDICATOR_TOTALS,
  SET_NEW_TREND_BREAKDOWN,
  SET_OCE_ANNOTATION_KBA_TYPES,
  SET_OCE_ANNOTATION_LEAD_TYPES,
  SET_TRENDLINE,
  SET_TREND_BREAKDOWN,
  SET_TREND_BREAKDOWN_TOTALS,
  SET_VISITS_CHART_ANNOTATIONS_DATA,
  SET_VISITS_KBAS_GRAPH_DATA_DAILY,
  SET_VISITS_KBAS_GRAPH_DATA_MONTHLY,
  SET_VISITS_KBAS_GRAPH_DATA_WEEKLY,
  SET_VISITS_LEADS_GRAPH_DATA_DAILY,
  SET_VISITS_LEADS_GRAPH_DATA_MONTHLY,
  SET_VISITS_LEADS_GRAPH_DATA_WEEKLY,
  SET_VISITS_TREND,
  SET_VISITS_TREND_KBAS,
  SET_VISITS_TREND_LEADS,
  SET_WEBSITE_VISITS,
} from "../actions/actionTypes";
import { baselineData, trendBreakdownData, trendBreakdownTotals, trendlineData, trendNewBreakdownData } from "../constants/interface";
import { MetricType } from "../constants/interface/admin/common";
import { Annotation } from "../pages/Admin/types";

const initialState: {
  full_figures: Record<string, unknown>;
  website_visits: Record<string, unknown>;
  trend_breakdown: { data: trendBreakdownData; totals: trendBreakdownTotals | Record<string, unknown>; kbas: any; leads: any };
  trendline: { data: trendlineData | []; baselineData: baselineData | [] };
  visits_trend: Record<string, unknown>;
  visits_trend_kbas: { all: Record<string, unknown>; graphData: { daily: []; weekly: []; monthly: [] } };
  visits_trend_leads: { all: Record<string, unknown>; graphData: { daily: []; weekly: []; monthly: [] } };
  baseline_chart_annotations: Annotation[];
  visits_chart_annotations: Annotation[];
  kba_types: MetricType[];
  lead_types: MetricType[];
  new_trend_breakdown: { data: trendNewBreakdownData };
  new_key_indicator_totals: { data: trendNewBreakdownData };
  kba_label_mapping: Array<Record<string, string>>;
  lead_label_mapping: Array<Record<string, string>>;
} = {
  full_figures: { daily: [], weekly: [], monthly: [], quarterly: [] },
  website_visits: { Current: [], YoY: [] },
  trend_breakdown: { data: [], totals: {}, kbas: {}, leads: {} },
  trendline: { data: [], baselineData: [] },
  visits_trend: { Current: [], YoY: [], PoP: [] },
  visits_trend_kbas: { all: {}, graphData: { daily: [], weekly: [], monthly: [] } },
  visits_trend_leads: { all: {}, graphData: { daily: [], weekly: [], monthly: [] } },
  baseline_chart_annotations: [],
  visits_chart_annotations: [],
  kba_types: [],
  lead_types: [],
  new_trend_breakdown: { data: [] },
  new_key_indicator_totals: { data: [] },
  kba_label_mapping: [],
  lead_label_mapping: [],
};

const onlineCePerformanceReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_FULL_FIGURES:
      return { ...state, full_figures: action.payload };

    case SET_FULL_FIGURES_DAILY:
      return { ...state, full_figures: { ...state.full_figures, daily: action.payload } };

    case SET_FULL_FIGURES_WEEKLY:
      return { ...state, full_figures: { ...state.full_figures, weekly: action.payload } };

    case SET_FULL_FIGURES_MONTHLY:
      return { ...state, full_figures: { ...state.full_figures, monthly: action.payload } };

    case SET_WEBSITE_VISITS:
      return { ...state, website_visits: action.payload };

    case SET_TREND_BREAKDOWN:
      return { ...state, trend_breakdown: { ...state.trend_breakdown, data: action.payload } };

    case SET_KBA_TREND_BREAKDOWN:
      return { ...state, trend_breakdown: { ...state.trend_breakdown, kbas: action.payload } };

    case SET_LEAD_TREND_BREAKDOWN:
      return { ...state, trend_breakdown: { ...state.trend_breakdown, leads: action.payload } };

    case SET_TREND_BREAKDOWN_TOTALS:
      return { ...state, trend_breakdown: { ...state.trend_breakdown, totals: action.payload } };

    case SET_TRENDLINE:
      return { ...state, trendline: { ...state.trendline, data: action.payload } };

    case SET_BASELINE_DATA:
      return { ...state, trendline: { ...state.trendline, baselineData: action.payload } };

    case SET_VISITS_TREND:
      return { ...state, visits_trend: action.payload };

    case SET_VISITS_TREND_KBAS:
      return { ...state, visits_trend_kbas: { ...state.visits_trend_kbas, all: action.payload } };

    case SET_VISITS_KBAS_GRAPH_DATA_DAILY:
      return {
        ...state,
        visits_trend_kbas: {
          ...state.visits_trend_kbas,
          graphData: { ...state.visits_trend_kbas.graphData, daily: action.payload },
        },
      };

    case SET_VISITS_KBAS_GRAPH_DATA_WEEKLY:
      return {
        ...state,
        visits_trend_kbas: {
          ...state.visits_trend_kbas,
          graphData: { ...state.visits_trend_kbas.graphData, weekly: action.payload },
        },
      };

    case SET_VISITS_KBAS_GRAPH_DATA_MONTHLY:
      return {
        ...state,
        visits_trend_kbas: {
          ...state.visits_trend_kbas,
          graphData: { ...state.visits_trend_kbas.graphData, monthly: action.payload },
        },
      };

    case SET_VISITS_TREND_LEADS:
      return { ...state, visits_trend_leads: { ...state.visits_trend_leads, all: action.payload } };

    case SET_VISITS_LEADS_GRAPH_DATA_DAILY:
      return {
        ...state,
        visits_trend_leads: {
          ...state.visits_trend_leads,
          graphData: { ...state.visits_trend_leads.graphData, daily: action.payload },
        },
      };

    case SET_VISITS_LEADS_GRAPH_DATA_WEEKLY:
      return {
        ...state,
        visits_trend_leads: {
          ...state.visits_trend_leads,
          graphData: { ...state.visits_trend_leads.graphData, weekly: action.payload },
        },
      };

    case SET_VISITS_LEADS_GRAPH_DATA_MONTHLY:
      return {
        ...state,
        visits_trend_leads: {
          ...state.visits_trend_leads,
          graphData: { ...state.visits_trend_leads.graphData, monthly: action.payload },
        },
      };

    case SET_BASELINE_CHART_ANNOTATIONS_DATA:
      return { ...state, baseline_chart_annotations: action.payload };

    case SET_VISITS_CHART_ANNOTATIONS_DATA:
      return { ...state, visits_chart_annotations: action.payload };

    case SET_OCE_ANNOTATION_KBA_TYPES:
      return { ...state, kba_types: action.payload };

    case SET_OCE_ANNOTATION_LEAD_TYPES:
      return { ...state, lead_types: action.payload };

    //refactored endpoints
    case SET_NEW_TREND_BREAKDOWN:
      return { ...state, new_trend_breakdown: { ...state.new_trend_breakdown, data: action.payload } };

    case SET_NEW_KEY_INDICATOR_TOTALS:
      return { ...state, new_key_indicator_totals: { ...state.new_key_indicator_totals, data: action.payload } };

    case SET_KBA_LABEL_MAPPING:
      return { ...state, kba_label_mapping: action.payload };

    case SET_LEAD_LABEL_MAPPING:
      return { ...state, lead_label_mapping: action.payload };

    default:
      return state;
  }
};

export default onlineCePerformanceReducer;
