import { color, Tooltip } from "@amcharts/amcharts4/core";
import { PointedCircle } from "@amcharts/amcharts4/plugins/bullets";
import { format } from "date-fns";
import { BLACK, CYAN, WHITE } from "../../../constants";
import { Annotation } from "../../../pages/Admin/types";

function createAnnotationTooltips(series: any, field?: string, filterBy?: Record<string, string>) {
  const pointer = field ? field : "annotations";
  // @ts-ignore
  series.dataFields.annotations = pointer;

  const bullet = series.bullets.push(new PointedCircle());
  bullet.fill = color("#FFF");
  bullet.tooltip = new Tooltip();
  bullet.tooltip.align = "right";
  bullet.tooltip.pointerOrientation = "left";
  bullet.tooltip.getFillFromObject = false;
  bullet.tooltip.background.fill = color(BLACK);
  bullet.tooltip.getStrokeFromObject = true;
  bullet.tooltip.strokeWidth = 10;
  bullet.tooltipHTML = ``;
  bullet.tooltip.interactionsEnabled = true;
  bullet.tooltip.keepTargetHover = true;
  bullet.tooltip.zIndex = 100;
  bullet.tooltip.ignoreBounds = true;
  bullet.tooltip.events.on("hit", (event: any) => {
    // @ts-ignore
    if (!this.state.shareModalOpen) {
      //@ts-ignore
      const annotations = event.target.dataItem?.annotations;
      // @ts-ignore
      this.handleShareAnnotation(annotations);
    }
  });
  bullet.adapter.add("tooltipHTML", function (html: any, target: any) {
    if (target.tooltipDataItem?.annotations && !target.disabled) {
      let annotations: Annotation[] = [];
      if (filterBy) {
        for (const key in filterBy) {
          annotations.push(
            ...target.tooltipDataItem.annotations.filter((annotation: any) =>
              annotation[`${key}s`].flatMap((item: any) => item[`${key}`]).includes(filterBy[key])
            )
          );
        }
      } else {
        annotations = target.tooltipDataItem.annotations;
      }

      annotations.forEach((annotation: Annotation, index: number) => {
        //get annotation parameters
        const parameters = [
          annotation.regions.map((region) => region.region),
          annotation.markets.map((market) => market.market),
          annotation.models.map((model) => model.model),
          annotation.channels.map((channel) => channel.channel),
          annotation.channel_breakdowns.map((channel_breakdown) => channel_breakdown.channel_breakdown),
        ]
          .flat()
          .filter((param) => param != "All");
        const startDate = new Date(annotation.start_date);
        const endDate = new Date(annotation.end_date);
        index > 0 ? (html += `<hr style="border-color: ${WHITE}; margin-top: 20px;"/>`) : false;
        html += `<div style="margin-top: 5px; margin-bottom: 5px;">
              <p style="font-size: var(--smaller_regular_font_size);">${format(startDate, "d LLL Y")} - ${format(endDate, "d LLL Y")}</p>
              <p style="font-size: var(--smaller_regular_font_size);">${parameters.length > 0 ? parameters.join(", ") : ""}</p>
              <p style="font-size: var(--regular_font_size); margin-top: 10px;">${annotation.insight}</p>
              ${annotation.tags.map(
                (tag) =>
                  `<span style="background-color: ${CYAN}; color: ${WHITE}; padding: 0px 5px; margin-right: 5px; font-size: var(--smaller_regular_font_size);">${tag.tag}</span>`
              )}
            </div>`;
      });
      html += `
          <div style="text-align: center; margin-top: 20px;">
            <hr style="border-color: ${CYAN};"/>
            <p style="color: ${CYAN}; font-size: var(--smaller_regular_font_size); margin: 10px 0px;">Share insights</p>
          </div>
        `;
    }
    return html;
  });
  bullet.radius = 4;
  bullet.dy = -8;

  return bullet;
}

export { createAnnotationTooltips };
