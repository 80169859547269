import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { performancePages } from "../../../../constants";
import { toLocaleInteger } from "../../../../utils/utilityFunctions";
import BrandPerformanceGraph from "../BrandPerformanceGraph";

interface Props {
  onHandleRadioClick: any;
  nissanCompetitor: string;
  activePage: performancePages;
  diff: number;
}

const TooltipParagraphs = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Volume average </span>
      considers the sum of all brand/model sales in the select period dividided by the number of months in the period.
    </p>
    <p>
      <u>Source</u>: Total (Retail & Fleet) Sales Data by PAI
    </p>
    <br />
  </>
);

const TooltipParagraphAvg = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Volume </span>
      represents the total sales of a brand or car model in the selected geography in the select period of time. Competitors corporate and
      model data are shown based on the selected Nissan brand/model.
    </p>
    <p>
      <u>Source</u>: Total (Retail & Fleet) Sales Data by PAI
    </p>
    <br />
  </>
);

const BrandVolume = (props: Props) => {
  const { onHandleRadioClick, nissanCompetitor, activePage, diff } = props;
  const model = useSelector((state: RootStateOrAny) => state.parameters.model);

  const chartData = useSelector((state: RootStateOrAny) => state.brand_performance.brand_volume_trend);
  const { data: tableData, date_range: dateRange } = useSelector((state: RootStateOrAny) => state.brand_performance.brand_volume_average);
  const loadingTable = useSelector((state: RootStateOrAny) => state.loading.brand_volume_average);
  const loadingTrend = useSelector((state: RootStateOrAny) => state.loading.brand_volume_trend);

  const columns = [
    {
      id: "model",
      Header: `${model == "CORPORATE" ? "Brand" : "Model"} `,
      accessor: "model",
      sortType: "alphanumericIgnoreCase",
      Cell: (props1: { value: string }) => {
        const { value } = props1;

        return value;
      },
    },
    {
      Header: "Volume",
      accessor: `volume`,
      id: "volume",
      sortType: "alphanumericCustom",

      Cell: (props: { cell: { row: { original: any } } }) => {
        const {
          cell: {
            row: { original },
          },
        } = props;
        const value = original?.["volume"];
        return value ? toLocaleInteger(value) : "n/a";
      },
    },
  ];

  const dateTitle = useMemo(() => {
    if (dateRange && dateRange.length > 1) {
      return `(${dateRange[0]} - ${dateRange.slice(-1)})`;
    } else if (dateRange && dateRange.length == 1) {
      return `(${dateRange[0]})`;
    }

    return "";
  }, [dateRange]);

  return (
    <div className=" brand_volume performance_page">
      <BrandPerformanceGraph
        chartData={chartData}
        tableData={tableData}
        columns={columns}
        onHandleRadioClick={onHandleRadioClick}
        nissanCompetitor={nissanCompetitor}
        tableId="brand_volume_table"
        trendChartId="brand_volume_chart"
        TooltipParagraphs={TooltipParagraphs}
        trendTitle="Brand Volume Trend"
        tableTitle="Brand Volume Average"
        loadingTrend={loadingTrend}
        loadingTable={loadingTable}
        TooltipParagraphAvg={TooltipParagraphAvg}
        initialSortCol={{ id: "volume", desc: true }}
        diff={diff}
        dateTitle={dateTitle}
        activePage={activePage}
      />
    </div>
  );
};

export default BrandVolume;
