import { all, put, retry, takeLatest } from "redux-saga/effects";
import {
  setBrandImageAttributesAverage,
  setBrandImageAttributesTrend,
  setBrandOaOAverage,
  setBrandOaOTrend,
  setBrandVolumeAverage,
  setBrandVolumeTrend,
} from "../actions";
import {
  FETCH_BRAND_IMAGE_ATTRIBUTES_AVERAGE,
  FETCH_BRAND_IMAGE_ATTRIBUTES_TREND,
  FETCH_BRAND_OAO_AVERAGE,
  FETCH_BRAND_OAO_TREND,
  FETCH_BRAND_VOLUME_AVERAGE,
  FETCH_BRAND_VOLUME_TREND,
} from "../actions/actionTypes";
import { setLoading } from "../actions/loadingActions";
import {
  getBrandImageAttributesAverage,
  getBrandImageAttributesTrend,
  getBrandOaOAverage,
  getBrandOaOTrend,
  getBrandVolumeAverage,
  getBrandVolumeTrend,
} from "../api/brandPerformanceApi";
import { secondsDelay } from "../api/utils";

export function* watchBrandVolumeTrendSaga() {
  yield takeLatest(FETCH_BRAND_VOLUME_TREND, fetchBrandVolumeTrendSaga);
}

export function* watchBrandVolumeAverageSaga() {
  yield takeLatest(FETCH_BRAND_VOLUME_AVERAGE, fetchBrandVolumeAverageSaga);
}

export function* watchBrandOaOTrendSaga() {
  yield takeLatest(FETCH_BRAND_OAO_TREND, fetchBrandOaOTrendSaga);
}

export function* watchBrandOaOAverageSaga() {
  yield takeLatest(FETCH_BRAND_OAO_AVERAGE, fetchBrandOaOAverageSaga);
}

export function* watchBrandImageAttributesTrendSaga() {
  yield takeLatest(FETCH_BRAND_IMAGE_ATTRIBUTES_TREND, fetchBrandImageAtrributesTrendSaga);
}

export function* watchBrandImageAttributesAverageSaga() {
  yield takeLatest(FETCH_BRAND_IMAGE_ATTRIBUTES_AVERAGE, fetchBrandImageAtrributesAverageSaga);
}

export function* fetchBrandVolumeTrendSaga() {
  try {
    yield put(setLoading("brand_volume_trend", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getBrandVolumeTrend, searchParams);

    if (!("error" in response)) {
      yield all([put(setBrandVolumeTrend(response)), put(setLoading("brand_volume_trend", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchBrandVolumeAverageSaga() {
  try {
    yield put(setLoading("brand_volume_average", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getBrandVolumeAverage, searchParams);

    if (!("error" in response)) {
      yield all([put(setBrandVolumeAverage(response)), put(setLoading("brand_volume_average", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchBrandOaOTrendSaga() {
  try {
    yield put(setLoading("brand_oao_trend", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getBrandOaOTrend, searchParams);

    if (!("error" in response)) {
      yield all([put(setBrandOaOTrend(response)), put(setLoading("brand_oao_trend", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchBrandOaOAverageSaga() {
  try {
    yield put(setLoading("brand_oao_average", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getBrandOaOAverage, searchParams);

    if (!("error" in response)) {
      yield all([put(setBrandOaOAverage(response)), put(setLoading("brand_oao_average", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchBrandImageAtrributesTrendSaga() {
  try {
    yield put(setLoading("brand_image_attributes_trend", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getBrandImageAttributesTrend, searchParams);

    if (!("error" in response)) {
      yield all([put(setBrandImageAttributesTrend(response)), put(setLoading("brand_image_attributes_trend", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchBrandImageAtrributesAverageSaga() {
  try {
    yield put(setLoading("brand_image_attributes_average", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getBrandImageAttributesAverage, searchParams);

    if (!("error" in response)) {
      yield all([put(setBrandImageAttributesAverage(response)), put(setLoading("brand_image_attributes_average", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}
