import { ErrorBoundary } from "@sentry/react";
import React from "react";
import "../../assets/styles/component/pageContainer.scss";
import { ErrorMsg } from "../AppMessages";
import { PasswordReset } from "../Modals";

interface Props {
  iframeUrl: string;
  frameId: string;
  filter?: JSX.Element;
}

export const IframePageContent = (props: Props): JSX.Element => {
  const { iframeUrl, filter, frameId } = props;

  return (
    <>
      {filter && <ErrorBoundary fallback={<ErrorMsg />}>{filter}</ErrorBoundary>}
      <div id="container">
        {/*Main content*/}
        <div className={"iframeWrapper"}>
          <iframe src={iframeUrl} frameBorder={0} allowFullScreen={true} id={frameId} data-test-id={frameId} />
        </div>
      </div>

      <PasswordReset />
    </>
  );
};
