import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ActivationTile } from ".";
import { ErrorMsg } from "../../../../components/AppMessages";
import { FilterBtns } from "../../../../components/Filters/common";
import { TopUsedServicesTable } from "../../../../components/Tables";
import { ALL_OPTION } from "../../../../constants";
import { ITopUsedServicesRow } from "../../../../constants/interface/ccsCockpit";
import { DashboardBannerContext } from "../../../../context";
import { CockpitContext } from "../../subs/CockpitContext";
import { DisabledTile } from "../tiles";

interface Props {
  showMetric?: boolean;
}

export const OperationalTiles = (props: Props) => {
  const { showMetric } = props;
  const { setMetric } = useContext(CockpitContext);

  const [highLight, setHighLight] = useState(false);

  const topUsedServices: Array<ITopUsedServicesRow> = useSelector((state: RootStateOrAny) => state.ccs_cockpit.top_used_services);
  const { top_used_services: topUsedServicesLoading } = useSelector((state: RootStateOrAny) => state.loading);
  const { region: regionParamValue } = useSelector((state: RootStateOrAny) => state.parameters);

  const [overFlow, setOverFlow] = useState("");

  const { bannerHovered } = useContext(DashboardBannerContext);
  useEffect(() => {
    if (regionParamValue === "Europe" && bannerHovered) {
      setHighLight(true);
    } else {
      setHighLight(false);
    }
  }, [regionParamValue, bannerHovered]);

  useEffect(() => {
    if (regionParamValue === "Europe") {
      setOverFlow("overflow_hidden");
    } else {
      setOverFlow("overflow_auto");
    }
  }, [regionParamValue]);

  return (
    <div id="operational_content" className={`section_content ${showMetric ? "isMetric" : ""}`}>
      {regionParamValue === "Japan" || regionParamValue === "North America" ? (
        <div id="avk_quality" className={`tile ${highLight ? "isBlue" : ""}`}>
          <p className="tile_header">AVK quality data out</p>
          <div className="tile_content">
            <div className={`data_points ${regionParamValue !== "North America" ? "hidden" : ""}`}>
              <FilterBtns id="ccs_data_quality_btn" name="See data quality" navigateTo="/ccs_vital_signs/data_quality" />
            </div>
            <div id="data_out_definition" className={regionParamValue === "North America" ? "raiseTop" : ""}>
              <p className="grey_info">&quot;Data Out&quot; refers to data sent out with expectation to be eligible for monetization.</p>
            </div>
          </div>
        </div>
      ) : regionParamValue == "China" || regionParamValue == ALL_OPTION ? (
        <DisabledTile title={"G8 private activation ratio"} disableMessage={""} onClick={() => setMetric("Operational")} />
      ) : (
        <ErrorBoundary fallback={<ErrorMsg />}>
          <ActivationTile isOperational={true} data-target="operational" onClick={() => setMetric("Operational")} />
        </ErrorBoundary>
      )}
      <div id="top_used_services" className="tile" onClick={() => setMetric("Operational")}>
        <Link className="dashboard-link" to="/ccs_vital_signs/top_flop">
          <ArrowCircleRightOutlinedIcon style={{ color: "#00a0dd" }} />
        </Link>
        <div className="CCS_main_content">
          <div className="tile_content">
            <p className="tile_header">Top/Flop connected services</p>
            <ErrorBoundary fallback={<ErrorMsg />}>
              <TopUsedServicesTable data={topUsedServices} isLoading={topUsedServicesLoading} overflow={overFlow} />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};
