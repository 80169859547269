import { retry, takeLatest } from "@redux-saga/core/effects";
import { put } from "redux-saga/effects";
import {
  setBrandsData,
  setChannelBreakdownsData,
  setChannelsData,
  setLeadSourcesData,
  setMarketsData,
  setMetricsData,
  setNewModelsData,
  setRegionsData,
} from "../actions";
import {
  FETCH_ANNOTATION_BRANDS,
  FETCH_ANNOTATION_CHANNELS,
  FETCH_ANNOTATION_CHANNEL_BREAKDOWNS,
  FETCH_ANNOTATION_LEAD_SOURCES,
  FETCH_ANNOTATION_MARKETS,
  FETCH_ANNOTATION_METRICS,
  FETCH_ANNOTATION_NEW_MODELS,
  FETCH_ANNOTATION_REGIONS,
} from "../actions/actionTypes";
import {
  getBrandsData,
  getChannelBreakdownsData,
  getChannelsData,
  getLeadSourcesData,
  getMarketsData,
  getMetricsData,
  getModelsData,
  getRegionsData,
} from "../api/annotationParametersApi";
import { secondsDelay } from "../api/utils";
import { Brand, Channel, ChannelBreakdown, LeadSource, Market, Metric, Model, Region } from "../constants/interface/admin/common";

// Watcher sagas
export function* watchFetchBrandsData() {
  yield takeLatest(FETCH_ANNOTATION_BRANDS, fetchBrandsData);
}

export function* watchFetchMarketsData() {
  yield takeLatest(FETCH_ANNOTATION_MARKETS, fetchMarketsData);
}

export function* watchFetchRegionsData() {
  yield takeLatest(FETCH_ANNOTATION_REGIONS, fetchRegionsData);
}

export function* watchFetchNewModelsData() {
  yield takeLatest(FETCH_ANNOTATION_NEW_MODELS, fetchNewModelsData);
}

export function* watchFetchChannelsData() {
  yield takeLatest(FETCH_ANNOTATION_CHANNELS, fetchChannelsData);
}

export function* watchFetchChannelBreakdownsData() {
  yield takeLatest(FETCH_ANNOTATION_CHANNEL_BREAKDOWNS, fetchChannelBreakdownsData);
}

export function* watchFetchMetricsData() {
  yield takeLatest(FETCH_ANNOTATION_METRICS, fetchMetricsData);
}

export function* watchFetchLeadSourcesData() {
  yield takeLatest(FETCH_ANNOTATION_LEAD_SOURCES, fetchLeadSourcesData);
}

// Worker sagas
export function* fetchBrandsData({ payload: dashboard }: any) {
  try {
    const response: Brand = yield retry(4, secondsDelay, getBrandsData, dashboard);

    if (!("error" in response)) {
      yield put(setBrandsData(response, dashboard));
    }
  } catch (err) {
    console.log("failed to fetch brands data", err);
  }
}

export function* fetchMarketsData({ payload: dashboard }: any) {
  try {
    const response: Market = yield retry(4, secondsDelay, getMarketsData, dashboard);

    if (!("error" in response)) {
      yield put(setMarketsData(response, dashboard));
    }
  } catch (err) {
    console.log("failed to fetch markets data", err);
  }
}

export function* fetchRegionsData({ payload: dashboard }: any) {
  try {
    const response: Region = yield retry(4, secondsDelay, getRegionsData, dashboard);

    if (!("error" in response)) {
      yield put(setRegionsData(response, dashboard));
    }
  } catch (err) {
    console.log("failed to fetch regions data", err);
  }
}

export function* fetchNewModelsData({ payload: dashboard }: any) {
  try {
    const response: Model = yield retry(4, secondsDelay, getModelsData, dashboard);

    if (!("error" in response)) {
      yield put(setNewModelsData(response, dashboard));
    }
  } catch (err) {
    console.log("failed to fetch models data", err);
  }
}

export function* fetchChannelsData({ payload: dashboard }: any) {
  try {
    const response: Channel = yield retry(4, secondsDelay, getChannelsData, dashboard);

    if (!("error" in response)) {
      yield put(setChannelsData(response, dashboard));
    }
  } catch (err) {
    console.log("failed to fetch channels data", err);
  }
}

export function* fetchChannelBreakdownsData({ payload: dashboard }: any) {
  try {
    const response: ChannelBreakdown = yield retry(4, secondsDelay, getChannelBreakdownsData, dashboard);

    if (!("error" in response)) {
      yield put(setChannelBreakdownsData(response, dashboard));
    }
  } catch (err) {
    console.log("failed to fetch channels breakdowns data", err);
  }
}

export function* fetchMetricsData({ payload: dashboard }: any) {
  try {
    const response: Metric = yield retry(4, secondsDelay, getMetricsData, dashboard);

    if (!("error" in response)) {
      yield put(setMetricsData(response, dashboard));
    }
  } catch (err) {
    console.log("failed to fetch metrics data", err);
  }
}

export function* fetchLeadSourcesData({ payload: dashboard }: any) {
  try {
    const response: LeadSource = yield retry(4, secondsDelay, getLeadSourcesData, dashboard);

    if (!("error" in response)) {
      yield put(setLeadSourcesData(response, dashboard));
    }
  } catch (err) {
    console.log("failed to fetch lead sources data", err);
  }
}
