import { ErrorBoundary } from "@sentry/react";
import jwtDecode from "jwt-decode";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { resetUserPassword } from "../../api/authenitcationApi";
import { postDataNoTokenV2 } from "../../api/utils";
import blueCedarLogo from "../../assets/images/blue_cedar_logo.svg";
import { CYAN } from "../../constants";
import { PasswordResetContext } from "../../context";
import { ErrorMsg } from "../AppMessages";
import { HiddenInput, PasswordInput } from "./common";
import { SubmitBtn } from "./common/Buttons";

const DEFAULT_PASSWORD_GUIDANCE =
  "Passwords must be a minimum of eight characters long and include lower and uppercase characters and at least one number.";

interface Props {
  token: string;
}

export const SetPasswordForm = withRouter((props: Props & RouteComponentProps) => {
  const { token, history } = props;

  const [loading, setLoading] = useState(false);
  const [guidanceString] = useState(DEFAULT_PASSWORD_GUIDANCE);
  const [error, setError] = useState("");
  const [passwordValues, setPasswordValues] = useState({ password: "", confirm_password: "" });

  const { setShowSuccessMessage } = useContext(PasswordResetContext);

  // Check if token is valid
  useEffect(() => {
    postDataNoTokenV2(`/authentication/check-token-validity?token=${token}`, {}).then((response: any) => {
      if (response && "error" in response) {
        setError(response.error);
      }
    });
  }, [token]);

  // Check that passwords match
  useEffect(() => {
    const { password, confirm_password } = passwordValues;
    const bothPasswordsMatch = password === confirm_password;

    if (password) {
      if (!bothPasswordsMatch) setError("Password and confirm password should match");
      else {
        setError("");
      }
    }
  }, [passwordValues]);

  const onChangePassword = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = evt;
    setPasswordValues({ ...passwordValues, [name]: value });
  };

  const handleSubmit = useCallback(
    () => (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);

      const { target } = event;

      const formData = new FormData(target as HTMLFormElement);
      const formObj: Record<string, FormDataEntryValue | string> = Object.fromEntries(formData);

      const { password, confirm_password } = formObj;
      const bothPasswordsMatch = password === confirm_password;

      const reqObj = {
        password: password,
      };

      if (password && confirm_password && bothPasswordsMatch) {
        const hasNumber = new RegExp(/\d.+$/);
        const hasUppercase = new RegExp(/^(?=.*[A-Z]).+$/);
        const hasLowercase = new RegExp(/^(?=.*[a-z]).+$/);

        if ((password as string).length < 8) {
          setError("Password must have atleast 8 characters");
          setLoading(false);
        } else if (!hasNumber.test(password as string)) {
          setError("Password should contain a number");
          setLoading(false);
        } else if (!hasUppercase.test(password as string)) {
          setError("Password should contain an uppercase character");
          setLoading(false);
        } else if (!hasLowercase.test(password as string)) {
          setError("Password should contain a lowercase character");
          setLoading(false);
        } else {
          resetUserPassword(reqObj, token)
            .then((response) => {
              if ("error" in response) {
                toast.error(response.error == "expired_token" ? "Expired token!" : "Invalid token!");
                setError(response.error);
              } else {
                toast.success("Password reset successful");
                const pathname = "/login";
                setTimeout(() => history.push({ pathname }), 2500);
              }
            })
            .finally(() => setLoading(false));
        }
      }
    },
    [setShowSuccessMessage, history, token]
  );

  const handleBackToLoginClick = useCallback(
    () => () => {
      history.push({ pathname: "/login" });
    },
    [history]
  );

  const handleRerirectToForgotPassword = () => {
    const decoded: any = jwtDecode(token);
    history.push({ pathname: "/forgotten_password", state: { email: decoded.user_email } });
  };

  return (
    <ErrorBoundary fallback={<ErrorMsg class={"formError"} />}>
      <div className="form_body" id="set_password_form_body">
        <div className="header_section">
          <img src={blueCedarLogo} alt="Cedar Logo" />
        </div>
        <form
          id="set_password_form"
          className="login_form"
          name="set_password_form"
          method="POST"
          data-test-id="set_password_form"
          onSubmit={handleSubmit()}
        >
          <div className="text_area">
            <h3 className="login_head form_header_text" data-test-id="set_password_form--welcome">
              Please set your password
            </h3>

            {guidanceString && <label className="password_guidance">{guidanceString}</label>}
            {error && (
              <label className="error">
                {error == "expired_token" ? (
                  <p>
                    Oops it looks like your token has expired, but don&apos;t worry you can generate a new one by clicking{" "}
                    <a style={{ color: CYAN, cursor: "pointer" }} onClick={handleRerirectToForgotPassword}>
                      here!
                    </a>
                  </p>
                ) : (
                  error
                )}
              </label>
            )}
            <br />

            <HiddenInput name="user_token" value={token} />

            <PasswordInput label="Enter new password" value={passwordValues.password} onChange={onChangePassword} name="password" />
            <PasswordInput
              label="Confirm new password"
              value={passwordValues.confirm_password}
              onChange={onChangePassword}
              name="confirm_password"
            />

            <SubmitBtn
              id="set_password"
              value="Set my password"
              disabled={!!error || !passwordValues.password || !passwordValues.confirm_password}
              isLoading={loading}
            />

            <p id="login_link" data-test-id="login_link" className="links back_button" onClick={handleBackToLoginClick()}>
              <span /> Back to login
            </p>
          </div>
        </form>

        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick={true}
          draggable={false}
          pauseOnHover={false}
        />
      </div>
    </ErrorBoundary>
  );
});
