import { CategoryAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { CYAN, RED } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import { configureCategoryAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  seriesName: string;
  dataKey: string;
  theme: ThemeInt;
  data: Array<{ title: string; value: number; color: string }>;
  isChurnRate?: boolean;
  region?: string;
}

class ValueLineChart extends Component<Props> {
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.props.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.props.chartId);
  }

  initChart() {
    const { data, chartId, seriesName, dataKey, theme, isChurnRate, region } = this.props;

    this.chart = create(chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.colors.list = [color(CYAN), color(RED)];

    // Creating Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "label";

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + "%";
    });

    // Creating Series
    const series = this.chart.series.push(new LineSeries());
    const bullet = lineSeriesConfiguration(series, {
      name: seriesName,
      valueY: dataKey,
    });
    series.dataFields.categoryX = "label";

    bullet.tooltipText = "{categoryX}: {valueY.formatNumber('#.')}%";

    // Creating Series
    if (!isChurnRate && region === "Japan") {
      const series2 = this.chart.series.push(new LineSeries());
      const bullet2 = lineSeriesConfiguration(series2, {
        name: "bp_renewal_rate",
        valueY: "bp_renewal_rate",
      });
      series2.dataFields.categoryX = "label";

      bullet2.tooltipText = "{categoryX} Bp Target: {valueY.formatNumber('#.')}%";
    }
  }
  render() {
    return <div id={this.props.chartId} className={"metricChart"} />;
  }
}

export default ValueLineChart;
