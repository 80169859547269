import { addLicense } from "@amcharts/amcharts4/core";
import React, { useEffect } from "react";
import "../../assets/styles/main.scss";
import { __chart_license__ } from "../../constants";
import { DashboardMenu } from "../Menu";
import "./Ariya/styles/ariyaLayout.scss";

interface Props {
  children: JSX.Element;
}

export const AriyaLayout = (props: Props): JSX.Element => {
  const { children } = props;

  const chartLicense = __chart_license__ || "";

  // Sets chart settings
  useEffect(() => {
    addLicense(chartLicense);
  }, []);

  return (
    <div className={`ariyaWrapper`}>
      <div className={`dashboard noPageFilter ariya_dashboard`} id="ariyaIframe">
        <DashboardMenu />
        {children}
      </div>
    </div>
  );
};
