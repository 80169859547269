import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getKeyIndicators } from "../../../api";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { PurchaseFunnelKeyIndicator } from "./KeyIndicator";

const PRESET_DATE_PARAMS = ["This month", "Last month", "This quarter", "Last quarter", "This financial year", "Last financial year"];

export const KeyIndicatorTile = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const [currentDateLabel, setCurrentDateLabel] = useState("");
  const [latestMonthLabel, setLatestMonth] = useState("");

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["keyIndicatorsData", history.location.search],
    queryFn: getKeyIndicators,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  //Manipulate data received
  useEffect(() => {
    if (data?.start_date && data?.end_date) {
      //set dates on key indicators tile
      const startDate = moment(data?.start_date, "YYYY-MM-DD").format("MMM YYYY");
      const endDate = moment(data?.end_date, "YYYY-MM-DD").format("MMM YYYY");
      const latestMonth = moment(data?.end_date, "YYYY-MM-DD").format("MMMM YYYY");
      setCurrentDateLabel(`${startDate} - ${endDate}`);
      setLatestMonth(latestMonth);
    }
  }, [data]);

  return (
    <div className="pf_key_indicators pf_tile">
      <div className="header">
        <h3>Key indicators</h3>
        <span>(for the date range selected and the last month in this range) </span>
      </div>
      <div className="pf_key_indicators_section">
        <PurchaseFunnelKeyIndicator
          title="% Digitally influenced sales"
          tooltipText={[
            "% Digitally influenced sales = Digitally influenced sales / Total private sales ",
            "YTD calculations are calculated using only complete months.",
          ]}
          showPercentageComparison={false}
          yoyLabel={currentDateLabel}
          latestMonthLabel={latestMonthLabel}
          yoyValue={data?.Current?.percentage_digital_influenced_sales}
          yoyComparisonValue={data?.YoY?.pts_percentage_digital_influenced_sales}
          latestMonthValue={data?.Current?.last_month_percentage_digital_influenced_sales}
          latestMonthMoMValue={data?.PoP?.last_month_pts_percentage_digital_influenced_sales}
          latestMonthYoYValue={data?.YoY?.last_month_pts_percentage_digital_influenced_sales}
          indicatorId={"percentage_digital_sales"}
          targetVariance={data?.Current?.pts_digital_influenced_sales_target}
          targetVariancelastMonth={data?.Current?.last_month_pts_digital_influenced_sales_target}
          targetPercentage={data?.Current?.digital_influenced_sales_target}
          targetPercentageLastmonth={data?.Current?.last_month_digital_influenced_sales_target}
          digitallyInfluencedSalesValue={data?.Current?.digital_influenced_sales}
          digitallyInfluencedSalesYoY={data?.YoY?.perc_digital_influenced_sales}
          digitallyInfluencedLastMonth={data?.Current?.last_month_digital_influenced_sales}
          digitallyInfluencedLastMonthMoM={data?.PoP?.last_month_perc_digital_influenced_sales}
          digitallyInfluencedLastMonthYoY={data?.YoY?.last_month_perc_digital_influenced_sales}
        />
        <PurchaseFunnelKeyIndicator
          title="Leads"
          yoyLabel={currentDateLabel}
          latestMonthLabel={latestMonthLabel}
          yoyValue={data?.Current?.leads}
          yoyComparisonValue={data?.YoY?.perc_leads}
          latestMonthValue={data?.Current?.last_month_leads}
          latestMonthMoMValue={data?.PoP?.last_month_perc_leads}
          latestMonthYoYValue={data?.YoY?.last_month_perc_leads}
          indicatorId={"digital_sales"}
          isPrivateSales={true}
          privateSalesValue={data?.Current?.private_sales}
          privateSalesYoY={data?.YoY?.perc_private_sales}
          privateSalesLastMonth={data?.Current?.last_month_private_sales}
          privateSalesLastMonthMoM={data?.PoP?.last_month_perc_private_sales}
          privateSalesLastMonthYoY={data?.YoY?.last_month_perc_private_sales}
        />
      </div>
      <LoadingEllipsis isLoading={isFetching} />
    </div>
  );
});
