import { ErrorBoundary } from "@sentry/react";
import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import LoadingEllipsis from "../Loading/LoadingEllipsis";
import { DefaultTable } from "./subs";

interface Props {
  data: Array<any>;
  isLoading: boolean;
}

const JourneyConversionTable = (props: Props) => {
  const { data, isLoading } = props;
  const dateRangeParam = useSelector((state: RootStateOrAny) => state.parameters.date_range);

  const columns = useMemo(() => {
    const cols = [
      {
        id: "name",
        Header: "",
        accessor: "name",
        disableSortBy: true,
      },
      {
        id: "conversion_rate",
        Header: "Conversion rate",
        accessor: "conversion_rate",
        disableSortBy: true,
        Cell: ({ value }: { value: number }) => {
          return <span>{value == null ? "n/a" : `${value}%`}</span>;
        },
      },
      {
        id: "yoy",
        Header: "YoY",
        accessor: "yoy",
        disableSortBy: true,
        Cell: ({ value }: { value: number }) => {
          return <span>{value == null ? "n/a" : `${value}PT`}</span>;
        },
      },
    ];

    if (dateRangeParam.includes("month") || dateRangeParam.includes("quarter")) {
      cols.push({
        id: "pop",
        Header: dateRangeParam.includes("month") ? "MoM" : "QoQ",
        accessor: "pop",
        disableSortBy: true,
        Cell: ({ value }: { value: number }) => {
          return <span>{value == null ? "n/a" : `${value}PT`}</span>;
        },
      });
    }
    return cols;
  }, [data, dateRangeParam]);

  return (
    <ErrorBoundary>
      <div className="journey-conversion-table-tile">
        <div className="title">title</div>
        <div className="journey-conversion-table">
          <DefaultTable
            columns={columns}
            data={data}
            id={"journeyConversionTable"}
            data-test-id={"journey_conversion_table"}
            isChildComponent={false}
            initialSortColId={{ id: "", desc: true }}
            hiddenCols={[]}
            manualSortBy={true}
          />
          <LoadingEllipsis isLoading={isLoading} />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default JourneyConversionTable;
