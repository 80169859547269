export const FETCH_DIGITAL_SPEND = "FETCH_DIGITAL_SPEND";
export const SET_DIGITAL_SPEND = "SET_DIGITAL_SPEND";

export const FETCH_DIGITAL_SPEND_VISITS_TREND = "FETCH_DIGITAL_SPEND_VISITS_TREND";
export const SET_DIGITAL_SPEND_VISITS_TREND = "SET_DIGITAL_SPEND_VISITS_TREND";

export const FETCH_DIGITAL_SPEND_VISITS_DATA = "FETCH_DIGITAL_SPEND_VISITS_DATA";
export const SET_DIGITAL_SPEND_VISITS_DATA = "SET_DIGITAL_SPEND_VISITS_DATA";

export const FETCH_WEBSITE_PERFORMANCE = "FETCH_WEBSITE_PERFORMANCE";
export const SET_WEBSITE_PERFORMANCE = "SET_WEBSITE_PERFORMANCE";

export const FETCH_WEBSITE_PERFORMANCE_TREND = "FETCH_WEBSITE_PERFORMANCE_TREND";
export const SET_WEBSITE_PERFORMANCE_TREND = "SET_WEBSITE_PERFORMANCE_TREND";

export const FETCH_JOURNEY_COST = "FETCH_JOURNEY_COST";
export const SET_JOURNEY_COST = "SET_JOURNEY_COST";

export const FETCH_JOURNEY_COST_TREND = "FETCH_JOURNEY_COST_TREND";
export const SET_JOURNEY_COST_TREND = "SET_JOURNEY_COST_TREND";

export const FETCH_CUSTOMER_JOURNEY = "FETCH_CUSTOMER_JOURNEY";
export const SET_CUSTOMER_JOURNEY = "SET_CUSTOMER_JOURNEY";

export const FETCH_CONVERSION = "FETCH_CONVERSION";
export const SET_CONVERSION = "SET_CONVERSION";

export const FETCH_CONVERSION_TREND = "FETCH_CONVERSION_TREND";
export const SET_CONVERSION_TREND = "SET_CONVERSION_TREND";

export const FETCH_METRIC_CHANNEL = "FETCH_METRIC_CHANNEL";
export const SET_METRIC_CHANNEL = "SET_METRIC_CHANNEL";

export const FETCH_METRIC_CHANNEL_TREND = "FETCH_METRIC_CHANNEL_TREND";
export const SET_METRIC_CHANNEL_TREND = "SET_METRIC_CHANNEL_TREND";

export const FETCH_CUSTOMER_EXPERIENCE_DXS = "FETCH_CUSTOMER_EXPERIENCE_DXS";
export const SET_CUSTOMER_EXPERIENCE_DXS = "SET_CUSTOMER_EXPERIENCE_DXS";

export const FETCH_STAR_RATING_AVERAGE = "FETCH_STAR_RATING_AVERAGE";
export const SET_STAR_RATING_AVERAGE = "SET_STAR_RATING_AVERAGE";

export const FETCH_STAR_RATING_FEEDBACK = "FETCH_STAR_RATING_FEEDBACK";
export const SET_STAR_RATING_FEEDBACK = "SET_STAR_RATING_FEEDBACK";

export const FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION = "FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION";
export const SET_EXIT_SURVEY_OBJECTIVE_COMPLETION = "SET_EXIT_SURVEY_OBJECTIVE_COMPLETION";

export const FETCH_EXIT_SURVEY_FEEDBACK = "FETCH_EXIT_SURVEY_FEEDBACK";
export const SET_EXIT_SURVEY_FEEDBACK = "SET_EXIT_SURVEY_FEEDBACK";

export const FETCH_CONVERSION_AND_COST = "FETCH_CONVERSION_AND_COST";
export const SET_CONVERSION_AND_COST = "SET_CONVERSION_AND_COST";

export const FETCH_PERFORMANCE_OVERVIEW = "FETCH_PERFORMANCE_OVERVIEW";
export const SET_PERFORMANCE_OVERVIEW = "SET_PERFORMANCE_OVERVIEW";

export const FETCH_LMS_DIGITALLY_INFLUENCED_SALES = "FETCH_LMS_DIGITALLY_INFLUENCED_SALES";
export const SET_LMS_DIGITALLY_INFLUENCED_SALES = "SET_LMS_DIGITALLY_INFLUENCED_SALES";

export const FETCH_LMS_DIGITALLY_INFLUENCED_SALES_TREND = "FETCH_LMS_DIGITALLY_INFLUENCED_SALES_TREND";
export const SET_LMS_DIGITALLY_INFLUENCED_SALES_TREND = "SET_LMS_DIGITALLY_INFLUENCED_SALES_TREND";

export const FETCH_LMS_DIS_AND_LEADS = "FETCH_LMS_DIS_AND_LEADS";
export const SET_LMS_DIS_AND_LEADS = "SET_LMS_DIS_AND_LEADS";

export const FETCH_LMS_SALES_AND_LEADS_TREND = "FETCH_SALES_AND_LEADS_TREND";
export const SET_LMS_SALES_AND_LEADS_TREND = "SET_SALES_AND_LEADS_TREND";

export const FETCH_LMS_CONVERSION_AND_COST = "FETCH_LMS_CONVERSION_AND_COST";
export const SET_LMS_CONVERSION_AND_COST = "SET_LMS_CONVERSION_AND_COST";

export const FETCH_LMS_CONVERSION_AND_COST_TREND = "FETCH_LMS_CONVERSION_AND_COST_TREND";
export const SET_LMS_CONVERSION_AND_COST_TREND = "SET_LMS_CONVERSION_AND_COST_TREND";

export const FETCH_PRODUCT_JOURNEY_PERFORMANCE_OVERVIEW = "FETCH_PRODUCT_JOURNEY_PERFORMANCE_OVERVIEW";
export const SET_PRODUCT_JOURNEY_PERFORMANCE_OVERVIEW = "SET_PRODUCT_JOURNEY_PERFORMANCE_OVERVIEW";

export const FETCH_PRODUCT_JOURNEY_ENTRY_POINTS = "FETCH_PRODUCT_JOURNEY_ENTRY_POINTS";
export const SET_PRODUCT_JOURNEY_ENTRY_POINTS = "SET_PRODUCT_JOURNEY_ENTRY_POINTS";

export const FETCH_PRODUCT_JOURNEY_EXIT_POINTS = "FETCH_PRODUCT_JOURNEY_EXIT_POINTS";
export const SET_PRODUCT_JOURNEY_EXIT_POINTS = "SET_PRODUCT_JOURNEY_EXIT_POINTS";

export const FETCH_PRODUCT_JOURNEY_WEBSITE_ENTRY_POINTS = "FETCH_PRODUCT_JOURNEY_WEBSITE_ENTRY_POINTS";
export const SET_PRODUCT_JOURNEY_WEBSITE_ENTRY_POINTS = "SET_PRODUCT_JOURNEY_WEBSITE_ENTRY_POINTS";

export const FETCH_FMI_LAST_ACT_MONTH = "FETCH_FMI_LAST_ACT_MONTH";
export const SET_FMI_LAST_ACT_MONTH = "SET_FMI_LAST_ACT_MONTH";

export const SET_JOURNEY_EXCELLENCE_CURRENCY = "SET_JOURNEY_EXCELLENCE_CURRENCY";
