import { ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { create, options } from "@amcharts/amcharts4/core";
import { endOfMonth, endOfQuarter } from "date-fns";
import Cookies from "js-cookie";
import _, { isNull } from "lodash";
import React, { Component, Dispatch, SetStateAction } from "react";
import WebFont from "webfontloader";
import { CYAN, DARK_PURPLE, LIGHT_CYAN, PURPLE } from "../../../constants";
import { Annotation } from "../../../pages/Admin/types";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import ShareAnnotationModal from "../../Annotations/Dialogs/ShareAnnotationModal";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { createAnnotationTooltips } from "../helpers/annotationTooltips";
import {
  configureMTMColumnSeries,
  configureMTMDateAxis,
  configureMTMLineSeries,
  configureMTMValueAxis,
  formatDateAxisLabelToMonthly,
  formatDateAxisLabelToQuarterly,
  mtmChartConfiguration,
  mtmLegendConfiguration,
} from "../helpers/mtmConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  chartName: string;
  isLoading: boolean;
  actualField?: string;
  actualName?: string;
  targetField?: string;
  targetName?: string;
  targetFieldTrendline?: string;
  targetNameTrendline?: string;
  forecastField?: string;
  tooltipForecastField?: string;
  secondForecastField?: string;
  returningVisitors?: string;
  returningVisitorsName?: string;
  secondTooltipForecastField?: string;
  isQuarterly: boolean;
  theme: ThemeInt;
  isRate?: boolean;
  toggleAnnotationsDialog: () => void;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
  annotationMetric?: string;
  momLegendTooltipText: Record<string, string>;
  yoyLegendTooltipText: Record<string, string>;
}

interface State {
  shareModalOpen: boolean;
  annotations: Annotation[] | undefined;
}

export class MTMActualTargetForecastChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { shareModalOpen: false, annotations: undefined };

    this.toogleShareModal = this.toogleShareModal.bind(this);
  }

  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  toogleShareModal() {
    this.setState({
      shareModalOpen: !this.state.shareModalOpen,
    });
  }

  handleShareAnnotation(annotations: Annotation[]) {
    this.setState({
      annotations: annotations,
    });
    this.toogleShareModal();
  }

  initChart() {
    const {
      data,
      chartName,
      actualField,
      targetField,
      targetName,
      forecastField,
      actualName,
      targetFieldTrendline,
      targetNameTrendline,
      tooltipForecastField,
      secondForecastField,
      secondTooltipForecastField,
      returningVisitors,
      returningVisitorsName,
      isQuarterly,
      isRate,
      theme,
      toggleAnnotationsDialog,
      setAnnotationStartDate,
      setAnnotationEndDate,
      setAnnotationDefaultMetric,
      annotationMetric,
      momLegendTooltipText,
      yoyLegendTooltipText,
    } = this.props;

    options.autoDispose = true;
    this.chart = create(chartName, XYChart);
    mtmChartConfiguration(this.chart);
    this.chart.data = data;

    const userViewsList = Cookies.get("views");

    //Add legends
    this.chart.legend = mtmLegendConfiguration("{date}", theme);
    this.chart.legend.labels.template.wrap = false;
    this.chart.legend.itemContainers.template.adapter.add("tooltipText", (text, target) => {
      // @ts-ignore
      const fieldName = target.dataItem?.dataContext?.dataFields?.valueY;
      const momText =
        // @ts-ignore
        target.dataItem?.name?.includes("MoM") || target.dataItem?.name?.includes("QoQ") ? momLegendTooltipText[fieldName] : ""; // @ts-ignore
      const yoyText = target.dataItem?.name?.includes("YoY") ? yoyLegendTooltipText[fieldName] : "";
      if (!momText && !yoyText) {
        return;
      }

      return `${momText}
      ${yoyText}
      `;
    });

    const marker = this.chart.legend.markers.template.children.getIndex(0);
    if (marker) marker.y = 10;

    //Default x axis to show current date
    const dateAxis: DateAxis = this.chart.xAxes.push(new DateAxis());
    configureMTMDateAxis(dateAxis, theme, isQuarterly);

    //Default x axis to show current date
    const forecastDateAxis: DateAxis = this.chart.xAxes.push(new DateAxis());
    configureMTMDateAxis(forecastDateAxis, theme, isQuarterly);
    forecastDateAxis.renderer.labels.template.disabled = true;
    forecastDateAxis.syncWithAxis = dateAxis;

    //Y Axis that is used by default
    const valueAxis: ValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureMTMValueAxis(valueAxis, theme);
    if (isRate)
      valueAxis.renderer.labels.template.adapter.add("text", (text) => {
        return text + "%";
      });
    valueAxis.extraMax = 0.1;

    const percentageValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureMTMValueAxis(percentageValueAxis, theme);
    percentageValueAxis.renderer.opposite = true;
    percentageValueAxis.renderer.labels.template.adapter.add("text", (text) => {
      return text + "%";
    });
    percentageValueAxis.syncWithAxis = valueAxis;

    //Actual column bar
    if (actualName && actualField) {
      const columnSeries = this.chart.series.push(new ColumnSeries());
      configureMTMColumnSeries(columnSeries, { name: actualName, valueY: actualField, forecastAxis: tooltipForecastField, color: CYAN });
      columnSeries.columns.template.adapter.add("tooltipText", (value, target) => {
        const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

        let text = `[bold]${
          isQuarterly ? formatDateAxisLabelToQuarterly(dateValue.date) : formatDateAxisLabelToMonthly(dateValue.date)
        }[/]\n`;

        this.chart.series.each((item) => {
          if (!item.isHidden) {
            const value =
              item.dataFields.valueY && dateValue
                ? item.name === "Forecast"
                  ? // @ts-ignore
                    (dateValue[item.dataFields.forecastY] as number)
                  : (dateValue[item.dataFields.valueY] as number)
                : null;
            const fieldName = item.name.includes("MoM")
              ? item.name.substring(0, item.name.indexOf("MoM"))
              : item.name.includes("QoQ")
              ? item.name.substring(0, item.name.indexOf("QoQ"))
              : item.name.includes("YoY")
              ? item.name.substring(0, item.name.indexOf("YoY"))
              : item.name;
            const itemText = fieldName.replace(/(\r\n|\n|\r)/gm, "").includes("+")
              ? fieldName.replace(/\+.*/, "").replace(/(\r\n|\n|\r)/gm, "")
              : fieldName.replace(/-.*/, "").replace(/(\r\n|\n|\r)/gm, "");

            text += `[${item.stroke}]●[/] ${itemText}: ${
              isNull(value)
                ? "n/a"
                : `${itemText.includes("Reach") ? value.toFixed(2) : formatToThreeDigits(value as number)}${isRate ? "%" : ""}`
            }\n`;
          }
        });
        return text;
      });
      if (userViewsList?.includes("annotations")) {
        columnSeries.columns.template.events.on("hit", (ev: any) => {
          const startDate = new Date(ev.target.dataItem.dateX);
          const endDate = isQuarterly ? endOfQuarter(startDate) : endOfMonth(startDate);
          setAnnotationStartDate(startDate);
          setAnnotationEndDate(endDate);
          annotationMetric && setAnnotationDefaultMetric(annotationMetric);
          toggleAnnotationsDialog();
        });
      }
      let annotationBullet = createAnnotationTooltips.bind(this);
      annotationBullet = annotationBullet(columnSeries, "annotations");
      // @ts-ignore
      annotationBullet.adapter.add("disabled", function (disabled: any, target: any) {
        if (!target.dataItem) {
          return disabled;
        }
        if (target.dataItem.dataContext?.annotations) {
          return false;
        } else {
          return true;
        }
      });

      if (chartName == "kbaChart") {
        //@ts-ignore
        annotationBullet.tooltip.pointerOrientation = "right";
      }
    }

    //Forecast dotted column series
    if (forecastField && tooltipForecastField) {
      const dottedColumnSeries: ColumnSeries = this.chart.series.push(new ColumnSeries());
      configureMTMColumnSeries(dottedColumnSeries, {
        name: `${forecastField == "temp_visits_forecast" ? "Visits forecast" : "Forecast"}`,
        color: CYAN,
        valueY: forecastField,
        forecastAxis: tooltipForecastField,
        xAxis: forecastDateAxis,
      });
      dottedColumnSeries.hiddenInLegend = true;
      dottedColumnSeries.fillOpacity = 0.0;
      dottedColumnSeries.strokeDasharray = "5,5";
      dottedColumnSeries.columns.template.adapter.add("tooltipText", (value, target) => {
        const dataValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

        let text = `[bold]${
          isQuarterly ? formatDateAxisLabelToQuarterly(dataValue.date) : formatDateAxisLabelToMonthly(dataValue.date)
        }[/]\n`;

        this.chart.series.each((item) => {
          if (!item.isHidden) {
            const value = item.dataFields.valueY
              ? item.name === "Forecast"
                ? // @ts-ignore
                  dataValue[item.dataFields.forecastY]
                : dataValue[item.dataFields.valueY]
              : null;

            const fieldName = item.name.includes("MoM")
              ? item.name.substring(0, item.name.indexOf("MoM"))
              : item.name.includes("QoQ")
              ? item.name.substring(0, item.name.indexOf("QoQ"))
              : item.name.includes("YoY")
              ? item.name.substring(0, item.name.indexOf("YoY"))
              : item.name;
            const itemText = fieldName.replace(/(\r\n|\n|\r)/gm, "").includes("+")
              ? fieldName.replace(/\+.*/, "").replace(/(\r\n|\n|\r)/gm, "")
              : fieldName.replace(/-.*/, "");

            text += `[${item.stroke}]●[/] ${itemText}: ${isNull(value) ? "n/a" : formatToThreeDigits(value as number)}\n`;
          }
        });
        return text;
      });
    }

    //Forecast dotted column series
    if (secondForecastField && secondTooltipForecastField) {
      const dottedColumnSeries2: ColumnSeries = this.chart.series.push(new ColumnSeries());
      configureMTMColumnSeries(dottedColumnSeries2, {
        name: `${secondForecastField == "qualified_visits_forecast" ? "Qualified visits forecast" : "Forecast"}`,
        color: DARK_PURPLE,
        valueY: secondForecastField,
        forecastAxis: secondTooltipForecastField,
        xAxis: forecastDateAxis,
      });
      dottedColumnSeries2.hiddenInLegend = true;
      dottedColumnSeries2.fillOpacity = 0.0;
      dottedColumnSeries2.strokeDasharray = "5,5";
      dottedColumnSeries2.columns.template.adapter.add("tooltipText", (value, target) => {
        const dataValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

        let text = `[bold]${
          isQuarterly ? formatDateAxisLabelToQuarterly(dataValue.date) : formatDateAxisLabelToMonthly(dataValue.date)
        }[/]\n`;

        this.chart.series.each((item) => {
          if (!item.isHidden) {
            const value = item.dataFields.valueY
              ? item.name === "Forecast"
                ? // @ts-ignore
                  dataValue[item.dataFields.forecastY]
                : dataValue[item.dataFields.valueY]
              : null;

            const fieldName = item.name.includes("MoM")
              ? item.name.substring(0, item.name.indexOf("MoM"))
              : item.name.includes("QoQ")
              ? item.name.substring(0, item.name.indexOf("QoQ"))
              : item.name.includes("YoY")
              ? item.name.substring(0, item.name.indexOf("YoY"))
              : item.name;

            const itemText = fieldName.replace(/(\r\n|\n|\r)/gm, "").includes("+")
              ? fieldName.replace(/\+.*/, "").replace(/(\r\n|\n|\r)/gm, "")
              : fieldName.replace(/-.*/, "").replace(/(\r\n|\n|\r)/gm, "");

            text += `[${item.stroke}]●[/] ${itemText}: ${isNull(value) ? "n/a" : formatToThreeDigits(value as number)}\n`;
          }
        });
        return text;
      });
    }

    //Returning visitors
    if (returningVisitors) {
      const offlineColumnSeries = this.chart.series.push(new ColumnSeries());
      configureMTMColumnSeries(offlineColumnSeries, {
        name: returningVisitorsName ? returningVisitorsName : "",
        valueY: returningVisitors,
        color: PURPLE,
      });
      offlineColumnSeries.columns.template.adapter.add("tooltipText", (value, target) => {
        const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

        let text = `[bold]${
          isQuarterly ? formatDateAxisLabelToQuarterly(dateValue.date) : formatDateAxisLabelToMonthly(dateValue.date)
        }[/]\n`;

        this.chart.series.each((item) => {
          if (!item.isHidden) {
            const value = item.dataFields.valueY ? (dateValue[item.dataFields.valueY] as number) : null;

            const fieldName = item.name.includes("MoM")
              ? item.name.substring(0, item.name.indexOf("MoM"))
              : item.name.includes("QoQ")
              ? item.name.substring(0, item.name.indexOf("QoQ"))
              : item.name.includes("YoY")
              ? item.name.substring(0, item.name.indexOf("YoY"))
              : item.name;

            const itemText = fieldName.replace(/(\r\n|\n|\r)/gm, "").includes("+")
              ? fieldName.replace(/\+.*/, "").replace(/(\r\n|\n|\r)/gm, "")
              : fieldName.replace(/-.*/, "").replace(/(\r\n|\n|\r)/gm, "");

            text += `[${item.stroke}]●[/] ${itemText}: ${isNull(value) ? "n/a" : formatToThreeDigits(value as number)}\n`;
          }
        });
        return text;
      });
      if (userViewsList?.includes("annotations")) {
        offlineColumnSeries.columns.template.events.on("hit", (ev: any) => {
          const startDate = new Date(ev.target.dataItem.dateX);
          const endDate = isQuarterly ? endOfQuarter(startDate) : endOfMonth(startDate);
          setAnnotationStartDate(startDate);
          setAnnotationEndDate(endDate);
          setAnnotationDefaultMetric(`Returning visitors`);
          toggleAnnotationsDialog();
        });
      }
      let annotationBullet2 = createAnnotationTooltips.bind(this);
      annotationBullet2 = annotationBullet2(offlineColumnSeries, "annotations");
      // @ts-ignore
      annotationBullet2.adapter.add("disabled", function (disabled: any, target: any) {
        if (!target.dataItem) {
          return disabled;
        }
        if (target.dataItem.dataContext?.annotations) {
          const itemAnnotations = target.dataItem.dataContext.annotations.filter((annotation: Annotation) =>
            annotation.metrics.flatMap((metric) => metric.metric).includes("Returning visitors")
          );
          if (itemAnnotations.length > 0) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      });
    }

    if (targetFieldTrendline && targetNameTrendline) {
      const trendlinelineSeries: LineSeries = this.chart.series.push(new LineSeries());
      configureMTMLineSeries(trendlinelineSeries, {
        name: targetNameTrendline,
        valueY: targetFieldTrendline,
        color: CYAN,
        xAxis: dateAxis,
      });
    }

    //Target line series
    if (targetName && targetField) {
      const lineSeries: LineSeries = this.chart.series.push(new LineSeries());
      targetField === "kba_target"
        ? configureMTMLineSeries(lineSeries, {
            name: targetName,
            valueY: targetField,
            color: LIGHT_CYAN,
            xAxis: dateAxis,
          })
        : configureMTMLineSeries(lineSeries, {
            name: targetName,
            valueY: targetField,
            color: PURPLE,
            xAxis: dateAxis,
            yAxis: percentageValueAxis,
          });
    }

    this.chart.series.each((series) => {
      series.bullets.getIndex(0)?.adapter.add("tooltipText", (value, target) => {
        if (target?.tooltipDataItem) {
          const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

          let text = `[bold]${
            isQuarterly ? formatDateAxisLabelToQuarterly(dateValue?.date) : formatDateAxisLabelToMonthly(dateValue?.date)
          }[/]\n`;

          this.chart.series.each((item) => {
            if (!item.isHidden) {
              const value =
                item.dataFields.valueY && dateValue
                  ? item.name === "Forecast"
                    ? // @ts-ignore
                      (dateValue[item.dataFields.forecastY] as number)
                    : (dateValue[item.dataFields.valueY] as number)
                  : null;

              const fieldName = item.name.includes("MoM")
                ? item.name.substring(0, item.name.indexOf("MoM"))
                : item.name.includes("QoQ")
                ? item.name.substring(0, item.name.indexOf("QoQ"))
                : item.name.includes("YoY")
                ? item.name.substring(0, item.name.indexOf("YoY"))
                : item.name;

              const itemText = fieldName.replace(/(\r\n|\n|\r)/gm, "").includes("+")
                ? fieldName.replace(/\+.*/, "").replace(/(\r\n|\n|\r)/gm, "")
                : fieldName.replace(/-.*/, "");

              text += `[${item.stroke}]●[/] ${itemText}: ${
                isNull(value) ? "n/a" : itemText.includes("Reach") ? value.toFixed(2) : formatToThreeDigits(value as number)
              }\n`;
            }
          });
          return text;
        }
      });
    });

    this.chart.bottomAxesContainer.layout = "vertical";
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
        <ShareAnnotationModal
          open={this.state.shareModalOpen}
          handleToogleDialog={this.toogleShareModal}
          annotations={this.state.annotations}
        />
      </div>
    );
  }
}
