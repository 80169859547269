import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { requestPasswordReset } from "../../api/authenitcationApi";
import blueCedarLogo from "../../assets/images/blue_cedar_logo.svg";
import { isValidEmail } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { TextInput } from "./common";
import { SubmitBtn } from "./common/Buttons";

export const ForgotPasswordForm = withRouter((props: RouteComponentProps) => {
  const { history, location } = props;

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    () => (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);

      const { target } = event;

      const formData = new FormData(target as HTMLFormElement);
      const formObj: Record<string, FormDataEntryValue | string> = Object.fromEntries(formData);

      if (isValidEmail(formObj.email as string)) {
        error && setError("");

        requestPasswordReset(formObj.email as string)
          .then((response) => {
            toast.success("A request token has been sent to your email if it exists");
            setTimeout(() => history.push({ pathname: "/login" }), 2500);
          })
          .finally(() => setLoading(false));
      } else {
        setError("Please enter a valid email address");
        setLoading(false);
      }
    },
    [setError, history, error]
  );
  const [email, setEmail] = useState("");

  useEffect(() => {
    const { state } = location as any;

    const user_email = state?.email;

    if (user_email) {
      setEmail(user_email);
    }
  }, [location]);

  const handleBackToLoginClick = useCallback(
    () => () => {
      history.push({ pathname: "/login" });
    },
    [history]
  );

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = evt;
    setEmail(value);
  };

  return (
    <ErrorBoundary fallback={<ErrorMsg class={"formError"} />}>
      <div className="form_body" id="login_form_body">
        <div className="header_section">
          <img src={blueCedarLogo} alt="Cedar Logo" />
        </div>
        <form
          id="forgot_password_form"
          className="login_form"
          name="forgot_password_form"
          data-test-id="forgot_password_form"
          onSubmit={handleSubmit()}
        >
          <div className="text_area">
            <h3 className="login_head form_header_text" data-test-id="login_form--welcome">
              Forgot your password?
            </h3>

            {error && <p className={"error"}>{error}</p>}

            <TextInput
              label="Enter your email below to request a password reset link"
              onChange={handleInputChange}
              name="email"
              id="email"
              value={email}
              pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
            />

            <SubmitBtn id="password_reset" value="Request a password reset" isLoading={loading} />

            <p id="login_link" data-test-id="login_link" className="links back_button" onClick={handleBackToLoginClick()}>
              <span /> Back to login
            </p>
          </div>
        </form>
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick={true}
          draggable={false}
          pauseOnHover={false}
        />
      </div>
    </ErrorBoundary>
  );
});
