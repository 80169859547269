import { ErrorBoundary } from "@sentry/react";
import React, { Fragment, useMemo } from "react";
import { CellPropGetter, useExpanded, useSortBy, useTable } from "react-table";
import "../../../assets/styles/component/tables.scss";
import { ErrorMsg } from "../../AppMessages";
import customSortTypes from "./sortType";

const RATINGS_COLOR_CLASSES: { [index: number]: string } = { 1: "red", 2: "orange", 3: "yellow", 4: "light-green", 5: "dark-green" };

interface Props {
  data: any;
  columns: any;
  id: string;
  extraClass?: string;
  hiddenCols: string[];
  hideHeader?: boolean;
  initialSortColId: { id: string; desc: boolean };
  setSortInformation?: (id: string, desc: boolean) => void;
}

export const CommentsDefaultTable = ({
  data,
  columns: chartColumns,
  id: chartId,
  extraClass,
  hideHeader,
  hiddenCols,
  initialSortColId,
  setSortInformation,
}: Props): JSX.Element => {
  const sortTypes = useMemo(() => customSortTypes(), []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: chartColumns,
      data,

      // @ts-ignore
      initialState: { hiddenColumns: [...hiddenCols], sortBy: [initialSortColId] },
      sortTypes,
    },
    useSortBy,
    useExpanded
  );

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <table id={chartId} {...getTableProps()} className={`${extraClass}`}>
        {!hideHeader && (
          <thead className={extraClass?.includes("appRatingsComments") ? "comments_header" : ""}>
            {headerGroups.map((headerGroup) => {
              const { key: headerGroupKey, ...otherHeaderGroupProps } = headerGroup.getHeaderGroupProps();
              return (
                <tr {...otherHeaderGroupProps} key={headerGroupKey}>
                  {headerGroup.headers.map((column) => {
                    const sortClass = column?.isSorted ? (column.isSortedDesc ? "down" : "up") : "";

                    const sortedId = column.id;
                    const isSorted = column.isSorted;
                    const isSortedDesc = !!column.isSortedDesc;

                    // Updates sort id and order for child component
                    isSorted && setSortInformation && setSortInformation(sortedId, isSortedDesc);

                    const { key: headerToggleKey, ...otherHeaderToggleProps } = column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th key={headerToggleKey} {...otherHeaderToggleProps} className={`sortable ${sortClass} ${column.Header}`}>
                        <span>{column.render("Header")}</span>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
        )}

        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            const rowClass = (index + 1) % 2 === 0 ? "even" : "odd";
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr {...row.getRowProps()} className={rowClass}>
                  {/* @ts-ignore */}
                  {row.cells.map((cell: CellPropGetter) => {
                    const {
                      // @ts-ignore
                      column: { id: columnId },
                      // @ts-ignore
                      value: columnValue,
                    } = cell.getCellProps(cell) as Parameters<typeof cell.getCellProps>[0];

                    const { key: cellKey, ...otherCellProps } = cell.getCellProps();
                    return (
                      <td {...otherCellProps} key={cellKey} className={columnId === "ratings" ? RATINGS_COLOR_CLASSES[columnValue] : ""}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </ErrorBoundary>
  );
};
