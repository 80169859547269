import React from "react";

interface Props {
  title: string;
  grigValues: Array<{ name: string; value: number }>;
  extraClass?: string;
}

const ReleaseGrid = (props: Props) => {
  const { title, grigValues, extraClass } = props;

  return (
    <div className={`${extraClass || ""} bug-release-container`}>
      <div>{title}</div>
      <div className="bug-release-items">
        {grigValues.map(({ name, value }) => (
          <div className="text-center" key={`${title}_${name}`}>
            <div>{value}</div>
            <div>{name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReleaseGrid;
