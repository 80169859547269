import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getMidasDataDefinitions } from "../../../api";

interface DataUpdate {
  Dashboard_page: string;
  Dashboard_page_objective: string;
  element_changing: string;
  Description: string;
  KPI_category: string;
  Source: string;
}

export const MidasData = (): JSX.Element => {
  const [dataUpdates, setDataUpdates] = useState<Array<DataUpdate>>();

  useEffect(() => {
    getMidasDataDefinitions().then((response: any) => {
      if (response && "error" in response) {
        toast.error("Error fetching data updates");
      } else {
        setDataUpdates(response);
      }
    });
  }, []);

  return (
    <div className="data_documentation">
      <h3>MIDAS - DATA OVERVIEW</h3>

      {/* <h4>Purchase funnel KPI definitions and sources</h4> */}
      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>Dashboard page</th>
            <th>Dashboard page objective</th>
            <th>KPI/category</th>
            <th>Description</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {dataUpdates?.map((data, index) => (
            <tr key={index}>
              <td>{data.Dashboard_page}</td>
              <td>{data.Dashboard_page_objective}</td>
              <td>{data.KPI_category}</td>
              <td style={{ whiteSpace: "pre-wrap" }}>{data.Description}</td>
              <td>{data.Source}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <br />
    </div>
  );
};
