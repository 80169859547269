import { CategoryAxis, ColumnSeries, LabelBullet, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, CYAN, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  theme: ThemeInt;
  chartName: string;
  isLoading: boolean;
  categoryField: string;
  showDataLabel?: boolean;
}

interface ISeries {
  name: string;
  field: string;
}

class ExecutiveSummaryCategoryBarChart extends Component<Props> {
  chartId = this.props.chartName;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, theme, categoryField, showDataLabel } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.##";
    this.chart.colors.list = [color(CYAN)];

    // Create current period date chart axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.renderer.minGridDistance = 50;
    categoryAxis.dataFields.category = categoryField;
    // categoryAxis.startLocation = 0.2;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.maxWidth = 130;
    categoryAxis.renderer.labels.template.fontSize = 12;
    categoryAxis.events.on("sizechanged", function (ev) {
      const axis = ev.target;
      const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -55;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter = new NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#a";
    valueAxis.extraMax = 0.1;

    const series = this.chart.series.push(new ColumnSeries());
    series.dataFields.categoryX = this.props.categoryField;
    series.dataFields.valueY = "visits";
    series.columns.template.width = percent(75);
    series.columns.template.tooltipText = "{categoryX}: {valueY.formatNumber('###,###')}[/]";
    if (series.tooltip) {
      series.tooltip.getFillFromObject = false;
      series.tooltip.autoTextColor = false;
      series.tooltip.background.fill = color(WHITE);
      series.tooltip.label.fill = color(BLACK);
    }

    if (showDataLabel) {
      const valueLabel = series.bullets.push(new LabelBullet());
      valueLabel.label.text = "{valueY}";
      valueLabel.label.dy = -10;
      valueLabel.label.hideOversized = false;
      valueLabel.label.fill = theme == "dark" ? color(WHITE) : color(BLACK);
    }
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default ExecutiveSummaryCategoryBarChart;
