import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { MetricType } from "../../../constants/interface/admin/common";
import { FormFields } from "../Dialogs/AnnotationDialog";

interface Props {
  control: Control<FormFields>;
  metricTypes: MetricType[];
  errors: FieldErrors | undefined;
}

const MetricTypeField = (props: Props) => {
  const { control, metricTypes, errors } = props;
  return (
    <Controller
      name="metric_types"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          multiple
          options={metricTypes}
          disableCloseOnSelect
          getOptionLabel={(option: MetricType) => option.metric_type}
          getOptionSelected={(option, value) => option.metric_type == value.metric_type}
          value={value}
          onChange={(_, data) => {
            onChange(data);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Metric Types"
              placeholder="Metric Types"
              error={errors?.metric_types ? true : false}
              helperText={errors?.metric_types?.message}
            />
          )}
        />
      )}
      rules={{
        required: {
          value: true,
          message: "Please select a metric",
        },
      }}
    />
  );
};

export default MetricTypeField;
