import React, { useCallback, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AriyaLayout } from "..";
import MenuOptions from "../../MenuOptions/MenuOptions";
import { handlePDFDownload_Ariya } from "../../MenuOptions/subs";
import { SaveHomePage } from "../../Modals";
import homeStyles from "./styles/drilldownLayout.module.scss";

interface Props {
  children: React.ReactChild;
  title: string;
  titleDescription?: string;
  lastDataRefresh: string;
  previousPagePath: string;
  flagName?: string;
  lastCanadaUpdate?: string;
}

const marketStyles: Record<string, string> = {
  GB: homeStyles.GB,
  DE: homeStyles.DE,
  NO: homeStyles.NO,
  JP: homeStyles.JP,
  USA: homeStyles.USA,
  NE: homeStyles.NE,
  CA: homeStyles.CA,
  FR: homeStyles.FR,
  ITA: homeStyles.ITA,
  SWE: homeStyles.SWE,
  BE: homeStyles.BE,
};

export const DrilldownLayout = withRouter((props: Props & RouteComponentProps) => {
  const { title, titleDescription, children, lastDataRefresh, previousPagePath, history, flagName, lastCanadaUpdate } = props;

  const [showSaveHomePage, setShowSaveHomePage] = useState<boolean>(false);

  const handleSaveHomePageClick = () => {
    setShowSaveHomePage(true);
  };

  const handleCloseSaveHomePage = () => {
    setShowSaveHomePage(false);
  };

  const pageNavigation = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;
      history.push({ pathname: pageLink, search: "" });
    },
    [history]
  );

  return (
    <>
      <AriyaLayout>
        <main className={homeStyles.main} id="ariyaContent">
        <div className={`dashboard_banner hide_on_mobile`}>
            <p>
              This dashboard has been discontinued and archived. Therefore, the data will no longer be updated and might contain
              discrepancies moving forward. Please contact us if you want to have more information.
            </p>
          </div>
          <div id="ariyaContentDiv">
            <div className={homeStyles.titleContainer}>
              <div className={homeStyles.backBtn}>
                &lt;
                <span className={homeStyles.backText} data-page={previousPagePath} onClick={pageNavigation}>
                  Back
                </span>
              </div>
              <div className={homeStyles.titleLastDataRefresh}>
                <div className={homeStyles.headerContainer}>
                  <h1 className={homeStyles.title} id="pageTitle">
                    {flagName && (
                      <span className={`${homeStyles.flagDiv} ${flagName in marketStyles ? marketStyles[flagName] : ""}`}></span>
                    )}
                    {title}
                  </h1>
                  {titleDescription && <h2 className={homeStyles.titleDescription}>{titleDescription}</h2>}
                </div>
                <MenuOptions
                  onHandleCSVDownload={() => {}}
                  onHandlePDFDownload={handlePDFDownload_Ariya}
                  onHandleSaveHomePageClick={handleSaveHomePageClick}
                  lastDataRefresh={lastDataRefresh}
                  dataDocumentation={""}
                  showLastDataRefreshAlert={true}
                  isAriya={true}
                  canadaUpdate={lastCanadaUpdate}
                />
              </div>
            </div>

            <div className={homeStyles.content}>{children}</div>
          </div>
          <SaveHomePage isVisible={showSaveHomePage} onCloseClick={handleCloseSaveHomePage} />
        </main>
      </AriyaLayout>
    </>
  );
});
