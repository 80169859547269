import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component, Dispatch, SetStateAction } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { WEBSITE_VISITS_COLORS, WHITE } from "../../../constants";
import { Annotation } from "../../../pages/Admin/types";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, legendConfiguration } from "../helpers/configurations";
import { configureOCELineSeries, oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  granularity: string;
  seriesList: Array<string>;
  metric: string;
  theme: ThemeInt;
  breakdownTitle: string;
  annotations: Annotation[];
  toggleAnnotationsDialog: () => void;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
  setAnnotationDefaultRegion: Dispatch<SetStateAction<string | undefined>>;
  setAnnotationDefaultMarket: Dispatch<SetStateAction<string | undefined>>;
  setAnnotationDefaultModel: Dispatch<SetStateAction<string | undefined>>;
  isCpoDashboard?: boolean;
}

interface State {
  highlightDataStartDate: string | undefined;
  highlightDataEndDate: string | undefined;
  shareModalOpen: boolean;
  annotations: Annotation[] | undefined;
}

class ModelsTrendChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { highlightDataStartDate: undefined, highlightDataEndDate: undefined, shareModalOpen: false, annotations: undefined };

    this.toogleShareModal = this.toogleShareModal.bind(this);
  }

  static defaultProps = {
    granularity: "daily",
  };

  chart!: XYChart;
  chartId = "modelTrendGraph";

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  setHighlightDataDates(startDate: string | undefined, endDate: string | undefined) {
    this.setState({
      highlightDataStartDate: startDate,
      highlightDataEndDate: endDate,
    });
  }

  toogleShareModal() {
    this.setState({
      shareModalOpen: !this.state.shareModalOpen,
    });
  }

  handleShareAnnotation(annotations: Annotation[]) {
    this.setState({
      annotations: annotations,
    });
    this.toogleShareModal();
  }

  initChart() {
    const { data, granularity, metric, seriesList, theme } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.colors.list = WEBSITE_VISITS_COLORS.map((chartColor) => color(chartColor));

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.extraMax = 0.1;

    // Creating series
    this.createSeries(seriesList?.slice(0, 6), metric, granularity);
  }

  createSeries(SeriesList: string[], metric: string, granularity: string) {
    SeriesList?.slice(0, 6).forEach((market, index) => {
      const series = this.chart.series.push(new LineSeries());
      configureOCELineSeries(series, {
        name: "Model detail visits",
        valueY: market,
        granularity,
      });
      series.dataFields.dateX = "row_date";
      series.adapter.add("fill", (currentColor, target) => {
        const startDate = this.state.highlightDataStartDate;
        const endDate = this.state.highlightDataEndDate;
        const dataStartDate = target.dataItem?.dates.dateX;
        if (startDate && endDate && dataStartDate) {
          if (dataStartDate >= new Date(startDate) && dataStartDate <= new Date(endDate)) return color(WHITE);
        }
        return currentColor;
      });
    });
  }
  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={"modelTrendGraph"} data-test-id={"modelTrendGraph"} className={"graph"} />
        </div>
      </>
    );
  }
}

export default ModelsTrendChart;
