import React from "react";
import { Tooltip } from "react-tippy";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../components/Ratings/Ratings";
import { getValueOrNa, getValueOrNull } from "../../../utils/utilityFunctions";

interface Props {
  title: string;
  isLoading: boolean;
  currentData: any;
  PoPData: any;
  YoYData: any;
  PoPLabel: string;
  showPoP?: boolean;
  paramKey: string;
  tooltipText?: string;
  ratingIsInverse?: boolean;
}

const MetricTile = (props: Props) => {
  const { isLoading, currentData, PoPData, YoYData, PoPLabel, showPoP, paramKey, title, tooltipText, ratingIsInverse } = props;

  return (
    <div className="grid-col-span-1 pp-tile">
      <div className="tile-header text-center flex-row">
        {title}{" "}
        {tooltipText && (
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={tooltipText}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        )}
      </div>
      <LoadingEllipsis isLoading={isLoading} />
      <>
        <div className="value text-center">{getValueOrNa(currentData, paramKey)}</div>
        {showPoP && (
          <div className="comparison">
            <Ratings
              value={getValueOrNull(PoPData, `pts_${paramKey}`)}
              isPercentageValue={false}
              testValue={false}
              ratingIsInverse={ratingIsInverse}
            />
            <span className="comparison_value">{getValueOrNa(PoPData, `pts_${paramKey}`)}</span>
            <span className="comparison_label">{PoPLabel}</span>
          </div>
        )}

        <div className="comparison">
          <Ratings
            value={getValueOrNull(YoYData, `pts_${paramKey}`)}
            isPercentageValue={true}
            ratingIsInverse={ratingIsInverse}
            testValue={false}
          />
          <span className="comparison_value">{getValueOrNa(YoYData, `pts_${paramKey}`)}</span>
          <span className="comparison_label">{"YoY"}</span>
        </div>
      </>
    </div>
  );
};

export default MetricTile;
