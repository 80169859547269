import { IUserDetails } from "../constants/interface";
import { defaultApiInt, fetchDataNoToken, getDataNoTokenV2, postDataNoTokenV2 } from "./utils";

export const validateUserToken = (formData: Record<string, string>): defaultApiInt => fetchDataNoToken("/validate_token", formData);

// V2
export const validateLogin = (data: Record<string, string>): Promise<IUserDetails> => postDataNoTokenV2(`/authentication/login`, data);
export const requestPasswordReset = (email: string): defaultApiInt =>
  postDataNoTokenV2(`/authentication/request-password-reset`, { email, domain: window.location.origin });
export const resetUserPassword = <T>(data: T, token: string): defaultApiInt =>
  postDataNoTokenV2(`/authentication/reset-password`, data, token);
export const getUserViews = (email: string, token: string): Promise<IUserDetails> =>
  getDataNoTokenV2(`/user_service/get-current-user?email=${email}`, token);
