import { DELETE_SPECIFIC_PARAMETER, SET_PARAMETERS, SET_SPECIFIC_PARAMETER } from "../actions/actionTypes";
import { DEFAULT_PARAMETER_VALUES, NISSAN } from "../constants";

const searchParam = new URLSearchParams(window.location.search);
const brandParam = searchParam.get("brand") ? searchParam.get("brand") : "";
const hasDatsun = /datsun/gi.test(brandParam ? brandParam : "");
const brand = hasDatsun ? NISSAN : brandParam ? brandParam : NISSAN;

const searchSpecificState = Object.fromEntries(searchParam.entries());

// Sets initial parameter state to the url parameters / default parameters when no parameters have been defined
const initialState: { [index: string]: string } =
  Object.keys(searchSpecificState)?.length > 0
    ? { ...DEFAULT_PARAMETER_VALUES, ...searchSpecificState, brand }
    : { ...DEFAULT_PARAMETER_VALUES };

const parametersReducer = (state = initialState, action: { payload: any; type: string; name: string }) => {
  switch (action.type) {
    case SET_PARAMETERS:
      return { ...state, ...action.payload };

    case SET_SPECIFIC_PARAMETER:
      return { ...state, [action.name]: action.payload };

    case DELETE_SPECIFIC_PARAMETER:
      const nextState = { ...state };
      delete nextState[action.name];
      return nextState;

    default:
      return state;
  }
};
export default parametersReducer;
