import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { ErrorMsg } from "../../../components/AppMessages";
import VisitsChart from "../../../components/Charts/SEO/VisitsChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import VisitsBreakdownTable from "../../../components/Tables/SEO/VisitsBreakdownTable";
import { ThemeContext } from "../../../context";
import { useMetricVisible } from "../../../hooks/dropdownVisibleHook";
import { generatePreviousRange } from "../helpers/helpers";

export const VisitsTrendBreakdown = (): JSX.Element => {
  //Param values
  const {
    date_range: dateRangeParamValue,
    market: marketParamValue,
    visits_disabled: visitsDisabled,
    landing_page: landingPage,
  } = useSelector((state: RootStateOrAny) => state.seo_parameters);

  const { theme } = useContext(ThemeContext);

  //Chart actions
  const visits_overview = useSelector((state: RootStateOrAny) => state.seo_data.visits_overview);
  const [chartData, setChartData] = useState([]);
  const [visitsChartData, setVisitsChartData] = useState<any>([]);
  const [minGridDistance, setMinGridDistance] = useState(120);
  const [weekCommence, setWeekCommence] = useState("");
  const legendLabels = generatePreviousRange(dateRangeParamValue);
  //Metric filter state
  const { metricRef, isMetricVisible, setIsMetricVisible } = useMetricVisible(false);
  const [isKbaChecked, setIsKbaChecked] = useState(false);
  const [isLeadsChecked, setIsLeadsChecked] = useState(false);
  const [isVisitsChecked, setIsVisitsChecked] = useState(false);
  const [isVisitsNationalChecked, setIsVisitsNationalChecked] = useState(false);
  const [isVisitsRegionalChecked, setIsVisitsRegionalChecked] = useState(false);
  //Loading actions
  const { visits_overview: isVisitsOverviewLoading, visits_breakdown: isVisitsBreakdownLoading } = useSelector(
    (state: RootStateOrAny) => state.loading
  );

  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  //Use weekly data on all date ranges apart from month
  useEffect(() => {
    if (dateRangeParamValue.includes("month") || dateRangeParamValue.includes("quarter")) {
      setVisitsChartData(visits_overview[0]?.data);
      setWeekCommence("");
    } else {
      if (visits_overview[0]?.data?.length > 90) {
        setVisitsChartData(visits_overview[0]?.week);
        setWeekCommence("w/c");
      } else {
        setVisitsChartData(visits_overview[0]?.data);
        setWeekCommence("");
      }
    }
  }, [dateRangeParamValue, visits_overview]);

  //Set min grid distance dynamically
  useEffect(() => {
    if (dateRangeParamValue.includes("quarter")) {
      window.innerWidth < 1400 ? setMinGridDistance(50) : setMinGridDistance(120);
    }
  }, [dateRangeParamValue]);

  //format the data from the api to use flat data on am charts
  useEffect(() => {
    if (visitsChartData !== 0) {
      const formattedData = visitsChartData?.map((row: any) => {
        const {
          row_date,
          current_data: {
            organic_visits: currentValue,
            paid_visits: paidVisits,
            kba_visits: kba,
            digital_leads: leads,
            paid_visits_regional: paidVisitsRegional,
            paid_visits_national: paidVisitsNational,
          },
          pop_data,
        } = row;
        const popDate = pop_data?.date;
        const popValue = pop_data?.organic_visits;
        return { row_date, currentValue, popDate, popValue, paidVisits, kba, leads, paidVisitsNational, paidVisitsRegional };
      });

      setChartData(formattedData);
    } else {
      setChartData([]);
    }
  }, [visitsChartData]);

  // //Disable Impressions if landing page is not All
  useEffect(() => {
    dispatch(setSpecificParameter("impressions_disabled", landingPage !== "All"));
  }, [landingPage]);

  return (
    <div className={`seo_tile visits_trend_breakdown ${visitsDisabled ? "disabled" : ""}`}>
      <h3>Website traffic trend and breakdown</h3>

      <div className="filters_container">
        {/*METRIC FILTER */}
        <div className="filter" ref={metricRef} data-test-id={"visits_metrics_filter_div"}>
          <label className="filter_header" htmlFor="visits_metrics_filter">
            METRICS
          </label>
          <div className="input_arrow" onClick={() => setIsMetricVisible(!isMetricVisible)}>
            <input
              readOnly
              type="text"
              id={"visits_metrics_filter"}
              data-test-id={"visits_metrics_filter_input"}
              value={
                isKbaChecked || isLeadsChecked || isVisitsChecked || isVisitsNationalChecked || isVisitsRegionalChecked
                  ? "Multiple"
                  : "Natural search visits"
              }
              className="filter_input"
            />
            <span className="arrow_down" />
          </div>

          <ul className={`filter_menu metrics_list ${isMetricVisible ? "showOptions" : ""}`} data-test-id="visits_metrics_ul">
            <label className="metric-option">
              <input className="metric-option" type="checkbox" checked={true} readOnly disabled />
              Natural search visits
            </label>
            <label className="metric-option">
              <input
                className="metric-option"
                type="checkbox"
                checked={isKbaChecked}
                onChange={() => {
                  setIsKbaChecked(!isKbaChecked);
                }}
              />
              Natural search KBA visits
            </label>
            <label className="metric-option">
              <input
                className="metric-option"
                type="checkbox"
                checked={isLeadsChecked}
                onChange={() => {
                  setIsLeadsChecked(!isLeadsChecked);
                }}
              />
              Natural search leads
            </label>
            {marketParamValue === "United States" ? (
              <>
                <label className="metric-option">
                  <input
                    className="metric-option"
                    type="checkbox"
                    checked={isVisitsRegionalChecked}
                    onChange={() => {
                      setIsVisitsRegionalChecked(!isVisitsRegionalChecked);
                    }}
                  />
                  Paid visits (Regional)
                </label>
                <label className="metric-option">
                  <input
                    className="metric-option"
                    type="checkbox"
                    checked={isVisitsNationalChecked}
                    onChange={() => {
                      setIsVisitsNationalChecked(!isVisitsNationalChecked);
                    }}
                  />
                  Paid visits (National)
                </label>
              </>
            ) : (
              <label className="metric-option">
                <input
                  className="metric-option"
                  type="checkbox"
                  checked={isVisitsChecked}
                  onChange={() => {
                    setIsVisitsChecked(!isVisitsChecked);
                  }}
                />
                {"Paid visits"}
              </label>
            )}
          </ul>
        </div>
      </div>

      {/*Visits chart */}
      <ErrorBoundary fallback={<ErrorMsg />}>
        <VisitsChart
          data={chartData}
          chartName={"visits-chart"}
          legendLabels={legendLabels}
          disabled={visitsDisabled}
          showKba={isKbaChecked}
          showPaidVisits={isVisitsChecked}
          showLeads={isLeadsChecked}
          showVisitsRegional={isVisitsRegionalChecked}
          showVisitsNational={isVisitsNationalChecked}
          minDistance={minGridDistance}
          weekCommence={weekCommence}
          landingPage={landingPage}
          dateRange={dateRangeParamValue}
          theme={theme}
        />
      </ErrorBoundary>

      <ErrorBoundary fallback={<ErrorMsg />}>
        <VisitsBreakdownTable />
      </ErrorBoundary>

      <LoadingEllipsis isLoading={isVisitsOverviewLoading} className="joined_graph_overlay" />
      <LoadingEllipsis isLoading={isVisitsBreakdownLoading} className={"joined_table_overlay"} />
    </div>
  );
};
