import {
  SET_BRAND_PERFORMANCE_LAST_DATA_REFRESH,
  SET_CPO_OPERATIONAL_LAST_REFRESH_DATE,
  SET_ECOMMERCE_LAST_REFRESH_DATE,
  SET_EXECUTIVE_SUMMARY_LAST_DATA_REFRESH,
  SET_JOURNEY_EXCELLENCE_LAST_DATA_REFRESH,
  SET_LAST_DATA_REFRESH,
  SET_MARKET_DATA_REFRESH,
  SET_MIDAS_FMI_LAST_REFRESH_DATE,
  SET_MSRP_LAST_DATA_REFRESH,
  SET_OCE_LAST_REFRESH_DATE,
  SET_PRODUCT_PERFORMANCE_LAST_REFRESH_DATE,
  SET_PURCHASE_FUNNEL_LAST_DATA_REFRESH,
  SET_USE_OWNERSHIP_LAST_DATA_REFRESH,
  SET_VOC_LAST_DATA_REFRESH,
} from "../actions/actionTypes";

const initialState: { [index: string]: any } = {
  digital_performance: "",
  ccs_cockpit: "",
  app_ratings: "",
  ariya_monitor: "",
  market_specific_data_dates: {},
  seo: "",
  purchase_funnel_data_refresh: {},
  product_marketing_optimization: {},
  midas_fmi_last_refresh_date: "",
  midas_fmi_by_model_last_refresh_date: "",
  cpo_operational_last_refresh_date: "",
  product_performance_last_refresh_date: "",
  ecommerce_last_data_refresh: "",
  msrp_last_data_refresh: "",
  journey_excellence_last_data_refresh: "",
  voc_last_data_refresh: "",
  use_ownership_last_data_refresh: "",
  brand_performance_last_data_refresh: "",
  executive_summary_last_data_refresh: "",
};

const lastDataRefreshReducer = (
  state = initialState,
  action: {
    type: string;
    payload: string;
    page_name: string;
    market: string;
  }
) => {
  switch (action.type) {
    case SET_LAST_DATA_REFRESH:
      return { ...state, [action.page_name]: action.payload };

    case SET_MARKET_DATA_REFRESH: {
      return { ...state, market_specific_data_dates: { ...state.market_specific_data_dates, [action.market]: action.payload } };
    }

    case SET_PURCHASE_FUNNEL_LAST_DATA_REFRESH: {
      return { ...state, purchase_funnel_data_refresh: action.payload };
    }

    case SET_OCE_LAST_REFRESH_DATE: {
      return { ...state, digital_performance: action.payload };
    }

    case SET_MIDAS_FMI_LAST_REFRESH_DATE: {
      return { ...state, midas_fmi_last_refresh_date: action.payload };
    }

    case SET_CPO_OPERATIONAL_LAST_REFRESH_DATE: {
      return { ...state, cpo_operational_last_refresh_date: action.payload };
    }

    case SET_PRODUCT_PERFORMANCE_LAST_REFRESH_DATE: {
      return { ...state, product_performance_last_refresh_date: action.payload };
    }

    case SET_ECOMMERCE_LAST_REFRESH_DATE: {
      return { ...state, ecommerce_last_data_refresh: action.payload };
    }

    case SET_MSRP_LAST_DATA_REFRESH: {
      return { ...state, msrp_last_data_refresh: action.payload };
    }

    case SET_JOURNEY_EXCELLENCE_LAST_DATA_REFRESH: {
      return { ...state, journey_excellence_last_data_refresh: action.payload };
    }

    case SET_VOC_LAST_DATA_REFRESH: {
      return { ...state, voc_last_data_refresh: action.payload };
    }

    case SET_USE_OWNERSHIP_LAST_DATA_REFRESH: {
      return { ...state, use_ownership_last_data_refresh: action.payload };
    }

    case SET_BRAND_PERFORMANCE_LAST_DATA_REFRESH: {
      return { ...state, brand_performance_last_data_refresh: action.payload };
    }

    case SET_EXECUTIVE_SUMMARY_LAST_DATA_REFRESH: {
      return { ...state, executive_summary_last_data_refresh: action.payload };
    }

    default:
      return state;
  }
};

export default lastDataRefreshReducer;
