export const FETCH_MODEL_TREND = "FETCH_MODEL_TREND";
export const SET_MODEL_TREND = "SET_MODEL_TREND";

export const FETCH_MODEL_KEY_INDICATORS = "FETCH_MODEL_KEY_INDICATORS";
export const SET_MODEL_KEY_INDICATORS = "SET_MODEL_KEY_INDICATORS";

export const FETCH_KEY_INDICATOR_VARIANCE = "FETCH_KEY_INDICATOR_VARIANCE";
export const SET_KEY_INDICATOR_VARIANCE = "SET_KEY_INDICATOR_VARIANCE";

export const FETCH_KEY_INDICATOR_ALL = "FETCH_KEY_INDICATOR_ALL";
export const SET_KEY_INDICATOR_ALL = "SET_KEY_INDICATOR_ALL";

export const FETCH_CPO_BREAKDOWN_TREND = "FETCH_CPO_BREAKDOWN_TREND";
export const SET_CPO_BREAKDOWN_TREND = "SET_CPO_BREAKDOWN_TREND";

export const FETCH_CPO_BREAKDOWN_TREND_ALL = "FETCH_CPO_BREAKDOWN_TREND_ALL";
export const SET_CPO_BREAKDOWN_TREND_ALL = "SET_CPO_BREAKDOWN_TREND_ALL";

export const FETCH_CPO_FILTERS = "FETCH_CPO_FILTERS";

export const SET_CPO_MARKETS = "SET_CPO_MARKETS";

export const FETCH_CPO_KBA_VISITS_TREND_DATA = "FETCH_CPO_KBA_VISITS_TREND_DATA";
export const SET_CPO_KBA_VISITS_TREND_DATA = "SET_CPO_KBA_VISITS_TREND_DATA";

export const FETCH_CPO_LEAD_VISITS_TREND_DATA = "FETCH_CPO_LEAD_VISITS_TREND_DATA";
export const SET_CPO_LEAD_VISITS_TREND_DATA = "SET_CPO_LEAD_VISITS_TREND_DATA";

export const FETCH_CPO_KBA_LEAD_VISITS_TREND_DATA = "FETCH_CPO_KBA_LEAD_VISITS_TREND_DATA";

export const SET_CPO_VISITS_GRAPH_DATA = "SET_CPO_VISITS_GRAPH_DATA";

export const SET_CPO_VISITS_TREND_KBA_LEADS = "SET_CPO_VISITS_TREND_KBA_LEADS";
export const SET_CPO_VISITS_LEADS_KBAS_GRAPH_DATA_DAILY = "SET_CPO_VISITS_LEADS_KBAS_GRAPH_DATA_DAILY";
export const SET_CPO_VISITS_LEADS_KBAS_GRAPH_DATA_WEEKLY = "SET_CPO_VISITS_LEADS_KBAS_GRAPH_DATA_WEEKLY";
export const SET_CPO_VISITS_LEADS_KBAS_GRAPH_DATA_MONTHLY = "SET_CPO_VISITS_LEADS_KBAS_GRAPH_DATA_MONTHLY";

export const SET_CPO_KBA_LEAD_KEYS = "SET_CPO_KBA_LEAD_KEYS";
export const SET_CPO_KBA_KEYS = "SET_CPO_KBA_KEYS";
export const SET_CPO_LEAD_KEYS = "SET_CPO_LEAD_KEYS";

export const SET_CPO_GROUPED_DATA = "SET_CPO_GROUPED_DATA";
export const SET_CPO_CHANNEL_DATA = "SET_CPO_CHANNEL_DATA";

export const FETCH_CPO_KBA_BREAKDOWN_TABLE_DATA = "FETCH_KBA_BREAKDOWN_TABLE_DATA";
export const SET_CPO_KBA_BREAKDOWN_TABLE_DATA = "SET_KBA_BREAKDOWN_TABLE_DATA";
export const FETCH_CPO_LEAD_BREAKDOWN_TABLE_DATA = "FETCH_LEAD_BREAKDOWN_TABLE_DATA";
export const SET_CPO_LEAD_BREAKDOWN_TABLE_DATA = "SET_LEAD_BREAKDOWN_TABLE_DATA";

export const FETCH_CPO_KBA_LABEL_MAPPING = "FETCH_CPO_KBA_LABEL_MAPPING";
export const SET_CPO_KBA_LABEL_MAPPING = "SET_CPO_KBA_LABEL_MAPPING";
export const FETCH_CPO_LEAD_LABEL_MAPPING = "FETCH_CPO_LEAD_LABEL_MAPPING";
export const SET_CPO_LEAD_LABEL_MAPPING = "SET_CPO_LEAD_LABEL_MAPPING";
