import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import "../../../../assets/styles/component/buttons.scss";

interface Props {
  id: string;
  value: string;
  disabled?: boolean;
  isLoading?: boolean;
}

export const SubmitBtn = (props: Props) => {
  const { id, value, disabled, isLoading } = props;
  return (
    <div className="button_area">
      <div className="submit_area">
        {isLoading ? (
          <CircularProgress />
        ) : disabled ? (
          <input type="submit" value={value} id={id} disabled />
        ) : (
          <input type="submit" value={value} id={id} />
        )}
      </div>
    </div>
  );
};
