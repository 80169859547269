import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getMarketDeepDiveTrendline } from "../../../../api/quickVocSalesApi";
import QuickVocLineChart from "../../../../components/Charts/QuickVocSales/QuickVocSalesLineChart";
import { ThemeContext } from "../../../../context";
import { SOV_MIX_COLORS } from "../../../../constants";
import { NoDataMsg } from "../../../../components/AppMessages";

interface Props {
  queryFn: () => Record<string, any>;
}

const MarketDeepDiveTrendline = withRouter((props: RouteComponentProps & Props) => {
  const { history, queryFn } = props;
  const { theme } = useContext(ThemeContext);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["marketDeepDiveTrendlineData", history.location.search],
    queryFn: queryFn,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return data?.data?.length > 0 ? (
    <QuickVocLineChart
      chartId="marketDeepDiveTrendlineChart"
      data={data?.data ?? []}
      isLoading={isFetching}
      seriesList={data?.series ?? []}
      theme={theme}
      seriesColors={SOV_MIX_COLORS}
    />
  ) : (
    <NoDataMsg />
  );
});

export default MarketDeepDiveTrendline;
