import { ErrorBoundary } from "@sentry/react";
import React, { useCallback } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import blueCedarLogo from "../../assets/images/blue_cedar_logo.svg";
import { ErrorMsg } from "../AppMessages";
import { PasswordInput, TextInput } from "./common";
import { SubmitBtn } from "./common/Buttons";

interface Props {
  onSubmit: any;
  error: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  user: { email: string; password: string };
  loading: boolean;
  disabled: boolean;
}

export const LoginForm = withRouter((props: Props & RouteComponentProps) => {
  const { error, user, onSubmit, onChange, history, loading, disabled } = props;

  const handleFormLinkClick = useCallback(
    () => (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const pathname = dataset.pageUrl;
      const searchString = dataset.searchString;

      const urlObj = searchString ? { pathname, search: searchString } : { pathname };

      history.push(urlObj);
    },
    [history, user]
  );

  return (
    <ErrorBoundary fallback={<ErrorMsg class="formError" />}>
      <div className="form_body" id="login_form_body">
        <div className="header_section">
          <img src={blueCedarLogo} alt="Cedar Logo" />
        </div>
        <form id="login_form" className="login_form" name="login_form" method="POST" data-test-id="login_form" onSubmit={onSubmit}>
          <div className="text_area">
            <h3 className="login_head form_header_text" data-test-id="login_form--welcome">
              Welcome to CEDAR Home
            </h3>

            {error && <p className={"error"}>{error}</p>}

            <TextInput
              label="Email address"
              onChange={onChange}
              name="email"
              id="username"
              value={user.email}
              pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
            />

            <PasswordInput label="Password" value={user.password} onChange={onChange} name="password" />

            <SubmitBtn id="login" value="Sign in" isLoading={loading} disabled={disabled} />

            <p
              id="forgot_password"
              data-test-id="forgot_password"
              data-page-url={"/forgotten_password"}
              data-search-string={`?user_email=${user?.email}`}
              className="links"
              onClick={handleFormLinkClick()}
            >
              Forgotten your password?
            </p>
            <p
              data-page-url={"/sign_up"}
              id="access_creation"
              className="email_sender links"
              data-test-id="access_creation"
              onClick={handleFormLinkClick()}
            >
              Don&apos;t have an account? Sign up here
            </p>
          </div>
        </form>
      </div>
    </ErrorBoundary>
  );
});
